import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { AppStore } from "../../../stores/AppStore";
import { AjaxRequest } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import { TextField, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FormControlLabel } from "@material-ui/core";
import MessageModal from "../MessageModal/MessageModal";
interface AjaxModalProps {
  appStore: AppStore;
  openModal: boolean;
  closeModal: () => void;
}

const SetAjaxModal: React.FC<AjaxModalProps> = ({
  appStore,
  openModal,
  closeModal,
}: AjaxModalProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [monitoringRequests, setMonitoringRequests] = React.useState<
    AjaxRequest[]
  >([]);
  const formattedRequests = React.useRef<string>("");
  const [userRequestCode, setUserRequestCode] = React.useState<number>(0);
  const [installerRequestCode, setInstallerRequestCode] =
    React.useState<number>(0);
  const [userRequestsCount, setUserRequestsCount] = React.useState<number>(0);
  const [installerRequestsCount, setInstallerRequestsCount] =
    React.useState<number>(0);
  const [siteId, setSiteId] = React.useState<number>(0);
  const [endButton, setEndButton] = React.useState<string>("Close");
  const [renderValue, setRenderValue] = useState(false);
  const [ajaxFormState, setAjaxFormState] = useState<any>([]);
  const acceptDisableCheck = React.useRef<boolean>(true);
  let selectRequests: { [key: string]: AjaxRequest } = {};
  const [selectRequestsState, setSelectRequestsState] = useState<{
    [key: string]: AjaxRequest;
  }>({});
  const [popmessage, setPopMessage] = useState("");
  const [popElement, setPopElement] = useState<any>(undefined);
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    fetchAjaxRequests();
  }, []);

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };
  const closeMessageModal = () => {
    setMessageModalOpen(false);
    closeModal();
  };

  const fetchAjaxRequests = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let requests = await appStore?.functionService.getAjaxRequests(token);
      if (requests.success === true) {
        setMonitoringRequests(requests.monitoring_requests);
        setUserRequestCode(requests.user_request_code);
        setInstallerRequestCode(requests.installer_request_code);
        setUserRequestsCount(requests.user_requests_count);
        setInstallerRequestsCount(requests.installer_requests_count);

        formattedRequests.current = formatMonitoringRequests(
          requests.monitoring_requests,
        );
      }
    }
    setLoading(false);
  };

  const sendAjaxRequests = async (request: string) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let result = await appStore?.functionService.sendAjaxRequests(
        token,
        request,
      );
      let element: any[] = [];
      let text: any = <p></p>;
      let msg = "";
      if (result.success === true) {
        if (
          result.successful_hubs != null &&
          Object.keys(result.successful_hubs).length !== 0
        ) {
          msg = "These hubs have been accepted successfully: ";
          text = <h2>{msg}</h2>;
          element.push(text);
          for (let entry in result.successful_hubs) {
            text = (
              <p className={classes.bold_text}>
                &nbsp;&nbsp;&nbsp;&nbsp;{entry}: {result.successful_hubs[entry]}
              </p>
            );
            element.push(text);
          }
          element.push(<br></br>);
        }
        if (
          result.unsuccessful_hubs != null &&
          Object.keys(result.unsuccessful_hubs).length !== 0
        ) {
          msg = "These hubs have failed to process:";
          text = <h2>{msg}</h2>;
          element.push(text);
          for (let entry in result.unsuccessful_hubs) {
            text = (
              <p className={classes.bold_text}>
                &nbsp;&nbsp;&nbsp;&nbsp;{entry}:{" "}
                {result.unsuccessful_hubs[entry]}
              </p>
            );
            element.push(text);
          }
        }
      } else {
        msg = result.msg;
        text = <h2>{msg}</h2>;
        element.push(text);
        element.push(<br />);
        if (
          result.successful_hubs != null &&
          Object.keys(result.successful_hubs).length !== 0
        ) {
          msg = "These hubs have been accepted successfully: ";
          text = <h2>{msg}</h2>;
          element.push(text);
          for (let entry in result.successful_hubs) {
            text = (
              <p className={classes.bold_text}>
                &nbsp;&nbsp;&nbsp;&nbsp;{entry}: {result.successful_hubs[entry]}
              </p>
            );
            element.push(text);
          }
        }
      }

      setPopMessage("");
      setPopElement(element);
      openMessageModal();
    }
    setLoading(false);
  };

  const sendRequest = () => {
    if (Object.keys(selectRequestsState).length > 0) {
      let requestString = "[";
      for (let i in selectRequestsState) {
        requestString += JSON.stringify(selectRequestsState[i]) + ",";
      }
      requestString = requestString.slice(0, -1) + "]";
      sendAjaxRequests(requestString);
    } else {
      closeModal();
    }
  };

  const handleRequestSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    request: AjaxRequest,
  ) => {
    let i = e.target.id;
    if (e.target.checked) {
      selectRequests[i] = request;
    } else {
      delete selectRequests[i];
    }
    setSelectRequestsState(selectRequests);
  };

  const formatMonitoringRequests = (requests: AjaxRequest[]): string => {
    var ajaxForm: React.ReactNode[] = [];
    if (requests != null && requests.length > 0) {
      for (const i in requests) {
        let disable = requests[i].site_id === "not created";
        ajaxForm.push(
          <FormControlLabel
            control={
              <Checkbox
                disabled={disable}
                className={classes.floatleft}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                // checked={}
                id={i}
                value={requests[i]}
                onChange={(e) => handleRequestSelection(e, requests[i])}
              />
            }
            label={`ajax_hub: ${requests[i].ajax_hub} - site id: ${requests[i].site_id} - site name: ${requests[i].site_name}`}
          />,
        );
      }
      setAjaxFormState(ajaxForm);
      return requests
        .map((request) => {
          return `ajax_hub: ${request.ajax_hub} - site id: ${request.site_id} - site name: ${request.site_name}`;
        })
        .join("\n");
    } else {
      ajaxForm.push(
        <FormControlLabel
          control={
            <Checkbox
              disabled={true}
              className={classes.floatleft}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
            />
          }
          label={`no pending hub requests`}
        />,
      );
      setAjaxFormState(ajaxForm);
      return `no pending hub requests`;
    }
  };

  const handleClose = () => {
    setRenderValue((value) => !value);
    setMonitoringRequests([]);
    setUserRequestCode(0);
    setInstallerRequestCode(0);
    setUserRequestsCount(0);
    setInstallerRequestsCount(0);
  };

  const StyledTableRowDynDns = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  const viewWindow = (
    <Dialog
      onClose={handleClose}
      open={openModal}
      className={classes.modal_dialog_wide}
      fullWidth={true}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                label="user result code"
                defaultValue={userRequestCode}
                disabled={true}
                variant="outlined"
                margin="normal"
                helperText="User requests URL result code"
                InputProps={{
                  className: classes.bold_text,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="user result count"
                defaultValue={userRequestsCount}
                disabled={true}
                variant="outlined"
                margin="normal"
                helperText="Count of pending user monitoring requests"
                InputProps={{
                  className: classes.bold_text,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="installer result code"
                defaultValue={installerRequestCode}
                disabled={true}
                variant="outlined"
                margin="normal"
                helperText="Installer requests URL result code"
                InputProps={{
                  className: classes.bold_text,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="installer result count"
                defaultValue={installerRequestsCount}
                disabled={true}
                variant="outlined"
                margin="normal"
                helperText="Count of pending installer monitoring requests"
                InputProps={{
                  className: classes.bold_text,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div>Monitoring Requests</div>
              {ajaxFormState}
            </Grid>
          </Grid>
        </div>
      )}

      <div className={classes.twoButtonsInARow}>
        <Button
          variant="contained"
          className={classes.cancel_button}
          onClick={() => {
            closeModal();
          }}
        >
          {endButton}
        </Button>
        {!siteId && (
          <Button
            variant="contained"
            className={classes.bold_text}
            disabled={false}
            onClick={() => {
              sendRequest();
            }}
          >
            Accept
          </Button>
        )}
      </div>
      <MessageModal
        open={messagemodalopen}
        onClose={closeMessageModal}
        message={popmessage}
        // elements={undefined}
        elements={popElement}
      />
    </Dialog>
  );

  return viewWindow;
};
export default SetAjaxModal;
