import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { IconButton, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  listBillingProfileOrgsItems,
  User,
  Org,
  Site,
  Hub,
} from "../../../../services/FunctionService";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

interface AddBillingProfileUserProps {
  open: boolean;
  appStore?: AppStore;
  profile: listBillingItems | undefined;
  onClose: () => void;
  AddBillingProfileUserFunction: (profile_id: number, user_id: number) => void;
}
interface listBillingItems {
  id: number;
  enabled: boolean;
  name: string;
  description: string;
  meta: { [key: string]: any };
  created: Date | string;
}
class impOrg implements Org {
  org_id!: number;
  org_name!: string;
  org_tree!: number[];
  org_level!: number;
  enabled!: boolean;
  sites!: Site[];
  hubs!: Hub[];
  orgs!: Org[];
  type!: string;
  created!: string;
  sub_org_count!: number;
  site_count!: number;
  hub_count!: number;
}

const AddBillingProfileUser: React.FC<AddBillingProfileUserProps> = ({
  open,
  onClose,
  profile,
  appStore,
  AddBillingProfileUserFunction,
}: AddBillingProfileUserProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
        paddingLeft: "2%",
        paddingRight: "2%",
      },
    }),
  )(TableRow);
  const useStyle = makeStyles({
    paper: {
      border: "2px solid #6D809D",
      color: "#15325F",
    },
  });
  const styler = useStyle();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [renderValue, setRenderValue] = useState(false);
  const [orgslist, setOrgsList] = React.useState<Org[]>([]);
  const [groupid, setGroupId] = React.useState(0);
  const [groupname, setGroupName] = React.useState("");
  const [incSubgroups, setIncSubgroups] = useState(false);
  const [viewProfileOrgsData, setProfileOrgsViewData] = useState<
    listBillingProfileOrgsItems | undefined
  >(undefined);
  const [billingOrgsProfiles, setBillingOrgsProfiles] = useState<
    listBillingProfileOrgsItems[] | undefined
  >(undefined);
  const [selectError, setSelectError] = useState(false);
  const [usersError, setUsersError] = useState(false);
  const [usersfortree, setUsersForTree] = useState<User[] | undefined>(
    undefined,
  );
  const [user_id, setUser_id] = React.useState<number | undefined>(undefined);

  const handleChange = (event: React.ChangeEvent<any>) => {
    setSelectError(false);
    setUser_id(+event.target.value);
  };
  const fetchData = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const users: User[] =
        await appStore?.functionService.getAllUsersForOrgFiltered(
          token,
          groupid,
          incSubgroups,
        );

      if (users) {
        setUser_id(undefined);
        setUsersForTree(users);
        setLoading(false);
      }
    }
  };
  const handleChangeAuto = (value: any) => {
    setUsersError(false);
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setGroupName(group.org_name);
    }
  };

  useEffect(() => {
    const fetchAllUsers = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      let neworg = new impOrg();
      neworg.org_id = appStore?.authService.getEntryOrg()?.id!;
      if (token) {
        setLoading(true);

        const usersData = await appStore!.functionService.getAllVisibleUsers(
          token,
          neworg.org_id,
          true,
        );
        if (usersData) {
          setUsersForTree(usersData);
          setLoading(false);
        }
      }
    };
    fetchAllUsers();
  }, [profile, renderValue]);
  useEffect(() => {
    const fetchOrgList = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        const OrgListResult =
          await appStore?.functionService.getListOrgsWithTreeNames(token);
        if (OrgListResult && OrgListResult.success) {
          let orgz = OrgListResult?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);

          setLoading(false);
        }
      }
    };
    fetchOrgList();
  }, []);
  useEffect(() => {
    fetchData();
  }, [groupid, incSubgroups, renderValue]);

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <Typography
            className={classes.bold_text}
            style={{ color: "#3f51b5" }}
          >
            Add a Profile User to{" "}
            <span
              style={{ color: "blue", fontSize: 14 }}
              className={classes.bold_text}
            >
              {profile ? profile.name : ""}
            </span>{" "}
          </Typography>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <FormControl className={classes.formControl}>
              <Autocomplete
                id="combo-box-demo"
                className={classes.form_component_medium}
                options={orgslist}
                onChange={(event: React.ChangeEvent<any>, value: any) => {
                  handleChangeAuto(value);
                }}
                style={{ marginLeft: "2%", width: "96%", color: "#6D809D" }}
                classes={{ paper: styler.paper }}
                getOptionLabel={(option: any) => {
                  let tree = option.org_tree_names!.map(
                    (item: any) => `   /   ${item} `,
                  );
                  let treeString = tree.toString();
                  const rpl = treeString.replace(/,/g, "");
                  return `${rpl}`;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span className={classes.bold_text_billing}>
                        {groupname ? groupname : "please select group"}
                      </span>
                    }
                    variant="outlined"
                    value={groupid}
                    error={usersError}
                    className={classes.bold_text_billing}
                  />
                )}
              />
              {groupname !== "" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingRight: "20%",
                  }}
                >
                  <FormControl>
                    <Typography className={classes.header_text}>
                      Include Sub-Groups
                    </Typography>
                  </FormControl>
                  <FormControl>
                    <Checkbox
                      className={classes.form_component_checkbox}
                      // disabled={true}
                      onChange={() => {
                        if (groupname !== "please select group") {
                          setIncSubgroups((prev) => !prev);
                        } else {
                          setUsersError(true);
                        }
                      }}
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={incSubgroups}
                    />
                  </FormControl>
                </div>
              ) : null}
              <br></br>
              <FormControl style={{ width: "90%", marginLeft: "5%" }}>
                <InputLabel
                  style={{ paddingLeft: "1%" }}
                  id="demo-controlled-open-select-label"
                >
                  {" "}
                  Username
                </InputLabel>
                <Select
                  error={selectError}
                  label=" username"
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  variant="outlined"
                  value={user_id}
                  // helperText={
                  //   selectError === true ? 'Profile name field cannot be blank' : ''
                  // }
                  onChange={(e) => handleChange(e)}
                >
                  {usersfortree &&
                    usersfortree.map((user, index) => {
                      return (
                        <MenuItem value={user.user_id}>
                          <Typography className={classes.bold_text_billing}>
                            {user.username}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </FormControl>
          </>
        )}

        <div
          style={{ marginTop: "5%", marginBottom: "5%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              setGroupName("");
              setUser_id(undefined);
              setSelectError(false);
              setRenderValue((value) => !value);
              onClose();
            }}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          <Button
            variant="contained"
            className={classes.cancel_button_billing}
            onClick={() => {
              if (user_id) {
                AddBillingProfileUserFunction(profile!.id, user_id!);
                setGroupName("");
                setUser_id(undefined);
                onClose();
              } else {
                setSelectError(true);
              }
            }}
          >
            <span className={classes.bold_text}>
              Add&nbsp;profile&nbsp;user
            </span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default AddBillingProfileUser;
