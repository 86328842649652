import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Typography, Box, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "../../../styling/global/global";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import {
  ListHikCentralEntitiesResult,
  hikCentralListItems,
  Sensor,
  Site,
  UpdateResult,
  AlertEndPoint,
  GetAlertEndPoints,
} from "../../../../services/FunctionService";
import { TextField } from "@material-ui/core";
import { NULL } from "node-sass";

interface CameraListingProps {
  handleClose: () => void;
  onBack: () => void;
  appStore?: AppStore;
  alert_channel_end_point_id: number;
  site: Site | undefined;
  pagingTotal: number;
  alert_channel_id: number;
  handleNext: () => void;
}
const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 30,
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:hover": {
        backgroundColor: "#e3f2fd",
        cursor: "pointer",
      },
    },
  }),
)(TableRow);
const StyledTableRowSMTP = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 20,
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const CameraListing: React.FC<CameraListingProps> = ({
  handleClose,
  onBack,
  appStore,
  alert_channel_end_point_id,
  site,
  pagingTotal,
  alert_channel_id,
  handleNext,
}: CameraListingProps) => {
  const classes = useStyles();
  const [paginationTotal, setPaginationtotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(1);
  const [, setTestResult] = useState(false);
  const [, setMultipleVisit] = useState(false);
  const [, setSelectError] = useState(false);
  const [resultFailure, setResultFailure] = useState(false);
  const [failMessage, setFailMessage] = useState("");
  const [, setSelectedCamera] = useState("");
  const [, setActivateNextStep] = useState(false);
  const [, setDeepAlertOverrideColor] = useState("");
  const [alertEndpoints, setAlertEndpoints] = useState<
    AlertEndPoint[] | undefined
  >([]);
  const [currentAlertEndpoint, setCurrentAlertEndpoint] = useState<
    AlertEndPoint | undefined
  >(undefined);
  const [listItems, setListItems] = useState<hikCentralListItems[] | undefined>(
    undefined,
  );
  const [renderValue, setRenderValue] = useState(false);
  const [listItem, setListItem] = useState<hikCentralListItems | undefined>(
    undefined,
  );
  const [sensors, setSensors] = useState<Sensor[] | undefined>(undefined);
  const [sensor, setSensor] = useState<Sensor | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [filterVariable, setFilterVariable] = useState("");
  const [checkedState, setCheckedState] = useState<boolean[] | undefined>([]);
  const [checkedHikState, setCheckedHikState] = useState<boolean[] | undefined>(
    [],
  );
  const handleCheckboxChange = (position: number) => {
    const updatedCheckedState = checkedState!.map((item, index) =>
      index === position ? !item : false,
    );

    setCheckedState(updatedCheckedState);
  };
  const handleFilter = (event: React.ChangeEvent<any>) => {
    setFilterVariable(event.target.value);
  };
  const handleCheckboxHikChange = (position: number) => {
    const updatedCheckedHikState = checkedHikState!.map((item, index) =>
      index === position ? !item : false,
    );

    setCheckedHikState(updatedCheckedHikState);
  };

  const handleMapping = () => {
    if (sensor) {
      if (listItem) {
        let meta: { [key: string]: any } = {};
        if (currentAlertEndpoint?.meta) {
          meta = currentAlertEndpoint?.meta;
          if (meta["mapping"]) {
            meta["mapping"] = {
              ...meta["mapping"],
              ...{ [sensor.sensor_id_str]: listItem.cameraIndexCode },
            };
          } else {
            meta["mapping"] = {
              [sensor.sensor_id_str]: listItem.cameraIndexCode,
            };
          }

          editHikCentralEndpoint(alert_channel_end_point_id, meta);
        }
      } else {
        setFailMessage("Please select hikcentral camera");
        setSnackMessage("Please select hikcentral camera");
        setResultFailure(true);
        setOpen(true);
      }
    } else {
      setFailMessage("Please select deepAlert sensor");
      setSnackMessage("Please select deepAlert sensor");
      setResultFailure(true);
      setOpen(true);
    }
  };
  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    fetchHikCentralListingData(value);
  };
  const getSingleHikEndPoint = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const hikEndpointsResults: GetAlertEndPoints =
        await appStore?.functionService.getSingleAlertEndPoint(
          token,
          alert_channel_id,
          alert_channel_end_point_id,
        );
      if (hikEndpointsResults) {
        if (hikEndpointsResults.success) {
          setCurrentAlertEndpoint(hikEndpointsResults.alert_endpoints[0]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };
  const getHikEndPoints = async (alert_channel_id: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const hikEndpointsResults: GetAlertEndPoints =
        await appStore?.functionService.getHikAlertEndPoints(
          token,
          alert_channel_id,
          "hikcentral",
        );
      if (hikEndpointsResults) {
        if (hikEndpointsResults.success) {
          setAlertEndpoints(hikEndpointsResults.alert_endpoints);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };
  const editHikCentralEndpoint = async (
    alert_channel_end_point_id: number,
    meta: { [key: string]: any },
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const hikEndpointResult: UpdateResult =
        await appStore?.functionService.modifyAlertEndPoint(
          token,
          alert_channel_end_point_id,
          meta,
        );
      if (hikEndpointResult) {
        if (hikEndpointResult.success) {
          setActivateNextStep(true);
          setLoading(false);
          setRenderValue((value) => !value);
          //handleSetPostresult();
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };

  const fetchHikCentralListingData = async (pageNo: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    setLoading(true);
    if (token) {
      const hikResult: ListHikCentralEntitiesResult =
        await appStore?.functionService.listHikCentralEntities(
          token,
          alert_channel_end_point_id,
          "camera",
          pageNo,
          20,
        );
      if (hikResult) {
        if (hikResult.success) {
          setPaginationtotal(
            Math.ceil(hikResult.msg.data.total / hikResult.msg.data.pageSize),
          );
          setListItems(hikResult.msg.data.list);
          setTestResult(true);
          setLoading(false);
          setMultipleVisit(true);
          if (hikResult.msg.data.list) {
            setCheckedHikState(
              new Array(hikResult.msg.data.list.length).fill(false),
            );
          }
        } else {
          setTestResult(false);
          setLoading(false);
        }
      }
    }
  };
  const fetchCameras = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    setLoading(true);
    if (token) {
    }
    var sensors: Sensor[] =
      await appStore?.functionService.getEnabledSensorsForSite(
        token,
        site!.site_id,
        "none",
      );
    if (sensors) {
      setSensors(sensors);
      setLoading(false);
      setCheckedState(new Array(sensors.length).fill(false));
    }
  };
  const fetchHikCentralListingTest = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    setLoading(true);
    if (token) {
      const hikResult = await appStore?.functionService.listHikCentralEntities(
        token,
        alert_channel_end_point_id,
        "camera",
        1,
        10,
      );
      if (hikResult) {
        if (hikResult.success) {
          setPaginationtotal(hikResult.msg.data.total);
          setListItems(hikResult.msg.data.list);
          setTestResult(true);
          if (hikResult.msg.data.list) {
            setCheckedHikState(
              new Array(hikResult.msg.data.list.length).fill(false),
            );
          }
          setLoading(false);
          setMultipleVisit(true);
        } else {
          setMultipleVisit(true);
          setTestResult(false);
          setLoading(false);
        }
      }
    }
  };

  const handleSnackClose = () => {
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  var mappingTable: React.ReactNode[] = [];
  if (alertEndpoints) {
    alertEndpoints.map((endpoint, index) => {
      if (endpoint.meta) {
        if ("mapping" in endpoint.meta) {
          Object.keys(endpoint.meta["mapping"]).map((keyName, keyValue) => {
            mappingTable.push(
              <StyledTableRowSMTP>
                <TableCell
                  style={{
                    fontSize: 10,
                  }}
                >
                  {keyName}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 10,
                  }}
                >
                  {endpoint.meta["mapping"][keyName]}
                </TableCell>
              </StyledTableRowSMTP>,
            );
          });
        }
      }
    });
  }
  useEffect(() => {
    fetchCameras();
    fetchHikCentralListingData(1);
    getSingleHikEndPoint();
    getHikEndPoints(alert_channel_id);
  }, [alert_channel_end_point_id, site, pagingTotal, renderValue]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div style={{ width: "100%" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleSnackClose}
              message={snackMessage}
              action={action}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // marginLeft: '25%',
                }}
              >
                <Typography
                  align="center"
                  style={{ fontSize: 15, color: "blue", marginBottom: 3 }}
                >
                  Existing&nbsp;mapping
                </Typography>
                <div
                  style={{
                    boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                    marginTop: 15,
                    borderRadius: 15,
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <Paper style={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer style={{ maxHeight: 300 }}>
                      <Table aria-label="sticky table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontSize: 12 }}>Name</TableCell>
                            <TableCell style={{ fontSize: 12 }}>
                              Index&nbsp;code
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>{mappingTable}</TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "55%" }}>
              <Box mb={2} mt={2} justifyContent="center">
                <TextField
                  onChange={(e) => {
                    handleFilter(e);
                  }}
                  label="Filter hikCentral cameras"
                  variant="outlined"
                  disabled={false}
                  margin="normal"
                  autoFocus
                  value={filterVariable}
                  style={{ width: 450 }}
                  InputProps={{
                    className: classes.bold_text,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.bold_text,
                    },
                  }}
                />
              </Box>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  width: "45%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  align="center"
                  style={{ fontSize: 15, color: "blue", marginBottom: 3 }}
                >
                  DeepAlert&nbsp;sensors
                </Typography>
                <div
                  style={{
                    boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                    marginTop: 15,
                    borderRadius: 15,
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <Paper style={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer style={{ maxHeight: 300 }}>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontSize: 12 }}>
                              Human name
                            </TableCell>
                            <TableCell style={{ fontSize: 12 }}>
                              Sensor id
                            </TableCell>
                            <TableCell style={{ fontSize: 12 }}>
                              Sensor name
                            </TableCell>
                            <TableCell style={{ fontSize: 12 }}>
                              Status
                            </TableCell>
                            <TableCell style={{ fontSize: 12 }}>
                              Hub id
                            </TableCell>
                            <TableCell style={{ fontSize: 12 }}>
                              Site id
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sensors &&
                            sensors.map((sensor, index) => {
                              return (
                                <StyledTableRow
                                  style={
                                    checkedState![index] === false
                                      ? // deepAlertOverrideColor === ''
                                        index % 2
                                        ? { background: "#fdffe0" }
                                        : { background: "white" }
                                      : { background: "#ad42f5" }
                                  }
                                  onClick={() => {
                                    if (checkedState![index] === false) {
                                      handleCheckboxChange(index);
                                      setSensor(sensor);
                                      setSnackMessage("sensor selected");
                                    } else {
                                      handleCheckboxChange(index);
                                      setSensor(undefined);
                                      setSnackMessage("sensor deselected");
                                    }

                                    setOpen(true);
                                    setResultFailure(false);
                                  }}
                                >
                                  <TableCell style={{ fontSize: 10 }}>
                                    {sensor.human_name}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 10 }}>
                                    {sensor.sensor_id}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 10 }}>
                                    {sensor.sensor_id_str}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 10 }}>
                                    {sensor.enabled ? "enabled" : "disabled"}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 10 }}>
                                    {sensor.hub_id}
                                  </TableCell>
                                  <TableCell style={{ fontSize: 10 }}>
                                    {sensor.site_id}
                                  </TableCell>
                                </StyledTableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </div>
              <div
                style={{
                  width: "45%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  align="center"
                  style={{ fontSize: 15, color: "blue", marginBottom: 3 }}
                >
                  HikCentral&nbsp;cameras
                </Typography>
                <div
                  style={{
                    boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                    marginTop: 15,
                    borderRadius: 15,
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <Paper style={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer style={{ maxHeight: 300 }}>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontSize: 12 }}>
                              Camera name
                            </TableCell>
                            <TableCell style={{ fontSize: 12 }}>
                              Camera index code
                            </TableCell>
                            <TableCell style={{ fontSize: 12 }}>
                              Site index code
                            </TableCell>
                            <TableCell style={{ fontSize: 12 }}>
                              Record type
                            </TableCell>
                            <TableCell style={{ fontSize: 12 }}>
                              Status
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listItems
                            ? filterVariable === ""
                              ? listItems.map((item, index) => {
                                  return (
                                    <StyledTableRow
                                      className={classes.tableRow}
                                      style={
                                        checkedHikState![index] === false
                                          ? index % 2
                                            ? { background: "#fdffe0" }
                                            : { background: "white" }
                                          : { background: "#ad42f5" }
                                      }
                                      onClick={() => {
                                        if (checkedHikState![index] === false) {
                                          handleCheckboxHikChange(index);
                                          setListItem(item);
                                          setSnackMessage("hik selected");
                                        } else {
                                          handleCheckboxHikChange(index);
                                          setListItem(undefined);
                                          setSnackMessage("hik deselected");
                                        }

                                        setOpen(true);
                                        setResultFailure(false);
                                      }}
                                    >
                                      <TableCell style={{ fontSize: 10 }}>
                                        {item.cameraName}
                                      </TableCell>
                                      <TableCell style={{ fontSize: 10 }}>
                                        {item.cameraIndexCode}
                                      </TableCell>
                                      <TableCell style={{ fontSize: 10 }}>
                                        {item.siteIndexCode}
                                      </TableCell>
                                      <TableCell style={{ fontSize: 10 }}>
                                        {item.recordType}
                                      </TableCell>
                                      <TableCell style={{ fontSize: 10 }}>
                                        {item.status}
                                      </TableCell>
                                    </StyledTableRow>
                                  );
                                })
                              : listItems
                                  .filter((fil) =>
                                    fil.cameraName
                                      .toLowerCase()
                                      .includes(filterVariable.toLowerCase()),
                                  )
                                  .map((item, index) => {
                                    return (
                                      <StyledTableRow
                                        className={classes.tableRow}
                                        style={
                                          checkedHikState![index] === false
                                            ? index % 2
                                              ? { background: "#fdffe0" }
                                              : { background: "white" }
                                            : { background: "#ad42f5" }
                                        }
                                        onClick={() => {
                                          if (
                                            checkedHikState![index] === false
                                          ) {
                                            handleCheckboxHikChange(index);
                                            setListItem(item);
                                            setSnackMessage("hik selected");
                                          } else {
                                            handleCheckboxHikChange(index);
                                            // setDeepAlertOverrideColor('');
                                            setListItem(undefined);
                                            setSnackMessage("hik deselected");
                                          }

                                          setOpen(true);
                                          setResultFailure(false);
                                        }}
                                      >
                                        <TableCell style={{ fontSize: 10 }}>
                                          {item.cameraName}
                                        </TableCell>
                                        <TableCell style={{ fontSize: 10 }}>
                                          {item.cameraIndexCode}
                                        </TableCell>
                                        <TableCell style={{ fontSize: 10 }}>
                                          {item.siteIndexCode}
                                        </TableCell>
                                        <TableCell style={{ fontSize: 10 }}>
                                          {item.recordType}
                                        </TableCell>
                                        <TableCell style={{ fontSize: 10 }}>
                                          {item.status}
                                        </TableCell>
                                      </StyledTableRow>
                                    );
                                  })
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
                <Box mt={2} mb={2} justifyContent="center" alignItems="center">
                  <Pagination
                    style={{ margin: "auto" }}
                    count={paginationTotal}
                    page={page}
                    onChange={handlePaginationChange}
                    variant="outlined"
                    color="primary"
                  />
                </Box>
              </div>
            </div>
            <div
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {resultFailure ? (
                <Box mt={2} mb={2} justifyContent="center">
                  <Alert
                    variant="outlined"
                    severity="error"
                    onClose={() => {
                      setResultFailure(false);
                      setFailMessage("");
                    }}
                  >
                    {failMessage}
                  </Alert>
                </Box>
              ) : null}
            </div>
          </div>
          <div
            style={{ marginBottom: 20, marginTop: 20, width: "100%" }}
            className={classes.twoButtonsInARow}
          >
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              className={classes.mute_button}
              onClick={() => onBack()}
            >
              Back
            </Button>
            <Button
              variant="contained"
              className={classes.mute_button}
              onClick={handleClose}
            >
              Close
            </Button>
            {listItems && (
              <Button
                variant="contained"
                className={classes.mute_button}
                onClick={handleMapping}
              >
                Add&nbsp;mapping
              </Button>
            )}

            <Button
              variant="contained"
              className={classes.mute_button}
              onClick={handleNext}
            >
              Next Step
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CameraListing;
