import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { AppStore } from "../../../stores/AppStore";
import { Site, Hub } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

interface RenameSiteProps {
  appStore?: AppStore;
  onClose: () => void;
  open: boolean;
  renameSiteName: (site: Site, name: string) => void;
  site: Site | undefined;
}

const RenameSite: React.FC<RenameSiteProps> = ({
  onClose,
  open,
  appStore,
  renameSiteName,
  site,
}: RenameSiteProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [hubs, setHubs] = useState<Hub[] | undefined>([]);
  const [name, setName] = React.useState<string>("");
  const [renderValue, setRenderValue] = useState(false);
  const [charError, setCharError] = useState(false);

  const handleNameChange = (event: React.ChangeEvent<any>) => {
    let letterNumber = /^[0-9a-zA-Z_ -]+$/;
    if (event.target.value.match(letterNumber)) {
      setCharError(false);
      setName(event.target.value);
    } else {
      setName(event.target.value);
      setCharError(true);
    }
  };

  useEffect(() => {
    if (site) {
      setName(site.name);
    }
  }, [renderValue, site]);

  const handleClose = () => {
    setName("");
    setRenderValue((value) => !value);
    onClose();
  };

  const StyledTableRowSMTP = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className={classes.modal_dialog_wide}
      fullWidth={true}
    >
      <DialogTitle className={classes.bold_text}>
        Change Site Name for {site?.name}
      </DialogTitle>
      <br></br>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <TextField
          fullWidth
          label="Site Name"
          placeholder={site?.site_name}
          onChange={handleNameChange}
          value={name}
          defaultValue={site?.site_name}
          variant="outlined"
          margin="normal"
          error={charError}
          helperText={
            charError
              ? "Name must only contain numbers,letters or the following characters: _-"
              : ""
          }
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
      )}

      <div className={classes.twoButtonsInARow}>
        <Button
          variant="contained"
          className={classes.cancel_button}
          onClick={() => {
            setRenderValue((value) => !value);
            setName("");
            handleClose();
          }}
        >
          Dismiss
        </Button>
        <Button
          variant="contained"
          className={classes.bold_text}
          onClick={() => {
            if (name !== site?.site_name && !charError) {
              renameSiteName(site!, name);
              setRenderValue((value) => !value);
              setName("");
              onClose();
            }
          }}
        >
          Update
        </Button>
      </div>
    </Dialog>
  );
};
export default RenameSite;
