import React, { useState } from "react";
import { useStyles } from "../styling/global/global";
import SlideShow from "../UI/SlideShow";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import FlagIcon from "@material-ui/icons/Flag";
import { AppStore } from "../../stores/AppStore";

interface EventType {
  doc_id: string;
  track_id: string;
  elastic_index: string;
  event_type: string;
  data_origin: string;
  site_id: number;
  site_name: string;
  sensor_id_str: string;
  sensor_human_name: string;
  media_url: string;
  image_height: number;
  image_width: number;
  created: number;
  classifier: string;
  probabilities: number[] | string[];
  class_labels: string[];
  message: string;
  improve_accuracy_flag: boolean;
  improve_accuracy_username: string | null;
  improve_accuracy_timestamp: string | null;
  suppress_flag: boolean;
  record_type: string;
  review_logs?: review_logs[] | [];
  reason_for_flag?: string;
}

interface review_logs {
  created: number;
  log_username: string;
  review_status: string;
  content: string;
}

interface Props {
  selectedDataAlert: any;
  flagBtn: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAlert: any;
  alertList: EventType[];
  alert: any;
  AutoPlay: any;
  autoplayHook: any;
  autoplay: boolean;
  flagged: boolean;
  silenced?: boolean;
  message?: string;
  setSelectedAlert: (a: any) => void;
  review_logs?: review_logs[] | [];
  siteId: string | number;
  siteName: string;
  sensorName: string;
  sensorId: string;
  classifier: string;
  imgUrl: string;
  created: number;
  probability: number | string[];
  onClickHandler: () => void;
  mode: string;
  appStore?: AppStore;
  object_detections?:
    | {
        inference_class: string;
        inference_probability: number;
        x0: number;
        y0: number;
        x1: number;
        y1: number;
      }[]
    | undefined;
  reason_for_flag?: string;
  advanced_rule_type?: string;
  advanced_rule_name?: string;
}

const AlertViewerMulti: React.FC<Props> = ({
  selectedDataAlert,
  autoplayHook,
  flagBtn,
  setSelectedAlert,
  review_logs,
  imgUrl,
  onClickHandler,
  siteName,
  message,
  siteId,
  sensorId,
  classifier,
  created,
  probability,
  sensorName,
  mode,
  autoplay,
  AutoPlay,
  alertList,
  alert,
  selectedAlert,
  flagged,
  silenced,
  appStore,
  object_detections,
  reason_for_flag,
  advanced_rule_type,
  advanced_rule_name,
}) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [loadImages, setLoadImages] = useState(false);
  const [mediaUrls, setMediaUrls] = useState<any>(undefined);

  const createdTime = (epocSeconds: number, showBrowser = true) => {
    let today = new Date(epocSeconds * 1000);
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();

    let hr: number | string = new Date(epocSeconds * 1000).getHours();
    let min: number | string = new Date(epocSeconds * 1000).getMinutes();
    let sec: number | string = new Date(epocSeconds * 1000).getSeconds();

    if (min == 0) {
      min = "00";
    }

    if (min.toString().length == 1) {
      min = `0${min}`;
    }

    if (hr.toString().length == 1) {
      hr = `0${hr}`;
    }

    if (sec.toString().length == 1) {
      sec = `0${sec}`;
    }

    return ` ${yyyy}/${mm}/${dd} -  ${hr}:${min}:${sec} ${
      showBrowser ? "(browser time)" : ""
    } `;
  };

  const noImages = [require("./images/noimage.jpeg")];

  const userLogsHandler = (review_logs: review_logs[] | []) => {
    let cmpStr =
      mode === "silenced" ? "suppress_flag set" : "suppress_flag unset";
    let action = mode === "silenced" ? "silenced" : "unsilenced";
    if (review_logs.length === 1) {
      return `Alert ${action} by ${review_logs[0].log_username},  ${createdTime(
        review_logs[0].created,
        true,
      )}`;
    } else if (review_logs.length === 0) {
      return "none";
    } else {
      for (let i = review_logs.length - 1; i >= 0; i--) {
        if (review_logs[i].content === cmpStr) {
          return `Alert ${action} by ${
            review_logs[i].log_username
          },  ${createdTime(review_logs[i].created, true)}`;
        }
      }
    }
  };

  const flaggedByUser = () => {
    let userInQuestion = "";
    if (selectedAlert.event_data.improve_accuracy_username) {
      userInQuestion = selectedAlert.event_data.improve_accuracy_username;
    } else {
      userInQuestion = selectedAlert.event_data.review_logs[0].log_username;
    }

    let msg = `Image flagged by ${userInQuestion}`;

    if (selectedAlert.event_data.reason_for_flag) {
      msg = msg + ` due to ${selectedAlert.event_data.reason_for_flag}`;
    }

    if (selectedAlert.event_data.improve_accuracy_timestamp) {
      msg =
        msg +
        `, ${createdTime(
          selectedAlert.event_data.improve_accuracy_timestamp,
          true,
        )}`;
    }
    return msg;
  };

  const onChangeHandler = (to: number) => {
    if (selectedAlert.inference_data.length <= 1) return;

    let cp = { ...selectedAlert };
    cp.event_data = selectedAlert.inference_data[to];
    setSelectedAlert(cp);
    setSelectedIndex(to + 1);

    if (imgUrl !== "/assets/img/nodata3.png" && to) {
      // setSelectedAlert(alertList[to - 1])
    }
  };

  const probMapper = () => {
    let probabilities = "";
    if (
      selectedAlert?.event_data?.class_labels &&
      selectedAlert.event_data.probabilities
    ) {
      selectedAlert?.event_data?.class_labels.map(
        (item: string, index: number) => {
          let txt =
            index === selectedAlert?.event_data?.class_labels.length - 1
              ? `${selectedAlert.event_data.probabilities[index]} (${item}) `
              : `${selectedAlert.event_data.probabilities[index]} (${item}), `;
          probabilities = probabilities + txt;
          // `${selectedAlert.event_data.probabilities[index]} (${item}) `;
        },
      );
    } else {
      probabilities = "n/a";
    }
    return probabilities;
  };

  return (
    <div style={{ width: "100%", zIndex: 100 }}>
      <div
        style={{
          minHeight:
            selectedAlert?.event_data?.image_height &&
            selectedAlert?.event_data?.image_height < 488
              ? selectedAlert?.event_data?.image_height + 8
              : 488,
        }}
      >
        <SlideShow
          autoplayHook={autoplayHook}
          flagBtn={flagBtn}
          width={selectedAlert?.event_data?.image_width}
          height={selectedAlert?.event_data?.image_height}
          object_detections={object_detections}
          autoplay={autoplay}
          AutoPlayy={AutoPlay}
          slideImages={
            selectedAlert?.inference_data?.length > 0
              ? selectedAlert.inference_data.map(
                  (i: any) => i.media_url || noImages,
                )
              : noImages
          }
          onChangeHandler={onChangeHandler}
        />
      </div>

      {created !== 0 && (
        <div
          className={classes.hover}
          style={{
            cursor: "pointer",
            textAlign: "left",
            width: "100%",
            overflowY: "scroll",
            maxHeight: 305,
          }}
          onClick={() => onClickHandler()}
        >
          {!loadImages && (
            <span
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5,
                paddingRight: 20,
              }}
            >
              {/* {selectedAlert?.event_data?.image_height &&
                selectedAlert?.event_data?.image_width && (
                  <div>
                    Camera Resolution - {selectedAlert?.event_data?.image_width}{" "}
                    X {selectedAlert?.event_data?.image_height}
                  </div>
                )} */}

              {/* "native_width": 640,
                "native_height": 480,
                "native_depth": 3 */}
              {selectedAlert?.event_data?.native_height &&
                selectedAlert?.event_data?.native_height &&
                selectedAlert?.event_data?.native_depth && (
                  <div>
                    Native resolution - width=
                    {selectedAlert?.event_data?.native_width} , height=
                    {selectedAlert?.event_data?.native_height}, colours=
                    {selectedAlert?.event_data?.native_depth}
                  </div>
                )}
              <div
                style={{
                  alignSelf: " flex-end",
                  width: "20%",
                  fontStyle: "italic",
                }}
              >
                {selectedAlert?.event_data?.record_type === "alert" ? (
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    (alert)
                  </span>
                ) : (
                  "(inference)"
                )}{" "}
                &nbsp;&nbsp; {selectedIndex}/
                {selectedAlert.inference_data.length !== 0
                  ? selectedAlert?.inference_data?.length
                  : 1}{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  paddingRight: 50,
                }}
              >
                {silenced && (
                  <div style={{ alignSelf: " flex-end", width: "5%" }}>
                    <VolumeOffIcon className={classes.icon_blue} />
                  </div>
                )}
                {flagged && (
                  <div style={{ alignSelf: " flex-end", width: "10%" }}>
                    <FlagIcon className={classes.icon_blue} />
                  </div>
                )}
              </div>
            </span>
          )}
          {siteName && (
            <p className={classes.bold_text} onClick={() => {}}>
              Site Name:{" "}
              <span style={{ color: "grey", marginLeft: 10 }}>{siteName}</span>
            </p>
          )}
          {sensorName && sensorId && (
            <p className={classes.bold_text} onClick={() => {}}>
              Camera:{" "}
              <span style={{ color: "grey", marginLeft: 10 }}>
                {sensorName} ({sensorId})
              </span>
            </p>
          )}
          {message && (
            <p className={classes.bold_text} onClick={() => {}}>
              Message:{" "}
              <span style={{ color: "grey", marginLeft: 10 }}>{message}</span>
            </p>
          )}
          {
            <p className={classes.bold_text} style={{ maxWidth: 750 }}>
              Probability %:
              <span style={{ color: "grey", marginLeft: 10 }}>
                {probMapper() ? probMapper() : "N/A"}
              </span>
            </p>
          }

          {!!review_logs?.length &&
            (mode === "silenced" || mode === "unsilenced") && (
              <p className={classes.bold_text}>
                User Logs:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>
                  {userLogsHandler(review_logs!)}
                </span>
              </p>
            )}

          {!!selectedAlert.event_data.rule_type &&
            !!selectedAlert.event_data.rule_name && (
              <p className={classes.bold_text}>
                Advanced Rule:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>
                  {advanced_rule_name} ({advanced_rule_type})
                </span>
              </p>
            )}

          {(!!selectedAlert.event_data.improve_accuracy_username ||
            !!selectedAlert.event_data.reason_for_flag) && (
            <p className={classes.bold_text}>
              Flagging info:{" "}
              <span style={{ color: "grey", marginLeft: 10 }}>
                {flaggedByUser()}
              </span>
            </p>
          )}

          <p className={classes.bold_text}>
            Record Timestamp:{" "}
            <span style={{ color: "grey", marginLeft: 10 }}>
              {createdTime(created)}
            </span>
          </p>
          <>
            {selectedAlert?.event_data.isAlpr &&
              selectedAlert.event_data.vehicle_recognitions &&
              selectedAlert.event_data.vehicle_recognitions.length > 0 && (
                <h4
                  style={{
                    fontWeight: 600,
                    color: "grey",
                    marginTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <u>ALPR DETAILS: </u>
                </h4>
              )}
            {selectedAlert.event_data.vehicle_recognitions &&
              selectedAlert.event_data.vehicle_recognitions.map((item: any) => (
                <div
                  style={{
                    border: "0.5px solid grey",
                    borderRadius: 8,
                    padding: 10,
                    margin: 5,
                    marginLeft: 0,
                  }}
                >
                  {item.plate && (
                    <p className={classes.bold_text} onClick={() => {}}>
                      Plate:{" "}
                      <span style={{ color: "grey", marginLeft: 10 }}>
                        {item.plate.toUpperCase()}
                      </span>
                    </p>
                  )}
                  {item.make && (
                    <p className={classes.bold_text} onClick={() => {}}>
                      Make:{" "}
                      <span style={{ color: "grey", marginLeft: 10 }}>
                        {item.make}
                      </span>
                    </p>
                  )}
                  {item.model && (
                    <p className={classes.bold_text} onClick={() => {}}>
                      Model:{" "}
                      <span style={{ color: "grey", marginLeft: 10 }}>
                        {item.model}
                      </span>
                    </p>
                  )}
                  {item.colour && (
                    <p className={classes.bold_text} onClick={() => {}}>
                      Colour:{" "}
                      <span style={{ color: "grey", marginLeft: 10 }}>
                        {item.colour}
                      </span>
                    </p>
                  )}
                  {item.vehicle_detection_score && (
                    <p className={classes.bold_text} onClick={() => {}}>
                      Vehicle Detection Probability:{" "}
                      <span style={{ color: "grey", marginLeft: 10 }}>
                        {Math.round(item.vehicle_detection_score * 100)}%
                      </span>
                    </p>
                  )}
                </div>
              ))}
          </>
        </div>
      )}
    </div>
  );
};

export default AlertViewerMulti;
