import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { AppStore } from "../../../stores/AppStore";
import { StepLabel, Step, Stepper, Box } from "@material-ui/core";
import { Site } from "../../../services/FunctionService";

import Credentials from "./HikSteps/Credentials";
import CameraListing from "./HikSteps/CameraListing";
import ConfigureHik from "./HikSteps/ConfigureHik";
interface HickCentralProps {
  open: boolean;
  message: string;
  onClose: () => void;
  appStore?: AppStore;
  alert_channel_id: number;
  site: Site;
}
const theme = createMuiTheme({
  transitions: {
    easing: {
      easeOut: "cubic-bezier(0, 1.5, .8, 1)",
      sharp: "linear",
    },
  },
});
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <ThemeProvider theme={theme}>
      <Slide direction="up" in={true} timeout={10000} ref={ref} {...props} />;
    </ThemeProvider>
  );
});
const steps = ["Credentials", "Camera mapping", "Configure HikCentral"];
const AddHickCentral: React.FC<HickCentralProps> = ({
  message,
  open,
  onClose,
  appStore,
  alert_channel_id,
  site,
}: HickCentralProps) => {
  const [channelEndpointId, setChannelEndpointId] = useState(0);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [postResult, setPostResult] = useState(false);
  const [multipleVisit, setMultipleVisit] = useState(false);
  const handleSetMultipleVisit = () => {
    setMultipleVisit(true);
  };
  const handleSetChannelEndpointId = (endpoint: number) => {
    setChannelEndpointId(endpoint);
  };
  const handleSetPaginationTotal = (pagination_total: number) => {
    setPaginationTotal(pagination_total);
  };

  const handleSetPostresult = () => {
    setPostResult(true);
  };

  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {}, [alert_channel_id]);

  return (
    <Dialog
      maxWidth={activeStep === 1 ? "xl" : "md"}
      onClose={handleClose}
      scroll="paper"
      aria-describedby="alert-dialog-slide-description"
      open={open}
      className="edit-dialog"
      fullWidth={true}
      TransitionComponent={Transition}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogTitle id="simple-dialog-title">
          <span
            className={classes.bold_text}
            style={{ fontSize: 20, color: "primary" }}
          >
            HikCentralPro Setup Page
          </span>
        </DialogTitle>
        <Box style={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {} = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        {activeStep === 0 ? (
          <Credentials
            setMultiple_Visit={handleSetMultipleVisit}
            multiple_visit={multipleVisit}
            appStore={appStore}
            handleClose={handleClose}
            alert_channel_id={alert_channel_id}
            setChannelEndpointId={(endpoint: number) =>
              handleSetChannelEndpointId(endpoint)
            }
            setPaginationTotal={(pagination_total: number) =>
              handleSetPaginationTotal(pagination_total)
            }
            alert_channel_end_point={channelEndpointId}
            postResult={postResult}
            handleSetPostresult={handleSetPostresult}
            handleNext={handleNext}
          />
        ) : null}
        {activeStep === 1 ? (
          <CameraListing
            handleClose={handleClose}
            onBack={handleBack}
            appStore={appStore}
            alert_channel_end_point_id={channelEndpointId}
            site={site}
            pagingTotal={paginationTotal}
            alert_channel_id={alert_channel_id}
            handleNext={handleNext}
          />
        ) : null}
        {activeStep === 2 ? (
          <ConfigureHik
            handleClose={handleClose}
            onBack={handleBack}
            appStore={appStore}
            alert_channel_end_point_id={channelEndpointId}
          />
        ) : null}
      </div>
    </Dialog>
  );
};

export default AddHickCentral;
