import React from "react";
import { AppStore } from "../../../stores/AppStore";
import TextField from "@material-ui/core/TextField";
import { TimeEntrySetSingle } from "../../../core/models/TimeEntrySingle";
import TimeEntryPanelSingle from "../../components/TimeEntryUI/TimeEntryPanelSingle";
import { AddCircle } from "@material-ui/icons";
import { useStyles } from "../../styling/global/global";
import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
interface SafetyAdvancedProps {
  appStore?: AppStore;
  safetyconfig: { [key: string]: any };
  setSafetyConfig: (x: { [key: string]: any }) => void;
  editable: boolean;
}
const SafetyAdvanced: React.FC<SafetyAdvancedProps> = ({
  editable,
  appStore,
  safetyconfig,
  setSafetyConfig,
}: SafetyAdvancedProps) => {
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(0);
  const [message, setMessage] = React.useState<string>("");

  const handleRODIntervalChange = (event: React.ChangeEvent<any>) => {
    if (isNaN(event.target.value)) {
      setMessage("Interval seconds must be a valid number!");
    } else {
      safetyconfig.regular_object_detection_interval_secs = Number(
        event.target.value,
      );
      setSafetyConfig(safetyconfig);
      setRefresh(new Date().getTime());
    }
  };
  const handleODDaysTimeChange = (newentrySet: string) => {
    safetyconfig.object_detection_days_and_exact_times = newentrySet;
    setSafetyConfig(safetyconfig);
    setRefresh(new Date().getTime());
  };
  const addODSchedule = () => {
    var timeentryset = new TimeEntrySetSingle("");
    if (safetyconfig.object_detection_days_and_exact_times) {
      timeentryset = new TimeEntrySetSingle(
        safetyconfig.object_detection_days_and_exact_times,
      );
    }
    timeentryset.addTimeEntry();
    handleODDaysTimeChange(timeentryset.toString());
  };
  const handleSSDaysTimeChange = (newentrySet: string) => {
    if (safetyconfig) {
      safetyconfig.snapshot_days_and_exact_times = newentrySet;
      setSafetyConfig(safetyconfig);
      setRefresh(new Date().getTime());
    }
  };
  const addSNSchedule = () => {
    var timeentryset = new TimeEntrySetSingle("");
    if (safetyconfig.snapshot_days_and_exact_times) {
      timeentryset = new TimeEntrySetSingle(
        safetyconfig.snapshot_days_and_exact_times,
      );
    }
    timeentryset.addTimeEntry();
    handleSSDaysTimeChange(timeentryset.toString());
  };

  var detections: React.ReactNode[] = [];
  if (safetyconfig && safetyconfig.object_detection_days_and_exact_times) {
    const guitimeentries = new TimeEntrySetSingle(
      safetyconfig.object_detection_days_and_exact_times,
    );
    if (
      guitimeentries &&
      guitimeentries.entries &&
      guitimeentries.entries.length > 0
    ) {
      detections.push(
        <TimeEntryPanelSingle
          addicon={true}
          disabled={!editable}
          entrySet={guitimeentries}
          changeTimeEntryParent={(newentrySet: TimeEntrySetSingle) => {
            handleODDaysTimeChange(newentrySet.toString());
          }}
        ></TimeEntryPanelSingle>,
      );
    } else {
      detections.push(
        <IconButton
          disabled={!editable}
          edge="end"
          aria-label="add"
          onClick={() => {
            addODSchedule();
          }}
        >
          <AddCircle color={"primary"} />
        </IconButton>,
      );
    }
  } else {
    detections.push(
      <IconButton
        disabled={!editable}
        edge="end"
        aria-label="add"
        onClick={() => {
          addODSchedule();
        }}
      >
        <AddCircle color={"primary"} />
      </IconButton>,
    );
  }
  return (
    <div className={classes.medium_panel}>
      <div className={classes.header}>
        <div className={classes.header_text}>Advanced Settings</div>
      </div>
      <Typography className={classes.bold_error_text}>{message}</Typography>
      <div className={classes.form_component}>
        <div className={classes.bold_text}>
          Regular Object Detection Interval Seconds
        </div>
        <TextField
          disabled={!editable}
          label="Seconds"
          onChange={handleRODIntervalChange}
          value={safetyconfig.regular_object_detection_interval_secs}
          variant="outlined"
          margin="normal"
          fullWidth
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
      </div>
      <div className={classes.form_component}>
        <div className={classes.bold_text}>
          Object Detection Days and Exact Times
        </div>
        {detections}
      </div>
    </div>
  );
};

export default SafetyAdvanced;
