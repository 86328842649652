import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useStyles } from "../../styling/global/global";
import {
  withStyles,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { AppStore } from "../../../stores/AppStore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Sensor } from "../../../services/FunctionService";
import { Typography } from "@material-ui/core";
interface SiteHealthDetailProps {
  appStore?: AppStore;
  onClose: () => void;
  open: boolean;
  site_id?: number;
  start_time_filter?: number;
  end_time_filter?: number;
}
const SiteHealthDetail: React.FC<SiteHealthDetailProps> = ({
  start_time_filter,
  end_time_filter,
  site_id,
  open,
  onClose,
  appStore,
}: SiteHealthDetailProps) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState<string>("");
  const [sitehealth, setSiteHealth] = React.useState<[{ [key: string]: any }]>([
    {},
  ]);
  const handleClose = () => {
    setSiteHealth([{}]);
    onClose();
  };

  const getEpochTime = () => {
    if (start_time_filter) {
      var d = start_time_filter! * 1000;
      var date = new Date(d).toLocaleDateString();
      var time = new Date(d).toLocaleString("en-GB", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      return `${time}       (${date})`;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (open && site_id) {
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var health: any;
          if (start_time_filter) {
            health = await appStore?.functionService.getHealthDetailForSite(
              token,
              site_id,
              start_time_filter,
              start_time_filter + 120,
            );
          } else {
            health = await appStore?.functionService.getHealthDetailForSite(
              token,
              site_id,
            );
          }
          setSiteHealth(health);
        }
      }
      setLoading(false);
    };
    fetchData();
  }, [open, site_id]);
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:hover": {
          backgroundColor: "#e3f2fd",
        },
      },
    }),
  )(TableRow);
  var hubdisplays: React.ReactNode[] = [];
  if (sitehealth && sitehealth.length > 0) {
    sitehealth.forEach((hubhealth) => {
      var healthitems: React.ReactNode[] = [];
      var sensorsdisplay: React.ReactNode[] = [];
      var siteoverall: React.ReactNode[] = [];
      var huboverall: React.ReactNode[] = [];
      var hubvpn: React.ReactNode[] = [];
      var hubmessage: React.ReactNode[] = [];
      for (let key in hubhealth) {
        let value = "";
        const translate = appStore?.functionService.translate(
          "en_ZA",
          "health_keys",
          key,
        );
        if (
          key != "trigger_conf" &&
          key != "sensors" &&
          !key.endsWith("ok") &&
          key != "hub_epoch_secs"
        ) {
          value = hubhealth[key];
        } else if (key.endsWith("ok")) {
          let bool = hubhealth[key];
          if (bool) {
            value = "True";
          } else {
            value = "False";
          }
        } else if (key == "hub_epoch_secs") {
          let timelong = hubhealth[key];
          value = new Date(timelong * 1000).toString();
        }
        if (
          key != "trigger_conf" &&
          key != "sensors" &&
          key != "classifier" &&
          key != "hub_cloud_delay_secs"
        ) {
          healthitems.push(
            <StyledTableRow key={key}>
              <TableCell>
                <span className={classes.bold_text}>{translate + " : "}</span>
              </TableCell>
              <TableCell>
                <span className={classes.standard_text}>
                  {translate == "Hub Time" || translate == "Created"
                    ? moment(value).format("YYYY-MM-DD HH:mm")
                    : value}
                </span>
              </TableCell>
            </StyledTableRow>,
          );
        }
        if (key == "hub_cloud_delay_secs") {
          healthitems.push(
            <StyledTableRow key={key}>
              <TableCell>
                <span className={classes.bold_text}>
                  {"Hub Cloud Delay " + " : "}
                </span>
              </TableCell>
              <TableCell>
                <span className={classes.standard_text}>
                  {parseFloat(value) < 0
                    ? Math.abs(parseFloat(value)).toFixed(1) +
                      " seconds ahead " +
                      "(includes propagation time)"
                    : parseFloat(value).toFixed(1) +
                      " seconds behind " +
                      "(includes propagation time)"}
                </span>
              </TableCell>
            </StyledTableRow>,
          );
        }
        if (key === "overall_indicator") {
          siteoverall.push(
            value === "ok" ? (
              <SentimentSatisfiedAltIcon
                className={classes.green}
              ></SentimentSatisfiedAltIcon>
            ) : value === "warning" ? (
              <SentimentDissatisfiedIcon
                className={classes.amber}
              ></SentimentDissatisfiedIcon>
            ) : (
              <SentimentVeryDissatisfiedIcon
                className={classes.red}
              ></SentimentVeryDissatisfiedIcon>
            ),
          );
        }
        if (key === "hub_status") {
          huboverall.push(
            value === "healthy" ? (
              <SentimentSatisfiedAltIcon
                className={classes.green}
              ></SentimentSatisfiedAltIcon>
            ) : value === "unhealthy" ? (
              <SentimentVeryDissatisfiedIcon
                className={classes.red}
              ></SentimentVeryDissatisfiedIcon>
            ) : (
              <SentimentDissatisfiedIcon
                className={classes.amber}
              ></SentimentDissatisfiedIcon>
            ),
          );
        }
        if (key === "hub_vpn_ok") {
          hubvpn.push(
            value === "True" ? (
              <CheckIcon className={classes.green}></CheckIcon>
            ) : (
              <CloseIcon className={classes.red}></CloseIcon>
            ),
          );
        }
        if (key === "hub_msg_ok") {
          hubmessage.push(
            value === "True" ? (
              <CheckIcon className={classes.green}></CheckIcon>
            ) : (
              <CloseIcon className={classes.red}></CloseIcon>
            ),
          );
        }

        if (key == "sensors") {
          let sensors = hubhealth[key];
          if (sensors && sensors.length > 0) {
            sensors.sort((a: Sensor, b: Sensor) =>
              a.sensor_id_str.localeCompare(b.sensor_id_str),
            );
            sensors.forEach((sensor: { [key: string]: any }) => {
              for (let skey in sensor) {
                const translate = appStore?.functionService.translate(
                  "en_ZA",
                  "health_keys",
                  skey,
                );
                let value = "";
                value = sensor[skey];
                sensorsdisplay.push(
                  <StyledTableRow key={key}>
                    <TableCell>
                      <span className={classes.bold_text}>
                        {sensor.sensor_id_str}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className={classes.bold_text}>
                        {translate + " : "}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className={classes.standard_text}>
                        {translate == "Active Now" ? value.toString() : value}
                      </span>
                    </TableCell>
                  </StyledTableRow>,
                );
              }
            });
          }
        }
      }
      hubdisplays.push(
        <>
          <Typography className={classes.bold_text}>
            {"HUB: " + hubhealth["hub_id_str"]}
          </Typography>
          <br />
        </>,
      );
      hubdisplays.push(
        <TableContainer component={Paper}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Site Overall</TableCell>
                <TableCell>Hub Overall</TableCell>
                <TableCell>Hub VPN</TableCell>
                <TableCell>Hub Messaging</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{siteoverall}</TableCell>
                <TableCell>{huboverall}</TableCell>
                <TableCell>{hubvpn}</TableCell>
                <TableCell>{hubmessage}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>

          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Property</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{healthitems}</TableBody>
          </Table>

          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Sensor</TableCell>
                <TableCell>Property</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{sensorsdisplay}</TableBody>
          </Table>
        </TableContainer>,
      );
    });
  }
  if (hubdisplays.length > 0) {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        {/* <DialogTitle className='add-rule-dialog-header'>
          Site Health
        </DialogTitle> */}
        <DialogTitle
          id="alert-dialog-slide-title"
          className={classes.button_container}
        >
          {start_time_filter && "SELECTED TIME -   "} {getEpochTime()}
          {!start_time_filter && " SITE HEALTH"}
        </DialogTitle>
        {!loading ? (
          hubdisplays
        ) : (
          <div style={{ margin: 40 }}>
            <LinearProgress />
          </div>
        )}
        &nbsp;
        <div className={classes.button_container}>
          <Button
            variant="contained"
            className={classes.cancel_button2}
            onClick={handleClose}
          >
            {/* <span className={classes.bold_text}>OK</span> */}
            OK
          </Button>
        </div>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle className="add-rule-dialog-header">
          Site Health
        </DialogTitle>
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stopColor="#ffffff" />
                <stop offset="100%" stopColor="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
        <div className={classes.button_container}>
          <Button
            variant="contained"
            className={classes.cancel_button2}
            onClick={handleClose}
          >
            {/* <span className={classes.bold_text}>OK</span>*/}
            OK
          </Button>
        </div>
      </Dialog>
    );
  }
};
export default SiteHealthDetail;
