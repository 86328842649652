import { AuthService } from "../services/AuthService";
import { FunctionService } from "../services/FunctionService";
import { Helpers } from "../services/Helpers";
export class AppStore {
  readonly authService: AuthService;
  readonly functionService: FunctionService;
  readonly helpers: Helpers;
  readonly version: string;
  constructor(
    functionService: FunctionService,
    authService: AuthService,
    helpers: Helpers,
    version: string,
  ) {
    this.authService = authService;
    this.functionService = functionService;
    this.helpers = helpers;
    this.version = version;
  }
}
