import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { IconButton } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import { AppStore } from "../../../stores/AppStore";
import { Org, MultiEmail } from "../../../services/FunctionService";
import { Typography } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Guid } from "guid-typescript";
import { useStyles } from "../../styling/global/global";
import Grid from "@material-ui/core/Grid";
class impMultiEmail implements MultiEmail {
  key!: Guid;
  email!: string;
  parent_id!: number;
}
interface AddReportGroupProps {
  currentOrg?: Org;
  currentOrgName?: string;
  appStore?: AppStore;
  open: boolean;
  onClose: () => void;
  saveNewReportGroup: (name: string, recipients: string[]) => void;
  message: string;
}

const AddReportGroupModal: React.FC<AddReportGroupProps> = ({
  currentOrg,
  currentOrgName,
  appStore,
  open,
  onClose,
  saveNewReportGroup,
  message,
}: AddReportGroupProps) => {
  const [emails, setEmails] = React.useState<MultiEmail[] | undefined>([]);
  const [name, setName] = React.useState<string>("");
  const [refresh, setRefresh] = React.useState(0);
  const classes = useStyles();
  const handleClose = () => {
    onClose();
    setEmails([]);
    setName("");
  };
  const handleEmailChange = (event: React.ChangeEvent<any>, key: Guid) => {
    var email = emails?.find((x) => x.key == key);
    var emailindex = emails?.findIndex((x) => x.key == key);
    if (emails && email) {
      email.email = event.target.value;
      emails[emailindex!] = email;
      setEmails(emails);
      setRefresh(new Date().getTime());
    }
  };
  const addEmail = () => {
    var email = new impMultiEmail();
    email.key = Guid.create();
    email.email = "";
    emails?.push(email);
    setEmails(emails);
    setRefresh(new Date().getTime());
  };
  const removeEmail = (key: Guid) => {
    var newemails: MultiEmail[] = [];
    emails!.forEach((email) => {
      if (email.key != key) {
        newemails.push(email);
      }
    });
    setEmails(newemails);
    setRefresh(new Date().getTime());
  };
  const handleNameChange = (event: React.ChangeEvent<any>) => {
    setName(event.target.value);
  };
  const handleSave = () => {
    onClose();
    var emailarray: string[] = [];
    emails?.forEach((email) => {
      emailarray.push(email.email);
    });
    setEmails([]);
    setName("");
    saveNewReportGroup(name, emailarray);
  };
  var email_list: React.ReactNode[] = [];
  if (emails) {
    emails.forEach((email) => {
      email_list.push(
        <Grid container>
          <Grid item xs={10}>
            <TextField
              onChange={(e) => handleEmailChange(e, email.key)}
              label="Email Address"
              variant="outlined"
              margin="normal"
              value={email.email}
              fullWidth
              autoFocus
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          </Grid>{" "}
          <Grid item xs={2}>
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => {
                removeEmail(email.key);
              }}
            >
              <DeleteOutlineIcon color={"primary"} />
            </IconButton>
          </Grid>
        </Grid>,
      );
    });
  }
  if (appStore && appStore.authService.getRoles().indexOf("org_admin") >= 0) {
    return (
      <Dialog
        onClose={handleClose}
        className={classes.modal_dialog}
        open={open}
      >
        <DialogTitle>
          <span className={classes.bold_text}>
            Add new report group to the{" "}
            {currentOrgName === "" ? currentOrg?.org_name : currentOrgName}{" "}
            group level.{" "}
          </span>
        </DialogTitle>
        <Typography className={classes.bold_text} color="error">
          {message}
        </Typography>
        <TextField
          onChange={handleNameChange}
          label="Name"
          variant="outlined"
          margin="normal"
          fullWidth
          autoFocus
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
        {email_list}
        <Grid container>
          <Grid item xs={2}>
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => {
                addEmail();
              }}
            >
              <AddCircle color={"primary"} />
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <p className={classes.bold_text}>{"Add Email"}</p>
          </Grid>
        </Grid>
        <div className={classes.button_container}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={handleClose}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
          <Button
            variant="contained"
            className={classes.save_button}
            onClick={handleSave}
          >
            <span className={classes.bold_text}>SAVE</span>
          </Button>
        </div>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className={classes.modal_dialog}
      >
        <DialogTitle className={classes.bold_text}>
          Add New Group to :{" "}
          {currentOrgName === "" ? currentOrg?.org_name : currentOrgName}
        </DialogTitle>
        <br></br>
        <Typography className={classes.bold_text}>
          {
            "You need group admin role to be able to add a report group - please contact your DeepAlert system administrator"
          }
        </Typography>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={() => handleClose()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
        </div>
      </Dialog>
    );
  }
};

export default AddReportGroupModal;
