import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import UserRuleEndPoint from "./UserRuleEndpoint";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AppStore } from "../../../stores/AppStore";
import {
  withStyles,
  useTheme,
  Theme,
  createStyles,
  fade,
} from "@material-ui/core/styles";
import {
  UserEndPointRoles,
  AlertChannel,
  AlertEndPointDef,
  Org,
  Sensor,
  User,
  AlertEndPoint,
  EntryPointValues,
  UserWebRoles,
  WebChannelResults,
  WebChannelRoles,
  UserRevisedWebRoles,
} from "../../../services/FunctionService";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import CameraMapping from "../../components/CameraMapping/CameraMapping";
import { Guid } from "guid-typescript";
import { useStyles } from "../../styling/global/global";
import MessageModal from "../MessageModal/MessageModal";

import Paper from "@material-ui/core/Paper";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

class impUserEndPointRoles implements UserEndPointRoles {
  key!: Guid;
  user_id!: number;
  web_roles!: { [key: string]: any };
  // end_point_roles!: { [key: string]: any };
}
class impEntryPointValues implements EntryPointValues {
  key!: string;
  value: any;
  type!: string;
}
// interface Data {
//     user_id: number;
//     username: string;
//     first_name: string;
//     last_name: string;
//     supervisor: boolean;
//     agent: boolean;
// }

interface NewData {
  user_id: number;
  username: string;
  first_name: string;
  last_name: string;
  web_alerts: boolean;
  web_alert_escalations: boolean;
  web_mute_controls: boolean;
  web_alert_camera_filter: boolean;
  web_alert_history: boolean;
  web_bulk_actions: boolean;
}

interface DummyObj {
  [key: string]: string | undefined;
}

class newImpUserWebRoles implements UserRevisedWebRoles {
  first_name!: string;
  last_name!: string;
  user_id!: number;
  username!: string;
  web_alerts!: boolean;
  web_alert_escalations!: boolean;
  web_mute_controls!: boolean;
  web_alert_camera_filter!: boolean;
  web_alert_history!: boolean;
  web_bulk_actions!: boolean;
}
interface EditAlertEndpointProps {
  telegramDefinition: AlertEndPointDef | undefined;
  currentOrg: Org;
  appStore?: AppStore;
  alertchannel: AlertChannel;
  open: boolean;
  onClose: () => void;
  updateEndpoint: (
    alert_channel_end_point_id: number,
    meta: { [key: string]: any },
  ) => void;
  endpointdefs?: AlertEndPointDef[];
  sensors: Sensor[];
  endpoint: AlertEndPoint;
  saveNewEndpoint: (
    alert_channel_id: number,
    end_point_type: string,
    name: string,
    meta: { [key: string]: any },
    userendpointroles: UserEndPointRoles[],
    message: string,
  ) => void;
  setPopMessage: (message: string) => void;
  openPopMessage: () => void;
  forceRefresh: () => void;
  setLoadingParent: () => void;
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 30,
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:hover": {
        backgroundColor: "#e3f2fd",
        cursor: "pointer",
      },
    },
  }),
)(TableRow);

const EditAlertChannelEndpointModal: React.FC<EditAlertEndpointProps> = ({
  telegramDefinition,
  setLoadingParent,
  forceRefresh,
  setPopMessage,
  openPopMessage,
  endpoint,
  currentOrg,
  appStore,
  alertchannel,
  open,
  onClose,
  endpointdefs,
  sensors,
  updateEndpoint,
  saveNewEndpoint,
}: EditAlertEndpointProps) => {
  const classes = useStyles();
  const [message, setMessage] = React.useState("");
  const [endpointtype, setEndPointType] = React.useState("");
  const [telegramBotName, setTelegramBotName] = React.useState("");
  const [telegramBotId, setTelegramBotId] = React.useState<number>(-1);
  const [isSysAdmin, setIsSysAdmin] = React.useState(false);
  const [selectError, setSelectError] = useState(false);
  const [values, setValues] = React.useState<any[]>([]);
  const [cameramappings, setCameraMappings] = React.useState<any[]>([]);
  const [userruleendpointvalues, setUserRuleEndpointValues] = React.useState<
    UserEndPointRoles[]
  >([]);
  const [userChannelRoles, setUserChannelRoles] = useState<
    WebChannelRoles | undefined
  >(undefined);
  const [orgUsers, setOrgUsers] = React.useState<User[]>([]);
  const [users, setUsers] = React.useState<User[]>([]);
  // const [userwebroles, setUserWebRoles] = React.useState<UserWebRoles[]>([]);
  const [newuserwebroles, setNewUserWebRoles] = React.useState<
    UserRevisedWebRoles[]
  >([]);
  const [refresh, setRefresh] = React.useState(0);
  const [renderValue, setRenderValue] = useState(false);
  // const [selecteduserwebroles, setSelectedUserWebRoles] = React.useState<UserWebRoles[]>([]);
  const [newSelecteduserwebroles, setNewSelectedUserWebRoles] = React.useState<
    UserRevisedWebRoles[]
  >([]);
  const [groupid, setGroupId] = React.useState(0);
  const [loadingrows, setLoadingRows] = React.useState(false);
  const [orgslist, setOrgsList] = React.useState<Org[]>([]);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  useEffect(() => {
    const setData = async () => {
      if (appStore!.authService.getRoles().indexOf("sys_admin") >= 0) {
        setIsSysAdmin(true);
      }
      if (endpoint) {
        setEndPointType(endpoint.end_point_type);
      }
      if (endpoint?.end_point_type === "telegram" && endpoint?.meta) {
        setTelegramBotName(endpoint.meta["telegram_bot"]);
        setTelegramBotId(endpoint.meta["bot"]);
      }
      var token = await appStore?.authService.getAuthorisedToken();
      const orgUsersRes: User[] =
        await appStore?.functionService.getAllUsersForOrgIncSubGroups(
          token,
          currentOrg.org_id,
          false,
        );

      if (orgUsersRes) {
        if (endpoint) {
          if (endpoint.users) {
            let existing_ids_array: number[] = endpoint.users.map((num) => {
              return num.user_id;
            });
            if (existing_ids_array.length > 0) {
              const filtered_orgs = orgUsersRes.filter(
                (user) => !existing_ids_array.includes(user.user_id),
              );
              setOrgUsers(filtered_orgs);
            } else {
              setOrgUsers(orgUsersRes);
            }
          } else {
            setOrgUsers(orgUsersRes);
          }
        }
      }
    };
    setData();
  }, [endpoint, renderValue]);

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        const orglist = await appStore?.functionService.getOrgsList(token);
        if (orglist && orglist.success) {
          setOrgsList(orglist?.orgs);
        }
      }
    };
    fetchData();
  }, [renderValue]);
  function newCreateData(
    user_id: number,
    username: string,
    first_name: string,
    last_name: string,
    web_alerts: boolean,
    web_alert_escalations: boolean,
    web_mute_controls: boolean,
    web_alert_camera_filter: boolean,
    web_alert_history: boolean,
    web_bulk_actions: boolean,
  ): NewData {
    return {
      user_id,
      username,
      first_name,
      last_name,
      web_alerts,
      web_alert_escalations,
      web_mute_controls,
      web_alert_camera_filter,
      web_alert_history,
      web_bulk_actions,
    };
  }

  useEffect(() => {
    const setData = async () => {
      if (endpoint && open) {
        var definition: { [key: string]: string } = {};
        if (endpointdefs) {
          endpointdefs.forEach((epdef) => {
            if (epdef.end_point_type == endpoint.end_point_type) {
              definition = epdef.definition;
            }
          });
        }
        var vals: any[] = [];
        for (let key in endpoint.meta) {
          if (key == "mapping") {
            let cameras = endpoint.meta[key];
            let newmappings: any[] = [];
            if (cameras) {
              for (let key in cameras) {
                let tmp: { [key: string]: any } = {};
                tmp[key] = cameras[key];
                newmappings.push(tmp);
              }
              setCameraMappings(newmappings);
            }
          } else if (key == "mappingtype") {
          } else {
            let thisvalue = endpoint.meta[key];
            let valueitem: EntryPointValues = new impEntryPointValues();
            valueitem.key = key;
            valueitem.value = thisvalue;
            valueitem.type = definition[key];
            vals.push(valueitem);
          }
        }
        setValues(vals);
        if (endpoint.users) {
          var usersendpointroles: impUserEndPointRoles[] = [];
          endpoint.users.forEach((user) => {
            var endpointroles: { [key: string]: boolean } = {};
            var userenpointroles = new impUserEndPointRoles();
            userenpointroles.key = Guid.create();
            userenpointroles.user_id = user.user_id;
            user.web_roles.forEach((role: any) => {
              endpointroles[role] = true;
            });
            userenpointroles.web_roles = endpointroles;
            usersendpointroles.push(userenpointroles);
          });
          setUserRuleEndpointValues(usersendpointroles);
        }
      }
    };
    setData();
  }, [open, renderValue]);
  useEffect(() => {
    const fetchData = async () => {
      setLoadingSpin(true);
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && userruleendpointvalues.length > 0) {
        var allusers: User[];
        if (groupid == -1 || groupid == 0) {
          allusers = await appStore?.functionService.getAllVisibleUsers(
            token,
            appStore?.authService.getLoggedInOrg(),
          );
        } else {
          allusers = await appStore?.functionService.getAllUsersForOrg(
            token,
            groupid,
          );
        }
        setUsers(allusers!);

        allusers?.map((user) => {
          let currentroles = userruleendpointvalues.find(
            (x) => x.user_id === user.user_id,
          );
          let isincurrentselected = newSelecteduserwebroles.find(
            (x) => x.user_id === user.user_id,
          );
          if (currentroles && !isincurrentselected) {
            let revisedwebrole = new newImpUserWebRoles();
            revisedwebrole.user_id = user.user_id;
            revisedwebrole.username = user.username;
            revisedwebrole.first_name = user.first_name;
            revisedwebrole.last_name = user.last_name;
            revisedwebrole.web_alerts = currentroles.web_roles["web_alerts"];
            revisedwebrole.web_alert_history =
              currentroles.web_roles["web_alert_history"];
            revisedwebrole.web_alert_camera_filter =
              currentroles.web_roles["web_alert_camera_filter"];
            revisedwebrole.web_alert_escalations =
              currentroles.web_roles["web_alert_escalations"];
            revisedwebrole.web_mute_controls =
              currentroles.web_roles["web_mute_controls"];
            revisedwebrole.web_bulk_actions =
              currentroles.web_roles["web_bulk_actions"];

            newSelecteduserwebroles.push(revisedwebrole);
          } else {
            if (groupid != 0 && !isincurrentselected) {
              let revisedwebrole = new newImpUserWebRoles();
              revisedwebrole.user_id = user.user_id;
              revisedwebrole.username = user.username;
              revisedwebrole.first_name = user.first_name;
              revisedwebrole.last_name = user.last_name;
              revisedwebrole.web_alerts = false;
              revisedwebrole.web_alert_escalations = false;
              revisedwebrole.web_alert_history = false;
              revisedwebrole.web_mute_controls = false;
              revisedwebrole.web_alert_camera_filter = false;
              revisedwebrole.web_bulk_actions = false;
              newuserwebroles.push(revisedwebrole);
            }
          }
        });

        setNewUserWebRoles(newuserwebroles);
        setNewSelectedUserWebRoles(newSelecteduserwebroles);
        setLoadingRows(false);
        setLoadingSpin(false);
        setRefresh(new Date().getTime());
      } else {
        setLoadingSpin(false);
      }
    };
    fetchData();
  }, [userruleendpointvalues, groupid, renderValue]);

  const selectUserHandler = async (user_id: number, channel_id: number) => {
    setLoadingSpin(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const fetchUserRolesRes: WebChannelResults =
        await appStore?.functionService.fetchUserRoles(
          token,
          currentOrg.org_id,
          user_id,
        );
      if (fetchUserRolesRes) {
        if (fetchUserRolesRes.success) {
          setUserChannelRoles(fetchUserRolesRes.data);
          let web_end_pos: string[];
          let other_roles = fetchUserRolesRes.data.other_web_end_point_roles;
          if (other_roles.length > 0) {
            web_end_pos = fetchUserRolesRes.data.other_web_end_point_roles;
          } else {
            web_end_pos = ["web_alerts"];
          }
          let web_end_point = web_end_pos.join();

          let alert_channel_ids = fetchUserRolesRes.data.alert_channel_ids;
          alert_channel_ids.push(channel_id.toString());
          const update_roles = await appStore?.functionService.updateUserRoles(
            token,
            currentOrg.org_id,
            user_id,
            web_end_point,
            alert_channel_ids.join(),
          );
          if (update_roles) {
            if (update_roles.success) {
              setRenderValue((value) => !value);
              setLoadingSpin(false);
              handleClose();
              setLoadingParent();
              forceRefresh();
            } else {
              setLoadingSpin(false);
              setLoadingParent();
            }
          }
        }
      }
    }
  };

  const [alertchannelmessage, setAlertchannelmessage] = useState("");
  const [trialValue, setTrialValue] = useState(false);
  const [changingValue, setChangingValue] = useState(0);
  const handleBotChange = (
    e: React.ChangeEvent<{ value: unknown }>,
    sanitised_bots: any,
  ) => {
    setTelegramBotId(e.target.value as number);
    let bot = e.target.value as number;
    let telegram_bot = sanitised_bots.props.children;
    let newvalues: any[] = [];
    values.forEach((valueitem: any) => {
      if (valueitem.key === "bot") {
        valueitem.value = bot;
      }
      if (valueitem.key === "telegram_bot") {
        valueitem.value = telegram_bot;
      }
      newvalues.push(valueitem);
    });
    setValues(newvalues);
  };
  const closeMessageModal = () => {
    setAlertchannelmessage("");
    onClose();
    setValues([]);
    setUserRuleEndpointValues([]);
    setNewUserWebRoles([]);
    setNewSelectedUserWebRoles([]);
    setGroupId(0);
    endpointtypes = [];
  };

  if (!users) {
    setLoadingParent();
  }

  const addUser = () => {
    const newuser = new impUserEndPointRoles();
    newuser.key = Guid.create();
    userruleendpointvalues.push(newuser);
    setUserRuleEndpointValues(userruleendpointvalues);
    setRefresh(new Date().getTime());
  };
  const handleClose = () => {
    onClose();
    setMessageModalOpen(false);
    setTrialValue(false);
    setValues([]);
    setUserRuleEndpointValues([]);
    setNewUserWebRoles([]);
    setNewSelectedUserWebRoles([]);
    setGroupId(0);
    endpointtypes = [];
  };
  const handleUpdate = () => {
    var definition: { [key: string]: string } = {};
    if (endpointdefs) {
      endpointdefs.forEach((epdef) => {
        if (epdef.end_point_type == endpoint.end_point_type) {
          definition = epdef.definition;
        }
      });
    }
    const unique =
      appStore?.functionService.translate(
        alertchannel.locale,
        "endpoint_names",
        endpointtype,
      ) + new Date().getTime().toString();
    var meta: { [key: string]: any } = {};
    values.forEach((valueitem) => {
      if (valueitem.type == "list") {
        let val: number = Number(valueitem.value);
        meta[valueitem.key] = val;
      } else if (valueitem.type == "boolean") {
        let val: boolean = valueitem.value;
        meta[valueitem.key] = val;
      } else if (
        valueitem.type == "number" ||
        valueitem.type == "integer" ||
        valueitem.type == "long"
      ) {
        let val: number = Number(valueitem.value);
        meta[valueitem.key] = val;
      } else {
        meta[valueitem.key] = valueitem.value;
      }
    });
    var mapping: { [key: string]: any } = {};
    var map = 0;
    cameramappings.forEach((cameramapping) => {
      for (let key in cameramapping) {
        if (definition["mappingtype"] == "integer") {
          mapping[key] = Number(cameramapping[key]);
        } else {
          mapping[key] = cameramapping[key];
        }
        map++;
      }
    });
    if (map > 0) {
      meta["mapping"] = mapping;
    }
    onClose();
    updateEndpoint(endpoint.alert_channel_end_point_id, meta);
    setEndPointType("");
    setValues([]);
    setUserRuleEndpointValues([]);
    endpointtypes = [];
    forceRefresh();
  };
  var buttons: React.ReactNode[] = [];
  var userruleendpoints: React.ReactNode[] = [];
  if (endpointtype == "user_app") {
    if (userruleendpointvalues.length == 0) {
      addUser();
    }
    var count = 0;
    userruleendpointvalues.forEach((value) => {
      var addbutton = false;
      if (count == userruleendpointvalues.length - 1) {
      }
      userruleendpoints.push(
        <div className={classes.form_component}>
          <UserRuleEndPoint
            addbutton={addbutton}
            users={users}
            value={value}
            handleUserRuleEndPointChange={(x: UserEndPointRoles) =>
              handleUserRuleEndPointChange(x)
            }
            endpointtype={endpointtype}
            appStore={appStore}
            alertchannel={alertchannel}
            addUser={() => addUser()}
          ></UserRuleEndPoint>
        </div>,
      );
      count++;
    });
    buttons.push(
      <div className={classes.button_container}>
        <Button
          variant="contained"
          className={classes.cancel_button}
          onClick={handleClose}
        >
          <span className={classes.bold_text}>CANCEL</span>
        </Button>
        <Tooltip
          placement="bottom"
          title="This type of delivery method cannot be updated, you have to delete and re-create it."
        >
          <Button
            variant="contained"
            className={classes.save_button}
            disabled={true}
          >
            <span className={classes.bold_text}>UPDATE</span>
          </Button>
        </Tooltip>
        <Typography className={classes.bold_text}>
          {
            "This type of delivery method cannot be updated, you have to delete and re-create it."
          }
        </Typography>
      </div>,
    );
  } else {
    buttons.push(
      <div className={classes.button_container}>
        <Button
          variant="contained"
          className={classes.cancel_button}
          onClick={handleClose}
        >
          <span className={classes.bold_text}>CANCEL</span>
        </Button>
        <Button
          variant="contained"
          className={classes.save_button}
          onClick={handleUpdate}
        >
          <span className={classes.bold_text}>UPDATE</span>
        </Button>
      </div>,
    );
  }
  var endpointtypes: React.ReactNode[] = [];

  const handleUserRuleEndPointChange = (
    userendpointrules: UserEndPointRoles,
  ) => {
    const currentforuser = userruleendpointvalues.find(
      (x) => x.key == userendpointrules.key,
    );
    if (currentforuser) {
      userruleendpointvalues[
        userruleendpointvalues.indexOf(currentforuser)
      ].web_roles = userendpointrules.web_roles;
      userruleendpointvalues[
        userruleendpointvalues.indexOf(currentforuser)
      ].user_id = userendpointrules.user_id;
    }
    const undefineduser = userruleendpointvalues.find(
      (x) => x.user_id == undefined,
    );
    if (undefineduser) {
      userruleendpointvalues.splice(
        userruleendpointvalues.indexOf(undefineduser),
        1,
      );
    }
    setUserRuleEndpointValues(userruleendpointvalues);
  };
  const handleEndPointTypeChange = (event: React.ChangeEvent<any>) => {
    setEndPointType(event.target.value);
    setValues([]);
    endpointtypes = [];
  };
  const handleCameraMappingsChange = (newvalues: any[]) => {
    setCameraMappings(newvalues);
  };

  const handleValueChange = (event: React.ChangeEvent<any>) => {
    let value = event.target.value;
    let key = event.target.name;
    let newvalues: any[] = [];
    values.forEach((valueitem: any) => {
      if (valueitem.key == key) {
        if (valueitem.type == "boolean") {
          valueitem.value = !valueitem.value;
        } else {
          valueitem.value = value;
        }
      }
      newvalues.push(valueitem);
    });
    setValues(newvalues);
  };

  if (appStore!.authService.getRoles().indexOf("site_admin") >= 0) {
    if (alertchannel) {
      const dummyObject = {} as DummyObj;

      var dummyArr: any[] = [];

      if (endpointdefs) {
        endpointdefs.forEach((endpointdef) => {
          let dummy_endpoint = endpointdef.end_point_type;
          const translate = appStore?.functionService.translate(
            alertchannel.locale,
            "endpoint_names",
            endpointdef.end_point_type,
          );
          dummyObject[dummy_endpoint] = translate;
          dummyArr[dummy_endpoint as any] = translate;
          // endpointtypes.push(<MenuItem value={endpointdef.end_point_type}><span className={classes.bold_text}>{translate}</span></MenuItem>);
        });
        const sortedArray = Object.values(dummyArr).sort();
        for (let val of Object.keys(sortedArray)) {
          endpointtypes.push(
            <MenuItem value={val}>
              <span className={classes.bold_text}>{[val]}</span>
            </MenuItem>,
          );
        }
      }
      var telegramendpointform: React.ReactNode[] = [];

      if (endpointtype && endpointtype === "telegram") {
        let sanitised_bots: { [key: number]: string } = {};
        if (isSysAdmin) {
          let tel_bots = telegramDefinition?.definition.bot;
          for (let key in tel_bots) {
            if (tel_bots.hasOwnProperty(key)) {
              sanitised_bots[Number(key)] = tel_bots[key];
            }
          }
        }
        telegramendpointform.push(
          <TextField
            name="Telegram Bot"
            label="Telegram Bot"
            type="text"
            disabled={true}
            value={telegramBotName}
            variant="outlined"
            margin="normal"
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />,
        );

        if (isSysAdmin) {
          telegramendpointform.push(
            <FormControl className={classes.form_component}>
              <InputLabel
                style={{ marginLeft: 15 }}
                className={classes.bold_text}
                id="test-select-label"
              >
                Change telegram bot
              </InputLabel>
              <Select
                variant="outlined"
                labelId="test-select-label"
                label="change telegram bot"
                name={sanitised_bots[telegramBotId]}
                error={selectError}
                value={telegramBotId}
                fullWidth
                disabled={!isSysAdmin}
                onChange={(e, sanitised_bots) =>
                  handleBotChange(e, sanitised_bots)
                }
              >
                {Object.entries(sanitised_bots).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>,
          );
        }

        telegramendpointform.push(
          <TextField
            label="Telegram Group ID"
            name="recipient_id"
            type="number"
            disabled={false}
            onChange={(e) => handleValueChange(e)}
            defaultValue={endpoint.meta["recipient_id"]}
            variant="outlined"
            margin="normal"
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />,
        );

        telegramendpointform.push(
          <TextField
            label="Telegram Group Name"
            name="recipient_name"
            type="text"
            disabled={false}
            onChange={(e) => handleValueChange(e)}
            defaultValue={endpoint.meta["recipient_name"]}
            variant="outlined"
            margin="normal"
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />,
        );
      }

      var endpointform: React.ReactNode[] = [];

      if (endpointtype && endpointtype != "" && endpointdefs) {
        let emptyvalues: any[] = [];
        endpointdefs.forEach((endpointdef) => {
          if (endpointdef.end_point_type == endpointtype) {
            const definition = endpointdef.definition;

            for (const key in definition) {
              if (key != "mappingtype") {
                const field = definition[key];
                const translate = appStore?.functionService.translate(
                  alertchannel.locale,
                  "endpoint_fields",
                  key,
                );

                let thisvalue = values.find((i) => i.key == key);
                if (field == "string") {
                  if (!thisvalue) {
                    thisvalue = { key: key, value: "", type: field };
                    emptyvalues.push(thisvalue);
                  }
                  endpointform.push(
                    <TextField
                      name={key}
                      label={translate}
                      type="text"
                      onChange={(e) => handleValueChange(e)}
                      value={thisvalue.value}
                      variant="outlined"
                      margin="normal"
                      autoComplete=""
                      fullWidth
                      InputProps={{
                        className: classes.bold_text,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.bold_text,
                        },
                      }}
                    />,
                  );
                } else if (
                  field == "number" ||
                  field == "integer" ||
                  field == "long"
                ) {
                  if (!thisvalue) {
                    thisvalue = { key: key, value: "", type: field };
                    emptyvalues.push(thisvalue);
                  }
                  let disabled = false;
                  if (key == "deviceno" && endpointtype == "listener") {
                    disabled = true;
                    thisvalue = {
                      key: key,
                      value: endpoint?.meta?.deviceno,
                      type: field,
                    };
                  } else if (key == "deviceno" && translate == "Site Number") {
                    disabled = true;
                    thisvalue.value = alertchannel.site_id;
                  }
                  endpointform.push(
                    <TextField
                      disabled={disabled}
                      name={key}
                      type="text"
                      label={translate}
                      onChange={(e) => handleValueChange(e)}
                      value={thisvalue.value}
                      variant="outlined"
                      margin="normal"
                      autoComplete=""
                      fullWidth
                      InputProps={{
                        className: classes.bold_text,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.bold_text,
                        },
                      }}
                    />,
                  );
                } else if (field == "password") {
                  if (!thisvalue) {
                    thisvalue = { key: key, value: "", type: field };
                    emptyvalues.push(thisvalue);
                  }
                  endpointform.push(
                    <TextField
                      name={key}
                      type="password"
                      label={translate}
                      onChange={(e) => handleValueChange(e)}
                      value={thisvalue.value}
                      variant="outlined"
                      margin="normal"
                      autoComplete=""
                      fullWidth
                      InputProps={{
                        className: classes.bold_text,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.bold_text,
                        },
                      }}
                    />,
                  );
                } else if (field == "camera_map") {
                  if (!thisvalue) {
                    thisvalue = { key: key, value: [], type: field };
                    emptyvalues.push(thisvalue);
                  }
                  endpointform.push(
                    <>
                      <br></br>
                      <FormLabel className={classes.bold_text}>
                        {translate + ":"}
                      </FormLabel>
                      <CameraMapping
                        mappingtype={definition["mappingtype"]}
                        disabled={false}
                        handleCameraMappingsChange={handleCameraMappingsChange}
                        sensors={sensors}
                        value={cameramappings}
                      />
                    </>,
                  );
                } else if (field == "boolean") {
                  if (!thisvalue) {
                    thisvalue = { key: key, value: false, type: field };
                    emptyvalues.push(thisvalue);
                  }
                  endpointform.push(
                    <FormControlLabel
                      className={classes.bold_text}
                      control={
                        <Checkbox
                          className={classes.form_component_checkbox}
                          name={key}
                          onChange={(e) => handleValueChange(e)}
                          checked={thisvalue.value}
                        />
                      }
                      label={translate}
                    />,
                  );
                } else {
                  //Assume a select option with different values
                  var items: React.ReactNode[] = [];
                  if (field) {
                    if (!thisvalue) {
                      thisvalue = { key: key, value: "", type: "list" };
                      emptyvalues.push(thisvalue);
                    }
                    var dict: {} = field;
                    for (const fkey in dict) {
                      const subfield = field[fkey];
                      items.push(
                        <MenuItem value={fkey}>
                          <span className={classes.bold_text}>{subfield}</span>
                        </MenuItem>,
                      );
                    }
                    endpointform.push(
                      <FormControl className={classes.form_component}>
                        <InputLabel className={classes.bold_text}>
                          {translate}
                        </InputLabel>
                        <Select
                          name={key}
                          value={thisvalue.value}
                          onChange={(e) => handleValueChange(e)}
                        >
                          {items}
                        </Select>
                      </FormControl>,
                    );
                  }
                }
                endpointform.push(<br></br>);
              }
            }
          }
        });
        if (values.length == 0 && emptyvalues.length > 0) {
          setValues(emptyvalues);
        }
      }
      if (endpointtype == "web") {
        var otherorgs: React.ReactNode[] = [];
        otherorgs.push(
          <MenuItem value={-1}>
            <span className={classes.bold_text}>All Users</span>
          </MenuItem>,
        );
        if (orgslist && orgslist.length > 0) {
          orgslist.forEach((org) => {
            otherorgs.push(
              <MenuItem value={org.org_id}>
                <span className={classes.bold_text}>{org.org_name}</span>
              </MenuItem>,
            );
          });
        }

        var newrows: NewData[] = [];
        let count = 0;
        if (newuserwebroles && newuserwebroles.length > 0) {
          newuserwebroles.forEach((userwebrole) => {
            newrows.push(
              newCreateData(
                userwebrole.user_id,
                userwebrole.username,
                userwebrole.first_name,
                userwebrole.last_name,
                userwebrole.web_alerts,
                userwebrole.web_alert_escalations,
                userwebrole.web_mute_controls,
                userwebrole.web_alert_camera_filter,
                userwebrole.web_alert_history,
                userwebrole.web_bulk_actions,
              ),
            );
          });
        }

        var newseletedrows: NewData[] = [];
        if (newSelecteduserwebroles && newSelecteduserwebroles.length > 0) {
          newSelecteduserwebroles.forEach((userwebrole) => {
            newseletedrows.push(
              newCreateData(
                userwebrole.user_id,
                userwebrole.username,
                userwebrole.first_name,
                userwebrole.last_name,
                userwebrole.web_alerts,
                userwebrole.web_alert_escalations,
                userwebrole.web_mute_controls,
                userwebrole.web_alert_camera_filter,
                userwebrole.web_alert_history,
                userwebrole.web_bulk_actions,
              ),
            );
          });
        }
        if (newseletedrows.length > 0 && !loadingrows) {
          return (
            <Dialog
              onClose={handleClose}
              className={classes.large_modal_dialog}
              open={open}
              fullWidth={true}
              maxWidth={"lg"}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <DialogTitle>
                  <span
                    className={classes.bold_text}
                    style={{ color: "color", fontSize: 20 }}
                  >
                    Alert Web Interface for {alertchannel.name}
                  </span>
                </DialogTitle>
                <Typography
                  align="center"
                  className={classes.header_text_error}
                >
                  {message}
                </Typography>
                <TableContainer component={Paper}>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Username</TableCell>
                        <TableCell>Firstname</TableCell>
                        <TableCell>Lastname</TableCell>
                        <TableCell>web alerts</TableCell>
                        <TableCell>web alert escalations </TableCell>
                        <TableCell>web mute controls</TableCell>
                        <TableCell>web alert camera filter</TableCell>
                        <TableCell>web alert history</TableCell>
                        <TableCell>web bulk actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {newseletedrows.map((seletedrow) => (
                        <TableRow key={seletedrow.user_id}>
                          <TableCell component="th" scope="row">
                            {seletedrow.username}
                          </TableCell>
                          <TableCell>{seletedrow.first_name}</TableCell>
                          <TableCell>{seletedrow.last_name}</TableCell>
                          <TableCell>
                            <Checkbox
                              disabled={true}
                              checked={seletedrow.web_alerts}
                              inputProps={{ "aria-label": "web_alerts" }}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              disabled={true}
                              checked={seletedrow.web_alert_escalations}
                              inputProps={{
                                "aria-label": "web_alert_escalations",
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              disabled={true}
                              checked={seletedrow.web_mute_controls}
                              inputProps={{ "aria-label": "web_mute_controls" }}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              disabled={true}
                              checked={seletedrow.web_alert_camera_filter}
                              inputProps={{
                                "aria-label": "web_alert_camera_filter",
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              disabled={true}
                              checked={seletedrow.web_alert_history}
                              inputProps={{ "aria-label": "web_alert_history" }}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              disabled={true}
                              checked={seletedrow.web_bulk_actions}
                              inputProps={{ "aria-label": "web_bulk_actions" }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br></br>
                {/* {orgUsers.length > 0 ? (
                  <>
                    <Typography
                      align='center'
                      style={{ color: 'red' }}
                      className={classes.bold_text}
                    >
                      Please select a user below to add to the web alert
                      endpoint
                    </Typography>
                    <br></br>
                  </>
                ) : null} */}

                {loadingSpin !== true ? (
                  <></>
                ) : (
                  // <TableContainer>
                  //   <Table size='small' stickyHeader>
                  //     <TableHead>
                  //       <TableCell>Username</TableCell>
                  //       <TableCell>Firstname</TableCell>
                  //       <TableCell>Lastname</TableCell>
                  //       <TableCell>Email</TableCell>
                  //     </TableHead>
                  //     <TableBody>
                  //       {orgUsers &&
                  //         orgUsers.map((user, index) => {
                  //           console.debug(user.end_point_roles);
                  //           return (
                  //             <StyledTableRow
                  //               key={user.user_id}
                  //               onClick={() =>
                  //                 selectUserHandler(
                  //                   user.user_id,
                  //                   alertchannel.alert_channel_id
                  //                 )
                  //               }
                  //               style={{ height: 30 }}
                  //               className={classes.tableRow}
                  //             >
                  //               <TableCell>
                  //                 <Typography className={classes.bold_text}>
                  //                   <br></br>
                  //                   {user.username}
                  //                   <br></br>
                  //                 </Typography>
                  //               </TableCell>
                  //               <TableCell>
                  //                 <Typography className={classes.bold_text}>
                  //                   <br></br>
                  //                   {user.first_name}
                  //                   <br></br>
                  //                 </Typography>
                  //               </TableCell>
                  //               <TableCell>
                  //                 <Typography className={classes.bold_text}>
                  //                   <br></br>
                  //                   {user.last_name}
                  //                   <br></br>
                  //                 </Typography>
                  //               </TableCell>
                  //               <TableCell>
                  //                 <Typography className={classes.bold_text}>
                  //                   <br></br>
                  //                   {user.email}
                  //                   <br></br>
                  //                 </Typography>
                  //               </TableCell>
                  //               <TableCell>{user.end_point_roles}</TableCell>
                  //             </StyledTableRow>
                  //           );
                  //         })}
                  //     </TableBody>
                  //   </Table>
                  // </TableContainer>
                  <div className="wait-container">
                    <svg width="48px" height="48px" viewBox="0 0 128 128">
                      <g>
                        <linearGradient id="linear-gradient">
                          <stop offset="0%" stop-color="#ffffff" />
                          <stop offset="100%" stop-color="#22305f" />
                        </linearGradient>
                        <path
                          d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                          fill="url(#linear-gradient)"
                          fill-rule="evenodd"
                        />
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 64 64"
                          to="360 64 64"
                          dur="1080ms"
                          repeatCount="indefinite"
                        ></animateTransform>
                      </g>
                    </svg>
                  </div>
                )}
              </div>

              <div className={classes.button_container}>
                <Button
                  variant="contained"
                  className={classes.cancel_button}
                  onClick={handleClose}
                >
                  <span className={classes.bold_text}>Dismiss</span>
                </Button>
              </div>
            </Dialog>
          );
        } else {
          return (
            <Dialog
              onClose={handleClose}
              className={classes.modal_dialog}
              open={open}
              maxWidth="lg"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <DialogTitle>
                  <span
                    className={classes.bold_text}
                    style={{ color: "blue", fontSize: 20 }}
                  >
                    Alert Web Interface for {alertchannel.name}
                  </span>
                </DialogTitle>
                <Typography
                  align="center"
                  className={classes.bold_text}
                  color="error"
                ></Typography>
                <TableContainer component={Paper}>
                  {loadingSpin !== true ? (
                    <>
                      <Typography
                        align="center"
                        className={classes.bold_text}
                        color="error"
                      >
                        {" "}
                        No users found for this alert web endpoint
                      </Typography>
                      <Typography className={classes.bold_text} color="error">
                        {" "}
                      </Typography>
                      {/* <Typography className={classes.bold_text} color='error'>
                      User assignment is performed in the Alerting section of
                      the menu items
                    </Typography> */}
                      {/* {orgUsers.length > 0 ? (
                        <>
                          <Typography
                            align='center'
                            style={{ color: 'red' }}
                            className={classes.bold_text}
                          >
                            Please select a user below to add to the web alert
                            endpoint
                          </Typography>
                          <br></br>
                        </>
                      ) : null} */}

                      {/* <TableContainer>
                        <Table size='small' stickyHeader>
                          <TableHead>
                            <TableCell>Username</TableCell>
                            <TableCell>Firstname</TableCell>
                            <TableCell>Lastname</TableCell>
                            <TableCell>Email</TableCell>
                          </TableHead>
                          <TableBody>
                            {orgUsers &&
                              orgUsers.map((user, index) => {
                                console.debug(user.end_point_roles);
                                return (
                                  <StyledTableRow
                                    key={user.user_id}
                                    onClick={() =>
                                      selectUserHandler(
                                        user.user_id,
                                        alertchannel.alert_channel_id
                                      )
                                    }
                                    style={{ height: 30 }}
                                    className={classes.tableRow}
                                  >
                                    <TableCell>
                                      <Typography className={classes.bold_text}>
                                        <br></br>
                                        {user.username}
                                        <br></br>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography className={classes.bold_text}>
                                        <br></br>
                                        {user.first_name}
                                        <br></br>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography className={classes.bold_text}>
                                        <br></br>
                                        {user.last_name}
                                        <br></br>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography className={classes.bold_text}>
                                        <br></br>
                                        {user.email}
                                        <br></br>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {user.end_point_roles}
                                    </TableCell>
                                  </StyledTableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer> */}
                    </>
                  ) : (
                    <div className="wait-container">
                      <svg width="48px" height="48px" viewBox="0 0 128 128">
                        <g>
                          <linearGradient id="linear-gradient">
                            <stop offset="0%" stop-color="#ffffff" />
                            <stop offset="100%" stop-color="#22305f" />
                          </linearGradient>
                          <path
                            d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                            fill="url(#linear-gradient)"
                            fill-rule="evenodd"
                          />
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 64 64"
                            to="360 64 64"
                            dur="1080ms"
                            repeatCount="indefinite"
                          ></animateTransform>
                        </g>
                      </svg>
                    </div>
                  )}
                </TableContainer>
              </div>

              <div className={classes.button_container}>
                <Button
                  variant="contained"
                  className={classes.cancel_button}
                  onClick={handleClose}
                >
                  <span className={classes.bold_text}>Dismiss</span>
                </Button>
              </div>
            </Dialog>
          );
        }
      } else {
        return (
          <Dialog
            onClose={handleClose}
            className={classes.modal_dialog}
            open={open}
          >
            <DialogTitle>
              <Typography
                className={classes.bold_text_edit_modal_header}
                variant="h5"
              >
                Edit Alert Endpoint for {alertchannel.name}
              </Typography>
            </DialogTitle>
            <Typography className={classes.bold_text} color="error">
              {message}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "12px",
                width: "70%",
              }}
            >
              <Typography
                style={{ fontSize: 14 }}
                className={classes.bold_text}
                color="error"
              >
                Endpoint Type :{" "}
              </Typography>
              <Typography
                style={{ fontSize: 14 }}
                className={classes.bold_text}
                color="error"
              >
                {endpointtype}
              </Typography>
            </div>
            {endpointtype === "telegram" && telegramendpointform}
            {endpointtype !== "telegram" && endpointform}
            {userruleendpoints}
            {buttons}
          </Dialog>
        );
      }
    } else {
      return <div></div>;
    }
  } else {
    return (
      <Dialog onClose={onClose} open={open} className={classes.modal_dialog}>
        <DialogTitle className={classes.bold_text}>
          Edit Alert Endpoint for {alertchannel.name}
        </DialogTitle>
        <br></br>
        <Typography className={classes.bold_text}>
          {
            "You need site admin role to be able to edit a Delivery Endpoint - please contact your DeepAlert system administrator"
          }
        </Typography>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
        </div>
      </Dialog>
    );
  }
};

export default EditAlertChannelEndpointModal;
