import React, { useState, useEffect } from "react";
import { AppStore } from "../../../stores/AppStore";
import { Org } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import MessageModal from "../../modals/MessageModal/MessageModal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import RuleSetsAssignment from "./RuleSetsAssignment";
import SiteAssignment from "./SiteAssignment";
import RoleAssignment from "./RoleAssignment";
import GroupAssignment from "./GroupAssignment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
interface RulesetsMainDrawerProps {
  appStore?: AppStore;
}

// Tabs Data and function
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const theme = createMuiTheme({
  overrides: {
    MuiTab: {
      root: {
        "&.Mui-selected": {
          background: "rgba(255,255,255,0.09)",
          color: "white",
          // fontSize: 15,
          fontWeight: "bold",
          // textTransform: 'capitalize',
          // opacity: 0.7,
        },
      },
    },
  },
});

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// end

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

//This class is to arm/disarm big selections of rulesets at the same time

const RulesetsMainDrawer: React.FC<RulesetsMainDrawerProps> = ({
  appStore,
}: RulesetsMainDrawerProps) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [alertchannels, setAlertChannels] = useState<
    { [key: string]: any } | undefined
  >(undefined);
  const [selectedchannels, setSelectedChannels] = useState<number[]>([]);
  const [refresh, setRefresh] = useState(0);
  const [message, setMessage] = useState("");
  const [orgslist, setOrgsList] = React.useState<any>([]);
  const [groupid, setGroupId] = React.useState(-1);
  const [groupname, setGroupName] = React.useState("");
  const [subgroups, setSubGroups] = React.useState(true);
  const [reload, setReload] = useState(0);
  const [actionid, setActionId] = React.useState("PLEASE");

  let userAdminAccess =
    appStore!.authService!.getRoles()!.indexOf("user_admin") >= 0;
  const [value, setValue] = React.useState(userAdminAccess ? 0 : 1);
  const [subValue, setSubValue] = React.useState(0);

  const styler = useStyle();

  //MessageModal
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };

  //Tabsmenu
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const handleSubChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSubValue(newValue);
  };

  const closeMessageModal = () => {
    setMessage("");
    setMessageModalOpen(false);
    setReload(new Date().getTime());
  };

  const getBaseUrl = (): string => {
    let base = "";
    if (window.location.href.includes("local")) {
      base = "https://manage.staging.deepalert.ai";
    }
    base += "/api/v1.3";
    return base;
  };

  //*************Fetching Data Functions*************/
  // 1) Fetching Organizations
  useEffect(() => {
    const fetchData = async () => {
      var baseUrl = getBaseUrl();
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        // Fetch Start
        try {
          const request = JSON.stringify({
            enabled: true,
            paginate: false,
            verbose: false,
            incl_tree_names: true,
          });

          const res = fetch(baseUrl + "/list_orgs", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });
          const result = await (await res).json();
          const orglist = result;
          if (orglist && orglist.success) {
            let orgz = orglist?.orgs;
            orgz.sort(function (a: any, b: any) {
              return a.org_tree_names - b.org_tree_names;
            });
            orgz.sort(function (a: any, b: any) {
              return a.org_tree_names.length - b.org_tree_names.length;
            });
            setOrgsList(orgz);
            setLoading(false);
          }
        } catch (err) {
          return err;
        }

        //Fetch end
      }
    };
    fetchData();
  }, []);

  const handleChangeAuto = (value: any) => {
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setGroupName(group.org_name);
    }
  };

  const fetchData = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && groupid >= 0) {
      setLoading(true);
      const rulesets: { [key: string]: any }[] =
        await appStore?.functionService.getAlertChannelsForUser(
          token,
          groupid,
          groupid === 0 ? false : !subgroups,
        );
      if (rulesets && rulesets.length > 0) {
        let newalertchannels: { [key: string]: any }[] = [];
        rulesets.forEach((ruleset) => {
          newalertchannels.push(ruleset);
        });

        setAlertChannels(newalertchannels);
        setRefresh(new Date().getTime());
      } else {
        setAlertChannels([]);
        setRefresh(new Date().getTime());
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [groupid, subgroups]);

  const handleChannelChange = (channel: { [key: string]: any }) => {
    let index = selectedchannels.indexOf(channel.id);
    if (index >= 0) {
      selectedchannels.splice(index, 1);
    } else {
      selectedchannels.push(channel.id);
    }
    setSelectedChannels(selectedchannels);
    setRefresh(new Date().getTime());
  };
  const handleSelectAll = () => {
    let newselectedchannels: number[] = [];
    alertchannels?.forEach((alertchannel: { [key: string]: any }) => {
      newselectedchannels.push(alertchannel.id);
    });
    setSelectedChannels(newselectedchannels);
    setRefresh(new Date().getTime());
  };
  const handleUnselectAll = () => {
    let ch: number[] = [];
    setSelectedChannels(ch);
  };
  const handleArm = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      let set_arm = await appStore?.functionService.setArm(
        token,
        selectedchannels,
        "manual_arm",
        0,
      );
      if (set_arm) {
        if (set_arm.success) {
          if (selectedchannels.length === 1) {
            setMessage(
              selectedchannels.length +
                " Site armed (please allow a couple of minutes to propagate)",
            );
          } else {
            setMessage(
              selectedchannels.length +
                " Sites armed (please allow a couple of minutes to propagate)",
            );
          }
          fetchData();
          handleUnselectAll();
        } else {
          setMessage(set_arm.msg);
        }
        openMessageModal();
      }
      // setLoading(false);
    }
  };
  const handleDisArm = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      let set_arm = await appStore?.functionService.setArm(
        token,
        selectedchannels,
        "disarm",
        0,
      );
      if (set_arm) {
        if (set_arm.success) {
          if (selectedchannels.length === 1) {
            setMessage(
              selectedchannels.length +
                " Site disarmed (please allow a couple of minutes to propagate)",
            );
          } else {
            setMessage(
              selectedchannels.length +
                " Sites disarmed (please allow a couple of minutes to propagate)",
            );
          }
          fetchData();
          handleUnselectAll();
        } else {
          setMessage(set_arm.msg);
        }
        openMessageModal();
      }
      setLoading(false);
    }
  };

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  const handleArmOnSchelude = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      let set_arm = await appStore?.functionService.setArm(
        token,
        selectedchannels,
        "arm_on_schedule",
        0,
      );
      if (set_arm) {
        if (set_arm.success) {
          if (selectedchannels.length === 1) {
            setMessage(
              selectedchannels.length +
                " Site armed on schedule (please allow a couple of minutes to propagate)",
            );
          } else {
            setMessage(
              selectedchannels.length +
                " Sites armed on schedule (please allow a couple of minutes to propagate)",
            );
          }
          fetchData();
          handleUnselectAll();
        } else {
          setMessage(set_arm.msg);
        }
        openMessageModal();
      }
      setLoading(false);
    }
  };
  const handleChangeGroup = (event: React.ChangeEvent<any>) => {
    let new_group_id = event.target.value;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id == new_group_id);
    if (group) {
      setGroupName(group.org_name);
    }
  };
  const handleChangeAction = (event: React.ChangeEvent<any>) => {
    setActionId(event.target.value);
  };
  const applyAction = () => {
    switch (actionid) {
      case "ARM":
        handleArm();
        break;
      case "DISARM":
        handleDisArm();
        break;
      case "SCHEDULE":
        handleArmOnSchelude();
        break;
      default:
        break;
    }
  };
  const handleSubGroupsChange = () => {
    setSubGroups(!subgroups);
  };
  var alertchanneldisplay: React.ReactNode[] = [];
  if (alertchannels && alertchannels.length > 0) {
    let ch = alertchannels.sort((a: any, b: any) => {
      return a.org_tree_names.length - b.org_tree_names.length;
    });
    ch.forEach((alertchannel: { [key: string]: any }, index: any) => {
      let channelchecked =
        selectedchannels.indexOf(alertchannel.id) >= 0 ? true : false;
      let norules = undefined;
      if (
        !alertchannel.manual_arm &&
        !alertchannel.manual_disarmed &&
        !alertchannel.armed_on_schedule
      ) {
        norules = "No Alert Rules";
      }

      let tree = alertchannel.org_tree_names!.map((item: any) => ` / ${item} `);
      let treeString = tree.toString();
      const rpl = treeString.replace(/,/g, "");

      alertchanneldisplay.push(
        <StyledTableRow>
          <TableCell>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  color="primary"
                  checked={channelchecked}
                  onChange={() => handleChannelChange(alertchannel)}
                />
              }
              label={""}
            />
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>{rpl}</Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {alertchannel.site_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {alertchannel.manual_arm ? (
                <DoneOutlineIcon style={{ fill: "#ee4623" }} />
              ) : undefined}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {alertchannel.manual_disarmed ? (
                <DoneOutlineIcon style={{ fill: "#ee4623" }} />
              ) : undefined}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {alertchannel.armed_on_schedule ? (
                <CheckIcon style={{ fill: "#3f54a5" }} />
              ) : (
                norules
              )}
            </Typography>
          </TableCell>
        </StyledTableRow>,
      );
    });
  }
  var otherorgs: React.ReactNode[] = [];
  otherorgs.push(
    <MenuItem value={-1}>
      <span className={classes.bold_text}>Please Select</span>
    </MenuItem>,
  );
  otherorgs.push(
    <MenuItem value={0}>
      <span className={classes.bold_text}>All Alert Rules</span>
    </MenuItem>,
  );
  if (orgslist && orgslist.length > 0) {
    orgslist.forEach((org: any) => {
      otherorgs.push(
        <MenuItem value={org.org_id}>
          <span className={classes.bold_text}>{org.org_name}</span>
        </MenuItem>,
      );
    });
  }

  const rolesAssignment = (
    <>
      <h3
        className={classes.bold_text}
        style={{
          textAlign: "center",
          fontSize: 16,
          textTransform: "capitalize",
          marginBottom: 20,
        }}
      >
        <>User Alert Web Role Assignment </>
      </h3>
      <Divider style={{ marginBottom: 20 }} />
      <RoleAssignment appStore={appStore} />
    </>
  );

  const groupAssignment = (
    <>
      <h3
        className={classes.bold_text}
        style={{
          textAlign: "center",
          fontSize: 16,
          textTransform: "capitalize",
          marginBottom: 20,
        }}
      >
        <>User Alert Web Group Assignment</>
      </h3>
      <Divider style={{ marginBottom: 20 }} />
      <GroupAssignment appStore={appStore} />
    </>
  );

  const siteAssignment = (
    <>
      <h3
        className={classes.bold_text}
        style={{
          textAlign: "center",
          fontSize: 16,
          textTransform: "capitalize",
          marginBottom: 20,
        }}
      >
        <>User Alert Web Site Assignment</>
      </h3>
      <Divider style={{ marginBottom: 20 }} />
      <SiteAssignment appStore={appStore} />
      {/* <RuleSetsAssignment appStore={appStore} /> */}
    </>
  );

  if (loading) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <AppBar
            position="static"
            className="navcolor"
            // className={classes.indicator}
            style={{ marginBottom: 20, marginTop: 40 }}
          >
            <Tabs
              indicatorColor="secondary"
              textColor="inherit"
              value={value}
              onChange={handleChange}
              // className={classes.indicator}
              // centered
            >
              {/* {tabsmenu} */}
              {userAdminAccess && (
                <Tab label=" Alert Web Interface" {...a11yProps(0)} />
              )}
              <Tab label="Alert Arming" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={1}>
            <h3
              className={classes.bold_text}
              style={{
                textAlign: "center",
                fontSize: 16,
                textTransform: "capitalize",
                marginBottom: 20,
              }}
            >
              <>ARM CONTROLS FOR SITES</>
            </h3>
            <Divider style={{ marginBottom: 20 }} />
          </TabPanel>
        </ThemeProvider>
        <Container maxWidth="md">
          <br />
          <br />
          <div className="wait-container">
            <svg width="48px" height="48px" viewBox="0 0 128 128">
              <g>
                <linearGradient id="linear-gradient">
                  <stop offset="0%" stop-color="#ffffff" />
                  <stop offset="100%" stop-color="#22305f" />
                </linearGradient>
                <path
                  d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                  fill="url(#linear-gradient)"
                  fill-rule="evenodd"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 64 64"
                  to="360 64 64"
                  dur="1080ms"
                  repeatCount="indefinite"
                ></animateTransform>
              </g>
            </svg>
          </div>
        </Container>
      </>
    );
  } else {
    return (
      <div className="main-drawer-container">
        {/* <ThemeProvider theme={theme}> */}
        {/********************** TABS FOR NAVIGATION *********************************/}
        <ThemeProvider theme={theme}>
          <AppBar
            position="static"
            className="navcolor"
            style={{ marginBottom: 20, marginTop: 40, minWidth: 1200 }}
            // style={{ marginBottom: 20, color: 'red' }}
          >
            <Tabs
              indicatorColor="secondary"
              textColor="inherit"
              value={value}
              onChange={handleChange}
              style={{ color: "white", minWidth: 1200 }}
              // centered
            >
              {/* {tabsmenu} */}
              {userAdminAccess && (
                <Tab label=" Alert Web Interface" {...a11yProps(0)} />
              )}
              <Tab label="Alert Arming" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </ThemeProvider>
        <TabPanel value={value} index={1}>
          <h3
            className={classes.bold_text}
            style={{
              textAlign: "center",
              fontSize: 16,
              textTransform: "capitalize",
              marginBottom: 20,
            }}
          >
            <>ARM CONTROLS FOR SITES </>
          </h3>
          <Divider style={{ marginBottom: 20 }} />
          <FormControl
            className={classes.form_component_medium}
            variant="outlined"
            fullWidth={false}
          >
            <InputLabel className={classes.bold_text}>
              Select Group to Filter Sites
            </InputLabel>
            <br></br>
            <br></br>

            {/* Auto Complete Form */}
            <Autocomplete
              id="combo-box-demo"
              className={classes.form_component_medium}
              options={orgslist}
              onChange={(event: React.ChangeEvent<any>, value: any) => {
                handleChangeAuto(value);
              }}
              getOptionLabel={(option: any) => {
                let tree = option.org_tree_names!.map(
                  (item: any) => `   /   ${item} `,
                );
                let treeString = tree.toString();
                const rpl = treeString.replace(/,/g, "");
                return `${rpl}`;
              }}
              style={{ width: 600, color: "#6D809D" }}
              classes={{ paper: styler.paper }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span
                      className={classes.bold_text}
                      style={{ color: "#15325F" }}
                    >
                      {groupname ? groupname : "Please Select Group"}
                    </span>
                  }
                  variant="outlined"
                  value={groupid}
                  className={classes.bold_text}
                />
              )}
            />
          </FormControl>

          <FormControl>
            <Typography className={classes.header_text}>
              Include Sites in Sub-Groups
            </Typography>
          </FormControl>
          <FormControl>
            <Checkbox
              className={classes.form_component_checkbox}
              color="primary"
              icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
              checkedIcon={<CheckBoxIcon fontSize="large" />}
              checked={subgroups}
              onChange={() => handleSubGroupsChange()}
            />
          </FormControl>
          <MessageModal
            open={messagemodalopen}
            onClose={closeMessageModal}
            message={message}
            elements={undefined}
          />
          <Divider />
          <div className={classes.shelf_item_top}>
            <Tooltip title="Select all" aria-label="add">
              <div
                className={classes.pointer_top_margin}
                onClick={handleSelectAll}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.53 11.06L15.47 10l-4.88 4.88-2.12-2.12-1.06 1.06L10.59 17l5.94-5.94z"
                    fill={"#15325F"}
                    fillOpacity="0.9"
                  />
                  <path
                    d="M19 4H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H5V6h14v12z"
                    fill={"#15325F"}
                    fillOpacity="0.9"
                  />
                </svg>
              </div>
            </Tooltip>
            <Tooltip title="Unselect all" aria-label="add">
              <div
                className={classes.pointer_top_margin}
                onClick={handleUnselectAll}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 4H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H5V6h14v12z"
                    fill={"#15325F"}
                    fillOpacity="0.9"
                  />
                </svg>
              </div>
            </Tooltip>
            <FormControl
              className={classes.form_component_medium}
              variant="outlined"
              fullWidth={false}
            >
              <InputLabel className={classes.bold_text}>Action</InputLabel>
              <Select value={actionid} onChange={handleChangeAction}>
                <MenuItem value={"PLEASE"}>
                  <span className={classes.bold_text}>Please Select</span>
                </MenuItem>
                <MenuItem value={"ARM"}>
                  <span className={classes.bold_text}>Manual Arm</span>
                </MenuItem>
                <MenuItem value={"DISARM"}>
                  <span className={classes.bold_text}>Disarm Completely</span>
                </MenuItem>
                <MenuItem value={"SCHEDULE"}>
                  <span className={classes.bold_text}>Arm on schedule</span>
                </MenuItem>
              </Select>
            </FormControl>
            <Button
              className={classes.cancel_button_top_margin}
              variant="contained"
              onClick={applyAction}
              disabled={selectedchannels.length === 0 || actionid === "PLEASE"}
            >
              <span className={classes.bold_text}>APPLY</span>
            </Button>
          </div>
          <div className={classes.space_smaller}></div>
          <Divider />
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              marginTop: 15,
              borderRadius: 15,
              overflow: "hidden",
            }}
          >
            <TableContainer component={Paper}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Select</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Site</TableCell>
                    {/* <TableCell>Ruleset</TableCell> */}
                    <TableCell>Manually Armed</TableCell>
                    <TableCell>Disarmed</TableCell>
                    <TableCell>Armed on Schedule (Default)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{alertchanneldisplay}</TableBody>
              </Table>
            </TableContainer>
          </div>
        </TabPanel>
        <TabPanel value={value} index={0}>
          <ThemeProvider theme={theme}>
            <AppBar
              position="static"
              className="navcolor"
              style={{ marginBottom: 20, marginTop: 0, width: "90%" }}
              // style={{ marginBottom: 20, color: 'red' }}
            >
              <Tabs
                indicatorColor="secondary"
                textColor="inherit"
                value={subValue}
                onChange={handleSubChange}
                style={{ color: "white", minWidth: 1200 }}
              >
                <Tab label="Roles" {...a11yProps(0)} />
                <Tab label="Groups" {...a11yProps(1)} />
                <Tab label="Sites" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
          </ThemeProvider>
          <TabPanel value={subValue} index={0}>
            {rolesAssignment}
          </TabPanel>
          <TabPanel value={subValue} index={1}>
            {groupAssignment}
          </TabPanel>
          <TabPanel value={subValue} index={2}>
            {siteAssignment}
          </TabPanel>
        </TabPanel>
        {/* </ThemeProvider> */}
      </div>
    );
  }
};
export default RulesetsMainDrawer;
