import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { useStyles } from "../../styling/global/global";
import Divider from "@material-ui/core/Divider";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  test_mail: any[] | undefined;
  unknown_email: any[] | undefined;
  open_email: boolean;
  close_email: () => void;
}

export const SmtpEmails: React.FC<Props> = ({
  test_mail,
  unknown_email,
  open_email,
  close_email,
}) => {
  const [open, setOpen] = React.useState<boolean>(open_email);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    close_email();
  };

  return (
    <div>
      <Dialog
        open={open_email}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {unknown_email && unknown_email!.length > 0 && (
            <>
              {unknown_email!.map((item) => (
                <div className={classes.hover}>
                  <br></br>
                  <h3
                    id="alert-dialog-slide-title"
                    style={{
                      // textDecoration: "underline",
                      color: "#15325F",
                    }}
                  >
                    {item.title === "unknown_email_format" &&
                      item.image_count > 0 &&
                      "UNSUPPORTED SMTP EMAIL FORMAT --> CONTACT SUPPORT"}
                    {/* {item.title === "unknown_email_format" && item.image_count === 0 && "UNSUPPORTED SMTP EMAIL FORMAT -> CONTACT SUPPORT (ALSO IMAGES MISSING)"} */}
                    {item.title === "known_email_format" &&
                      item.image_count === 0 &&
                      "SMTP EMAIL FORMAT RECOGNISED (IMAGES MISSING)"}
                  </h3>
                  {item.title === "unknown_email_format" &&
                    item.image_count === 0 && (
                      <>
                        <h3
                          id="alert-dialog-slide-title"
                          style={{
                            // textDecoration: "underline",
                            color: "#15325F",
                          }}
                        >
                          {"UNSUPPORTED SMTP EMAIL FORMAT --> CONTACT SUPPORT"}
                        </h3>
                        <h5
                          id="alert-dialog-slide-title"
                          // style={{ marginLeft: 20 }}
                          className={classes.bold_text}
                        >
                          (ALSO IMAGES MISSING)
                        </h5>
                      </>
                    )}
                  <div style={{ display: "flex" }}>
                    <h5
                      id="alert-dialog-slide-title"
                      className={classes.bold_text}
                    >
                      {"HUB ID: "}{" "}
                      <span style={{ color: "grey" }}>{item.hub_id}</span>
                    </h5>
                    <h5
                      id="alert-dialog-slide-title"
                      style={{ marginLeft: 20 }}
                      className={classes.bold_text}
                    >
                      {"CREATED AT: "}{" "}
                      <span style={{ color: "grey" }}>
                        {item.created_time} (UTC)
                      </span>
                    </h5>
                    <h5
                      id="alert-dialog-slide-title"
                      style={{ marginLeft: 20 }}
                      className={classes.bold_text}
                    >
                      {"IMAGE COUNT: "}{" "}
                      <span style={{ color: "grey" }}>{item.image_count}</span>
                    </h5>
                  </div>
                  {/* <DialogContent> */}
                  <DialogContentText id="alert-dialog-slide-description">
                    {item.content!.map((mail: string) => {
                      return <p className={classes.hover2}>{mail}</p>;
                    })}
                  </DialogContentText>
                  {/* </DialogContent> */}
                  <Divider />
                </div>
              ))}
            </>
          )}
          {test_mail && test_mail!.length > 0 && (
            <>
              {test_mail.map((item) => (
                <div className={classes.hover}>
                  <br></br>
                  <h3
                    id="alert-dialog-slide-title"
                    style={{
                      // textDecoration: "underline",
                      color: "#15325F",
                    }}
                  >
                    {"SMTP TEST EMAIL"}
                  </h3>
                  <div style={{ display: "flex" }}>
                    <h5
                      id="alert-dialog-slide-title"
                      className={classes.bold_text}
                    >
                      {"HUB ID: "}{" "}
                      <span style={{ color: "grey" }}>{item.hub_id}</span>
                    </h5>
                    <h5
                      id="alert-dialog-slide-title"
                      style={{ marginLeft: 20 }}
                      className={classes.bold_text}
                    >
                      {"CREATED AT: "}{" "}
                      <span style={{ color: "grey" }}>
                        {item.created_time} (UTC)
                      </span>
                    </h5>
                    <h5
                      id="alert-dialog-slide-title"
                      style={{ marginLeft: 20 }}
                      className={classes.bold_text}
                    >
                      {"IMAGE COUNT: "}{" "}
                      <span style={{ color: "grey" }}>{item.image_count}</span>
                    </h5>
                  </div>
                  {/* <DialogContent> */}
                  <DialogContentText id="alert-dialog-slide-description">
                    {item.content!.map((mail: string) => {
                      return <p className={classes.hover2}>{mail}</p>;
                    })}
                  </DialogContentText>
                  {/* </DialogContent> */}
                  <Divider />
                </div>
              ))}
            </>
          )}
          {test_mail!.length === 0 && unknown_email!.length === 0 && (
            // <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <p>No test or unsupported emails recorded</p>
            </DialogContentText>
            // </DialogContent>
            //   <Divider />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
