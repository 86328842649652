import React from "react";
import classes from "./ui.module.css";
import CloseIcon from "@material-ui/icons/Close";

import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import { DirectionsCar, DirectionsWalk, Pets, Help } from "@material-ui/icons";
import SecurityIcon from "@material-ui/icons/Security";
import SportsIcon from "@material-ui/icons/Sports";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import WavesIcon from "@material-ui/icons/Waves";
import CheckIcon from "@material-ui/icons/Check";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined";
import CenterFocusStrongIcon from "@material-ui/icons/CenterFocusStrong";
//

interface AlertType {
  alert_id: string;
  site_id: number;
  site_name: string;
  sensor_human_name: string;
  sensor_id_str: string;
  probability: number;
  created: number;
  media_url: string;
  message: string;
  alert_type: string;
  class_label: string;
  type?: string;
  improve_accuracy_flag: boolean;
}

interface review_logs {
  created: number;
  log_username: string;
  review_status: string;
  content: string;
}

interface EventType {
  doc_id: string;
  track_id: string;
  elastic_index: string;
  event_type: string;
  data_origin: string;
  site_id: number;
  site_name: string;
  sensor_id_str: string;
  sensor_human_name: string;
  media_url: string;
  image_height: number;
  image_width: number;
  created: number;
  classifier: string;
  probabilities: number[] | string[];
  class_labels: string[];
  message: string;
  improve_accuracy_flag: boolean;
  improve_accuracy_username: string | null;
  improve_accuracy_timestamp: string | null;
  suppress_flag: boolean;
  record_type: string;
  review_logs?: review_logs[] | [];
  isAlpr?: boolean;
}

export interface Alert {
  alert_type: string;
  class_label: string;
  hub_id_str: string;
  hub_location: string;
  inference_classes: string[];
  inference_probabilities: number[];
  message: string;
  probability: number;
}

interface ListItemProps {
  children?: React.ReactNode;
  alert: EventType;
  autoPlayFunc: React.Dispatch<any>;
  type: string;
  lastImage: (e: any) => void;
  renderRef: boolean;
  key?: any;
}

const createdTime = (
  epocSeconds: number,
  showBrowser = false,
  date_only = false,
) => {
  let today = new Date(epocSeconds * 1000);
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();

  let hr: number | string = new Date(epocSeconds * 1000).getHours();
  let min: number | string = new Date(epocSeconds * 1000).getMinutes();
  let sec: number | string = new Date(epocSeconds * 1000).getSeconds();

  if (min == 0) {
    min = "00";
  }

  if (min.toString().length == 1) {
    min = `0${min}`;
  }

  if (hr.toString().length == 1) {
    hr = `0${hr}`;
  }

  if (sec.toString().length == 1) {
    sec = `0${sec}`;
  }

  if (date_only) {
    return ` ${yyyy}/${mm}/${dd}`;
  }

  return ` ${hr}:${min}:${sec} -  ${yyyy}/${mm}/${dd} ${
    showBrowser ? "(browser time)" : ""
  } `;
};

const getIcon = (alert: EventType, training: boolean) => {
  let icon: React.ReactNode[] = [];
  let icon2: React.ReactNode[] = [];
  // if (training) {
  //   icon.push(
  //     <AssistantPhotoIcon className={classes.icon_blue}></AssistantPhotoIcon>
  //   );
  // }
  if (
    // alert.event_type == "analytics" &&
    alert.class_labels.toString().includes("safety.") ||
    alert.class_labels.toString().includes("safety")
  ) {
    icon.push(<SecurityIcon className={classes.icon_blue}></SecurityIcon>);
    // } else if (
    //   // alert.event_type == "analytics" &&
    //   // alert.class_labels.toString().includes("human1.") ||
    //   alert.class_labels.toString().includes("human1")
    // ) {
    icon.push(<DirectionsWalk className={classes.icon}></DirectionsWalk>);
  } else if (alert.class_labels.toString().includes("ignore.")) {
    icon.push(
      <DeleteSweepOutlinedIcon
        className={classes.icon_blue}
      ></DeleteSweepOutlinedIcon>,
    );
  } else if (
    // alert.event_type == "analytics" &&
    alert.class_labels.toString().includes("weapons.") ||
    alert.class_labels.toString().includes("weapons")
  ) {
    icon.push(<SportsIcon className={classes.icon_blue}></SportsIcon>);
  } else if (
    alert.event_type == "object" ||
    alert.event_type == "analytics" ||
    alert.event_type == "flagged_image" ||
    alert.event_type == "silenced_image" ||
    alert.event_type == "unsilenced_image"
  ) {
    if (
      alert.class_labels.toString().includes("animal.") ||
      alert.class_labels.toString().includes("animal")
    ) {
      icon.push(<Pets className={classes.icon}></Pets>);
    } else if (
      alert.class_labels.toString().includes("people.") ||
      alert.class_labels.toString().includes("people")
    ) {
      icon.push(<DirectionsWalk className={classes.icon}></DirectionsWalk>);
    } else if (
      alert.class_labels.toString().includes("vehicle.") ||
      alert.class_labels.toString().includes("vehicles")
    ) {
      icon.push(<DirectionsCar className={classes.icon}></DirectionsCar>);
    }
  } else if (alert.event_type == "snapshot") {
    icon.push(<CameraAltIcon className={classes.icon_blue}></CameraAltIcon>);
  } else if (
    alert.event_type == "hub_disconnect" ||
    alert.event_type == "hub_disconnect_reminder" ||
    alert.event_type == "sensor_analytics_disconnect" ||
    alert.event_type == "sensor_analytics_disconnect_reminder" ||
    alert.event_type == "sensor_analytics_reconnect" ||
    alert.event_type == "sensor_disconnect" ||
    alert.event_type == "sensor_disconnect_reminder"
  ) {
    icon.push(<CloseIcon className={classes.icon_blue}></CloseIcon>);
  } else if (
    alert.event_type == "hub_reconnect" ||
    alert.event_type == "sensor_reconnect"
  ) {
    icon.push(<CheckIcon className={classes.icon_blue}></CheckIcon>);
  } else if (alert.event_type == "motion") {
    icon.push(<WavesIcon className={classes.icon_blue}></WavesIcon>);
  }
  if (icon.length === 0) {
    icon.push(<Help className={classes.icon_blue}></Help>);
  }
  icon2.push(<VolumeDownIcon className={classes.icon_blue}></VolumeDownIcon>);
  return [icon, icon2];
};

const ListAlertItem = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ListItemProps>
>(({ alert, type, lastImage, renderRef, key, autoPlayFunc }, ref) => {
  var alert_icon = (
    <WarningRoundedIcon
      style={{ color: "red" }}
      className={classes.icon_blue}
    ></WarningRoundedIcon>
  );
  var bin_icon = (
    <DeleteSweepOutlinedIcon
      className={classes.icon_blue}
    ></DeleteSweepOutlinedIcon>
  );

  var icon =
    alert.record_type === "alert"
      ? getIcon(alert, alert.improve_accuracy_flag)[0]
      : null;

  var icon2 = (
    <CenterFocusStrongIcon
      className={classes.icon_blue}
    ></CenterFocusStrongIcon>
  );

  var camera = alert.sensor_human_name
    ? alert.sensor_human_name.slice(0, 36)
    : " ";
  var detection = alert.site_name || `${alert.site_id}`;
  var time =
    // alert.message && alert.message.includes(":")
    //   ? alert.message.substring(
    //       alert.message.indexOf(" at ") + 4,
    //       alert.message.indexOf(" on ")
    //     ) +
    //     " - " +
    //     createdTime(alert.created, false, true).trim()
    //   : createdTime(alert.created);
    createdTime(alert.created);

  if (renderRef) {
    return (
      <div ref={ref} key={key}>
        <p
          ref={lastImage}
          className={classes["item-wrapper2"]}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            {alert.record_type === "alert" && alert_icon}
            {alert.record_type === "inference" && bin_icon}
          </div>
          <div>
            <span style={{ color: "grey" }}>{camera}</span>
            <div>{detection.slice(0, 40) + " : " + time}</div>
          </div>

          {alert.record_type === "alert" && (
            <div style={{ marginLeft: 10, marginRight: 10 }}>{icon}</div>
          )}

          {alert.record_type === "inference" && alert?.isAlpr && (
            <div style={{ marginLeft: 10, marginRight: 10 }}>{icon2}</div>
          )}
        </p>
      </div>
    );
  } else {
    return (
      <div ref={ref} key={key}>
        <p
          className={classes["item-wrapper2"]}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            {alert.record_type === "alert" && alert_icon}
            {alert.record_type === "inference" && bin_icon}
          </div>
          <div>
            <span style={{ color: "grey" }}>{camera}</span>
            <div>{detection.slice(0, 40) + " : " + time}</div>
          </div>
          {alert.record_type === "alert" && (
            <div style={{ marginLeft: 10, marginRight: 10 }}>{icon}</div>
          )}

          {alert.record_type === "inference" && alert?.isAlpr && (
            <div style={{ marginLeft: 10, marginRight: 10 }}>{icon2}</div>
          )}
        </p>
      </div>
    );
  }
});

export default ListAlertItem;
