import { Divider } from "@material-ui/core";
import ReportGroups from "./ReportGroups/ReportGroups";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { getBaseUrl } from "../../HelperFunctions";
import { ArrayToStringifiedArray } from "../../../hooks/ArrayToStringifiedArray";

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { ReportGroup } from "../../../services/FunctionService";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import { AppStore } from "../../../stores/AppStore";
import { useStyles } from "../../styling/global/global";
import ReportEdit from "./ReportEdit";
import ReportAdd from "./ReportAdd";
import { ReportsConsolidate } from "./ReportsConsolidate";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import ReportOffline from "./ReportOffline";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface Report {
  report_id: number;
  site_ids: number[];
  report_group_id: number;
  site_name?: string;
  site_names: string[];
  org_tree_names: string;
  report_type: string;
  site_product_details: { site_id: number; site_product_name: string }[];
  report_group_name: string;
  config: {
    time: string;
    isoweekday: number;
    include_escalated_events?: boolean;
    include_csv?: boolean;
  };
  timezone_str: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const themez = createMuiTheme({
  overrides: {
    MuiTab: {
      root: {
        "&.Mui-selected": {
          // background: "rgba(255,255,255,0.09)",
          color: "white",
          fontWeight: "bold",
          width: 1200,
        },
      },
    },
  },
});

const weekdays = [
  "",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStylesDate = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      color: "#6D809D",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      color: "#6D809D",
    },
  }),
);

// Switch Styles
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);

interface ReportProps {
  appStore?: AppStore;
}
const Reports: React.FC<ReportProps> = ({ appStore }) => {
  const [orgslist, setOrgsList] = React.useState<any>([]);
  const [groupid, setGroupId] = React.useState(-1);
  const [loading, setLoading] = React.useState(true);
  const [groupname, setGroupName] = React.useState("");
  const [groupTreeName, setGroupTreeName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [err, setErr] = React.useState(false);
  const [siteList, setSiteList] = React.useState<any>([]);

  const [siteReports, setSiteReports] = useState<Report[]>([]);

  console.debug("REPORTS LIST: ", siteReports);

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  let [isEditable, setIsEditable] = React.useState(false);
  const theme = useTheme();

  const [viewOptions, setViewOptions] = React.useState("pdf");
  const [selectedPeriodValue, setSelectedPeriodValue] = React.useState("day");
  const [selectedSite, setSelectedSite] = React.useState("select site");
  const [selectedSiteId, setSelectedSiteId] = React.useState<number | null>(
    null,
  );
  const [openToast, setOpenToast] = React.useState(false);
  const [reportType, setReportType] = React.useState("any");
  const [incSubgroups, setIncSubgroups] = useState(false);

  const [scheduleReport, setScheduleReport] = React.useState("demand");
  const [escalateEvents, setEscalateEvents] = React.useState(true);
  const [day, setDay] = React.useState({
    sun: false,
    mon: false,
    tue: false,
    wed: true,
    thur: false,
    fri: false,
    sat: false,
  });

  const [siteIds, setSiteIds] = React.useState<number[]>([]);
  const [email, setEmail] = React.useState("");
  const [selectedReport, setSelectedReport] = React.useState<
    Report | undefined
  >();

  const [value, setValue] = React.useState(0);

  const [reportgroupsForWholeTree, setReportGroupsForWholeTree] = useState<
    ReportGroup[] | undefined
  >(undefined);

  const [selectedDate, setSelectedDate] = React.useState<number | string>(0);
  const [reportIds, setReportIds] = React.useState<number[]>([]);

  //Date
  const [startDate, setStartDate] = useState("");
  const [editOrgTreeName, setEditOrgTreeName] = useState("");
  const [endDate, setEndDate] = useState("");
  const date_style = useStylesDate();
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const [reportGroupNames, setReportGroupNames] = React.useState<
    string[] | any
  >([]);

  //Tabsmenu
  const handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const reloadOrgs = async (id?: number) => {
    if (id && id !== groupid) {
      return setGroupId(id);
    }
    await fetchOrgsData();
    await fetchReports();
  };

  const reportIdsHandler = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let status = event.target.checked;
    let newItem = id;
    if (status) {
      reportIds.push(newItem);
    } else {
      const index = reportIds.indexOf(id);
      if (index > -1) {
        reportIds.splice(index, 1);
      }
    }
  };

  const continueToDeleteHandler = async () => {
    try {
      const request = JSON.stringify({
        // report_ids: deleteId,
        report_ids: ArrayToStringifiedArray(reportIds),
      });
      const token = await appStore?.authService.getAuthorisedToken();
      const res = fetch(getBaseUrl() + "/site_report", {
        method: "delete",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = await (await res).json();
      await fetchReports();
      setReportIds([]);
      handleDeleteClose();
      return result;
    } catch (err) {
      return err;
    }
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const setEditClose = () => {
    setOpenEdit(false);
  };

  const setAddClose = () => {
    setOpenAdd(false);
  };

  // STYLING SECTION
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:hover": {
          backgroundColor: "#e3f2fd",
        },
      },
    }),
  )(TableRow);

  const refreshInput = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  };

  const handleToastClose = () => {
    setMessage("");
    setErr(false);
    setOpenToast(false);
  };

  const handleViewOptions = (val: string) => {
    if (val == "pdf") {
      setViewOptions("pdf");
    }
    if (val == "csv") {
      setViewOptions("csv");
    }
  };

  const handleChangeAuto = (value: any) => {
    if (!value) {
      return setGroupId(-1);
    }
    setReportGroupNames([]);
    setSelectedSite("select site");
    setSelectedSiteId(null);
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      let tree = group.org_tree_names!.map((item: any) => `   /   ${item} `);
      let treeString = tree.toString();
      const rpl = treeString.replace(/,/g, "");
      setGroupTreeName(rpl);
      setGroupName(group.org_name);
    }
  };

  const styler = useStyle();
  const classes = useStyles();

  const handleChange = (value: any) => {
    setSelectedSite(value.site_name);
    setSelectedSiteId(value.site_id);
    refreshInput();
  };

  const siteIdsHandler = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let status = event.target.checked;
    let newItem = id;
    if (status) {
      siteIds.push(newItem);
    } else {
      const index = siteIds.indexOf(id);
      if (index > -1) {
        siteIds.splice(index, 1);
      }
    }
  };

  const onSubmitHandler = () => {
    console.debug(
      "scheduleReport: ",
      scheduleReport,
      "Report Type: ",
      reportType,
      "escalateEvents",
      escalateEvents,
      "selectedSite: ",
      selectedSite,
      "selectedPeriodValue: ",
      selectedPeriodValue,
      "SelectedSiteId: ",
      selectedSiteId,
      "start time: ",
      startDate,
      "end time: ",
      endDate,
    );
  };

  const handleTypeOfReport = (value: unknown) => {
    if (value === "activity") {
      setReportType("activity");
    }

    if (value === "health") {
      setReportType("health");
    }

    if (value === "any") {
      setReportType("any");
    }
  };

  const handleChangeDay = (day: string) => {
    let default_day = {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thur: false,
      fri: false,
      sat: false,
    };

    if (day === "sun") {
      default_day.sun = true;
      setDay(default_day);
      setSelectedDate(0);
    }
    if (day === "mon") {
      default_day.mon = true;
      setDay(default_day);
      setSelectedDate(1);
    }
    if (day === "tue") {
      default_day.tue = true;
      setDay(default_day);
      setSelectedDate(2);
    }
    if (day === "wed") {
      default_day.wed = true;
      setDay(default_day);
      setSelectedDate(3);
    }
    if (day === "thur") {
      default_day.thur = true;
      setDay(default_day);
      setSelectedDate(4);
    }
    if (day === "fri") {
      default_day.fri = true;
      setDay(default_day);
      setSelectedDate(5);
    }
    if (day === "sat") {
      default_day.sat = true;
      setDay(default_day);
      setSelectedDate(6);
    }
  };

  const fetchOrgsData = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          enabled: true,
          paginate: false,
          verbose: false,
          incl_tree_names: true,
        });

        const res = fetch(baseUrl + "/list_orgs", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        const orglist = result;
        if (orglist && orglist.success) {
          let orgz = orglist?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }
    }
  };

  // ######### GET REPORTS #############
  const fetchReports = async () => {
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token && groupid >= 0) {
      setLoading(true);
      try {
        const request = JSON.stringify({
          org_id: groupid,
          top_org_only: !incSubgroups,
        });

        const res = fetch(baseUrl + "/list_site_reports", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        const result = await (await res).json();
        if (result && result.success) {
          let reports = result?.reports;

          // Transform data
          let arr: Report[] = [];
          reports.forEach((site_report: { [key: string]: any }, index: any) => {
            let tree = site_report.org_tree_names!.map(
              (item: any) => ` / ${item} `,
            );
            let treeString = tree.toString();
            const rpl = treeString.replace(/,/g, "");
            arr.push({
              report_id: site_report.report_id,
              site_ids: site_report.site_ids,
              report_group_id: site_report.report_group_id,
              org_tree_names: rpl,
              site_product_details: site_report.site_product_details,
              report_type: site_report.report_type,
              config: site_report.config,
              timezone_str: site_report.timezone_str,
              site_names: site_report.site_names,
              report_group_name: site_report.report_group_name,
            });
          });

          setSiteReports(arr);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }
    }
  };

  useEffect(() => {
    fetchReports();
  }, [groupid, incSubgroups]);

  // Fetch Organizations
  useEffect(() => {
    fetchOrgsData();
  }, [groupid]);

  // Fetch sites
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      if (token && groupid >= 0) {
        try {
          const request = JSON.stringify({
            enabled: true,
            top_org_id: groupid,
          });

          const res = fetch(baseUrl + "/list_sites", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });
          const result = await (await res).json();
          if (result && result.success) {
            let sites = result?.sites;
            sites.sort(function (a: any, b: any) {
              return a.site_name - b.site_name;
            });
            setSiteList(sites);
          }
        } catch (err) {
          return err;
        } finally {
          setLoading(false);
        }

        //Fetch end
      }
    };
    fetchData();
  }, [groupid]);

  useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      let groupid = appStore?.authService.getEntryOrg()?.id!;

      if (token) {
        const reportgroupsforwholetree2 =
          await appStore?.functionService.getReportGroups(token, groupid);
        if (reportgroupsforwholetree2) {
          const list = reportgroupsforwholetree2.sort(
            (a: ReportGroup, b: ReportGroup): number => {
              if (b.name.toLowerCase() > a.name.toLowerCase()) {
                return -1;
              }
              if (b.name.toLowerCase() < a.name.toLowerCase()) {
                return 1;
              }
              return 0;
            },
          );
          setReportGroupsForWholeTree(list);
        }
      }
    };
    fetchData();
  }, []);

  const handleOrderChange = (e: string) => {
    let order = e;

    if (order == "Report Group ASC") {
      sortByHandler("Report Group", true);
    }

    if (order == "Report Group DSC") {
      sortByHandler("Report Group", false);
    }

    if (order === "Site ASC") {
      sortByHandler("Site", true);
    }

    if (order === "Site DSC") {
      sortByHandler("Site", false);
    }

    if (order === "Report Type ASC") {
      sortByHandler("Report Type", true);
    }

    if (order === "Report Type DSC") {
      sortByHandler("Report Type", false);
    }
    if (order === "Timezone ASC") {
      sortByHandler("Timezone", true);
    }

    if (order === "Timezone DSC") {
      sortByHandler("Timezone", false);
    }
    if (order === "Org Group ASC") {
      sortByHandler("Org Group", true);
    }

    if (order === "Org Group DSC") {
      sortByHandler("Org Group", false);
    }
  };

  const sortByHandler = (orderBy: string | unknown, direction: boolean) => {
    switch (orderBy) {
      case "Report Group":
        if (direction) {
          const list = [...siteReports].sort((a, b): number => {
            if (
              b.report_group_name.toLowerCase() >
              a.report_group_name.toLowerCase()
            ) {
              return -1;
            }
            if (
              b.report_group_name.toLowerCase() <
              a.report_group_name.toLowerCase()
            ) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        } else {
          const list = [...siteReports].sort((a, b): number => {
            if (
              b.report_group_name.toLowerCase() >
              a.report_group_name.toLowerCase()
            ) {
              return 1;
            }
            if (
              b.report_group_name.toLowerCase() <
              a.report_group_name.toLowerCase()
            ) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        }
      case "Org Group":
        if (direction) {
          const list = [...siteReports].sort((a, b): number => {
            if (
              b.org_tree_names.toLowerCase() > a.org_tree_names.toLowerCase()
            ) {
              return -1;
            }
            if (
              b.org_tree_names.toLowerCase() < a.org_tree_names.toLowerCase()
            ) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        } else {
          const list = [...siteReports].sort((a, b): number => {
            if (
              b.org_tree_names.toLowerCase() > a.org_tree_names.toLowerCase()
            ) {
              return 1;
            }
            if (
              b.org_tree_names.toLowerCase() < a.org_tree_names.toLowerCase()
            ) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        }
      case "Site":
        if (direction) {
          const list = [...siteReports].sort((a, b): number => {
            if (b.site_names[0].toLowerCase() > a.site_names[0].toLowerCase()) {
              return -1;
            }
            if (b.site_names[0].toLowerCase() < a.site_names[0].toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        } else {
          const list = [...siteReports].sort((a, b): number => {
            if (b.site_names[0].toLowerCase() > a.site_names[0].toLowerCase()) {
              return 1;
            }
            if (b.site_names[0].toLowerCase() < a.site_names[0].toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        }
      case "Report Type":
        if (direction) {
          const list = [...siteReports].sort((a, b): number => {
            if (b.report_type.toLowerCase() > a.report_type.toLowerCase()) {
              return -1;
            }
            if (b.report_type.toLowerCase() < a.report_type.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        } else {
          const list = [...siteReports].sort((a, b): number => {
            if (b.report_type.toLowerCase() > a.report_type.toLowerCase()) {
              return 1;
            }
            if (b.report_type.toLowerCase() < a.report_type.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        }

      case "Timezone":
        if (direction) {
          const list = [...siteReports].sort((a, b): number => {
            if (b.timezone_str.toLowerCase() > a.timezone_str.toLowerCase()) {
              return -1;
            }
            if (b.timezone_str.toLowerCase() < a.timezone_str.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        } else {
          const list = [...siteReports].sort((a, b): number => {
            if (b.timezone_str.toLowerCase() > a.timezone_str.toLowerCase()) {
              return 1;
            }
            if (b.timezone_str.toLowerCase() < a.timezone_str.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        }
    }
  };

  var sites_display: React.ReactNode[] = [];
  if (siteList && siteList.length > 0)
    siteList.forEach((site: { [key: string]: any }, index: any) => {
      sites_display.push(
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.site_id}
            </Typography>
          </TableCell>
          {groupTreeName && (
            <TableCell>
              <Typography className={classes.bold_text}>
                {groupTreeName}
              </Typography>
            </TableCell>
          )}
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.site_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.product}
            </Typography>
          </TableCell>
          <TableCell>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Tooltip
                placement="bottom"
                title="select site for scheduled reports"
              >
                <Grid item>
                  <AntSwitch
                    onChange={(e) => {
                      siteIdsHandler(site.site_id, e);
                    }}
                    name="checkedC"
                  />
                </Grid>
              </Tooltip>
            </Grid>
          </TableCell>
        </StyledTableRow>,
      );
    });

  //Table Content Setup
  var reports_display: React.ReactNode[] = [];
  if (siteReports && siteReports.length > 0)
    siteReports.forEach((site_report: Report | any, index: any) => {
      if (site_report.report_type === "site_health:connection") {
        return;
      }
      reports_display.push(
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site_report.report_group_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site_report.org_tree_names.replace("/", "")}
            </Typography>
          </TableCell>
          <TableCell>
            <Tooltip placement="bottom" title="click edit to view full list">
              <Typography className={classes.bold_text}>
                {site_report.report_type.startsWith("site_consolidated") ||
                site_report.report_type.startsWith(
                  "enhanced_camera_health_report",
                ) ||
                site_report.report_type.startsWith("site_activity_consolidated")
                  ? `${site_report.site_names.length} site(s) scheduled`
                  : site_report.report_type.startsWith("site_activity_group")
                    ? "N/A"
                    : site_report.report_type.startsWith("idle_camera_report")
                      ? "N/A"
                      : site_report.site_names[0]}
              </Typography>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site_report.report_type}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site_report.timezone_str ? site_report.timezone_str : ""}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site_report.report_type === "site_activity:daily" ||
              site_report.report_type === "site_health:daily" ||
              site_report.report_type ===
                "enhanced_camera_health_report:daily" ||
              site_report.report_type === "site_consolidated:daily" ||
              site_report.report_type === "site_activity_consolidated:daily" ||
              site_report.report_type === "site_activity_group:daily" ||
              site_report.report_type === "site_health:weekly" ||
              site_report.report_type === "site_consolidated:weekly" ||
              site_report.report_type === "site_activity_consolidated:weekly" ||
              site_report.report_type === "site_activity_group:weekly" ||
              site_report.report_type === "idle_camera_report:weekly" ||
              site_report.report_type === "site_activity:weekly"
                ? site_report.config.time
                : ""}
              {site_report.report_type === "site_activity:weekly" ||
              site_report.report_type === "site_health:weekly" ||
              site_report.report_type === "site_consolidated:weekly" ||
              site_report.report_type === "site_activity_consolidated:weekly" ||
              site_report.report_type === "site_activity_group:weekly" ||
              site_report.report_type === "idle_camera_report:weekly"
                ? ",     " + weekdays[site_report.config.isoweekday]
                : ""}
              {site_report.report_type === "site_activity:monthly" ||
              site_report.report_type === "site_health:monthly" ||
              site_report.report_type ===
                "site_activity_consolidated:monthly" ||
              site_report.report_type === "site_consolidated:monthly" ||
              site_report.report_type === "site_activity_group:monthly"
                ? "_____"
                : ""}
            </Typography>
          </TableCell>

          <TableCell>
            <Typography className={classes.bold_text}>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setSelectedReport(site_report);
                      setOpenEdit(true);
                      setEditOrgTreeName(site_report.org_tree_names);
                    }}
                  >
                    <EditIcon />
                  </span>{" "}
                  &nbsp;
                </Grid>
              </Grid>
            </Typography>
          </TableCell>
          <TableCell>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Tooltip
                placement="bottom"
                title="select site for scheduled reports"
              >
                <Grid item>
                  <AntSwitch
                    // defaultChecked={selectAll && !unSelectAll ? true : false}
                    onChange={(e) => {
                      reportIdsHandler(site_report.report_id, e);
                    }}
                    name="checkedC"
                  />
                </Grid>
              </Tooltip>
            </Grid>
          </TableCell>
        </StyledTableRow>,
      );
    });

  if (loading) {
    return (
      <>
        <ThemeProvider theme={themez}>
          <AppBar
            position="static"
            className={classes.appBar}
            style={{ width: 1300 }}
          >
            <Tabs
              indicatorColor="secondary"
              textColor="inherit"
              value={value}
              onChange={handleTabsChange}
            >
              {/* <Tab label="Ondemand" {...a11yProps(0)} /> */}
              <Tab label="Scheduled" {...a11yProps(0)} />
              <Tab label="Triggered" {...a11yProps(1)} />
              <Tab label="Groups" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={1}>
            <h3
              className={classes.bold_text}
              style={{
                textAlign: "center",
                fontSize: 16,
                textTransform: "capitalize",
                marginBottom: 20,
              }}
            >
              <> Site Report Configurations</>
            </h3>
            <Divider style={{ marginBottom: 20 }} />
          </TabPanel>
        </ThemeProvider>
        <div style={{ margin: 40 }}>
          <LoadingSpinner />
        </div>
      </>
    );
  } else {
    return (
      <div className="main-drawer-container">
        {/********************** TABS FOR NAVIGATION *********************************/}
        <ThemeProvider theme={themez}>
          <AppBar
            position="static"
            className={classes.appBar}
            style={{ width: 1300 }}
          >
            <Tabs
              indicatorColor="secondary"
              textColor="inherit"
              value={value}
              onChange={handleTabsChange}
              style={{ color: "white" }}
              // centered
            >
              {/* {tabsmenu} */}
              {/* <Tab label="Ondemand" {...a11yProps(0)} /> */}
              <Tab label="Scheduled" {...a11yProps(0)} />
              <Tab label="Triggered" {...a11yProps(1)} />
              <Tab label="Groups" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
        </ThemeProvider>
        <TabPanel value={value} index={0}>
          <h3
            className={classes.bold_text}
            style={{
              textAlign: "center",
              fontSize: 16,
              textTransform: "capitalize",
              marginBottom: 20,
            }}
          >
            <>Edit Scheduled Reports for Sites</>
          </h3>
          <Divider style={{ marginBottom: 20 }} />
          <div>
            {message && <div style={{ marginTop: 30 }} />}
            <Collapse in={openToast}>
              {message && (
                <div style={{ marginBottom: 35 }}>
                  <Alert
                    severity={err ? "error" : "success"}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          handleToastClose();
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {message}
                  </Alert>
                </div>
              )}
            </Collapse>
            {!loading && !message && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: 950,
                  }}
                >
                  <div>
                    <FormControl
                      className={classes.form_component_medium}
                      variant="outlined"
                      fullWidth={true}
                    >
                      <InputLabel className={classes.bold_text}>
                        Select Group to Filter Sites{" "}
                      </InputLabel>
                      <br></br>
                      <Autocomplete
                        id="combo-box-demo"
                        className={classes.form_component_medium}
                        options={orgslist}
                        onChange={(
                          event: React.ChangeEvent<any>,
                          value: any,
                        ) => {
                          handleChangeAuto(value);
                        }}
                        getOptionLabel={(option) => {
                          let tree = option.org_tree_names!.map(
                            (item: any) => `   /   ${item} `,
                          );
                          let treeString = tree.toString();
                          const rpl = treeString.replace(/,/g, "");
                          return `${rpl}`;
                        }}
                        style={{ width: 600, color: "#6D809D" }}
                        classes={{ paper: styler.paper }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span
                                className={classes.bold_text}
                                style={{ color: "#15325F" }}
                              >
                                {groupname ? groupname : "Please Select Group"}
                              </span>
                            }
                            variant="outlined"
                            value={groupid}
                            className={classes.bold_text}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <Typography className={classes.header_text}>
                        Include Sub-Groups
                      </Typography>
                    </FormControl>
                    <FormControl>
                      <Checkbox
                        className={classes.form_component_checkbox}
                        onChange={() => {
                          setIncSubgroups((prev) => !prev);
                        }}
                        color="primary"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                        checked={incSubgroups}
                      />
                    </FormControl>
                  </div>
                </div>
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: 1150,
                    }}
                  >
                    <div style={{ marginLeft: 20 }}>
                      <h2 className={classes.bold_text}>Report List:</h2>
                    </div>
                    <div style={{ display: "flex", paddingLeft: 15 }}>
                      {groupname && (
                        <>
                          <Tooltip
                            title="Select your group first to add report"
                            aria-label="add"
                          >
                            <Button
                              variant="contained"
                              className={classes.mute_button}
                              onClick={() => {
                                if (groupid === -1) {
                                  return;
                                }
                                setOpenAdd(true);
                              }}
                            >
                              Add Report
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Select the reports from the table first to delete."
                            aria-label="add"
                          >
                            <Button
                              variant="contained"
                              className={classes.mute_button}
                              style={{ backgroundColor: "red" }}
                              onClick={() => {
                                if (reportIds.length === 0) {
                                  return;
                                }
                                setOpenDelete(true);
                              }}
                            >
                              Delete
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                  <br />
                  <div
                    style={{
                      boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                      borderRadius: 15,
                      minHeight: "fit-content",
                      maxHeight: 450,
                      overflowY: "scroll",
                      backgroundColor: "white",
                      marginLeft: 40,
                      marginRight: 20,
                      marginBottom: 35,
                      width: 1200,
                    }}
                  >
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: 350 }}
                    >
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Report Group
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: 10,
                                    marginLeft: 8,
                                    cursor: "pointer",
                                  }}
                                >
                                  <span>
                                    <ArrowDropUpIcon
                                      onClick={() => {
                                        handleOrderChange("Report Group ASC");
                                      }}
                                    />
                                  </span>
                                  <span>
                                    <ArrowDropDownIcon
                                      onClick={() => {
                                        handleOrderChange("Report Group DSC");
                                      }}
                                    />
                                  </span>
                                </span>
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Group
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: 10,
                                    marginLeft: 8,
                                    cursor: "pointer",
                                  }}
                                >
                                  <span>
                                    <ArrowDropUpIcon
                                      onClick={() => {
                                        handleOrderChange("Org Group ASC");
                                      }}
                                    />
                                  </span>
                                  <span>
                                    <ArrowDropDownIcon
                                      onClick={() => {
                                        handleOrderChange("Org Group DSC");
                                      }}
                                    />
                                  </span>
                                </span>
                              </span>
                            </TableCell>

                            <TableCell>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Site Name
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: 10,
                                    marginLeft: 8,
                                    cursor: "pointer",
                                  }}
                                >
                                  <span>
                                    <ArrowDropUpIcon
                                      onClick={() => {
                                        handleOrderChange("Site ASC");
                                      }}
                                    />
                                  </span>
                                  <span>
                                    <ArrowDropDownIcon
                                      onClick={() => {
                                        handleOrderChange("Site DSC");
                                      }}
                                    />
                                  </span>
                                </span>
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Report Type
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: 10,
                                    marginLeft: 8,
                                    cursor: "pointer",
                                  }}
                                >
                                  <span>
                                    <ArrowDropUpIcon
                                      onClick={() => {
                                        handleOrderChange("Report Type ASC");
                                      }}
                                    />
                                  </span>
                                  <span>
                                    <ArrowDropDownIcon
                                      onClick={() => {
                                        handleOrderChange("Report Type DSC");
                                      }}
                                    />
                                  </span>
                                </span>
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Timezone
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: 10,
                                    marginLeft: 8,
                                    cursor: "pointer",
                                  }}
                                >
                                  <span>
                                    <ArrowDropUpIcon
                                      onClick={() => {
                                        handleOrderChange("Timezone ASC");
                                      }}
                                    />
                                  </span>
                                  <span>
                                    <ArrowDropDownIcon
                                      onClick={() => {
                                        handleOrderChange("Timezone DSC");
                                      }}
                                    />
                                  </span>
                                </span>
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{
                                  fontSize: 13,
                                  color: "#616161",
                                  fontWeight: "bold",
                                }}
                              >
                                Schedule
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{
                                  fontSize: 13,
                                  color: "#616161",
                                  fontWeight: "bold",
                                  display: "flex",
                                  cursor: "pointer",
                                }}
                              >
                                Edit
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{
                                  fontSize: 13,
                                  color: "#616161",
                                  fontWeight: "bold",
                                }}
                              >
                                Select
                              </span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ overflowY: "scroll" }}>
                          {reports_display}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {openEdit && (
                      <ReportEdit
                        reloadOrgs={reloadOrgs}
                        open={openEdit}
                        setOpenToClose={setEditClose}
                        report={selectedReport}
                        report_tree={reportgroupsForWholeTree}
                        appStore={appStore}
                        groupTreeName={groupTreeName}
                        top_org_only={incSubgroups}
                        groupid={groupid}
                        editOrgTreeName={editOrgTreeName}
                      />
                    )}
                    {openAdd && (
                      <ReportAdd
                        open_add={openAdd}
                        setAddOpenToClose={setAddClose}
                        appStore={appStore}
                        reloadOrgs={reloadOrgs}
                        group_id={groupid}
                        groupTreeName={groupTreeName}
                        top_org_only={incSubgroups}
                      />
                    )}
                  </div>
                </>
              </>
            )}
            {loading && <LoadingSpinner />}
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete the selected report(s)?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} color="primary">
                  No
                </Button>
                <Button
                  onClick={continueToDeleteHandler}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          {/* Put your code here */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div>
            <h3
              className={classes.bold_text}
              style={{
                textAlign: "center",
                fontSize: 16,
                textTransform: "capitalize",
                marginBottom: 20,
              }}
            >
              Generate Reports
            </h3>
            <Divider style={{ marginBottom: 20 }} />
            {message && <div style={{ marginTop: 30 }} />}
            <Collapse in={openToast}>
              {message && (
                <div style={{ marginBottom: 35 }}>
                  <Alert
                    severity={err ? "error" : "success"}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          handleToastClose();
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {message}
                  </Alert>
                </div>
              )}
            </Collapse>
            {!loading && !message && (
              <>
                <div>
                  <FormControl
                    className={classes.form_component_medium}
                    variant="outlined"
                    fullWidth={true}
                  >
                    <div style={{ marginBottom: 3 }}>
                      <h5 className={classes.bold_text}>
                        Select Group to Filter Sites By Group: <br />{" "}
                        <p style={{ fontSize: 11, color: "#9e9e9e" }}>
                          (This is for filtering sites based on the selected
                          group.)
                        </p>
                      </h5>
                    </div>

                    <Autocomplete
                      id="combo-box-demo"
                      className={classes.form_component_medium}
                      options={orgslist}
                      onChange={(event: React.ChangeEvent<any>, value: any) => {
                        handleChangeAuto(value);
                      }}
                      getOptionLabel={(option) => {
                        let tree = option.org_tree_names!.map(
                          (item: any) => `   /   ${item} `,
                        );
                        let treeString = tree.toString();
                        const rpl = treeString.replace(/,/g, "");
                        return `${rpl}`;
                      }}
                      style={{ width: 600, color: "#6D809D" }}
                      classes={{ paper: styler.paper }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span
                              className={classes.bold_text}
                              style={{ color: "#15325F" }}
                            >
                              {groupname ? groupname : "Please Select Group"}
                            </span>
                          }
                          variant="outlined"
                          value={groupid}
                          className={classes.bold_text}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <Divider variant="middle" style={{ marginTop: 20 }} />
                <div style={{ marginLeft: 30, display: "flex" }}>
                  <FormControl
                    className={classes.form_component_medium}
                    variant="outlined"
                    fullWidth={false}
                  >
                    <div
                      style={{ width: 250, color: "#15325F", marginRight: 150 }}
                    >
                      <h2 className={classes.header_text}>Type of Report:</h2>
                      <div
                        style={{ width: 250, color: "#15325F", marginLeft: 10 }}
                      >
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={reportType}
                            label="Report Type"
                            onChange={(e) => handleTypeOfReport(e.target.value)}
                          >
                            <MenuItem value={"activity"}>
                              site activity{" "}
                            </MenuItem>
                            <MenuItem value={"health"}>site health</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </FormControl>
                </div>

                {scheduleReport === "demand" && (
                  <>
                    <div>
                      <div style={{ marginLeft: 20, marginBottom: 10 }}>
                        <FormControl
                          className={classes.form_component_medium}
                          variant="outlined"
                          fullWidth={false}
                        >
                          <InputLabel className={classes.bold_text}>
                            &nbsp;&nbsp;&nbsp;Select a site you want a report
                            for:
                          </InputLabel>
                          <br />
                          <Autocomplete
                            id="combo-box-demo"
                            className={classes.form_component_medium}
                            options={siteList}
                            onChange={(
                              event: React.ChangeEvent<any>,
                              value: any,
                            ) => {
                              handleChange(value);
                            }}
                            getOptionLabel={(option: any) => {
                              return `${option.site_name}`;
                            }}
                            style={{ width: 600, color: "#6D809D" }}
                            classes={{ paper: styler.paper }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  <span
                                    className={classes.bold_text}
                                    style={{ color: "#15325F" }}
                                  >
                                    {selectedSite
                                      ? selectedSite
                                      : "Please Select Site"}
                                  </span>
                                }
                                variant="outlined"
                                value={selectedSite}
                                className={classes.bold_text}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      {/* ####### DATE #### */}
                      <h2
                        className={classes.header_text}
                        style={{ marginLeft: 40 }}
                      >
                        Time Range:
                      </h2>
                      <div
                        style={{
                          marginLeft: 40,
                          display: "flex",
                          alignItems: "center",
                          color: "#6D809D",
                        }}
                      >
                        <form className={date_style.container} noValidate>
                          <TextField
                            variant="outlined"
                            onChange={(e) => {
                              setStartDate(e.target.value);
                            }}
                            value={startDate}
                            id="datetime-local"
                            label="FROM:"
                            type="datetime-local"
                            className={date_style.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </form>
                        <span
                          style={{
                            fontSize: 40,
                            color: "#6D809D",
                            marginLeft: 50,
                            marginRight: 50,
                          }}
                        >
                          &rarr;
                        </span>
                        <form className={date_style.container} noValidate>
                          <TextField
                            variant="outlined"
                            onChange={(e) => {
                              setEndDate(e.target.value);
                            }}
                            value={endDate}
                            id="datetime-local"
                            label="TO:"
                            type="datetime-local"
                            className={date_style.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </form>
                      </div>
                      <div style={{ marginLeft: 50, flex: 1 }}>
                        <FormControl>
                          <br />
                          <Typography className={classes.header_text}>
                            View Options:
                          </Typography>
                          <div
                            style={{
                              color: "#15325F",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div style={{ marginLeft: 20 }}>
                              <RadioGroup
                                aria-label="gender"
                                name="Users"
                                value={viewOptions}
                                onChange={(e) => {
                                  handleViewOptions(e.target.value);
                                }}
                              >
                                <FormControlLabel
                                  value="pdf"
                                  control={<Radio />}
                                  label="PDF"
                                />
                                <FormControlLabel
                                  value="csv"
                                  control={<Radio />}
                                  label="CSV"
                                />
                              </RadioGroup>
                            </div>
                          </div>
                        </FormControl>
                      </div>
                    </div>
                  </>
                )}
                {/* include esc events */}
                <Divider variant="middle" style={{ margin: 20 }} />

                {/* Add border */}
                {reportType !== "health" && (
                  <div style={{ display: "flex", marginLeft: 40 }}>
                    <FormControl>
                      <br />
                      <Typography className={classes.header_text}>
                        Include Escalated Events
                      </Typography>
                    </FormControl>
                    <FormControl>
                      <br />
                      <Checkbox
                        className={classes.form_component_checkbox}
                        color="primary"
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        checked={escalateEvents}
                        onChange={(e) => {
                          setEscalateEvents(e.target.checked);
                        }}
                      />
                    </FormControl>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    margin: 40,
                    alignItems: "center",
                    paddingLeft: 30,
                    paddingRight: 30,
                  }}
                >
                  <Tooltip title={`submit request`}>
                    <Button
                      variant="contained"
                      className={classes.mute_button}
                      onClick={onSubmitHandler}
                    >
                      Submit
                    </Button>
                  </Tooltip>
                </div>
              </>
            )}
            {loading && <LoadingSpinner />}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <>
            <h3
              className={classes.bold_text}
              style={{
                textAlign: "center",
                fontSize: 16,
                textTransform: "capitalize",
                marginBottom: 20,
              }}
            >
              <>Edit Triggered Reports for Sites</>
            </h3>
            <Divider style={{ marginBottom: 20 }} />
            <ReportOffline appStore={appStore} />
          </>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ReportGroups appStore={appStore}></ReportGroups>
        </TabPanel>
        <div
          style={{
            display: "none",
          }}
        >
          <ReportGroups appStore={appStore} value={value}></ReportGroups>
        </div>
      </div>
    );
  }
};

export default Reports;
