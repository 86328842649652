import React, { useState } from "react";
import { useStyles } from "../styling/global/global";
import SlideShow from "../UI/SlideShow";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import FlagIcon from "@material-ui/icons/Flag";
import { AppStore } from "../../stores/AppStore";

interface EventType {
  doc_id: string;
  track_id: string;
  elastic_index: string;
  event_type: string;
  data_origin: string;
  site_id: number;
  site_name: string;
  sensor_id_str: string;
  sensor_human_name: string;
  media_url: string;
  image_height: number;
  image_width: number;
  created: number;
  classifier: string;
  probabilities: number[] | string[];
  class_labels: string[];
  message: string;
  improve_accuracy_flag: boolean;
  improve_accuracy_username: string | null;
  improve_accuracy_timestamp: string | null;
  suppress_flag: boolean;
  record_type: string;
  review_logs?: review_logs[] | [];
  reason_for_flag?: string;
}

interface review_logs {
  created: number;
  log_username: string;
  review_status: string;
  content: string;
}

interface Props {
  selectedAlert: any;
  alertList: EventType[];
  alert: any;
  autoplay: boolean;
  flagged: boolean;
  silenced?: boolean;
  message?: string;
  setSelectedAlert: (a: any) => void;
  review_logs?: review_logs[] | [];
  siteId: string | number;
  siteName: string;
  sensorName: string;
  sensorId: string;
  classifier: string;
  imgUrl: string;
  created: number;
  probability: number | string[];
  onClickHandler: () => void;
  mode: string;
  appStore?: AppStore;
  object_detections?:
    | {
        inference_class: string;
        inference_probability: number;
        x0: number;
        y0: number;
        x1: number;
        y1: number;
      }[]
    | undefined;
  reason_for_flag?: string;
  advanced_rule_type?: string;
  advanced_rule_name?: string;
}

const ALPR_DATA = [
  {
    make: "Mercedes Benz",
    licence: "CEY 234 244",
    color: "Yellow",
    model: "A200",
    year: 2019,
  },
  {
    make: "Mercedes Benz",
    licence: "CEY 235 244",
    color: "Red",
    model: "A250",
    year: 2020,
  },
  {
    make: "BMW",
    licence: "CEY 814 744",
    color: "White",
    model: "F30",
    year: 2012,
  },
  {
    make: "BMW",
    licence: "CEY 174 244",
    color: "White",
    model: "F20",
    year: 2015,
  },
  {
    make: "Nissan",
    licence: "CEY 631 429",
    color: "White",
    model: "Atios",
    year: 2018,
  },
  {
    make: "Range Rover",
    licence: "CEY 751 719",
    color: "Black",
    model: "Evoque",
    year: 2017,
  },
  {
    make: "Volkswagen",
    licence: "CAA 321 719",
    color: "Silver",
    model: "TSI Polo",
    year: 2018,
  },
  {
    make: "Ford",
    licence: "CAA 901 719",
    color: "Navy Blue",
    model: "Ranger",
    year: 2019,
  },
];

const AlertViewerMulti: React.FC<Props> = ({
  setSelectedAlert,
  review_logs,
  imgUrl,
  onClickHandler,
  siteName,
  message,
  siteId,
  sensorId,
  classifier,
  created,
  probability,
  sensorName,
  mode,
  autoplay,
  alertList,
  alert,
  selectedAlert,
  flagged,
  silenced,
  appStore,
  object_detections,
  reason_for_flag,
  advanced_rule_type,
  advanced_rule_name,
}) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [loadImages, setLoadImages] = useState(false);
  const [mediaUrls, setMediaUrls] = useState<any>(undefined);
  const [hide, setHide] = useState(false);
  const [useless, setuseless] = useState<any>(null);

  const createdTime = (epocSeconds: number, showBrowser = true) => {
    let today = new Date(epocSeconds * 1000);
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();

    let hr: number | string = new Date(epocSeconds * 1000).getHours();
    let min: number | string = new Date(epocSeconds * 1000).getMinutes();
    let sec: number | string = new Date(epocSeconds * 1000).getSeconds();

    if (selectedAlert) {
      console.debug("selected **", selectedAlert);
    }

    if (min == 0) {
      min = "00";
    }

    if (min.toString().length == 1) {
      min = `0${min}`;
    }

    if (hr.toString().length == 1) {
      hr = `0${hr}`;
    }

    if (sec.toString().length == 1) {
      sec = `0${sec}`;
    }

    return ` ${yyyy}/${mm}/${dd} -  ${hr}:${min}:${sec} ${
      showBrowser ? "(browser time)" : ""
    } `;
  };

  const noImages = [require("./images/noimage.jpeg")];

  const userLogsHandler = (review_logs: review_logs[] | []) => {
    let cmpStr =
      mode === "silenced" ? "suppress_flag set" : "suppress_flag unset";
    console.debug("MODE --->", mode);
    let action = mode === "silenced" ? "silenced" : "unsilenced";
    if (review_logs.length === 1) {
      return `Alert ${action} by ${review_logs[0].log_username},  ${createdTime(
        review_logs[0].created,
        true,
      )}`;
    } else if (review_logs.length === 0) {
      return "none";
    } else {
      for (let i = review_logs.length - 1; i >= 0; i--) {
        if (review_logs[i].content === cmpStr) {
          return `Alert ${action} by ${
            review_logs[i].log_username
          },  ${createdTime(review_logs[i].created, true)}`;
        }
      }
    }
  };

  const flaggedByUser = () => {
    let userInQuestion = "";
    if (selectedAlert.event_data.improve_accuracy_username) {
      userInQuestion = selectedAlert.event_data.improve_accuracy_username;
    } else {
      userInQuestion = selectedAlert.event_data.review_logs[0].log_username;
    }

    let msg = `Image flagged by ${userInQuestion}`;

    if (selectedAlert.event_data.reason_for_flag) {
      msg = msg + ` due to ${selectedAlert.event_data.reason_for_flag}`;
    }

    if (selectedAlert.event_data.improve_accuracy_timestamp) {
      msg =
        msg +
        `, ${createdTime(
          selectedAlert.event_data.improve_accuracy_timestamp,
          true,
        )}`;
    }
    return msg;
  };

  const onChangeHandler = (to: number) => {
    if (selectedAlert.inference_data.length <= 1) return;
    setSelectedIndex(to + 1);
    console.debug("next", to);
    console.debug("nee inf data", selectedAlert.inference_data);

    let cp = { ...selectedAlert };
    cp.event_data = selectedAlert.inference_data[to];
    setSelectedAlert(cp);
    if (imgUrl !== "/assets/img/nodata3.png" && to) {
      // setSelectedAlert(alertList[to - 1])
    }
  };

  console.debug("Selected ->", selectedAlert, "message: ", message);
  var car = selectedAlert?.event_data?.alpr;

  return (
    <div style={{ width: "100%", zIndex: 100 }}>
      <div
        style={{
          minHeight:
            selectedAlert?.event_data?.image_height &&
            selectedAlert?.event_data?.image_height < 488
              ? selectedAlert?.event_data?.image_height + 8
              : 488,
        }}
      >
        {car && (
          <p
            style={{ marginBottom: 30 }}
            className={classes.bold_text}
            onClick={() => {}}
          >
            {car.make && "Make: "}
            <span style={{ color: "grey", marginLeft: 10 }}>
              {car.make}&nbsp;&nbsp;{" "}
            </span>{" "}
            {car.model && "Model: "}
            <span style={{ color: "grey", marginLeft: 10 }}>
              {car.model}&nbsp;&nbsp;{" "}
            </span>
            {car.colour && "Color: "}
            <span style={{ color: "grey", marginLeft: 10 }}>
              {car.colour}&nbsp;&nbsp;{" "}
            </span>
            {car.plate && "License Plate: "}
            <span style={{ color: "grey", marginLeft: 10 }}>
              {car?.plate ? car?.plate.toUpperCase() : ""}
            </span>
          </p>
        )}
        <SlideShow
          isAlpr={true}
          autoplayHook={setuseless}
          flagBtn={setHide}
          width={selectedAlert?.event_data?.image_width}
          height={selectedAlert?.event_data?.image_height}
          object_detections={object_detections}
          autoplay={autoplay}
          AutoPlayy={useless}
          slideImages={
            selectedAlert?.inference_data?.length > 0
              ? [
                  selectedAlert.inference_data.map(
                    (i: any) => i.media_url || noImages,
                  )[0],
                ]
              : noImages
          }
          onChangeHandler={onChangeHandler}
        />
      </div>

      {created !== 0 && (
        <div
          className={classes.hover}
          style={{
            cursor: "pointer",
            textAlign: "left",
            width: "100%",
            marginTop: 20,
          }}
          onClick={() => onClickHandler()}
        >
          {!loadImages && (
            <span
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5,
                paddingRight: 20,
              }}
            >
              <br />
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  paddingRight: 50,
                }}
              >
                {silenced && (
                  <div style={{ alignSelf: " flex-end", width: "5%" }}>
                    <VolumeOffIcon className={classes.icon_blue} />
                  </div>
                )}
                {flagged && (
                  <div style={{ alignSelf: " flex-end", width: "10%" }}>
                    <FlagIcon className={classes.icon_blue} />
                  </div>
                )}
              </div>
            </span>
          )}
          {siteName && (
            <p className={classes.bold_text} onClick={() => {}}>
              Site Name:{" "}
              <span style={{ color: "grey", marginLeft: 10 }}>{siteName}</span>
            </p>
          )}
          {sensorName && sensorId && (
            <p className={classes.bold_text} onClick={() => {}}>
              Camera:{" "}
              <span style={{ color: "grey", marginLeft: 10 }}>
                {sensorName} ({sensorId})
              </span>
            </p>
          )}

          {!!selectedAlert.event_data.rule_type &&
            !!selectedAlert.event_data.rule_name && (
              <p className={classes.bold_text}>
                Advanced Rule:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>
                  {advanced_rule_name} ({advanced_rule_type})
                </span>
              </p>
            )}

          {(!!selectedAlert.event_data.improve_accuracy_username ||
            !!selectedAlert.event_data.reason_for_flag) && (
            <p className={classes.bold_text}>
              Flagging info:{" "}
              <span style={{ color: "grey", marginLeft: 10 }}>
                {flaggedByUser()}
              </span>
            </p>
          )}

          <p className={classes.bold_text}>
            Record Timestamp:{" "}
            <span style={{ color: "grey", marginLeft: 10 }}>
              {createdTime(created)}
            </span>
          </p>
          <br />
        </div>
      )}
    </div>
  );
};

export default AlertViewerMulti;
