import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BillingProfiles from "../BillingProfiles/BillingProfiles";
import { TextField } from "@material-ui/core";
// import {AddBillingProfileFunction} from './../BillingProfiles/BillingProfiles'

interface AddBillingProfileProps {
  open: boolean;
  appStore?: AppStore;
  onClose: () => void;
  AddBillingProfileFunction: (name: string, description: string) => void;
}
interface listBillingItems {
  id: number;
  enabled: boolean;
  name: string;
  description: string;
  meta: { [key: string]: any };
  created: Date | string;
}

const AddBillingProfile: React.FC<AddBillingProfileProps> = ({
  open,
  onClose,
  appStore,
  AddBillingProfileFunction,
}: AddBillingProfileProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
        paddingLeft: "2%",
        paddingRight: "2%",
      },
    }),
  )(TableRow);

  const classes = useStyles();
  const [inputName, setInputName] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [textError, setTextError] = useState(false);
  const handleInputNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setTextError(false);
    setInputName(evt.target.value);
  };
  const handleInputDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    setInputDescription(event.target.value);
  };

  useEffect(() => {}, []);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <span style={{ fontSize: "25px" }}>Create A Billing Profile</span>
        </DialogTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "5%",
            marginBottom: "5%",
            width: "80%",
            marginLeft: "10%",
          }}
        >
          <TextField
            style={{ marginBottom: "5%" }}
            required
            autoFocus
            error={textError}
            // error={inputName === ''}
            variant="outlined"
            label="add billing profile name"
            value={inputName}
            onChange={handleInputNameChange}
            helperText={
              textError === true ? "Profile name field cannot be blank" : ""
            }
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />

          <TextField
            autoFocus
            variant="outlined"
            label="add billing profile description"
            value={inputDescription}
            onChange={handleInputDescriptionChange}
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
        </div>

        <div
          style={{ marginTop: "5%", marginBottom: "2%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          <Button
            style={{ textOverflow: "ellipsis" }}
            variant="contained"
            className={classes.cancel_button_billing}
            onClick={() => {
              if (inputName != "") {
                AddBillingProfileFunction(inputName, inputDescription);
                onClose();
              } else {
                setTextError(true);
              }
            }}
          >
            <span
              style={{ textOverflow: "ellipsis" }}
              className={classes.bold_text}
            >
              Create New Profile
            </span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default AddBillingProfile;
