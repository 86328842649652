import React, { useState, useEffect } from "react";
import { AppStore } from "../../stores/AppStore";
import {
  Org,
  SiteHealth,
  SensorHealth,
  MainMenu,
  Activity,
  HealthTimelineCount,
  SensorHealthTimelineCount,
} from "../../services/FunctionService";

interface MainDrawerProps {
  appStore?: AppStore;
  setCurrentOrg: (d: any) => void;
  currentOrg: Org;
  mainMenu: MainMenu;
  changeMainMenu: (d: MainMenu) => void;
  authhandler: any;
  selectedItem: { [key: string]: any };
}

const useRightDrawer = (
  appStore: AppStore | undefined,
  currentOrg: Org,
  interval: number,
  selectedItem: { [key: string]: any },
) => {
  const [sensorhealth, setSensorHealth] = useState<SensorHealth | undefined>(
    undefined,
  );
  const [healthsites, setHealthSites] = useState<SiteHealth[] | undefined>(
    undefined,
  );
  const [activity, setActivity] = useState<Activity | undefined>({
    alert_counts: [],
    inference_counts: [],
  });
  const [healthtimeline, setHealthTimeLine] = useState<
    HealthTimelineCount[] | undefined
  >(undefined);
  const [sensorhealthtimeline, setSensorHealthTimeline] = useState<
    SensorHealthTimelineCount[] | undefined
  >(undefined);

  const controllerRef = React.useRef<AbortController | null>();

  function convertIntervalToHours(interval: number) {
    let hours = 2;
    if (interval == 17) {
      hours = 4;
    }
    if (interval == 29) {
      hours = 8;
    }
    if (interval == 44) {
      hours = 12;
    }
    if (interval == 62) {
      hours = 24;
    }
    if (interval == 84) {
      hours = 48;
    }
    if (interval == 110) {
      hours = 168;
    }
    if (interval == 148) {
      hours = 336;
    }
    return hours;
  }

  useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && currentOrg && selectedItem.type !== "site") {
        const healthforsites = await appStore?.functionService.getHealthForOrg(
          token,
          currentOrg.org_id,
        );
        if (healthforsites) {
          setHealthSites(healthforsites);
        }
      }
    };
    fetchData();
    const refresh = setInterval(() => fetchData(), 1000 * 60 * 2);
    return () => clearInterval(refresh);
  }, [currentOrg.org_id]);

  // access_token: string,
  // top_org_id: number | null = null,
  // start_time_filter: number,
  // bin_secs: number,
  // end_time_filter?: number | undefined,
  // site_ids: string | null = null,
  // sensor_ids: string | null = null,

  // await appStore?.functionService.getActivityForSensors(
  //   token,
  //   [selectedItem.sensor_id],
  //   now.getTime() / 1000 - 3600 * hours,
  //   hours * 60
  // );

  const fetchOrgAlertActivity = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && currentOrg && currentOrg.org_id != undefined && selectedItem) {
      if (selectedItem.type == "org" || selectedItem.type == undefined) {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAlertActivityStats(
            token,
            currentOrg.org_id,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            null,
            null,
            controllerRef.current?.signal,
          );
        if (activity.success) {
          // setActivity(activity.msg);
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              alert_counts: activity.msg.alert_counts,
            };
          });
        }
        const health = await appStore?.functionService.getHealthTimeLineForOrg(
          token,
          currentOrg.org_id,
          now.getTime() / 1000 - 3600 * hours,
          hours * 60,
          controllerRef.current?.signal,
        );
        if (health.success) {
          setHealthTimeLine(health.msg);
        }
      }
      controllerRef.current = null;
    }
  };

  const fetchOrgInferenceActivity = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && currentOrg && currentOrg.org_id != undefined && selectedItem) {
      if (selectedItem.type == "org" || selectedItem.type == undefined) {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAInferenceActivityStats(
            token,
            currentOrg.org_id,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            null,
            null,
            controllerRef.current?.signal,
          );
        if (activity.success) {
          // setActivity(activity.msg);
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              inference_counts: activity.msg.inference_counts,
            };
          });
        }
        const health = await appStore?.functionService.getHealthTimeLineForOrg(
          token,
          currentOrg.org_id,
          now.getTime() / 1000 - 3600 * hours,
          hours * 60,
          controllerRef.current?.signal,
        );
        if (health.success) {
          setHealthTimeLine(health.msg);
        }
      }
      controllerRef.current = null;
    }
  };

  const fetchAlertData = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && selectedItem) {
      if (selectedItem.type == "site") {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAlertActivityStats(
            token,
            null,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            `[${selectedItem.site_id}]`,
            null,
            controllerRef.current?.signal,
          );
        if (activity.success) {
          // setActivity(activity.msg);
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              alert_counts: activity.msg.alert_counts,
            };
          });
        }
        const health =
          await appStore?.functionService.getHealthTimeLineForSites(
            token,
            [selectedItem.site_id],
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            controllerRef.current?.signal,
          );
        if (health.success) {
          setSensorHealthTimeline(undefined);
          setHealthTimeLine(health.msg);
        }
      }
      if (selectedItem.type == "sensor") {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAlertActivityStats(
            token,
            null,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            null,
            `[${selectedItem.sensor_id}]`,
            controllerRef.current?.signal,
          );
        if (activity.success) {
          // setActivity(activity.msg);
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              alert_counts: activity.msg.alert_counts,
            };
          });
        }
        const sensorhealthtimeline =
          await appStore?.functionService.getHealthTimeLineForSensor(
            token,
            selectedItem.site_id,
            selectedItem.sensor_id_str,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            controllerRef.current?.signal,
          );
        if (sensorhealthtimeline.success) {
          setHealthTimeLine(undefined);
          setSensorHealthTimeline(sensorhealthtimeline.msg);
        }
      }
      controllerRef.current = null;
    }
  };

  const fetchInferenceData = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && selectedItem) {
      if (selectedItem.type == "site") {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAInferenceActivityStats(
            token,
            null,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            `[${selectedItem.site_id}]`,
            null,
          );
        if (activity.success) {
          // setActivity(activity.msg);
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              inference_counts: activity.msg.inference_counts,
            };
          });
        }
        const health =
          await appStore?.functionService.getHealthTimeLineForSites(
            token,
            [selectedItem.site_id],
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
          );
        if (health.success) {
          setSensorHealthTimeline(undefined);
          setHealthTimeLine(health.msg);
        }
      }
      if (selectedItem.type == "sensor") {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAInferenceActivityStats(
            token,
            null,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            null,
            `[${selectedItem.sensor_id}]`,
          );
        if (activity.success) {
          // setActivity(activity.msg);
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              inference_counts: activity.msg.inference_counts,
            };
          });
        }
        const sensorhealthtimeline =
          await appStore?.functionService.getHealthTimeLineForSensor(
            token,
            selectedItem.site_id,
            selectedItem.sensor_id_str,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
          );
        if (sensorhealthtimeline.success) {
          setHealthTimeLine(undefined);
          setSensorHealthTimeline(sensorhealthtimeline.msg);
        }
      }
      controllerRef.current = null;
    }
  };

  useEffect(() => {
    setActivity({ alert_counts: [], inference_counts: [] });
    fetchOrgAlertActivity();
    fetchOrgInferenceActivity();
    const refresh = setInterval(
      () => {
        fetchOrgAlertActivity();
        fetchOrgInferenceActivity();
      },
      1000 * 60 * 2,
    );
    return () => clearInterval(refresh);
  }, [currentOrg, interval]);

  useEffect(() => {
    fetchAlertData();
    fetchInferenceData();
    const refresh = setInterval(
      () => {
        fetchAlertData();
        fetchInferenceData();
      },
      1000 * 60 * 2,
    );
    return () => clearInterval(refresh);
  }, [selectedItem, interval]);

  return {
    activity,
    sensorhealthtimeline,
    healthtimeline,
    healthsites,
    sensorhealth,
  };
};

export default useRightDrawer;
