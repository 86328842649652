import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Alert from "@material-ui/lab/Alert";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import {
  Org,
  listBillingRates,
  listBillingRatesItems,
  listClassLabels,
  Locale,
  addBillingOrgResult,
  GetSentinelResult,
  addOrgToImmixResult,
} from "../../../services/FunctionService";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

import { time_zones } from "../../../hooks/Timezones";

interface ImmixProps {
  open: boolean;
  appStore?: AppStore;
  org: Org | undefined;
  onClose: () => void;
}
const Immix: React.FC<ImmixProps> = ({
  open,
  appStore,
  org,
  onClose,
}: ImmixProps) => {
  const StyledTableRowSMTP = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
      },
    }),
  )(TableRow);
  const classes = useStyles();

  const [renderValue, setRenderValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultFailure, setResultFailure] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [openResultMessage, setOpenResultMessage] = useState(false);
  const [timezone, setTimeZone] = React.useState("");
  const [threshold, setThreshold] = React.useState<number>(35);
  const [billingRateItems, setBillingRateItems] = useState<
    listBillingRatesItems[]
  >([]);
  const [selectError, setSelectError] = useState(false);
  const [selectLabelError, setSelectLabelError] = useState(false);
  const [selectTimeError, setSelectTimeError] = useState(false);
  const [labelValue, setLabelValue] = useState<[string]>([""]);
  const [localeValue, setLocaleValue] = useState("");
  const [billing_rate_id, setBilling_rate_id] = React.useState<
    number | undefined
  >(undefined);
  const [classLabels, setClassLabels] = useState<string[]>([]);
  const [locales, setLocales] = useState<Locale[]>([]);
  const [returnNonDetectionVideos, setReturnNonDetectionVideos] =
    useState(false);
  const [selectLocaleError, setSelectLocaleError] = useState(false);
  const [ignoreStationaryVehicles, setIgnoreStationaryVehicles] =
    useState(true);
  const [token, setToken] = useState("");
  const [addToGroup, setAddToGroup] = useState(true);
  const [metaValues, setMetaValues] = useState<{ [key: string]: any }>({});

  const handleLocaleChange = (event: React.ChangeEvent<any>) => {
    setSelectLocaleError(false);
    setLocaleValue(event.target.value);
  };

  const addProductOrgFunc = async (
    org_id: number,
    billing_rate_id: number,
    locale: string,
    timezone: string,
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const addProductOrgResult: addOrgToImmixResult =
        await appStore?.functionService.addOrgToImmixProduct(
          token,
          org_id,
          billing_rate_id,
          locale,
          timezone,
        );
      if (addProductOrgResult) {
        if (addProductOrgResult?.success) {
          setLoading(false);
          setToken(addProductOrgResult?.msg.immix_group_password);
          setResultMessage("Added successfully");
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        } else {
          setLoading(false);
          setResultMessage("failed to add group to immix product");
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        }
      } else {
        setLoading(false);
        setResultFailure(true);
      }
    }
  };

  const handleAddToProduct = () => {
    if (billing_rate_id) {
      if (addToGroup !== true) {
        addProductOrgFunc(org!.org_id, billing_rate_id!, localeValue, timezone);
      } else {
        if (localeValue) {
          if (timezone) {
            addProductOrgFunc(
              org!.org_id,
              billing_rate_id!,
              localeValue,
              timezone,
            );
          } else {
            setSelectTimeError(true);
          }
        } else {
          setSelectLocaleError(true);
        }
      }
    } else {
      setSelectError(true);
    }
  };
  const handleTimeZoneChange = (event: React.ChangeEvent<any>) => {
    setSelectTimeError(false);
    setOpenResultMessage(false);
    setResultMessage("");
    setTimeZone(event.target.value);
  };
  const handleChange = (event: React.ChangeEvent<any>) => {
    setSelectError(false);
    setOpenResultMessage(false);
    setResultMessage("");
    setBilling_rate_id(+event.target.value);
    let name = billingRateItems.filter(
      (item) => item.id === +event.target.value,
    )[0].classifier;
    getClassLabels(name);
  };

  const handleLabelsChange = (event: React.ChangeEvent<any>) => {
    setSelectLabelError(false);
    setOpenResultMessage(false);
    setResultMessage("");
    setLabelValue(event.target.value);
  };
  const getClassLabels = async (name: string) => {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const labelResults: listClassLabels =
        await appStore?.functionService.listClassLabels(token, name);
      if (labelResults) {
        if (labelResults.success) {
          setClassLabels(labelResults.class_labels);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
      setLoading(false);
    }
  };
  const handleThresholdChange = (event: React.ChangeEvent<any>) => {
    setThreshold(+event.target.value);
  };

  var timezones = [];
  timezones.push(
    <MenuItem value="Africa/Johannesburg">
      <span className={classes.bold_text}>Africa/Johannesburg</span>
    </MenuItem>,
  );
  timezones.push(
    <MenuItem value="Europe/London">
      <span className={classes.bold_text}>Europe/London</span>
    </MenuItem>,
  );

  useEffect(() => {
    const fetchBillingRates = async () => {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && org) {
        const RatesResults: listBillingRates =
          await appStore?.functionService.listBillingRates(token);
        if (RatesResults) {
          if (RatesResults.success) {
            setBillingRateItems(
              RatesResults.billing_rates.filter(
                (item) => item.data_origin.name === "immix_ailink_human1",
              ),
            );
            const showToken: addOrgToImmixResult =
              await appStore?.functionService.getImmix(token, org!.org_id);
            if (showToken) {
              if (showToken.success) {
                if (showToken.msg.hasOwnProperty("immix_group_password")) {
                  setToken(showToken.msg.immix_group_password);
                }
                if (showToken.msg.hasOwnProperty("immix_billing_rate_id")) {
                  let rate_items = RatesResults.billing_rates.filter(
                    (item) => item.id === showToken.msg.immix_billing_rate_id,
                  );
                  setBillingRateItems(rate_items);
                  setBilling_rate_id(showToken.msg.immix_billing_rate_id);
                }
                if (showToken.msg.hasOwnProperty("immix_timezone_str")) {
                  setTimeZone(showToken.msg["immix_timezone_str"]);
                }
                if (showToken.msg.hasOwnProperty("immix_locale")) {
                  setLocaleValue(showToken.msg["immix_locale"]);
                }
                setAddToGroup(false);
                setOpenResultMessage(false);
              } else {
                setToken("");
                setBilling_rate_id(undefined);
                setLocaleValue("");
                setLabelValue([""]);
                setThreshold(50);
                setTimeZone("");
                setClassLabels([]);
                setReturnNonDetectionVideos(false);
                setIgnoreStationaryVehicles(true);
                setAddToGroup(true);
              }
            }
            var all_locales = await appStore?.functionService.getLocales(token);
            if (all_locales) {
              setLocales(all_locales);
            }
          }
        }
      }
      setLoading(false);
    };
    fetchBillingRates();
  }, [org, renderValue]);

  var locales_menu: React.ReactNode[] = [];
  var locales_list: string[] = [];
  if (locales) {
    locales.forEach((locale_menu) => {
      if (!locales_list.includes(locale_menu.locale)) {
        locales_menu.push(
          <MenuItem value={locale_menu.locale}>
            <span className={classes.bold_text}>{locale_menu.locale}</span>
          </MenuItem>,
        );
        locales_list.push(locale_menu.locale);
      }
    });
  }
  useEffect(() => {
    if (resultFailure) {
      const timeId = setTimeout(() => {
        // After 5 seconds set the show value to false

        setResultFailure(false);
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }
    // when the component is mounted, the alert is displayed for 3 seconds
  }, [resultFailure]);
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogTitle
          id="simple-dialog-title"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <span
            className={classes.bold_text}
            style={{ fontSize: 20, color: "primary" }}
          >
            Configure {org ? org.org_name : ""} for Immix
          </span>
        </DialogTitle>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div style={{ marginLeft: "25%" }}>
          {addToGroup !== true ? null : (
            <Box mb={2} mt={2} justifyContent="left">
              <FormControl style={{ width: 450 }}>
                <InputLabel
                  style={{ paddingLeft: "1%" }}
                  id="demo-controlled-open-select-label"
                >
                  {" "}
                  Product
                </InputLabel>
                <Select
                  error={selectError}
                  label=" Username"
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  variant="outlined"
                  value={billing_rate_id}
                  // helperText={
                  //   selectError === true ? 'Profile name field cannot be blank' : ''
                  // }
                  onChange={(e) => handleChange(e)}
                >
                  {billingRateItems &&
                    billingRateItems.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.product}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          )}

          <Box mt={2} mb={2} justifyContent="left">
            <FormControl style={{ width: 450 }} variant="outlined">
              <InputLabel
                style={{ paddingLeft: "1%" }}
                id="demo-controlled-open-select-label"
              >
                {" "}
                Time Zone
              </InputLabel>

              <Select
                error={selectTimeError}
                value={timezone}
                onChange={handleTimeZoneChange}
              >
                {time_zones.map((zone) => (
                  <MenuItem value={zone}>{zone}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {addToGroup !== true
            ? null
            : locales.length > 0 && (
                <Box mb={2} mt={2} justifyContent="left">
                  <FormControl variant="outlined" style={{ width: 450 }}>
                    <InputLabel
                      style={{ paddingLeft: "1%" }}
                      id="demo-controlled-open-select-label"
                    >
                      {" "}
                      Locale
                    </InputLabel>
                    <Select
                      error={selectLocaleError}
                      value={localeValue}
                      onChange={handleLocaleChange}
                    >
                      {locales_menu}
                    </Select>
                  </FormControl>
                </Box>
              )}

          {openResultMessage ? (
            <Box mt={2} mb={2} justifyContent="left">
              <Alert
                style={{ width: 450 }}
                variant="outlined"
                severity={
                  resultMessage.includes("successfully") ? "success" : "error"
                }
                onClose={() => {
                  setOpenResultMessage(false);
                  setResultMessage("");
                }}
              >
                {resultMessage}
              </Alert>
            </Box>
          ) : null}
        </div>
      )}
      <Box mt={2} mb={2} justifyContent="center" style={{ marginLeft: "25%" }}>
        {loading ? null : token !== "" && billingRateItems.length > 0 ? (
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              marginTop: 15,
              borderRadius: 15,
              overflow: "hidden",
              width: 450,
            }}
          >
            <TableContainer>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    Username:
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    {org?.org_id}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    PRODUCT :
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    {billingRateItems[0].product}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text}>LOCALE:</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    {localeValue}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text}>Token:</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>{token}</Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text}>URL:</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    https://immix-ailink.deepalert.ai/
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
            </TableContainer>
          </div>
        ) : null}
      </Box>

      {resultFailure ? (
        <Box textAlign="center" mt={2} mb={2}>
          <Alert variant="outlined" severity="error">
            error in obtaining immix information
          </Alert>
        </Box>
      ) : null}

      <div
        className={classes.twoButtonsInARow}
        style={{ marginBottom: 20, marginTop: 20 }}
      >
        <Button
          variant="contained"
          className="save"
          onClick={() => {
            setBilling_rate_id(undefined);
            setLocaleValue("");
            setLabelValue([""]);
            setThreshold(50);
            setBillingRateItems([]);
            setTimeZone("");
            setClassLabels([]);
            setToken("");
            setBillingRateItems([]);
            setReturnNonDetectionVideos(false);
            setIgnoreStationaryVehicles(true);
            setOpenResultMessage(false);
            setResultMessage("");
            setRenderValue((value) => !value);
            onClose();
          }}
        >
          Dismiss
        </Button>
        {loading ? null : (
          <Button
            variant="contained"
            className="save"
            onClick={handleAddToProduct}
          >
            {addToGroup === true ? "Add" : "Update"}
          </Button>
        )}
      </div>
    </Dialog>
  );
};

export default Immix;
