import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddLogosModal from "../MainExpansionPanel/AddlogosModal";
import { useStyles } from "../../styling/global/global";
import CustomModal from "../../UI/CustomModal";
import Frontel from "../../modals/Frontel/Frontel";
import FrontelSiteList from "../../UI/FrontelSiteList";

interface GroupExpansionPanelMenuProps {
  handleDefaultRulesOrg?: any;
  defaultRules?: boolean;
  openAddDefaultsModal?: any;
  activeOrg?: any;
  reloadLogos?: any;
  currentOrg: any;
  appStore: any;
  del: boolean;
  edit: boolean;
  enable: boolean;
  enabled: boolean;
  advancedsettings: boolean;
  moreinfo: boolean;
  notes: boolean;
  handleEdit: (x: any) => void;
  handleEnable: () => void;
  handleDelete: () => void;
  handleAdvancedSettings: () => void;
  handleMoreInfo: () => void;
  handleNotes: () => void;
  setSelectedItem: (item: any, editing: boolean) => void;
  handleAddGroupToFrontel: () => void;
}
const GroupExpansionPanelMenu: React.FC<GroupExpansionPanelMenuProps> = ({
  defaultRules,
  openAddDefaultsModal,
  handleDefaultRulesOrg,
  activeOrg,
  reloadLogos,
  appStore,
  currentOrg,
  del,
  edit,
  enable,
  enabled,
  advancedsettings,
  moreinfo,
  notes,
  handleMoreInfo,
  handleAdvancedSettings,
  handleEdit,
  handleEnable,
  handleDelete,
  handleNotes,
  setSelectedItem,
  handleAddGroupToFrontel,
}: GroupExpansionPanelMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const enabletext = enabled ? "disable" : "enable";
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [Thumbnail, setThumbnail] = useState<{
    logo: { stringb64: string } | null;
  }>({
    logo: activeOrg?.logo?.thumbnail,
  });

  const [openModal, setOpenModal] = useState(false);
  const [openFrontelSites, setOpenFrontelSites] = useState(false);
  const [openFrontelOrg, setOpenFrontelOrg] = useState(false);

  const modalHandler = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const Edit = (x: any) => {
    setAnchorEl(null);
    handleEdit(x);
  };
  const Enable = () => {
    setAnchorEl(null);
    handleEnable();
  };
  const Delete = () => {
    setAnchorEl(null);
    handleDelete();
  };
  const AdvancedSettings = () => {
    setAnchorEl(null);
    handleAdvancedSettings();
  };
  const MoreInfo = () => {
    setAnchorEl(null);
    handleMoreInfo();
  };
  const EditNotes = () => {
    setAnchorEl(null);
    handleNotes();
  };

  const handleGroupOrg = () => {
    setAnchorEl(null);
    openAddDefaultsModal();
    handleDefaultRulesOrg!();
  };

  const AddGroupToFrontel = () => {
    setAnchorEl(null);
    handleAddGroupToFrontel();
  };

  const closeAddGroupToFrontel = () => {
    setOpenFrontelOrg(false);
  };

  var menuitems: React.ReactNode[] = [];
  if (edit) {
    menuitems.push(
      <MenuItem onClick={Edit}>
        <span className={classes.bold_text}>edit</span>
      </MenuItem>,
    );
  }
  if (advancedsettings) {
    menuitems.push(
      <MenuItem onClick={AdvancedSettings}>
        <span className={classes.bold_text}>advanced settings</span>
      </MenuItem>,
    );
  }
  if (enable) {
    menuitems.push(
      <MenuItem onClick={Enable}>
        <span className={classes.bold_text}>{enabletext}</span>
      </MenuItem>,
    );
  }

  if (moreinfo) {
    menuitems.push(
      <MenuItem onClick={MoreInfo}>
        <span className={classes.bold_text}>rename group</span>
      </MenuItem>,
    );
  }
  if (del) {
    menuitems.push(
      <MenuItem onClick={Delete}>
        <span className={classes.bold_text}>delete group</span>
      </MenuItem>,
    );
  }

  if (notes) {
    menuitems.push(
      <MenuItem onClick={EditNotes}>
        <span className={classes.bold_text}>alert web interface settings</span>
      </MenuItem>,
    );
  }

  // menuitems.push(
  //   <MenuItem onClick={modalHandler}>
  //     <span className={classes.bold_text}>{"add logo"}</span>
  //   </MenuItem>
  // );
  if (!Thumbnail?.logo?.stringb64 && currentOrg) {
    menuitems.push(
      <MenuItem onClick={modalHandler}>
        <span className={classes.bold_text}>{"add logo"}</span>
      </MenuItem>,
    );
  }

  if (Thumbnail?.logo?.stringb64 && currentOrg) {
    menuitems.push(
      <MenuItem onClick={modalHandler}>
        <span className={classes.bold_text}>{"edit logo"}</span>
      </MenuItem>,
    );
  }

  if (currentOrg) {
    menuitems.push(
      <MenuItem onClick={() => setOpenFrontelSites(true)}>
        <span className={classes.bold_text}>{"view frontel sites"}</span>
      </MenuItem>,
    );
  }

  if (activeOrg.meta?.frontel_ip) {
    menuitems.push(
      <MenuItem onClick={AddGroupToFrontel}>
        <span className={classes.bold_text}>Edit Frontel Org</span>
      </MenuItem>,
    );
  } else {
    menuitems.push(
      <MenuItem onClick={AddGroupToFrontel}>
        <span className={classes.bold_text}>Frontel Org Activation</span>
      </MenuItem>,
    );
  }

  if (defaultRules) {
    menuitems.push(
      <MenuItem onClick={handleGroupOrg}>
        <span className={classes.bold_text}>{"default rules settings"}</span>
      </MenuItem>,
    );
  }

  return (
    <div>
      <AddLogosModal
        activeOrg={activeOrg}
        appStore={appStore}
        currentOrg={currentOrg}
        openModal={openModal}
        handleClose={setOpenModal}
      ></AddLogosModal>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      {
        <CustomModal
          closeHandler={() => {
            setOpenFrontelSites(false);
          }}
          open={openFrontelSites}
        >
          <FrontelSiteList
            appStore={appStore}
            setSelectedItem={setSelectedItem}
            currentOrgId={currentOrg.org_id || activeOrg.org_id}
          />
        </CustomModal>
      }
      {
        <CustomModal
          closeHandler={() => {
            setOpenFrontelOrg(false);
          }}
          open={openFrontelOrg}
        >
          <Frontel
            open={openFrontelOrg}
            appStore={appStore}
            org={currentOrg}
            site={undefined}
            hub={undefined}
            onClose={closeAddGroupToFrontel}
          />
        </CustomModal>
      }
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onMouseLeave={handleClose}
      >
        {menuitems}
      </Menu>
    </div>
  );
};
export default GroupExpansionPanelMenu;
