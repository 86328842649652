import LuxonUtils from "@date-io/luxon";
import { Guid } from "guid-typescript";

export class TimeEntrySetSingle {
  entries: TimeEntrySingle[];
  setString?: string;

  constructor(setString?: string) {
    this.entries = [];
    if (setString && setString.startsWith("[") && setString.endsWith("]")) {
      this.setString = setString;
      this.resetEntries();
    }
  }

  private static TimeEntryRegex =
    /^"(?<days>(?:(?:Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s*)+)",\s*"(?:(?<time>\d{2}:\d{2}:\d{2})\s*)?"$/;
  private static EntryFullMatch = /^\[\((.+)\)\]/;

  resetEntries = () => {
    const entries = [];
    if (
      this.setString &&
      TimeEntrySetSingle.EntryFullMatch.test(this.setString)
    ) {
      const entryStrings = this.setString
        .replace("[(", "")
        .replace(")]", "")
        .split(/\),\s*\(/);
      for (const entry of entryStrings) {
        const entryMatch = TimeEntrySetSingle.TimeEntryRegex.exec(entry);
        if (entryMatch && entryMatch.groups) {
          const days = entryMatch.groups.days.split(/,\s*/);
          entries.push(new TimeEntrySingle(days, entryMatch.groups.time));
        }
      }
    }
    this.entries = entries;
  };

  clearEntries = () => {
    this.entries = [];
  };

  updateString = () => {
    this.setString = `[${this.entries
      .map((entry) => entry.timeString)
      .join(",")}]`;
  };

  addTimeEntry = () => {
    this.entries.push(
      new TimeEntrySingle(
        ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        "00:00:00",
      ),
    );
  };

  deleteTimeEntry = (timeEntry: TimeEntrySingle) => {
    const index = this.entries.indexOf(timeEntry);
    this.entries.splice(index, 1);
  };

  toString = () => {
    var entriesstring: string = "[";
    if (this.entries) {
      this.entries.forEach(
        (entry: { days: { toString: () => any }; time: any }) => {
          entriesstring =
            entriesstring + '("' + entry.days + '","' + entry.time + '")' + ",";
        },
      );
    }
    if (entriesstring.length > 1) {
      entriesstring = entriesstring.substr(0, entriesstring.length - 1);
    }
    entriesstring = entriesstring + "]";

    return entriesstring;
  };
}

export class TimeEntrySingle {
  time: string;
  days: string[];
  key: Guid;

  private static TimeRegex =
    /^(?<hours>[0-1]?[0-9]|2[0-4]):(?<mins>[0-5][0-9]):(?<seconds>[0-5][0-9])$/;
  private static ShortenedTimeRegex =
    /^(?<hours>[0-1]?[0-9]|2[0-4]):(?<mins>[0-5][0-9])$/;
  private static DayRegex = /Mon|Tue|Wed|Thu|Fri|Sat|Sun/;

  private static ValidateTimeString(timeString: string) {
    if (!timeString) {
      return false;
    }
    return TimeEntrySingle.TimeRegex.test(timeString);
  }

  private static ValidateTimePair(time: string) {
    var utils = new LuxonUtils();
    if (!time) {
      return false;
    }
    if (!TimeEntrySingle.ValidateTimeString(time)) {
      return false;
    }
    return true;
  }

  get valid() {
    // Check if there are valid days
    if (
      !this.days ||
      !this.days.length ||
      !this.days.every((d) => TimeEntrySingle.DayRegex.test(d))
    ) {
      return false;
    }

    // Check required times
    return TimeEntrySingle.ValidateTimePair(this.time);
  }

  get timeString() {
    let timeString = `${this.time}`;
    return `("${this.days.join(",")}", "${timeString}")`;
  }

  setDays = (days: string[]) => {
    this.days = days;
  };

  setTime = (timeString: string) => {
    if (!timeString) {
      return;
    }

    // Append seconds to shortened time strings
    if (TimeEntrySingle.ShortenedTimeRegex.test(timeString)) {
      timeString += ":00";
    }

    // Ensure time matches correct format
    if (!TimeEntrySingle.TimeRegex.test(timeString)) {
      return false;
    }

    this.time = timeString;
  };
  constructor(days: string[], time: string) {
    this.time = time;

    this.days = days;
    this.key = Guid.create();
  }
}
