import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "react-quill/dist/quill.snow.css";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";

import { useStyles } from "../../styling/global/global";
import {
  WebChannelResults,
  AlertChannel,
  AlertEndPoint,
  AlertEndPointDef,
  MultiEmail,
  UpdateResult,
  UpdateSensorResult,
  User,
  UserEndPointRoles,
  UserRevisedWebRoles,
  UpdateWebChannelResults,
  Org,
  Site,
  Hub,
  Sensor,
  TalkbackRelay,
  TalkbackRelayElements,
} from "../../../services/FunctionService";
import Grid from "@material-ui/core/Grid";
import { AppBar, IconButton, TextField, Toolbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import { AppStore } from "../../../stores/AppStore";
import { Guid } from "guid-typescript";
import { AddCircle, MenuTwoTone } from "@material-ui/icons";
import ViewUsers from "./ViewUsers/ViewUsers";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import MessageModal from "../MessageModal/MessageModal";
import { Autocomplete } from "@material-ui/lab";
import { index } from "d3";
// import { hostname } from "os";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export interface NewData {
  user_id: number;
  username: string;
  first_name: string;
  last_name: string;
  web_alerts: boolean;
  web_alert_escalations: boolean;
  web_mute_controls: boolean;
  web_alert_camera_filter: boolean;
  web_alert_history: boolean;
  web_bulk_actions: boolean;
}
class impOrg implements Org {
  org_id!: number;
  org_name!: string;
  org_tree!: number[];
  org_level!: number;
  enabled!: boolean;
  sites!: Site[];
  hubs!: Hub[];
  orgs!: Org[];
  type!: string;
  created!: string;
  sub_org_count!: number;
  site_count!: number;
  hub_count!: number;
}

class newImpUserWebRoles implements UserRevisedWebRoles {
  first_name!: string;
  last_name!: string;
  user_id!: number;
  username!: string;
  web_alerts!: boolean;
  web_alert_escalations!: boolean;
  web_mute_controls!: boolean;
  web_alert_camera_filter!: boolean;
  web_alert_history!: boolean;
  web_bulk_actions!: boolean;
}

class impUserEndPointRoles implements UserEndPointRoles {
  key!: Guid;
  user_id!: number;
  web_roles!: { [key: string]: any };
  // end_point_roles!: { [key: string]: any };
}
interface MultiEmailWithError {
  key: Guid;
  email: string;
  parent_id: number;
  error: boolean;
}
class impMultiEmailWithError implements MultiEmailWithError {
  key!: Guid;
  email!: string;
  parent_id!: number;
  error!: boolean;
}

interface WebSettingsProps {
  org_id: number;
  open: boolean;
  sensors: Sensor[];
  onClose: () => void;
  appStore?: AppStore;
  alertchannel: AlertChannel;
  endpoint: AlertEndPoint;
  endpointdefs?: AlertEndPointDef[];
}
interface SensorObject {
  MethodError: boolean;
  UrlError: boolean;
  UsernameError: boolean;
  PasswordError: boolean;
  talkbackMethod: string;
  meta: { [key: string]: any };
  talkbackUrl: string;
  talkbackUsername: string;
  talkbackPassword: string;
  sensor_string: string;
  sensor_id: number;
  sensor_name: string;
  talkback_relay_id?: number;
}
interface SensorGeneric {
  talkbackMethod: string;
  talkbackUrl: string;
  talkbackUsername: string;
  talkbackPassword: string;
  relay: number;
}
const theme = createMuiTheme({
  overrides: {
    MuiTab: {
      root: {
        "&.Mui-selected": {
          background: "rgba(255,255,255,0.09)",
          color: "white",
          fontWeight: "bold",
        },
      },
    },
  },
});

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const WebSettings: React.FC<WebSettingsProps> = ({
  org_id,
  open,
  sensors,
  appStore,
  endpoint,
  alertchannel,
  endpointdefs,
  onClose,
}: WebSettingsProps) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // COLLECT THE SITE SOP AS AN ARRAY
  const [siteSOPList, setSiteSOPList] = useState<string[]>([]);

  const [siteSOP, setSiteSOP] = useState<string>("");

  const collectSiteSOPHandler = (event: any) => {
    setSiteSOP(event.target.value);
  };

  // A HANDLER FOR ADDING A SITE SOP
  const addSiteSOPHandler = () => {
    if (siteSOP === "") {
      return;
    }
    setSiteSOPList((prev: string[]) => {
      return [siteSOP, ...prev];
    });
    setSiteSOP("");
  };

  // DELETE HANDLER FOR DELETING A SITE SOP
  const deleteSiteSOP = (sop: string) => {
    const updatedItems = siteSOPList?.filter((item) => item !== sop);
    setSiteSOPList(updatedItems);
  };

  // CAMERA SOP LOGIC BELOW:
  const [cameraSOPList, setCameraSOPList] = useState<any>([]);
  const [cameraSensor, setCameraSensor] = useState<any>("");
  const [cameraSOP, setCameraSOP] = useState<any>("");

  const collectCameraSOPHandler = (event: any) => {
    setCameraSOP(event.target.value);
  };

  const collectCameraSensorHandler = (event: any) => {
    setCameraSensor(event?.target?.value);
  };

  // A HANDLER FOR ADDING A SITE SOP
  const addCameraSOPHandler = () => {
    if (cameraSensor === "" || cameraSOP === "") {
      return;
    }

    const findIndex = cameraSOPList?.findIndex(
      (sop: any) => sop?.sensor_id_str === cameraSensor,
    );

    if (findIndex !== -1) {
      // Sensor already exists, update its sop_list
      let newSensorData = {
        sensor_id_str: cameraSensor,
        sop_list: [cameraSOP, ...(cameraSOPList[findIndex]?.sop_list || [])],
      };

      // Update the cameraSOPList immutably
      let updatedSOPList = [...cameraSOPList];
      updatedSOPList[findIndex] = newSensorData;

      // Set the updated list
      setCameraSOPList(updatedSOPList);
    } else {
      // Sensor doesn't exist, create new sensor data
      let newSensorData = {
        sensor_id_str: cameraSensor,
        sop_list: [cameraSOP],
      };

      // Add the new sensor to the list
      setCameraSOPList((prev: any) => {
        return [newSensorData, ...prev];
      });
    }

    // Reset the input values
    setCameraSensor("");
    setCameraSOP("");
  };

  // DELETE HANDLER FOR DELETING A SITE SOP
  const deleteCameraSOP = (sop: string) => {
    const updatedItems = cameraSOPList?.filter(
      (item: any) => item?.sensor_id_str !== sop,
    );
    setCameraSOPList(updatedItems);
  };

  const [emails, setEmails] = useState<MultiEmail[] | undefined>([]);
  const [emailsErr, setEmailsErr] = useState<MultiEmailWithError[] | undefined>(
    [],
  );
  const [refresh, setRefresh] = React.useState(0);
  const [emailLoading, setEmailLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [disconnectVal, setDisconnectVal] = useState(true);
  const [customEndpointDefs, setCustomEndpointDefs] = useState<
    AlertEndPointDef[]
  >([]);
  const [values, setValues] = React.useState<any[]>([]);
  const [botValue, setBotValue] = useState(-1);
  const [botCheck, setBotCheck] = useState(false);
  const [botName, setBotName] = useState("error");
  const [groupTelegramId, setGroupTelegramId] = useState("0");
  const [groupName, setGroupName] = useState("");
  const [newseletedrows, setNewseletedrows] = useState<NewData[]>([]);
  const [groupid, setGroupId] = React.useState(0);
  const [userruleendpointvalues, setUserRuleEndpointValues] = React.useState<
    UserEndPointRoles[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [renderValue, setRenderValue] = useState(false);
  const [emailsErrorText, setEmailsErrorText] = useState("");
  const [telegramErrorText, setTelegramErrorText] = useState("");
  const [teleGroupHelperText, setTeleGroupHelperText] = useState("");
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [message, setMessage] = useState("");
  const [prefix, setPrefix] = useState("");
  const [finishedFetching, setFinishedFetching] = useState(false);
  const [sendToTele, setSendToTele] = useState(false);
  const [sendToEmail, setSendToEmail] = useState(false);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [resultFailure, setResultFailure] = useState(false);
  const [failMessage, setFailMessage] = useState("");
  const [updateButtonError, setUpdateButtonError] = useState(false);
  const [updateEmptyError, setUpdateEmptyError] = useState(false);
  const [relays, setRelays] = useState<TalkbackRelayElements[]>([]);
  const [talkbackRelayId, setTalkbackRelayId] = useState<number | null>(null);
  // const [dynDnsHelperText, setDynDnsHelperText] = React.useState<string>("");
  const [siteIdStrExampleText, setSiteIdStrExampleText] =
    React.useState<string>("");

  const [checkedState, setCheckedState] = useState<boolean[] | undefined>([]);
  const [sensorTexts, setSensorTexts] = useState<SensorObject[]>([]);
  const [genericTexts, setGenericTexts] = useState<SensorGeneric>({
    talkbackMethod: "none",
    talkbackUrl: "",
    talkbackUsername: "",
    talkbackPassword: "",
    relay: 0,
  });

  const handleCheckboxChange = (position: number) => {
    const updatedCheckedState = checkedState!.map((item, index) =>
      index === position ? !item : item,
    );

    setCheckedState(updatedCheckedState);
  };

  const handleSelectAll = () => {
    setCheckedState(new Array(sensorTexts.length).fill(true));
  };

  const handleDeSelectAll = () => {
    setCheckedState(new Array(sensorTexts.length).fill(false));
  };

  const classes = useStyles();

  const useStyle = makeStyles({
    paper: {
      border: "2px solid #6D809D",
      color: "#15325F",
    },
  });
  const styler = useStyle();

  const checkEmailError = () => {
    let err: boolean = false;
    emailsErr?.forEach((item) => {
      if (item.email === "") {
        item.error = true;
        err = true;
      } else {
        item.error = false;
      }
    });
    if (!err) {
      setEmailsErrorText("");
    } else {
      setEmailsErrorText("one of the emails is empty!");
    }
  };

  const handleSaveData = () => {
    let errorFound: boolean = false;
    let emaillist: string[] = [];

    emailsErr?.forEach((email) => {
      if (email.email === "") {
        email.error = true;
        errorFound = true;
      } else {
        if (email.error === true) {
          errorFound = true;
        }
      }

      emaillist.push(email.email);
    });
    if (!errorFound) {
      setEmailsErrorText("");
      let meta = endpoint.meta;

      let email_export: { [key: string]: any } = {};
      email_export["recipients"] = emaillist;
      meta["email_export"] = email_export;
      email_export["prefix"] = prefix;
      if (siteSOPList || cameraSOPList) {
        meta["sop"] = JSON.stringify({
          site_sop: siteSOPList,
          camera_sop: cameraSOPList,
        });
      } else if (!siteSOPList && !cameraSOPList) {
        if (meta.hasOwnProperty("sop")) {
          delete meta["sop"];
        }
      }
      meta["send_disconnect_notifications"] = disconnectVal;
      if (
        sendToTele === true &&
        (botValue === -1 ||
          isNaN(+groupTelegramId) ||
          groupTelegramId == "0" ||
          groupTelegramId == "" ||
          groupName === "")
      ) {
        setTelegramErrorText("invalid settings in one of the telegram fields");
      } else {
        if (sendToTele === true) {
          setTelegramErrorText("");
          meta["telegram"] = {};
          meta.telegram["telegram_bot"] = botValue;
          meta.telegram["telegram_id"] = +groupTelegramId;
          meta.telegram["telegram_group_name"] = groupName;
          meta.telegram["send_to_tele"] = sendToTele;
        } else {
          if (!meta.hasOwnProperty("telegram")) {
            meta["telegram"] = {};
          }
          meta.telegram["send_to_tele"] = sendToTele;
        }

        editEndpoint(endpoint.alert_channel_end_point_id, meta);
      }
    } else {
      setEmailsErrorText("invalid email format in one of the emails");
    }
  };

  let endpointtypes: React.ReactNode[] = [];
  if (endpointdefs) {
    endpointdefs.forEach((endpointdef) => {
      const translate = appStore?.functionService.translate(
        alertchannel.locale,
        "endpoint_names",
        endpointdef.end_point_type,
      );
      endpointtypes.push(
        <MenuItem value={endpointdef.end_point_type}>
          <span className={classes.bold_text}>{translate}</span>
        </MenuItem>,
      );
    });
  }
  async function updateCameraMeta(
    sensor_id: number,
    met: { [key: string]: any },
  ) {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setEmailLoading(true);
      let update_meta: UpdateSensorResult =
        await appStore?.functionService.modifySensorMeta(token, sensor_id, met);
      if (update_meta) {
        setLoading(false);
        if (update_meta.success) {
          setEmailLoading(false);
          setMessage("sensor updated successfully");
          setOpenMessageModal(true);
        } else {
          setMessage(update_meta.msg);
          setOpenMessageModal(true);
          setEmailLoading(false);
        }
      }
    }
  }

  const modifyBatchSensorData = async (
    sensor_data: { [key: string]: any }[],
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setEmailLoading(true);
      const modifyBatch: UpdateSensorResult =
        await appStore?.functionService.modifyBatchSensorMeta(
          token,
          sensor_data,
        );
      if (modifyBatch) {
        if (modifyBatch.success) {
          setEmailLoading(false);
          setMessage("updated successfully");
          setOpenMessageModal(true);
        } else {
          setMessage(modifyBatch.msg);
          setOpenMessageModal(true);
          setEmailLoading(false);
        }
      }
    }
  };

  const editEndpoint = async (
    alert_channel_end_point_id: number,
    meta: { [key: string]: any },
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setEmailLoading(true);
      const modifyEndpoint: UpdateResult =
        await appStore?.functionService.modifyAlertEndPoint(
          token,
          alert_channel_end_point_id,
          meta,
        );
      if (modifyEndpoint) {
        if (modifyEndpoint.success) {
          setEmailLoading(false);
          setMessage("updated successfully");
          setOpenMessageModal(true);
        } else {
          setMessage(modifyEndpoint.msg);
          setOpenMessageModal(true);
          setEmailLoading(false);
        }
      }
    }
  };
  const handleClose = () => {
    onClose();
  };

  const tabPanelItems = [
    {
      name: "Configure SOP",
    },
    {
      name: "Talkback",
    },
    {
      name: "Disconnection Notifications",
    },
    {
      name: "Telegram On Demand Export",
    },
    {
      name: "Email On Demand Export",
    },
    {
      name: "Directly-Assigned Users",
    },
  ];
  function newCreateData(
    user_id: number,
    username: string,
    first_name: string,
    last_name: string,
    web_alerts: boolean,
    web_alert_escalations: boolean,
    web_mute_controls: boolean,
    web_alert_camera_filter: boolean,
    web_alert_history: boolean,
    web_bulk_actions: boolean,
  ): NewData {
    return {
      user_id,
      username,
      first_name,
      last_name,
      web_alerts,
      web_alert_escalations,
      web_mute_controls,
      web_alert_camera_filter,
      web_alert_history,
      web_bulk_actions,
    };
  }

  const fetchTalkback = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    setLoading(true);
    if (token) {
      let relays: TalkbackRelay =
        await appStore?.functionService.fetchTalkbackRelay(token, org_id);
      if (relays && relays.success) {
        setRelays(relays.msg);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTalkback();
  }, [org_id]);
  useEffect(() => {
    const fetchSensors = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      {
        if (alertchannel && token) {
          setLoading(true);
          let sensors: Sensor[] =
            await appStore?.functionService.getEnabledSensorsForSite(
              token,
              alertchannel.site_id,
              "none",
            );
          if (sensors) {
            setCheckedState(new Array(sensors.length).fill(false));
            sensors.map((sensor, index) => {
              let talkbackMethod = "none";
              let talkbackUrl = "";
              let talkbackUsername = "";
              let talkbackPassword = "";
              let talkback_relay_id = 0;
              let meta = {};
              if (sensor.meta) {
                if (sensor.meta && sensor.meta.hasOwnProperty("talkback")) {
                  if (sensor.meta.talkback.hasOwnProperty("talkbackMethod")) {
                    talkbackMethod = sensor.meta["talkback"]["talkbackMethod"];
                    talkbackUrl = sensor.meta["talkback"]["talkbackUrl"];
                    talkbackUsername =
                      sensor.meta["talkback"]["talkbackUsername"];
                    talkbackPassword =
                      sensor.meta["talkback"]["talkbackPassword"];
                  }
                  if (
                    sensor.meta.talkback.hasOwnProperty("talkback_relay_id")
                  ) {
                    talkback_relay_id =
                      sensor.meta["talkback"]["talkback_relay_id"];
                  }
                }
                meta = sensor.meta;
              }
              let sensObj: SensorObject = {
                sensor_id: sensor.sensor_id,
                sensor_string: sensor.sensor_id_str,
                talkbackMethod,
                talkbackUrl,
                talkbackUsername,
                talkbackPassword,
                meta,
                MethodError: false,
                UrlError: false,
                UsernameError: false,
                PasswordError: false,
                sensor_name: sensor.human_name,
                talkback_relay_id,
              };
              let siteIdString = sensor.sensor_id_str.slice(
                0,
                sensor.sensor_id_str.indexOf("-"),
              );
              setSiteIdStrExampleText(siteIdString);
              sensorTexts.push(sensObj);
            });
          }
          setLoading(false);
        }
      }
    };
    fetchSensors();
  }, [alertchannel]);

  // useEffect(() => {
  //   const DynDnsSettings = async () => {
  //     var server_address: string = "";
  //     let host = window.location.hostname;
  //     if (host === "localhost") {
  //       server_address = "ddns-api.staging.deepalert.ai";
  //     } else {
  //       var url_ending: string = host.slice(host.indexOf(".") + 1);
  //       server_address = "ddns-api." + url_ending;
  //     }
  //
  //     let host_name_example = siteIdStrExampleText + "." + server_address;
  //
  //     let dynDnsHelperMessage =
  //       "Please note that if you are using DeepAlert Dynamic DNS, you can use ";
  //     dynDnsHelperMessage += host_name_example + " as the hostname";
  //
  //     setDynDnsHelperText(dynDnsHelperMessage);
  //   };
  //   DynDnsSettings();
  // });

  useEffect(() => {
    const setData = async () => {
      if (endpoint) {
        setLoading(true);

        if (endpoint.users) {
          var usersendpointroles: impUserEndPointRoles[] = [];
          endpoint.users.forEach((user) => {
            var endpointroles: { [key: string]: boolean } = {};
            var userenpointroles = new impUserEndPointRoles();
            userenpointroles.key = Guid.create();
            userenpointroles.user_id = user.user_id;
            user.web_roles.forEach((role) => {
              endpointroles[role] = true;
            });
            userenpointroles.web_roles = endpointroles;

            usersendpointroles.push(userenpointroles);
          });

          setUserRuleEndpointValues(usersendpointroles);
        } else {
          setUserRuleEndpointValues([]);
        }
        setLoading(false);
      }
    };
    setData();
  }, [endpoint, refresh]);

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        let neworg = new impOrg();
        neworg.org_id = appStore?.authService.getEntryOrg()?.id!;

        let allusers: User[] =
          await appStore?.functionService.getAllUsersForAllOrg(
            token,
            neworg.org_id,
          );
        if (userruleendpointvalues?.length > 0 && allusers?.length > 0) {
          let filteredUsers = allusers.filter((user, index) => {
            let findUser = userruleendpointvalues.findIndex(
              (x) => x.user_id === user.user_id,
            );
            if (findUser >= 0) {
              return user;
            }
          });
          if (filteredUsers?.length > 0) {
            let UnAssignedUsers = allusers.filter((user) => {
              let findUser = filteredUsers.findIndex(
                (x) => x.user_id === user.user_id,
              );
              if (findUser < 0) {
                return user;
              }
            });
            setAllUsers(UnAssignedUsers);
          } else {
            setAllUsers(allUsers);
          }
          let tempUsers: newImpUserWebRoles[] = [];
          filteredUsers.map((filtUser, index) => {
            let currentroles = userruleendpointvalues.find(
              (x) => x.user_id === filtUser.user_id,
            );
            if (currentroles) {
              let revisedwebrole = new newImpUserWebRoles();
              revisedwebrole.user_id = filtUser.user_id;
              revisedwebrole.username = filtUser.username;
              revisedwebrole.first_name = filtUser.first_name;
              revisedwebrole.last_name = filtUser.last_name;
              revisedwebrole.web_alerts = currentroles.web_roles["web_alerts"];
              revisedwebrole.web_alert_history =
                currentroles.web_roles["web_alert_history"];
              revisedwebrole.web_alert_camera_filter =
                currentroles.web_roles["web_alert_camera_filter"];
              revisedwebrole.web_alert_escalations =
                currentroles.web_roles["web_alert_escalations"];
              revisedwebrole.web_mute_controls =
                currentroles.web_roles["web_mute_controls"];
              revisedwebrole.web_bulk_actions =
                currentroles.web_roles["web_bulk_actions"];

              tempUsers.push(revisedwebrole);
            }
          });
          let tempnewseletedrows: NewData[] = [];

          tempUsers &&
            tempUsers.length > 0 &&
            tempUsers.forEach((userwebrole) => {
              tempnewseletedrows.push(
                newCreateData(
                  userwebrole.user_id,
                  userwebrole.username,
                  userwebrole.first_name,
                  userwebrole.last_name,
                  userwebrole.web_alerts,
                  userwebrole.web_alert_escalations,
                  userwebrole.web_mute_controls,
                  userwebrole.web_alert_camera_filter,
                  userwebrole.web_alert_history,
                  userwebrole.web_bulk_actions,
                ),
              );
            });
          setNewseletedrows(tempnewseletedrows);
          setFinishedFetching(true);
          setLoading(false);
        } else {
          setAllUsers(allusers);
          setFinishedFetching(true);
          setLoading(false);
        }
      } else {
        setFinishedFetching(true);
      }
    };
    fetchData();
  }, [userruleendpointvalues, groupid, renderValue, org_id]);

  useEffect(() => {
    if (endpoint) {
      if (endpoint.meta && endpoint.meta.email_export) {
        if (endpoint.meta.email_export.prefix) {
          setPrefix((val) => endpoint.meta.email_export.prefix);
        }
        if (
          endpoint.meta.email_export &&
          endpoint.meta.email_export.hasOwnProperty("send_to_email")
        ) {
          setSendToEmail(endpoint.meta.email_export["send_to_email"]);
        }
        if (endpoint.meta.email_export.recipients) {
          let recipient_emails = endpoint.meta.email_export.recipients;
          recipient_emails.forEach((recipient_email: string) => {
            let emailWithErr = new impMultiEmailWithError();
            emailWithErr.key = Guid.create();
            emailWithErr.email = recipient_email;
            emailWithErr.parent_id = endpoint.alert_channel_id;
            emailWithErr.error = !validateEmail(recipient_email);
            emailsErr?.push(emailWithErr);
          });
          setEmailsErr(emailsErr);
          setRefresh(new Date().getTime());
        }
      }

      if (endpoint.meta && endpoint.meta.sop) {
        let sopValue = endpoint.meta.sop;
        let parsedSOP;
        try {
          parsedSOP = JSON.parse(sopValue);
        } catch (error) {
          // If parsing fails, sopValue might be a plain string SOP
          console.debug("Invalid JSON in endpoint.meta.sop:", error);
          parsedSOP = sopValue;
        }
        if (typeof parsedSOP === "string") {
          // Handle the case where SOP is a plain string
          setSiteSOPList([parsedSOP]);
          setCameraSOPList([]);
        } else if (typeof parsedSOP === "object" && parsedSOP !== null) {
          // Handle the case where SOP is an object with site_sop and camera_sop
          let site_sop_arr = parsedSOP.site_sop || [];
          let camera_sop_arr = parsedSOP.camera_sop || [];
          setSiteSOPList(site_sop_arr);
          setCameraSOPList(camera_sop_arr);
        } else {
          // If parsedSOP is not a string or object, reset SOP lists
          setSiteSOPList([]);
          setCameraSOPList([]);
        }
      } else {
        // If endpoint or endpoint.meta.sop is not available, reset SOP lists
        setSiteSOPList([]);
        setCameraSOPList([]);
      }
      if (
        endpoint.meta &&
        endpoint.meta.hasOwnProperty("send_disconnect_notifications")
      ) {
        setDisconnectVal(endpoint.meta.send_disconnect_notifications);
      }
      if (endpoint.meta && endpoint.meta.hasOwnProperty("telegram")) {
        if (
          endpoint.meta.telegram &&
          endpoint.meta.telegram.hasOwnProperty("telegram_bot")
        ) {
          setBotValue(endpoint.meta.telegram["telegram_bot"]);
          setBotCheck(true);
          setBotName(endpoint.meta.telegram["telegram_bot_name"]);
        }
        if (endpoint.meta.telegram && endpoint.meta.telegram.telegram_id) {
          setGroupTelegramId(endpoint.meta.telegram["telegram_id"]);
        }
        if (
          endpoint.meta.telegram &&
          endpoint.meta.telegram.telegram_group_name
        ) {
          setGroupName(endpoint.meta.telegram["telegram_group_name"]);
        }
        if (
          endpoint.meta.telegram &&
          endpoint.meta.telegram.hasOwnProperty("send_to_tele")
        ) {
          setSendToTele(endpoint.meta.telegram["send_to_tele"]);
        }
      }
    }
    if (endpointdefs) {
      setCustomEndpointDefs(
        endpointdefs.filter((def, index) => {
          return def.end_point_type === "telegram";
        }),
      );
    }
  }, [endpoint, endpointdefs]);

  useEffect(() => {
    if (resultFailure) {
      const timeId = setTimeout(() => {
        // After 5 seconds set the show value to false

        setResultFailure(false);
        setFailMessage("");
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
    // when the component is mounted, the alert is displayed for 5 seconds
  }, [resultFailure]);

  useEffect(() => {
    if (updateButtonError) {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false

        setUpdateButtonError(false);
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }
    // when the component is mounted, the alert is displayed for 3 seconds
  }, [updateButtonError]);

  useEffect(() => {
    if (updateEmptyError) {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false

        setUpdateEmptyError(false);
        // setEmptyMessage("");
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }
    // when the component is mounted, the alert is displayed for 3 seconds
  }, [updateEmptyError]);
  const RemoveUser = async (userId: number) => {
    let token = await appStore?.authService.getAuthorisedToken();
    setUsersLoading(true);
    if (token) {
      let roles: any = await appStore?.functionService.fetchUserWebRoleNames(
        token,
        userId,
      );
      let data: any = await appStore?.functionService.fetchUserRoles(
        token,
        org_id,
        userId,
      );

      if (data.success) {
        let channel_ids = data.alert_channel_ids.filter(
          (item: any) => item != endpoint.alert_channel_id.toString(),
        );

        const updatedRoles: UpdateWebChannelResults =
          await appStore?.functionService.updateUserRoles(
            token,
            org_id,
            userId,
            `${roles}`,
            // `${roles.data.web_end_point_roles}`,
            `${channel_ids}`,
          );
        if (updatedRoles.success) {
          //add removed user to array of all users
          let neworg = new impOrg();
          neworg.org_id = appStore?.authService.getEntryOrg()?.id!;
          let allusers: User[] =
            await appStore?.functionService.getAllUsersForAllOrg(
              token,
              neworg.org_id,
            );
          if (allusers) {
            let removedUser = allusers.find((user) => user.user_id === userId);

            if (!removedUser) {
              setUsersLoading(false);
              return;
            }
            let newRows = newseletedrows.filter(
              (row) => row.user_id !== removedUser?.user_id,
            );
            setNewseletedrows(newRows);
            setAllUsers((curr_users) => [...curr_users, removedUser!]);
          }
        }
      }
      setUsersLoading(false);
    }
  };

  const handleUsersChange = async (user: any) => {
    if (user) {
      setResultFailure(false);
      setFailMessage("");
      let user_id = user.user_id;
      setUsersLoading(true);
      let token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        let roles: any = await appStore?.functionService.fetchUserWebRoleNames(
          token,
          user_id,
        );

        let data: any = await appStore?.functionService.fetchUserRoles(
          token,
          org_id,
          user_id,
        );
        if (
          roles.length < 1
          // roles.data.other_web_end_point_roles.length < 1
        ) {
          setResultFailure(true);
          setFailMessage(
            "this user does not have existing roles and cannot be added to this web endpoint",
          );
          setUsersLoading(false);
          return;
        } else {
          if (roles.length > 0 && data.success) {
            console.debug("hit roles.success, ", roles);
            let currentUser = allUsers.find((user) => user.user_id === user_id);

            if (!currentUser) {
              setUsersLoading(false);
              return;
            }
            let rolesObtained = roles.length > 1 ? roles : [];
            let revisedwebrole = new newImpUserWebRoles();
            revisedwebrole.user_id = currentUser.user_id;
            revisedwebrole.username = currentUser.username;
            revisedwebrole.first_name = currentUser.first_name;
            revisedwebrole.last_name = currentUser.last_name;
            revisedwebrole.web_alerts = rolesObtained.includes("web_alerts")
              ? true
              : false;
            revisedwebrole.web_alert_history = rolesObtained.includes(
              "web_alert_history",
            )
              ? true
              : false;
            revisedwebrole.web_alert_camera_filter = rolesObtained.includes(
              "web_alert_camera_filter",
            )
              ? true
              : false;
            revisedwebrole.web_alert_escalations = rolesObtained.includes(
              "web_alert_escalations",
            )
              ? true
              : false;
            revisedwebrole.web_mute_controls = rolesObtained.includes(
              "web_mute_controls",
            )
              ? true
              : false;
            revisedwebrole.web_bulk_actions = rolesObtained.includes(
              "web_bulk_actions",
            )
              ? true
              : false;

            let channel_ids = data.alert_channel_ids;
            if (!channel_ids.includes(endpoint.alert_channel_id.toString())) {
              channel_ids.push(endpoint.alert_channel_id.toString());
            }
            // channel_ids.push(endpoint.alert_channel_id.toString());
            const updatedRoles: UpdateWebChannelResults =
              await appStore?.functionService.updateUserRoles(
                token,
                org_id,
                user_id,
                `${rolesObtained}`,
                `${channel_ids}`,
              );
            if (updatedRoles.success) {
              //remove updated user from array of all users
              let updatedUsers = allUsers.filter((user) => {
                return user.user_id !== currentUser?.user_id;
              });

              setNewseletedrows((curr) => [
                ...curr,
                newCreateData(
                  revisedwebrole.user_id,
                  revisedwebrole.username,
                  revisedwebrole.first_name,
                  revisedwebrole.last_name,
                  revisedwebrole.web_alerts,
                  revisedwebrole.web_alert_escalations,
                  revisedwebrole.web_mute_controls,
                  revisedwebrole.web_alert_camera_filter,
                  revisedwebrole.web_alert_history,
                  revisedwebrole.web_bulk_actions,
                ),
              ]);

              setAllUsers(updatedUsers);
            } else {
              setMessage(updatedRoles.msg);
              setOpenMessageModal(true);
            }
            setUsersLoading(false);
          }
        }
      }
      setUsersLoading(false);
    }
  };

  const handleValueChange = (event: React.ChangeEvent<any>) => {
    setTelegramErrorText("");
    setBotValue(+event.target.value);
  };
  const handleGroupIdChange = (event: React.ChangeEvent<any>) => {
    if (isNaN(event.target.value.trim()) || event.target.value.trim() === "") {
      setGroupTelegramId(event.target.value);
      setTeleGroupHelperText("please enter a valid number");
      setTelegramErrorText("invalid settings in one of the telegram fields");
    } else {
      let telegramGroupID = event.target.value.trim();
      if (telegramGroupID > 0) {
        telegramGroupID = telegramGroupID * -1;
      }
      setTeleGroupHelperText("");
      setTelegramErrorText("");
      setGroupTelegramId(telegramGroupID);
    }
  };
  const handleGroupNameChange = (event: React.ChangeEvent<any>) => {
    setTelegramErrorText("");
    setGroupName(event.target.value);
  };

  function validateEmail(email: string): boolean {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }
  const handlePrefixChange = (event: React.ChangeEvent<any>) => {
    setPrefix(event.target.value);
  };
  const handleEmailChange = (event: React.ChangeEvent<any>, key: Guid) => {
    let email = emailsErr?.find((x) => x.key == key);
    let emailindex = emailsErr?.findIndex((x) => x.key == key);
    if (emailsErr && email) {
      email.email = event.target.value;
      if (validateEmail(email.email) === true) {
        setEmailsErrorText("");
        email.error = false;
      } else {
        setEmailsErrorText("invalid email format in one of emails");
        email.error = true;
      }
      emailsErr[emailindex!] = email;
      setEmailsErr(emailsErr);
      setRefresh(new Date().getTime());
    }
  };
  type generic =
    | "talkbackMethod"
    | "talkbackUrl"
    | "talkbackPassword"
    | "talkbackUsername";

  const talkbackUpdator = (sensObj: SensorObject, index: number) => {
    setSensorTexts((state) =>
      state.map((el, i) => (i === index ? { ...sensObj } : el)),
    );
  };
  const removeEmail = (key: Guid) => {
    let newemails: MultiEmailWithError[] = [];
    emailsErr!.forEach((email) => {
      if (email.key != key) {
        newemails.push(email);
      } else {
        if (email.error === true) {
          setEmailsErrorText("");
        }
      }
    });

    setEmailsErr(newemails);
    setRefresh(new Date().getTime());
  };
  const addEmail = () => {
    let email = new impMultiEmailWithError();
    email.key = Guid.create();
    email.email = "";
    email.error = true;
    emailsErr?.push(email);
    setEmailsErr(emailsErr);
    setRefresh(new Date().getTime());
  };
  let bot_list: React.ReactNode[] = [];
  if (customEndpointDefs.length > 0) {
    bot_list.push(
      <MenuItem value={-1}>
        <span className={classes.bold_text}>none</span>
      </MenuItem>,
    );
    if (botCheck) {
      bot_list.push(
        <MenuItem value={botValue}>
          <span className={classes.bold_text}>{botName}</span>
        </MenuItem>,
      );
    }
    if (!botCheck) {
      customEndpointDefs.map((item, index) => {
        if (item && item.definition && item.definition.hasOwnProperty("bot")) {
          var dict: {} = item.definition.bot;
          for (const fkey in dict) {
            const subfield = item.definition.bot[fkey];
            bot_list.push(
              <MenuItem value={fkey}>
                <span className={classes.bold_text}>{subfield}</span>
              </MenuItem>,
            );
          }
        }
      });
    }
  }
  let new_email_list: React.ReactNode[] = [];
  new_email_list.push(
    <Grid container>
      <Grid item xs={10}>
        <Typography className={classes.bold_text_billing}>
          {/* Email prefix is the unique identifier that comes before the @ symbol
          of an email address. */}
          Please note that ‘Email Subject’ will form part of the subject line in
          the email and the body of the email.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <TextField
          label="Email Subject"
          variant="outlined"
          margin="normal"
          value={prefix}
          onChange={(e) => handlePrefixChange(e)}
          fullWidth
          autoFocus
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
      </Grid>{" "}
    </Grid>,
  );
  if (emailsErr) {
    emailsErr.forEach((email) => {
      new_email_list.push(
        <Grid container>
          <Grid item xs={10}>
            <TextField
              onChange={(e) => handleEmailChange(e, email.key)}
              label="Email Address"
              variant="outlined"
              margin="normal"
              value={email.email}
              fullWidth
              autoFocus
              error={email.error}
              helperText={email.error ? "invalid email format" : ""}
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          </Grid>{" "}
          <Grid item xs={2}>
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => {
                removeEmail(email.key);
              }}
            >
              <DeleteOutlineIcon color={"primary"} />
            </IconButton>
          </Grid>
        </Grid>,
      );
    });
  }
  let email_list: React.ReactNode[] = [];
  if (emails) {
    emails.forEach((email) => {
      email_list.push(
        <Grid container>
          <Grid item xs={10}>
            <TextField
              onChange={(e) => handleEmailChange(e, email.key)}
              label="Email Address"
              variant="outlined"
              margin="normal"
              value={email.email}
              fullWidth
              autoFocus
              onBlur={(e) => {
                checkEmailError();
              }}
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          </Grid>{" "}
          <Grid item xs={2}>
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => {
                removeEmail(email.key);
              }}
            >
              <DeleteOutlineIcon color={"primary"} />
            </IconButton>
          </Grid>
        </Grid>,
      );
    });
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
      maxWidth="lg"
    >
      <DialogTitle id="simple-dialog-title">
        <AppBar className={classes.appBar} position="relative">
          <Toolbar>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h4"
                  className={classes.bold_text_white}
                  style={{ textTransform: "capitalize", fontSize: 14 }}
                >
                  Alert&nbsp;Web&nbsp;Interface&nbsp;Settings
                </Typography>
              </div>

              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <ThemeProvider theme={theme}>
        <AppBar position="static" className="navcolor">
          <Tabs
            indicatorColor="secondary"
            textColor="inherit"
            value={value}
            onChange={handleChange}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            centered
          >
            {tabPanelItems.map((item, index) => {
              return (
                <Tab
                  label={item.name}
                  {...a11yProps(index)}
                  style={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                >
                  <span
                    className={classes.bold_text_white}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.name}
                  </span>
                </Tab>
              );
            })}
          </Tabs>
        </AppBar>
        {tabPanelItems.map((item, tabIndex) => {
          return (
            <TabPanel value={value} index={tabIndex}>
              {emailLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  {tabIndex === 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "70%",
                          marginLeft: "15%",
                        }}
                      >
                        <Typography className={classes.bold_text_billing}>
                          The standard operating procedure (SOP) which is to be
                          displayed with the alert on the DeepAlert Alert Web
                          Interface found at{" "}
                          <a href="https://alert.deepalert.ai" target="_blank">
                            https://alert.deepalert.ai
                          </a>
                          . Note that multiple lines can be used using the enter
                          key.
                        </Typography>
                      </div>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "70%",
                          margin: "0 auto",
                        }}
                      >
                        <div className={classes.sop_section}>
                          <div className={classes.site_sop}>
                            <p
                              className="title"
                              style={{
                                fontWeight: "700",
                                fontSize: "14px",
                                color: "#2E4FA5",
                              }}
                            >
                              {"Site Based SOP"}
                            </p>
                            <div className={classes.field_section}>
                              <TextField
                                placeholder="Start Typing"
                                variant="outlined"
                                fullWidth
                                value={siteSOP}
                                InputProps={{
                                  className: classes.sop_textfield,
                                }}
                                onChange={collectSiteSOPHandler}
                              ></TextField>{" "}
                              <Button
                                variant="contained"
                                className={classes.sop_btn}
                                onClick={addSiteSOPHandler}
                              >
                                <span
                                  className={classes.bold_text}
                                  style={{
                                    textTransform: "capitalize",
                                    color: "white",
                                  }}
                                >
                                  {"Add"}
                                </span>
                              </Button>
                            </div>
                            <div className={classes.site_sop_list}>
                              <ul
                                style={{
                                  color: "#6287E8",
                                }}
                              >
                                {siteSOPList?.map((sop, index) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <li
                                        style={{
                                          color: "#6287E8",
                                          width: "90%",
                                        }}
                                        value={sop}
                                        key={index}
                                      >{`${sop}`}</li>
                                      <IconButton
                                        style={{
                                          cursor: "pointer",
                                          color: "#6287E8",
                                          marginTop: "-10px",
                                          marginRight: "20px",
                                        }}
                                        onClick={() => {
                                          deleteSiteSOP(sop);
                                        }}
                                      >
                                        <DeleteForeverIcon
                                          style={{
                                            fontSize: "18px",
                                            color: "#6287E8",
                                          }}
                                        />
                                      </IconButton>
                                    </div>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                          <div className={classes.camera_sop}>
                            <p
                              className="title"
                              style={{
                                fontWeight: "700",
                                fontSize: "14px",
                                color: "#2E4FA5",
                              }}
                            >
                              {"Camera Based SOP"}
                            </p>
                            <div className={classes.field_section}>
                              <div>
                                <TextField
                                  select
                                  label="Select Camera"
                                  value={cameraSensor}
                                  variant="outlined"
                                  fullWidth
                                  InputProps={{
                                    className: classes.sop_textfield,
                                  }}
                                  InputLabelProps={{
                                    shrink: true, // Force the label to shrink
                                  }}
                                  onChange={collectCameraSensorHandler}
                                >
                                  {sensors?.map((sensor) => (
                                    <MenuItem
                                      key={sensor.sensor_id}
                                      value={sensor.sensor_id_str}
                                    >
                                      {sensor?.sensor_id_str}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                <div
                                  style={{
                                    margin: "10px 0",
                                  }}
                                >
                                  <TextField
                                    placeholder="Start Typing"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                      className: classes.sop_textfield,
                                    }}
                                    value={cameraSOP}
                                    onChange={collectCameraSOPHandler}
                                  ></TextField>{" "}
                                </div>
                              </div>
                              <Button
                                variant="contained"
                                className={classes.sop_btn}
                                style={{
                                  width: "120px",
                                  marginLeft: "15px",
                                }}
                                onClick={addCameraSOPHandler}
                              >
                                <span
                                  className={classes.bold_text}
                                  style={{
                                    textTransform: "capitalize",
                                    color: "white",
                                  }}
                                >
                                  {"Add"}
                                </span>
                              </Button>
                            </div>
                            <div>
                              {cameraSOPList?.map((sop: any, index: number) => {
                                return (
                                  <div style={{ width: "100%" }}>
                                    <div
                                      style={{ width: "100%", display: "flex" }}
                                    >
                                      <span
                                        style={{
                                          width: "90%",
                                          color: "#6287E8",
                                        }}
                                      >
                                        <VideocamOutlinedIcon
                                          style={{
                                            fontSize: 25,
                                            margin: "0 10px",
                                            color: "#6287E8",
                                            transform: "translate(0,7px)",
                                          }}
                                        />
                                        {`${sop?.sensor_id_str}`}
                                      </span>
                                      <IconButton
                                        style={{
                                          cursor: "pointer",
                                          color: "#6287E8",
                                          marginTop: "-5px",
                                          marginRight: "20px",
                                        }}
                                        onClick={() => {
                                          deleteCameraSOP(sop?.sensor_id_str);
                                        }}
                                      >
                                        <DeleteForeverIcon
                                          style={{
                                            fontSize: "18px",
                                            color: "#6287E8",
                                          }}
                                        />
                                      </IconButton>
                                    </div>
                                    <ul
                                      style={{
                                        color: "#6287E8",
                                      }}
                                    >
                                      {sop?.sop_list?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <li
                                              style={{
                                                color: "#6287E8",
                                                width: "90%",
                                              }}
                                              value={item}
                                              key={item}
                                            >{`${item}`}</li>
                                          );
                                        },
                                      )}
                                    </ul>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        {sendToTele === true && telegramErrorText !== "" ? (
                          <Box mt={2} mb={2} justifyContent="center">
                            <Alert variant="outlined" severity="error">
                              {telegramErrorText}
                            </Alert>
                          </Box>
                        ) : null}
                        {emailsErrorText !== "" ? (
                          <Box mt={2} mb={2} justifyContent="center">
                            <Alert variant="outlined" severity="error">
                              {emailsErrorText}
                            </Alert>
                          </Box>
                        ) : null}
                        <div style={{ height: "40px" }}></div>
                        <Button
                          variant="contained"
                          className="save"
                          onClick={handleSaveData}
                        >
                          <span
                            className={classes.bold_text}
                            style={{ textTransform: "capitalize" }}
                          >
                            Update
                          </span>
                        </Button>
                      </div>
                    </>
                  ) : tabIndex === 1 ? (
                    <>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Box mt={2} mb={2} justifyContent="center">
                          <Typography align="center">
                            <span
                              className={classes.bold_text_billing}
                              style={{ color: "red" }}
                            >
                              Note that audio talkback feature is only
                              applicable to HikVision cameras!
                            </span>
                          </Typography>
                        </Box>
                        <Box mt={1} mb={1} justifyContent="center">
                          <Typography align="center">
                            <span
                              className={classes.bold_text}
                              style={{
                                fontSize: 14,
                                color: "black",
                                textTransform: "uppercase",
                              }}
                            >
                              Populating bulk update fields below automatically
                              updates all selected sensors
                            </span>
                          </Typography>

                          {/*<Typography align="center">*/}
                          {/*  <div*/}
                          {/*    className={classes.bold_text}*/}
                          {/*    style={{*/}
                          {/*      fontSize: 12,*/}
                          {/*      color: "red",*/}
                          {/*    }}*/}
                          {/*  >*/}
                          {/*    {dynDnsHelperText}*/}
                          {/*  </div>*/}
                          {/*</Typography>*/}
                        </Box>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                              marginTop: 15,
                              borderRadius: 15,
                              overflow: "hidden",
                              width: "100%",
                            }}
                          >
                            <TableContainer
                              component={Paper}
                              style={{ maxHeight: 350, width: "100%" }}
                            >
                              <Table size="small" stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      <Typography align="center">
                                        <span
                                          className={classes.bold_text}
                                          style={{ color: "#ffffff" }}
                                        >
                                          select/deselect
                                        </span>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography align="center">
                                        <span
                                          className={classes.bold_text}
                                          style={{ color: "#ffffff" }}
                                        >
                                          sensor&nbsp;name
                                        </span>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography align="center">
                                        <span
                                          className={classes.bold_text}
                                          style={{
                                            fontSize: 12,
                                            color: "black",
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          bulk&nbsp;update&nbsp;:
                                        </span>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Select
                                        style={{ height: "50%" }}
                                        variant="outlined"
                                        labelId="test-select-label"
                                        value={genericTexts.relay}
                                        fullWidth
                                        onChange={(e) => {
                                          let genObject: SensorGeneric = {
                                            talkbackMethod:
                                              genericTexts.talkbackMethod,
                                            talkbackUrl:
                                              genericTexts.talkbackUrl,
                                            talkbackUsername:
                                              genericTexts.talkbackUsername,
                                            talkbackPassword:
                                              genericTexts.talkbackPassword,
                                            relay: e.target.value as number,
                                          };
                                          setGenericTexts(genObject);
                                          sensorTexts.map(
                                            (sensor_item, index) => {
                                              checkedState &&
                                                checkedState.map(
                                                  (
                                                    checkedItem,
                                                    checkedIndex,
                                                  ) => {
                                                    if (checkedItem == true) {
                                                      if (
                                                        index === checkedIndex
                                                      ) {
                                                        let sensObjChanger: SensorObject =
                                                          {
                                                            sensor_id:
                                                              sensor_item.sensor_id,
                                                            sensor_name:
                                                              sensor_item.sensor_name,
                                                            sensor_string:
                                                              sensor_item.sensor_string,
                                                            talkbackMethod:
                                                              sensor_item.talkbackMethod,
                                                            talkbackUrl:
                                                              sensor_item.talkbackUrl,
                                                            talkbackUsername:
                                                              sensor_item.talkbackUsername,
                                                            talkbackPassword:
                                                              sensor_item.talkbackPassword,
                                                            meta: sensor_item.meta,
                                                            MethodError:
                                                              sensor_item.MethodError,
                                                            UrlError:
                                                              sensor_item.UrlError,
                                                            UsernameError:
                                                              sensor_item.UsernameError,
                                                            PasswordError:
                                                              sensor_item.PasswordError,
                                                            talkback_relay_id: e
                                                              .target
                                                              .value as number,
                                                          };
                                                        talkbackUpdator(
                                                          sensObjChanger,
                                                          index,
                                                        );
                                                      }
                                                    }
                                                  },
                                                );
                                            },
                                          );
                                        }}
                                      >
                                        {!!relays &&
                                          relays.map((rel, index) => {
                                            return (
                                              <MenuItem
                                                key={rel.id}
                                                value={rel.id}
                                              >
                                                <span
                                                  className={classes.bold_text}
                                                >
                                                  {rel.name.toLowerCase()}
                                                </span>
                                              </MenuItem>
                                            );
                                          })}
                                        <MenuItem value={0}>
                                          <span className={classes.bold_text}>
                                            none
                                          </span>
                                        </MenuItem>
                                      </Select>
                                    </TableCell>
                                    <TableCell>
                                      <Select
                                        style={{ height: "50%" }}
                                        variant="outlined"
                                        labelId="test-select-label"
                                        value={genericTexts.talkbackMethod}
                                        fullWidth
                                        onChange={(e) => {
                                          let genObject: SensorGeneric = {
                                            talkbackMethod: e.target
                                              .value as string,
                                            talkbackUrl:
                                              genericTexts.talkbackUrl,
                                            talkbackUsername:
                                              genericTexts.talkbackUsername,
                                            talkbackPassword:
                                              genericTexts.talkbackPassword,
                                            relay: genericTexts.relay,
                                          };
                                          setGenericTexts(genObject);
                                          sensorTexts.map(
                                            (sensor_item, index) => {
                                              checkedState &&
                                                checkedState.map(
                                                  (
                                                    checkedItem,
                                                    checkedIndex,
                                                  ) => {
                                                    if (checkedItem == true) {
                                                      if (
                                                        index === checkedIndex
                                                      ) {
                                                        let sensObjChanger: SensorObject =
                                                          {
                                                            sensor_id:
                                                              sensor_item.sensor_id,
                                                            sensor_name:
                                                              sensor_item.sensor_name,
                                                            sensor_string:
                                                              sensor_item.sensor_string,
                                                            talkbackMethod: e
                                                              .target
                                                              .value as string,
                                                            talkbackUrl:
                                                              sensor_item.talkbackUrl,
                                                            talkbackUsername:
                                                              sensor_item.talkbackUsername,
                                                            talkbackPassword:
                                                              sensor_item.talkbackPassword,
                                                            meta: sensor_item.meta,
                                                            MethodError:
                                                              sensor_item.MethodError,
                                                            UrlError:
                                                              sensor_item.UrlError,
                                                            UsernameError:
                                                              sensor_item.UsernameError,
                                                            PasswordError:
                                                              sensor_item.PasswordError,
                                                            talkback_relay_id:
                                                              sensor_item.talkback_relay_id,
                                                          };
                                                        talkbackUpdator(
                                                          sensObjChanger,
                                                          index,
                                                        );
                                                      }
                                                    }
                                                  },
                                                );
                                            },
                                          );
                                        }}
                                      >
                                        <MenuItem value="hikvision">
                                          <span className={classes.bold_text}>
                                            hikvision
                                          </span>
                                        </MenuItem>
                                        <MenuItem value="none">
                                          <span className={classes.bold_text}>
                                            none
                                          </span>
                                        </MenuItem>
                                      </Select>
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        label="bulk url"
                                        variant="outlined"
                                        margin="normal"
                                        placeholder=""
                                        value={genericTexts.talkbackUrl}
                                        onChange={(e) => {
                                          let genObject: SensorGeneric = {
                                            talkbackMethod:
                                              genericTexts.talkbackMethod,
                                            talkbackUrl: e.target.value,
                                            talkbackUsername:
                                              genericTexts.talkbackUsername,
                                            talkbackPassword:
                                              genericTexts.talkbackPassword,
                                            relay: genericTexts.relay,
                                          };
                                          setGenericTexts(genObject);
                                          sensorTexts.map(
                                            (sensor_item, index) => {
                                              checkedState &&
                                                checkedState.map(
                                                  (
                                                    checkedItem,
                                                    checkedIndex,
                                                  ) => {
                                                    if (checkedItem == true) {
                                                      if (
                                                        index === checkedIndex
                                                      ) {
                                                        let sensObjChanger: SensorObject =
                                                          {
                                                            sensor_id:
                                                              sensor_item.sensor_id,
                                                            sensor_name:
                                                              sensor_item.sensor_name,
                                                            sensor_string:
                                                              sensor_item.sensor_string,
                                                            talkbackMethod:
                                                              sensor_item.talkbackMethod,
                                                            talkbackUrl:
                                                              e.target.value,
                                                            talkbackUsername:
                                                              sensor_item.talkbackUsername,
                                                            talkbackPassword:
                                                              sensor_item.talkbackPassword,
                                                            meta: sensor_item.meta,
                                                            MethodError:
                                                              sensor_item.MethodError,
                                                            UrlError:
                                                              sensor_item.UrlError,
                                                            UsernameError:
                                                              sensor_item.UsernameError,
                                                            PasswordError:
                                                              sensor_item.PasswordError,
                                                            talkback_relay_id:
                                                              sensor_item.talkback_relay_id,
                                                          };
                                                        talkbackUpdator(
                                                          sensObjChanger,
                                                          index,
                                                        );
                                                      }
                                                    }
                                                  },
                                                );
                                            },
                                          );
                                        }}
                                        InputProps={{
                                          className: classes.bold_text,
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.bold_text,
                                          },
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        label="bulk username"
                                        variant="outlined"
                                        margin="normal"
                                        placeholder=""
                                        value={genericTexts.talkbackUsername}
                                        onChange={(e) => {
                                          let genObject: SensorGeneric = {
                                            talkbackMethod:
                                              genericTexts.talkbackMethod,
                                            talkbackUrl:
                                              genericTexts.talkbackUrl,
                                            talkbackUsername: e.target.value,
                                            talkbackPassword:
                                              genericTexts.talkbackPassword,
                                            relay: genericTexts.relay,
                                          };
                                          setGenericTexts(genObject);
                                          sensorTexts.map(
                                            (sensor_item, index) => {
                                              checkedState &&
                                                checkedState.map(
                                                  (
                                                    checkedItem,
                                                    checkedIndex,
                                                  ) => {
                                                    if (checkedItem == true) {
                                                      if (
                                                        index === checkedIndex
                                                      ) {
                                                        let sensObjChanger: SensorObject =
                                                          {
                                                            sensor_id:
                                                              sensor_item.sensor_id,
                                                            sensor_name:
                                                              sensor_item.sensor_name,
                                                            sensor_string:
                                                              sensor_item.sensor_string,
                                                            talkbackMethod:
                                                              sensor_item.talkbackMethod,
                                                            talkbackUrl:
                                                              sensor_item.talkbackUrl,
                                                            talkbackUsername:
                                                              e.target.value,
                                                            talkbackPassword:
                                                              sensor_item.talkbackPassword,
                                                            meta: sensor_item.meta,
                                                            MethodError:
                                                              sensor_item.MethodError,
                                                            UrlError:
                                                              sensor_item.UrlError,
                                                            UsernameError:
                                                              sensor_item.UsernameError,
                                                            PasswordError:
                                                              sensor_item.PasswordError,
                                                            talkback_relay_id:
                                                              sensor_item.talkback_relay_id,
                                                          };
                                                        talkbackUpdator(
                                                          sensObjChanger,
                                                          index,
                                                        );
                                                      }
                                                    }
                                                  },
                                                );
                                            },
                                          );
                                        }}
                                        InputProps={{
                                          className: classes.bold_text,
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.bold_text,
                                          },
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        label="bulk password"
                                        variant="outlined"
                                        margin="normal"
                                        placeholder=""
                                        // style={{ width: "15%" }}
                                        value={genericTexts.talkbackPassword}
                                        onChange={(e) => {
                                          let genObject: SensorGeneric = {
                                            talkbackMethod:
                                              genericTexts.talkbackMethod,
                                            talkbackUrl:
                                              genericTexts.talkbackUrl,
                                            talkbackUsername:
                                              genericTexts.talkbackUsername,
                                            talkbackPassword: e.target.value,
                                            relay: genericTexts.relay,
                                          };
                                          setGenericTexts(genObject);
                                          sensorTexts.map(
                                            (sensor_item, index) => {
                                              checkedState &&
                                                checkedState.map(
                                                  (
                                                    checkedItem,
                                                    checkedIndex,
                                                  ) => {
                                                    if (checkedItem == true) {
                                                      if (
                                                        index === checkedIndex
                                                      ) {
                                                        let sensObjChanger: SensorObject =
                                                          {
                                                            sensor_id:
                                                              sensor_item.sensor_id,
                                                            sensor_name:
                                                              sensor_item.sensor_name,
                                                            sensor_string:
                                                              sensor_item.sensor_string,
                                                            talkbackMethod:
                                                              sensor_item.talkbackMethod,
                                                            talkbackUrl:
                                                              sensor_item.talkbackUrl,
                                                            talkbackUsername:
                                                              sensor_item.talkbackUsername,
                                                            talkbackPassword:
                                                              e.target.value,
                                                            meta: sensor_item.meta,
                                                            MethodError:
                                                              sensor_item.MethodError,
                                                            UrlError:
                                                              sensor_item.UrlError,
                                                            UsernameError:
                                                              sensor_item.UsernameError,
                                                            PasswordError:
                                                              sensor_item.PasswordError,
                                                            talkback_relay_id:
                                                              sensor_item.talkback_relay_id,
                                                          };
                                                        talkbackUpdator(
                                                          sensObjChanger,
                                                          index,
                                                        );
                                                      }
                                                    }
                                                  },
                                                );
                                            },
                                          );
                                        }}
                                        InputProps={{
                                          className: classes.bold_text,
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.bold_text,
                                          },
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography align="center">
                                        <span
                                          className={classes.bold_text}
                                          style={{ color: "#3f51b5" }}
                                        >
                                          select/deselect
                                        </span>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography align="center">
                                        <span
                                          className={classes.bold_text}
                                          style={{ color: "#3f51b5" }}
                                        >
                                          sensor&nbsp;name
                                        </span>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography align="center">
                                        <span
                                          className={classes.bold_text}
                                          style={{ color: "#3f51b5" }}
                                        >
                                          sensor&nbsp;id
                                        </span>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography align="center">
                                        <span
                                          className={classes.bold_text}
                                          style={{ color: "#3f51b5" }}
                                        >
                                          talkback&nbsp;relay
                                        </span>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography align="center">
                                        <span
                                          className={classes.bold_text}
                                          style={{ color: "#3f51b5" }}
                                        >
                                          talkback&nbsp;method
                                        </span>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography align="center">
                                        <span
                                          className={classes.bold_text}
                                          style={{ color: "#3f51b5" }}
                                        >
                                          talkback&nbsp;url
                                        </span>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography align="center">
                                        <span
                                          className={classes.bold_text}
                                          style={{ color: "#3f51b5" }}
                                        >
                                          talkback&nbsp;username
                                        </span>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography align="center">
                                        <span
                                          className={classes.bold_text}
                                          style={{ color: "#3f51b5" }}
                                        >
                                          talkback&nbsp;password
                                        </span>
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {sensorTexts.map((sen, index) => {
                                    return (
                                      <TableRow key={sen.sensor_id}>
                                        <TableCell>
                                          <FormControl>
                                            <Checkbox
                                              className={
                                                classes.form_component_checkbox
                                              }
                                              onChange={() =>
                                                handleCheckboxChange(index)
                                              }
                                              color="primary"
                                              icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                              }
                                              checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                              }
                                              checked={checkedState![index]}
                                            />
                                          </FormControl>
                                        </TableCell>
                                        <TableCell>
                                          <Typography
                                            align="center"
                                            className={
                                              classes.bold_text_billing
                                            }
                                            style={{ verticalAlign: "center" }}
                                          >
                                            {sen.sensor_name}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography
                                            align="center"
                                            className={
                                              classes.bold_text_billing
                                            }
                                            style={{ verticalAlign: "center" }}
                                          >
                                            {sen.sensor_string}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Select
                                            variant="outlined"
                                            labelId="test-select-label"
                                            value={sen.talkback_relay_id}
                                            fullWidth
                                            onChange={(e) => {
                                              let num = Number(e.target.value);
                                              let sensObj: SensorObject = {
                                                sensor_id: sen.sensor_id,
                                                sensor_string:
                                                  sen.sensor_string,
                                                talkbackMethod:
                                                  sen.talkbackMethod,
                                                talkbackUrl: sen.talkbackUrl,
                                                sensor_name: sen.sensor_name,
                                                talkbackUsername:
                                                  sen.talkbackUsername,
                                                talkbackPassword:
                                                  sen.talkbackPassword,
                                                meta: sen.meta,
                                                MethodError: sen.MethodError,
                                                UrlError: sen.UrlError,
                                                UsernameError:
                                                  sen.UsernameError,
                                                PasswordError:
                                                  sen.PasswordError,
                                                talkback_relay_id: num,
                                              };
                                              talkbackUpdator(sensObj, index);
                                            }}
                                          >
                                            {!!relays &&
                                              relays.map((rel, index) => {
                                                return (
                                                  <MenuItem
                                                    key={rel.id}
                                                    value={rel.id}
                                                  >
                                                    <span
                                                      className={
                                                        classes.bold_text
                                                      }
                                                    >
                                                      {rel.name.toLowerCase()}
                                                    </span>
                                                  </MenuItem>
                                                );
                                              })}
                                            <MenuItem value={0}>
                                              <span
                                                className={classes.bold_text}
                                              >
                                                none
                                              </span>
                                            </MenuItem>
                                          </Select>
                                        </TableCell>
                                        <TableCell>
                                          <Select
                                            variant="outlined"
                                            labelId="test-select-label"
                                            value={sen.talkbackMethod}
                                            error={sen.MethodError}
                                            fullWidth
                                            onChange={(e) => {
                                              let err =
                                                e.target.value == "" ||
                                                e.target.value == "none"
                                                  ? true
                                                  : false;
                                              let sensObj: SensorObject = {
                                                sensor_id: sen.sensor_id,
                                                sensor_string:
                                                  sen.sensor_string,
                                                talkbackMethod: e.target
                                                  .value as string,
                                                talkbackUrl: sen.talkbackUrl,
                                                sensor_name: sen.sensor_name,
                                                talkbackUsername:
                                                  sen.talkbackUsername,
                                                talkbackPassword:
                                                  sen.talkbackPassword,
                                                meta: sen.meta,
                                                MethodError: err,
                                                UrlError: sen.UrlError,
                                                UsernameError:
                                                  sen.UsernameError,
                                                PasswordError:
                                                  sen.PasswordError,
                                                talkback_relay_id:
                                                  sen.talkback_relay_id,
                                              };
                                              talkbackUpdator(sensObj, index);
                                            }}
                                          >
                                            <MenuItem value="hikvision">
                                              <span
                                                className={classes.bold_text}
                                              >
                                                hikvision
                                              </span>
                                            </MenuItem>
                                            <MenuItem value="none">
                                              <span
                                                className={classes.bold_text}
                                              >
                                                none
                                              </span>
                                            </MenuItem>
                                          </Select>
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            label="talkback url"
                                            variant="outlined"
                                            margin="normal"
                                            placeholder=""
                                            value={sen.talkbackUrl}
                                            error={sen.UrlError}
                                            helperText={
                                              sen.UrlError && "invalid url"
                                            }
                                            onChange={(e) => {
                                              let err =
                                                e.target.value == "" ||
                                                !e.target.value.startsWith(
                                                  "http",
                                                )
                                                  ? true
                                                  : false;
                                              let sensObj: SensorObject = {
                                                sensor_id: sen.sensor_id,
                                                sensor_name: sen.sensor_name,
                                                sensor_string:
                                                  sen.sensor_string,
                                                talkbackMethod:
                                                  sen.talkbackMethod,
                                                talkbackUrl: e.target.value,
                                                talkbackUsername:
                                                  sen.talkbackUsername,
                                                talkbackPassword:
                                                  sen.talkbackPassword,
                                                meta: sen.meta,
                                                MethodError: sen.MethodError,
                                                UrlError: err,
                                                UsernameError:
                                                  sen.UsernameError,
                                                PasswordError:
                                                  sen.PasswordError,
                                                talkback_relay_id:
                                                  sen.talkback_relay_id,
                                              };
                                              talkbackUpdator(sensObj, index);
                                            }}
                                            InputProps={{
                                              className: classes.bold_text,
                                            }}
                                            InputLabelProps={{
                                              classes: {
                                                root: classes.bold_text,
                                              },
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            label="talkback username"
                                            variant="outlined"
                                            margin="normal"
                                            placeholder=""
                                            value={sen.talkbackUsername}
                                            error={sen.UsernameError}
                                            onChange={(e) => {
                                              let err =
                                                e.target.value == ""
                                                  ? true
                                                  : false;
                                              let sensObj: SensorObject = {
                                                sensor_id: sen.sensor_id,
                                                sensor_name: sen.sensor_name,
                                                sensor_string:
                                                  sen.sensor_string,
                                                talkbackMethod:
                                                  sen.talkbackMethod,
                                                talkbackUrl: sen.talkbackUrl,
                                                talkbackUsername:
                                                  e.target.value,
                                                talkbackPassword:
                                                  sen.talkbackPassword,
                                                meta: sen.meta,
                                                MethodError: sen.MethodError,
                                                UrlError: sen.UrlError,
                                                UsernameError: err,
                                                PasswordError:
                                                  sen.PasswordError,
                                                talkback_relay_id:
                                                  sen.talkback_relay_id,
                                              };
                                              talkbackUpdator(sensObj, index);
                                            }}
                                            InputProps={{
                                              className: classes.bold_text,
                                            }}
                                            InputLabelProps={{
                                              classes: {
                                                root: classes.bold_text,
                                              },
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            label="talkback password"
                                            variant="outlined"
                                            margin="normal"
                                            placeholder=""
                                            value={sen.talkbackPassword}
                                            error={sen.PasswordError}
                                            onChange={(e) => {
                                              let err =
                                                e.target.value == ""
                                                  ? true
                                                  : false;
                                              let sensObj: SensorObject = {
                                                sensor_id: sen.sensor_id,
                                                sensor_name: sen.sensor_name,
                                                sensor_string:
                                                  sen.sensor_string,
                                                talkbackMethod:
                                                  sen.talkbackMethod,
                                                talkbackUrl: sen.talkbackUrl,
                                                talkbackUsername:
                                                  sen.talkbackUsername,
                                                talkbackPassword:
                                                  e.target.value,
                                                meta: sen.meta,
                                                MethodError: sen.MethodError,
                                                UrlError: sen.UrlError,
                                                UsernameError:
                                                  sen.UsernameError,
                                                PasswordError: err,
                                                talkback_relay_id:
                                                  sen.talkback_relay_id,
                                              };
                                              talkbackUpdator(sensObj, index);
                                            }}
                                            InputProps={{
                                              className: classes.bold_text,
                                            }}
                                            InputLabelProps={{
                                              classes: {
                                                root: classes.bold_text,
                                              },
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {updateButtonError && (
                            <Box mt={2} mb={2} justifyContent="center">
                              <Alert
                                variant="outlined"
                                severity="error"
                                onClose={() => {
                                  setUpdateButtonError(false);
                                }}
                              >
                                One or more fields are invalid
                              </Alert>
                            </Box>
                          )}
                          {updateEmptyError && (
                            <Box mt={2} mb={2} justifyContent="center">
                              <Alert
                                variant="outlined"
                                severity="error"
                                onClose={() => {
                                  setUpdateEmptyError(false);
                                }}
                              >
                                please select at least one sensor
                              </Alert>
                            </Box>
                          )}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              width: "100%",
                              marginTop: 20,
                            }}
                          >
                            <Button
                              autoFocus
                              color="inherit"
                              // style={{
                              //   marginTop: 25,
                              // }}
                              className={classes.menu_button}
                              onClick={handleDeSelectAll}
                            >
                              <span className={classes.bold_text_billing}>
                                deselect&nbsp;all
                              </span>
                            </Button>
                            <Button
                              autoFocus
                              color="inherit"
                              // style={{
                              //   marginTop: 25,
                              // }}
                              className={classes.menu_button}
                              onClick={handleSelectAll}
                            >
                              <span className={classes.bold_text_billing}>
                                select&nbsp;all
                              </span>
                            </Button>
                            <Button
                              autoFocus
                              color="inherit"
                              // style={{
                              //   marginTop: 25,
                              // }}
                              className={classes.menu_button}
                              onClick={() => {
                                let globalError = false;
                                let sensor_data: { [key: string]: any }[] = [];
                                sensorTexts.map((sensor, index) => {
                                  checkedState &&
                                    checkedState.map(
                                      (checkedItem, checkedIndex) => {
                                        if (checkedItem == true) {
                                          if (index === checkedIndex) {
                                            let met = sensor.meta;
                                            met["talkback"] = {};
                                            let sensObj: SensorObject = {
                                              sensor_id: sensor.sensor_id,
                                              sensor_name: sensor.sensor_name,
                                              sensor_string:
                                                sensor.sensor_string,
                                              talkbackMethod: "none",
                                              talkbackUrl: "",
                                              talkbackUsername: "",
                                              talkbackPassword: "",
                                              meta: sensor.meta,
                                              MethodError: false,
                                              UrlError: false,
                                              UsernameError: false,
                                              PasswordError: false,
                                            };
                                            talkbackUpdator(sensObj, index);
                                            let meta = JSON.stringify(met);
                                            sensor_data.push({
                                              sensor_id: sensor.sensor_id,
                                              meta,
                                            });
                                          }
                                        }
                                      },
                                    );
                                });
                                if (sensor_data.length < 1) {
                                  setUpdateEmptyError(true);
                                } else {
                                  modifyBatchSensorData(sensor_data);
                                }
                              }}
                            >
                              <span className={classes.bold_text_billing}>
                                clear&nbsp;selected
                              </span>
                            </Button>
                            <Button
                              autoFocus
                              color="inherit"
                              className={classes.menu_button}
                              onClick={() => {
                                let globalError = false;
                                let sensor_data: { [key: string]: any }[] = [];
                                sensorTexts.map((sensor, index) => {
                                  checkedState &&
                                    checkedState.map(
                                      (checkedItem, checkedIndex) => {
                                        if (checkedItem == true) {
                                          if (index === checkedIndex) {
                                            let met = sensor.meta;
                                            met["talkback"] = {
                                              talkbackMethod:
                                                sensor.talkbackMethod,
                                              talkbackUrl: sensor.talkbackUrl,
                                              talkbackUsername:
                                                sensor.talkbackUsername,
                                              talkbackPassword:
                                                sensor.talkbackPassword,
                                              talkback_relay_id:
                                                sensor.talkback_relay_id,
                                            };
                                            if (
                                              sensor.MethodError === true ||
                                              sensor.PasswordError === true ||
                                              sensor.UrlError === true ||
                                              sensor.UsernameError ||
                                              sensor.talkbackMethod == "" ||
                                              sensor.talkbackMethod == "none" ||
                                              sensor.talkbackUsername == "" ||
                                              sensor.talkbackUrl == "" ||
                                              sensor.talkbackPassword == "" ||
                                              !sensor.talkbackUrl.startsWith(
                                                "http",
                                              )
                                            ) {
                                              globalError = true;
                                              let methError =
                                                sensor.talkbackMethod == "" ||
                                                sensor.talkbackMethod == "none"
                                                  ? true
                                                  : false;
                                              let urlError =
                                                sensor.talkbackUrl == "" ||
                                                !sensor.talkbackUrl.startsWith(
                                                  "http",
                                                )
                                                  ? true
                                                  : false;
                                              let userError =
                                                sensor.talkbackUsername == ""
                                                  ? true
                                                  : false;
                                              let passError =
                                                sensor.talkbackPassword == ""
                                                  ? true
                                                  : false;
                                              let sensObj: SensorObject = {
                                                sensor_id: sensor.sensor_id,
                                                sensor_name: sensor.sensor_name,
                                                sensor_string:
                                                  sensor.sensor_string,
                                                talkbackMethod:
                                                  sensor.talkbackMethod,
                                                talkbackUrl: sensor.talkbackUrl,
                                                talkbackUsername:
                                                  sensor.talkbackUsername,
                                                talkbackPassword:
                                                  sensor.talkbackPassword,
                                                meta: sensor.meta,
                                                MethodError: methError,
                                                UrlError: urlError,
                                                UsernameError: userError,
                                                PasswordError: passError,
                                                talkback_relay_id:
                                                  sensor.talkback_relay_id,
                                              };
                                              talkbackUpdator(sensObj, index);
                                            }
                                            let meta = JSON.stringify(met);
                                            sensor_data.push({
                                              sensor_id: sensor.sensor_id,
                                              meta,
                                            });
                                          }
                                        }
                                      },
                                    );
                                });
                                if (sensor_data.length < 1) {
                                  setUpdateEmptyError(true);
                                } else if (!globalError) {
                                  modifyBatchSensorData(sensor_data);
                                } else {
                                  setUpdateButtonError(true);
                                }
                              }}
                            >
                              <span className={classes.bold_text_billing}>
                                update&nbsp;selected
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : tabIndex === 2 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "70%",
                          marginLeft: "15%",
                        }}
                      >
                        <Typography className={classes.bold_text_billing}>
                          Camera / hub disconnection and reconnection
                          notification alerts to be displayed on the DeepAlert
                          Alert Web Interface found at{" "}
                          <a href="https://alert.deepalert.ai" target="_blank">
                            https://alert.deepalert.ai
                          </a>
                          . Note that typically a 5 minute down/recovery window
                          is applied to this before notifications are sent.{" "}
                        </Typography>
                      </div>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30%",
                          marginLeft: "40%",
                        }}
                      >
                        <FormControlLabel
                          className={classes.form_component_checkbox}
                          control={
                            <Checkbox
                              color="primary"
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                              checked={disconnectVal}
                              onChange={(e) => {
                                setDisconnectVal(e.target.checked);
                              }}
                            />
                          }
                          label={
                            <span className={classes.bold_text}>
                              send&nbsp;disconnection&nbsp;notifications
                            </span>
                          }
                        />
                        {sendToTele === true && telegramErrorText !== "" ? (
                          <Box mt={2} mb={2} justifyContent="center">
                            <Alert variant="outlined" severity="error">
                              {telegramErrorText}
                            </Alert>
                          </Box>
                        ) : null}
                        {emailsErrorText !== "" ? (
                          <Box mt={2} mb={2} justifyContent="center">
                            <Alert variant="outlined" severity="error">
                              {emailsErrorText}
                            </Alert>
                          </Box>
                        ) : null}
                        <Button
                          variant="contained"
                          className="save"
                          onClick={handleSaveData}
                        >
                          <span
                            className={classes.bold_text}
                            style={{ textTransform: "capitalize" }}
                          >
                            Update
                          </span>
                        </Button>
                      </div>
                    </>
                  ) : tabIndex === 3 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "70%",
                          marginLeft: "15%",
                        }}
                      >
                        <Typography className={classes.bold_text_billing}>
                          This is the Telegram group that will be notified once
                          a user clicks the "Telegram Export" button within the
                          DeepAlert Alert Web Interface found at{" "}
                          <a href="https://alert.deepalert.ai" target="_blank">
                            https://alert.deepalert.ai
                          </a>
                        </Typography>
                      </div>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30%",
                          marginLeft: "40%",
                          color: "#15325F",
                        }}
                      >
                        <FormControlLabel
                          className={classes.form_component_checkbox}
                          control={
                            <Checkbox
                              color="primary"
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                              checked={sendToTele}
                              onChange={(e) => {
                                setSendToTele(e.target.checked);
                              }}
                            />
                          }
                          label={
                            <span className={classes.bold_text}>
                              send&nbsp;telegram&nbsp;alerts
                            </span>
                          }
                        />
                        {sendToTele && (
                          <>
                            <FormControl fullWidth>
                              <InputLabel
                                style={{ marginLeft: "2%", color: "#15325F" }}
                                id="test-select-label"
                              >
                                telegram
                              </InputLabel>
                              <Select
                                variant="outlined"
                                labelId="test-select-label"
                                value={botValue}
                                error={botValue === -1 ? true : false}
                                fullWidth
                                onChange={(e) => handleValueChange(e)}
                                disabled={botCheck}
                              >
                                {bot_list}
                              </Select>
                            </FormControl>
                            <TextField
                              label="telegram group id"
                              onChange={(e) => handleGroupIdChange(e)}
                              value={groupTelegramId}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              style={{ width: "100%" }}
                              error={
                                isNaN(+groupTelegramId) ||
                                groupTelegramId == "0" ||
                                groupTelegramId == ""
                                  ? true
                                  : false
                              }
                              helperText={teleGroupHelperText}
                              InputProps={{
                                className: classes.bold_text,
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.bold_text,
                                },
                              }}
                            />
                            <TextField
                              label="telegram group name"
                              onChange={(e) => handleGroupNameChange(e)}
                              value={groupName}
                              variant="outlined"
                              margin="normal"
                              autoComplete=""
                              fullWidth
                              error={groupName === "" ? true : false}
                              InputProps={{
                                className: classes.bold_text,
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.bold_text,
                                },
                              }}
                            />
                          </>
                        )}

                        {sendToTele === true && telegramErrorText !== "" ? (
                          <Box mt={2} mb={2} justifyContent="center">
                            <Alert variant="outlined" severity="error">
                              {telegramErrorText}
                            </Alert>
                          </Box>
                        ) : null}
                        {emailsErrorText !== "" ? (
                          <Box mt={2} mb={2} justifyContent="center">
                            <Alert variant="outlined" severity="error">
                              {emailsErrorText}
                            </Alert>
                          </Box>
                        ) : null}
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            disabled={!sendToTele}
                            variant="contained"
                            onClick={() => {
                              setBotValue(-1);
                              setGroupName("");
                              setGroupTelegramId("0");
                              let meta = endpoint.meta;
                              if (meta.hasOwnProperty("telegram")) {
                                delete meta["telegram"];
                              }
                              editEndpoint(
                                endpoint.alert_channel_end_point_id,
                                meta,
                              );
                            }}
                          >
                            <span
                              className={classes.bold_text}
                              style={{ textTransform: "capitalize" }}
                            >
                              Clear&nbsp;Settings
                            </span>
                          </Button>

                          <Button variant="contained" onClick={handleSaveData}>
                            <span
                              className={classes.bold_text}
                              style={{ textTransform: "capitalize" }}
                            >
                              Update
                            </span>
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : tabIndex === 4 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "70%",
                          marginLeft: "15%",
                        }}
                      >
                        <Typography className={classes.bold_text_billing}>
                          These directly-assigned users can view alerts for this
                          ruleset on the Alert Web Interface{" "}
                          <a href="https://alert.deepalert.ai" target="_blank">
                            (htps://alert.deepalert.ai)
                          </a>
                        </Typography>
                      </div>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30%",
                          marginLeft: "40%",
                        }}
                      >
                        {new_email_list}
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <IconButton
                            edge="end"
                            aria-label="add"
                            onClick={() => {
                              addEmail();
                            }}
                          >
                            <AddCircle color={"primary"} />
                          </IconButton>
                          <p className={classes.bold_text}>{"Add Email"}</p>
                        </div>
                        {sendToTele === true && telegramErrorText !== "" ? (
                          <Box mt={2} mb={2} justifyContent="center">
                            <Alert variant="outlined" severity="error">
                              {telegramErrorText}
                            </Alert>
                          </Box>
                        ) : null}
                        {emailsErrorText !== "" ? (
                          <Box mt={2} mb={2} justifyContent="center">
                            <Alert variant="outlined" severity="error">
                              {emailsErrorText}
                            </Alert>
                          </Box>
                        ) : null}
                        <Button
                          variant="contained"
                          className="save"
                          onClick={handleSaveData}
                        >
                          <span
                            className={classes.bold_text}
                            style={{ textTransform: "capitalize" }}
                          >
                            Update
                          </span>
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div style={{ width: "100%" }}>
                      {usersLoading || loading ? (
                        <LoadingSpinner />
                      ) : (
                        <>
                          <ViewUsers
                            finishedFetching={finishedFetching}
                            appStore={appStore}
                            endpoint={endpoint}
                            newseletedrows={newseletedrows}
                            endpointdefs={endpointdefs}
                            setCurrentId={(id: number) => RemoveUser(id)}
                            refresh={value}
                          />
                          <br></br>

                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            {resultFailure && (
                              <Box mt={2} mb={2} justifyContent="center">
                                <Alert
                                  variant="outlined"
                                  severity="error"
                                  onClose={() => {
                                    setResultFailure(false);
                                    setFailMessage("");
                                  }}
                                >
                                  {failMessage}
                                </Alert>
                              </Box>
                            )}
                            {allUsers && allUsers.length > 0 ? (
                              <>
                                <Autocomplete
                                  id="combo-box-demo"
                                  className={classes.form_component_medium}
                                  options={allUsers}
                                  onChange={(
                                    event: React.ChangeEvent<any>,
                                    user: any,
                                  ) => {
                                    handleUsersChange(user);
                                  }}
                                  getOptionLabel={(option: User) => {
                                    return option.username;
                                  }}
                                  renderOption={(option: User) => {
                                    return (
                                      <span
                                        className={classes.bold_text_billing}
                                      >
                                        {option.username}
                                      </span>
                                    );
                                  }}
                                  style={{
                                    width: "100%",
                                    color: "#51709e",
                                    textAlign: "center",
                                  }}
                                  classes={{ paper: styler.paper }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={
                                        <span
                                          className={classes.bold_text_billing}
                                        >
                                          Add users
                                        </span>
                                      }
                                      variant="outlined"
                                      className={classes.bold_text_billing}
                                    />
                                  )}
                                />
                              </>
                            ) : (
                              <Typography align="left">
                                <span
                                  className={classes.bold_text_billing}
                                  style={{ color: "red" }}
                                >
                                  No users found to be added to this web alert
                                  endpoint
                                </span>
                              </Typography>
                            )}
                          </div>
                          <br></br>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </TabPanel>
          );
        })}
      </ThemeProvider>
      <MessageModal
        open={openMessageModal}
        onClose={() => setOpenMessageModal(false)}
        message={message}
        elements={undefined}
      />
    </Dialog>
  );
};

export default WebSettings;
