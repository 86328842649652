import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useStyles } from "../../styling/global/global";
interface HubExpansionPanelMenuProps {
  status: boolean;
  del: boolean;
  edit: boolean;
  enable: boolean;
  enabled: boolean;
  move: boolean;
  handleStatus: () => void;
  handleEdit: () => void;
  handleEnable: () => void;
  handleDelete: () => void;
  handleMove: () => void;
}
const HubExpansionPanelMenu: React.FC<HubExpansionPanelMenuProps> = ({
  move,
  status,
  del,
  edit,
  enable,
  enabled,
  handleMove,
  handleStatus,
  handleEdit,
  handleEnable,
  handleDelete,
}: HubExpansionPanelMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const enabletext = enabled ? "Disable" : "Enable";
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const Status = () => {
    setAnchorEl(null);
    handleStatus();
  };
  const Edit = () => {
    setAnchorEl(null);
    handleEdit();
  };
  const Enable = () => {
    setAnchorEl(null);
    handleEnable();
  };
  const Delete = () => {
    setAnchorEl(null);
    handleDelete();
  };
  const Move = () => {
    setAnchorEl(null);
    handleMove();
  };
  var menuitems: React.ReactNode[] = [];
  if (status) {
    menuitems.push(
      <MenuItem onClick={Status}>
        <span className={classes.bold_text}>Status</span>
      </MenuItem>,
    );
  }
  if (edit) {
    menuitems.push(
      <MenuItem onClick={Edit}>
        <span className={classes.bold_text}>Edit</span>
      </MenuItem>,
    );
  }
  if (move) {
    menuitems.push(
      <MenuItem onClick={Move}>
        <span className={classes.bold_text}>Move</span>
      </MenuItem>,
    );
  }
  if (enable) {
    menuitems.push(
      <MenuItem onClick={Enable}>
        <span className={classes.bold_text}>{enabletext}</span>
      </MenuItem>,
    );
  }
  if (del) {
    menuitems.push(
      <MenuItem onClick={Delete}>
        <span className={classes.bold_text}>Delete</span>
      </MenuItem>,
    );
  }

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuitems}
      </Menu>
    </React.Fragment>
  );
};
export default HubExpansionPanelMenu;
