import React from "react";

import TimeEntryUITimeOnly from "./TimeEntryUiTimeOnly";
import {
  TimeEntryTimeOnly,
  TimeEntrySetTimeOnly,
} from "../../../core/models/TimeEntryTimeOnly";

interface TimeEntryPanelTimeOnlyProps {
  disabled: boolean;
  entrySet: TimeEntrySetTimeOnly | undefined;
  addicon: boolean;
  changeTimeEntryParent: (entrySet: TimeEntrySetTimeOnly) => void;
}

const TimeEntryPanelTimeOnly: React.FC<TimeEntryPanelTimeOnlyProps> = ({
  disabled,
  entrySet,
  addicon,
  changeTimeEntryParent,
}: TimeEntryPanelTimeOnlyProps) => {
  const [timeentries, setTimeentries] = React.useState<
    TimeEntrySetTimeOnly | undefined
  >(entrySet);
  const [refresh, setRefresh] = React.useState(0);
  const handleScheduleDeleteClicked = (timeEntry: TimeEntryTimeOnly) => {
    if (timeentries) {
      timeentries.deleteTimeEntry(timeEntry);
      setTimeentries(timeentries);
      changeTimeEntryParent(timeentries);
    }

    setRefresh(new Date().getTime());
  };
  const handleAdd = () => {
    if (timeentries) {
      timeentries.addTimeEntry();
      setTimeentries(timeentries);
      changeTimeEntryParent(timeentries);
    }

    setRefresh(new Date().getTime());
  };
  const changeTimeEntry = (entry: TimeEntryTimeOnly) => {
    const old_entry = timeentries?.entries.find((x) => x.key == entry.key);
    if (old_entry && timeentries) {
      timeentries!.entries[timeentries!.entries.indexOf(old_entry!)] = entry;
      setTimeentries(timeentries);
      changeTimeEntryParent(timeentries);
    }
  };
  var timeEntryContent: React.ReactNode[] = [];
  if (timeentries) {
    timeentries.entries.map((timeEntry, index) => {
      const addSchedule =
        index == timeentries.entries.length - 1 ? true : false;
      timeEntryContent.push(
        <TimeEntryUITimeOnly
          addicon={addicon}
          timeEntry={timeEntry}
          key={index}
          disabled={disabled}
          addSchedule={addSchedule}
          timeEntries={timeentries}
          changeTimeEntry={(timeEntry: TimeEntryTimeOnly) =>
            changeTimeEntry(timeEntry)
          }
          handleScheduleDeleteClicked={(timeEntry: TimeEntryTimeOnly) =>
            handleScheduleDeleteClicked(timeEntry)
          }
          handleAdd={() => handleAdd()}
        ></TimeEntryUITimeOnly>,
      );
    });
    return <>{timeEntryContent}</>;
  } else {
    return <></>;
  }
};

export default TimeEntryPanelTimeOnly;
