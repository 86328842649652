import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import { getBaseUrl } from "../../HelperFunctions";
import { ReportGroupForTree, Site } from "../../../services/FunctionService";
import { ArrayToStringifiedArray } from "../../../hooks/ArrayToStringifiedArray";

import LoadingSpinner from "../../../spinner/LoadingSpinner";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
  Theme,
  useTheme,
  makeStyles,
  withStyles,
  createStyles,
} from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface ReportProps {
  appStore?: AppStore;
}

// Switch Styles
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

// const classer = useStyles();
const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:hover": {
        backgroundColor: "#e3f2fd",
      },
    },
  }),
)(TableRow);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const onSubmitHandler = () => {};

const ReportOffline: React.FC<ReportProps> = ({ appStore }) => {
  const [reportGroupNames, setReportGroupNames] = React.useState<
    string[] | any[]
  >([]);

  const [reportgroupsForWholeTree, setReportGroupsForWholeTree] = useState<
    ReportGroupForTree[] | undefined
  >([]);

  // The first commit of Material-UI
  const [personName, setPersonName] = React.useState<string[]>([]);
  const theme = useTheme();
  const [groupid, setGroupId] = React.useState<number>(-1);
  const [loading, setLoading] = React.useState(true);
  const [orgslist, setOrgsList] = React.useState<any>([]);
  const [siteList, setSiteList] = React.useState<any>([]);
  const [groupname, setGroupName] = React.useState("");
  const [groupTreeName, setGroupTreeName] = React.useState("");
  const [selectedSite, setSelectedSite] = React.useState("select site");
  const [selectedSiteId, setSelectedSiteId] = React.useState<number | null>(
    null,
  );
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [reportIds, setReportIds] = React.useState<number[]>([]);

  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [selectedReport, setSelectedReport] = React.useState<
    Report | undefined | unknown
  >();
  const [selectedReportGroupId, setSelectedReportGroupId] = React.useState<
    number | unknown
  >(-1);
  const [selectedReportId, setSelectedReportId] = React.useState<number>(-1);

  const [selectAll, setSelectAll] = React.useState(false);
  const [unSelectAll, setUnSelectAll] = React.useState(false);
  const [sitePushedIds, setSitePushedIds] = React.useState<number[] | any[]>(
    [],
  );
  const [siteIds, setSiteIds] = React.useState<number[]>([]);

  const [siteReports, setSiteReports] = useState<Report[]>([]);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("md");
  const [incSubgroups, setIncSubgroups] = useState(false);

  const [message, setMessage] = React.useState("");
  const [err, setErr] = React.useState(false);

  const styler = useStyle();

  const classes = useStyles();

  interface Report {
    report_id: number;
    site_ids: number[];
    report_group_id: number;
    site_names: string;
    org_tree_names: string;
    report_type: string;
    report_group_name: string;
    config: {
      time: string;
      isoweekday: number;
      include_escalated_events?: boolean;
    };
    timezone_str: string;
  }

  const reloadOrgs = async (id?: number) => {
    if (id && id !== groupid) {
      return setGroupId(id);
    }
    await fetchOrgsData();
    await fetchReports();
  };

  const siteIdsHandler = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let status = event.target.checked;
    let newItem = id;
    if (status) {
      siteIds.push(newItem);
    } else {
      const index = siteIds.indexOf(id);
      if (index > -1) {
        siteIds.splice(index, 1);
      }
    }
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const setAddClose = () => {
    setOpenAdd(false);
  };

  const setEditClose = () => {
    setOpenEdit(false);
  };

  const continueToDeleteHandler = async () => {
    try {
      setLoading(true);
      const request = JSON.stringify({
        // report_id: deleteId,
        report_ids: ArrayToStringifiedArray(reportIds),
      });
      const token = await appStore?.authService.getAuthorisedToken();
      const res = fetch(getBaseUrl() + "/site_report", {
        method: "delete",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = await (await res).json();
      setReportIds([]);
      handleDeleteClose();
      await fetchReports();
      return result;
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  //
  const reportIdsHandler = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let status = event.target.checked;
    let newItem = id;
    if (status) {
      reportIds.push(newItem);
    } else {
      const index = reportIds.indexOf(id);
      if (index > -1) {
        reportIds.splice(index, 1);
      }
    }
  };

  const handleChangeAuto = (value: any) => {
    if (!value) {
      return setGroupId(-1);
    }
    setReportGroupNames([]);
    setSelectedSite("select site");
    setSelectedSiteId(null);
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      let tree = group.org_tree_names!.map((item: any) => `   /   ${item} `);
      let treeString = tree.toString();
      const rpl = treeString.replace(/,/g, "");
      setGroupTreeName(rpl);

      setGroupName(group.org_name);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      setLoading(true);
      if (token) {
        const reportgroupsforwholetree =
          await appStore?.functionService.getReportGroups(
            token,
            appStore?.authService.getEntryOrg()?.id!,
          );
        if (reportgroupsforwholetree) {
          setReportGroupsForWholeTree(reportgroupsforwholetree);
        }
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const fetchOrgsData = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          enabled: true,
          paginate: false,
          verbose: false,
          incl_tree_names: true,
        });

        const res = fetch(baseUrl + "/list_orgs", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        const orglist = result;
        if (orglist && orglist.success) {
          let orgz = orglist?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }
    }
  };

  useEffect(() => {
    fetchOrgsData();
  }, [groupid]);

  // Fetch sites
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      if (token) {
        try {
          const request = JSON.stringify({
            enabled: true,
            top_org_id: groupid,
            top_org_only: false,
            paginate: false,
          });

          const res = fetch(baseUrl + "/list_sites", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });
          const result = await (await res).json();
          if (result && result.success) {
            let sites: Site[] = result?.sites;
            sites.sort(function (a: any, b: any) {
              return a.site_name - b.site_name;
            });
            setSiteList(sites);
            sites.map((site: Site) => {
              sitePushedIds.push(site.site_id);
            });
          }
          setLoading(false);
        } catch (err) {
          return err;
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [groupid]);

  //Table Content Setup
  var sites_display: React.ReactNode[] = [];
  if (siteList && siteList.length > 0) {
    siteList.forEach((site: { [key: string]: any }, index: any) => {
      let group = orgslist?.find((x: any) => x.org_id === site.org_id);
      let rpl;
      if (group) {
        let tree = group.org_tree_names!.map((item: any) => `   /   ${item} `);
        let treeString = tree.toString();
        rpl = treeString.replace(/,/g, "");
      }
      sites_display.push(
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.site_id}
            </Typography>
          </TableCell>
          {/* {groupTreeName && ( */}
          <TableCell>
            <Typography className={classes.bold_text}>{rpl}</Typography>
          </TableCell>
          {/* )} */}
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.site_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.product}
            </Typography>
          </TableCell>
          <TableCell>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Tooltip
                placement="bottom"
                title="select site for scheduled reports"
              >
                <Grid item>
                  <AntSwitch
                    defaultChecked={selectAll && !unSelectAll ? true : false}
                    onChange={(e) => {
                      siteIdsHandler(site.site_id, e);
                    }}
                    name="checkedC"
                  />
                </Grid>
              </Tooltip>
            </Grid>
          </TableCell>
        </StyledTableRow>,
      );
    });
  }

  //Table Content Setup
  var reports_display: React.ReactNode[] = [];
  if (siteReports && siteReports.length > 0)
    siteReports.forEach((site_report: Report | any, index: any) => {
      if (site_report.report_type !== "site_health:connection") {
        return;
      }
      reports_display.push(
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site_report.report_group_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site_report.org_tree_names}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site_report.site_names}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site_report.report_type}
            </Typography>
          </TableCell>
          <TableCell>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Tooltip
                placement="bottom"
                title="select site for scheduled reports"
              >
                <Grid item>
                  <AntSwitch
                    // defaultChecked={selectAll && !unSelectAll ? true : false}
                    onChange={(e) => {
                      reportIdsHandler(site_report.report_id, e);
                    }}
                    name="checkedC"
                  />
                </Grid>
              </Tooltip>
            </Grid>
          </TableCell>
        </StyledTableRow>,
      );
    });

  const onEditHandler = () => {
    updateReport();
  };

  const updateReport = async () => {
    setLoading(true);
    var baseUrl = getBaseUrl();
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      try {
        const request = JSON.stringify({
          report_id: selectedReportId,
          report_group_id: selectedReportGroupId,
        });

        const res = await fetch(baseUrl + "/site_report", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        if (!res.ok) {
          setErr(true);
          setMessage("Something went wrong, please try again");
        }
        const result = (await (await res).json()) as any;
        const msg = result.msg;

        if (result.success) {
          setErr(false);
        }

        if (!result.success) {
          setMessage(msg);
        }
      } catch (err) {
        console.debug(err);
      }
      setLoading(false);
      reloadOrgs();
      setEditClose();
    }
  };

  // ######### GET REPORTS #############
  const fetchReports = async () => {
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          org_id: groupid,
          top_org_only: !incSubgroups,
        });

        const res = fetch(baseUrl + "/list_site_reports", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        const result = await (await res).json();
        if (result && result.success) {
          let reports = result?.reports;

          // Transform data
          let arr: Report[] = [];
          reports.forEach((site_report: { [key: string]: any }, index: any) => {
            let tree = site_report.org_tree_names!.map(
              (item: any) => ` / ${item} `,
            );
            let treeString = tree.toString();
            const rpl = treeString.replace(/,/g, "");
            arr.push({
              report_id: site_report.report_id,
              site_ids: site_report.site_ids,
              report_group_id: site_report.report_group_id,
              org_tree_names: rpl,
              report_type: site_report.report_type,
              config: site_report.config,
              timezone_str: site_report.timezone_str,
              site_names: site_report.site_names,
              report_group_name: site_report.report_group_name,
            });
          });
          // End
          setSiteReports(arr);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }
    }
  };

  useEffect(() => {
    fetchReports();
  }, [groupid, incSubgroups]);

  const onSubmitHandler = () => {
    setMessage("");

    let type = "monthly";

    if (selectedReport === -1) {
      setMessage("PLEASE SELECT REPORT GROUP");
      return;
    }

    if (siteIds.length === 0) {
      setMessage("PLEASE SELECT AT LEAST 1 SITE");
      return;
    }

    addReport();
  };

  const addReport = async () => {
    setLoading(true);
    var baseUrl = getBaseUrl();
    var token = await appStore?.authService.getAuthorisedToken();
    setMessage("");
    if (token) {
      try {
        const request = JSON.stringify({
          org_id: groupid,
          report_group_id: selectedReport,
          site_ids: ArrayToStringifiedArray(siteIds),
          // site_ids: siteIds.length === 1 ? `[${siteIds}]` : `${siteIds}`,
          report_type: "site_health:connection",
          // config: "",
          timezone_str: "UTC",
        });

        const res = await fetch(baseUrl + "/site_report", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        if (!res.ok) {
          setErr(true);
          setMessage("Something went wrong, please try again");
        }
        const result = (await (await res).json()) as any;
        const msg = result.msg;

        if (result.success) {
          setErr(false);
        }

        if (!result.success) {
          setMessage(msg);
        }
      } catch (err) {
        console.debug(err);
      }
      setSiteIds([]);
      setSelectedReportGroupId(-1);
      setSelectedReport(undefined);
      setLoading(false);
      reloadOrgs(groupid);
      setAddClose();
    }
  };

  const handleOrderChange = (e: string) => {
    let order = e;

    if (order == "Report Group ASC") {
      sortByHandler("Report Group", true);
    }

    if (order == "Report Group DSC") {
      sortByHandler("Report Group", false);
    }

    if (order === "Site ASC") {
      sortByHandler("Site", true);
    }

    if (order === "Site DSC") {
      sortByHandler("Site", false);
    }

    if (order === "Report Type ASC") {
      sortByHandler("Report Type", true);
    }

    if (order === "Report Type DSC") {
      sortByHandler("Report Type", false);
    }

    if (order === "Org Group ASC") {
      sortByHandler("Org Group", true);
    }

    if (order === "Org Group DSC") {
      sortByHandler("Org Group", false);
    }
  };

  const sortByHandler = (orderBy: string | unknown, direction: boolean) => {
    switch (orderBy) {
      case "Report Group":
        if (direction) {
          const list = [...siteReports].sort((a, b): number => {
            if (
              b.report_group_name.toLowerCase() >
              a.report_group_name.toLowerCase()
            ) {
              return -1;
            }
            if (
              b.report_group_name.toLowerCase() <
              a.report_group_name.toLowerCase()
            ) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        } else {
          const list = [...siteReports].sort((a, b): number => {
            if (
              b.report_group_name.toLowerCase() >
              a.report_group_name.toLowerCase()
            ) {
              return 1;
            }
            if (
              b.report_group_name.toLowerCase() <
              a.report_group_name.toLowerCase()
            ) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        }
      case "Org Group":
        if (direction) {
          const list = [...siteReports].sort((a, b): number => {
            if (
              b.org_tree_names.toLowerCase() > a.org_tree_names.toLowerCase()
            ) {
              return -1;
            }
            if (
              b.org_tree_names.toLowerCase() < a.org_tree_names.toLowerCase()
            ) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        } else {
          const list = [...siteReports].sort((a, b): number => {
            if (
              b.org_tree_names.toLowerCase() > a.org_tree_names.toLowerCase()
            ) {
              return 1;
            }
            if (
              b.org_tree_names.toLowerCase() < a.org_tree_names.toLowerCase()
            ) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        }
      case "Site":
        if (direction) {
          const list = [...siteReports].sort((a, b): number => {
            if (b.site_names[0].toLowerCase() > a.site_names[0].toLowerCase()) {
              return -1;
            }
            if (b.site_names[0].toLowerCase() < a.site_names[0].toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        } else {
          const list = [...siteReports].sort((a, b): number => {
            if (b.site_names[0].toLowerCase() > a.site_names[0].toLowerCase()) {
              return 1;
            }
            if (b.site_names[0].toLowerCase() < a.site_names[0].toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        }
      case "Report Type":
        if (direction) {
          const list = [...siteReports].sort((a, b): number => {
            if (b.report_type.toLowerCase() > a.report_type.toLowerCase()) {
              return -1;
            }
            if (b.report_type.toLowerCase() < a.report_type.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        } else {
          const list = [...siteReports].sort((a, b): number => {
            if (b.report_type.toLowerCase() > a.report_type.toLowerCase()) {
              return 1;
            }
            if (b.report_type.toLowerCase() < a.report_type.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setSiteReports(newArr);
          return;
        }
    }
  };

  return (
    <div>
      <>
        {!loading && (
          <>
            {/* ORG LIST FORM */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: 950,
              }}
            >
              <div>
                <FormControl
                  className={classes.form_component_medium}
                  variant="outlined"
                  fullWidth={true}
                >
                  <InputLabel className={classes.bold_text}>
                    Select Group to Filter Reports By Site Group:{" "}
                  </InputLabel>
                  <br></br>
                  <Autocomplete
                    id="combo-box-demo"
                    className={classes.form_component_medium}
                    options={orgslist}
                    onChange={(event: React.ChangeEvent<any>, value: any) => {
                      handleChangeAuto(value);
                    }}
                    getOptionLabel={(option) => {
                      let tree = option.org_tree_names!.map(
                        (item: any) => `   /   ${item} `,
                      );
                      let treeString = tree.toString();
                      const rpl = treeString.replace(/,/g, "");
                      return `${rpl}`;
                    }}
                    style={{ width: 600, color: "#6D809D" }}
                    classes={{ paper: styler.paper }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span
                            className={classes.bold_text}
                            style={{ color: "#15325F" }}
                          >
                            {groupname ? groupname : "Please Select Group"}
                          </span>
                        }
                        variant="outlined"
                        value={groupid}
                        className={classes.bold_text}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <Typography className={classes.header_text}>
                    Include Sub-Groups
                  </Typography>
                </FormControl>
                <FormControl>
                  <Checkbox
                    className={classes.form_component_checkbox}
                    onChange={() => {
                      setIncSubgroups((prev) => !prev);
                    }}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    checked={incSubgroups}
                  />
                </FormControl>
              </div>
            </div>

            {/* TABLE */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: 1150,
              }}
            >
              <div style={{ marginLeft: 20 }}>
                <h2 className={classes.bold_text}>Report List:</h2>
              </div>
              <div style={{ display: "flex", paddingLeft: 15 }}>
                <Tooltip
                  title="Select your group first to add report"
                  aria-label="add"
                >
                  <Button
                    variant="contained"
                    className={classes.mute_button}
                    onClick={() => {
                      if (groupid === -1) {
                        return setMessage("Please select group first");
                        setErr(true);
                      }

                      setOpenAdd(true);
                    }}
                  >
                    Add Report
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Select the reports from the table first to delete."
                  aria-label="add"
                >
                  <Button
                    variant="contained"
                    className={classes.mute_button}
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      if (reportIds.length == 0) {
                        return;
                      }
                      setOpenDelete(true);
                    }}
                  >
                    Delete
                  </Button>
                </Tooltip>
              </div>
            </div>
            <br />
            <div
              style={{
                boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                borderRadius: 15,
                minHeight: "fit-content",
                maxHeight: 450,
                overflowY: "scroll",
                backgroundColor: "white",
                marginLeft: 40,
                marginRight: 20,
                marginBottom: 35,
                width: 1200,
              }}
            >
              <TableContainer component={Paper} style={{ maxHeight: 350 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 13,
                              color: "#616161",
                              fontWeight: "bold",
                            }}
                          >
                            Report Group
                          </span>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginTop: 10,
                              marginLeft: 8,
                              cursor: "pointer",
                            }}
                          >
                            <span>
                              <ArrowDropUpIcon
                                onClick={() => {
                                  handleOrderChange("Report Group ASC");
                                }}
                              />
                            </span>
                            <span>
                              <ArrowDropDownIcon
                                onClick={() => {
                                  handleOrderChange("Report Group DSC");
                                }}
                              />
                            </span>
                          </span>
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 13,
                              color: "#616161",
                              fontWeight: "bold",
                            }}
                          >
                            Group
                          </span>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginTop: 10,
                              marginLeft: 8,
                              cursor: "pointer",
                            }}
                          >
                            <span>
                              <ArrowDropUpIcon
                                onClick={() => {
                                  handleOrderChange("Org Group ASC");
                                }}
                              />
                            </span>
                            <span>
                              <ArrowDropDownIcon
                                onClick={() => {
                                  handleOrderChange("Org Group DSC");
                                }}
                              />
                            </span>
                          </span>
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 13,
                              color: "#616161",
                              fontWeight: "bold",
                            }}
                          >
                            Site Name
                          </span>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginTop: 10,
                              marginLeft: 8,
                              cursor: "pointer",
                            }}
                          >
                            <span>
                              <ArrowDropUpIcon
                                onClick={() => {
                                  handleOrderChange("Site ASC");
                                }}
                              />
                            </span>
                            <span>
                              <ArrowDropDownIcon
                                onClick={() => {
                                  handleOrderChange("Site DSC");
                                }}
                              />
                            </span>
                          </span>
                        </span>
                      </TableCell>
                      {/* )} */}
                      <TableCell>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 13,
                              color: "#616161",
                              fontWeight: "bold",
                            }}
                          >
                            Report Type
                          </span>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginTop: 10,
                              marginLeft: 8,
                              cursor: "pointer",
                            }}
                          >
                            <span>
                              <ArrowDropUpIcon
                                onClick={() => {
                                  handleOrderChange("Report Type ASC");
                                }}
                              />
                            </span>
                            <span>
                              <ArrowDropDownIcon
                                onClick={() => {
                                  handleOrderChange("Report Type DSC");
                                }}
                              />
                            </span>
                          </span>
                        </span>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 13,
                              color: "#616161",
                              fontWeight: "bold",
                              display: "flex",
                            }}
                          >
                            Select
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{reports_display}</TableBody>
                </Table>
              </TableContainer>
            </div>

            {/* DIALOG END */}
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete report?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose} color="primary">
                  No
                </Button>
                <Button
                  onClick={continueToDeleteHandler}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            {!loading && openAdd && (
              <>
                <Dialog
                  fullWidth={false}
                  maxWidth={maxWidth}
                  open={openAdd}
                  onClose={setAddClose}
                  aria-labelledby="max-width-dialog-title"
                >
                  <DialogContent>
                    {/* DIALOG START */}
                    <h3
                      className={classes.bold_text}
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        textTransform: "capitalize",
                        marginBottom: 20,
                      }}
                    >
                      <>Add Site Offline Notifications Report</>
                    </h3>
                    <Divider style={{ marginBottom: 20 }} />
                    <div style={{ marginLeft: 40 }}>
                      <FormControl className={classes.form_component_medium}>
                        <h2
                          className={classes.bold_text}
                          style={{ marginTop: 25, marginBottom: 25 }}
                        >
                          Select report group to send site offline notifications
                          report:
                        </h2>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Report Type"
                          value={selectedReport}
                          onChange={(e) => {
                            setSelectedReport(e.target.value);
                          }}
                        >
                          {reportgroupsForWholeTree!.map((report) => (
                            <MenuItem
                              key={report.id}
                              value={report.id}
                              style={getStyles(report.name, personName, theme)}
                            >
                              {report.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <FormControl
                      className={classes.form_component_medium}
                      variant="outlined"
                      fullWidth={false}
                    >
                      <div
                        style={{
                          marginLeft: 40,
                          display: "flex",
                          justifyContent: "space-between",
                          width: 900,
                        }}
                      >
                        <h2 className={classes.bold_text}>
                          Select site(s) you want a report for:
                        </h2>
                      </div>
                      <br />
                    </FormControl>
                    {/* TABLE CONTENT */}
                    <div
                      style={{
                        boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                        borderRadius: 15,
                        minHeight: "fit-content",
                        maxHeight: 250,
                        overflowY: "scroll",
                        backgroundColor: "white",
                        marginLeft: 50,
                        marginRight: 20,
                        marginBottom: 35,
                        width: 850,
                      }}
                    >
                      <TableContainer component={Paper}>
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Site_ID
                                </span>
                              </TableCell>
                              {groupname && (
                                <TableCell>
                                  <span
                                    style={{
                                      fontSize: 13,
                                      color: "#616161",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Group
                                  </span>
                                </TableCell>
                              )}
                              <TableCell>
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Site Name
                                </span>
                              </TableCell>
                              <TableCell>
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Product
                                </span>
                              </TableCell>
                              <TableCell>
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                    display: "flex",
                                  }}
                                >
                                  Select
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>{sites_display}</TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <div style={{ marginRight: 40 }}>
                      <Button
                        onClick={setAddClose}
                        color="primary"
                        className={classes.cancel_button2}
                      >
                        Close
                      </Button>
                      <Button
                        onClick={onSubmitHandler}
                        color="primary"
                        className={classes.mute_button}
                      >
                        Submit
                      </Button>
                    </div>
                  </DialogActions>
                </Dialog>
                <Dialog
                  fullWidth={false}
                  maxWidth={maxWidth}
                  open={openEdit}
                  onClose={setEditClose}
                  aria-labelledby="max-width-dialog-title"
                >
                  <DialogContent>
                    <h3
                      className={classes.bold_text}
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        textTransform: "capitalize",
                        marginBottom: 20,
                        width: 800,
                      }}
                    >
                      <>Edit Site Offline Notifications Report</>
                    </h3>
                    <Divider style={{ marginBottom: 20 }} />
                    <div style={{ marginLeft: 40 }}>
                      <FormControl className={classes.form_component_medium}>
                        <h2
                          className={classes.bold_text}
                          style={{ marginTop: 25, marginBottom: 25 }}
                        >
                          Select report group to send site offline notifications
                          report:
                        </h2>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Report Type"
                          value={selectedReportGroupId}
                          onChange={(e) => {
                            setSelectedReportGroupId(e.target.value);
                          }}
                        >
                          {reportgroupsForWholeTree!.map((report) => (
                            <MenuItem
                              key={report.id}
                              value={report.id}
                              style={getStyles(report.name, personName, theme)}
                            >
                              {report.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <div style={{ marginRight: 40 }}>
                      <Button
                        onClick={setEditClose}
                        color="primary"
                        className={classes.cancel_button2}
                      >
                        Close
                      </Button>
                      <Button
                        onClick={onEditHandler}
                        color="primary"
                        className={classes.mute_button}
                      >
                        Submit
                      </Button>
                    </div>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </>
        )}
        {loading && <LoadingSpinner />}
      </>
    </div>
  );
};

export default ReportOffline;
