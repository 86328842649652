import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { AppStore } from "../../../stores/AppStore";
import Tooltip from "@material-ui/core/Tooltip";
import {
  EthernetDetail,
  Hub,
  IpValidity,
  NameServers,
  Network,
  NetworkConfig,
  Sensor,
} from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from "@material-ui/icons/Close";
import { Container, Divider, FormControl } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { AddCircle, RestaurantRounded } from "@material-ui/icons";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

interface EditHubModalProps {
  appStore?: AppStore;
  onClose: () => void;
  hub: Hub;
  site?: any;
  open: boolean;
  updateHub: (location: string, networkconfig: NetworkConfig) => void;
  updateSharedHub: (location: string, networkconfig: NetworkConfig) => void;
}
class impIpValidity implements IpValidity {
  netmask!: string;
  key!: string;
  index!: number;
  type!: string;
  valid!: boolean;
}
class impNameServers implements NameServers {
  addresses: string[] = [];
}
class impEthernetDetail implements EthernetDetail {
  dhcp4: boolean = true;
  dhcp6: boolean = false;
  gateway4: string = "";
  addresses: string[] = [];
  nameservers: NameServers = new impNameServers();
}
const EditHubModal: React.FC<EditHubModalProps> = ({
  onClose,
  open,
  updateHub,
  updateSharedHub,
  hub,
  appStore,
  site,
}: EditHubModalProps) => {
  const classes = useStyles();
  const [location, setLocation] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState(0);
  const [newethernetname, setNewEthernetName] = useState("");
  const [networkconfig, setNetworkConfig] = React.useState<
    NetworkConfig | undefined
  >(undefined);
  const [message, setMessage] = React.useState("");
  const [restart, setRestart] = useState<boolean>(false);
  const [validips, setValidIps] = React.useState<IpValidity[]>([]);

  const isCameraMate =
    site?.data_origin?.includes("client_hub_cameramate") || false;

  useEffect(() => {
    const fetchData = async () => {
      if (hub && hub.location) {
        setLocation(hub.location);
      }
    };
    fetchData();
  }, [open]);

  useEffect(() => {
    const fetchData = async () => {
      if (hub && open) {
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var network_config =
            await appStore?.functionService.getNetworkConfigForHub(
              token,
              hub.hub_id,
            );
          if (network_config) {
            setNetworkConfig(network_config);
          }
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [open]);
  useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && hub && restart) {
        const hub_restart = await appStore?.functionService.forceHubRestart(
          token,
          hub.hub_id,
        );
        setLoading(false);
        if (hub_restart?.success) {
          setMessage("Hub restart instruction sent successfully");
        } else {
          if (hub_restart?.msg && hub_restart?.msg.indexOf("timed out") >= 0) {
            setMessage("Hub restart instruction sent");
          } else {
            setMessage("Hub restart instruction failed: " + hub_restart?.msg);
          }
        }
        setRestart(false);
      }
    };
    fetchData();
  }, [restart]);
  const handleSharedUpdate = () => {
    let error_message = "";
    if (
      networkconfig &&
      Object.keys(networkconfig.network.ethernets).length > 0
    ) {
      let canupdate = true;
      for (let key in networkconfig.network.ethernets) {
        let ethernet = networkconfig.network.ethernets[key];
        if (
          !ethernet.dhcp4 &&
          (ethernet.addresses === undefined || ethernet.addresses.length === 0)
        ) {
          canupdate = false;
          error_message =
            error_message + key + " must have at least 1 IP Address, ";
        }
        if (
          !ethernet.dhcp4 &&
          (ethernet.nameservers === undefined ||
            ethernet.nameservers.addresses === undefined ||
            ethernet.nameservers.addresses.length === 0)
        ) {
          canupdate = false;
          error_message =
            error_message + key + " must have at least 1 Name server, ";
        }
        if (
          !ethernet.dhcp4 &&
          (ethernet.gateway4 === undefined || ethernet.gateway4 === "")
        ) {
          canupdate = false;
          error_message = error_message + key + " gateway cannot be blank, ";
        }
      }
      validips.forEach((validip) => {
        if (!validip.valid) {
          canupdate = false;
          error_message =
            error_message +
            validip.type +
            " for " +
            validip.key +
            " is not valid, ";
        }
      });
      if (canupdate) {
        updateSharedHub(location!, networkconfig!);
      } else {
        error_message =
          error_message.substring(0, error_message.length - 2) + ".";
        setMessage(error_message);
      }
    } else {
      setMessage("You must have at least one ethernet endpoint configured!");
    }
  };
  const handleUpdate = () => {
    let error_message = "";
    if (
      networkconfig &&
      Object.keys(networkconfig.network.ethernets).length > 0
    ) {
      let canupdate = true;
      for (let key in networkconfig.network.ethernets) {
        let ethernet = networkconfig.network.ethernets[key];
        if (
          !ethernet.dhcp4 &&
          (ethernet.addresses === undefined || ethernet.addresses.length === 0)
        ) {
          canupdate = false;
          error_message =
            error_message + key + " must have at least 1 IP Address, ";
        }
        if (
          !ethernet.dhcp4 &&
          (ethernet.nameservers === undefined ||
            ethernet.nameservers.addresses === undefined ||
            ethernet.nameservers.addresses.length === 0)
        ) {
          canupdate = false;
          error_message =
            error_message + key + " must have at least 1 Nameserver, ";
        }
        if (
          !ethernet.dhcp4 &&
          (ethernet.gateway4 === undefined || ethernet.gateway4 === "")
        ) {
          canupdate = false;
          error_message = error_message + key + " gateway cannot be blank, ";
        }
      }
      validips.forEach((validip) => {
        if (!validip.valid) {
          canupdate = false;
          error_message =
            error_message +
            validip.type +
            " for " +
            validip.key +
            " is not valid, ";
        }
      });
      if (canupdate) {
        if (hub.site_id === undefined || hub.site_id === null) {
          updateSharedHub(location!, networkconfig!);
        } else {
          updateHub(location!, networkconfig!);
        }
      } else {
        error_message =
          error_message.substring(0, error_message.length - 2) + ".";
        setMessage(error_message);
      }
    } else {
      setMessage("You must have at least one ethernet endpoint configured!");
    }
  };
  const handleClose = () => {
    setMessage("");
    onClose();
  };
  const handleEthernetNameChange = (event: React.ChangeEvent<any>) => {
    setNewEthernetName(event.target.value);
  };
  const handleLocationChange = (event: React.ChangeEvent<any>) => {
    setLocation(event.target.value);
  };
  const handleRestart = () => {
    setMessage("");
    setLoading(true);
    setRestart(true);
  };
  const handleIPAddressChange = (
    event: React.ChangeEvent<any>,
    key: string,
    index: number,
  ) => {
    let newnetworkconfig = networkconfig;
    if (newnetworkconfig) {
      let newIp = event.target.value;
      let valid = ValidateCIDR(newIp);
      if (valid) {
        let strbitCount = newIp.substring(newIp.indexOf("/") + 1);
        let bitCount = parseInt(strbitCount);
        let newnetmask = createNetmaskAddr(bitCount);
        let newvalidips = validips;
        let idx = validips.findIndex(
          (ip) =>
            ip.key === key && ip.index === index && ip.type === "ipaddress",
        );
        if (idx >= 0) {
          newvalidips[idx].netmask = newnetmask;
        }
        setValidIps(newvalidips);
      }
      setIpValidity(key, index, "ipaddress", valid);
      newnetworkconfig.network.ethernets[key].addresses[index] = newIp;
      setNetworkConfig(newnetworkconfig);
      setRefresh(new Date().getTime());
    }
  };
  const addIpAddress = (key: string) => {
    let newnetworkconfig = networkconfig;
    if (newnetworkconfig) {
      if (newnetworkconfig.network.ethernets[key].addresses === undefined) {
        newnetworkconfig.network.ethernets[key].addresses = [];
      }
      newnetworkconfig.network.ethernets[key].addresses.push("");
      setNetworkConfig(newnetworkconfig);
      setRefresh(new Date().getTime());
    }
  };
  const removeIpAddress = (key: string, index: number) => {
    let newnetworkconfig = networkconfig;
    if (newnetworkconfig) {
      if (
        newnetworkconfig.network.ethernets[key].addresses &&
        newnetworkconfig.network.ethernets[key].addresses.length >= index - 1
      ) {
        newnetworkconfig.network.ethernets[key].addresses.splice(index, 1);
        removeIpValidity(key, index, "ipaddress");
      }
      setNetworkConfig(newnetworkconfig);
      setRefresh(new Date().getTime());
    }
  };
  const handleGatewayChange = (event: React.ChangeEvent<any>, key: string) => {
    let newnetworkconfig = networkconfig;
    if (newnetworkconfig) {
      let valid = ValidateIPaddress(event.target.value);
      setIpValidity(key, 0, "gateway", valid);
      newnetworkconfig.network.ethernets[key].gateway4 = event.target.value;
      setNetworkConfig(newnetworkconfig);
      setRefresh(new Date().getTime());
    }
  };
  const handleNameServerChange = (
    event: React.ChangeEvent<any>,
    key: string,
    index: number,
  ) => {
    let newnetworkconfig = networkconfig;
    if (newnetworkconfig) {
      let valid = ValidateIPaddress(event.target.value);
      setIpValidity(key, index, "nameserver", valid);
      newnetworkconfig.network.ethernets[key].nameservers.addresses[index] =
        event.target.value;
      setNetworkConfig(newnetworkconfig);
      setRefresh(new Date().getTime());
    }
  };
  const addNameServer = (key: string) => {
    let newnetworkconfig = networkconfig;
    if (newnetworkconfig) {
      if (newnetworkconfig.network.ethernets[key].nameservers === undefined) {
        newnetworkconfig.network.ethernets[key].nameservers =
          new impNameServers();
      }
      newnetworkconfig.network.ethernets[key].nameservers.addresses.push("");
      setNetworkConfig(newnetworkconfig);
      setRefresh(new Date().getTime());
    }
  };
  const removeNameServer = (key: string, index: number) => {
    let newnetworkconfig = networkconfig;
    if (newnetworkconfig) {
      if (
        newnetworkconfig.network.ethernets[key].nameservers.addresses &&
        newnetworkconfig.network.ethernets[key].nameservers.addresses.length >=
          index - 1
      ) {
        newnetworkconfig.network.ethernets[key].nameservers.addresses.splice(
          index,
          1,
        );
        removeIpValidity(key, index, "nameserver");
      }
      setNetworkConfig(newnetworkconfig);
      setRefresh(new Date().getTime());
    }
  };
  const addEthernet = () => {
    let newnetworkconfig = networkconfig;
    if (newnetworkconfig) {
      let ethernetdetail = new impEthernetDetail();
      newnetworkconfig.network.ethernets["new"] = ethernetdetail;
      setNetworkConfig(newnetworkconfig);
      setRefresh(new Date().getTime());
    }
  };
  const removeEthernet = (key: string) => {
    let newnetworkconfig = networkconfig;
    if (newnetworkconfig) {
      if (key in newnetworkconfig.network.ethernets) {
        delete newnetworkconfig.network.ethernets[key];
      }
      let newvalidips: IpValidity[] = [];
      validips.forEach((validip) => {
        if (validip.key !== key) {
          newvalidips.push(validip);
        }
      });
      setValidIps(newvalidips);
      setNetworkConfig(newnetworkconfig);
      setRefresh(new Date().getTime());
    }
  };

  const handleDHCPSelection = (event: React.ChangeEvent<any>, key: string) => {
    let newnetworkconfig = networkconfig;
    if (newnetworkconfig) {
      if (event.target.value === "true") {
        newnetworkconfig.network.ethernets[key] = JSON.parse(
          '{"dhcp4": true,"dhcp6": false}',
        );
      } else {
        newnetworkconfig.network.ethernets[key].dhcp4 = false;
        addNameServer(key);
        addIpAddress(key);
      }
      setNetworkConfig(newnetworkconfig);
      setRefresh(new Date().getTime());
    }
  };
  const handleClickAway = () => {
    if (newethernetname != "") {
      let newnetworkconfig = networkconfig;
      if (newnetworkconfig) {
        newnetworkconfig.network.ethernets[newethernetname] =
          newnetworkconfig.network.ethernets["new"];
        delete newnetworkconfig.network.ethernets["new"];
        setNetworkConfig(newnetworkconfig);
        setNewEthernetName("");
        setRefresh(new Date().getTime());
      }
    }
  };
  function ValidateIPaddress(ipaddress: string) {
    if (
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(
        ipaddress,
      )
    ) {
      let components = ipaddress.split(".");
      components.forEach((component) => {
        if (parseInt(component) > 255) {
          return false;
        }
      });
      return true;
    }
    return false;
  }
  function ValidateCIDR(ipaddress: string) {
    if (
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/.test(
        ipaddress,
      )
    ) {
      let i = ipaddress.indexOf("/");
      let ip = ipaddress.substring(0, i);
      let components = ip.split(".");
      components.forEach((component) => {
        if (parseInt(component) > 255) {
          return false;
        }
      });
      let cdr = ipaddress.substring(i + 1);
      if (parseInt(cdr) < 8 || parseInt(cdr) > 28) {
        return false;
      }
      return true;
    }
    return false;
  }
  function setIpValidity(
    key: string,
    index: number,
    type: string,
    valid: boolean,
  ) {
    let newvalidips = validips;
    let idx = validips.findIndex(
      (ip) => ip.key === key && ip.index === index && ip.type === type,
    );
    if (idx < 0) {
      let validip = new impIpValidity();
      validip.key = key;
      validip.index = index;
      validip.type = type;
      validip.valid = valid;
      validip.netmask = "";
      newvalidips.push(validip);
    } else {
      newvalidips[idx].valid = valid;
    }
    setValidIps(newvalidips);
  }
  function getIpValidity(
    ipaddress: string,
    key: string,
    index: number,
    type: string,
  ) {
    let idx = validips.findIndex(
      (ip) => ip.key === key && ip.index === index && ip.type === type,
    );

    if (!ipaddress) {
      return false;
    }

    if (idx >= 0) {
      return validips[idx].valid;
    } else {
      //not in the valid array, so check and add
      let valid = false;
      if (ipaddress.indexOf("/") > 0) {
        valid = ValidateCIDR(ipaddress);
      } else {
        valid = ValidateIPaddress(ipaddress);
      }
      setIpValidity(key, index, type, valid);
      return valid;
    }
  }
  function removeIpValidity(key: string, index: number, type: string) {
    let newvalidips = validips;
    let idx = validips.findIndex(
      (ip) => ip.key === key && ip.index === index && ip.type === type,
    );
    if (idx >= 0) {
      newvalidips.splice(idx, 1);
    }
    setValidIps(newvalidips);
  }
  function createNetmaskAddr(bitCount: number) {
    var mask = [],
      i,
      n;
    for (i = 0; i < 4; i++) {
      n = Math.min(bitCount, 8);
      mask.push(256 - Math.pow(2, 8 - n));
      bitCount -= n;
    }
    return mask.join(".");
  }
  var shared: React.ReactNode[] = [];
  if (
    hub &&
    hub.site_id != undefined &&
    hub.site_id != null &&
    hub.site_id != 0 &&
    appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0
  ) {
    shared.push(
      <Button
        className={classes.save_button}
        variant="contained"
        onClick={handleSharedUpdate}
      >
        <span className={classes.bold_text}>MAKE HUB SHARED</span>
      </Button>,
    );
  }
  var restartbutton: React.ReactNode[] = [];
  if (appStore!.authService!.getRoles()!.indexOf("site_admin") >= 0) {
    restartbutton.push(
      <Button
        className={classes.save_button}
        variant="contained"
        onClick={handleRestart}
      >
        <span className={classes.bold_text}>Restart Hub</span>
      </Button>,
    );
  }
  var networktab: React.ReactNode[] = [];
  networktab.push(<Divider></Divider>);
  if (
    networkconfig &&
    networkconfig.network &&
    networkconfig.network.ethernets
  ) {
    for (let key in networkconfig.network.ethernets) {
      let ethernet: EthernetDetail = networkconfig.network.ethernets[key];
      if (ethernet) {
        if (key === "new") {
          networktab.push(
            <div className={classes.row_container}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <TextField
                  onChange={(e: React.ChangeEvent<any>) =>
                    handleEthernetNameChange(e)
                  }
                  label={"Ethernet Name "}
                  variant="outlined"
                  margin="normal"
                  value={newethernetname}
                  fullWidth
                  autoFocus
                  InputProps={{
                    className: classes.bold_text,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.bold_text,
                    },
                  }}
                />
              </ClickAwayListener>
              <IconButton
                edge="end"
                aria-label="add"
                onClick={() => {
                  removeEthernet(key);
                }}
              >
                <RemoveCircleIcon color={"primary"} />
              </IconButton>
            </div>,
          );
        } else {
          networktab.push(
            <div className={classes.row_container}>
              <Typography className={classes.bold_text_top_margin}>
                Ethernet: {key}
              </Typography>
              <IconButton
                edge="end"
                aria-label="add"
                onClick={() => {
                  removeEthernet(key);
                }}
              >
                <RemoveCircleIcon color={"primary"} />
              </IconButton>
            </div>,
          );
        }

        networktab.push(
          <FormControl
            component="fieldset"
            fullWidth
            variant="outlined"
            className={classes.marginleft25}
          >
            <RadioGroup
              value={ethernet.dhcp4 ? "true" : "false"}
              onChange={(e: React.ChangeEvent<any>) =>
                handleDHCPSelection(e, key)
              }
            >
              <FormControlLabel
                className={classes.bold_text}
                value={"true"}
                control={<Radio color="default" />}
                label={"DHCP"}
              />
              <FormControlLabel
                className={classes.bold_text}
                value={"false"}
                control={<Radio color="default" />}
                label={"Static IP"}
              />
            </RadioGroup>
          </FormControl>,
        );
        if (ethernet.dhcp4 === false) {
          if (ethernet.addresses && ethernet.addresses.length > 0) {
            ethernet.addresses.forEach((address, index) => {
              networktab.push(
                <div className={classes.row_container}>
                  <Tooltip
                    title="Add Netmask at the end of your IP address, eg. 10.001.002.003/16"
                    aria-label="View users assigned to this channel"
                    arrow
                  >
                    <TextField
                      onChange={(e: React.ChangeEvent<any>) =>
                        handleIPAddressChange(e, key, index)
                      }
                      label={"IP Address " + (index + 1) + " (CIDR notation)"}
                      variant="outlined"
                      margin="normal"
                      value={address}
                      fullWidth
                      autoFocus
                      InputProps={{
                        className: getIpValidity(
                          address,
                          key,
                          index,
                          "ipaddress",
                        )
                          ? classes.bold_text
                          : classes.bold_text_red,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.bold_text,
                        },
                      }}
                    />
                  </Tooltip>
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={() => {
                      removeIpAddress(key, index);
                    }}
                  >
                    <RemoveCircleIcon color={"primary"} />
                  </IconButton>
                </div>,
              );
              let idx = validips.findIndex(
                (ip) =>
                  ip.key === key &&
                  ip.index === index &&
                  ip.type === "ipaddress",
              );
              if (idx >= 0) {
                networktab.push(
                  <div className={classes.row_container}>
                    <Typography className={classes.bold_text}>
                      {"Netmask: " + validips[idx].netmask}
                    </Typography>
                  </div>,
                );
              }
            });
          }
          networktab.push(
            <div className={classes.bold_text}>
              Add Additional IP Address
              <IconButton
                edge="end"
                aria-label="add"
                onClick={() => {
                  addIpAddress(key);
                }}
              >
                <AddCircle color={"primary"} />
              </IconButton>
            </div>,
          );
          networktab.push(
            <TextField
              onChange={(e: React.ChangeEvent<any>) =>
                handleGatewayChange(e, key)
              }
              label="Gateway"
              variant="outlined"
              margin="normal"
              value={ethernet.gateway4}
              fullWidth
              InputProps={{
                className: getIpValidity(ethernet.gateway4, key, 0, "gateway")
                  ? classes.bold_text
                  : classes.bold_text_red,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />,
          );
          if (
            ethernet.nameservers &&
            ethernet.nameservers.addresses &&
            ethernet.nameservers.addresses.length > 0
          ) {
            ethernet.nameservers.addresses.forEach((address, index) => {
              networktab.push(
                <div className={classes.row_container}>
                  <TextField
                    onChange={(e: React.ChangeEvent<any>) =>
                      handleNameServerChange(e, key, index)
                    }
                    label={"Name Server " + (index + 1)}
                    variant="outlined"
                    margin="normal"
                    value={address}
                    defaultValue={"8.8.8.8"}
                    fullWidth
                    InputProps={{
                      className: getIpValidity(
                        address,
                        key,
                        index,
                        "nameserver",
                      )
                        ? classes.bold_text
                        : classes.bold_text_red,
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.bold_text,
                      },
                    }}
                  />
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={() => {
                      removeNameServer(key, index);
                    }}
                  >
                    <RemoveCircleIcon color={"primary"} />
                  </IconButton>
                </div>,
              );
            });
          }
          networktab.push(
            <div className={classes.bold_text}>
              Add Additional Nameserver
              <IconButton
                edge="end"
                aria-label="add"
                onClick={() => {
                  addNameServer(key);
                }}
              >
                <AddCircle color={"primary"} />
              </IconButton>
            </div>,
          );
        }
      }
      networktab.push(<Divider></Divider>);
    }
    networktab.push(
      <div className={classes.bold_text}>
        Add Ethernet Endpoint
        <IconButton
          edge="end"
          aria-label="add"
          onClick={() => {
            addEthernet();
          }}
        >
          <AddCircle color={"primary"} />
        </IconButton>
      </div>,
    );
  } else {
    networktab.push(
      <Typography className={classes.bold_text_red}>
        Could not get network config, please make sure hub is on and reachable!
      </Typography>,
    );
  }

  if (appStore && appStore.authService.getRoles().indexOf("site_admin") >= 0) {
    if (loading) {
      return (
        <Dialog
          onClose={handleClose}
          open={open}
          className={classes.modal_dialog_fixed}
        >
          <DialogTitle>
            <span className={classes.bold_text}>Editing {hub?.hub_id_str}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <CloseIcon className={classes.close_icon} onClick={handleClose} />
          </DialogTitle>
          <div className="wait-container">
            <svg width="48px" height="48px" viewBox="0 0 128 128">
              <g>
                <linearGradient id="linear-gradient">
                  <stop offset="0%" stop-color="#ffffff" />
                  <stop offset="100%" stop-color="#22305f" />
                </linearGradient>
                <path
                  d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                  fill="url(#linear-gradient)"
                  fill-rule="evenodd"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 64 64"
                  to="360 64 64"
                  dur="1080ms"
                  repeatCount="indefinite"
                ></animateTransform>
              </g>
            </svg>
          </div>
        </Dialog>
      );
    } else {
      return (
        <Dialog
          onClose={handleClose}
          open={open}
          fullWidth={true}
          maxWidth="sm"
          className={classes.modal_dialog_fixed}
        >
          <DialogTitle className={classes.bold_text}>
            Editing {hub?.hub_id_str}
          </DialogTitle>
          <Container>
            <TextField
              onChange={handleLocationChange}
              label="Location"
              variant="outlined"
              margin="normal"
              value={location}
              fullWidth
              autoFocus
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
            <Typography className={classes.bold_error_text}>
              {message}
            </Typography>
            {!isCameraMate && (
              <>
                <Typography className={classes.bold_text_top_margin}>
                  Network Settings:
                </Typography>
                {networktab}
              </>
            )}
            <div className={classes.button_container}>
              <Button
                className={classes.cancel_button}
                variant="contained"
                onClick={handleClose}
              >
                <span className={classes.bold_text}>CANCEL</span>
              </Button>
              {!isCameraMate && (
                <Button
                  className={classes.save_button}
                  variant="contained"
                  onClick={handleUpdate}
                >
                  <span className={classes.bold_text}>UPDATE</span>
                </Button>
              )}
              {shared}
              {!isCameraMate && restartbutton}
            </div>
          </Container>
        </Dialog>
      );
    }
  } else {
    return (
      <Dialog
        onClose={onClose}
        open={open}
        className={classes.modal_dialog_fixed}
      >
        <DialogTitle className={classes.bold_text}>
          Editing {hub?.hub_id_str}
        </DialogTitle>
        <br></br>
        <Typography className={classes.bold_text}>
          {
            "You need site admin role to be able to edit a Hub - please contact your DeepAlert system administrator"
          }
        </Typography>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
        </div>
      </Dialog>
    );
  }
};
export default EditHubModal;
