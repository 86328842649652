import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../../styling/global/global";
import { Typography } from "@material-ui/core";
import { AppStore } from "../../../../stores/AppStore";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import {
  RegisteredUser,
  RegisteredUsersResult,
} from "../../../../services/FunctionService";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
interface UpdateRegisteredUserProps {
  open: boolean;
  onClose: () => void;
  appStore?: AppStore;
  user: RegisteredUser | undefined;
}
const UpdateRegisteredUser: React.FC<UpdateRegisteredUserProps> = ({
  open,
  onClose,
  appStore,
  user,
}: UpdateRegisteredUserProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [resultStatus, setResultStatus] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [registrationDone, setRegistrationDone] = useState(false);
  const handleClose = () => {
    onClose();
  };
  const handleUpdateUser = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let add_user: RegisteredUsersResult =
        await appStore?.functionService.UpdateProcessedUser(
          token,
          !user?.processed,
          user!.id,
        );
      if (add_user.success) {
        setResultStatus(true);
        setResultMessage(add_user.msg);
        setRegistrationDone(true);
      } else {
        setResultStatus(true);
        setResultMessage(add_user.msg);
        setRegistrationDone(true);
      }
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
      fullWidth={true}
    >
      <DialogTitle
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        id="simple-dialog-title"
      >
        <span className={classes.bold_text}>Update {user?.email}</span>
      </DialogTitle>
      {loading ? (
        <LoadingSpinner />
      ) : registrationDone ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box mt={2} mb={2} justifyContent="center">
            <Alert
              variant="outlined"
              severity={
                resultMessage === ""
                  ? "error"
                  : resultMessage.includes("successful")
                    ? "success"
                    : "error"
              }
            >
              {resultMessage}
            </Alert>
          </Box>
          <Box mt={2} mb={2} justifyContent="center">
            <Button
              variant="contained"
              className={classes.menu_button}
              onClick={() => {
                onClose();
              }}
            >
              <span className={classes.bold_text}>Finish</span>
            </Button>
          </Box>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: "5%",
            paddingRight: "5%",
            marginBottom: "5%",
          }}
        >
          <Typography className={classes.bold_text}>
            Are you sure you want to mark {user?.email} as{" "}
            {user?.processed ? "unprocessed" : "processed"}?
          </Typography>
          <div
            style={{ marginTop: "5%", marginBottom: "5%" }}
            className={classes.twoButtonsInARow}
          >
            <Button
              variant="contained"
              style={{ marginRight: 5 }}
              className={classes.menu_button}
              onClick={() => {
                onClose();
              }}
            >
              <span className={classes.bold_text}>dismiss</span>
            </Button>

            <Button
              variant="contained"
              style={{ marginLeft: 5 }}
              className={classes.menu_button}
              onClick={() => handleUpdateUser()}
            >
              <span className={classes.bold_text}>update user</span>
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default UpdateRegisteredUser;
