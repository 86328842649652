import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useStyles } from "../../../../styling/global/global";
interface ProductSetupExpansionProps {
  list: boolean;
  add: boolean;
  view: boolean;
  edit: boolean;
  clone: boolean;
  handleClone: () => void;
  handleList: () => void;
  handleAdd: () => void;
  handleView: () => void;
  handleEdit: () => void;
}
const ProductSetupExpansion: React.FC<ProductSetupExpansionProps> = ({
  list,
  add,
  view,
  edit,
  clone,
  handleClone,
  handleList,
  handleAdd,
  handleView,
  handleEdit,
}: ProductSetupExpansionProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const List = () => {
    setAnchorEl(null);
    handleList();
  };
  const Add = () => {
    setAnchorEl(null);
    handleAdd();
  };

  const View = () => {
    setAnchorEl(null);
    handleView();
  };
  const Edit = () => {
    setAnchorEl(null);
    handleEdit();
  };

  const Clone = () => {
    setAnchorEl(null);
    handleClone();
  };

  var menuitems: React.ReactNode[] = [];

  if (view) {
    menuitems.push(
      <MenuItem onClick={View}>
        <span className={classes.bold_text}>view product info</span>
      </MenuItem>,
    );
  }
  if (list) {
    menuitems.push(
      <MenuItem onClick={List}>
        <span className={classes.bold_text}>
          list groups assigned to billing rate for new sites
        </span>
      </MenuItem>,
    );
  }
  if (add) {
    menuitems.push(
      <MenuItem onClick={Add}>
        <span className={classes.bold_text}>
          assign billing rate to group for new sites
        </span>
      </MenuItem>,
    );
  }

  if (edit) {
    menuitems.push(
      <MenuItem onClick={Edit}>
        <span className={classes.bold_text}>edit billing rate</span>
      </MenuItem>,
    );
  }
  if (clone) {
    menuitems.push(
      <MenuItem onClick={Clone}>
        <span className={classes.bold_text}>clone billing rate</span>
      </MenuItem>,
    );
  }

  return (
    <>
      <div>
        <IconButton
          style={{ marginLeft: "2%" }}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onMouseLeave={handleClose}
        >
          {menuitems}
        </Menu>
      </div>
    </>
  );
};
export default ProductSetupExpansion;
