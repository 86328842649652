import React, { useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import { useStyles } from "../../styling/global/global";
import { getBaseUrl } from "../../HelperFunctions";
import { AppStore } from "../../../stores/AppStore";
import Spinner from "../../../spinner/Spinner";

interface Props {
  site_id: number;
  is_unusual: boolean;
  unusual_activity_type: string;
  classifier: string;
  sensor_id_strs: string[];
  alert_description: string;
  result_reason: string;
  result_description: string;
  object_count: number;
  vehicle_count: number;
  person_count: number;
  alert_id: string;
  appStore: AppStore;
}

let img_url =
  "https://s3.eu-central-1.wasabisys.com/alert-images.staging.2022-3/to_view/TSTAAAB-q/20220331/TSTAAAB-q_20220331_094610.890362_EMLiyIdGgtoRnUgs_odsa.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=6OAFXDB2I19HWLV3PI1L%2F20220331%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220331T111755Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=ca8ea0337a520b22e9a05accacc2da6d170cd6c7bfeaefd53ed5eea3c51d1298";

const ViewDetails: React.FC<Props> = ({
  site_id,
  is_unusual,
  unusual_activity_type,
  classifier,
  sensor_id_strs,
  alert_description,
  result_reason,
  result_description,
  object_count,
  vehicle_count,
  person_count,
  alert_id,
  appStore,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState(false);
  const [imgUrl, setImgUrl] = React.useState("");

  const classes = useStyles();

  const fetchUrls = async () => {
    setLoading(true);
    let baseUrl = getBaseUrl();
    let token = await appStore?.authService.getAuthorisedToken();
    if (token && alert_id.length > 0) {
      try {
        let request = JSON.stringify({
          alert_ids: alert_id,
        });

        const res = await fetch(baseUrl + "/alerts", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        if (!res.ok) {
          setErr(true);
          // setMessage("Something went wrong, please try again");
        }

        const result = (await (await res).json()) as any;
        const data = result.msg[0];

        if (result.success) {
          let resp = await fetch(data.media_url);

          if (resp.ok) {
            setImgUrl(data.media_url);
          } else {
            setImgUrl(data.media_url_secondary);
          }

          setErr(false);
        }
      } catch (err) {
        console.debug(err);
      }
    } else {
      setImgUrl(
        "https://mapandan.gov.ph/wp-content/uploads/2018/03/no_image.jpg",
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUrls();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "contain",
          borderRadius: 5,
          overflow: "hidden",
          transition: "width 2s, height 4s",
        }}
      >
        {!loading && (
          <img
            src={imgUrl}
            alt=""
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              objectFit: "contain",
              borderRadius: 3,
              cursor: "pointer",
            }}
          />
        )}
        {loading && (
          <div
            className={classes.bold_text}
            style={{
              display: "flex",
              height: 350,
              width: 640,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        )}
      </div>
      <div style={{ marginTop: 15 }}>
        <Divider />
      </div>
      <div>
        <p className={classes.bold_text}>
          <span style={{ color: "grey", marginLeft: 10 }}>
            Description :&nbsp;&nbsp;
          </span>
          {result_description}
        </p>
        <p className={classes.bold_text}>
          <span style={{ color: "grey", marginLeft: 10 }}>
            Activity Type :&nbsp;&nbsp;
          </span>
          {unusual_activity_type.replace(/_/g, " ")}
        </p>
        <p className={classes.bold_text}>
          <span style={{ color: "grey", marginLeft: 10 }}>
            Classifier :&nbsp;&nbsp;
          </span>
          {classifier}
        </p>
        {object_count && (
          <p className={classes.bold_text}>
            <span style={{ color: "grey", marginLeft: 10 }}>
              Object Count :&nbsp;&nbsp;
            </span>
            {object_count}
          </p>
        )}
        {vehicle_count && (
          <p className={classes.bold_text}>
            <span style={{ color: "grey", marginLeft: 10 }}>
              Vehicle Count :&nbsp;&nbsp;
            </span>
            {vehicle_count}
          </p>
        )}
        {person_count && (
          <p className={classes.bold_text}>
            <span style={{ color: "grey", marginLeft: 10 }}>
              Person Count :&nbsp;&nbsp;
            </span>
            {person_count}
          </p>
        )}
        <p className={classes.bold_text}>
          <span style={{ color: "grey", marginLeft: 10 }}>
            Is the activity unusual ? &nbsp;&nbsp;
          </span>
          {is_unusual ? "yes" : "No"}
        </p>
        <p className={classes.bold_text}>
          <span style={{ color: "grey", marginLeft: 10 }}>
            Reason :&nbsp;&nbsp;
          </span>
          {result_reason}
        </p>
        <br />
      </div>
    </div>
  );
};

export default ViewDetails;
