import LuxonUtils from "@date-io/luxon";
import { Guid } from "guid-typescript";
export enum TimeEntryType {
  StartTime,
  EndTime,
}

export class TimeEntrySet {
  entries: TimeEntry[];
  setString?: string;

  constructor(setString?: string) {
    this.entries = [];
    if (setString && setString.startsWith("[") && setString.endsWith("]")) {
      this.setString = setString;
      this.resetEntries();
    }
  }
  private static TimeEntryRegex =
    /^"(?<days>(?:(?:Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s*)+)",\s*"(?:(?<startTime>\d{2}:\d{2}:\d{2})\s*-\s*(?<endTime>\d{2}:\d{2}:\d{2}))(?:,\s*(?<startTime2>\d{2}:\d{2}:\d{2})?\s*-\s*(?<endTime2>\d{2}:\d{2}:\d{2}))?"$/;
  private static EntryFullMatch = /^\[\((.+)\)\]/;

  resetEntries = () => {
    const entries: TimeEntry[] = [];
    if (this.setString && TimeEntrySet.EntryFullMatch.test(this.setString)) {
      const entryStrings = this.setString
        .replace("[(", "")
        .replace(")]", "")
        .split(/\),\s*\(/);
      for (let entry of entryStrings) {
        entry = entry.split(" ").join("");
        let date_time = entry.split('","');
        if (date_time && date_time.length == 2) {
          let times = date_time[1].replace("'", "").replace('"', "");
          let timearray = times.split(",");
          timearray.forEach((time) => {
            let thisentry = date_time[0] + '","' + time + '"';
            const entryMatch = TimeEntrySet.TimeEntryRegex.exec(thisentry);
            if (entryMatch && entryMatch.groups) {
              const days = entryMatch.groups.days.split(/,\s*/);
              entries.push(
                new TimeEntry(
                  days,
                  entryMatch.groups.startTime,
                  entryMatch.groups.endTime,
                ),
              );
            }
          });
        }
      }
    }
    this.entries = TimeEntry.Simplify(entries);
  };

  clearEntries = () => {
    this.entries = [];
  };

  updateString = () => {
    this.setString = `[${this.entries
      .map((entry) => entry.timeString)
      .join(",")}]`;
  };

  addTimeEntry = () => {
    this.entries.push(
      new TimeEntry(
        ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        "00:00:00",
        "24:00:00",
      ),
    );
  };

  deleteTimeEntry = (timeEntry: TimeEntry) => {
    const index = this.entries.indexOf(timeEntry);
    this.entries.splice(index, 1);
  };
}

export class TimeEntry {
  startTime: string;
  endTime: string;
  days: string[];
  key: Guid;

  private static TimeRegex =
    /^(?<hours>[0-1]?[0-9]|2[0-4]):(?<mins>[0-5][0-9]):(?<seconds>[0-5][0-9])$/;
  private static ShortenedTimeRegex =
    /^(?<hours>[0-1]?[0-9]|2[0-4]):(?<mins>[0-5][0-9])$/;
  private static DayRegex = /Mon|Tue|Wed|Thu|Fri|Sat|Sun/;

  private static ValidateTimeString(timeString: string) {
    if (!timeString) {
      return false;
    }
    return TimeEntry.TimeRegex.test(timeString);
  }

  private static ValidateTimePair(startTime: string, endTime: string) {
    var utils = new LuxonUtils();
    if (!startTime || !endTime) {
      return false;
    }
    if (
      !TimeEntry.ValidateTimeString(startTime) ||
      !TimeEntry.ValidateTimeString(endTime)
    ) {
      return false;
    }

    if (endTime === "00:00:00") {
      endTime = "24:00:00";
    }
    return utils.isAfter(endTime, startTime);
  }

  get valid() {
    // Check if there are valid days
    if (
      !this.days ||
      !this.days.length ||
      !this.days.every((d) => TimeEntry.DayRegex.test(d))
    ) {
      return false;
    }

    // Check required times
    return TimeEntry.ValidateTimePair(this.startTime, this.endTime);
  }

  get timeString() {
    let timeString = `${this.startTime}-${this.endTime}`;
    return `("${this.days.join(",")}", "${timeString}")`;
  }

  setDays = (days: string[]) => {
    this.days = days;
  };

  setTime = (timeString: string, timeType: TimeEntryType) => {
    if (!timeString) {
      return;
    }

    // Append seconds to shortened time strings
    if (TimeEntry.ShortenedTimeRegex.test(timeString)) {
      timeString += ":00";
    }

    // Ensure time matches correct format
    if (!TimeEntry.TimeRegex.test(timeString)) {
      return false;
    }

    switch (timeType) {
      case TimeEntryType.StartTime:
        this.startTime = timeString;
        return;
      case TimeEntryType.EndTime:
        this.endTime = timeString;
        return;
    }
  };

  // Simplifies time entries by combining those with the same start/end times
  public static Simplify(entries: TimeEntry[]): TimeEntry[] {
    const simplifiedEntries = new Array<TimeEntry>();

    for (const entry of entries) {
      simplifiedEntries.push(entry);
    }
    return simplifiedEntries;
  }

  constructor(days: string[], startTime: string, endTime: string) {
    this.startTime = startTime;
    this.endTime = endTime;
    this.days = days;
    this.key = Guid.create();
  }
}
