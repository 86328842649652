import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useStyles } from "../../styling/global/global";
import { Org, MoveSelect } from "../../../services/FunctionService";
import { AppStore } from "../../../stores/AppStore";
import { makeStyles } from "@material-ui/core/styles";

interface MoveModalProps {
  group: MoveSelect | undefined;
  hub: MoveSelect | undefined;
  site: MoveSelect | undefined;
  appStore?: AppStore;
  open: boolean;
  close: () => void;
  movingGroupOrg: Org | undefined;
  setSelectedItem: (item: any, editing: boolean) => void;
}

const MoveModal: React.FC<MoveModalProps> = ({
  open,
  close,
  group,
  setSelectedItem,
  site,
  hub,
  appStore,
  movingGroupOrg,
}: MoveModalProps) => {
  const [orgslist, setOrgsList] = React.useState<Org[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [group_id, setGroupId] = React.useState(0);
  const [result, setResult] = React.useState("");
  const classes = useStyles();
  var message = "";
  React.useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && open) {
        const orglist = await appStore?.functionService.getListOrgs(token);
        if (orglist) {
          if (orglist.success) {
            setOrgsList(orglist?.orgs);
            setLoading(false);
          } else {
            setOrgsList(orglist?.orgs);
            setLoading(false);
          }
        }
      }
    };
    fetchData();
  }, [open]);
  const handleSave = async () => {
    if (group && !site && !hub) {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        let movegroup = await appStore?.functionService.moveOrg(
          token,
          group.id,
          group_id,
        );
        if (movegroup) {
          if (movegroup.success) {
            setResult("Group successfully moved.");
          } else {
            setResult("Could not move group : " + movegroup.msg);
          }
        } else {
          setResult("Could not move group");
        }
        setLoading(false);
      }
    }
    if (!group && site && !hub) {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        let movesite = await appStore?.functionService.moveSite(
          token,
          site.id,
          group_id,
        );
        if (movesite) {
          if (movesite.success) {
            setResult("Site successfully moved.");
          } else {
            setResult("Could not move site : " + movesite.msg);
          }
        } else {
          setResult("Could not move site");
        }
        setLoading(false);
      }
    }
    if (!group && !site && hub) {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        let movehub = await appStore?.functionService.moveSharedHub(
          token,
          hub.id,
          group_id,
        );
        if (movehub) {
          if (movehub.success) {
            setResult("Hub successfully moved.");
          } else {
            setResult("Could not move hub : " + movehub.msg);
          }
        } else {
          setResult("Could not move hub");
        }
        setLoading(false);
      }
    }
  };
  const handleChangeGroup = (event: React.ChangeEvent<any>) => {
    let new_group_id = event.target.value;
    setGroupId(new_group_id);
  };
  const handleClose = () => {
    setResult("");
    close();
    appStore?.authService.getBreadCrumbs()?.map((org, index) => {
      org.type = "org";
      if (org.org_id === movingGroupOrg?.org_id) {
        setSelectedItem(org, false);
      }
    });
  };
  const scrollStyles = makeStyles({
    paper: {
      minWidth: "500px",
    },
  });
  if (group && !site && !hub) {
    message = "Move Group " + group.name;
  }
  if (!group && site && !hub) {
    message = "Move Site " + site.name;
  }
  if (!group && !site && hub) {
    message = "Move Hub " + hub.name;
  }
  const scrollclasses = scrollStyles();
  var otherorgs: React.ReactNode[] = [];
  if (orgslist && orgslist.length > 0) {
    orgslist.forEach((org) => {
      otherorgs.push(
        <MenuItem value={org.org_id}>
          <span className={classes.bold_text}>
            {org.org_name + " (Id = " + org.org_id + ")"}
          </span>
        </MenuItem>,
      );
    });
  }
  if (loading) {
    return (
      <Dialog classes={{ paper: scrollclasses.paper }} open={open}>
        <DialogTitle className={classes.bold_text}>{message}</DialogTitle>
        <div className="wait-container">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </Dialog>
    );
  } else if (result != "") {
    return (
      <Dialog classes={{ paper: scrollclasses.paper }} open={open}>
        <DialogTitle className={classes.bold_text}>{result}</DialogTitle>
        <div className={classes.button_container}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => handleClose()}
          >
            <span className={classes.bold_text}>OK</span>
          </Button>
        </div>
      </Dialog>
    );
  } else {
    return (
      <Dialog classes={{ paper: scrollclasses.paper }} open={open}>
        <DialogTitle className={classes.bold_text}>{message}</DialogTitle>

        <FormControl
          className={classes.form_component_medium}
          variant="outlined"
          fullWidth={false}
        >
          <InputLabel className={classes.bold_text}>
            Select Group to move to
          </InputLabel>
          <Select value={group_id} onChange={handleChangeGroup}>
            {otherorgs}
          </Select>
        </FormControl>
        <div className={classes.button_container}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => close()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
          <Button
            variant="contained"
            className={classes.save_button}
            onClick={() => handleSave()}
          >
            <span className={classes.bold_text}>MOVE</span>
          </Button>
        </div>
      </Dialog>
    );
  }
};

export default MoveModal;
