import React from "react";
import TimeEntryUISingle from "./TimeEntryUISingle";

import {
  TimeEntrySingle,
  TimeEntrySetSingle,
} from "../../../core/models/TimeEntrySingle";

interface TimeEntryPanelSingleProps {
  disabled: boolean;
  entrySet: TimeEntrySetSingle | undefined;
  addicon: boolean;
  changeTimeEntryParent: (entrySet: TimeEntrySetSingle) => void;
}

const TimeEntryPanelSingle: React.FC<TimeEntryPanelSingleProps> = ({
  disabled,
  entrySet,
  addicon,
  changeTimeEntryParent,
}: TimeEntryPanelSingleProps) => {
  const [timeentrySet, setTimeentrySet] = React.useState<
    TimeEntrySetSingle | undefined
  >(entrySet);
  const [refresh, setRefresh] = React.useState(0);
  const handleScheduleDeleteClicked = (timeEntry: TimeEntrySingle) => {
    if (timeentrySet) {
      timeentrySet.deleteTimeEntry(timeEntry);
      setTimeentrySet(timeentrySet);
      changeTimeEntryParent(timeentrySet);
    }

    setRefresh(new Date().getTime());
  };
  const handleAdd = () => {
    if (timeentrySet) {
      timeentrySet.addTimeEntry();
      setTimeentrySet(timeentrySet);
      changeTimeEntryParent(timeentrySet);
    }

    setRefresh(new Date().getTime());
  };
  const changeTimeEntry = (entry: TimeEntrySingle) => {
    const old_entry = timeentrySet?.entries.find((x) => x.key == entry.key);
    if (old_entry && timeentrySet) {
      timeentrySet!.entries[timeentrySet!.entries.indexOf(old_entry!)] = entry;
      setTimeentrySet(timeentrySet);
      changeTimeEntryParent(timeentrySet);
    }
  };
  var timeEntryContent: React.ReactNode[] = [];
  if (timeentrySet) {
    timeentrySet.entries.map((timeEntry, index) => {
      const addSchedule =
        index == timeentrySet.entries.length - 1 ? true : false;
      timeEntryContent.push(
        <TimeEntryUISingle
          addicon={addicon}
          timeEntry={timeEntry}
          key={index}
          disabled={disabled}
          addSchedule={addSchedule}
          timeEntries={timeentrySet}
          changeTimeEntry={(timeEntry: TimeEntrySingle) =>
            changeTimeEntry(timeEntry)
          }
          handleScheduleDeleteClicked={(timeEntry: TimeEntrySingle) =>
            handleScheduleDeleteClicked(timeEntry)
          }
          handleAdd={() => handleAdd()}
        ></TimeEntryUISingle>,
      );
    });
    return <>{timeEntryContent}</>;
  } else {
    return <></>;
  }
};

export default TimeEntryPanelSingle;
