import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../styling/global/global";
import CustomModal from "../../UI/CustomModal";
import Button from "@material-ui/core/Button";
import { AppStore } from "../../../stores/AppStore";
import { Site, Sensor } from "../../../services/FunctionService";
import { Spin } from "antd";

import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStylez = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export const useStyler = makeStyles((theme) => ({
  hov: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
      borderColor: "#ffff",
      cursor: "pointer",
    },
  },
}));

interface Props {
  openModal: boolean;
  // snapshots: any[];
  onContinue: (id?: any) => void;
  onClose: () => void;
  appStore?: AppStore;
  site?: Site;
  sensor: Sensor;
}

export const SnapshotsModal: React.FC<Props> = ({
  openModal,
  onContinue,
  onClose,
  // snapshots,
  appStore,
  sensor,
  site,
}) => {
  const classes = useStyles();
  const classer = useStyler();
  const [enableALPR, setEnableALPR] = useState(false);
  const [serverSnapShots, setServerSnapshots] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  const classez = useStylez();
  const [filtering, setFiltering] = useState(false);
  const [filteredStartTime, setFilteredStartTime] = useState(0);
  const [filteredEndTime, setFilteredEndTime] = useState(0);
  const [newEndTime, setNewEndTime] = useState(0);
  // const [maxAlertCount, setMaxAlertCount] = useState(5);
  const [maxCount, setMaxCount] = useState(10);

  const setEndTimeFilter = (e: any) => {
    var time: any = new Date(e);
    time = time.getTime() / 1000;
    setFilteredEndTime(e);
    setNewEndTime(time);
  };

  async function getSmtpDetails() {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let response = await appStore?.functionService.fetchServerSnapshots(
        token,
        sensor.sensor_id,
        newEndTime,
        maxCount,
      );
      if (response?.success) {
        const data = [...response?.reference_images];
        // const data = [...response?.inferences];
        setServerSnapshots(data);
        setLoading(false);
      }
      setFilteredEndTime(0);
      setNewEndTime(0);
      setLoading(false);
    }
  }

  useEffect(() => {
    // if (site?.data_origin === "alert_smtp_client") {
    getSmtpDetails();
    // }
  }, [site]);
  return (
    <div>
      <Grid container spacing={1}>
        <CustomModal
          closeHandler={onClose}
          open={openModal}
          onContinueHandler={onContinue}
          continueAction={enableALPR}
          continue
          title={"continue"}
          large
        >
          {filtering && (
            <div
              style={{
                marginLeft: 50,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 25,
                minWidth: 450,
              }}
            >
              {/* ######## TIME COMPONENT ####### */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 30,
                }}
              >
                <div style={{ marginLeft: 30 }}>
                  {/* <p>End Time</p> */}
                  <form className={classez.container} noValidate>
                    <TextField
                      id="datetime-local"
                      label="End Time"
                      type="datetime-local"
                      // defaultValue="2017-05-24T10:30"
                      value={filteredEndTime}
                      className={classez.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setEndTimeFilter(e.target.value);
                      }}
                    />
                  </form>
                </div>
                <div style={{ marginRight: 30 }}>
                  <form className={classez.container} noValidate>
                    <TextField
                      // id="datetime-local"
                      label="Max Count"
                      type="number"
                      value={maxCount}
                      className={classez.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ min: "1", step: "1" }}
                      onChange={(e) => {
                        setMaxCount(+e.target.value);
                      }}
                    />
                  </form>
                </div>
                {/* <div style={{ marginRight: 30 }}>
                  <form className={classez.container} noValidate>
                    <TextField
                      // id="datetime-local"
                      label="Max Alert Count"
                      type="number"
                      value={maxAlertCount}
                      className={classez.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ min: "1", step: "1" }}
                      onChange={(e) => {
                        setMaxAlertCount(+e.target.value);
                      }}
                    />
                  </form>
                </div> */}
              </div>

              {/* ######## TIME COMPONENT ####### */}
              <div style={{ marginTop: 20, padding: 15, display: "flex" }}>
                <Button
                  variant="contained"
                  className={`${classes.cancel_button2} {$classes.bold_text}`}
                  onClick={(e) => {
                    setFiltering(false);
                    setFilteredEndTime(0);
                    setNewEndTime(0);
                  }}
                >
                  Cancel&nbsp;
                </Button>
                <Button
                  variant="contained"
                  className={classes.mute_button}
                  onClick={() => {
                    getSmtpDetails();
                    setFiltering(false);
                    setLoading(true);
                    setServerSnapshots([]);
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
          {!filtering && (
            <div style={{ margin: "0 auto", width: 200 }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setFiltering(true);
                }}
                style={{
                  fontWeight: 500,
                  fontSize: 13,
                  borderRadius: 4,
                  height: 51,
                  marginBottom: 20,
                }}
                color="primary"
                component="span"
              >
                Time Filter
              </Button>
            </div>
          )}
          <div
            style={{
              padding: 5,
              display: "flex",
              flexWrap: "wrap",
              width: 1200,
              overflowY: "scroll",
              backgroundColor: "white",
              borderRadius: "25px",
              border: "0.5px solid rgba(21, 50, 95, 0.5)",
              justifyContent: "center",
            }}
          >
            {serverSnapShots.length > 0 ? (
              serverSnapShots.map((item) => (
                <div
                  style={{
                    alignItems: "center",
                    overflow: "hidden",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    borderRadius: 10,
                    paddingRight: 10,
                    backgroundColor: "rgba(246, 246, 246)",
                    margin: 10,
                    boxShadow: "0 2px 4px rgba(0, 0, 0,0.1)",
                    width: 500,
                    padding: 5,
                  }}
                >
                  <div
                    className={classer.hov}
                    style={{
                      backgroundColor: "#f6f6f6",
                      width: 640,
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                      padding: 9,
                      overflow: "hidden",
                    }}
                    onClick={() => {
                      console.debug("image clicked!");
                    }}
                  >
                    <img
                      src={
                        "data:image/png;base64," + item.standard_image.stringb64
                      }
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        objectFit: "fill",
                        width: 480,
                        // height: 480,
                        borderRadius: 3,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#f6f6f6",
                      alignItems: "center",
                      fontSize: 12,
                      color: "sky-blue",
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    Native resolution - width=
                    {item?.native_width} , height=
                    {item?.native_height}, colours=
                    {item?.native_depth}
                    <br />
                    {Math.round(item.native_size_bytes / 1000)} kB
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: 5,
                      padding: 5,
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div></div>
                    <Button
                      className={classes.bold_text}
                      onClick={() => {
                        onContinue(item);
                      }}
                      variant="outlined"
                    >
                      Save As Exclusion Zones
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <p
                className={classes.bold_text}
                style={{ marginTop: 20, height: 120 }}
              >
                {!loading && serverSnapShots.length === 0 ? (
                  "No images found."
                ) : (
                  <Spin
                    className={classes.bold_text}
                    tip="Loading..."
                    size="large"
                  >
                    <div
                      className={classes.bold_text}
                      style={{
                        padding: "50px",
                        background: "rgba(0, 0, 0, 0.05)",
                        borderRadius: 4,
                      }}
                    />
                  </Spin>
                )}
              </p>
            )}
          </div>
        </CustomModal>
      </Grid>
    </div>
  );
};
