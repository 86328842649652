import React, { useState } from "react";
import { Divider } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Spinner from "../../../../spinner/Spinner";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import IconButton from "@mui/material/IconButton";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { getBaseUrl } from "../../../HelperFunctions";
import { useStyles } from "../../../styling/global/global";
import { useEffect } from "react";
import MessageModal from "../../../modals/MessageModal/MessageModal";
import EditReportGroupModal from "../../../modals/AddReportGroupModal/EditReportGroupModal";
import AddReportGroupModal from "../../../modals/AddReportGroupModal/AddReportGroupModal";
import DeleteReportGroupModal from "./DeleteReportGroupModal";
import { ReportGroup } from "../../../../services/FunctionService";

const ReportGroups = (props: any) => {
  // const [loading, setLoading] = useState<boolean>(false);
  const [orgslist, setOrgsList] = React.useState<any>([]);
  const [reloadData, setReloadData] = useState<number>(
    Math.floor(Math.random() * 100) + 1,
  );
  const [loading, setLoading] = React.useState(true);
  const [groupid, setGroupId] = React.useState(-1);
  const [groupname, setGroupName] = React.useState<string>("");
  const [selectedOrgID, setSelectedOrgID] = useState<any>(-1);
  const [reportGroups, setReportGroups] = useState<any>([]);
  const [reportGroupsForTopOrg, setReportGroupsForTopOrg] = useState<any>([]);
  const [reportGroupsForWholeTree, setReportGroupsForWholeTree] = useState<any>(
    [],
  );

  // SORT BY NAME //
  const sortByName1 = () => {
    setReportGroupsForTopOrg((prev: any) => {
      const data = [...prev];
      const sortedData = data.sort((a: any, b: any) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return sortedData;
    });
  };

  const sortByName2 = () => {
    setReportGroupsForTopOrg((prev: any) => {
      const data = [...prev];
      const sortedData = data.sort((a: any, b: any) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      });
      return sortedData;
    });
  };

  // SORT BY ID //
  const sortByID1 = () => {
    setReportGroupsForTopOrg((prev: any) => {
      const data = [...prev];
      const sortedData = data.sort((a: any, b: any) => {
        const lengthA = a?.recipients?.length || 0;
        const lengthB = b?.recipients?.length || 0;

        if (lengthA < lengthB) {
          return -1;
        }
        if (lengthA > lengthB) {
          return 1;
        }
        return 0;
      });

      return sortedData;
    });
  };
  const sortByID2 = () => {
    setReportGroupsForTopOrg((prev: any) => {
      const data = [...prev];
      const sortedData = data.sort((a: any, b: any) => {
        const lengthA = a?.recipients?.length || 0;
        const lengthB = b?.recipients?.length || 0;

        if (lengthA > lengthB) {
          return -1;
        }
        if (lengthA < lengthB) {
          return 1;
        }
        return 0;
      });

      return sortedData;
    });
  };

  ////////////////////////////////////////////////////////////////////
  const [message, setMessage] = useState("");
  const [popmessage, setPopMessage] = useState("");
  const [reload, setReload] = useState(0);
  const [reportgroupmodalopen, setAddReportGroupModalOpen] =
    React.useState(false);
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);

  const [popelements, setPopElements] = React.useState<
    React.ReactNode[] | undefined
  >(undefined);

  const closeMessageModal = () => {
    setPopElements([]);
    setMessage("");
    setPopMessage("");
    setMessageModalOpen(false);
  };

  //ADD REPORT GROUP MODAL
  const closeAddReportGroupModal = () => {
    setMessage("");
    setAddReportGroupModalOpen(false);
  };

  const openAddReportGroupModal = () => {
    if (groupname === "") {
      return;
    } else if (groupname !== "") {
      setAddReportGroupModalOpen(true);
    }
  };

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };

  async function saveNewReportGroup(name: string, recipients: string[]) {
    closeAddReportGroupModal();
    const token = await props?.appStore?.authService.getAuthorisedToken();
    if (token) {
      let new_report_group =
        await props?.appStore?.functionService.createReportGroup(
          token,
          selectedOrgID,
          name,
          recipients,
        );
      if (new_report_group) {
        if (new_report_group.success) {
          setPopMessage("Created new Report Group " + name);
          openMessageModal();
          setReload(new Date().getTime());
        } else {
          setPopMessage(new_report_group.msg);
          openMessageModal();
          setReload(new Date().getTime());
        }
      }
    }
  }

  ///////////////////////////////////////////////////////////////////

  const [editreportgroupmodalopen, setEditReportGroupModalOpen] =
    useState(false);

  const [editingreportgroup, setEditingReportGroup] = useState<
    ReportGroup | undefined
  >(undefined);

  //Open EditReportGroup
  const openEditReportGroupModal = (reportgroup: ReportGroup) => {
    setEditingReportGroup(reportgroup);
    setEditReportGroupModalOpen(true);
  };

  const closeEditReportGroupModal = () => {
    setMessage("");
    setEditReportGroupModalOpen(false);
  };

  async function updateReportGroup(
    report_group_id: number,
    name: string,
    recipients: string[],
  ) {
    setEditReportGroupModalOpen(false);
    const token = await props?.appStore?.authService.getAuthorisedToken();
    if (token) {
      let update_report =
        await props?.appStore?.functionService.modifyReportGroup(
          token,
          report_group_id,
          name,
          recipients,
        );
      if (update_report) {
        if (update_report.success) {
          setPopMessage("Updated report group");
          openMessageModal();
          setReload(new Date().getTime());
        } else {
          setPopMessage(update_report.msg);
          openMessageModal();
        }
      }
    }
  }

  ///////////////////////////////////////////////////////////////////

  const [reportGroupNames, setReportGroupNames] = React.useState<
    string[] | any
  >([]);

  const [groupTreeName, setGroupTreeName] = React.useState("");

  //Tabsmenu
  const handleChangeAuto = (value: any) => {
    if (!value) {
      return setGroupId(-1);
    }
    setReportGroupNames([]);
    const new_group_id = +value.org_id;
    console.debug("Group ID", new_group_id);
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      let tree = group.org_tree_names!.map((item: any) => `   /   ${item} `);
      let treeString = tree.toString();
      const rpl = treeString.replace(/,/g, "");
      setGroupTreeName(rpl);
      setGroupName(group.org_name);
    }
    setSelectedOrgID(value.org_id);
  };

  // FETCH THE REPORT GROUPS RELATED TO THE SUB-GROUP
  useEffect(() => {
    const fetchData = async () => {
      const token = await props?.appStore?.authService.getAuthorisedToken();
      if (token && selectedOrgID !== -1) {
        const reportgroups =
          await props?.appStore?.functionService.getReportGroups(
            token,
            selectedOrgID,
          );
        if (reportgroups) {
          setReportGroups(reportgroups);
        }
        const reportgroupstoporg =
          await props?.appStore?.functionService.getReportGroupsForTopOrg(
            token,
            selectedOrgID,
          );
        if (reportgroupstoporg) {
          setReportGroupsForTopOrg(reportgroupstoporg);
        }
        if (selectedOrgID) {
          const reportgroupsforwholetree =
            await props?.appStore?.functionService.getReportGroupsFromCurrentLevelUp(
              token,
              selectedOrgID,
            );
          if (reportgroupsforwholetree) {
            setReportGroupsForWholeTree(reportgroupsforwholetree);
          }
        }
      }
    };
    fetchData();
  }, [selectedOrgID, reloadData]);

  const classes = useStyles();

  const fetchOrgsData = async () => {
    setLoading(true);
    var token = await props.appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          enabled: true,
          paginate: false,
          verbose: false,
          incl_tree_names: true,
        });

        const res = fetch(baseUrl + "/list_orgs", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        const orglist = result;
        if (orglist && orglist.success) {
          let orgz = orglist?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }
    }
  };

  useEffect(() => {
    fetchOrgsData();
  }, [groupid, reloadData]);

  /////////////////////// DELETE REPORT GROUP /////////////////////////
  const [openCloseModal, setOpenCloseModal] = useState<boolean>(false);
  const [group_name, setReportGroupName] = useState<string>("");
  const [report_id, setReportID] = useState<number>(-1);

  const deleteReportGroupHandler = (name: string, id: number) => {
    setOpenCloseModal(true);
    setReportGroupName(name);
    setReportID(id);
  };

  return (
    <div
      className="container"
      style={{
        position: "relative",
      }}
    >
      {!loading && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            position: "absolute",
            top: 130,
            left: 700,
          }}
        >
          <>
            <Tooltip
              title="Select your group first to add report"
              aria-label="add"
            >
              <Button
                variant="contained"
                className={classes.mute_button}
                style={{
                  width: "auto",
                  position: "relative",
                }}
                onClick={openAddReportGroupModal}
              >
                Add Report Group
              </Button>
            </Tooltip>
          </>
        </div>
      )}

      <h3
        className={classes.bold_text}
        style={{
          textAlign: "center",
          fontSize: 15,
          textTransform: "capitalize",
          marginBottom: 20,
        }}
      >
        Add/Edit Report Groups For Sub-Groups
      </h3>
      <Divider style={{ marginBottom: 20 }} />
      {loading && (
        <div
          style={{
            transform: "translate(500px,30px)",
            position: "relative",
          }}
        >
          <Spinner />
        </div>
      )}

      {!loading && (
        <div
          style={{
            marginTop: 10,
          }}
        >
          <div>
            <FormControl
              className={classes.form_component_medium}
              variant="outlined"
              fullWidth={true}
            >
              <div style={{ marginBottom: 3 }}>
                <h5 className={classes.bold_text}>
                  Select Group to Filter Report Groups By Group: <br />{" "}
                </h5>
              </div>
              <Autocomplete
                id="combo-box-demo"
                className={classes.form_component_medium}
                options={orgslist}
                onChange={(event: React.ChangeEvent<any>, value: any) => {
                  handleChangeAuto(value);
                }}
                getOptionLabel={(option) => {
                  let tree = option.org_tree_names!.map(
                    (item: any) => `   /   ${item} `,
                  );
                  let treeString = tree.toString();
                  const rpl = treeString.replace(/,/g, "");
                  return `${rpl}`;
                }}
                style={{ width: 600, color: "#6D809D" }}
                // classes={{ paper: styler.paper }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span
                        className={classes.bold_text}
                        style={{ color: "#15325F" }}
                      >
                        {groupname ? groupname : "Please Select Group"}
                      </span>
                    }
                    variant="outlined"
                    value={groupid}
                    className={classes.bold_text}
                  />
                )}
              />
            </FormControl>
            <div style={{ marginLeft: 20 }}>
              <h2 className={classes.bold_text}>Report Groups:</h2>
            </div>
          </div>
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              borderRadius: 15,
              minHeight: "fit-content",
              maxHeight: 450,
              overflowY: "scroll",
              backgroundColor: "white",
              marginLeft: 40,
              marginRight: 20,
              marginBottom: 35,
              width: 1200,
            }}
          >
            <TableContainer component={Paper} style={{ maxHeight: 350 }}>
              <Table size="small" stickyHeader>
                <TableHead style={{ zIndex: 100 }}>
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 13,
                            color: "#616161",
                            fontWeight: "bold",
                          }}
                        >
                          Report Group
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: 10,
                            marginLeft: 8,
                            cursor: "pointer",
                          }}
                        >
                          <span>
                            <IconButton onClick={sortByName1}>
                              <ArrowDropUpIcon />
                            </IconButton>
                            {/* <ArrowDropUpIcon onClick={sortByName} /> */}
                          </span>
                          <span>
                            <IconButton onClick={sortByName2}>
                              <ArrowDropDownIcon />
                            </IconButton>
                            {/* <ArrowDropDownIcon onClick={sortByName} /> */}
                          </span>
                        </span>
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 13,
                            color: "#616161",
                            fontWeight: "bold",
                          }}
                        >
                          Group
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: 10,
                            marginLeft: 8,
                            cursor: "pointer",
                          }}
                        >
                          <span>
                            <ArrowDropUpIcon />
                          </span>
                          <span>
                            <ArrowDropDownIcon />
                          </span>
                        </span>
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 13,
                            color: "#616161",
                            fontWeight: "bold",
                          }}
                        >
                          Recipients
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: 10,
                            marginLeft: 8,
                            cursor: "pointer",
                          }}
                        >
                          <span>
                            <IconButton onClick={sortByID1}>
                              <ArrowDropUpIcon />
                            </IconButton>
                          </span>
                          <span>
                            <IconButton onClick={sortByID2}>
                              <ArrowDropDownIcon />
                            </IconButton>
                          </span>
                        </span>
                      </span>
                    </TableCell>
                    {/* )} */}

                    <TableCell>
                      <span
                        style={{
                          fontSize: 13,
                          color: "#616161",
                          fontWeight: "bold",
                          marginLeft: 30,
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        style={{
                          fontSize: 13,
                          color: "#616161",
                          marginLeft: 2,
                          fontWeight: "bold",
                        }}
                      >
                        Delete
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ overflowY: "scroll", zIndex: -10 }}>
                  {reportGroupsForTopOrg?.length > 0
                    ? reportGroupsForTopOrg.map(
                        (report_group: any, index: any) => (
                          // RETURN JSX HERE
                          <TableRow key={index}>
                            <TableCell
                              style={{
                                //   textAlign: "center",
                                paddingLeft: 20,
                                fontWeight: "bold",
                                fontSize: 15,
                              }}
                            >
                              <Typography className={classes.bold_text}>
                                {report_group?.name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                //   textAlign: "center",
                                paddingLeft: 20,
                                fontWeight: "bold",
                                marginLeft: "70px",

                                fontSize: 15,
                              }}
                            >
                              <Typography className={classes.bold_text}>
                                {report_group?.org_name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                //   textAlign: "center",
                                paddingLeft: 50,
                                fontWeight: "bold",
                                fontSize: 15,
                              }}
                            >
                              <Typography className={classes.bold_text}>
                                {report_group?.recipients?.length}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                //   textAlign: "center",
                                paddingLeft: 50,
                                fontWeight: "bold",
                                fontSize: 15,
                              }}
                            >
                              <Typography className={classes.bold_text}>
                                <IconButton
                                  onClick={() => {
                                    openEditReportGroupModal(report_group);
                                  }}
                                >
                                  <EditIcon style={{ color: "#15325F" }} />
                                </IconButton>
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                //   textAlign: "center",
                                paddingLeft: 30,
                                fontWeight: "bold",
                                fontSize: 10,
                              }}
                            >
                              <Typography
                                className={classes.bold_text}
                                style={{ zIndex: -100 }}
                              >
                                <IconButton
                                  onClick={() => {
                                    deleteReportGroupHandler(
                                      report_group?.name,
                                      report_group?.id,
                                    );
                                  }}
                                >
                                  <DeleteIcon style={{ color: "#15325F" }} />
                                </IconButton>
                              </Typography>
                            </TableCell>
                            {/* Add more cells as needed */}
                          </TableRow>
                        ),
                      )
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="add-report_group_modal">
            <AddReportGroupModal
              appStore={props?.appStore}
              saveNewReportGroup={(name: string, recipients: string[]) =>
                saveNewReportGroup(name, recipients)
              }
              open={reportgroupmodalopen}
              currentOrgName={groupname}
              message={message}
              onClose={closeAddReportGroupModal}
            ></AddReportGroupModal>
            <EditReportGroupModal
              appStore={props?.appStore}
              reportgroup={editingreportgroup!}
              updateReportGroup={(report_group_id, name, recipients) =>
                updateReportGroup(report_group_id, name, recipients)
              }
              open={editreportgroupmodalopen}
              message={""}
              onClose={closeEditReportGroupModal}
            />
            <MessageModal
              open={messagemodalopen}
              onClose={closeMessageModal}
              spinner={setReloadData}
              message={popmessage}
              elements={popelements}
            />
            <DeleteReportGroupModal
              appStore={props?.appStore}
              groupName={group_name}
              reloadData={setReloadData}
              selectedOrgID={report_id}
              openCloseModal={openCloseModal}
              handleClose={setOpenCloseModal}
            ></DeleteReportGroupModal>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportGroups;
