import React, { useState, useEffect, useRef } from "react";
import { AppStore } from "../../../stores/AppStore";
import {
  Site,
  MainMenu,
  Org,
  Sensor,
  Hub,
  Classifier,
  CanvasImage,
  SensorHealth,
} from "../../../services/FunctionService";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { useStyles } from "../../styling/global/global";
import Button from "@material-ui/core/Button";
import MessageModal from "../../modals/MessageModal/MessageModal";
import Typography from "@material-ui/core/Typography";
import useRightDrawer from "../useRightDrawer";

interface CameraSimpleMainDrawerProps {
  sensorhealth?: SensorHealth;
  interval: number;
  selectedItem: { [key: string]: any };
  mainMenu: MainMenu;
  showFullModalHandler: (params: (number | string)[]) => void;
  handleIntervalChange: (x: number) => void;
  currentOrg: Org;
  appStore?: AppStore;
  sensor: Sensor;
  site?: Site;
  setSelectedItem: (x: any) => void;
}

class initCanvasImage implements CanvasImage {
  width!: number;
  height!: number;
  stringb64!: string;
}

const CameraSimpleMainDrawer: React.FC<CameraSimpleMainDrawerProps> = ({
  sensorhealth,
  mainMenu,
  showFullModalHandler,
  selectedItem,
  interval,
  currentOrg,
  handleIntervalChange,
  appStore,
  sensor,
  site,
  setSelectedItem,
}: CameraSimpleMainDrawerProps) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = useState(sensor.human_name);
  const [enabled, setEnabled] = useState(sensor.enabled);
  const [classifier, setClassifier] = useState<Classifier | undefined>(
    undefined,
  );
  const [popmessage, setPopMessage] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [message, setMessage] = React.useState<string>("");

  //Hook
  const { activity, sensorhealthtimeline, healthtimeline, healthsites } =
    useRightDrawer(appStore, currentOrg, interval, selectedItem);

  //MessageModal
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);

  const currentEnv = useRef<string>("None");
  const [displayEnv, setDisplayEnv] = useState("None");
  const environment_list = [
    "None",
    "Commercial - exterior",
    "Commercial - interior",
    "Commercial - perimeter",
    "Farm",
    "High site / tower",
    "Parking area",
    "Public space - pedestrian",
    "Rapid deployment tower",
    "Residential - exterior",
    "Residential - interior",
    "Residential - perimeter",
    "Rural",
    "Shopping mall - interior",
    "Other",
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (sensor && sensor.environment && sensor.environment !== "") {
        currentEnv.current = sensor.environment;
      }
      sanityCheck();
    };
    fetchData();
  }, [site, sensor]);

  const handleEnvironmentChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    var new_env = event.target.value as string;
    if (new_env === "Other") {
      var own_environment = prompt("Please enter a custom environment");
      new_env += " -> " + own_environment;
    }
    currentEnv.current = new_env;

    sanityCheck();
  };

  const sanityCheck = () => {
    if (currentEnv?.current?.includes("Other")) {
      setDisplayEnv("Other");
    } else if (currentEnv?.current !== "") {
      setDisplayEnv(currentEnv.current);
    } else {
      setDisplayEnv("None");
    }
  };

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };
  const closeMessageModal = () => {
    setMessageModalOpen(false);
  };
  async function updateCamera() {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let update_camera = await appStore?.functionService.modifySimpleSensor(
        token,
        sensor.sensor_id,
        name,
        enabled,
        currentEnv.current,
      );
      setLoading(false);
      if (update_camera) {
        if (update_camera.success) {
          setPopMessage("Updated Camera " + name + " successfully");
          openMessageModal();
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(update_camera.msg);
          openMessageModal();
        }
      }
    }
  }
  const handleEnabledChange = (event: React.ChangeEvent<any>) => {
    setEnabled(!enabled);
  };
  const handleNameChange = (event: React.ChangeEvent<any>) => {
    setName(event.target.value);
  };

  const dummy = () => {};

  if (!loading) {
    return (
      <>
        <div className="main-drawer-container">
          <div className={classes.location_header}>
            <div className={classes.expansion_area}>
              <div className={classes.shelf_container}>
                <div
                  className={classes.grab_icon}
                  onClick={() => {
                    setSelectedItem(site);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
                      fill="#15325F"
                      fill-opacity="0.87"
                    ></path>
                  </svg>
                </div>
                <div className={classes.title_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                      fill="#15325F"
                      fill-opacity="0.87"
                    ></path>
                  </svg>
                </div>
                <div className={classes.header_text}>
                  {sensor.sensor_id_str + " " + name}
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <Typography className={classes.bold_error_text}>{message}</Typography>

          <TextField
            fullWidth
            label="Camera Name"
            onChange={handleNameChange}
            value={name}
            variant="outlined"
            margin="normal"
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
          <br></br>
          <FormControl fullWidth={true} variant="outlined">
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.bold_text}
            >
              Select Environment
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={displayEnv}
              onChange={handleEnvironmentChange}
              label="Environment"
              // style={{ width: 300 }}
            >
              {environment_list.map((option: string) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <br></br>
          <div className={classes.button_container}>
            <Button
              variant="contained"
              className={classes.bold_text}
              onClick={updateCamera}
            >
              Update
            </Button>
          </div>
          <MessageModal
            open={messagemodalopen}
            onClose={closeMessageModal}
            message={popmessage}
            elements={undefined}
          />
        </div>
        {/* <Drawer> </Drawer>*/}
      </>
    );
  } else {
    return (
      <Container maxWidth="md">
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </Container>
    );
  }
};
export default CameraSimpleMainDrawer;
