import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { MouseEvent, useEffect, useState } from "react";
import { MainMenu, Org } from "../../../services/FunctionService";
import { AppStore } from "../../../stores/AppStore";
import { MainContext } from "../../containers/HomePage/HomePage";

import { useStyles } from "../../styling/global/global";
import SearchIcon from "@material-ui/icons/Search";

// END OF Full Screen Modal
interface MockEvent {
  (event: Event): any;
}

interface TopNavBarProps {
  appStore?: AppStore;
  currentOrg?: Org;
  authhandler: MockEvent;
  changeOrg: (d: any) => void;
  changeMainMenu: (d: MainMenu) => void;
  mainMenu: MainMenu;
  openSearch: () => void;
  searchState: boolean;
  openAjax: () => void;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TopNavBar: React.FC<TopNavBarProps> = ({
  appStore,
  currentOrg,
  authhandler,
  changeOrg,
  changeMainMenu,
  mainMenu,
  openSearch,
  searchState,
  openAjax,
}: TopNavBarProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = useState(1);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const { setShowGraph } = React.useContext(MainContext);

  useEffect(() => {
    if (!searchState) {
      handleMenuChange("dummy", "navigator");
    }
  }, [searchState]);
  const handleClick = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent<any>) => {
    var x = event.currentTarget;
    setAnchorEl(null);
    //When the menu close and 'logout' was selected, sign us out in the authservice and execute the authhandler to take us to the login page
    if (x.id == "logout") {
      appStore?.authService.signOut();
      var ev: Event;
      ev = new Event("authupdate");
      authhandler(ev);
    }
  };

  //when selecting a new entry org, excecute the event handler to display the org select screen
  const handleLoginOrg = async (event: MouseEvent<any>) => {
    if (
      appStore?.authService.isCurrentlyAuthorised() &&
      appStore?.authService.getOrgs() != undefined &&
      (appStore?.authService.getOrgs()).length > 1
    ) {
      var ev: Event;
      ev = new Event("entrychanged");
      authhandler(ev);
    }
  };
  const handleProfile = () => {
    mainMenu.dashboard = false;
    mainMenu.insights = false;
    mainMenu.navigator = false;
    mainMenu.reports = false;
    mainMenu.usereditor = false;
    mainMenu.sites = false;
    mainMenu.rulesets = false;
    mainMenu.billing = false;
    mainMenu.site_reports = false;
    mainMenu.profile = true;
    mainMenu.tests = false;
    mainMenu.search = false;
    changeMainMenu(mainMenu);
  };
  const handleMenuChange = (event: MouseEvent<any> | string, data: string) => {
    mainMenu.dashboard = false;
    mainMenu.navigator = false;
    mainMenu.reports = false;
    mainMenu.usereditor = false;
    mainMenu.sites = false;
    mainMenu.profile = false;
    mainMenu.rulesets = false;
    mainMenu.billing = false;
    mainMenu.site_reports = false;
    mainMenu.tests = false;
    mainMenu.search = false;
    mainMenu.review = false;
    mainMenu.review3 = false;
    mainMenu.alpr = false;
    mainMenu.bi = false;

    setShowGraph(true);

    if (data == "dashboard") {
      mainMenu.dashboard = true;
    }
    if (data == "insights") {
      mainMenu.insights = true;
    }
    if (data == "navigator") {
      mainMenu.navigator = true;
    }
    if (data == "users") {
      mainMenu.usereditor = true;
    }
    if (data == "reports") {
      mainMenu.reports = true;
    }
    if (data == "sites") {
      mainMenu.sites = true;
    }
    if (data == "rulesets") {
      mainMenu.rulesets = true;
    }
    if (data == "billing") {
      mainMenu.billing = true;
    }
    if (data == "site_reports") {
      mainMenu.site_reports = true;
    }
    if (data == "tests") {
      mainMenu.tests = true;
    }
    if (data === "search") {
      mainMenu.search = true;
    }
    if (data === "review") {
      mainMenu.review = true;
      setShowGraph(false);
    }
    if (data === "review3") {
      mainMenu.review3 = true;
      setShowGraph(false);
    }
    if (data === "alpr") {
      mainMenu.alpr = true;
      setShowGraph(false);
    }

    if (data === "bi") {
      mainMenu.bi = true;
      setShowGraph(false);
    }
    changeMainMenu(mainMenu);
  };
  //get initials from name in JWT to create avatar display
  const av = appStore?.authService.getAvatar();

  //create dropdown list for new entry org selection
  var changeorg: React.ReactNode[] = [];
  var entryOrgName;
  if (
    appStore?.authService.isCurrentlyAuthorised() &&
    appStore?.authService.getOrgs() != undefined &&
    (appStore?.authService.getOrgs()).length > 1
  ) {
    entryOrgName = appStore?.authService.getEntryOrg()?.name;
    if (
      appStore?.authService.getOrgs() &&
      (appStore?.authService.getOrgs()).length > 0
    ) {
      changeorg.push(
        <ul className="nav-list">
          <li>
            <a
              href="#!"
              onClick={handleLoginOrg}
              className={classes.bold_text_white}
            >
              Profile : {entryOrgName}
            </a>
          </li>
        </ul>,
      );
    }
  }
  //
  let tests: React.ReactNode[] = [];
  if (
    appStore!.authService!.getRoles() &&
    appStore!.authService!.getRoles()!.indexOf("site_admin") >= 0
  ) {
    tests.push(
      <li>
        <a
          href="#"
          onClick={(e) => handleMenuChange(e, "tests")}
          className={mainMenu.tests ? "active" : ""}
        >
          <span className={classes.bold_text_white}>Resources</span>
        </a>
      </li>,
    );
  }

  let search: React.ReactNode[] = [];
  search.push(
    <li>
      <a
        href="#"
        onClick={(e) => handleMenuChange(e, "search")}
        className={mainMenu.search ? "active" : ""}
      >
        <span className={classes.bold_text_white}>Search</span>
      </a>
    </li>,
  );

  //create top menu items based on the logged-in user roles in the JWT
  var usermanage: React.ReactNode[] = [];
  if (
    appStore!.authService!.getRoles() &&
    appStore!.authService!.getRoles()!.indexOf("user_admin") >= 0
  ) {
    usermanage.push(
      <li>
        <a
          href="#"
          onClick={(e) => handleMenuChange(e, "users")}
          className={mainMenu.usereditor ? "active" : ""}
        >
          <span className={classes.bold_text_white}>Users</span>
        </a>
      </li>,
    );
  }
  var reports_config: React.ReactNode[] = [];
  if (
    appStore!.authService!.getRoles() &&
    appStore!.authService!.getRoles()!.indexOf("site_admin") >= 0
  ) {
    reports_config.push(
      <li>
        <a
          href="#"
          onClick={(e) => handleMenuChange(e, "site_reports")}
          className={mainMenu.site_reports ? "active" : ""}
        >
          <span className={classes.bold_text_white}>Reports</span>
        </a>
      </li>,
    );
  }
  var reviewpage_config: React.ReactNode[] = [];
  var reviewpage3_config: React.ReactNode[] = [];
  if (
    appStore!.authService!.getRoles() &&
    appStore!.authService!.getRoles()!.indexOf("site_admin") >= 0
  ) {
    reviewpage_config.push(
      <li>
        <a
          href="#"
          onClick={(e) => handleMenuChange(e, "review3")}
          className={mainMenu.review3 ? "active" : ""}
        >
          <span className={classes.bold_text_white}>Review</span>
        </a>
      </li>,
    );
  }

  var alpr_config: React.ReactNode[] = [];
  if (
    appStore!.authService!.getRoles() &&
    appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0
  ) {
    alpr_config.push(
      <li>
        <a
          href="#"
          onClick={(e) => handleMenuChange(e, "alpr")}
          className={mainMenu.alpr ? "active" : ""}
        >
          <span className={classes.bold_text_white}>ALPR</span>
        </a>
      </li>,
    );
  }

  var BI_config: React.ReactNode[] = [];
  if (
    appStore!.authService!.getRoles() &&
    // appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0
    appStore!.authService!.getRoles()!.indexOf("business_intelligence") >= 0
  ) {
    BI_config.push(
      <li>
        <a
          href="#"
          onClick={(e) => handleMenuChange(e, "bi")}
          className={mainMenu.bi ? "active" : ""}
        >
          <span className={classes.bold_text_white}>BI</span>
        </a>
      </li>,
    );
  }

  var userbilling: React.ReactNode[] = [];
  if (
    appStore!.authService!.getRoles() &&
    appStore!.authService!.getRoles()!.indexOf("billing_sys_admin") >= 0
  ) {
    userbilling.push(
      <li>
        <a
          href="#"
          onClick={(e) => handleMenuChange(e, "billing")}
          className={mainMenu.billing ? "active" : ""}
        >
          <span className={classes.bold_text_white}>System</span>
        </a>
      </li>,
    );
  }
  var sitesmanage: React.ReactNode[] = [];
  if (
    appStore!.authService!.getRoles() &&
    appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0
  ) {
    sitesmanage.push(
      <li>
        <a
          href="#"
          onClick={(e) => handleMenuChange(e, "sites")}
          className={mainMenu.sites ? "active" : ""}
        >
          <span className={classes.bold_text_white}>Sites</span>
        </a>
      </li>,
    );
  }

  var sitesmanage: React.ReactNode[] = [];
  if (
    appStore!.authService!.getRoles() &&
    appStore!.authService!.getRoles()!.indexOf("site_admin") >= 0
  ) {
    sitesmanage.push(
      <li>
        <a
          href="#"
          onClick={(e) => handleMenuChange(e, "rulesets")}
          className={mainMenu.rulesets ? "active" : ""}
        >
          <span className={classes.bold_text_white}>Alerting</span>
        </a>
      </li>,
    );
  }
  var insightsmanage: React.ReactNode[] = [];
  if (
    appStore!.authService!.getRoles() &&
    appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0
  ) {
    insightsmanage.push(
      <li>
        <a
          href="#"
          onClick={(e) => handleMenuChange(e, "insights")}
          className={mainMenu.insights ? "active" : ""}
        >
          <span className={classes.bold_text_white}>Insights</span>
        </a>
      </li>,
    );
  }
  const tabPanelItems = [
    {
      name: "Ajax",
    },
    {
      name: "Dashboard",
    },
    {
      name: "Navigator",
    },
    {
      name: "Alerting",
    },
    {
      name: "Users",
    },
    {
      name: "System",
    },
    {
      name: "Reports",
    },
    {
      name: "Review",
    },
    {
      name: "ReviewV3",
    },
    {
      name: "Resources",
    },
  ];

  return (
    <>
      {/* <AppBar position="static" className="navcolor">
        <Tabs
          indicatorColor="secondary"
          textColor="inherit"
          value={value}
          onChange={handleChange}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          centered
        >
          {tabPanelItems.map((item, index) => {
            return (
              <Tab
                label={item.name}
                {...a11yProps(index)}
                style={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
              >
                <span
                  className={classes.bold_text_white}
                  style={{ textTransform: "capitalize" }}
                >
                  {item.name}
                </span>
              </Tab>
            );
          })}
        </Tabs>
      </AppBar> */}
      <section
        className="navigation"
        style={{
          zIndex: 100,
        }}
      >
        <div className="nav-container">
          <div className="brand">
            <a href="#!">
              <img className="logo" src="/assets/img/logo.png" alt="" />
            </a>
          </div>

          <nav>
            <div className="nav-mobile">
              <a id="nav-toggle" href="#!">
                <span></span>
              </a>
            </div>
            <ul className="nav-list">
              {appStore!.authService!.getRoles() &&
              (appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0 ||
                appStore!.authService!.getRoles()!.indexOf("support") >= 0) ? (
                <li>
                  <a href="#" onClick={openAjax}>
                    <span className={classes.bold_text_white}>Ajax</span>
                  </a>
                </li>
              ) : null}

              {appStore!.authService!.getRoles() &&
              appStore!.authService!.getRoles()!.indexOf("site_admin") >= 0 ? (
                <li>
                  <a
                    href="#"
                    onClick={(e) => handleMenuChange(e, "dashboard")}
                    className={mainMenu.dashboard ? "active" : ""}
                  >
                    <span className={classes.bold_text_white}>Dashboard</span>
                  </a>
                </li>
              ) : null}

              <li>
                <a
                  href="#"
                  onClick={(e) => handleMenuChange(e, "navigator")}
                  className={mainMenu.navigator ? "active" : ""}
                >
                  <span className={classes.bold_text_white}>Navigator</span>
                </a>
              </li>
              {sitesmanage}
              {usermanage}
              {userbilling}
              {reports_config}
              {/* {reviewpage_config} */}
              {/* {insightsmanage} */}
              {tests}
              {reviewpage_config}
              {reviewpage3_config}
              {BI_config}
              {alpr_config}
              {appStore!.authService!.getRoles() &&
              appStore!.authService!.getRoles()!.indexOf("site_admin") >= 0 ? (
                <li
                  onClick={openSearch}
                  style={{ width: 10, marginTop: 20, marginLeft: 20 }}
                >
                  {/* <StyledButton title={""} clickHandler={openSearch}> */}
                  <span
                    onClick={openSearch}
                    style={{
                      fontSize: 23,
                      color: "white",
                      cursor: "pointer",
                      fontWeight: 800,
                    }}
                  >
                    <SearchIcon />
                  </span>
                  {/* </StyledButton> */}
                </li>
              ) : null}
            </ul>
          </nav>
          <div className="action-tab">
            <nav>
              <div className="nav-mobile">
                <a id="nav-toggle" href="#!">
                  <span></span>
                </a>
              </div>
              {changeorg}
            </nav>

            {/* <div className="action-item"> */}
            <Avatar onClick={handleClick} className="avatar-icon">
              {av}
            </Avatar>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem id="version">
                <span className={classes.bold_text}>
                  sw ver: {appStore?.version}
                </span>
              </MenuItem>
              <MenuItem id="logout" onClick={handleProfile}>
                <span className={classes.bold_text}>
                  My Profile / MFA Settings
                </span>
              </MenuItem>
              <MenuItem id="logout" onClick={handleClose}>
                <span className={classes.bold_text}>Logout</span>
              </MenuItem>
            </Menu>
            {/* </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export { TopNavBar };
