import React, { useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { User, Org, UsersForOrg } from "../../../services/FunctionService";
import { message as AntMessage } from "antd";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface AddUserModalProps {
  groupid: number;
  appStore?: AppStore;
  open: boolean;
  message: string;
  close: () => void;
  saveNewUser: (
    user_name: string,
    first_name: string,
    last_name: string,
    password: string,
    email: string,
    contact_number: string,
    roles: string,
    web_roles: string,
    job_description: string,
    org_id: number,
  ) => void;
  groupname: string;
  refreshTable: () => void;
  setOpen: (open: any) => void;
}

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

const getBaseUrl = (): string => {
  let base = "";
  if (window.location.href.includes("local")) {
    base = "https://manage.staging.deepalert.ai";
  }
  base += "/api/v1.3";
  return base;
};

const AddUserModal: React.FC<AddUserModalProps> = ({
  refreshTable,
  // groupname,
  // groupid,
  message,
  appStore,
  open,
  close,
  saveNewUser,
  setOpen,
}: AddUserModalProps) => {
  const classes = useStyles();
  const [user_name, setUserName] = React.useState("");
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contact_number, setContactNumber] = React.useState("");
  const [passwordlabel, setPasswordLabel] = React.useState("too short");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [passwordcolor, setPasswordColor] = React.useState("#FF0000");
  const [strong, setStrong] = React.useState(false);
  const [orgslist, setOrgsList] = React.useState<Org[]>([]);
  const [webRoleSelectorStatus, setWebRoleSelectorStatus] = React.useState(
    appStore?.helpers.webRoleSelectorStatus,
  );
  const [roleSelectorStatus, setRoleSelectorStatus] = React.useState(
    appStore?.helpers.roleSelectorStatus,
  );
  const [groupid, setGroupId] = React.useState(-1);
  const [groupname, setGroupName] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [usersfortree, setUsersForTree] = React.useState([
    "Sysadmin",
    "DeepAlert Sales",
    "DeepAlert Support",
    "DeepAlert Dev",
    "Operator",
    "Supervisor",
    "False Alarm Filtering Manager",
    "False Alarm Filtering Administrator",
    "Technician",
    "End-user access - installer",
    "Other",
  ]);
  const [userRole, setUserRole] = React.useState("");

  const styler = useStyle();
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [showOther, setShowOther] = React.useState(false);
  const [showOtherComment, setShowOtherComment] = React.useState<string>("");

  const userName = appStore?.authService.getUsername();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleChangeAuto = (value: any) => {
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    console.debug("Group ID", new_group_id);
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setGroupName(group.org_name);
    }
  };

  const handleUserNameChange = (event: React.ChangeEvent<any>) => {
    setUserName(event.target.value);
  };
  const handleFirstNameChange = (event: React.ChangeEvent<any>) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event: React.ChangeEvent<any>) => {
    setLastName(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<any>) => {
    let password = event.target.value;
    setPassword(password);
    if (password.length > 12) {
      if (password.toLowerCase() != password) {
        var hasNumber = /\d/;
        if (hasNumber.test(password)) {
          var hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
          if (!hasSpecial.test(password)) {
            setPasswordLabel("Password must contain some special characters!");
            setPasswordColor("#FFBF00");
            setStrong(false);
          } else {
            setPasswordLabel("");
            setStrong(true);
            setPasswordColor("#0000FF");
          }
        } else {
          setPasswordLabel("Password must contain some numbers!");
          setPasswordColor("#FFBF00");
          setStrong(false);
        }
      } else {
        setPasswordLabel("Use uppercase and lowercase letters!");
        setPasswordColor("#FFBF00");
        setStrong(false);
      }
    }
  };
  const handleEmailChange = (event: React.ChangeEvent<any>) => {
    setEmail(event.target.value);
  };
  const handleContactNumberChange = (event: React.ChangeEvent<any>) => {
    setContactNumber(event.target.value);
  };

  const alert_web_roles = [
    {
      label: "View Alerts",
      value: "web_alerts",
    },
    {
      label: "View History",
      value: "web_alert_history",
    },
    {
      label: "View Escalations",
      value: "web_alert_escalations",
    },
    {
      label: "Camera filter",
      value: "web_alert_camera_filter",
    },
    {
      label: "Bulk Actions",
      value: "web_bulk_actions",
    },
    {
      label: "Mute Control",
      value: "web_mute_controls",
    },
    {
      label: "Mute one hour",
      value: "web_mute_one_hour",
    },
  ];
  const user_roles = [
    "org_admin",
    "site_admin",
    "user_admin",
    "billing_admin",
    "classifier_jwt",
  ];
  useEffect(() => {
    if (userRole === "Other") {
      setShowOther(true);
    } else {
      setShowOther(false);
    }

    if (appStore) {
      if (userRole === "Operator") {
        console.debug("`roleSelectorStatus userRole: ", userRole);

        const inc_web_roles = [
          "web_alerts",
          "web_alert_escalations",
          "web_alert_history",
        ];
        const inc_user_roles: any = [];

        alert_web_roles.map((item: any) => {
          if (inc_web_roles.indexOf(item.value) > -1) {
            appStore.helpers.webRoleSelectorStatus[item.value] = true;
          } else {
            appStore.helpers.webRoleSelectorStatus[item.value] = false;
          }
        });
        user_roles.map((item: any) => {
          if (inc_user_roles.indexOf(item) > -1) {
            appStore.helpers.roleSelectorStatus[item] = true;
          } else {
            appStore.helpers.roleSelectorStatus[item] = false;
          }
        });
        setRoleSelectorStatus(appStore.helpers.roleSelectorStatus);
        setWebRoleSelectorStatus(appStore.helpers.webRoleSelectorStatus);
        setReload(new Date().getTime());
        setOpen(true);
      }

      if (userRole === "Supervisor") {
        console.debug("`roleSelectorStatus userRole: ", userRole);

        const inc_web_roles = [
          "web_alerts",
          "web_alert_escalations",
          "web_alert_history",
          "web_mute_controls",
          "web_alert_camera_filter",
          "web_bulk_actions",
        ];
        const inc_user_roles = ["view_only"];

        alert_web_roles.map((item: any) => {
          if (inc_web_roles.indexOf(item.value) > -1) {
            appStore.helpers.webRoleSelectorStatus[item.value] = true;
          } else {
            appStore.helpers.webRoleSelectorStatus[item.value] = false;
          }
        });
        user_roles.map((item: any) => {
          if (inc_user_roles.indexOf(item) > -1) {
            appStore.helpers.roleSelectorStatus[item] = true;
          } else {
            appStore.helpers.roleSelectorStatus[item] = false;
          }
        });
        setRoleSelectorStatus(appStore.helpers.roleSelectorStatus);
        setWebRoleSelectorStatus(appStore.helpers.webRoleSelectorStatus);
        setReload(new Date().getTime());
        setOpen(true);
      }

      if (userRole === "False Alarm Filtering Manager") {
        console.debug("`roleSelectorStatus userRole: ", userRole);

        const alert_web_roles = [
          {
            label: "View Alerts",
            value: "web_alerts",
          },
          {
            label: "View History",
            value: "web_alert_history",
          },
          {
            label: "Mute Controls",
            value: "web_mute_controls",
          },
          {
            label: "View Escalations",
            value: "web_alert_escalations",
          },
          {
            label: "Camera filter",
            value: "web_alert_camera_filter",
          },
          {
            label: "Bulk Actions",
            value: "web_bulk_actions",
          },
        ];

        const inc_web_roles: any = [];
        const inc_user_roles = [
          "site_admin",
          "user_admin",
          "org_admin",
          "billing_admin",
        ];

        alert_web_roles.map((item: any) => {
          if (inc_web_roles.indexOf(item.value) > -1) {
            appStore.helpers.webRoleSelectorStatus[item.value] = true;
          } else {
            appStore.helpers.webRoleSelectorStatus[item.value] = false;
          }
        });
        user_roles.map((item: any) => {
          if (inc_user_roles.indexOf(item) > -1) {
            appStore.helpers.roleSelectorStatus[item] = true;
          } else {
            appStore.helpers.roleSelectorStatus[item] = false;
          }
        });
        setRoleSelectorStatus(appStore.helpers.roleSelectorStatus);
        setWebRoleSelectorStatus(appStore.helpers.webRoleSelectorStatus);
        setReload(new Date().getTime());
        window.alert("This allows this user to add sites that are billable");
      }

      if (userRole === "End-user access - installer") {
        console.debug("`roleSelectorStatus userRole: ", userRole);

        const inc_web_roles: any = [];
        const inc_user_roles = ["site_admin", "user_admin", "org_admin"];

        alert_web_roles.map((item: any) => {
          if (inc_web_roles.indexOf(item.value) > -1) {
            appStore.helpers.webRoleSelectorStatus[item.value] = true;
          } else {
            appStore.helpers.webRoleSelectorStatus[item.value] = false;
          }
        });
        user_roles.map((item: any) => {
          if (inc_user_roles.indexOf(item) > -1) {
            appStore.helpers.roleSelectorStatus[item] = true;
          } else {
            appStore.helpers.roleSelectorStatus[item] = false;
          }
        });
        setRoleSelectorStatus(appStore.helpers.roleSelectorStatus);
        setWebRoleSelectorStatus(appStore.helpers.webRoleSelectorStatus);
        setReload(new Date().getTime());
      }

      // Update logic to handle new roles Pinto
      if (userRole === "Technician") {
        console.debug("`roleSelectorStatus userRole: ", userRole);

        const inc_web_roles: any = [];
        const inc_user_roles = [
          "site_admin",
          "user_admin",
          "org_admin",
          "billing_admin",
        ];

        alert_web_roles.map((item: any) => {
          if (inc_web_roles.indexOf(item.value) > -1) {
            appStore.helpers.webRoleSelectorStatus[item.value] = true;
          } else {
            appStore.helpers.webRoleSelectorStatus[item.value] = false;
          }
        });
        user_roles.map((item: any) => {
          if (inc_user_roles.indexOf(item) > -1) {
            appStore.helpers.roleSelectorStatus[item] = true;
          } else {
            appStore.helpers.roleSelectorStatus[item] = false;
          }
        });
        setRoleSelectorStatus(appStore.helpers.roleSelectorStatus);
        setWebRoleSelectorStatus(appStore.helpers.webRoleSelectorStatus);
        setReload(new Date().getTime());
      }
    }
  }, [userRole]);

  //  Fetching Organizations
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      if (token) {
        // Fetch Start
        try {
          const request = JSON.stringify({
            enabled: true,
            paginate: false,
            verbose: false,
            incl_tree_names: true,
          });

          const res = fetch(baseUrl + "/list_orgs", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });
          const result = await (await res).json();
          const orglist = result;
          if (orglist && orglist.success) {
            let orgz = orglist?.orgs;
            orgz.sort(function (a: any, b: any) {
              return a.org_tree_names - b.org_tree_names;
            });
            orgz.sort(function (a: any, b: any) {
              return a.org_tree_names.length - b.org_tree_names.length;
            });
            setOrgsList(orgz);
            setLoading(false);
          }
        } catch (err) {
          return err;
        }
      }
    };
    fetchData();
  }, []);

  const roles = appStore?.authService.getRoles();
  var roleselector: React.ReactNode[] = [];
  var webroleselector: React.ReactNode[] = [];

  const [reload, setReload] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<any>) => {
    if (appStore) {
      appStore.helpers.roleSelectorStatus[event.target.name] =
        event.target.checked;
      setRoleSelectorStatus(appStore.helpers.roleSelectorStatus);
    }
    setReload(new Date().getTime());
  };

  // Initialize web role selector Pinto
  useEffect(() => {
    if (appStore) {
      setWebRoleSelectorStatus(appStore.helpers.webRoleSelectorStatus);
    }
  }, [appStore]);

  //Handle changes in web role Pinto
  const handleWebRoleChange = (event: React.ChangeEvent<any>) => {
    if (appStore) {
      const { name, checked } = event.target;

      if (name === "web_mute_controls" && checked) {
        appStore.helpers.webRoleSelectorStatus["web_mute_one_hour"] = false;
      } else if (name === "web_mute_one_hour" && checked) {
        appStore.helpers.webRoleSelectorStatus["web_mute_controls"] = false;
      }

      appStore.helpers.webRoleSelectorStatus[name] = checked;
      setWebRoleSelectorStatus(appStore.helpers.webRoleSelectorStatus);
    }
    setReload(new Date().getTime());
  };
  // Render roles in UI Pinto
  if (roles) {
    let description = "";
    roleselector.push(
      <Typography className={classes.bold_text}>
        Management Permissions:
      </Typography>,
    );
    webroleselector.push(
      <Typography className={classes.bold_text}>
        Alerting Interface Permissions:
      </Typography>,
    );

    alert_web_roles.map((item: any) => {
      const checked = !!webRoleSelectorStatus[`${item.value}`];
      webroleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={checked}
                onChange={handleWebRoleChange}
                name={item.value}
              />
            }
            label={<span className={classes.bold_text}>{item.label}</span>}
          />
        </Tooltip>,
      );
    });

    if (roles.indexOf("org_admin") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "org_admin",
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={roleSelectorStatus["org_admin"]}
                onChange={handleChange}
                name={"org_admin"}
              />
            }
            label={<span className={classes.bold_text}>Group Admin</span>}
          />
        </Tooltip>,
      );
    }

    if (roles.indexOf("move_site") > -1 && userName?.endsWith("deepalert.ai")) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "move_site",
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={roleSelectorStatus["move_site"]}
                onChange={handleChange}
                name={"move_site"}
              />
            }
            label={<span className={classes.bold_text}>Move Site</span>}
          />
        </Tooltip>,
      );
    }

    if (roles.indexOf("site_admin") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "site_admin",
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={roleSelectorStatus["site_admin"]}
                onChange={handleChange}
                name={"site_admin"}
              />
            }
            label={<span className={classes.bold_text}>Site Admin</span>}
          />
        </Tooltip>,
      );
    }
    if (roles.indexOf("user_admin") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "user_admin",
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={roleSelectorStatus["user_admin"]}
                onChange={handleChange}
                name={"user_admin"}
              />
            }
            label={<span className={classes.bold_text}>User Admin</span>}
          />
        </Tooltip>,
      );
    }
    if (roles.indexOf("billing_admin") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "billing_admin",
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={roleSelectorStatus["billing_admin"]}
                onChange={handleChange}
                name={"billing_admin"}
              />
            }
            label={<span className={classes.bold_text}>Billing Admin</span>}
          />
        </Tooltip>,
      );
    }
    if (roles.indexOf("uat") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "uat",
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={roleSelectorStatus["uat"]}
                onChange={handleChange}
                name={"uat"}
              />
            }
            label={<span className={classes.bold_text}>UAT</span>}
          />
        </Tooltip>,
      );
    }
    if (roles.indexOf("support") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "support",
      )!;
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={roleSelectorStatus["support"]}
                onChange={handleChange}
                name={"support"}
              />
            }
            label={<span className={classes.bold_text}>Support</span>}
          />
        </Tooltip>,
      );
    }

    if (roles.indexOf("classifier_jwt") > -1) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "classifier_jwt",
      )!;
      roleselector.push(<div className={classes.space_smallest}></div>);
      roleselector.push(
        <Typography className={classes.bold_text}>Other Roles:</Typography>,
      );
      roleselector.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox_left_margin}
                color="primary"
                checked={roleSelectorStatus["classifier_jwt"]}
                onChange={handleChange}
                name={"classifier_jwt"}
              />
            }
            label={<span className={classes.bold_text}>Inference Tokens</span>}
          />
        </Tooltip>,
      );
    }
  }

  const handleChangeUser = (value: any) => {
    setUserRole(value);
  };

  // Save roles when saving the user Pinto
  const handleSave = async () => {
    var selected_roles = "default";
    var selected_webroles = "";

    for (let role in appStore?.helpers.roleSelectorStatus) {
      if (appStore?.helpers.roleSelectorStatus[role]) {
        selected_roles = selected_roles + "," + role;
      }
    }

    for (let webrole in appStore?.helpers.webRoleSelectorStatus) {
      if (appStore?.helpers.webRoleSelectorStatus[webrole]) {
        selected_webroles = selected_webroles + "," + webrole;
      }
    }

    if (groupid < 0) {
      setOpenSnackBar(true);
      return setErrorMessage("Please select the group");
    }

    saveNewUser(
      user_name,
      first_name,
      last_name,
      password,
      email,
      contact_number,
      selected_roles,
      selected_webroles,
      userRole === "Other" ? showOtherComment : userRole || "",
      groupid,
    );
  };

  const scrollStyles = makeStyles({
    paper: {
      minWidth: "500px",
    },
  });
  const scrollclasses = scrollStyles();

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <Dialog classes={{ paper: scrollclasses.paper }} open={open}>
      <DialogTitle className={classes.bold_text}>
        Add New User to {groupname}
      </DialogTitle>
      <Typography className={classes.bold_text}>{message}</Typography>
      <TextField
        onChange={handleUserNameChange}
        label="Username"
        variant="outlined"
        margin="normal"
        fullWidth
        autoFocus
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      <TextField
        onChange={handlePasswordChange}
        type="password"
        label={"Password :" + passwordlabel}
        variant="outlined"
        margin="normal"
        fullWidth
        InputProps={{
          style: { color: passwordcolor },
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />

      <Autocomplete
        id="combo-box-demo"
        className={classes.form_component_medium}
        options={orgslist}
        onChange={(event: React.ChangeEvent<any>, value: any) => {
          handleChangeAuto(value);
        }}
        getOptionLabel={(option: any) => {
          let tree = option.org_tree_names!.map(
            (item: any) => `   /   ${item} `,
          );
          let treeString = tree.toString();
          const rpl = treeString.replace(/,/g, "");
          return `${rpl}`;
        }}
        style={{
          width: "100%",
          color: "#6D809D",
          margin: "15px 0px 10px 0px",
          padding: 0,
        }}
        classes={{ paper: styler.paper }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              <span className={classes.bold_text} style={{ color: "#15325F" }}>
                {groupname ? groupname : "Please select group"}
              </span>
            }
            variant="outlined"
            value={groupid}
            className={classes.bold_text}
          />
        )}
      />

      {/* END */}
      {/* </FormControl> */}

      <TextField
        onChange={handleFirstNameChange}
        label="First Name"
        variant="outlined"
        margin="normal"
        fullWidth
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      <TextField
        onChange={handleLastNameChange}
        label="Last Name"
        variant="outlined"
        margin="normal"
        fullWidth
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      <TextField
        onChange={handleEmailChange}
        label="Email Address"
        variant="outlined"
        margin="normal"
        fullWidth
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      <TextField
        onChange={handleContactNumberChange}
        label="Contact Number"
        variant="outlined"
        margin="normal"
        fullWidth
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      <Autocomplete
        id="combo-box-demo"
        options={usersfortree!}
        onChange={(event: React.ChangeEvent<any>, value: any) => {
          handleChangeUser(value);
        }}
        getOptionLabel={(option) => {
          return option;
        }}
        renderOption={(option: any) => {
          return <span>{option}</span>;
        }}
        style={{
          width: "100%",
          color: "#6D809D",
          margin: "15px 0px 10px 0px",
          padding: 0,
        }}
        classes={{ paper: styler.paper }}
        renderInput={(params) => {
          console.debug("PARAMS: ************", params);
          return (
            <TextField
              {...params}
              label={
                <span className={classes.bold_text}>
                  {userRole ? userRole : "Please Select Role"}
                </span>
              }
              variant="outlined"
              value={userRole}
              className={classes.bold_text}
            />
          );
        }}
      />
      {showOther && (
        <div>
          <TextField
            onChange={(e) => {
              setShowOtherComment(e.target.value);
            }}
            style={{ color: "#15325F", marginTop: 20 }}
            label="Enter Your Role"
            value={showOtherComment}
            margin="normal"
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
          />
        </div>
      )}
      <FormControl
        component="fieldset"
        variant="outlined"
        className={classes.form_component}
        style={{ display: "flex" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <FormGroup>{roleselector}</FormGroup>
          <FormGroup>{webroleselector}</FormGroup>
        </div>
      </FormControl>
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={4000}
        onClose={handleClose}
        message={errorMessage}
        action={action}
      />
      <div className={classes.button_container}>
        <Button
          variant="contained"
          className={classes.cancel_button}
          onClick={() => close()}
        >
          <span className={classes.bold_text}>CANCEL</span>
        </Button>
        <Button
          variant="contained"
          className={classes.save_button}
          onClick={() => handleSave()}
        >
          <span className={classes.bold_text}>SAVE</span>
        </Button>
      </div>
    </Dialog>
  );
};

export default AddUserModal;
