import React, { useState, useEffect } from "react";
import { useStyles } from "../../styling/global/global";
import Button from "@material-ui/core/Button";

import { getBaseUrl } from "../../HelperFunctions";
import Spinner from "../../../spinner/Spinner";
import { AppStore } from "../../../stores/AppStore";
import List from "../../UI/List";
import ViewDetails from "../DashboardMainDrawer/ViewDetails";
import CustomModal from "../../UI/CustomModal";
import CustomButton from "../../UI/CustomButton";
import Card from "../../UI/Card";
import CustomSwitch from "../../UI/CustomSwitch";
import OrderBy from "../../UI/OrderBy";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

interface UnusualActivity {
  id: number;
  title: string;
  description: string;
  reason: string;
  url: string;
}

interface Activity {
  event_id: string;
  created: string;
  site_id: number;
  alert_channel_id: number;
  is_unusual: true;
  unusual_activity_type: string;
  classifier: string;
  sensor_id_strs: string[];
  alert_ids: string[];
  alert_description: string;
  result_reason: string;
  result_description: string;
  object_count: number;
  vehicle_count: number;
  person_count: number;
}

interface Props {
  appStore: AppStore;
}

const options = [
  { label: "select filter ", value: "select filter" },
  { label: "none ", value: "none" },
  { label: "unusual object count ", value: "unusual_object_count" },
  { label: "normal object count ", value: "normal_object_count" },
  { label: "unusual object location", value: "unusual_object_location" },
  { label: "normal object location", value: "normal_object_location" },
  { label: "unusual object size", value: "unusual_object_size" },
  { label: "normal object size", value: "normal_object_size" },
  { label: "unusual 10min object count", value: "unusual_10min_object_count" },
  { label: "normal 10min object count", value: "normal_10min_object_count" },
  { label: "unusual 10min object ratio", value: "unusual_10min_object_ratio" },
  { label: "normal 10min object ratio", value: "normal_10min_object_ratio" },
];

const time_options = [
  { label: "None", value: "none" },
  { label: "1 hour", value: "1 hr" },
  { label: "12 hrs", value: "12 hrs" },
  { label: "1 day", value: "1 day" },
  { label: "1 week", value: "1 week" },
];

const Insights: React.FC<Props> = ({ appStore }) => {
  const [enabled, setEnabled] = React.useState(true);
  const [filter_value, setFilterValue] = React.useState<string>("none");
  const [openModal, setOpenModal] = React.useState(false);
  const [openTimeFilter, setOpenTimeFilter] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState(false);
  const [activities, setActivities] = React.useState<Activity[] | []>([]);
  const [timeVal, setTimeVal] = React.useState("none");
  const [message, setMessage] = React.useState<string>(
    "Under Implementation - Illustrative Data Only.",
  );
  const [errMessage, setErrMessage] = React.useState<string>("");
  const [eventDetails, setEventDetails] = React.useState<Activity>({
    event_id: "",
    created: "",
    site_id: 0,
    alert_channel_id: 0,
    is_unusual: true,
    unusual_activity_type: "",
    classifier: "",
    sensor_id_strs: [""],
    alert_ids: [""],
    alert_description: "",
    result_reason: "",
    result_description: "",
    object_count: 0,
    vehicle_count: 0,
    person_count: 0,
  });

  const classes = useStyles();

  const orderBy = (value: any) => {
    setFilterValue(value);
    fetchActivities(value);
  };

  const filterTime = (data: any) => {
    let epocSeconds = 0;
    let day = new Date().getDate();
    let hour = new Date().getHours();
    let minutes = new Date().getMinutes();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    let d = new Date(year, month, day, hour, minutes);
    epocSeconds = Math.round(d.getTime() / 1000);

    if (data === "1 hr") {
      hour = hour - 1;
      d = new Date(year, month, day, hour, minutes);
      epocSeconds = Math.round(d.getTime() / 1000);
      setTimeVal("1 hr");
      fetchActivities(undefined, epocSeconds);
    }
    if (data === "12 hrs") {
      hour = hour - 12;
      d = new Date(year, month, day, hour, minutes);
      epocSeconds = Math.round(d.getTime() / 1000);
      setTimeVal("12 hrs");
      fetchActivities(undefined, epocSeconds);
    }
    if (data === "1 day") {
      day = day - 1;
      d = new Date(year, month, day, hour, minutes);
      epocSeconds = Math.round(d.getTime() / 1000);
      setTimeVal("1 day");
      fetchActivities(undefined, epocSeconds);
    }
    if (data === "1 week") {
      day = day - 7;
      d = new Date(year, month, day, hour, minutes);
      epocSeconds = Math.round(d.getTime() / 1000);
      setTimeVal("1 week");
      fetchActivities(undefined, epocSeconds);
    }

    if (data === "none") {
      setTimeVal("none");
      fetchActivities(undefined, undefined);
    }

    setOpenTimeFilter(false);
  };

  const filterActivities = (data: Activity[], fil_value: string) => {
    let filtered_activities = data;

    if (fil_value === "unusual_object_count") {
      filtered_activities = data.filter(
        (activity) => activity.unusual_activity_type === "unusual_object_count",
      );
      return filtered_activities;
    }
    if (fil_value === "normal_object_count") {
      filtered_activities = data.filter(
        (activity) => activity.unusual_activity_type === "normal_object_count",
      );
      return filtered_activities;
    }
    if (fil_value === "unusual_object_location") {
      filtered_activities = data.filter(
        (activity) =>
          activity.unusual_activity_type === "unusual_object_location",
      );
      return filtered_activities;
    }
    if (fil_value === "normal_object_location") {
      filtered_activities = data.filter(
        (activity) =>
          activity.unusual_activity_type === "normal_object_location",
      );
      return filtered_activities;
    }
    if (fil_value === "unusual_object_size") {
      filtered_activities = data.filter(
        (activity) => activity.unusual_activity_type === "unusual_object_size",
      );
      return filtered_activities;
    }
    if (fil_value === "normal_object_size") {
      filtered_activities = data.filter(
        (activity) => activity.unusual_activity_type === "normal_object_size",
      );
      return filtered_activities;
    }
    if (fil_value === "unusual_10min_object_count") {
      filtered_activities = data.filter(
        (activity) =>
          activity.unusual_activity_type === "unusual_10min_object_count",
      );
      return filtered_activities;
    }
    if (fil_value === "normal_10min_object_count") {
      filtered_activities = data.filter(
        (activity) =>
          activity.unusual_activity_type === "normal_10min_object_count",
      );
      return filtered_activities;
    }
    if (fil_value === "unusual_10min_object_ratio") {
      filtered_activities = data.filter(
        (activity) =>
          activity.unusual_activity_type === "unusual_10min_object_ratio",
      );
      return filtered_activities;
    }
    if (fil_value === "normal_10min_object_ratio") {
      filtered_activities = data.filter(
        (activity) =>
          activity.unusual_activity_type === "normal_10min_object_ratio",
      );
      return filtered_activities;
    }

    if (fil_value === "none") {
      return data;
    }
    return data;
  };

  const closeModalHandler = () => {
    setOpenModal(false);
  };

  const openDetailsHandler = (id: string) => {
    const found_item = activities.find((item: any) => item.event_id === id);

    if (found_item) {
      setEventDetails(found_item);
      setOpenModal(true);
    }
  };

  const filterActivitiesByTime = (
    data: Activity[],
    look_back_period: number,
  ) => {
    let filtered_activities = data.filter(
      (activity) =>
        new Date(activity.created).getTime() / 1000 >= look_back_period,
    );
    return filtered_activities;
  };

  const fetchActivities = async (
    fil_value?: string,
    look_back_period?: number | undefined,
  ) => {
    setLoading(true);
    let baseUrl = getBaseUrl();
    let token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      try {
        let request = JSON.stringify({
          is_unusual: enabled,
        });

        if (look_back_period) {
          request = JSON.stringify({
            is_unusual: enabled,
            event_start_start: look_back_period,
          });
        }

        const res = await fetch(baseUrl + "/unusual_activity", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        if (!res.ok || res.status >= 500) {
          setErr(true);
          setErrMessage("Something went wrong, please try again");
        }

        const result = (await (await res).json()) as any;
        const msg = result.msg;

        if (result.success) {
          if (fil_value) {
            let data = filterActivities(msg, fil_value);
            setActivities(data);
          } else if (look_back_period) {
            let data = filterActivitiesByTime(msg, look_back_period);
            setActivities(data);
          } else {
            setActivities(msg);
          }

          setErr(false);
        }
      } catch (err) {
        console.debug(err);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchActivities();
  }, [enabled]);

  return (
    <div style={{ width: 1080 }}>
      <CustomModal closeHandler={closeModalHandler} open={openModal}>
        <ViewDetails
          appStore={appStore}
          site_id={eventDetails.site_id}
          is_unusual={eventDetails.is_unusual}
          unusual_activity_type={eventDetails.unusual_activity_type}
          classifier={eventDetails.classifier}
          sensor_id_strs={eventDetails.sensor_id_strs}
          alert_description={eventDetails.alert_description}
          result_reason={eventDetails.result_reason}
          result_description={eventDetails.result_description}
          object_count={eventDetails.object_count}
          vehicle_count={eventDetails.vehicle_count}
          person_count={eventDetails.person_count}
          alert_id={
            eventDetails.alert_ids && eventDetails.alert_ids[0].length > 0
              ? eventDetails.alert_ids[0]
              : ""
          }
        />
      </CustomModal>
      <CustomModal
        closeHandler={() => {
          setOpenTimeFilter(false);
        }}
        open={openTimeFilter}
      >
        <p className={classes.bold_text}>
          &nbsp;&nbsp;&nbsp;Select the look back period to filter events
        </p>
        <div style={{ display: "flex", width: 200, padding: 20 }}>
          <OrderBy
            options={time_options}
            orderBy={filterTime}
            title={"look back period"}
            value={timeVal}
          />
          <div
            style={{
              marginLeft: 25,
              justifyContent: "center",
              alignItems: "center",
              marginTop: 12,
            }}
          ></div>
        </div>
      </CustomModal>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <span className={classes.bold_text} style={{ fontSize: 16 }}>
              DeepAlert Insight Analysis
            </span>
          </Paper>
        </Grid>
        <Card>
          <div
            style={{
              marginLeft: 50,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CustomSwitch
              title="Usual Activities/ Unusual Activities"
              enabled={enabled}
              setEnabled={(enabled: boolean) => {
                setEnabled(enabled);
              }}
            />
            <div style={{ marginTop: 30 }}>
              <Button
                variant="contained"
                className={classes.mute_button}
                onClick={(e) => {
                  setOpenTimeFilter(true);
                }}
              >
                Time Filter &nbsp; &nbsp;
              </Button>
            </div>
            <div style={{ marginRight: 40 }}>
              <OrderBy
                options={options}
                orderBy={orderBy}
                title={"Filter By"}
                value={filter_value}
              />
            </div>
          </div>
          {!loading && !!activities.length && (
            <>
              <div className={classes.bold_text} style={{ height: 450 }}>
                <List data={activities} openModal={openDetailsHandler} />
              </div>
              <CustomButton
                title={"Refresh"}
                onClickHandler={(id) => {
                  fetchActivities(filter_value);
                }}
                id={"refresh"}
              />
            </>
          )}
          {loading && (
            <div
              className={classes.bold_text}
              style={{
                display: "flex",
                height: 450,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          )}
          {!loading && !!activities.length === false && (
            <div
              className={classes.bold_text}
              style={{
                display: "flex",
                height: 450,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>No activities found!</p>
            </div>
          )}
          {errMessage && (
            <p
              style={{
                display: "flex",
                height: 450,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {errMessage}
            </p>
          )}
        </Card>
      </Grid>
    </div>
  );
};

export default Insights;
