import React, { useState, useEffect } from "react";
import { AppStore } from "../stores/AppStore";
import LoginPage from "../shared/containers/LoginPage/LoginPage";
import HomePage from "../shared/containers/HomePage/HomePage";
import OrgPage from "../shared/containers/OrgPage/OrgPage";
import { Org, Hub, Site } from "../services/FunctionService";
import { ValueChecking } from "../shared/HelperFunctions";
import "antd/dist/antd.min.css";

interface AppProps {
  appStore?: AppStore;
}
class impOrg implements Org {
  org_id!: number;
  org_name!: string;
  org_tree!: number[];
  org_level!: number;
  enabled!: boolean;
  sites!: Site[];
  hubs!: Hub[];
  orgs!: Org[];
  type!: string;
  created!: string;
  sub_org_count!: number;
  site_count!: number;
  hub_count!: number;
}
const App: React.FC<AppProps> = ({ appStore }: AppProps) => {
  var currentpage: React.ReactNode = [];

  const [page, setPage] = useState(currentpage);
  //This is the current ORG (Group) in the breadcumbs and on the MainExpansionDrawer, contains sub-groups, sites, shared hubs and report groups.
  const [currentOrg, setCurrentOrg] = useState<Org | undefined>(undefined);

  // Whenever the current org changes, make sure the user is still logged in, else goto Login Page
  useEffect(() => {
    if (appStore?.authService.isCurrentlyAuthorised()) {
      setPage([
        <HomePage
          appStore={appStore}
          authhandler={handler}
          changeOrg={(d: Org) => changeOrg(d)}
          currentOrg={currentOrg}
        />,
      ]);
    } else {
      setPage([<LoginPage appStore={appStore} authhandler={handler} />]);
    }
  }, [currentOrg]);

  //Load the Locales and store in functionservice for translation  usage
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var newlocales = await appStore?.functionService.getLocale(
          token,
          "en_ZA",
        );
        if (newlocales && appStore) {
          appStore.functionService.locales = newlocales;
        }
      }
    };
    fetchData();
  }, []);
  //receive version from API and check against the current. if different, force a reload
  useEffect(() => {
    try {
      const checkVersionInterval = setInterval(async () => {
        await getVersionResult();
      }, 30000);

      return () => {
        clearInterval(checkVersionInterval);
      };
    } catch (err) {}
  }, []);

  const getVersionResult = async () => {
    const result = await appStore?.functionService.checkVersion();

    if (result?.min_management_gui_ver && appStore?.version) {
      var alert_backend = result?.min_management_gui_ver;
      var alert_front = appStore?.version;
      const valueResult = ValueChecking(alert_front, alert_backend);
      if (valueResult === true) {
      } else {
        alert("Your Management GUI version is out of date, click ok to update");
        appStore?.authService.signOut();
        var ev: Event;
        ev = new Event("authupdate");
        handler(ev);

        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
        }
        window.location.reload();
      }
    } else {
      appStore?.authService.signOut();
      var ev: Event;
      ev = new Event("authupdate");
      handler(ev);

      if ("caches" in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
      window.location.reload();
    }
  };

  //If logged in and there is no current org, set the current org to the logged-in entry org.
  if (appStore) {
    if (appStore.authService.isCurrentlyAuthorised()) {
      if (!currentOrg) {
        let neworg = new impOrg();
        neworg.org_id = appStore?.authService.getEntryOrg()?.id!;
        changeOrg(neworg);
      }
    }
  }

  //function that gets passed down the application to be used to change a current org, i.e. click on breadcrumbs or on sub-group
  async function changeOrg(org: Org) {
    if (org && org.org_id) {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var rest_org = await appStore?.functionService.getOrgOneLevel(
          token!,
          org.org_id,
        );

        if (rest_org) {
          if (rest_org.orgs == undefined) {
            rest_org.orgs = [];
          }
          await appStore?.authService.setBreadcrumbOrgs(rest_org);
          appStore?.helpers.setCurrentOrgId(org.org_id);
          setCurrentOrg(rest_org);
        }
      }
    }
  }

  //Function called when a login or entry org change takes place. This can happen for instance where a JWT cannot be refreshed
  function handler(ev: Event) {
    if (appStore) {
      if (ev.type == "authupdate") {
        if (!appStore.authService.isCurrentlyAuthorised()) {
          if (currentOrg) {
            setCurrentOrg(undefined);
          }
          setPage([<LoginPage appStore={appStore} authhandler={handler} />]);
        } else {
          setPage([
            <HomePage
              appStore={appStore}
              authhandler={handler}
              changeOrg={(d: Org) => changeOrg(d)}
              currentOrg={currentOrg}
            />,
          ]);
        }
      } else if (ev.type == "entrychanged") {
        if (!appStore.authService.isCurrentlyAuthorised()) {
          if (currentOrg) {
            setCurrentOrg(undefined);
          }
          setPage([<LoginPage appStore={appStore} authhandler={handler} />]);
        } else {
          setPage([
            <OrgPage
              appStore={appStore}
              changeOrg={(d: Org) => changeOrg(d)}
            />,
          ]);
        }
      } else {
        setPage([
          <OrgPage appStore={appStore} changeOrg={(d: Org) => changeOrg(d)} />,
        ]);
      }
    }
  }
  //Pass the authhandler to the authService class so it can be called from anywhere.
  appStore?.authService.setHandler(handler);
  return (
    <div
      style={{
        margin: 0,
        fontSize: "0.85rem",
        fontFamily: "Open Sans",
        fontWeight: 400,
        lineHeight: "1.43",
        letterSpacing: "0.06em",
      }}
    >
      {page}
    </div>
  );
};
export { App };
