import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { AppStore } from "../../../stores/AppStore";
import {
  Site,
  BillingRate,
  Sensor,
  Hub,
} from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

interface NvrModalProps {
  appStore?: AppStore;
  onClose: () => void;
  updateSiteUseEmailTime: (site_id: number, use_email_time: boolean) => void;
  sensor: Sensor | undefined;
  open: boolean;
  site: Site | undefined;
}

const NvrModal: React.FC<NvrModalProps> = ({
  onClose,
  open,
  appStore,
  updateSiteUseEmailTime,
  sensor,
  site,
}: NvrModalProps) => {
  const classes = useStyles();
  const [billing_rate_id, setBillingRateId] = React.useState(0);
  const [billing_rates, setBillingRates] = React.useState<
    BillingRate[] | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [hubs, setHubs] = useState<Hub[] | undefined>([]);
  const [renderValue, setRenderValue] = useState(false);
  const [useEmailTime, setUseEmailTime] = useState(false);
  const [siteDetails, setSiteDetails] = useState<Site | undefined>(undefined);
  const [smtpDetails, setSmtpDetails] = useState({
    success: false,
    edge_enhanced_smtp_server: "",
    edge_enhanced_smtp_server_port: "",
    smtp_password: "",
  });

  useEffect(() => {
    async function getSmtpDetails(siteId: number) {
      const token = await appStore?.authService.getAuthorisedToken();
      setLoading(true);
      if (token) {
        let newAlertSmtp =
          await appStore?.functionService.createNewSmtpPassword(
            token,
            siteId,
            false,
          );
        if (newAlertSmtp) {
          if (newAlertSmtp.success) {
            setSmtpDetails(newAlertSmtp);
            var hubs = await appStore?.functionService.getHubsForSite(
              token,
              site!.site_id,
            );
            if (hubs) {
              setHubs(hubs);
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        }
      }
    }
    if (site) {
      if (site?.data_origin === "alert_smtp_client") {
        getSmtpDetails(site.site_id);
      }
      if (site && site.meta) {
        if (site.meta.hasOwnProperty("use_email_time")) {
          setUseEmailTime(site.meta["use_email_time"]);
        }
      }

      setSiteDetails(site);
    }
  }, [renderValue, site]);

  const handleClose = () => {
    setUseEmailTime(false);
    onClose();
  };

  const StyledTableRowSMTP = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className={classes.modal_dialog_wide}
    >
      <DialogTitle>
        <span
          className={classes.bold_text}
          style={{ fontSize: 20, color: "primary" }}
        >
          SMTP connection settings
        </span>
      </DialogTitle>
      <br></br>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              marginTop: 15,
              borderRadius: 15,
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    Sender name:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    DeepAlert
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    Sender email address:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    alert@deepalert.ai
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    SMTP server:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    {smtpDetails.edge_enhanced_smtp_server}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    SMTP PORT:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    {smtpDetails.edge_enhanced_smtp_server_port}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    SMTP username:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  {hubs &&
                    hubs.slice(0, 1).map((item, index) => {
                      return (
                        <Typography
                          className={classes.bold_text}
                          style={{ color: "grey" }}
                        >
                          {item.hub_id_str}
                        </Typography>
                      );
                    })}
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    SMTP password:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    {smtpDetails.smtp_password}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    Recipient email address:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    cloud@deepalert.ai
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
            </TableContainer>
          </div>
          <div>
            <FormControlLabel
              className={classes.form_component_checkbox}
              control={
                <Checkbox
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  checked={useEmailTime}
                  onChange={(e) => {
                    setUseEmailTime(e.target.checked);
                  }}
                />
              }
              label="use email time"
            />
          </div>
        </>
      )}

      <div className={classes.twoButtonsInARow}>
        <Button
          variant="contained"
          className={classes.cancel_button}
          onClick={handleClose}
        >
          Dismiss
        </Button>
        {!loading ? (
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              updateSiteUseEmailTime(siteDetails?.site_id!, useEmailTime);
              handleClose();
            }}
          >
            Update
          </Button>
        ) : null}
      </div>
    </Dialog>
  );
};
export default NvrModal;
