import React, { useEffect, useState } from "react";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

import {
  addBillingOrgResult,
  listBillingRates,
  listBillingRatesItems,
  listBillingRateSupportItems,
  billing_rate_support_items,
} from "../../../../services/FunctionService";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import ProductSetupExpansion from "./ProductSetupExpansion/ProductSetupExpansion";
import ListProductOrgs from "../BillingModals/ListProductOrgs";
import EditProduct from "../BillingModals/EditProduct";

import { Typography } from "@material-ui/core";
import AddProductOrg from "../BillingModals/AddProductOrg";
import ResultMessage from "../BillingModals/ResultMessage";
import ViewProductInfo from "../BillingModals/ViewProductInfo";
import { ImageTwoTone } from "@material-ui/icons";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CloneProduct from "../BillingModals/CloneProduct";

interface ProductSetupProps {
  appStore?: AppStore;
}
type col_variable =
  | "public_name"
  | "billing_rate"
  | "tier"
  | "classifier"
  | "data_origin"
  | "tier"
  | "site_count"
  | "group_count"
  | "id"
  | "";
type isAscendingType = "true" | "false" | "default";

interface OrderArrayBySorterProps {
  arr: listBillingRatesItems[];
  col_variable: col_variable;
  isAscending: boolean;
}
const ProductSetup: React.FC<ProductSetupProps> = ({
  appStore,
}: ProductSetupProps) => {
  //creating zebra striped table rows

  const StyledTableRowSMTP = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
      },
    }),
  )(TableRow);
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        width: "100%",
        cursor: "pointer",
      },
    }),
  )(TableRow);
  const classes = useStyles();

  const initialIsAscending: isAscendingType[] = [
    "default",
    "default",
    "default",
  ];
  interface OderedState {
    check: boolean;
    direction: "up" | "down";
  }

  const [billingRateItems, setBillingRateItems] = useState<
    listBillingRatesItems[]
  >([]);
  const [rateSupportItems, setRateSupportItems] = useState<
    billing_rate_support_items | undefined
  >(undefined);
  const [productData, setProductData] = useState<
    listBillingRatesItems | undefined
  >(undefined);

  const [loading, setLoading] = useState(false);
  const [openListProductOrgs, setOpenListProductOrgs] = useState(false);
  const [openClone, setOpenClone] = useState(false);
  const [openAddProductOrg, setOpenAddProductOrg] = useState(false);
  const [renderValue, setRenderValue] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [openResultMessage, setOpenResultMessage] = useState(false);
  const [openViewProductInfo, setViewProductInfo] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const [isHoveringIndex, setIsHoveringIndex] = useState(-1);
  let arr = new Array<boolean>(8);
  const [downItemsState, setDownItemsState] = useState<boolean[]>(
    arr.fill(false),
  );
  const [upItemsState, setUpItemsState] = useState<boolean[]>(arr.fill(false));
  const [isAscending, setIsAscending] =
    useState<isAscendingType[]>(initialIsAscending);
  const [filterVariable, setFilterVariable] = useState<col_variable>("");
  const closeClone = () => {
    setOpenClone(false);
  };
  const closeEditProduct = () => {
    setOpenEditProduct(false);
  };
  const closeViewProductInfo = () => {
    setViewProductInfo(false);
  };
  const closeListProductOrgs = () => {
    setOpenListProductOrgs(false);
  };
  const closeAddProductOrg = () => {
    setOpenAddProductOrg(false);
  };
  const closeResultMessageModal = () => {
    setOpenResultMessage(false);
    setResultMessage("");
  };

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }
  const handleUpdateMarker = (position: number, direction: "up" | "down") => {
    if (direction === "up") {
      const upArray = upItemsState!.map((item, index) =>
        index === position ? true : false,
      );

      setUpItemsState(upArray);
      setDownItemsState(arr.fill(false));
    } else {
      const downArray = downItemsState!.map((item, index) =>
        index === position ? true : false,
      );

      setDownItemsState(downArray);
      setUpItemsState(arr.fill(false));
    }
  };

  const OrderBySorter = (
    col_variable: col_variable,
    isAsce: isAscendingType[],
  ) => {
    if (col_variable === "public_name") {
      if (isAsce[1] === "true") {
        let sortedArray = [...billingRateItems].sort((a, b) =>
          a.public_product_name!.toLowerCase() <
          b.public_product_name!.toLowerCase()
            ? 1
            : -1,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(1, "up");
      } else if (isAsce[1] === "false") {
        let sortedArray = [...billingRateItems].sort((a, b) =>
          a.public_product_name!.toLowerCase() >
          b.public_product_name!.toLowerCase()
            ? 1
            : -1,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(1, "down");
      }
    } else if (col_variable === "billing_rate") {
      if (isAsce[0] === "true") {
        let sortedArray = [...billingRateItems].sort((a, b) =>
          a.product.toLowerCase() < b.product.toLowerCase() ? 1 : -1,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(0, "up");
      } else if (isAsce[0] === "false") {
        let sortedArray = [...billingRateItems].sort((a, b) =>
          a.product.toLowerCase() > b.product.toLowerCase() ? 1 : -1,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(0, "down");
      }
    } else if (col_variable === "classifier") {
      if (isAsce[2] === "true") {
        let sortedArray = [...billingRateItems].sort((a, b) =>
          a.classifier.toLowerCase() < b.classifier.toLowerCase() ? 1 : -1,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(2, "up");
      } else if (isAsce[2] === "false") {
        let sortedArray = [...billingRateItems].sort((a, b) =>
          a.classifier.toLowerCase() > b.classifier.toLowerCase() ? 1 : -1,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(2, "down");
      }
    } else if (col_variable === "data_origin") {
      if (isAsce[3] === "true") {
        let sortedArray = [...billingRateItems].sort((a, b) =>
          a.data_origin.name.toLowerCase() < b.data_origin.name.toLowerCase()
            ? 1
            : -1,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(3, "up");
      } else if (isAsce[3] === "false") {
        let sortedArray = [...billingRateItems].sort((a, b) =>
          a.data_origin.name.toLowerCase() > b.data_origin.name.toLowerCase()
            ? 1
            : -1,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(3, "down");
      }
    } else if (col_variable === "tier") {
      if (isAsce[4] === "true") {
        let sortedArray = [...billingRateItems].sort((a, b) =>
          a.tier.toLowerCase() < b.tier.toLowerCase() ? 1 : -1,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(4, "up");
      } else if (isAsce[4] === "false") {
        let sortedArray = [...billingRateItems].sort((a, b) =>
          a.tier.toLowerCase() > b.tier.toLowerCase() ? 1 : -1,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(4, "down");
      }
    } else if (col_variable === "site_count") {
      if (isAsce[5] === "true") {
        let sortedArray = [...billingRateItems].sort(
          (a, b) => b.enabled_site_count - a.enabled_site_count,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(5, "up");
      } else if (isAsce[5] === "false") {
        let sortedArray = [...billingRateItems].sort(
          (a, b) => a.enabled_site_count - b.enabled_site_count,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(5, "down");
      }
    } else if (col_variable === "group_count") {
      if (isAsce[6] === "true") {
        let sortedArray = [...billingRateItems].sort(
          (a, b) => b.group_count - a.group_count,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(6, "up");
      } else if (isAsce[6] === "false") {
        let sortedArray = [...billingRateItems].sort(
          (a, b) => a.group_count - b.group_count,
        );
        setBillingRateItems(sortedArray);
        handleUpdateMarker(6, "down");
      }
    } else if (col_variable === "id") {
      if (isAsce[7] === "true") {
        let sortedArray = [...billingRateItems].sort((a, b) => b.id - a.id);
        setBillingRateItems(sortedArray);
        handleUpdateMarker(7, "up");
      } else if (isAsce[7] === "false") {
        let sortedArray = [...billingRateItems].sort((a, b) => a.id - b.id);
        setBillingRateItems(sortedArray);
        handleUpdateMarker(7, "down");
      }
    }
  };

  const addProductOrgFunc = async (org_id: number, billing_rate_id: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const addProductOrgResult = await appStore?.functionService.addProductOrg(
        token,
        org_id,
        billing_rate_id,
      );
      if (addProductOrgResult) {
        if (addProductOrgResult?.success) {
          setLoading(false);
          setResultMessage("Group added successfully to the product");
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        } else {
          setLoading(false);
          setResultMessage(addProductOrgResult?.msg);
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        }
      }
    }
  };

  const editProduct = async (
    id: number,
    product: string,
    classifier_id: number,
    tier_id: number,
    product_name_id: number,
    data_origin_id: number,
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const editResult: addBillingOrgResult =
        await appStore?.functionService.editBillingRate(
          token,
          id,
          product,
          classifier_id,
          tier_id,
          product_name_id,
          data_origin_id,
        );
      if (editResult) {
        if (editResult.success) {
          setLoading(false);
          setResultMessage("Product successfully edited");
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        } else {
          setLoading(false);
          setResultMessage(editResult?.msg);
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        }
      }
    }
  };
  const addCloneProduct = async (
    product: string,
    classifier_id: number,
    data_origin_id: number,
    tier_id: number,
    product_name_id: number,
    rate_info: { [key: string]: any },
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const cloneResults: addBillingOrgResult =
        await appStore?.functionService.addBillingRate(
          token,
          tier_id,
          product_name_id,
          data_origin_id,
          rate_info,
          product,
          classifier_id,
        );
      if (cloneResults) {
        if (cloneResults.success) {
          setLoading(false);
          setResultMessage("Product successfully created");
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        } else {
          setLoading(false);
          setResultMessage(cloneResults?.msg);
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        }
      }
    }
  };

  useEffect(() => {
    const fetchBillingRates = async () => {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      const RatesResults: listBillingRates =
        await appStore?.functionService.listBillingRates(token);
      if (RatesResults) {
        if (RatesResults.success) {
          setBillingRateItems(RatesResults.billing_rates);
          setLoading(false);
        }
      }
    };
    const fetchBillingRateSupportItems = async () => {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      const rateSupport: listBillingRateSupportItems =
        await appStore?.functionService.listBillingRateSupportItems(token);
      if (rateSupport) {
        if (rateSupport.success) {
          setRateSupportItems(rateSupport.billing_rate_support_items);
          setLoading(false);
        }
      }
    };
    fetchBillingRates();
    fetchBillingRateSupportItems();
  }, [renderValue]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              marginTop: 15,
              borderRadius: 15,
              overflow: "hidden",
              width: "80%",
            }}
          >
            <TableContainer style={{ maxHeight: 500 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#1f3161",
                      height: "20px",
                      color: "#fff",
                    }}
                  >
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          id
                        </span>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (upItemsState[7] !== true) {
                                OrderBySorter("id", [
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "true",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropUpIcon
                              className={
                                upItemsState[7] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (downItemsState[7] !== true) {
                                OrderBySorter("id", [
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "false",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropDownIcon
                              className={
                                downItemsState[7] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </TableCell>

                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          billing&nbsp;rate
                        </span>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (upItemsState[0] !== true) {
                                OrderBySorter("billing_rate", [
                                  "true",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropUpIcon
                              className={
                                upItemsState[0] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (downItemsState[0] !== true) {
                                OrderBySorter("billing_rate", [
                                  "false",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropDownIcon
                              className={
                                downItemsState[0] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          public&nbsp;name
                        </span>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (upItemsState[1] !== true) {
                                OrderBySorter("public_name", [
                                  "default",
                                  "true",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropUpIcon
                              className={
                                upItemsState[1] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (downItemsState[1] !== true) {
                                OrderBySorter("public_name", [
                                  "default",
                                  "false",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropDownIcon
                              className={
                                downItemsState[1] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          data&nbsp;origin
                        </span>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (upItemsState[3] !== true) {
                                OrderBySorter("data_origin", [
                                  "default",
                                  "default",
                                  "default",
                                  "true",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropUpIcon
                              className={
                                upItemsState[3] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (downItemsState[3] !== true) {
                                OrderBySorter("data_origin", [
                                  "default",
                                  "default",
                                  "default",
                                  "false",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropDownIcon
                              className={
                                downItemsState[3] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          tier
                        </span>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (upItemsState[4] !== true) {
                                OrderBySorter("tier", [
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "true",
                                  "default",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropUpIcon
                              className={
                                upItemsState[4] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (downItemsState[4] !== true) {
                                OrderBySorter("tier", [
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "false",
                                  "default",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropDownIcon
                              className={
                                downItemsState[4] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          site&nbsp;count
                        </span>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (upItemsState[5] !== true) {
                                OrderBySorter("site_count", [
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "true",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropUpIcon
                              className={
                                upItemsState[5] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (downItemsState[5] !== true) {
                                OrderBySorter("site_count", [
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "false",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropDownIcon
                              className={
                                downItemsState[5] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          group&nbsp;count
                        </span>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (upItemsState[6] !== true) {
                                OrderBySorter("group_count", [
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "true",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropUpIcon
                              className={
                                upItemsState[6] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (downItemsState[6] !== true) {
                                OrderBySorter("group_count", [
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "false",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropDownIcon
                              className={
                                downItemsState[6] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          classifier
                        </span>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (upItemsState[2] !== true) {
                                OrderBySorter("classifier", [
                                  "default",
                                  "default",
                                  "true",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropUpIcon
                              className={
                                upItemsState[2] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (downItemsState[2] !== true) {
                                OrderBySorter("classifier", [
                                  "default",
                                  "default",
                                  "false",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                  "default",
                                ]);
                              }
                            }}
                          >
                            <ArrowDropDownIcon
                              className={
                                downItemsState[2] === true
                                  ? classes.marker_fill
                                  : classes.non_marker_fill
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Typography
                        style={{ color: "#3f51b5" }}
                        className={classes.bold_text_lower}
                      >
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billingRateItems &&
                    billingRateItems.map((item, index) => {
                      return (
                        <StyledTableRow
                          onMouseEnter={() => setIsHoveringIndex(index)}
                          onMouseLeave={() => setIsHoveringIndex(-1)}
                        >
                          <TableCell>
                            <Typography
                              className={classes.bold_text}
                              // style={{ color: 'grey' }}
                            >
                              {item.id}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={classes.bold_text}
                              // style={{ color: 'grey' }}
                            >
                              {item.product}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={classes.bold_text}
                              // style={{ color: 'grey' }}
                            >
                              {item.public_product_name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={classes.bold_text_lower}
                              // style={{ color: 'grey' }}
                            >
                              {item.data_origin.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={classes.bold_text_lower}
                              // style={{ color: "grey" }}
                            >
                              {item.tier}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={classes.bold_text_lower}
                              // style={{ color: 'grey' }}
                            >
                              {item.enabled_site_count}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={classes.bold_text_lower}
                              // style={{ color: 'grey' }}
                            >
                              {item.group_count}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={classes.bold_text_lower}
                              // style={{ color: 'grey' }}
                            >
                              {item.classifier}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.bold_text_lower}>
                              <ProductSetupExpansion
                                list={true}
                                add={true}
                                view={false}
                                edit={true}
                                clone={true}
                                handleClone={() => {
                                  setProductData(item);
                                  setOpenClone(true);
                                }}
                                handleList={() => {
                                  setProductData(item);
                                  setOpenListProductOrgs(true);
                                }}
                                handleAdd={() => {
                                  setProductData(item);
                                  setOpenAddProductOrg(true);
                                }}
                                handleView={() => {
                                  setProductData(item);
                                  setViewProductInfo(true);
                                }}
                                handleEdit={() => {
                                  setProductData(item);
                                  setOpenEditProduct(true);
                                }}
                              />
                            </Typography>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div style={{ width: "18%" }}>
            {billingRateItems &&
              billingRateItems.map((item, index) => {
                if (index === isHoveringIndex) {
                  return (
                    item.rate_info && (
                      <>
                        <Typography
                          align="center"
                          style={{ fontSize: 14, color: "#3f51b5" }}
                          className={classes.bold_text}
                        >
                          Rate info table
                        </Typography>
                        <div
                          style={{
                            boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                            marginTop: 5,
                            borderRadius: 15,
                            overflow: "hidden",
                            width: "100%",
                          }}
                        >
                          <TableContainer style={{ maxHeight: 500 }}>
                            <StyledTableRowSMTP>
                              <TableCell>
                                <Typography
                                  className={classes.bold_text}
                                  style={{ color: "#3f51b5" }}
                                >
                                  rate info description
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  className={classes.bold_text}
                                  style={{ color: "grey" }}
                                >
                                  {item.rate_info.description}
                                </Typography>
                              </TableCell>
                            </StyledTableRowSMTP>
                            <StyledTableRowSMTP>
                              <TableCell>
                                <Typography
                                  className={classes.bold_text}
                                  style={{ color: "#3f51b5" }}
                                >
                                  VAT %
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  className={classes.bold_text}
                                  style={{ color: "grey" }}
                                >
                                  {item.rate_info.VAT_percentage}
                                </Typography>
                              </TableCell>
                            </StyledTableRowSMTP>
                            <StyledTableRowSMTP>
                              <TableCell>
                                <Typography
                                  className={classes.bold_text}
                                  style={{ color: "#3f51b5" }}
                                >
                                  VAT include Status
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  className={classes.bold_text}
                                  style={{ color: "grey" }}
                                >
                                  {item.rate_info.VAT_included.toString()}
                                </Typography>
                              </TableCell>
                            </StyledTableRowSMTP>
                            <StyledTableRowSMTP>
                              <TableCell>
                                <Typography
                                  className={classes.bold_text}
                                  style={{ color: "#3f51b5" }}
                                >
                                  billing cycle
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  className={classes.bold_text}
                                  style={{ color: "grey" }}
                                >
                                  {item.rate_info.billing_cycle}
                                </Typography>
                              </TableCell>
                            </StyledTableRowSMTP>
                            <StyledTableRowSMTP>
                              <TableCell>
                                <Typography
                                  className={classes.bold_text}
                                  style={{ color: "#3f51b5" }}
                                >
                                  currency
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  className={classes.bold_text}
                                  style={{ color: "grey" }}
                                >
                                  {item.rate_info.currency}
                                </Typography>
                              </TableCell>
                            </StyledTableRowSMTP>
                          </TableContainer>
                        </div>
                        <Typography
                          align="center"
                          style={{
                            fontSize: 14,
                            marginTop: 5,
                            color: "#3f51b5",
                          }}
                          className={classes.bold_text}
                        >
                          Volume bands table
                        </Typography>
                        <div
                          style={{
                            boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                            marginTop: 2,
                            borderRadius: 15,
                            overflow: "hidden",
                            width: "100%",
                          }}
                        >
                          <TableContainer style={{ maxHeight: 400 }}>
                            <Table size="small" stickyHeader>
                              <TableHead>
                                <TableRow
                                  style={{
                                    backgroundColor: "#1f3161",
                                    height: "20px",
                                    color: "#fff",
                                  }}
                                >
                                  <TableCell>
                                    <Typography
                                      style={{ color: "#3f51b5" }}
                                      className={classes.bold_text}
                                    >
                                      rate
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      style={{ color: "#3f51b5" }}
                                      className={classes.bold_text}
                                    >
                                      lower
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      style={{ color: "#3f51b5" }}
                                      className={classes.bold_text}
                                    >
                                      upper
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {item.rate_info.volume_bands &&
                                  item.rate_info.volume_bands.map(
                                    (vol_item, vol_index) => {
                                      return (
                                        <StyledTableRow>
                                          <TableCell>
                                            <Typography
                                              className={classes.bold_text}
                                              style={{ color: "grey" }}
                                            >
                                              {vol_item.rate}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography
                                              className={classes.bold_text}
                                              style={{ color: "grey" }}
                                            >
                                              {vol_item.lower}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography
                                              className={classes.bold_text}
                                              style={{ color: "grey" }}
                                            >
                                              {vol_item.upper}
                                            </Typography>
                                          </TableCell>
                                        </StyledTableRow>
                                      );
                                    },
                                  )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </>
                    )
                  );
                }
              })}
          </div>
        </div>
      )}

      <ListProductOrgs
        open={openListProductOrgs}
        onClose={closeListProductOrgs}
        appStore={appStore}
        product={productData}
      />
      <AddProductOrg
        open={openAddProductOrg}
        onClose={closeAddProductOrg}
        appStore={appStore}
        product={productData}
        addProductOrgFunc={(org_id: number, billing_rate_id: number) =>
          addProductOrgFunc(org_id, billing_rate_id)
        }
      />
      <ResultMessage
        open={openResultMessage}
        onClose={closeResultMessageModal}
        message={resultMessage}
      />
      <ViewProductInfo
        open={openViewProductInfo}
        onClose={closeViewProductInfo}
        productData={productData}
      />
      <EditProduct
        open={openEditProduct}
        onClose={closeEditProduct}
        productData={productData}
        rateSupportItems={rateSupportItems!}
        billingRateItems={billingRateItems}
        appStore={appStore}
        editProduct={(
          id: number,
          product: string,
          classifier_id: number,
          tier_id: number,
          product_name_id: number,
          data_origin_id: number,
        ) =>
          editProduct(
            id,
            product,
            classifier_id,
            tier_id,
            product_name_id,
            data_origin_id,
          )
        }
      />
      <CloneProduct
        open={openClone}
        onClose={closeClone}
        productData={productData}
        rateSupportItems={rateSupportItems!}
        billingRateItems={billingRateItems}
        appStore={appStore}
        addCloneProduct={(
          product: string,
          classifier_id: number,
          data_origin_id: number,
          tier_id: number,
          product_name_id: number,
          rate_info: { [key: string]: any },
        ) =>
          addCloneProduct(
            product,
            classifier_id,
            data_origin_id,
            tier_id,
            product_name_id,
            rate_info,
          )
        }
      />
    </div>
  );
};

export default ProductSetup;
