import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import { Org } from "../../../services/FunctionService";

import { TextField } from "@material-ui/core";

interface EditOrgModalProps {
  // elements:React.ReactNode[]|undefined;
  open: boolean;
  onClose: () => void;
  appStore?: AppStore;
  editOrgFunc: (org_id: number, org_name: string) => void;
  org: Org | undefined;
}
const EditOrgModal: React.FC<EditOrgModalProps> = ({
  open,
  appStore,
  editOrgFunc,
  org,
  onClose,
}: EditOrgModalProps) => {
  const classes = useStyles();
  const [renderValue, setRenderValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputDescription, setInputDescription] = useState<string | undefined>(
    "",
  );
  const [textError, setTextError] = useState(false);
  const [message, setMessage] = useState("");
  const handleClose = () => {
    onClose();
  };
  const handleInputDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    let letterNumber = /^[0-9a-zA-Z_ -]+$/;
    if (event.target.value.match(letterNumber)) {
      setTextError(false);
      setMessage("");
      setInputDescription(event.target.value);
    } else {
      setMessage(
        "Name must only contain numbers,letters or the following characters: _-",
      );
      setInputDescription(event.target.value);
      setTextError(true);
    }
  };
  const handleOk = () => {
    onClose();
  };
  useEffect(() => {
    if (org) {
      setInputDescription(org.org_name);
    }
  }, [org, renderValue]);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.modal_dialog_wide}
      fullWidth={true}
    >
      <DialogTitle className={classes.bold_text}>
        Change Group name for {org ? org.org_name : ""}
      </DialogTitle>
      <TextField
        variant="outlined"
        margin="normal"
        placeholder={org?.org_name}
        label="edit group name"
        value={inputDescription}
        onChange={handleInputDescriptionChange}
        defaultValue={org?.org_name}
        error={textError}
        fullWidth
        helperText={textError === true ? message : ""}
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      <div className={classes.twoButtonsInARow}>
        <Button
          variant="contained"
          className="save"
          onClick={() => {
            setInputDescription("");
            handleClose();
            setRenderValue((value) => !value);
          }}
        >
          Dismiss
        </Button>
        <Button
          variant="contained"
          className="save"
          onClick={() => {
            if (
              inputDescription !== "" &&
              inputDescription !== org?.org_name &&
              !textError
            ) {
              editOrgFunc(org!.org_id, inputDescription!);
              setRenderValue((value) => !value);
              setInputDescription("");
              onClose();
            } else if (inputDescription === "") {
              setTextError(true);
              setMessage("Group name cannot be empty");
            } else if (inputDescription === org?.org_name) {
              setTextError(true);
              setMessage("Group name still the same");
            } else if (textError) {
              setTextError(true);
              setMessage(
                "Name must only contain numbers,letters or the following characters: _-",
              );
            }
          }}
        >
          Update
        </Button>
      </div>
    </Dialog>
  );
};

export default EditOrgModal;
