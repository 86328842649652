import React, { useState, useEffect } from "react";
import { TextField, Button, Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../styling/global/global";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import {
  UpdateResult,
  AlertEndPoint,
  GetAlertEndPoints,
} from "../../../../services/FunctionService";
interface EditCredentialsProps {
  appStore?: AppStore;
  alert_channel_id: number;
  alert_channel_end_point_id: number;
  postResult: boolean;
  handleSetPostresult: () => void;
  handleNext: () => void;
  setPaginationTotal: (pagination_total: number) => void;
  handleClose: () => void;
}

const EditCredentials: React.FC<EditCredentialsProps> = ({
  appStore,
  alert_channel_id,
  handleClose,
  alert_channel_end_point_id,
  postResult,
  handleSetPostresult,
  handleNext,
  setPaginationTotal,
}: EditCredentialsProps) => {
  const classes = useStyles();

  const [serverUrl, setServerUrl] = useState("");
  const [PartnerKey, setPartnerKey] = useState("");
  const [PartnerSecret, setPartnerSecret] = useState("");
  const [serverError, setServerError] = useState(false);
  const [keyError, setKeyError] = useState(false);
  const [secretError, setSecretError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [socketServer, setSocketServer] = useState("");
  const [socketPort, setSocketPort] = useState("");
  const [socketServerError, setSocketServerError] = useState(false);
  const [socketPortError, setSocketPortError] = useState(false);
  const [currentAlertEndpoint, setCurrentAlertEndpoint] = useState<
    AlertEndPoint | undefined
  >(undefined);
  const [testResult, setTestResult] = useState(false);
  const [multipleVisit, setMultipleVisit] = useState(false);
  const [localPaginationTotal, setLocalPaginationtotal] = useState(0);
  const [resultFailure, setResultFailure] = useState(false);
  const [resultSuccess, setResultSuccess] = useState(false);

  const handleServerUrlChange = (event: React.ChangeEvent<any>) => {
    setServerError(false);
    setServerUrl(event.target.value);
  };

  const handlePartnerKeyChange = (event: React.ChangeEvent<any>) => {
    setKeyError(false);
    setPartnerKey(event.target.value);
  };

  const handlePartnerSecretChange = (event: React.ChangeEvent<any>) => {
    setSecretError(false);
    setPartnerSecret(event.target.value);
  };
  const handleSocketServerChange = (event: React.ChangeEvent<any>) => {
    setSocketServerError(false);
    setSocketServer(event.target.value);
  };
  const handleSocketPortChange = (event: React.ChangeEvent<any>) => {
    setSocketPortError(false);
    setSocketPort(event.target.value);
  };

  const getSingleHikEndPoint = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const hikEndpointsResults: GetAlertEndPoints =
        await appStore?.functionService.getSingleAlertEndPoint(
          token,
          alert_channel_id,
          alert_channel_end_point_id,
        );
      if (hikEndpointsResults) {
        if (hikEndpointsResults.success) {
          setCurrentAlertEndpoint(hikEndpointsResults.alert_endpoints[0]);
          if (hikEndpointsResults.alert_endpoints[0].meta) {
            let newMeta = hikEndpointsResults.alert_endpoints[0].meta;

            if (newMeta["api_key"]) {
              setPartnerKey(newMeta["api_key"]);
            }
            if (newMeta["api_secret"]) {
              setPartnerSecret(newMeta["api_secret"]);
            }
            if (newMeta["server_url"]) {
              setServerUrl(newMeta["server_url"]);
            }
            if (newMeta["event_server"]) {
              setSocketServer(newMeta["event_server"]);
            }
            if (newMeta["event_port"]) {
              setSocketPort(newMeta["event_port"]);
            }
          }
          if (token) {
            const hikResult =
              await appStore?.functionService.listHikCentralEntities(
                token,
                alert_channel_end_point_id,
                "camera",
                1,
                10,
              );
            if (hikResult) {
              if (hikResult.success) {
                setLocalPaginationtotal(
                  Math.ceil(
                    hikResult.msg.data.total / hikResult.msg.data.pageSize,
                  ),
                );

                setTestResult(true);
                setResultSuccess(true);
                setLoading(false);
                setMultipleVisit(true);
              } else {
                setMultipleVisit(true);
                setTestResult(false);
                setResultFailure(true);
                setLoading(false);
              }
            }
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };
  const fetchHikCentralListingTest = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    setLoading(true);
    if (token) {
      const hikResult = await appStore?.functionService.listHikCentralEntities(
        token,
        alert_channel_end_point_id,
        "camera",
        1,
        10,
      );
      if (hikResult) {
        if (hikResult.success) {
          setLocalPaginationtotal(
            Math.ceil(hikResult.msg.data.total / hikResult.msg.data.pageSize),
          );
          if (token) {
            const hikResult =
              await appStore?.functionService.listHikCentralEntities(
                token,
                alert_channel_end_point_id,
                "camera",
                1,
                10,
              );
            if (hikResult) {
              if (hikResult.success) {
                setLocalPaginationtotal(
                  Math.ceil(
                    hikResult.msg.data.total / hikResult.msg.data.pageSize,
                  ),
                );

                setTestResult(true);
                setResultSuccess(true);
                setLoading(false);
                setMultipleVisit(true);
              } else {
                setMultipleVisit(true);
                setTestResult(false);
                setResultFailure(true);
                setLoading(false);
              }
            }
          }
          setTestResult(true);
          setLoading(false);
          setMultipleVisit(true);
        } else {
          setMultipleVisit(true);
          setTestResult(false);
          setLoading(false);
        }
      }
    }
  };
  const handleTest = () => {
    fetchHikCentralListingTest();
  };
  const handleNextStep = () => {
    setPaginationTotal(localPaginationTotal);
    handleSetPostresult();
    handleNext();
  };
  const editHikCentralEndpoint = async (
    alert_channel_end_point_id: number,
    meta: { [key: string]: any },
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const hikEndpointResult: UpdateResult =
        await appStore?.functionService.modifyAlertEndPoint(
          token,
          alert_channel_end_point_id,
          meta,
        );
      if (hikEndpointResult) {
        if (hikEndpointResult.success) {
          setLoading(false);
          //handleSetPostresult();
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };
  const handleEditEndpoint = () => {
    if (
      serverUrl !== "" &&
      PartnerSecret !== "" &&
      PartnerKey !== "" &&
      socketServer !== "" &&
      socketPort !== ""
    ) {
      let meta: { [key: string]: any } = {};
      meta["api_key"] = PartnerKey;
      meta["api_secret"] = PartnerSecret;
      meta["server_url"] = serverUrl;
      meta["event_server"] = socketServer;
      meta["event_port"] = +socketPort;

      editHikCentralEndpoint(alert_channel_end_point_id, meta);
    } else if (serverUrl === "") {
      setServerError(true);
    } else if (PartnerKey === "") {
      setKeyError(true);
    } else if (PartnerSecret === "") {
      setSecretError(true);
    } else if (socketPort === "") {
      setSocketPortError(true);
    } else if (socketServer === "") {
      setSocketServerError(true);
    }
  };
  useEffect(() => {
    getSingleHikEndPoint();
  }, [alert_channel_id, alert_channel_end_point_id]);
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
            <TextField
              fullWidth
              error={keyError}
              style={{ marginBottom: 10 }}
              value={PartnerKey}
              helperText={keyError ? "Partner key field cannot be empty" : ""}
              onChange={handlePartnerKeyChange}
              variant="outlined"
              label={
                <span className={classes.bold_text} style={{ color: "black" }}>
                  Partner Key
                </span>
              }
            ></TextField>
            <TextField
              fullWidth
              error={secretError}
              style={{ marginBottom: 10 }}
              value={PartnerSecret}
              helperText={
                secretError ? "Partner secret field cannnot be empty" : ""
              }
              onChange={handlePartnerSecretChange}
              variant="outlined"
              label={
                <span className={classes.bold_text} style={{ color: "black" }}>
                  Partner Secret
                </span>
              }
            ></TextField>
            <TextField
              fullWidth
              error={serverError}
              style={{ marginBottom: 10 }}
              value={serverUrl}
              helperText={serverError ? "ServerUrl field cannot be empty" : ""}
              onChange={handleServerUrlChange}
              variant="outlined"
              label={
                <span className={classes.bold_text} style={{ color: "black" }}>
                  Server Url
                </span>
              }
            ></TextField>
            <TextField
              fullWidth
              style={{ marginBottom: 10 }}
              value={socketServer}
              error={socketServerError}
              helperText={
                socketServerError ? "Socket server field cannot be empty" : ""
              }
              onChange={handleSocketServerChange}
              variant="outlined"
              label={
                <span className={classes.bold_text} style={{ color: "black" }}>
                  Event socket server
                </span>
              }
            ></TextField>
            <TextField
              fullWidth
              placeholder="15300"
              type={"number"}
              style={{ marginBottom: 10 }}
              value={socketPort}
              error={socketPortError}
              helperText={
                socketPortError ? "Socket port field cannot be empty" : ""
              }
              onChange={handleSocketPortChange}
              variant="outlined"
              label={
                <span className={classes.bold_text} style={{ color: "black" }}>
                  Event socket port
                </span>
              }
            ></TextField>
          </div>
          {resultFailure ? (
            <Box mt={2} mb={2} justifyContent="center">
              <Alert variant="outlined" severity="error">
                Authentication failed, please check credentials
              </Alert>
            </Box>
          ) : null}
          {resultSuccess ? (
            <Box mt={2} mb={2} justifyContent="center">
              <Alert variant="outlined" severity="success">
                Authentication successful, please proceed to the next step
              </Alert>
            </Box>
          ) : null}

          <div
            style={{ marginBottom: 10, marginTop: 10, width: "100%" }}
            className={classes.twoButtonsInARow}
          >
            <Button
              variant="contained"
              className={classes.mute_button}
              color="primary"
              onClick={handleClose}
            >
              Close
            </Button>

            <Button
              variant="contained"
              // className='save'
              onClick={handleEditEndpoint}
              className={classes.mute_button}
              color="primary"
            >
              Save&nbsp;and&nbsp;Test
            </Button>

            <Button
              variant="contained"
              // className='save'
              className={classes.mute_button}
              color="primary"
              onClick={handleNextStep}
            >
              Next&nbsp;step
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default EditCredentials;
