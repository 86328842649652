import React, { useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

import { AppStore } from "../../../../stores/AppStore";
import {
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
} from "@material-ui/core";
import { EntryPoint, Org } from "../../../../services/FunctionService";
interface AddEntryOrgToUserModalProps {
  appStore?: AppStore;
  user_id: number;
  onClose: () => void;
  open: boolean;
  user_name: string;
  initialentrypoints: EntryPoint[];
  saveEntryOrgsForUser: (
    user_id: number,
    selectedorgs: { [key: number]: any },
  ) => void;
}
const AddEntryOrgToUserModal: React.FC<AddEntryOrgToUserModalProps> = ({
  appStore,
  user_id,
  user_name,
  onClose,
  open,
  saveEntryOrgsForUser,
  initialentrypoints,
}: AddEntryOrgToUserModalProps) => {
  const [orgs, setOrgs] = React.useState<Org[]>([]);
  const [selectedorgs, setSelectedOrgs] = React.useState<
    { [key: string]: any } | undefined
  >(undefined);
  const [entrypoints, setEntryPoints] = React.useState<
    EntryPoint[] | undefined
  >(undefined);
  //get roles for selected endpoint_type
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        const listorgs = await appStore?.functionService.getListOrgs(token);
        if (listorgs?.success) {
          setOrgs(listorgs.orgs);
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var entrypoints = await appStore?.functionService.getUserEntryPoints(
          token,
          user_id,
        );
        setEntryPoints(entrypoints);
      }
    };
    fetchData();
  }, [user_id || open]);
  const handleSave = () => {
    onClose();
    saveEntryOrgsForUser(user_id, selectedorgs!);
    setSelectedOrgs(undefined);
  };

  const closeModal = () => {
    setSelectedOrgs(undefined);
    onClose();
  };
  const handleOrgSelect = (event: React.ChangeEvent<any>) => {
    selectedorgs![event.target.value] = !selectedorgs![event.target.value];
    setSelectedOrgs(selectedorgs);
  };
  const getOrgs = () => {
    orgs.forEach((org) => {
      const existing = initialentrypoints.find((x) => x.org_id === org.org_id);
      if (!existing) {
        var checked = false;
        if (selectedorgs) {
          checked = selectedorgs![org.org_id];
        }
        tree_list.push(
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleOrgSelect}
                value={org.org_id}
              />
            }
            label={org.org_name + " (Group ID: " + org.org_id + ")"}
          />,
        );
      }
    });
  };
  if (entrypoints && selectedorgs == undefined) {
    var initialselectedorgs: { [key: number]: any } = {};
    entrypoints?.forEach((entrypoint) => {
      if (entrypoint.org_id == 0) {
        initialselectedorgs[entrypoint.org_id] = true;
      }
    });
    setSelectedOrgs(initialselectedorgs);
  }
  var tree_display: React.ReactNode[] = [];
  var tree_list: React.ReactNode[] = [];
  if (orgs && initialentrypoints) {
    getOrgs();
    if (tree_list.length > 0) {
      tree_display.push(
        <>
          <FormLabel component="legend">Select Entry Points</FormLabel>
          <FormGroup>{tree_list}</FormGroup>{" "}
        </>,
      );
    }
  }
  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Add new entry groups to {user_name}
      </DialogTitle>
      <div className="add-user-text-field-container">
        <form noValidate autoComplete="off">
          {tree_display}
        </form>
      </div>
      <br />
      <div className="button-container-add-user">
        <Button
          variant="contained"
          className="add-user-cancel-button"
          onClick={closeModal}
        >
          <span className="button-text">CANCEL</span>
        </Button>
        &nbsp; &nbsp;{" "}
        <Button
          variant="contained"
          color="primary"
          className="add-user-save-button"
          onClick={handleSave}
        >
          <span className="button-text">SAVE</span>
        </Button>
        <br />
      </div>
    </Dialog>
  );
};
export default AddEntryOrgToUserModal;
