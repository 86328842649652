import React, { useState, useEffect } from "react";
import { TextField, Button, Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../styling/global/global";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import {
  CreateAlertEndPointResult,
  UpdateResult,
  hikCentralListItems,
  GetAlertEndPoints,
} from "../../../../services/FunctionService";

export interface CredentialsProps {
  appStore?: AppStore;
  alert_channel_id: number;
  handleClose: () => void;
  setChannelEndpointId: (alert_channel_endpoint: number) => void;
  setPaginationTotal: (pagination_total: number) => void;
  alert_channel_end_point: number;
  postResult: boolean;
  handleSetPostresult: () => void;
  handleNext: () => void;
  multiple_visit: boolean;
  setMultiple_Visit: () => void;
}

const Credentials: React.FC<CredentialsProps> = ({
  appStore,
  alert_channel_id,
  handleClose,
  setChannelEndpointId,
  alert_channel_end_point,
  postResult,
  handleSetPostresult,
  handleNext,
  setPaginationTotal,
  multiple_visit,
  setMultiple_Visit,
}: CredentialsProps) => {
  const [serverUrl, setServerUrl] = useState("");
  const [PartnerKey, setPartnerKey] = useState("");
  const [PartnerSecret, setPartnerSecret] = useState("");
  const [serverError, setServerError] = useState(false);
  const [keyError, setKeyError] = useState(false);
  const [secretError, setSecretError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [socketServer, setSocketServer] = useState("");
  const [socketPort, setSocketPort] = useState("15300");
  const [socketServerError, setSocketServerError] = useState(false);
  const [socketPortError, setSocketPortError] = useState(false);
  const [localChannelEndpointId, setLocalChannelEndpointId] = useState(0);
  const [localPaginationTotal, setLocalPaginationtotal] = useState(0);
  const [, setListItems] = useState<hikCentralListItems[] | undefined>(
    undefined,
  );
  const [testResult, setTestResult] = useState(false);
  const [multipleVisit, setMultipleVisit] = useState(false);
  const [resultFailure, setResultFailure] = useState(false);
  const [resultSuccess, setResultSuccess] = useState(false);

  const classes = useStyles();

  const getSingleHikEndPoint = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const hikEndpointsResults: GetAlertEndPoints =
        await appStore?.functionService.getSingleAlertEndPoint(
          token,
          alert_channel_id,
          alert_channel_end_point,
        );
      if (hikEndpointsResults) {
        if (hikEndpointsResults.success) {
          if (hikEndpointsResults.alert_endpoints[0].meta) {
            let newMeta = hikEndpointsResults.alert_endpoints[0].meta;

            if (newMeta["api_key"]) {
              setPartnerKey(newMeta["api_key"]);
            }
            if (newMeta["api_secret"]) {
              setPartnerSecret(newMeta["api_secret"]);
            }
            if (newMeta["server_url"]) {
              setServerUrl(newMeta["server_url"]);
            }
            if (newMeta["event_server"]) {
              setSocketServer(newMeta["event_server"]);
            }
            if (newMeta["event_port"]) {
              setSocketPort(newMeta["event_port"]);
            }
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };
  const createHikCentralEndpoint = async (meta: { [key: string]: any }) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const hikEndpointResult: CreateAlertEndPointResult =
        await appStore?.functionService.createAlertEndPointForHikCentral(
          token,
          alert_channel_id,
          "hikcentral",
          meta,
        );
      if (hikEndpointResult) {
        if (hikEndpointResult.success) {
          setLocalChannelEndpointId(
            hikEndpointResult.alert_channel_end_point_id,
          );
          if (token) {
            const hikResult =
              await appStore?.functionService.listHikCentralEntities(
                token,
                hikEndpointResult.alert_channel_end_point_id,
                "camera",
                1,
                10,
              );
            if (hikResult) {
              if (hikResult.success) {
                setLocalPaginationtotal(
                  Math.ceil(
                    hikResult.msg.data.total / hikResult.msg.data.pageSize,
                  ),
                );
                setListItems(hikResult.msg.data.list);
                setTestResult(true);
                setResultSuccess(true);
                setResultFailure(false);
                setLoading(false);
                setMultipleVisit(true);
              } else {
                setMultipleVisit(true);
                setTestResult(false);
                setResultSuccess(false);
                setResultFailure(true);
                setLoading(false);
              }
            }
          }

          setLoading(false);
          // handleSetPostresult();
          // handleNext();
        } else {
          setLoading(false);
        }
      }
    }
  };
  const editHikCentralEndpoint = async (
    alert_channel_end_point_id: number,
    meta: { [key: string]: any },
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const hikEndpointResult: UpdateResult =
        await appStore?.functionService.modifyAlertEndPoint(
          token,
          alert_channel_end_point_id,
          meta,
        );
      if (hikEndpointResult) {
        if (hikEndpointResult.success) {
          if (token) {
            const hikResult =
              await appStore?.functionService.listHikCentralEntities(
                token,
                localChannelEndpointId,
                "camera",
                1,
                10,
              );
            if (hikResult) {
              if (hikResult.success) {
                setLocalPaginationtotal(
                  Math.ceil(
                    hikResult.msg.data.total / hikResult.msg.data.pageSize,
                  ),
                );
                setListItems(hikResult.msg.data.list);
                setTestResult(true);
                setResultSuccess(true);
                setLoading(false);
                setMultipleVisit(true);
              } else {
                setMultipleVisit(true);
                setTestResult(false);
                setResultFailure(true);
                setLoading(false);
              }
            }
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };
  const fetchHikCentralListingTest = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    setLoading(true);
    if (token) {
      const hikResult = await appStore?.functionService.listHikCentralEntities(
        token,
        localChannelEndpointId,
        "camera",
        1,
        10,
      );
      if (hikResult) {
        if (hikResult.success) {
          setLocalPaginationtotal(
            Math.ceil(hikResult.msg.data.total / hikResult.msg.data.pageSize),
          );
          setListItems(hikResult.msg.data.list);
          setTestResult(true);
          setResultSuccess(true);
          setResultFailure(false);
          setLoading(false);
          setMultipleVisit(true);
        } else {
          setMultipleVisit(true);
          setTestResult(false);
          setResultFailure(true);
          setResultSuccess(false);
          setLoading(false);
        }
      }
    }
  };

  const handleServerUrlChange = (event: React.ChangeEvent<any>) => {
    setServerError(false);
    setSocketServerError(false);
    setServerUrl(event.target.value);
    setSocketServer(event.target.value);
  };

  const handlePartnerKeyChange = (event: React.ChangeEvent<any>) => {
    setKeyError(false);
    setPartnerKey(event.target.value);
  };

  const handlePartnerSecretChange = (event: React.ChangeEvent<any>) => {
    setSecretError(false);
    setPartnerSecret(event.target.value);
  };
  const handleSocketServerChange = (event: React.ChangeEvent<any>) => {
    setSocketServerError(false);
    setSocketServer(event.target.value);
  };
  const handleSocketPortChange = (event: React.ChangeEvent<any>) => {
    setSocketPortError(false);
    setSocketPort(event.target.value);
  };

  const handleCreateOrEditEndpoint = () => {
    if (
      serverUrl !== "" &&
      PartnerSecret !== "" &&
      PartnerKey !== "" &&
      socketServer !== "" &&
      socketPort !== ""
    ) {
      let meta: { [key: string]: any } = {};
      meta["api_key"] = PartnerKey;
      meta["api_secret"] = PartnerSecret;
      meta["server_url"] = serverUrl;
      meta["event_server"] = socketServer;
      meta["event_port"] = +socketPort;

      if (postResult) {
        if (alert_channel_end_point !== 0) {
          editHikCentralEndpoint(alert_channel_end_point, meta);
        }
      } else if (localChannelEndpointId !== 0) {
        editHikCentralEndpoint(localChannelEndpointId, meta);
      } else {
        createHikCentralEndpoint(meta);
      }
    } else if (serverUrl === "") {
      setServerError(true);
    } else if (PartnerKey === "") {
      setKeyError(true);
    } else if (PartnerSecret === "") {
      setSecretError(true);
    } else if (socketPort === "") {
      setSocketPortError(true);
    } else if (socketServer === "") {
      setSocketServerError(true);
    }
  };
  const handleTest = () => {
    fetchHikCentralListingTest();
  };
  const handleNextStep = () => {
    setChannelEndpointId(localChannelEndpointId);
    setPaginationTotal(localPaginationTotal);
    handleSetPostresult();
    setMultiple_Visit();
    handleNext();
  };
  useEffect(() => {
    if (alert_channel_end_point !== 0) {
      getSingleHikEndPoint();
    }
  }, [alert_channel_end_point, multiple_visit]);
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
            <TextField
              fullWidth
              error={keyError}
              style={{ marginBottom: 10 }}
              value={PartnerKey}
              helperText={keyError ? "Partner key field cannot be empty" : ""}
              onChange={handlePartnerKeyChange}
              variant="outlined"
              label={
                <span className={classes.bold_text} style={{ color: "black" }}>
                  Partner Key
                </span>
              }
            ></TextField>
            <TextField
              fullWidth
              error={secretError}
              style={{ marginBottom: 10 }}
              value={PartnerSecret}
              helperText={
                secretError ? "Partner secret field cannnot be empty" : ""
              }
              onChange={handlePartnerSecretChange}
              variant="outlined"
              label={
                <span className={classes.bold_text} style={{ color: "black" }}>
                  Partner Secret
                </span>
              }
            ></TextField>
            <TextField
              fullWidth
              error={serverError}
              style={{ marginBottom: 10 }}
              value={serverUrl}
              helperText={serverError ? "ServerUrl field cannot be empty" : ""}
              onChange={handleServerUrlChange}
              variant="outlined"
              label={
                <span className={classes.bold_text} style={{ color: "black" }}>
                  Server Url
                </span>
              }
              onBlur={() => {
                let arrStr = serverUrl.split(":")[1];
                if (arrStr) {
                  let wanted = arrStr.substring(2);
                  setSocketServer(wanted);
                }
              }}
            ></TextField>
            <TextField
              fullWidth
              style={{ marginBottom: 10 }}
              value={socketServer}
              error={socketServerError}
              helperText={
                socketServerError ? "Socket server field cannot be empty" : ""
              }
              onChange={handleSocketServerChange}
              variant="outlined"
              label={
                <span className={classes.bold_text} style={{ color: "black" }}>
                  Event socket server
                </span>
              }
            ></TextField>
            <TextField
              fullWidth
              placeholder="15300"
              type={"number"}
              style={{ marginBottom: 10 }}
              value={socketPort}
              error={socketPortError}
              helperText={
                socketPortError ? "Socket port field cannot be empty" : ""
              }
              onChange={handleSocketPortChange}
              variant="outlined"
              label={
                <span className={classes.bold_text} style={{ color: "black" }}>
                  Event socket port
                </span>
              }
            ></TextField>
          </div>
          {resultFailure ? (
            <Box textAlign="center" mt={2} mb={2}>
              <Alert variant="outlined" severity="error">
                Authentication failed, please check credentials
              </Alert>
            </Box>
          ) : null}
          {resultSuccess ? (
            <Box textAlign="center" mt={2} mb={2}>
              <Alert variant="outlined" severity="success">
                Authentication successful, please proceed to the next step
              </Alert>
            </Box>
          ) : null}
          {/* {localChannelEndpointId === 0 ? null : (
            <Box textAlign='center'>
              <Button
                variant='contained'
                className='save'
                style={{ color: 'blue', marginBottom: 5 }}
                onClick={handleTest}
              >
                Test credentials
              </Button>
            </Box>
          )} */}

          <div
            style={{ marginBottom: 10, marginTop: 10, width: "100%" }}
            className={classes.twoButtonsInARow}
          >
            <Button
              variant="contained"
              className={classes.mute_button}
              color="primary"
              onClick={handleClose}
            >
              Close
            </Button>
            {testResult ? null : (
              <Button
                variant="contained"
                className={classes.mute_button}
                color="primary"
                onClick={handleCreateOrEditEndpoint}
              >
                Save&nbsp;and&nbsp;Test
              </Button>
            )}
            {multiple_visit ? (
              <Button
                variant="contained"
                className={classes.mute_button}
                color="primary"
                onClick={handleNextStep}
              >
                Next&nbsp;step
              </Button>
            ) : resultSuccess || resultFailure ? (
              <Button
                variant="contained"
                className={classes.mute_button}
                color="primary"
                onClick={handleNextStep}
              >
                Next&nbsp;step
              </Button>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default Credentials;
