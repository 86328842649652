import React from "react";
import { TimeEntrySet } from "../../../core/models/TimeEntry";
import TimeEntryPanel from "../../components/TimeEntryUI/TimeEntryPanel";
import { IconButton } from "@material-ui/core";
import { useStyles } from "../../styling/global/global";
import { AddCircle } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import { MainContext } from "../../containers/HomePage/HomePage";
import TextField from "@material-ui/core/TextField";

interface Props {
  startT?: string;
  endT?: string;
  scheduled?: string;
  days?: string[];
  ruleId?: any;
  timeEntrySet?: TimeEntrySet;
  editable?: boolean;
  noEndTime?: boolean;
  isWizard?: boolean;
}

export default function TimeSelector({
  startT,
  endT,
  days,
  scheduled,
  ruleId,
  timeEntrySet,
  editable = true,
  noEndTime = false,
  isWizard,
}: Props) {
  const classes = useStyles();

  const ctx = React.useContext(MainContext);

  const radioVal = !editable ? timeEntrySet : ctx.timeEntrySet;
  const [radioValue, setRadioValue] = React.useState(
    radioVal || ctx.rule_type === "snapshot" ? "schedule" : "always",
  );

  let time = !editable ? timeEntrySet : ctx.timeEntrySet;

  const [timeentryset, setTimeEntrySet] = React.useState<
    TimeEntrySet | undefined
  >(time || undefined);
  const [, setRefresh] = React.useState(0);

  const updateRule = (val: any, key: string) => {
    let foundRule = ctx.rulesList.find((rule) => rule.id === ruleId);
    const newRule = { ...foundRule, [key]: val };
    const filteredRules = ctx.rulesList.filter(
      (rule: any) => rule.id !== ruleId,
    );
    ctx.setRuleList(
      [...filteredRules, newRule].sort((a: any, b: any) => a.id - b.id),
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
    if (event.target.value === "always") {
      if (ruleId) {
        updateRule(false, "scheduled");
      } else {
        ctx.setScheduled(false);
      }
      ctx.setTimeEntrySet(null);
    } else {
      if (ruleId) {
        updateRule(true, "scheduled");
      } else {
        ctx.setScheduled(true);
      }
    }
  };

  const addSchedule = () => {
    if (timeentryset) {
      timeentryset.addTimeEntry();
      setTimeEntrySet(timeentryset);
    } else {
      const newtimeentrySet = new TimeEntrySet("");
      newtimeentrySet.addTimeEntry();
      setTimeEntrySet(newtimeentrySet);
    }
    setRefresh(new Date().getTime());
  };

  const formattedTime = (time: TimeEntrySet) => {
    var valid_days_and_times1 = "[";
    if (time) {
      time.entries.forEach((timeEntry) => {
        var thisentry =
          valid_days_and_times1 == "["
            ? timeEntry.timeString
            : "," + timeEntry.timeString;
        valid_days_and_times1 = valid_days_and_times1 + thisentry;
      });
    }
    return valid_days_and_times1 + "]";
  };

  const changeTimeEntry = (entrySet: TimeEntrySet | undefined) => {
    setTimeEntrySet(entrySet);

    if (entrySet) ctx.setTimeEntrySet(entrySet!);

    //if deleted last one, make sure to add add button
    if (entrySet?.entries.length == 0) {
      setRefresh(new Date().getTime());
    }
  };

  var schedule: React.ReactNode[] = [];
  if (true) {
    if (true) {
      schedule.push(
        <TimeEntryPanel
          horisontal={false}
          key={"OVERRIDE1"}
          addicon={true}
          noEndTime={noEndTime}
          disabled={!editable}
          entrySet={timeentryset}
          changeTimeEntryParent={(entrySet: TimeEntrySet | undefined) =>
            changeTimeEntry(entrySet)
          }
        ></TimeEntryPanel>,
      );
    }
  }

  React.useEffect(() => {
    if (radioValue === "schedule" && editable) {
      addSchedule();
    }
  }, [radioValue]);

  return (
    <>
      {ctx.timeZone !== "" && (
        <div style={isWizard ? { width: "100%" } : {}}>
          {isWizard ? (
            <TextField
              label="Timezone"
              disabled={true}
              margin="normal"
              autoFocus
              value={ctx.timeZone}
              style={{ width: "99%", marginLeft: 10, marginRight: 10 }}
            />
          ) : (
            <TextField
              label="Timezone"
              variant="outlined"
              disabled={true}
              margin="normal"
              autoFocus
              value={ctx.timeZone}
              style={{ width: "300px" }}
            />
          )}
          <br></br>
        </div>
      )}
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="schedule"
          name="schedule"
          value={radioValue}
          onChange={handleChange}
        >
          {ctx.rule_type !== "snapshot" && (
            <FormControlLabel
              value="always"
              control={<Radio />}
              label="Always active"
              disabled={!editable || ctx.rule_type === "snapshot"}
            />
          )}
          <FormControlLabel
            value="schedule"
            control={<Radio />}
            label="Schedule"
            disabled={!editable}
          />
        </RadioGroup>
      </FormControl>
      <br />
      <br />
      {radioValue === "schedule" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "300px" }}>{schedule}</div>
        </div>
      )}
      {!timeentryset && ctx.scheduled && editable && (
        <div className={classes.form_component}>
          <div className={classes.bold_text}>
            Add Schedule
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => {
                addSchedule();
              }}
            >
              <AddCircle color={"primary"} />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
}
