export const ValueChecking = (
  frontValue: string,
  backValue: string,
): boolean => {
  var frontVarThird = parseInt(frontValue.split(".")[2]);
  var frontVarSecond = parseInt(frontValue.split(".")[1]);
  var frontVarFirst = parseInt(frontValue.split(".")[0]);
  var backVarThird = parseInt(backValue.split(".")[2]);
  var backVarSecond = parseInt(backValue.split(".")[1]);
  var backVarfirst = parseInt(backValue.split(".")[0]);
  if (frontVarFirst > backVarfirst) {
    return true;
  } else if (frontVarFirst === backVarfirst) {
    if (frontVarSecond > backVarSecond) {
      return true;
    } else if (frontVarSecond === backVarSecond) {
      if (frontVarThird >= backVarThird) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// export const getBaseUrl = (): string => {
//   return "/api/v.3"
// };

export const getBaseUrl = (): string => {
  let url = window.location.href;
  if (url.includes("local")) {
    return "https://manage.staging.deepalert.ai/api/v1.3";
  } else {
    return "/api/v1.3";
  }
};
