import React, { useState, useEffect } from "react";
import { Box, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Alert from "@material-ui/lab/Alert";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, TextField } from "@material-ui/core";
import {
  Org,
  listBillingRates,
  listBillingRatesItems,
  listClassLabels,
  Locale,
  addBillingOrgResult,
  GetSentinelResult,
} from "../../../services/FunctionService";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

import { time_zones } from "../../../hooks/Timezones";

interface AddGroupToSentinelProps {
  open: boolean;
  appStore?: AppStore;
  org: Org | undefined;
  onClose: () => void;
}
const AddGroupToSentinel: React.FC<AddGroupToSentinelProps> = ({
  open,
  appStore,
  org,
  onClose,
}: AddGroupToSentinelProps) => {
  const StyledTableRowSMTP = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
      },
    }),
  )(TableRow);
  const classes = useStyles();

  const [renderValue, setRenderValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultFailure, setResultFailure] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [openResultMessage, setOpenResultMessage] = useState(false);
  const [timezone, setTimeZone] = React.useState("");
  const [threshold, setThreshold] = React.useState<number>(35);
  const [billingRateItems, setBillingRateItems] = useState<
    listBillingRatesItems[]
  >([]);
  const [selectError, setSelectError] = useState(false);
  const [selectLabelError, setSelectLabelError] = useState(false);
  const [selectTimeError, setSelectTimeError] = useState(false);
  const [labelValue, setLabelValue] = useState<[string]>([""]);
  const [localeValue, setLocaleValue] = useState("");
  const [billing_rate_id, setBilling_rate_id] = React.useState<
    number | undefined
  >(undefined);
  const [classLabels, setClassLabels] = useState<string[]>([]);
  const [locales, setLocales] = useState<Locale[]>([]);
  const [returnNonDetectionVideos, setReturnNonDetectionVideos] =
    useState(false);
  const [selectLocaleError, setSelectLocaleError] = useState(false);
  const [ignoreStationaryVehicles, setIgnoreStationaryVehicles] =
    useState(true);
  const [detectVanishingObjects, setDetectVanishingObjects] =
    useState<boolean>(false);
  const [token, setToken] = useState("");
  const [addToGroup, setAddToGroup] = useState(true);
  const [metaValues, setMetaValues] = useState<{ [key: string]: any }>({});

  const handleLocaleChange = (event: React.ChangeEvent<any>) => {
    setSelectLocaleError(false);
    setLocaleValue(event.target.value);
  };

  const updateProductOrgFunc = async (
    org_id: number,
    meta: { [key: string]: any },
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const updateProductOrgResult =
        await appStore?.functionService.updateOrginInSentinel(
          token,
          org_id,
          meta,
        );
      if (updateProductOrgResult) {
        if (updateProductOrgResult?.success) {
          setLoading(false);
          setResultMessage("Updated successfully");
          setOpenResultMessage(true);
        } else {
          setLoading(false);
          setResultMessage(updateProductOrgResult?.msg);
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        }
      } else {
        setLoading(false);
        setResultFailure(true);
      }
    }
  };

  const addProductOrgFunc = async (
    org_id: number,
    billing_rate_id: number,
    meta: { [key: string]: any },
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const addProductOrgResult: addBillingOrgResult =
        await appStore?.functionService.addOrgToSentinelProduct(
          token,
          org_id,
          billing_rate_id,
          org!.org_name,
          meta,
        );
      if (addProductOrgResult) {
        if (addProductOrgResult?.success) {
          setLoading(false);
          setToken(addProductOrgResult?.msg);
          setResultMessage("Added successfully");
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        } else {
          setLoading(false);
          setResultMessage(addProductOrgResult?.msg);
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        }
      } else {
        setLoading(false);
        setResultFailure(true);
      }
      setLoading(false);
    }
  };
  const handleAddToProduct = () => {
    if (billing_rate_id) {
      if (labelValue.length > 0) {
        if (timezone) {
          if (localeValue) {
            let meta: { [key: string]: any } = {};
            if (addToGroup !== true) {
              meta = metaValues;
            }
            if (meta.hasOwnProperty("defaults")) {
            } else {
              meta["defaults"] = {};
            }
            if (addToGroup === true) {
              if (labelValue.length > 1) {
                // meta["defaults"]["class_labels"] = labelValue.slice(1);
                delete meta["defaults"]["class_labels"];
              } else {
                setSelectLabelError(true);
              }
            } else {
              delete meta.defaults["class_labels"];
              // meta.defaults["class_labels"] = labelValue;
              delete meta.defaults["class_labels"];
            }

            if (labelValue?.join().includes("vehicle")) {
              // meta["defaults"]["additional_inference_options"] = {
              //   ignore_stationary_vehicles: ignoreStationaryVehicles,
              // };
              delete meta["defaults"]["additional_inference_options"];
            } else {
              if (
                meta.defaults.hasOwnProperty("additional_inference_options")
              ) {
                delete meta.defaults["additional_inference_options"];
              }
            }
            meta["defaults"]["billing_rate_id"] = billing_rate_id;
            // meta["defaults"]["confidence_threshold"] = threshold;
            if (meta["defaults"]["confidence_threshold"]) {
              delete meta["defaults"]["confidence_threshold"];
            }
            meta["defaults"]["timezone_str"] = timezone;
            meta["defaults"]["locale"] = localeValue;
            meta["defaults"]["return_non_detection_videos"] =
              returnNonDetectionVideos;
            meta["defaults"]["detect_vanishing_objects"] =
              detectVanishingObjects;
            if (addToGroup === true) {
              addProductOrgFunc(org!.org_id, billing_rate_id!, meta);
            } else {
              updateProductOrgFunc(org!.org_id, meta);
            }
          } else {
            setSelectLocaleError(true);
          }
        } else {
          setSelectTimeError(true);
        }
      } else {
        setSelectLabelError(true);
      }
    } else {
      setSelectError(true);
    }
  };
  const handleTimeZoneChange = (event: React.ChangeEvent<any>) => {
    setSelectTimeError(false);
    setOpenResultMessage(false);
    setResultMessage("");
    setTimeZone(event.target.value);
  };
  const handleChange = (event: React.ChangeEvent<any>) => {
    setSelectError(false);
    setOpenResultMessage(false);
    setResultMessage("");
    setBilling_rate_id(+event.target.value);
    let name = billingRateItems.filter(
      (item) => item.id === +event.target.value,
    )[0].classifier;
    getClassLabels(name);
  };

  const handleLabelsChange = (event: React.ChangeEvent<any>) => {
    setSelectLabelError(false);
    setOpenResultMessage(false);
    setResultMessage("");
    setLabelValue(event.target.value);
  };
  const getClassLabels = async (name: string) => {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const labelResults: listClassLabels =
        await appStore?.functionService.listClassLabels(token, name);
      if (labelResults) {
        if (labelResults.success) {
          setClassLabels(labelResults.class_labels);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };
  const handleThresholdChange = (event: React.ChangeEvent<any>) => {
    setThreshold(+event.target.value);
  };

  var timezones = [];
  timezones.push(
    <MenuItem value="Africa/Johannesburg">
      <span className={classes.bold_text}>Africa/Johannesburg</span>
    </MenuItem>,
  );
  timezones.push(
    <MenuItem value="Europe/London">
      <span className={classes.bold_text}>Europe/London</span>
    </MenuItem>,
  );

  useEffect(() => {
    const fetchBillingRates = async () => {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && org) {
        const RatesResults: listBillingRates =
          await appStore?.functionService.listBillingRates(token);
        if (RatesResults) {
          if (RatesResults.success) {
            setBillingRateItems(
              RatesResults.billing_rates.filter(
                (item) => item.data_origin.name === "sentinel_http_edge_human1",
              ),
            );
            const showToken: GetSentinelResult =
              await appStore?.functionService.getSentinelToken(
                token,
                org!.org_id,
              );
            console.debug("showToken: ", showToken);
            if (showToken) {
              if (showToken.success) {
                setMetaValues(showToken.msg.meta);
                setToken(showToken.msg.token);
                if (showToken.msg.meta.hasOwnProperty("defaults")) {
                  if (
                    showToken.msg.meta["defaults"].hasOwnProperty(
                      "confidence_threshold",
                    )
                  ) {
                    // setThreshold(
                    //   showToken.msg.meta["defaults"]["confidence_threshold"]
                    // );
                    delete showToken.msg.meta["defaults"][
                      "confidence_threshold"
                    ];
                  }
                  if (
                    showToken.msg.meta["defaults"].hasOwnProperty(
                      "billing_rate_id",
                    )
                  ) {
                    let rate_items = RatesResults.billing_rates.filter(
                      (item) =>
                        item.id ===
                        showToken.msg.meta["defaults"]["billing_rate_id"],
                    );
                    setBillingRateItems(rate_items);
                    setBilling_rate_id(
                      showToken.msg.meta["defaults"]["billing_rate_id"],
                    );
                    if (rate_items.length > 0) {
                      let name = rate_items.filter(
                        (item) =>
                          item.id ===
                          showToken.msg.meta["defaults"]["billing_rate_id"],
                      )[0].classifier;

                      const labelResults: listClassLabels =
                        await appStore?.functionService.listClassLabels(
                          token,
                          name,
                        );
                      if (labelResults) {
                        if (labelResults.success) {
                          setClassLabels(labelResults.class_labels);
                        } else {
                          // setLoading(false);
                        }
                      }
                    }
                  }
                  if (
                    showToken.msg.meta["defaults"].hasOwnProperty(
                      "timezone_str",
                    )
                  ) {
                    setTimeZone(showToken.msg.meta["defaults"]["timezone_str"]);
                  }
                  if (showToken.msg.meta["defaults"].hasOwnProperty("locale")) {
                    setLocaleValue(showToken.msg.meta["defaults"]["locale"]);
                  }
                  if (
                    showToken.msg.meta["defaults"].hasOwnProperty(
                      "class_labels",
                    )
                  ) {
                    setLabelValue(
                      showToken.msg.meta["defaults"]["class_labels"],
                    );
                  }
                  if (
                    showToken.msg.meta["defaults"].hasOwnProperty(
                      "additional_inference_options",
                    )
                  ) {
                    if (
                      showToken.msg.meta["defaults"][
                        "additional_inference_options"
                      ].hasOwnProperty("ignore_stationary_vehicles")
                    ) {
                      setIgnoreStationaryVehicles(
                        showToken.msg.meta["defaults"][
                          "additional_inference_options"
                        ]["ignore_stationary_vehicles"],
                      );
                    }
                  }
                  setReturnNonDetectionVideos(
                    showToken.msg.meta["defaults"][
                      "return_non_detection_videos"
                    ],
                  );
                  setDetectVanishingObjects(
                    showToken.msg.meta["defaults"]["detect_vanishing_objects"],
                  );
                }
                setAddToGroup(false);
                setOpenResultMessage(false);
              } else {
                setToken("");
                setBilling_rate_id(undefined);
                setLocaleValue("");
                setLabelValue([""]);
                setThreshold(35);
                setTimeZone("");
                setClassLabels([]);
                setReturnNonDetectionVideos(false);
                setIgnoreStationaryVehicles(true);
                setAddToGroup(true);
              }
            }
            var all_locales = await appStore?.functionService.getLocales(token);
            if (all_locales) {
              setLocales(all_locales);
              setLoading(false);
            }
          }
        }
      }
      setLoading(false);
    };
    fetchBillingRates();
  }, [org, renderValue]);

  var locales_menu: React.ReactNode[] = [];
  var locales_list: string[] = [];
  if (locales) {
    locales.forEach((locale_menu) => {
      if (!locales_list.includes(locale_menu.locale)) {
        locales_menu.push(
          <MenuItem value={locale_menu.locale}>
            <span className={classes.bold_text}>{locale_menu.locale}</span>
          </MenuItem>,
        );
        locales_list.push(locale_menu.locale);
      }
    });
  }
  useEffect(() => {
    if (resultFailure) {
      const timeId = setTimeout(() => {
        // After 5 seconds set the show value to false

        setResultFailure(false);
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }
    // when the component is mounted, the alert is displayed for 3 seconds
  }, [resultFailure]);
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogTitle
          id="simple-dialog-title"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <span
            className={classes.bold_text}
            style={{ fontSize: 20, color: "primary" }}
          >
            Configure {org ? org.org_name : ""} for Sentinel Post Analytics
          </span>
        </DialogTitle>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div style={{ marginLeft: "25%" }}>
          {addToGroup !== true ? null : (
            <Box mb={2} mt={2} justifyContent="left">
              <FormControl style={{ width: 450 }}>
                <InputLabel
                  style={{ paddingLeft: "1%" }}
                  id="demo-controlled-open-select-label"
                >
                  {" "}
                  Product
                </InputLabel>
                <Select
                  error={selectError}
                  label=" Username"
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  variant="outlined"
                  value={billing_rate_id}
                  // helperText={
                  //   selectError === true ? 'Profile name field cannot be blank' : ''
                  // }
                  onChange={(e) => handleChange(e)}
                >
                  {billingRateItems &&
                    billingRateItems.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.product}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          )}

          {/* {classLabels.length > 0 && (
            <Box mb={2} mt={2} justifyContent="left">
              <FormControl style={{ width: 450 }}>
                <InputLabel
                  style={{ paddingLeft: "1%" }}
                  id="demo-controlled-open-select-label"
                >
                  {" "}
                  Class labels
                </InputLabel>
                <Select
                  error={selectLabelError}
                  label=" Username"
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  variant="outlined"
                  value={labelValue}
                  multiple
                  onChange={(e) => handleLabelsChange(e)}
                >
                  {classLabels &&
                    classLabels.map((item, index) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Box>
          )} */}
          <Box mt={2} mb={2} justifyContent="left">
            <FormControl style={{ width: 450 }} variant="outlined">
              <InputLabel
                style={{ paddingLeft: "1%" }}
                id="demo-controlled-open-select-label"
              >
                {" "}
                Time Zone
              </InputLabel>

              <Select
                error={selectTimeError}
                value={timezone}
                onChange={handleTimeZoneChange}
              >
                {time_zones.map((zone) => (
                  <MenuItem value={zone}>{zone}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {addToGroup !== true
            ? null
            : locales.length > 0 && (
                <Box mb={2} mt={2} justifyContent="left">
                  <FormControl variant="outlined" style={{ width: 450 }}>
                    <InputLabel
                      style={{ paddingLeft: "1%" }}
                      id="demo-controlled-open-select-label"
                    >
                      {" "}
                      Locale
                    </InputLabel>
                    <Select
                      error={selectLocaleError}
                      value={localeValue}
                      onChange={handleLocaleChange}
                    >
                      {locales_menu}
                    </Select>
                  </FormControl>
                </Box>
              )}
          {/* {locales.length > 0 && (
            <Box mb={2} mt={2} justifyContent='left'>
              <FormControl variant='outlined' style={{ minWidth: '40% ' }}>
                <InputLabel
                  style={{ paddingLeft: '1%' }}
                  id='demo-controlled-open-select-label'
                >
                  {' '}
                  Locale
                </InputLabel>
                <Select
                  error={selectLocaleError}
                  value={localeValue}
                  onChange={handleLocaleChange}
                >
                  {locales_menu}
                </Select>
              </FormControl>
            </Box>
          )} */}
          {/* <Box mb={2} mt={2} justifyContent="left">
            <TextField
              onChange={(e) => {
                handleThresholdChange(e);
              }}
              label="Default Confidence Threshold"
              variant="outlined"
              disabled={false}
              margin="normal"
              autoFocus
              value={threshold}
              style={{ width: 450 }}
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          </Box> */}

          {/* {labelValue?.join().includes("vehicle") ? (
            <Box mt={2} mb={2} justifyContent="center">
              <FormControlLabel
                className={classes.form_component_checkbox}
                control={
                  <Tooltip
                    title={
                      "If this option is selected DeepAlert will ignore stationary vehicles. Please note if we receive one frame this functionality will not work and you can expect vehicle alerts as DeepAlert can not determine motion."
                    }
                    placement="top"
                  >
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      checked={ignoreStationaryVehicles}
                      onChange={(e) => {
                        setIgnoreStationaryVehicles(e.target.checked);
                      }}
                    />
                  </Tooltip>
                }
                label="Ignore stationary vehicles"
              />
            </Box>
          ) : null} */}

          <Box mt={2} mb={2} justifyContent="left">
            <FormControlLabel
              className={classes.form_component_checkbox}
              //   fontSize='large'
              control={
                <Checkbox
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  checked={detectVanishingObjects}
                  onChange={(e) => {
                    setDetectVanishingObjects(e?.target?.checked);
                  }}
                />
              }
              label="Detect vanishing objects"
            />
          </Box>
          <Box mt={2} mb={2} justifyContent="left">
            <FormControlLabel
              className={classes.form_component_checkbox}
              //   fontSize='large'
              control={
                <Checkbox
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  checked={returnNonDetectionVideos}
                  onChange={(e) => {
                    setReturnNonDetectionVideos(e.target.checked);
                  }}
                />
              }
              label="Return non-detection videos"
            />
          </Box>

          {openResultMessage ? (
            <Box mt={2} mb={2} justifyContent="left">
              <Alert
                style={{ width: 450 }}
                variant="outlined"
                severity={
                  resultMessage.includes("successfully") ? "success" : "error"
                }
                onClose={() => {
                  setOpenResultMessage(false);
                  setResultMessage("");
                }}
              >
                {resultMessage}
              </Alert>
            </Box>
          ) : null}
        </div>
      )}
      <Box mt={2} mb={2} justifyContent="center" style={{ marginLeft: "25%" }}>
        {loading ? null : token !== "" && billingRateItems.length > 0 ? (
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              marginTop: 15,
              borderRadius: 15,
              overflow: "hidden",
              width: 450,
            }}
          >
            <TableContainer>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    PRODUCT :
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    {billingRateItems[0].product}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text}>LOCALE:</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    {localeValue}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text}>Token:</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>{token}</Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    Subscription URL:
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    https://http.edge.deepalert.ai/api/v1.0/sentinel_subscription
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    Analytics URL :
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    https://http.edge.deepalert.ai/api/v1.0/sentinel_analytics
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    Guard Tour URL:
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    https://http.edge.deepalert.ai/api/v1.0/sentinel_guardtour
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
            </TableContainer>
          </div>
        ) : null}
      </Box>

      {resultFailure ? (
        <Box textAlign="center" mt={2} mb={2}>
          <Alert variant="outlined" severity="error">
            error in obtaining sentinnel Information
          </Alert>
        </Box>
      ) : null}

      <div
        className={classes.twoButtonsInARow}
        style={{ marginBottom: 20, marginTop: 20 }}
      >
        <Button
          variant="contained"
          className="save"
          onClick={() => {
            setBilling_rate_id(undefined);
            setLocaleValue("");
            setLabelValue([""]);
            setThreshold(35);
            setBillingRateItems([]);
            setTimeZone("");
            setClassLabels([]);
            setToken("");
            setBillingRateItems([]);
            setDetectVanishingObjects(false);
            setReturnNonDetectionVideos(false);
            setIgnoreStationaryVehicles(true);
            setOpenResultMessage(false);
            setResultMessage("");
            setRenderValue((value) => !value);
            onClose();
          }}
        >
          Dismiss
        </Button>
        {loading ? null : (
          <Button
            variant="contained"
            className="save"
            onClick={handleAddToProduct}
          >
            {addToGroup === true ? "Add" : "Update"}
          </Button>
        )}
      </div>
    </Dialog>
  );
};

export default AddGroupToSentinel;
