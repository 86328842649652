import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AppStore } from "../../../stores/AppStore";
import { Site } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

interface TrainingSettingsProps {
  appStore?: AppStore;
  onClose: () => void;
  open: boolean;
  trainingSettings: (
    site: Site,
    training_fraction: number,
    training_triggers_only: boolean,
  ) => void;

  site: Site | undefined;
}

const TrainingSettings: React.FC<TrainingSettingsProps> = ({
  onClose,
  open,
  appStore,
  trainingSettings,
  site,
}: TrainingSettingsProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [name, setName] = React.useState<string>("");
  const [localSite, setLocalSite] = useState<Site | undefined>(undefined);
  const [renderValue, setRenderValue] = useState(false);
  const [textError, setTextError] = useState(false);
  const [message, setMessage] = useState("");
  const [training, setTraining] = React.useState<boolean>(false);
  const [training_triggers_only, setTrainingTriggersOnly] =
    React.useState<boolean>(false);
  const [training_fraction, setTrainingFraction] = React.useState<number>(0);
  const handleTrainingPercentageChange = (event: React.ChangeEvent<any>) => {
    setTextError(false);
    setTrainingFraction(event.target.value);
  };
  const handleCheckTrainingTriggerOnly = () => {
    setTrainingTriggersOnly(!training_triggers_only);
  };
  const handleUpdate = () => {
    if (isNaN(+training_fraction)) {
      setTextError(true);
      setMessage("Training fraction is not a valid number!");
    } else if (Number(training_fraction) < 0 || Number(training_fraction) > 1) {
      setMessage("Training fraction must be a decimal between 0 and 1!");
      setTextError(true);
    } else {
      if (!training) {
        setTrainingFraction(0);
        trainingSettings(
          site!,
          Number(training_fraction),
          training_triggers_only,
        );
        onClose();
        setRenderValue((value) => !value);
        // setTrainingTriggersOnly(false);
        // setTraining(false);
      } else {
        if (training_fraction == 0) {
          setMessage(
            "Training fraction must be greater than 0 if training set to be provided!",
          );
          setTextError(true);
        } else {
          trainingSettings(
            site!,
            Number(training_fraction),
            training_triggers_only,
          );
          onClose();
          setRenderValue((value) => !value);
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (site) {
        setLoading(true);
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          let siteVal = await appStore?.functionService.getSite(
            token,
            site.site_id,
          );
          if (siteVal) {
            if (siteVal.site_name) {
              setName(siteVal.site_name);
            }
            if (siteVal.meta && siteVal.meta.training) {
              if (siteVal.meta.training.hasOwnProperty("fraction")) {
                if (siteVal.meta.training.fraction == 0) {
                  setTrainingFraction(0);
                  setTraining(false);
                } else {
                  setTraining(true);
                  setTrainingFraction(Number(site.meta.training.fraction));
                }
              }
              if (site.meta.training.hasOwnProperty("triggers_only")) {
                setTrainingTriggersOnly(site.meta.training.triggers_only);
                setLoading(false);
              }
              setLoading(false);
            }

            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      }
    };
    fetchData();
  }, [renderValue, site, appStore]);

  const handleClose = () => {
    onClose();
  };
  const handleCheckTraining = () => {
    if (training === false) {
      setTrainingFraction(1);
    }
    setTraining(!training);
    setMessage("");
  };

  const StyledTableRowSMTP = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className={classes.modal_dialog_wide}
      fullWidth={true}
    >
      <DialogTitle className={classes.bold_text}>
        Training Settings for {site?.name}
      </DialogTitle>
      <br></br>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <FormControlLabel
            className={classes.form_component_checkbox}
            control={
              <Checkbox
                color="primary"
                checked={training}
                onChange={handleCheckTraining}
              />
            }
            label="provide training images"
          />
          <FormControlLabel
            className={classes.form_component_checkbox}
            control={
              <Checkbox
                disabled={!training}
                color="primary"
                checked={training_triggers_only}
                onChange={handleCheckTrainingTriggerOnly}
              />
            }
            label="provide training images for alerts only"
          />
          <TextField
            onChange={handleTrainingPercentageChange}
            label="training fraction"
            variant="outlined"
            margin="normal"
            value={training_fraction}
            error={textError}
            disabled={!training}
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
            helperText={textError === true ? message : ""}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
        </>
      )}

      <div className={classes.twoButtonsInARow}>
        <Button
          variant="contained"
          className={classes.cancel_button}
          onClick={() => {
            onClose();
          }}
        >
          Dismiss
        </Button>
        <Button
          variant="contained"
          className={classes.bold_text}
          onClick={handleUpdate}
        >
          Update
        </Button>
      </div>
    </Dialog>
  );
};
export default TrainingSettings;
