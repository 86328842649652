import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import { Org, addBillingOrgResult } from "../../../services/FunctionService";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
  IconButton,
  Paper,
  Card,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

interface ShowSentinelTokenProps {
  open: boolean;
  appStore?: AppStore;
  org: Org | undefined;
  onClose: () => void;
}
const ShowSentinelToken: React.FC<ShowSentinelTokenProps> = ({
  open,
  appStore,
  org,
  onClose,
}: ShowSentinelTokenProps) => {
  const StyledTableRowSMTP = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
      },
    }),
  )(TableRow);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [openResultMessage, setOpenResultMessage] = useState(false);
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    const retrieveToken = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        const showToken: addBillingOrgResult =
          await appStore?.functionService.getSentinelToken(token, org!.org_id);
        if (showToken) {
          if (showToken.success) {
            setToken(showToken.msg);
            setOpenResultMessage(false);
            setLoading(false);
          } else {
            setToken("");
            setOpenResultMessage(true);
            setResultMessage(showToken.msg);
            setLoading(false);
          }
        }
      }
    };

    if (org) {
      retrieveToken();
    }
  }, [org]);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
      fullWidth={true}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogTitle id="simple-dialog-title">
          <span
            className={classes.bold_text}
            style={{ fontSize: 20, color: "primary" }}
          >
            Sentinel Token for {org ? org.org_name : ""}
          </span>
        </DialogTitle>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div
          style={{
            marginLeft: "10%",
            marginRight: "10%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {token !== "" ? (
            <div
              style={{
                boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                marginTop: 15,
                borderRadius: 15,
                overflow: "hidden",
                width: "100%",
              }}
            >
              <TableContainer>
                <StyledTableRowSMTP>
                  <TableCell>
                    <Typography className={classes.bold_text}>
                      Token:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.bold_text}>
                      {token}
                    </Typography>
                  </TableCell>
                </StyledTableRowSMTP>
                <StyledTableRowSMTP>
                  <TableCell>
                    <Typography className={classes.bold_text}>URL :</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.bold_text}>
                      https://http.edge.deepalert.ai
                    </Typography>
                  </TableCell>
                </StyledTableRowSMTP>
              </TableContainer>
            </div>
          ) : null}

          {openResultMessage ? (
            <Box mt={2} mb={2} justifyContent="center">
              <Alert
                variant="outlined"
                severity="error"
                // onClose={() => {
                //   setOpenResultMessage(false);
                //   setResultMessage('');
                // }}
              >
                {resultMessage}
              </Alert>
            </Box>
          ) : null}
        </div>
      )}

      <div
        className={classes.singleOkButton}
        style={{ marginBottom: 10, marginTop: 20 }}
      >
        <Button
          className={classes.bold_text}
          variant="contained"
          onClick={handleClose}
        >
          Dismiss
        </Button>
      </div>
    </Dialog>
  );
};

export default ShowSentinelToken;
