import React from "react";
import ReactDOM from "react-dom";
import { App } from "./core/App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { AuthService } from "./services/AuthService";
import { FunctionService } from "./services/FunctionService";
import { Helpers } from "./services/Helpers";
import { AppStore } from "./stores/AppStore";
import theme from "./theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { RecoilRoot } from "recoil";

const helpers = new Helpers();
const authService = new AuthService();
const url = window.location.href;
const functionService = new FunctionService();
functionService.setUrl(url);
functionService.setStreamingUrl(url);
authService.setFunctionService(functionService);
functionService.setAuthService(authService);
helpers.setAuthService(authService);
helpers.setFunctionService(functionService);
const appStore = new AppStore(functionService, authService, helpers, "2.6.0");

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RecoilRoot>
        <App appStore={appStore} />
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
