import React, { useState, useEffect } from "react";
import { IconButton, Typography } from "@material-ui/core";
// import { AddCircle } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
// import Grid from "@material-ui/core/Grid";
// import Checkbox from "@material-ui/core/Checkbox";
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { atom, useRecoilState } from "recoil";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Container from "@material-ui/core/Container";
import { AppStore } from "../../../../stores/AppStore";
import { User, Org, UsersForOrg } from "../../../../services/FunctionService";
import { useStyles } from "../../../styling/global/global";
import AddUserModal from "../../../modals/AddUserModal/AddUserModal";
import MessageModal from "../../../modals/MessageModal/MessageModal";
import {
  withStyles,
  useTheme,
  Theme,
  createStyles,
  fade,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import UserAuditTrails from "./UserAuditTrails";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { PlusOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Button as AntButton } from "antd";

interface UserAuditTrailsProps {
  appStore?: AppStore;
}

// Tabs Data and function
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const GroupIdState = atom({
  key: "groupid",
  default: -1,
});

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const theme = createMuiTheme({
  overrides: {
    MuiTab: {
      root: {
        "&.Mui-selected": {
          background: "rgba(255,255,255,0.09)",
          color: "white",
          // fontSize: 15,
          fontWeight: "bold",
          // textTransform: 'capitalize',
          // opacity: 0.7,
        },
      },
    },
  },
});

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// end

interface UserManagementMainDrawerProps {
  appStore?: AppStore;
  setSelectedItem: (x: any) => void;
}

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 30,
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:hover": {
        backgroundColor: "#e3f2fd",
        cursor: "pointer",
      },
    },
  }),
)(TableRow);

const UserManagementMainDrawer: React.FC<UserManagementMainDrawerProps> = ({
  appStore,
  setSelectedItem,
}: UserManagementMainDrawerProps) => {
  const classes = useStyles();
  const [usersfortree, setUsersForTree] = useState<User[] | undefined | any>(
    undefined,
  );
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [orgslist, setOrgsList] = React.useState<Org[]>([]);
  // const [groupid, setGroupId] = React.useState(-1);
  const [groupid, setGroupId] = useRecoilState(GroupIdState);
  const [groupname, setGroupName] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [message, setMessage] = useState("");
  const [popmessage, setPopMessage] = useState("");
  const [showDisabled, setShowDisabled] = useState(false);
  const [incSubgroups, setIncSubgroups] = useState(false);
  const [value, setValue] = React.useState(0);

  const styler = useStyle();

  const getBaseUrl = (): string => {
    let base = "";
    if (window.location.href.includes("local")) {
      base = "https://manage.staging.deepalert.ai";
    }
    base += "/api/v1.3";
    return base;
  };

  //*************Fetching Data Functions*************/
  //  Fetching Organizations
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      if (token) {
        // Fetch Start
        try {
          const request = JSON.stringify({
            enabled: true,
            paginate: false,
            verbose: false,
            incl_tree_names: true,
          });

          const res = fetch(baseUrl + "/list_orgs", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });
          const result = await (await res).json();
          const orglist = result;
          if (orglist && orglist.success) {
            let orgz = orglist?.orgs;
            orgz.sort(function (a: any, b: any) {
              return a.org_tree_names - b.org_tree_names;
            });
            orgz.sort(function (a: any, b: any) {
              return a.org_tree_names.length - b.org_tree_names.length;
            });
            setOrgsList(orgz);
            setLoading(false);
          }
        } catch (err) {
          return err;
        }
      }
    };
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    if (token && groupid > -1) {
      var users;
      users = await getAllUsersForOrg(token, groupid, incSubgroups);
      setUsersForTree(users);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [groupid, incSubgroups]);

  // Fetch Users Function (All Users and For Specific Org Tree)
  const getAllUsersForOrg = async (
    access_token: string,
    top_org_id: number,
    top_org_only: boolean,
  ): Promise<User[]> => {
    var baseUrl = getBaseUrl();
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        order_by: "username",
        top_org_only: top_org_only ? false : true,
        paginate: false,
        verbose: true,
        include_alert_web_roles: true,
        include_management_roles: true,
      });

      const res = fetch(baseUrl + "/list_users", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UsersForOrg;
      let ret = result.users;
      return ret;
    } catch (err: any) {
      return err;
    }
  };

  async function saveNewUser(
    user_name: string,
    first_name: string,
    last_name: string,
    password: string,
    email: string,
    contact_number: string,
    roles: string,
    web_roles: string,
    job_description: string,
    org_id: number,
  ) {
    if (org_id == 0) {
      setMessage("Please select Entry Group before saving.");
    } else {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        let new_user = await appStore?.functionService.createUser(
          token,
          org_id,
          user_name,
          first_name,
          last_name,
          password,
          email,
          contact_number,
          roles,
          web_roles,
          job_description,
        );
        if (new_user) {
          if (new_user.success) {
            closeAddUserModal();
            setMessage("");
            setPopMessage("Created new User : " + first_name + " " + last_name);
            openMessageModal();
            setLoading(false);
            setUsersForTree(undefined);
            setGroupName("");
            setGroupId(-1);
          } else {
            setMessage(new_user.msg);
          }
        }
        setLoading(false);
      }
    }
  }

  //MessageModal
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };

  const closeMessageModal = () => {
    setMessageModalOpen(false);
  };
  //AddUserModal
  const [openaddusermodal, setOpenAddUserModal] = React.useState(false);

  const openAddUserModal = () => {
    // if (groupid == 0 || groupid == -1) {
    //   setPopMessage("Select group before creating user!");
    //   openMessageModal();
    // } else {
    setOpenAddUserModal(true);
    // }
  };

  const closeAddUserModal = () => {
    setOpenAddUserModal(false);
  };
  const handleSelectUser = (user: User) => {
    setSelectedItem(user);
  };
  const handleChangeAuto = (value: any) => {
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setGroupName(group.org_name);
    }
  };

  //Tabsmenu
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // Order By Change
  const handleOrderChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    let order = e.target.value;

    if (order == "Username ASC") {
      sortByHandler("Username", true);
    }

    if (order == "Username DSC") {
      sortByHandler("Username", false);
    }

    if (order === "Firstname ASC") {
      sortByHandler("Firstname", true);
    }

    if (order === "Firstname DSC") {
      sortByHandler("Firstname", false);
    }

    if (order === "Lastname ASC") {
      sortByHandler("Lastname", true);
    }

    if (order === "Lastname DSC") {
      sortByHandler("Lastname", false);
    }
    if (order === "Email ASC") {
      sortByHandler("Email", true);
    }

    if (order === "Email DSC") {
      sortByHandler("Email", false);
    }
  };

  // Sorting Table's Data Function
  const sortByHandler = (orderBy: string | unknown, direction: boolean) => {
    switch (orderBy) {
      case "Username":
        if (direction) {
          const list = [...usersfortree].sort((a, b): number => {
            if (b.username.toLowerCase() > a.username.toLowerCase()) {
              return -1;
            }
            if (b.username.toLowerCase() < a.username.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setUsersForTree(newArr);
          return;
        } else {
          const list = [...usersfortree].sort((a, b): number => {
            if (b.username.toLowerCase() > a.username.toLowerCase()) {
              return 1;
            }
            if (b.username.toLowerCase() < a.username.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setUsersForTree(newArr);
          return;
        }
      case "Firstname":
        if (direction) {
          const list = [...usersfortree].sort((a, b): number => {
            if (b.first_name.toLowerCase() > a.first_name.toLowerCase()) {
              return -1;
            }
            if (b.first_name.toLowerCase() < a.first_name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setUsersForTree(newArr);
          return;
        } else {
          const list = [...usersfortree].sort((a, b): number => {
            if (b.first_name.toLowerCase() > a.first_name.toLowerCase()) {
              return 1;
            }
            if (b.first_name.toLowerCase() < a.first_name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setUsersForTree(newArr);
          return;
        }
      case "Lastname":
        if (direction) {
          const list = [...usersfortree].sort((a, b): number => {
            if (b.last_name.toLowerCase() > a.last_name.toLowerCase()) {
              return -1;
            }
            if (b.last_name.toLowerCase() < a.last_name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setUsersForTree(newArr);
          // forceUpdate();
          return;
        } else {
          const list = [...usersfortree].sort((a, b): number => {
            if (b.last_name.toLowerCase() > a.last_name.toLowerCase()) {
              return 1;
            }
            if (b.last_name.toLowerCase() < a.last_name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setUsersForTree(newArr);
          // forceUpdate();
          return;
        }
      /******/
      case "Email":
        if (direction) {
          const list = [...usersfortree].sort((a, b): number => {
            if (b.email.toLowerCase() > a.email.toLowerCase()) {
              return -1;
            }
            if (b.email.toLowerCase() < a.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setUsersForTree(newArr);
          // forceUpdate();
          return;
        } else {
          const list = [...usersfortree].sort((a, b): number => {
            if (b.email.toLowerCase() > a.email.toLowerCase()) {
              return 1;
            }
            if (b.email.toLowerCase() < a.email.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setUsersForTree(newArr);
          // forceUpdate();
          return;
        }
    }
  };

  const dummy = () => {};
  const handleChangeGroup = (event: React.ChangeEvent<any>) => {
    let new_group_id = event.target.value;
    setLoading(true);
    setGroupId(new_group_id);
    let group = orgslist.find((x) => x.org_id == new_group_id);
    if (group) {
      setGroupName(group.org_name);
    }
  };
  var users: React.ReactNode[] = [];
  var usersEnabled: React.ReactNode[] = [];
  if (usersfortree) {
    let index = 0;
    usersfortree.forEach((user: any) => {
      user.type = "user";
      const className =
        index === activeIndex ? "entry-component active" : "entry-component";
      users.push(
        <StyledTableRow
          key={user.user_id}
          onClick={() => handleSelectUser(user)}
          style={{ height: 30 }}
          className={classes.tableRow}
        >
          <TableCell>
            <Typography className={classes.bold_text}>
              <br></br>
              {user.username}
              <br></br>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              <br></br>
              {user.first_name}
              <br></br>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              <br></br>
              {user.last_name}
              <br></br>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              <br></br>
              {user.email}
              <br></br>
            </Typography>
          </TableCell>
          {showDisabled && (
            <TableCell>
              <Typography className={classes.bold_text}>
                {!user.enabled ? (
                  <ClearIcon style={{ color: "red" }} />
                ) : (
                  <CheckIcon />
                )}
              </Typography>
            </TableCell>
          )}
        </StyledTableRow>,
      );

      // Only enabled
      if (user.enabled) {
        usersEnabled.push(
          <StyledTableRow
            key={user.user_id}
            onClick={() => handleSelectUser(user)}
            style={{ height: 30 }}
            className={classes.tableRow}
          >
            <TableCell>
              <Typography className={classes.bold_text}>
                <br></br>
                {user.username}
                <br></br>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>
                <br></br>
                {user.first_name}
                <br></br>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>
                <br></br>
                {user.last_name}
                <br></br>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>
                <br></br>
                {user.email}
                <br></br>
              </Typography>
            </TableCell>
            {showDisabled && (
              <TableCell>
                <Typography className={classes.bold_text}>
                  {!user.enabled ? (
                    <ClearIcon style={{ color: "red" }} />
                  ) : (
                    <CheckIcon />
                  )}
                </Typography>
              </TableCell>
            )}
          </StyledTableRow>,
        );
      }
      index++;
    });
  }
  var otherorgs: React.ReactNode[] = [];
  otherorgs.push(
    <MenuItem value={-1}>
      <span className={classes.bold_text}>All Users</span>
    </MenuItem>,
  );
  if (orgslist && orgslist.length > 0) {
    orgslist.forEach((org) => {
      otherorgs.push(
        <MenuItem value={org.org_id}>
          <span className={classes.bold_text}>{org.org_name}</span>
        </MenuItem>,
      );
    });
  }
  if ((usersfortree || orgslist) && !loading) {
    return (
      <div className="main-drawer-container">
        {/* <ThemeProvider theme={theme}> */}
        {/********************** TABS FOR NAVIGATION *********************************/}
        <ThemeProvider theme={theme}>
          <AppBar
            position="static"
            className="navcolor"
            style={{ marginBottom: 20, marginTop: 40 }}
            // style={{ marginBottom: 20, color: 'red' }}
          >
            <Tabs
              indicatorColor="secondary"
              textColor="inherit"
              value={value}
              onChange={handleChange}
              style={{ color: "white" }}
              // centered
            >
              {/* {tabsmenu} */}

              <Tab label="User Management" {...a11yProps(0)} />
              <Tab label="User Audit Trail" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </ThemeProvider>
        <TabPanel value={value} index={1}>
          <h3
            className={classes.bold_text}
            style={{
              textAlign: "center",
              fontSize: 16,
              textTransform: "capitalize",
              marginBottom: 20,
            }}
          >
            <>USER AUDIT TRAIL </>
          </h3>
          <Divider style={{ marginBottom: 30 }} />
          <UserAuditTrails appStore={appStore} />
        </TabPanel>
        <TabPanel value={value} index={0}>
          <h3
            className={classes.bold_text}
            style={{
              textAlign: "center",
              fontSize: 16,
              textTransform: "capitalize",
              marginBottom: 20,
            }}
          >
            <>USER MANAGEMENT</>
          </h3>
          <Divider style={{ marginBottom: 20 }} />
          <br />
          <Tooltip
            placement="bottom"
            title="A user is a person who is granted role-based access to parts of the system. For example users with the 'Management Roles' will be able to log into this web interface while users with 'Alert Web' roles and assignments will be able to log into the Alert Web Interface"
          >
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_text_icons}>
                  <AntButton
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{
                      width: 170,
                      borderRadius: 8,
                      backgroundColor: "rgb(21, 50, 95)",
                    }}
                    onClick={openAddUserModal}
                  >
                    <span className="button-text">Create New User</span>
                  </AntButton>
                </div>
              </div>
            </Typography>
          </Tooltip>
          <br />
          <div>
            <FormControl
              className={classes.form_component_medium}
              variant="outlined"
              fullWidth={false}
            >
              <InputLabel className={classes.bold_text}>
                {" "}
                {/* Select Group to filter users{" "} */}
                Select group to view which users have access to that group and
                below{" "}
              </InputLabel>
              <br></br>
              {/* AUTO-COMPLETE FORM */}
              <Autocomplete
                id="combo-box-demo"
                className={classes.form_component_medium}
                options={orgslist}
                onChange={(event: React.ChangeEvent<any>, value: any) => {
                  handleChangeAuto(value);
                }}
                getOptionLabel={(option: any) => {
                  let tree = option.org_tree_names!.map(
                    (item: any) => `   /   ${item} `,
                  );
                  let treeString = tree.toString();
                  const rpl = treeString.replace(/,/g, "");
                  return `${rpl}`;
                }}
                style={{ width: 600, color: "#6D809D" }}
                classes={{ paper: styler.paper }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span
                        className={classes.bold_text}
                        style={{ color: "#15325F" }}
                      >
                        {groupname ? groupname : "Please Select Group"}
                      </span>
                    }
                    variant="outlined"
                    value={groupid}
                    className={classes.bold_text}
                  />
                )}
              />
              {/* END */}
            </FormControl>

            {/* <FormControl>
              <Typography className={classes.header_text}>
                Include Sub-Groups
              </Typography>
            </FormControl>
            <FormControl>
              <Checkbox
                className={classes.form_component_checkbox}
                // disabled={true}
                onChange={() => {
                  setIncSubgroups((prev) => !prev);
                }}
                color="primary"
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxIcon fontSize="large" />}
                checked={incSubgroups}
              />
            </FormControl> */}

            {/* <Tooltip
              placement="bottom"
              title="A user is a person who is granted role-based access to parts of the system. For example users with the 'Management Roles' will be able to log into this web interface while users with 'Alert Web' roles and assignments will be able to log into the Alert Web Interface"
            >
              <Typography>
                <div className={classes.header}>
                  <div className={classes.header_icon}>
                    <svg
                      className="MuiSvgIcon-root jss80 MuiSvgIcon-fontSizeLarge"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                    </svg>
                  </div>
                  <div className={classes.header_text_icons}>
                    Users
                    <IconButton
                      edge="end"
                      aria-label="add"
                      onClick={() => {
                        openAddUserModal();
                      }}
                    >
                      <AddCircle color={"primary"} />
                    </IconButton>
                  </div>
                </div>
              </Typography>
            </Tooltip> */}

            <div style={{ marginTop: 40, marginLeft: 20 }}>
              {usersfortree && (
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    htmlFor="outlined-age-native-simple"
                    className={classes.bold_text}
                  >
                    &nbsp; ORDER BY &nbsp;
                  </InputLabel>
                  <Select
                    native
                    defaultValue={"Group ASC!"}
                    onChange={(e) => handleOrderChange(e)}
                    label="Order By"
                    inputProps={{
                      name: "Order By",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={"Username ASC"}>Username (Ascending)</option>
                    <option value={"Username DSC"}>
                      Username (Descending)
                    </option>
                    <option value={"Firstname ASC"}>
                      Firstname (Ascending)
                    </option>
                    <option value={"Firstname DSC"}>
                      Firstname (Descending)
                    </option>
                    <option value={"Lastname ASC"}>Lastname (Ascending)</option>
                    <option value={"Lastname DSC"}>
                      Lastname (Descending)
                    </option>
                    <option value={"Email ASC"}>Email (Descending)</option>
                    <option value={"Email DSC"}>Email (Descending)</option>
                  </Select>
                </FormControl>
              )}
            </div>
            <div
              style={{
                boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                marginTop: 15,
                borderRadius: 15,
                overflow: "hidden",
              }}
            >
              <TableContainer component={Paper}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            setShowDisabled((prev) => !prev);
                          }}
                        >
                          Username &nbsp; &nbsp;{" "}
                          <Tooltip
                            title={"View Disabled Users"}
                            placement="bottom"
                          >
                            <NotInterestedIcon
                              style={{
                                color: showDisabled ? "red" : "",
                                boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                                borderRadius: "50%",
                              }}
                            />
                          </Tooltip>
                        </div>{" "}
                      </TableCell>
                      <TableCell>Firstname</TableCell>
                      <TableCell>Lastname</TableCell>
                      <TableCell>Email</TableCell>
                      {showDisabled && <TableCell>Enabled</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>{showDisabled ? users : usersEnabled}</TableBody>
                </Table>
              </TableContainer>
            </div>
            <AddUserModal
              refreshTable={fetchData}
              message={message}
              groupname={groupname}
              groupid={groupid}
              setOpen={setOpenAddUserModal}
              open={openaddusermodal}
              close={closeAddUserModal}
              appStore={appStore}
              saveNewUser={async (
                user_name: string,
                first_name: string,
                last_name: string,
                password: string,
                email: string,
                contact_number: string,
                roles: string,
                web_role: string,
                job_description,
                org_id: number,
              ) => {
                await saveNewUser(
                  user_name,
                  first_name,
                  last_name,
                  password,
                  email,
                  contact_number,
                  roles,
                  web_role,
                  job_description,
                  org_id,
                );
              }}
            />
            <MessageModal
              open={messagemodalopen}
              onClose={closeMessageModal}
              message={popmessage}
              elements={undefined}
            />
          </div>
        </TabPanel>
        {/* </ThemeProvider> */}
      </div>
    );
  } else {
    return (
      <Container maxWidth="md">
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </Container>
    );
  }
};

export default UserManagementMainDrawer;
