import React, { useState, useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Input from "@material-ui/core/Input";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useStyles } from "../../../styling/global/global";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper } from "@material-ui/core";
import {
  listBillingRunTypesItems,
  listBillingRunsItems,
  listBillingProfileOrgsItems,
  listBillingItems,
  ListBillingProfileOrgsResult,
} from "../../../../services/FunctionService";
import { ArrayToStringifiedArray } from "../../../../hooks/ArrayToStringifiedArray";
interface SubmitClaculationsProps {
  appStore?: AppStore;
  open: boolean;
  onClose: () => void;
  profile: listBillingItems | undefined;
  final: boolean;
  billingRun: listBillingRunsItems | undefined;
  submitCalculationsFunc: (
    billing_run_id: number,
    billing_org_ids: string,
    incl_pro_rata: boolean,
    finalise: boolean,
  ) => void;
}

const SubmitCalculations: React.FC<SubmitClaculationsProps> = ({
  open,
  onClose,
  appStore,
  profile,
  final,
  billingRun,
  submitCalculationsFunc,
}: SubmitClaculationsProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [renderValue, setRenderValue] = useState(false);
  const [billingRuns, setBillingRuns] = useState<listBillingRunsItems[]>([]);
  const [viewProfileOrgsData, setProfileOrgsViewData] = useState<
    listBillingProfileOrgsItems[] | undefined
  >(undefined);

  const [incProRata, setIncProRata] = useState(true);
  const [runError, setRunError] = useState(false);
  const [orgsError, setOrgsError] = useState(false);
  const [finalise, setFinalise] = useState(false);
  const [orgsName, setOrgsName] = React.useState<string[]>([]);
  const [orgIds, setOrgIds] = React.useState<number[]>([]);
  const [runId, setRunId] = React.useState<number | undefined>(undefined);
  const [checkedState, setCheckedState] = useState<boolean[] | undefined>([]);

  const handleCheckboxChange = (position: number) => {
    const updatedCheckedState = checkedState!.map((item, index) =>
      index === position ? !item : item,
    );

    setCheckedState(updatedCheckedState);
  };

  const handleSelectAll = () => {
    if (viewProfileOrgsData) {
      setCheckedState(new Array(viewProfileOrgsData.length).fill(true));
    }
  };
  const handleDeSelectAll = () => {
    if (viewProfileOrgsData) {
      setCheckedState(new Array(viewProfileOrgsData.length).fill(false));
    }
  };
  const handleGetSelectedProfileOrgs = () => {
    setOrgIds([]);
    var idsArray: number[] = [];
    viewProfileOrgsData &&
      viewProfileOrgsData.map((OrgItem, orgIndex) => {
        checkedState &&
          checkedState.map((checkedItem, checkedIndex) => {
            if (checkedItem == true) {
              if (orgIndex === checkedIndex) {
                idsArray.push(OrgItem.billing_org_id);
              }
            }
          });
      });
    setOrgIds(idsArray);
    if (idsArray.length > 0 && billingRun) {
      let stringifiedIds = ArrayToStringifiedArray(idsArray);
      submitCalculationsFunc(
        billingRun.id,
        stringifiedIds,
        incProRata,
        finalise,
      );
      setRunId(undefined);
      setOrgsName([]);
      setRenderValue((value) => !value);
      onClose();
    } else {
      setOrgsError(true);
    }
  };
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  const counts: { [key: string]: number } = {};
  checkedState &&
    checkedState.map((x) => {
      let item = x.toString();
      counts[item] = (counts[item] || 0) + 1;
    });

  useEffect(() => {
    const ViewProfileFunction = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        if (profile) {
          const listBillingProfileOrgsResult: ListBillingProfileOrgsResult =
            await appStore?.functionService.listBillingProfileOrgs(
              token,
              profile.id,
            );
          if (listBillingProfileOrgsResult) {
            let list = [
              ...listBillingProfileOrgsResult.billing_profile_orgs,
            ].sort((a, b): number => {
              if (
                b.billing_org_name.toLowerCase() <
                a.billing_org_name.toLowerCase()
              ) {
                return 1;
              }
              if (
                b.billing_org_name.toLowerCase() >
                a.billing_org_name.toLowerCase()
              ) {
                return -1;
              }
              return 0;
            });
            setProfileOrgsViewData(list);
            setLoading(false);
          }
        }
      }
    };

    const fetchBillingRuns = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        if (profile) {
          const listRunsResult =
            await appStore?.functionService.listBillingRuns(token, profile!.id);
          if (listRunsResult) {
            setBillingRuns(listRunsResult.billing_runs);
            setLoading(false);
          }
        }
      }
    };
    ViewProfileFunction();
    fetchBillingRuns();

    setFinalise(final);
  }, [profile, renderValue]);
  useEffect(() => {
    if (viewProfileOrgsData) {
      setCheckedState(new Array(viewProfileOrgsData.length).fill(true));
    }
  }, [viewProfileOrgsData]);

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <span style={{ fontSize: "25px" }}>
            {" "}
            {final === true
              ? "Submit final Calculations"
              : "Submit Calculations"}{" "}
            for {billingRun ? billingRun.name : ""}
          </span>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {billingRun?.status === "finalised" ? (
              <Typography align="center" className={classes.bold_text}>
                Billing Run already finalised
              </Typography>
            ) : (
              <>
                <div
                  style={{
                    width: "98%",
                    marginLeft: "1%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                      marginTop: 5,
                      borderRadius: 10,
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    <Paper style={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer style={{ maxHeight: 300 }}>
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Billing Group Name</TableCell>
                              <TableCell>Billing Group Description</TableCell>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    className={classes.bold_text_lower_small}
                                    onClick={handleSelectAll}
                                  >
                                    Select All
                                  </Typography>
                                  <Typography
                                    className={classes.bold_text_lower_small}
                                  >
                                    {" "}
                                    /{" "}
                                  </Typography>
                                  <Typography
                                    className={classes.bold_text_lower_small}
                                    onClick={handleDeSelectAll}
                                  >
                                    DeSelect All
                                  </Typography>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {viewProfileOrgsData &&
                              viewProfileOrgsData.map((item, index) => {
                                return (
                                  <StyledTableRow>
                                    <TableCell>
                                      <Typography className={classes.bold_text}>
                                        {item.billing_org_name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography className={classes.bold_text}>
                                        {item.description}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <FormControl>
                                        <Checkbox
                                          className={
                                            classes.form_component_checkbox
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(index)
                                          }
                                          color="primary"
                                          icon={
                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                          }
                                          checkedIcon={
                                            <CheckBoxIcon fontSize="small" />
                                          }
                                          checked={checkedState![index]}
                                        />
                                      </FormControl>
                                    </TableCell>
                                  </StyledTableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginLeft: "5%",
                    marginRight: "10%",
                    width: "50%",
                  }}
                >
                  <FormControl>
                    <Typography
                      className={classes.header_text}
                      style={{ textAlign: "left" }}
                    >
                      Include Pro Rata
                    </Typography>
                  </FormControl>
                  <FormControl>
                    <Checkbox
                      // style={{ marginRight: '10%' }}
                      className={classes.form_component_checkbox}
                      // disabled={true}
                      onChange={() => {
                        setIncProRata((prev) => !prev);
                      }}
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={incProRata}
                    />
                  </FormControl>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginLeft: "5%",
                    marginRight: "10%",
                    width: "50%",
                    textAlign: "left",
                  }}
                >
                  <Typography className={classes.bold_text}>
                    Total groups selected:
                  </Typography>
                  <Typography className={classes.bold_text}>
                    {counts && counts.hasOwnProperty("true")
                      ? counts["true"]
                      : 0}
                  </Typography>
                </div>
              </>
            )}

            <div
              style={{ marginTop: "5%", marginBottom: "5%" }}
              className={classes.twoButtonsInARow}
            >
              <Button
                variant="contained"
                className={classes.cancel_button}
                onClick={() => {
                  setRunId(undefined);
                  setOrgsName([]);
                  setRunError(false);
                  setOrgsError(false);
                  setRenderValue((value) => !value);
                  onClose();
                }}
              >
                <span className={classes.bold_text}>Dismiss</span>
              </Button>
              {billingRun?.status === "finalised" ? null : (
                <Button
                  variant="contained"
                  className={classes.cancel_button}
                  onClick={() => {
                    handleGetSelectedProfileOrgs();
                  }}
                >
                  <span className={classes.bold_text}>Submit</span>
                </Button>
              )}
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default SubmitCalculations;
