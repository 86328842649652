import React, { useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import {
  IconButton,
  Typography,
  FormLabel,
  TextField,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { TimeEntrySet } from "../../../core/models/TimeEntry";
import TimeEntryPanel from "../../components/TimeEntryUI/TimeEntryPanel";
import { AddCircle } from "@material-ui/icons";
import { AppStore } from "../../../stores/AppStore";
import { AlertChannel, AlertConfig } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
interface EditRulesModalProps {
  appStore?: AppStore;
  onClose: () => void;
  currentAlertChannel: AlertChannel;
  open: boolean;
  configs: number[];
  rules: AlertConfig[] | undefined;
  updateRules(alert_channel_id: number, valid_days_and_times: string): void;
}

const EditRulesModal: React.FC<EditRulesModalProps> = ({
  onClose,
  open,
  currentAlertChannel,
  updateRules,
  rules,
  appStore,
  configs,
}: EditRulesModalProps) => {
  const classes = useStyles();
  const [timeentrySet, setTimeEntrySet] = React.useState<
    TimeEntrySet | undefined
  >(undefined);
  const [refresh, setRefresh] = React.useState(0);

  useEffect(() => {
    const setData = async () => {
      if (open && configs && configs.length > 0 && rules && rules?.length > 0) {
        let same = true;
        let valid_days_and_times = rules.find(
          (x) => x.alert_config_id === configs[0],
        )?.valid_days_and_times;
        configs.forEach((config) => {
          let rule = rules?.find((x) => x.alert_config_id == config);
          if (rule?.valid_days_and_times != valid_days_and_times) {
            same = false;
          }
        });
        if (same) {
          setTimeEntrySet(new TimeEntrySet(valid_days_and_times));
          setRefresh(new Date().getTime());
        }
      }
    };
    setData();
  }, [open]);

  const handleSave = () => {
    var valid_days_and_times = "[";
    if (timeentrySet) {
      timeentrySet.entries.forEach((timeEntry) => {
        var thisentry =
          valid_days_and_times == "["
            ? timeEntry.timeString
            : "," + timeEntry.timeString;
        valid_days_and_times = valid_days_and_times + thisentry;
      });
    }
    valid_days_and_times = valid_days_and_times + "]";
    updateRules(currentAlertChannel.alert_channel_id, valid_days_and_times);
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setTimeEntrySet(undefined);
  };

  const changeTimeEntry = (entrySet: TimeEntrySet | undefined) => {
    setTimeEntrySet(entrySet);
    //if deleted las one, make sure to add add button
    if (entrySet?.entries.length == 0) {
      setRefresh(new Date().getTime());
    }
  };
  const addSchedule = () => {
    if (timeentrySet) {
      timeentrySet.addTimeEntry();
      setTimeEntrySet(timeentrySet);
    } else {
      const newtimeentrySet = new TimeEntrySet("");
      newtimeentrySet.addTimeEntry();
      setTimeEntrySet(newtimeentrySet);
    }
    setRefresh(new Date().getTime());
  };

  var schedule: React.ReactNode[] = [];
  if (timeentrySet && timeentrySet.entries.length > 0) {
    schedule.push(
      <TextField
        value={currentAlertChannel.timezone_str}
        fullWidth={true}
        disabled={true}
        variant={"outlined"}
        label={"Time Zone"}
        margin="normal"
      ></TextField>,
      <br />,
    );
    schedule.push(
      <TimeEntryPanel
        noEndTime={false}
        horisontal={false}
        addicon={true}
        disabled={false}
        entrySet={timeentrySet}
        changeTimeEntryParent={(entrySet: TimeEntrySet | undefined) =>
          changeTimeEntry(entrySet)
        }
      ></TimeEntryPanel>,
    );
  } else {
    schedule.push(
      <div className={classes.form_component}>
        <div className={classes.bold_text}>Always Active</div>
        <div className={classes.bold_text}>
          Add Schedule
          <IconButton
            edge="end"
            aria-label="add"
            onClick={() => {
              addSchedule();
            }}
          >
            <AddCircle color={"primary"} />
          </IconButton>
        </div>
      </div>,
    );
  }
  if (appStore!.authService.getRoles().indexOf("site_admin") >= 0) {
    let sametext =
      timeentrySet === undefined
        ? "re-creating since schedules different"
        : "updating since identical schedules";
    if (configs.length > 0) {
      return (
        <Dialog
          onClose={handleClose}
          className={classes.modal_dialog}
          open={open}
        >
          <DialogTitle>
            <span className={classes.bold_text}>
              Edit {configs.length} Rules from {currentAlertChannel.name}
            </span>
          </DialogTitle>
          <FormControl variant="outlined" className={classes.form_component}>
            <FormLabel component="legend" className={classes.bold_text}>
              {sametext}
            </FormLabel>
          </FormControl>
          <FormControl className={classes.form_component}>
            {schedule}
          </FormControl>
          <div className={classes.button_container}>
            <Button
              variant="contained"
              className={classes.cancel_button}
              onClick={handleClose}
            >
              <span className={classes.bold_text}>CANCEL</span>
            </Button>
            <Button
              variant="contained"
              className={classes.save_button}
              onClick={handleSave}
            >
              <span className={classes.bold_text}>SAVE</span>
            </Button>
          </div>
        </Dialog>
      );
    } else {
      return (
        <Dialog onClose={onClose} open={open} className={classes.modal_dialog}>
          <DialogTitle className={classes.bold_text}>
            Edit Rules from {currentAlertChannel.name}
          </DialogTitle>
          <br></br>
          <Typography className={classes.bold_text}>
            {"Please select some rules to edit!"}
          </Typography>
          <div className={classes.button_container}>
            <Button
              className={classes.cancel_button}
              variant="contained"
              onClick={() => onClose()}
            >
              <span className={classes.bold_text}>CANCEL</span>
            </Button>
          </div>
        </Dialog>
      );
    }
  } else {
    return (
      <Dialog onClose={onClose} open={open} className={classes.modal_dialog}>
        <DialogTitle className={classes.bold_text}>
          Edit Rules from {currentAlertChannel.name}
        </DialogTitle>
        <br></br>
        <Typography className={classes.bold_text}>
          {
            "You need site admin role to be able to edit an Alert Rule - please contact your DeepAlert system administrator"
          }
        </Typography>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
        </div>
      </Dialog>
    );
  }
};

export default EditRulesModal;
