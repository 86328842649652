import { Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Site } from "../../../services/FunctionService";
import CustomModal from "../../UI/CustomModal";

import Slide from "@material-ui/core/Slide";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import { TransitionProps } from "@material-ui/core/transitions";
import CameraRearRoundedIcon from "@material-ui/icons/CameraRearRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import FlagIcon from "@material-ui/icons/Flag";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import Alert from "@material-ui/lab/Alert";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { ArrayToStringifiedArray } from "../../../hooks/ArrayToStringifiedArray";
import { AppStore } from "../../../stores/AppStore";
import Mapper from "../../components/Mapper";
import MapperDetails from "../../components/MapperDetails";
import MuiltMapper from "../../components/MuiltMapper";
import { useStyles } from "../../styling/global/global";
import { getBaseUrl } from "../../HelperFunctions";

import TextField from "@material-ui/core/TextField";

const useStylez = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FullScreenProp {
  showModal: boolean;
  toogleFullScreenModal: () => void;
  healthParams: (string | number)[];
  appStore?: AppStore;
  endTime: number;
  setSelectedItem: (item: any, editing: boolean) => void;
}

interface imgProp {
  original: string;
  thumbnail: string;
  flagged: boolean;
  title: string;
  class: string;
  filename: string;
  sensorId: string;
  type: string;
  start_time: number;
  end_time: number;
  ogWidth?: number;
  ogHeight?: number;
  doc_id: string;
  objDection?: boolean;
  coords?: [];
  probs: string[];
  classifier: string;
  siteId: string;
  siteName: string;
  created: number;
  probability: number;
  message?: string;
  advanced_rule_type?: string;
  rule_type?: string;
  advanced_rule_name?: string;
  rule_name?: string;
  advanced?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

// Options Names
const names = [
  "flagged",
  "unflagged",
  "classifier: Security",
  "classifier: Weapons",
  "classifier: Human1",
  "classifier: Safety",
  "class: vehicles",
  "class: people",
  "class: safety vests",
  "class: hard hat",
  "class: handguns",
  "class: rifles",
  "class: covered faces",
  "class: no hard hats",
  "class: no safety vests",
  "all items",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// end

export const FullScreenDialog: React.FC<FullScreenProp> = ({
  showModal,
  toogleFullScreenModal,
  healthParams,
  appStore,
  endTime,
  setSelectedItem,
}) => {
  const classes = useStyle();
  const [loading, setLoading] = React.useState(false);
  const [auto, setAuto] = useState<boolean | any>("");
  const classic = useStyles();
  const [img, setImg] = React.useState<imgProp[]>([]);
  const [imgTransformed, setImgTransformed] = React.useState<string[]>([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [openTimeToast, setOpenTimeToast] = useState(false);
  const [openTimefilter, setOpenTimeFilter] = useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);

  const [page, setPage] = useState(1);
  const [width, setWidth] = useState(150);
  const [height, setHeight] = useState(80);
  const [hasMore, setHasMore] = useState(true);
  const [imagesList, setImagesList] = React.useState<string[]>([]);
  const [message, setMessage] = React.useState("");
  const [err, setErr] = React.useState(false);
  const [inferences, setIncInferences] = useState(false);
  const [alerts, setIncAlerts] = useState(true);
  let [imagesLeftInf, setImagesLeftInf] = useState<number>(+healthParams[1]);
  let [imagesLeftAl, setImagesLeftAl] = useState<number>(+healthParams[2]);
  const [imgWidth, setImgWidth] = useState(140);
  const [imgHeight, setImgHeight] = useState(110);

  const [scale, setScale] = useState(0);
  const [endTimeSelected, setEndTimeSelected] = useState("");
  const [silenceMode, setSilenceMode] = useState(false);

  const [mdProbability, setMdProbability] = useState(0);
  const [md_siteId, setMdSiteId] = useState("");
  const [md_siteName, setMdSiteName] = useState("");
  const [md_sensorId, setMdSensorId] = useState("");
  const [md_file_name, setMdFileName] = useState(
    "TSTAAAB-b_20210927_105230.356514_I2vwItvGUyNS1drA_1_z163-14-29-28_vm.jpg",
  );

  const [md_classifier, setMdClassifier] = useState("");
  const [md_imgUrl, setMdImageUrl] = useState("");
  const [md_doc_id, setMd_DocId] = useState("");
  const [md_objDection, setMdObjDection] = useState<boolean | undefined>(false);
  const [md_open, setMdOpen] = useState(false);
  const [md_coords, setMdCoords] = useState<number[]>([]);
  const [md_created, setMdCreated] = useState(0);
  const [md_probs, setMdProbs] = useState<string[]>([]);
  const [md_index, setMdIndex] = useState<number>(0);
  const [md_message, setMdMessage] = useState<string>("");
  const [md_rule_name, setMdRuleName] = useState<string>("");
  const [md_rule_type, setMdRuleType] = useState<string>("");

  const [reloadImgs, setReloadImgs] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [siteList, setSiteList] = React.useState<Site[]>([]);

  const [filteredStartTime, setFilteredStartTime] = useState(0);
  const [filteredEndTime, setFilteredEndTime] = useState(0);
  const [newStartTime, setNewStartTime] = useState(0);
  const [newEndTime, setNewEndTime] = useState(0);

  const [procStartTime, setProcStartTime] = useState(0);
  const [procEndTime, setProcEndTime] = useState(0);

  const theme = useTheme();
  const [optionNames, setOptionNames] = React.useState<string[] | any>([
    "flagged",
    "unflagged",
    "classifier: Security",
    "classifier: Weapons",
    "classifier: Human1",
    "classifier: Safety",
    "class: vehicles",
    "class: people",
    "class: safety vests",
    "class: hard hat",
    "class: handguns",
    "class: rifles",
    "class: covered faces",
    "class: no hard hats",
    "class: no safety vests",
    "all items",
  ]);

  const classez = useStylez();

  const [filtering, setFiltering] = useState(false);

  const personName: string[] = [];
  const [docIds] = useState<string[]>([]);
  const [docIdsSilence, setDocIdsSilence] = useState<string[]>([]);

  const [endTimeFil, setEndTimeFil] = useState(0);

  const [isNotSystemAdmin] = useState(
    appStore!.authService.getRoles().indexOf("sys_admin") < 0,
  );

  const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOptionNames(event.target.value as string[]);
  };

  const imageNavigator = (index: number) => {
    if (index < 0 || index >= img.length) {
      return;
    }

    let selected_img = img[index];

    openMapperDetails(
      selected_img.siteId,
      selected_img.siteName,
      selected_img.sensorId,
      selected_img.filename,
      selected_img.classifier,
      selected_img.original,
      selected_img.objDection,
      true,
      selected_img.coords,
      selected_img.start_time,
      // selected_img.probability ? selected_img.probability : 0,
      0,
      // [' 60%', ' 23%', ' 45%']
      selected_img.probs ? selected_img.probs : [],
      index,
      selected_img.doc_id,
      selected_img.message,
      selected_img.rule_name,
      selected_img.rule_type,
    );
  };

  // Fetch sites
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      if (token) {
        try {
          const request = JSON.stringify({
            enabled: true,
            top_org_id: appStore?.authService.getEntryOrg()?.id!,
            paginate: false,
            top_org_only: false,
            verbose: true,
          });

          const res = fetch(baseUrl + "/list_sites", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });
          const result = await (await res).json();
          if (result && result.success) {
            let sites: Site[] = result?.sites;
            setSiteList(sites);
          }
          setLoading(false);
        } catch (err) {
          return err;
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);

  const openMapperDetails = (
    siteId: string,
    siteName: string,
    sensorId: string,
    file_name: string,
    classifier: string,
    imgUrl: string,
    objDetection: boolean | undefined,
    open: boolean,
    coords: any,
    created: number,
    probability: number,
    probs: string[],
    index: number,
    id: string,
    message: string = "",
    ruleName: string = "",
    ruleType: string = "",
  ) => {
    setMdSiteId(siteId);
    setMdSiteName(siteName);
    setMdSensorId(sensorId);
    setMdFileName(file_name);
    setMdClassifier(classifier);
    setMdImageUrl(imgUrl);
    setMdObjDection(objDetection);
    setMdOpen(open);
    setMdCoords(coords);
    setMdCreated(created);
    setMdProbability(probability);
    setMdProbs(probs);
    setMdIndex(index);
    setMd_DocId(id);
    setMdMessage(message);
    setMdRuleName(ruleName);
    setMdRuleType(ruleType);
  };
  const classer = useStyles();

  const observer = useRef<HTMLDivElement | any>();

  // const getBaseUrl = (): string => {
  //   return "/api/v1.3";
  // };

  const mdOpenHandler = () => {
    setMdOpen(true);
  };

  const mdCloseHandler = () => {
    setMdOpen(false);
    setMdClassifier("");
    setMdImageUrl("");
    setMdObjDection(false);
    setMdOpen(false);
    setMdCoords([]);
    setMdCreated(0);

    setMdMessage("");
    setMdRuleName("");
    setMdRuleType("");
    // setMdSiteId('');
    // setMdFileName('');
  };

  const coordsExtractor = (filename: string): number[] => {
    const fileArr = filename.split("_");
    const coords = fileArr[5].split("-");
    let a = coords[0].substring(1);
    let b = coords[1];
    let c = coords[2];
    let d = coords[3];
    return [
      parseInt(a),
      parseInt(b),
      parseInt(c) + parseInt(a),
      parseInt(b) + parseInt(d),
    ];
  };

  const handleToastOpen = () => {
    setOpenToast(true);
  };

  const handleToastClose = () => {
    setMessage("");
    setFilterValue("");
    setOpenToast(false);
    setImagesList([]);
    setLoading(true);
    if (!silenceMode) {
      fetchImgs(true);
    } else {
      setSilenceMode(false);
      fetchImgs(true);
      setLoading(false);
    }
  };

  const lastImage = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer!.current!.disconnect();
      observer!.current! = new IntersectionObserver((entries) => {
        if ((entries[0].isIntersecting && hasMore) || reloadImgs) {
          setLoading(true);
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer!.current!.observe(node);
    },
    [loading, hasMore, reloadImgs],
  );

  const imagesListHandler = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>,
    site_id: number | string,
  ) => {
    let status = event.target.checked;
    let newItem = id;

    let skip = false;
    let site = siteList.find((item) => item.site_id == site_id);
    if (site!.data_origin.includes("motioncam")) {
      skip = true;
    }

    if (status) {
      docIds.push(newItem);
      if (!skip) {
        docIdsSilence.push(newItem);
      }
    } else {
      const index = docIds.indexOf(id);
      if (index > -1) {
        docIds.splice(index, 1);
      }
      const index2 = docIdsSilence.indexOf(id);
      if (index2 > -1) {
        docIdsSilence.splice(index, 1);
      }
    }
  };

  const openImageViewer = useCallback((index: any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const increaseImageHandler = () => {
    if (width + 30 > 630) {
      return;
    }
    setScale((prev) => prev + 1);

    setHeight((prev) => prev * 1.2);
    setWidth((prev) => prev * 1.2);
    setImgWidth((prev) => prev * 1.2);
    setImgHeight((prev) => prev * 1.2);
  };
  const decreaseImageHandler = () => {
    setScale((prev) => prev + 1);
    if (width - 30 < 150) {
      return;
    }
    setWidth((prev) => prev / 1.2);
    setHeight((prev) => prev / 1.2);
    setImgWidth((prev) => prev / 1.2);
    setImgHeight((prev) => prev / 1.2);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const imageTypeHandler = (type: string) => {
    if (type === "inferences") {
      setIncInferences(true);
      setIncAlerts(false);
    }
    if (type === "alerts") {
      setIncInferences(false);
      setIncAlerts(true);
    }
    setImg([]);
    setNewStartTime(0);
    setNewEndTime(0);
    setFilterValue("");
    setImagesLeftInf(+healthParams[1]);
    setImagesLeftAl(+healthParams[2]);
    setMdFileName(
      "TSTAAAB-b_20210927_105230.356514_I2vwItvGUyNS1drA_1_z163-14-29-28_vm.jpg",
    );
  };

  const setStartTimeFilter = (e: any) => {
    var time: any = new Date(e);
    time = time.getTime() / 1000;
    setFilteredStartTime(e);
    setProcStartTime(newStartTime);
    setNewStartTime(time);
    // setHasMore(true)
  };

  const setEndTimeFilter = (e: any) => {
    var time: any = new Date(e);
    time = time.getTime() / 1000;
    setFilteredEndTime(e);
    setProcEndTime(newEndTime);
    setNewEndTime(time);
    // setHasMore(true)
  };

  const applyTimeFilter = () => {
    if (newStartTime > newEndTime) {
      setOpenTimeToast(true);
      setErr(true);
      setMessage("Start time cannot be greater than Endtime");
      return console.debug("Startime cannot be greater than Endtime");
    }
    setHasMore(true);
    setFilteredStartTime(0);
    setFilteredEndTime(0);
    setEndTimeFil(newEndTime);
    setFiltering(false);
    fetchImgsForFilter(false, "all", newEndTime);
  };

  const fetchImgs = async (reload = false, filterOption = "all") => {
    let time = healthParams[0].toString();
    let epocSeconds = 0;
    let day = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();

    let startTimeFilter = 0;
    let endTimeFilter = 0;
    if (time.length > 5) {
      // let timeArray = time.split(" ");
      let timeArray = time.split("(");
      let dateSplit = timeArray[0].split("/");
      let timeSplit = timeArray[1].split(":");

      month = parseInt(dateSplit[1]) - 1;
      day = parseInt(dateSplit[0]);
      let hour = parseInt(timeSplit[0]);
      let minutes = parseInt(timeSplit[1]);

      let d = new Date(year, month, day, hour, minutes);
      epocSeconds = Math.round(d.getTime() / 1000);
      startTimeFilter = epocSeconds;
      endTimeFilter = epocSeconds + endTime;

      let hr: number | string = new Date(
        (epocSeconds + endTime) * 1000,
      ).getHours();
      let min: number | string = new Date(
        (epocSeconds + endTime) * 1000,
      ).getMinutes();

      if (+min === 0) {
        min = "00";
      }

      if (min.toString().length === 1) {
        min = `0${min}`;
      }

      if (hr.toString().length == 1) {
        hr = `0${hr}`;
      }
      setEndTimeSelected(`${hr}:${min}`);
    } else {
      let timeSplit = time.split(":");
      let hour = parseInt(timeSplit[0]);
      let minutes = parseInt(timeSplit[1]);
      let d = new Date(year, month, day, hour, minutes);
      epocSeconds = Math.round(d.getTime() / 1000);

      startTimeFilter = epocSeconds;
      endTimeFilter = epocSeconds + endTime;

      let hr: number | string = new Date(
        (epocSeconds + endTime) * 1000,
      ).getHours();
      let min: number | string = new Date(
        (epocSeconds + endTime) * 1000,
      ).getMinutes();

      if (min === 0) {
        min = "00";
      }
      if (min.toString().length === 1) {
        min = `0${min}`;
      }

      if (hr.toString().length === 1) {
        hr = `0${hr}`;
      }
      setEndTimeSelected(`${hr}:${min}`);
    }

    try {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        let request: string = "";
        if (healthParams[4] === "top_org_id") {
          request = JSON.stringify({
            top_org_id: +healthParams[5],
            start_time_filter:
              newStartTime === 0 || reload ? startTimeFilter : newStartTime,
            end_time_filter: endTimeFil === 0 ? endTimeFilter : endTimeFil,
            include_inference_images: inferences,
            include_alert_images: alerts,
            max_count: 10,
            [`${filterOption}`]: true,
          });
        }

        if (healthParams[4] === "site_id") {
          request = JSON.stringify({
            site_ids: ArrayToStringifiedArray([+healthParams[5]]),
            start_time_filter:
              newStartTime === 0 || reload ? startTimeFilter : newStartTime,
            end_time_filter: endTimeFil === 0 ? endTimeFilter : endTimeFil,
            include_inference_images: inferences,
            include_alert_images: alerts,
            max_count: 10,
            [`${filterOption}`]: true,
          });
        }

        if (healthParams[4] === "sensor_id") {
          request = JSON.stringify({
            sensor_ids: ArrayToStringifiedArray([+healthParams[5]]),
            start_time_filter:
              newStartTime === 0 || reload ? startTimeFilter : newStartTime,
            end_time_filter: endTimeFilter,
            include_inference_images: inferences,
            include_alert_images: alerts,
            max_count: 10,
            [`${filterOption}`]: true,
          });
        }

        const res = await fetch(getBaseUrl() + "/activity_images", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        const result = (await (await res).json()) as any;
        if (result.msg.inference_images) {
          if (scale === 0) {
            setImgHeight(273);
            setImgWidth(348);
            setHeight(273 * 1.2);
            setWidth(348 * 1.2);
          }

          let filtered = result.msg.inference_images.filter((img: any) => {
            if (img.classifier === "security" && isNotSystemAdmin) {
              return false;
            }
            return true;
          });

          // imagesLeftInf = imagesLeftInf - filtered.length
          // setImagesLeftInf(prev => prev - filtered.length)

          let data = filtered.map((img: any, index: number) => {
            if (filtered.length === index + 1) {
              setNewStartTime(img.created);
              setNewEndTime(img.created + endTime);
              let coords: any[] = [];
              let probs: any[] = [];
              if (img.object_detections && img.object_detections.length > 0) {
                coords = img.object_detections.map((item: any) => {
                  probs.push(
                    "  " +
                      Number(item.inference_probability * 100).toFixed(0) +
                      "%" +
                      // " -> " +
                      "  (" +
                      item.inference_class +
                      ") ",
                  );

                  // let color = 'blue';
                  let color = `rgba(0,0,255, ${item.inference_probability})`;

                  if (item.inference_class === "people") {
                    // color = 'red';
                    color = `rgba(255,0,0, ${item.inference_probability})`;
                  }
                  if (item.inference_class === "vehicles") {
                    // color = '#00FF00';
                    color = `rgba(50,205,50, ${item.inference_probability})`;
                  }
                  return {
                    name: img.image_url,
                    shape: "rect",
                    strokeColor: color,
                    coords: [item.x0, item.y0, item.x1, item.y1],
                    preFillColor: "rgb(0,255,0, 0)",
                    fillColor: "rgb(255,0,255, 0)",
                  };
                });

                return {
                  original: img.image_url,
                  thumbnail: img.image_url,
                  title: img.doc_id,
                  flagged: img.improve_accuracy_flag,
                  class: img.top_inference_class
                    ? img.top_inference_class
                    : "analytics",
                  filename: img.vm_filename
                    ? img.vm_filename
                    : "TSTAAAB-b_20210927_105230.356514_I2vwItvGUyNS1drA_1_z0-0-0-0_vm.jpg",
                  type: "inferences",
                  start_time: img.created,
                  doc_id: img.doc_id,
                  end_time: img.created + endTime,
                  ogWidth: img.vf_image_width ? img.vf_image_width : 0,
                  ogHeight: img.vf_image_height ? img.vf_image_height : 0,
                  objDection: true,
                  coords: coords,
                  probs: probs,
                  sensorId: img.sensor_id_str,
                  classifier:
                    img.object_detections.length > 0
                      ? img.object_detections[0].inference_class
                      : img.class_label || "",
                  siteId: img.site_id,
                  siteName: img.site_name,
                  probability: img.top_inference_probability
                    ? img.top_inference_probability
                    : undefined,
                };
              }

              return {
                original: img.image_url,
                thumbnail: img.image_url,
                title: img.doc_id,
                flagged: img.improve_accuracy_flag,
                class: img.top_inference_class
                  ? img.top_inference_class
                  : "analytics",
                filename: img.vm_filename
                  ? img.vm_filename
                  : "TSTAAAB-b_20210927_105230.356514_I2vwItvGUyNS1drA_1_z0-0-0-0_vm.jpg",
                type: "inferences",
                start_time: img.created,
                doc_id: img.doc_id,
                end_time: img.created + endTime,
                ogWidth: img.vf_image_width ? img.vf_image_width : 0,
                ogHeight: img.vf_image_height ? img.vf_image_height : 0,
                objDection: false,
                coords: coords,
                probs: probs,
                sensorId: img.sensor_id_str,
                classifier:
                  img.object_detections && img.object_detections.length > 0
                    ? img.object_detections[0].inference_class
                    : img.class_label || "",
                siteId: img.site_id,
                siteName: img.site_name,
                probability: img.top_inference_probability
                  ? img.top_inference_probability
                  : undefined,
              };
            }

            let probs: any[] = [];
            if (img.object_detections && img.object_detections.length > 0) {
              let coordz = img.object_detections.map((item: any) => {
                let color = `rgba(0,0,255, ${item.inference_probability})`;
                probs.push(
                  "  " +
                    Number(item.inference_probability * 100).toFixed(0) +
                    "%" +
                    // " -> " +
                    " (" +
                    item.inference_class +
                    ") ",
                );
                if (item.inference_class === "people") {
                  // color = 'red';
                  color = `rgba(255,0,0, ${item.inference_probability})`;
                }
                if (item.inference_class === "vehicles") {
                  // color = '#00FF00';
                  color = `rgba(50,205,50, ${item.inference_probability})`;
                }
                return {
                  name: img.image_url,
                  shape: "rect",
                  strokeColor: color,
                  coords: [item.x0, item.y0, item.x1, item.y1],
                  preFillColor: "rgb(0,255,0, 0)",
                  fillColor: "rgb(255,0,255, 0)",
                };
              });
              return {
                original: img.image_url,
                thumbnail: img.image_url,
                title: img.doc_id,
                flagged: img.improve_accuracy_flag,
                class: img.top_inference_class
                  ? img.top_inference_class
                  : "analytics",
                filename: img.vm_filename
                  ? img.vm_filename
                  : "TSTAAAB-b_20210927_105230.356514_I2vwItvGUyNS1drA_1_z0-0-0-0_vm.jpg",
                type: "inferences",
                start_time: img.created,
                doc_id: img.doc_id,
                end_time: img.created + endTime,
                ogWidth: img.vf_image_width ? img.vf_image_width : 0,
                ogHeight: img.vf_image_height ? img.vf_image_height : 0,
                objDection: true,
                coords: coordz,
                probs: probs,
                sensorId: img.sensor_id_str,
                classifier:
                  img.object_detections && img.object_detections.length > 0
                    ? img.object_detections[0].inference_class
                    : img.class_label || "",
                siteId: img.site_id,
                siteName: img.site_name,
                probability: img.top_inference_probability
                  ? img.top_inference_probability
                  : undefined,
              };
            }

            return {
              original: img.image_url,
              thumbnail: img.image_url,
              title: img.doc_id,
              flagged: img.improve_accuracy_flag,
              class: img.top_inference_class ? img.top_inference_class : "",
              filename: img.vm_filename
                ? img.vm_filename
                : "TSTAAAB-b_20210927_105230.356514_I2vwItvGUyNS1drA_1_z0-0-0-0_vm.jpg",
              type: "inferences",
              start_time: img.created,
              doc_id: img.doc_id,
              end_time: img.created + endTime,
              ogWidth: img.vf_image_width ? img.vf_image_width : 0,
              ogHeight: img.vf_image_height ? img.vf_image_height : 0,
              objDection: false,
              coords: [],
              probs: probs,
              sensorId: img.sensor_id_str,
              classifier:
                img.object_detections && img.object_detections.length > 0
                  ? img.object_detections[0].inference_class
                  : img.class_label || "",
              siteId: img.site_id,
              siteName: img.site_name,
              probability: img.top_inference_probability
                ? img.top_inference_probability
                : undefined,
            };
          });

          if (filtered.length === 0) {
            result.msg.inference_images.map((img: any, index: number) => {
              if (result.msg.inference_images.length === index + 1) {
                setNewStartTime(img.created);
                setNewEndTime(img.created + endTime);
              }
            });
          }

          // data = data.filter((img:any) => {img !== undefined})

          let imgsT = data.map(
            (image: { original: string; thumbnail: string }) => image.original,
          );

          let currentLeftImages = imagesLeftInf - 10;

          if (currentLeftImages > 0) {
            setHasMore(true);
            setImagesLeftInf((prev) => prev - 10);
          } else {
            setHasMore(false);
          }

          if (reload) {
            setImgTransformed(imgsT);
            setImg([...data]);
          } else {
            setImgTransformed((prev) => [...prev, ...imgsT]);
            setImg((prev) => [...prev, ...data]);
          }
          setLoading(false);
        } else {
          const data = result.msg.alert_images.map(
            (img: any, index: number) => {
              if (result.msg.alert_images.length === index + 1) {
                setNewStartTime(img.created);
                setNewEndTime(img.created + endTime);
              }

              let probs: any[] = [];
              let coordz: any[] = [];
              if (img.object_detections && img.object_detections.length > 0) {
                coordz = img.object_detections.map((item: any) => {
                  let color = `rgba(0,0,255, ${item.inference_probability})`;
                  probs.push(
                    "  " +
                      Number(item.inference_probability * 100).toFixed(0) +
                      "%" +
                      // " -> " +
                      " (" +
                      item.inference_class +
                      ") ",
                  );
                  if (item.inference_class === "people") {
                    // color = 'red';
                    color = `rgba(255,0,0, ${item.inference_probability})`;
                  }
                  if (item.inference_class === "vehicles") {
                    // color = '#00FF00';
                    color = `rgba(50,205,50, ${item.inference_probability})`;
                  }
                  return {
                    name: img.image_url,
                    shape: "rect",
                    strokeColor: color,
                    coords: [item.x0, item.y0, item.x1, item.y1],
                    preFillColor: "rgb(0,255,0, 0)",
                    fillColor: "rgb(255,0,255, 0)",
                  };
                });
              }

              return {
                original: img.image_url,
                thumbnail: img.image_url,
                title: img.doc_id,
                flagged: img.improve_accuracy_flag,
                class: img.class_label,
                doc_id: img.doc_id,
                filename: img.vm_filename ? img.vm_filename : "",
                type: "alerts",
                start_time: img.created,
                end_time: img.created + endTime,
                objDection: false,
                classifier:
                  img.object_detections && img.object_detections.length > 0
                    ? img.object_detections[0].inference_class
                    : img.class_label || "",
                siteId: img.site_id,
                siteName: img.site_name,
                sensorId: img.sensor_id_str,
                probability: img.probability,
                coords: coordz,
                probs: probs,
                advanced: img.advanced,
                advanced_rule_name: img.rule_name || "",
                advanced_rule_type: img.rule_type || "",
                message: img.message || "",
              };
            },
          );

          let imgsT = data.map(
            (image: { original: string; thumbnail: string }) => image.original,
          );
          let currentLeftImages = imagesLeftAl - 10;
          if (currentLeftImages > 0) {
            setHasMore(true);
            setImagesLeftAl((prev) => prev - 10);
          } else {
            setHasMore(false);
          }

          if (reload) {
            setImgTransformed(imgsT);
            setImg([...data]);
          } else {
            setImgTransformed((prev) => [...prev, ...imgsT]);
            setImg((prev) => [...prev, ...data]);
          }

          // setImgTransformed((prev) => [...prev, ...imgsT]);
          // setImg((prev) => [...prev, ...data]);
        }
      }
    } catch (err) {
    } finally {
      setReloadImgs(false);
      setLoading(false);
    }
  };

  const suppressAlert = async () => {
    setLoading(true);
    setSilenceMode(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    const lastSnapShot = docIds;

    if (!!!docIdsSilence.length) {
      handleToastOpen();
      setErr(true);
      setImagesList(lastSnapShot);
      setLoading(false);
      return setMessage(
        "Operation could not be performed, either no image is selected or silencing is not supported for this product",
      );
    }

    if (token) {
      try {
        const request = JSON.stringify({
          doc_ids: docIdsSilence.join(),
          suppress_flag: true,
        });

        const res = fetch(baseUrl + "/suppress_alert", {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        handleToastOpen();
        if (result && result.success) {
          // setAlertList(result.msg);
          // if(result.msg[0]){
          //   setSelectedAlert(result.msg[0]);
          // }
          setErr(false);
          setMessage(
            `successfully silenced ${docIdsSilence.length} alert(s) for the supported product`,
          );
          setImagesLeftAl(+healthParams[2]);
          setImagesLeftInf(+healthParams[1]);
          setHasMore(true);
          setNewStartTime(0);
          setReloadImgs(true);
          setDocIdsSilence([]);
          // for(let id in docIdsSilence){
          //   const index = docIds.indexOf(id);
          //   if (index > -1) {
          //     docIds.splice(index, 1);
          //   }
          // }
        } else {
          setErr(true);
          setImagesList(lastSnapShot);
          setMessage("something went wrong");
        }
      } catch (err) {
        return err;
      } finally {
        fetchImgs(true);
        setLoading(false);
      }
    }
  };

  const fetchImgsForFilter = async (
    reload = false,
    filterOption = "all",
    endTimeEpoch: number,
  ) => {
    let time = healthParams[0].toString();
    let epocSeconds = 0;
    let day = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();

    let startTimeFilter = 0;
    let endTimeFilter = 0;
    if (time.length > 5) {
      let timeArray = time.split(" ");
      let dateSplit = timeArray[0].split("/");
      let timeSplit = timeArray[1].split(":");

      month = parseInt(dateSplit[1]) - 1;
      day = parseInt(dateSplit[0]);
      let hour = parseInt(timeSplit[0]);
      let minutes = parseInt(timeSplit[1]);

      let d = new Date(year, month, day, hour, minutes);
      epocSeconds = Math.round(d.getTime() / 1000);
      startTimeFilter = epocSeconds;
      endTimeFilter = epocSeconds + endTime;

      let hr: number | string = new Date(
        (epocSeconds + endTime) * 1000,
      ).getHours();
      let min: number | string = new Date(
        (epocSeconds + endTime) * 1000,
      ).getMinutes();

      if (+min === 0) {
        min = "00";
      }

      if (min.toString().length === 1) {
        min = `0${min}`;
      }

      if (hr.toString().length == 1) {
        hr = `0${hr}`;
      }
      setEndTimeSelected(`${hr}:${min}`);
    } else {
      let timeSplit = time.split(":");
      let hour = parseInt(timeSplit[0]);
      let minutes = parseInt(timeSplit[1]);
      let d = new Date(year, month, day, hour, minutes);
      epocSeconds = Math.round(d.getTime() / 1000);

      startTimeFilter = epocSeconds;
      endTimeFilter = epocSeconds + endTime;

      let hr: number | string = new Date(
        (epocSeconds + endTime) * 1000,
      ).getHours();
      let min: number | string = new Date(
        (epocSeconds + endTime) * 1000,
      ).getMinutes();

      if (min === 0) {
        min = "00";
      }
      if (min.toString().length === 1) {
        min = `0${min}`;
      }

      if (hr.toString().length === 1) {
        hr = `0${hr}`;
      }
      setEndTimeSelected(`${hr}:${min}`);
    }

    try {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        let request: string = "";
        if (healthParams[4] === "top_org_id") {
          request = JSON.stringify({
            top_org_id: +healthParams[5],
            start_time_filter:
              newStartTime === 0 || reload ? startTimeFilter : newStartTime,
            end_time_filter: endTimeEpoch,
            include_inference_images: inferences,
            include_alert_images: alerts,
            max_count: 10,
            [`${filterOption}`]: true,
          });
        }

        if (healthParams[4] === "site_id") {
          request = JSON.stringify({
            site_ids: ArrayToStringifiedArray([+healthParams[5]]),
            start_time_filter:
              newStartTime === 0 || reload ? startTimeFilter : newStartTime,
            end_time_filter: endTimeEpoch,
            include_inference_images: inferences,
            include_alert_images: alerts,
            max_count: 10,
            [`${filterOption}`]: true,
          });
        }

        if (healthParams[4] === "sensor_id") {
          request = JSON.stringify({
            sensor_ids: ArrayToStringifiedArray([+healthParams[5]]),
            start_time_filter:
              newStartTime === 0 || reload ? startTimeFilter : newStartTime,
            end_time_filter: endTimeFilter,
            include_inference_images: inferences,
            include_alert_images: alerts,
            max_count: 10,
            [`${filterOption}`]: true,
          });
        }

        const res = await fetch(getBaseUrl() + "/activity_images", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        const result = (await (await res).json()) as any;
        if (result.msg.inference_images) {
          if (scale === 0) {
            setImgHeight(273);
            setImgWidth(348);
            setHeight(273 * 1.2);
            setWidth(348 * 1.2);
          }

          let filtered = result.msg.inference_images.filter((img: any) => {
            if (img.classifier === "security" && isNotSystemAdmin) {
              return false;
            }
            return true;
          });

          // imagesLeftInf = imagesLeftInf - filtered.length
          // setImagesLeftInf(prev => prev - filtered.length)

          let data = filtered.map((img: any, index: number) => {
            if (filtered.length === index + 1) {
              setNewStartTime(img.created);
              setNewEndTime(img.created + endTime);
              let coords: any[] = [];
              let probs: any[] = [];
              if (img.object_detections && img.object_detections.length > 0) {
                coords = img.object_detections.map((item: any) => {
                  probs.push(
                    "  " +
                      Number(item.inference_probability * 100).toFixed(0) +
                      "%" +
                      // " -> " +
                      " (" +
                      item.inference_class +
                      ") ",
                  );

                  // let color = 'blue';
                  let color = `rgba(0,0,255, ${item.inference_probability})`;

                  if (item.inference_class === "people") {
                    // color = 'red';
                    color = `rgba(255,0,0, ${item.inference_probability})`;
                  }
                  if (item.inference_class === "vehicles") {
                    // color = '#00FF00';
                    color = `rgba(50,205,50, ${item.inference_probability})`;
                  }
                  return {
                    name: img.image_url,
                    shape: "rect",
                    strokeColor: color,
                    coords: [item.x0, item.y0, item.x1, item.y1],
                    preFillColor: "rgb(0,255,0, 0)",
                    fillColor: "rgb(255,0,255, 0)",
                  };
                });

                return {
                  original: img.image_url,
                  thumbnail: img.image_url,
                  title: img.doc_id,
                  flagged: img.improve_accuracy_flag,
                  class: img.top_inference_class
                    ? img.top_inference_class
                    : "analytics",
                  filename: img.vm_filename
                    ? img.vm_filename
                    : "TSTAAAB-b_20210927_105230.356514_I2vwItvGUyNS1drA_1_z0-0-0-0_vm.jpg",
                  type: "inferences",
                  start_time: img.created,
                  doc_id: img.doc_id,
                  end_time: img.created + endTime,
                  ogWidth: img.vf_image_width ? img.vf_image_width : 0,
                  ogHeight: img.vf_image_height ? img.vf_image_height : 0,
                  objDection: true,
                  coords: coords,
                  probs: probs,
                  sensorId: img.sensor_id_str,
                  classifier:
                    img.object_detections && img.object_detections.length > 0
                      ? img.object_detections[0].inference_class
                      : img.class_label || "",
                  siteId: img.site_id,
                  siteName: img.site_name,
                  probability: img.top_inference_probability
                    ? img.top_inference_probability
                    : undefined,
                };
              }

              return {
                original: img.image_url,
                thumbnail: img.image_url,
                title: img.doc_id,
                flagged: img.improve_accuracy_flag,
                class: img.top_inference_class
                  ? img.top_inference_class
                  : "analytics",
                filename: img.vm_filename
                  ? img.vm_filename
                  : "TSTAAAB-b_20210927_105230.356514_I2vwItvGUyNS1drA_1_z0-0-0-0_vm.jpg",
                type: "inferences",
                start_time: img.created,
                doc_id: img.doc_id,
                end_time: img.created + endTime,
                ogWidth: img.vf_image_width ? img.vf_image_width : 0,
                ogHeight: img.vf_image_height ? img.vf_image_height : 0,
                objDection: false,
                coords: coords,
                probs: probs,
                sensorId: img.sensor_id_str,
                classifier:
                  img.object_detections && img.object_detections.length > 0
                    ? img.object_detections[0].inference_class
                    : img.class_label || "",
                siteId: img.site_id,
                siteName: img.site_name,
                probability: img.top_inference_probability
                  ? img.top_inference_probability
                  : undefined,
              };
            }

            let probs: any[] = [];
            let coordz: any[] = [];
            if (img.object_detections && img.object_detections.length > 0) {
              coordz = img.object_detections.map((item: any) => {
                let color = `rgba(0,0,255, ${item.inference_probability})`;
                probs.push(
                  "  " +
                    Number(item.inference_probability * 100).toFixed(0) +
                    "%" +
                    // " -> " +
                    " (" +
                    item.inference_class +
                    ") ",
                );
                if (item.inference_class === "people") {
                  // color = 'red';
                  color = `rgba(255,0,0, ${item.inference_probability})`;
                }
                if (item.inference_class === "vehicles") {
                  // color = '#00FF00';
                  color = `rgba(50,205,50, ${item.inference_probability})`;
                }
                return {
                  name: img.image_url,
                  shape: "rect",
                  strokeColor: color,
                  coords: [item.x0, item.y0, item.x1, item.y1],
                  preFillColor: "rgb(0,255,0, 0)",
                  fillColor: "rgb(255,0,255, 0)",
                };
              });
              return {
                original: img.image_url,
                thumbnail: img.image_url,
                title: img.doc_id,
                flagged: img.improve_accuracy_flag,
                class: img.top_inference_class
                  ? img.top_inference_class
                  : "analytics",
                filename: img.vm_filename
                  ? img.vm_filename
                  : "TSTAAAB-b_20210927_105230.356514_I2vwItvGUyNS1drA_1_z0-0-0-0_vm.jpg",
                type: "inferences",
                start_time: img.created,
                doc_id: img.doc_id,
                end_time: img.created + endTime,
                ogWidth: img.vf_image_width ? img.vf_image_width : 0,
                ogHeight: img.vf_image_height ? img.vf_image_height : 0,
                objDection: true,
                coords: coordz,
                probs: probs,
                sensorId: img.sensor_id_str,
                classifier:
                  img.object_detections && img.object_detections.length > 0
                    ? img.object_detections[0].inference_class
                    : img.class_label || "",
                siteId: img.site_id,
                siteName: img.site_name,
                probability: img.top_inference_probability
                  ? img.top_inference_probability
                  : undefined,
              };
            }

            return {
              original: img.image_url,
              thumbnail: img.image_url,
              title: img.doc_id,
              flagged: img.improve_accuracy_flag,
              class: img.top_inference_class ? img.top_inference_class : "",
              filename: img.vm_filename
                ? img.vm_filename
                : "TSTAAAB-b_20210927_105230.356514_I2vwItvGUyNS1drA_1_z0-0-0-0_vm.jpg",
              type: "inferences",
              start_time: img.created,
              doc_id: img.doc_id,
              end_time: img.created + endTime,
              ogWidth: img.vf_image_width ? img.vf_image_width : 0,
              ogHeight: img.vf_image_height ? img.vf_image_height : 0,
              objDection: false,
              coords: coordz,
              probs: probs,
              sensorId: img.sensor_id_str,
              classifier:
                img.object_detections && img.object_detections.length > 0
                  ? img.object_detections[0].inference_class
                  : img.class_label || "",
              siteId: img.site_id,
              siteName: img.site_name,
              probability: img.top_inference_probability
                ? img.top_inference_probability
                : undefined,
            };
          });

          if (filtered.length === 0) {
            result.msg.inference_images.map((img: any, index: number) => {
              if (result.msg.inference_images.length === index + 1) {
                setNewStartTime(img.created);
                setNewEndTime(img.created + endTime);
              }
            });
          }

          // data = data.filter((img:any) => {img !== undefined})
          let imgsT = data.map(
            (image: { original: string; thumbnail: string }) => image.original,
          );

          let currentLeftImages = +healthParams[1] - 10;

          if (currentLeftImages > 0) {
            setHasMore(true);
            setImagesLeftInf(+healthParams[1] - 10);
          } else {
            setHasMore(false);
          }

          if (reload) {
            setImgTransformed(imgsT);
            setImg([...data]);
          } else {
            setImgTransformed((prev) => [...prev, ...imgsT]);
            setImg((prev) => [...data]);
          }
          setLoading(false);
        } else {
          const data = result.msg.alert_images.map(
            (img: any, index: number) => {
              if (result.msg.alert_images.length === index + 1) {
                setNewStartTime(img.created);
                setNewEndTime(img.created + endTime);
              }

              let probs: any[] = [];
              let coordz: any[] = [];
              if (img.object_detections && img.object_detections.length > 0) {
                coordz = img.object_detections.map((item: any) => {
                  let color = `rgba(0,0,255, ${item.inference_probability})`;
                  probs.push(
                    "  " +
                      Number(item.inference_probability * 100).toFixed(0) +
                      "%" +
                      // " -> " +
                      " (" +
                      item.inference_class +
                      ") ",
                  );
                  if (item.inference_class === "people") {
                    // color = 'red';
                    color = `rgba(255,0,0, ${item.inference_probability})`;
                  }
                  if (item.inference_class === "vehicles") {
                    // color = '#00FF00';
                    color = `rgba(50,205,50, ${item.inference_probability})`;
                  }
                  return {
                    name: img.image_url,
                    shape: "rect",
                    strokeColor: color,
                    coords: [item.x0, item.y0, item.x1, item.y1],
                    preFillColor: "rgb(0,255,0, 0)",
                    fillColor: "rgb(255,0,255, 0)",
                  };
                });
              }

              return {
                original: img.image_url,
                thumbnail: img.image_url,
                title: img.doc_id,
                flagged: img.improve_accuracy_flag,
                class: img.class_label,
                doc_id: img.doc_id,
                filename: img.vm_filename ? img.vm_filename : "",
                type: "alerts",
                start_time: img.created,
                end_time: img.created + endTime,
                objDection: false,
                coords: coordz,
                probs: probs,
                classifier:
                  img.object_detections && img.object_detections.length > 0
                    ? coordz
                    : img.class_label || "",
                siteId: img.site_id,
                siteName: img.site_name,
                sensorId: img.sensor_id_str,
                probability: img.probability,
                advanced: img.advanced,
                advanced_rule_name: img.rule_name || "",
                advanced_rule_type: img.rule_type || "",
              };
            },
          );

          let imgsT = data.map(
            (image: { original: string; thumbnail: string }) => image.original,
          );

          let currentLeftImages = +healthParams[2] - 10;
          if (currentLeftImages > 0) {
            setHasMore(true);
            setImagesLeftAl(+healthParams[2] - 10);
          } else {
            setHasMore(false);
          }

          if (reload) {
            setImgTransformed(imgsT);
            setImg([...data]);
          } else {
            setImgTransformed((prev) => [...prev, ...imgsT]);
            setImg((prev) => [...data]);
          }

          // setImgTransformed((prev) => [...prev, ...imgsT]);
          // setImg((prev) => [...prev, ...data]);
        }
      }
    } catch (err) {
    } finally {
      setReloadImgs(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImgs();
  }, [inferences, alerts, page]);

  // Flag Images for Training (Bulk Flagging)
  const flagImages = async () => {
    setLoading(true);
    var baseUrl = getBaseUrl();
    const lastSnapShot = docIds;
    var token = await appStore?.authService.getAuthorisedToken();

    setMessage("");
    if (token) {
      try {
        let request = JSON.stringify({
          doc_ids: `${docIds}`,
          doc_type: inferences ? "inference" : "alert",
        });
        const res = await fetch(baseUrl + "/flag_for_training", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        if (!res.ok) {
          setErr(true);
          setMessage("Something went wrong, please try again later");
        }
        const result = (await (await res).json()) as any;
        handleToastOpen();

        if (result.success) {
          setImagesLeftAl(+healthParams[2]);
          setImagesLeftInf(+healthParams[1]);
          setHasMore(true);
          setNewStartTime(0);
          setReloadImgs(true);
          setErr(false);
          setMessage(`Your changes  have been successfully saved`);
        }

        if (!result.success) {
          setErr(true);
          setImagesList(lastSnapShot);
          setMessage("Something went wrong, please select at least 1 image");
        }
      } catch (err) {
      } finally {
        fetchImgs(true);
        setLoading(false);
      }
    }
  };

  const handleClickOpen = () => {
    toogleFullScreenModal();
  };

  const handleClose = () => {
    setIsViewerOpen(false);
    setImagesList([]);
    toogleFullScreenModal();
  };

  let loadedImages: React.ReactNode[] = [];
  if (!!img) {
    // filtering Logic
    let imgs = img.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.doc_id === value.doc_id),
    );
    //filtering Logic
    imgs.map((image, index) => {
      if (img.length === index + 1) {
        loadedImages.push(
          <div
            ref={lastImage}
            style={{
              alignItems: "center",
              overflow: "hidden",
              textAlign: "center",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              borderRadius: 10,
              paddingRight: 10,
              backgroundColor: "rgba(246, 246, 246)",
              margin: 10,
              boxShadow: "0 2px 4px rgba(0, 0, 0,0.1)",
              width: image.type === "alerts" ? width : width,
              // border: '1px solid grey',
              // &hover: {
              //   backgroundColor: '#f6f6f6',
              // }
            }}
          >
            <div
              className={classer.hov}
              style={{
                backgroundColor: "#f6f6f6",
                // width:  140,
                // height: 110,
                width: image.type === "alerts" ? imgWidth : imgWidth,
                height: image.type === "alerts" ? imgHeight : imgHeight,
                marginTop: 10,
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                padding: 9,
                overflow: "hidden",
              }}
              onClick={() => {
                // if (image.type ==== 'alerts') {
                //   return;
                // }
                openMapperDetails(
                  image.siteId,
                  image.siteName,
                  image.sensorId,
                  image.filename,
                  image.classifier,
                  image.original,
                  image.objDection,
                  true,
                  image.coords,
                  image.start_time,
                  image.probability ? image.probability : 0,
                  // [' 60%', ' 23%', ' 45%']
                  image.probs ? image.probs : [],
                  index,
                  image.doc_id,
                  image.message || "",
                  image.rule_name || "",
                  image.rule_type || "",
                );
              }}
            >
              {image.type === "alerts" && (
                <img
                  src={image.original}
                  // maxWidth={`${width}`}
                  height={120}
                  onClick={() => {
                    openMapperDetails(
                      image.siteId,
                      image.siteName,
                      image.sensorId,
                      image.filename,
                      image.classifier,
                      image.original,
                      image.objDection,
                      true,
                      image.coords,
                      image.start_time,
                      image.probability ? image.probability : 0,
                      image.probs ? image.probs : [],
                      index,
                      image.doc_id,
                      image.message,
                      image.rule_name,
                      image.rule_type,
                    );
                    // openImageViewer(index);
                  }}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    minHeight: height,
                    objectFit: "fill",
                    width: imgWidth,
                    height: imgHeight,
                    borderRadius: 3,
                    cursor: "pointer",
                  }}
                  alt="image"
                />
              )}
              {/* <Mapper coordinates={coordsExtractor()} imgURL={''} /> */}
              {image.type === "inferences" &&
                image.ogWidth !== 0 &&
                !image.objDection && (
                  <Mapper
                    key={image.filename}
                    coords={coordsExtractor(image.filename)}
                    imgUrl={image.original}
                    width={width}
                    ogWidth={image.ogWidth}
                  />
                )}

              {image.type === "inferences" &&
                image.ogWidth !== 0 &&
                image.objDection &&
                image.coords &&
                image.coords.length > 0 && (
                  <MuiltMapper
                    height={height}
                    img_height={height}
                    auto_play={setAuto}
                    key={image.filename}
                    coords={image.coords}
                    imgUrl={image.original}
                    width={width}
                    ogWidth={image.ogWidth}
                  />
                )}
              {image.type === "inferences" && image.ogWidth === 0 && (
                <div
                  className={classer.hov}
                  style={{
                    backgroundColor: "#f6f6f6",
                    width: imgWidth,
                    height: imgHeight,
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    padding: 9,
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={image.original}
                    onClick={() => {
                      openImageViewer(index);
                    }}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      minHeight: height,
                      objectFit: "fill",
                      width: imgWidth,
                      height: imgHeight,
                      // backgroundSize: 'cover',
                      borderRadius: 3,
                      cursor: "pointer",
                    }}
                  />
                </div>
              )}
            </div>
            {image.class && (
              <div
                style={{
                  backgroundColor: "#f6f6f6",
                  alignItems: "center",
                  fontSize: 12,
                  color: "sky-blue",
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                {image.class === "misc"
                  ? "miscellaneous"
                  : image.class.replace("human1.", "") || "miscellaneous"}
                {image.probability !== undefined &&
                  " " + Number(image.probability * 100).toFixed(0) + "%"}
                {image.class === "" && "analytics"}
                {image.class === "analytics" && ":  " + image.probs}
              </div>
            )}
            {!image.class && (
              <div
                style={{
                  backgroundColor: "#f6f6f6",
                  alignItems: "center",
                  fontSize: 12,
                  color: "sky-blue",
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                {image?.advanced ? "alert" : "No objects detected"}
                {image.probability !== undefined &&
                  " " + Number(image.probability * 100).toFixed(0) + "%"}
              </div>
            )}
            <div
              style={{
                display: "flex",
                margin: 5,
                padding: 5,
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <FormControl>
                {!image.flagged && (
                  <Tooltip
                    title="Select image"
                    aria-label="Select image for flagging"
                    arrow
                  >
                    <Checkbox
                      className={classer.form_component_checkbox}
                      disabled={false}
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      defaultChecked={false}
                      onChange={(e) => {
                        imagesListHandler(image.doc_id, e, image.siteId);
                      }}
                    />
                  </Tooltip>
                )}
              </FormControl>
              &nbsp;
              <Tooltip
                title="Flag for training"
                aria-label="Flag for training"
                arrow
              >
                <>{image.flagged && <FlagIcon color="primary" />}</>
              </Tooltip>
            </div>
          </div>,
        );
      } else {
        loadedImages.push(
          <div
            style={{
              alignItems: "center",
              overflow: "hidden",
              textAlign: "center",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              borderRadius: 10,
              paddingRight: 10,
              backgroundColor: "rgba(246, 246, 246)",
              margin: 10,
              boxShadow: "0 2px 4px rgba(0, 0, 0,0.1)",
              width: image.type === "alerts" ? width : width,
            }}
          >
            <div
              className={classer.hov}
              style={{
                backgroundColor: "#f6f6f6",
                // width: 140,
                // height: 110,
                width: image.type === "alerts" ? imgWidth : imgWidth,
                height: image.type === "alerts" ? imgHeight : imgHeight,
                marginTop: 10,
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                padding: 9,
                overflow: "hidden",
              }}
              onClick={() => {
                openMapperDetails(
                  image.siteId,
                  image.siteName,
                  image.sensorId,
                  image.filename,
                  image.classifier,
                  image.original,
                  image.objDection,
                  true,
                  image.coords,
                  image.start_time,
                  image.probability ? image.probability : 0,
                  image.probs ? image.probs : [],
                  index,
                  image.doc_id,
                  image.message || "",
                  image.rule_name || "",
                  image.rule_type || "",
                );
              }}
            >
              {image.type === "alerts" && (
                <img
                  src={image.original}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    objectFit: "fill",
                    width: imgWidth,
                    height: imgHeight,
                    borderRadius: 3,
                    cursor: "pointer",
                  }}
                />
              )}
              {image.type === "inferences" &&
                image.ogWidth !== 0 &&
                !image.objDection && (
                  <Mapper
                    key={image.filename}
                    coords={coordsExtractor(image.filename)}
                    imgUrl={image.original}
                    width={width}
                    ogWidth={image.ogWidth}
                  />
                )}

              {image.type === "inferences" &&
                image.ogWidth !== 0 &&
                image.objDection &&
                image.coords &&
                image.coords.length > 0 && (
                  <MuiltMapper
                    height={height}
                    img_height={height}
                    auto_play={setAuto}
                    key={image.filename}
                    coords={image.coords}
                    imgUrl={image.original}
                    width={width}
                    ogWidth={image.ogWidth}
                  />
                )}
              {image.type === "inferences" && image.ogWidth === 0 && (
                <img
                  src={image.original}
                  // onClick={() => {
                  //   openImageViewer(index);
                  // }}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    // minHeight: height,
                    objectFit: "fill",
                    width: imgWidth,
                    height: imgHeight,
                    borderRadius: 3,
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
            {image.class && (
              <div
                style={{
                  backgroundColor: "#f6f6f6",
                  alignItems: "center",
                  fontSize: 12,
                  color: "sky-blue",
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                {image.class === "misc"
                  ? "miscellaneous"
                  : image.class.replace("human1.", "") || "miscellaneous"}
                {image.probability !== undefined &&
                  " " + Number(image.probability * 100).toFixed(0) + "%"}
                {image.class === "" && "analytics"}
                {image.class === "analytics" && ":  " + image.probs}
              </div>
            )}
            {!image.class && (
              <div
                style={{
                  backgroundColor: "#f6f6f6",
                  alignItems: "center",
                  fontSize: 12,
                  color: "sky-blue",
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                {image?.advanced ? "alert" : "No objects detected."}
                {image.probability !== undefined &&
                  " " + Number(image.probability * 100).toFixed(0) + "%"}
              </div>
            )}
            <div
              style={{
                display: "flex",
                margin: 5,
                padding: 5,
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <FormControl>
                {!image.flagged && (
                  <Tooltip
                    title="Select image"
                    aria-label="Select image for flagging"
                    arrow
                  >
                    <Checkbox
                      className={classer.form_component_checkbox}
                      disabled={false}
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      defaultChecked={false}
                      onChange={(e) => {
                        imagesListHandler(image.doc_id, e, image.siteId);
                      }}
                    />
                  </Tooltip>
                )}
              </FormControl>
              &nbsp;
              <Tooltip
                title="Flag for training"
                aria-label="Flag for training"
                arrow
              >
                <>{image.flagged && <FlagIcon color="primary" />}</>
              </Tooltip>
              {/* <Button size='small' color='primary'></Button> */}
            </div>
          </div>,
        );
      }
    });
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button>
      <Dialog
        fullScreen
        open={showModal}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            // backgroundColor: 'white',
            boxShadow: "none",
            backgroundColor: "rgba(246, 246, 246, 0.92)",
            zIndex: 100,
          },
        }}
      >
        <CustomModal
          closeHandler={() => {
            setOpenWarning(false);
          }}
          open={openWarning}
          title={"confirm"}
          continueAction={true}
          onContinueHandler={() => {
            suppressAlert();
            setOpenWarning(false);
          }}
        >
          <h1
            style={{
              fontSize: 14,
              color: "#15325F",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            WARNING!
          </h1>
          <br />
          <p
            style={{
              fontSize: 14,
              color: "#F32424",
              fontWeight: "bold",
              textAlign: "center",
              marginLeft: 30,
              marginRight: 30,
            }}
          >
            Silencing an alert means that DeepAlert will not generate alerts for
            that specific camera for a period of 2 weeks for similar objects in
            similar locations as detected in the silenced alert. This is
            intended mostly for stationary objects causing nuisance alerts. When
            silencing an alert, it will also be flagged for training.
          </p>
          <p
            style={{
              fontSize: 14,
              color: "#15325F",
              fontWeight: "bold",
              textAlign: "center",
              marginLeft: 30,
              marginRight: 30,
            }}
          >
            Are you sure you want to silence the alert(s) ?
          </p>
        </CustomModal>
        {!isViewerOpen && (
          <AppBar className={classes.appBar} position="relative">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Activity
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>
        )}
        {/* {inferences && ( */}
        <MapperDetails
          openHandler={mdOpenHandler}
          closeHandler={mdCloseHandler}
          coords={md_coords}
          siteId={md_siteId}
          created={md_created}
          sensorId={md_sensorId}
          siteName={md_siteName}
          fileName={md_file_name}
          classifier={md_classifier}
          imgUrl={md_imgUrl}
          objDetection={md_objDection}
          open={md_open}
          inferences={inferences}
          setSelectedItem={setSelectedItem}
          handleToastClose={handleToastClose}
          handleMClose={handleClose}
          probability={mdProbability}
          probs={md_probs}
          index={md_index}
          navigator={imageNavigator}
          appStore={appStore}
          doc_id={md_doc_id}
          available_images={img.length}
          reloadImages={fetchImgs}
          message={md_message}
          advanced_rule_type={md_rule_type}
          advanced_rule_name={md_rule_name}
        />
        {/* ) */}

        <List>
          <div style={{ width: "90%", overflow: "hidden" }}>
            <ListItem button>
              <ListItemText primary={"Time Selected: " + healthParams[0]} />
              <Divider />
              <ListItemText primary={alerts ? "Alerts " : "Inferences"} />
            </ListItem>
            <Divider />
            <div
              style={{
                justifyContent: "flex-start",
              }}
            >
              {message && !loading && !openTimeToast && (
                <div style={{ marginTop: 30 }} />
              )}
              <Collapse in={openToast}>
                {message && !openTimeToast && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: 20,
                      marginBottom: 35,
                      justifyContent: "center",
                      // alignItems: 'center',
                    }}
                  >
                    <Alert
                      severity={err ? "error" : "success"}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            handleToastClose();
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {message}
                    </Alert>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 20,
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        padding: 20,
                      }}
                    >
                      <Button
                        variant="contained"
                        className={`${classic.cancel_button2} {$classes.bold_text}`}
                        onClick={(e) => {
                          handleClose();
                        }}
                      >
                        Close &nbsp;
                        {/* {<WarningRoundedIcon style={{ color: 'white' }} />} */}
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        // disabled={alerts}
                        variant="contained"
                        className={classic.mute_button}
                        onClick={(e) => {
                          handleToastClose();
                        }}
                      >
                        Continue &nbsp;
                        {/* {<WarningRoundedIcon style={{ color: 'white' }} />} */}
                      </Button>
                    </div>
                  </div>
                )}
              </Collapse>

              {!!!message.length && !filtering && (
                <div
                  className={classic.button_container_small}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <Button
                    variant="contained"
                    className={`${classic.cancel_button2} {$classes.bold_text}`}
                    onClick={(e) => decreaseImageHandler()}
                  >
                    Zoom Out&nbsp;
                    <ZoomOutIcon />
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    className={`${classic.cancel_button2} {$classes.bold_text}`}
                    onClick={(e) => {
                      increaseImageHandler();
                    }}
                  >
                    Zoom In &nbsp;
                    <ZoomInIcon />
                  </Button>
                  {!alerts && (
                    <Tooltip title={`list alerts from ${healthParams[0]}`}>
                      <Button
                        variant="contained"
                        className={classic.mute_button}
                        onClick={(e) => {
                          imageTypeHandler("alerts");
                          setNewStartTime(0);
                          setNewEndTime(0);
                        }}
                      >
                        Alerts &nbsp;
                        {<WarningRoundedIcon style={{ color: "white" }} />}
                      </Button>
                    </Tooltip>
                  )}
                  {!inferences &&
                    appStore!.authService.getRoles().indexOf("site_admin") >=
                      0 && (
                      <Tooltip
                        title={`list inferences from ${healthParams[0]}`}
                      >
                        <Button
                          variant="contained"
                          className={classic.mute_button}
                          onClick={(e) => {
                            imageTypeHandler("inferences");
                            setNewStartTime(0);
                            setNewEndTime(0);
                          }}
                          style={{ width: 130 }}
                        >
                          Inference &nbsp;
                          {<CameraRearRoundedIcon style={{ color: "white" }} />}
                        </Button>
                      </Tooltip>
                    )}
                  <Button
                    variant="contained"
                    className={classic.mute_button}
                    onClick={(e) => {
                      setNewStartTime(0);
                      flagImages();
                    }}
                  >
                    Flag &nbsp; &nbsp;{<FlagIcon style={{ color: "white" }} />}
                  </Button>
                  {!inferences && (
                    <Button
                      variant="contained"
                      className={classic.mute_button}
                      onClick={(e) => {
                        setNewStartTime(0);
                        setOpenWarning(true);
                        // suppressAlert();
                      }}
                    >
                      Silence &nbsp; &nbsp;
                      {<VolumeOffIcon style={{ color: "white" }} />}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    className={classic.mute_button}
                    onClick={(e) => {
                      setFiltering(true);
                    }}
                  >
                    Time Filter &nbsp; &nbsp;
                  </Button>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 40,
                  marginRight: 40,
                  marginTop: 20,
                  // marginBottom: 20,
                  height: 50,
                }}
              >
                <div>{loading ? <LinearProgress /> : ""}</div>
                <div
                  className={classic.bold_text}
                  style={{ alignSelf: "center", marginTop: 20 }}
                >
                  {/* {loading && 'Loading...'} */}
                </div>
              </div>

              {filtering && (
                <div
                  style={{
                    marginLeft: 50,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 25,
                    minWidth: 450,
                  }}
                >
                  {/* ######## TIME COMPONENT ####### */}

                  {message && openTimeToast && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 20,
                        marginBottom: 35,
                        justifyContent: "center",
                        // alignItems: 'center',
                      }}
                    >
                      <Alert
                        severity={err ? "error" : "success"}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpenTimeToast(false);
                              setMessage("");
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {message}
                      </Alert>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 30,
                    }}
                  >
                    <div style={{ marginRight: 30 }}>
                      {/* <p>Start Time</p> */}
                      <form className={classez.container} noValidate>
                        <TextField
                          id="datetime-local"
                          label="Start Time"
                          type="datetime-local"
                          // defaultValue="2017-05-24T10:30"
                          value={filteredStartTime}
                          className={classez.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            setStartTimeFilter(e.target.value);
                          }}
                        />
                      </form>
                    </div>
                    <div style={{ marginLeft: 30 }}>
                      {/* <p>End Time</p> */}
                      <form className={classez.container} noValidate>
                        <TextField
                          id="datetime-local"
                          label="End Time"
                          type="datetime-local"
                          // defaultValue="2017-05-24T10:30"
                          value={filteredEndTime}
                          className={classez.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            setEndTimeFilter(e.target.value);
                          }}
                        />
                      </form>
                    </div>
                  </div>

                  {/* ######## TIME COMPONENT ####### */}
                  <div style={{ marginTop: 20, padding: 15, display: "flex" }}>
                    <Button
                      variant="contained"
                      className={`${classic.cancel_button2} {$classes.bold_text}`}
                      onClick={(e) => {
                        setFiltering(false);
                        setMessage("");
                      }}
                    >
                      Cancel&nbsp;
                    </Button>
                    <Button
                      variant="contained"
                      className={classic.mute_button}
                      onClick={(e) => {
                        applyTimeFilter();
                      }}
                    >
                      Apply
                      {/* &nbsp; &nbsp;{<FlagIcon style={{ color: 'white' }} />} */}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </List>
        <div
          style={{
            // background: 'linear-gradient(to bottom, #430089, #82ffa1)',
            marginBottom: -10,
            backgroundColor: "#f6f6f6",
          }}
        ></div>
        {!openToast && (
          <div
            style={{
              padding: 5,
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              overflowY: "scroll",
              backgroundColor: "white",
              borderRadius: "25px",
              border: "0.5px solid rgba(21, 50, 95, 0.5)",
              justifyContent: inferences ? "flex-start" : "flex-start",
              // borderBottom: 'none',
              // margin: 10,
              // marginLeft: 60,
              // paddingTop: 20,
              // opacity: 0.5
            }}
          >
            {loadedImages}
            {loadedImages.length === 0 && !loading && (
              <span style={{ margin: "0 auto" }}>No data found.</span>
            )}
            {isViewerOpen && (
              // <LazyLoad height={200} scroll={true}>
              <ImageViewer
                src={imgTransformed}
                disableScroll={true}
                // src={imgzTransformed}
                currentIndex={currentImage}
                onClose={closeImageViewer}
              />
            )}
          </div>
        )}
      </Dialog>
    </div>
  );
};
