import React, { useEffect } from "react";
import MultipleSelect from "./MultipleSelect";
import CustomButton from "../../UI/CustomButton";
import { MainContext } from "../../containers/HomePage/HomePage";
import LinearProgress from "@material-ui/core/LinearProgress";
import CustomInput from "./CustomInput";

interface Props {
  zone?: string;
  class_label?: string;
  isEdit?: boolean;
  ruleId?: string | number;
  zoneId?: string | number;
  siteId?: any;
  sensorId?: any;
  site?: any;
  sensorDefinedRule: any;
  ruleOption?: string;
  defaultRule?: boolean;
}

const analyticsList = [
  "alert_zones",
  "alert_zones_and_counts",
  "Known Vehicle Filter",
  "suspicious_activity_self_storage",
  "PPE Detection - coming soon!",
  "Tailgating - coming soon!",
  "ALPR - coming soon!",
  "Loitering - coming soon!",
];

const scheduledList = [
  "Guard Tour",
  "Guard Management (no guard)",
  "Snapshot",
  "Scene Change Alert",
  "unusual_activity",
  "Left object - coming soon!",
];

const ruletypes = [...analyticsList, ...scheduledList];

const combination_logic_list = [
  "< (Less than)",
  "> (greater than)",
  "<= (less than or equal to)",
  ">= (greater than or equal to)",
  "== (equal to)",
];
const object_motion_list = ["include all", "moving only", "stationary only"];
const colours_list = [
  "white",
  "black",
  "orange",
  "yellow",
  "blue",
  "lime",
  "green",
];

const AddZoneForm: React.FC<Props> = ({
  ruleId,
  zone,
  class_label,
  isEdit,
  zoneId,
  siteId,
  sensorDefinedRule,
  sensorId,
  site,
  ruleOption,
  defaultRule,
}) => {
  let ruleTypes: string[] = [];

  const ruleOptions = sensorDefinedRule?.fields?.rule_configs
    ? Object.keys(sensorDefinedRule?.fields?.rule_configs)
    : null;

  let ruleObj =
    Array.isArray(ruleOptions) && ruleOption
      ? sensorDefinedRule?.fields?.rule_configs[
          `${ruleOption ? ruleOption : ruleOptions[0]}`
        ] || sensorDefinedRule?.fields?.rule_configs[`${ruleOptions[0]}`]
      : undefined;

  console.debug(
    "Wizard Zones 1",
    ruleObj.zone_configs,
    "ruleOptions: ",
    ruleOptions,
    "ruleOption: ",
    ruleOption,
  );

  const { zone_configs, label } = ruleObj;

  if (zone_configs) {
    ruleTypes = zone_configs.map((item: any) => item.type) || [];
  }

  const [classifierName, setClassifierName] = React.useState("");
  const [clearZone, setClearZone] = React.useState(false);
  const [clearClasses, setClearClasses] = React.useState(false);
  const ctx = React.useContext(MainContext);
  const [zone_options, setZoneOptions] = React.useState<string[]>(
    ctx.zoneOptions || [],
  );
  const [loading, setLoading] = React.useState(false);
  const [classLabels, setClassLabels] = React.useState<string[] | string>([]);
  const [safetyClassLabels, setSafetyClassLabels] = React.useState<
    string[] | string
  >(["hard hat", "no hard hat", "safety vest", "no safety vest"]);
  const [count, setCount] = React.useState(0);
  const [threshold, setThreshold] = React.useState(0);

  const [zones, setZones] = React.useState<string[]>([]);

  console.debug("ZONES: ", zones);

  const [objectMotion, setObjectMotion] = React.useState("");
  const [colour, setColour] = React.useState("");
  const [classes, setClasses] = React.useState<string[] | string>([]);
  const [countLogic, setcountLogic] = React.useState("");
  const [ruleType, setRuleType] = React.useState("");
  const [cadenceSecs, setCadenceSecs] = React.useState("");

  const [touched, setTouched] = React.useState(false);

  const resetForm = () => {
    setZones([]);
    setCount(0);
    setcountLogic("");
    setObjectMotion("");
    setThreshold(0);
    setClasses([]);
  };

  const alert_config_type = sensorDefinedRule?.fields?.alert_config_type;
  const showOnlyFullImage = alert_config_type
    ? ["Snapshot", "UnusualTrigger", "SuspiciousActivitySelfStorage"].includes(
        alert_config_type,
      )
    : false;

  const getClassLabels = async (siteId: number) => {
    setLoading(true);
    try {
      const token = await ctx.appStore?.authService.getAuthorisedToken();
      if (token && siteId) {
        let labelResults: any =
          await ctx.appStore?.functionService.getClassifierForSite(
            token,
            siteId,
          );
        if (labelResults) {
          let config: any;

          labelResults = labelResults.class_labels;
          console.debug(
            "LabelResults: ",
            labelResults,
            "zone_configs: ",
            zone_configs,
          );
          if (zone_configs) {
            config = zone_configs.find(
              (item: any) => item.type === "class_label",
            );
            labelResults = config.options;
            setClassifierName(
              config?.options && config.options.length > 0
                ? config.options[0].split(".")[0]
                : labelResults.name,
            );
          } else {
            setClassifierName(
              labelResults.name || labelResults[0]
                ? labelResults[0].split(".")[0]
                : undefined,
            );
          }

          let class_labels: string[] | [] =
            labelResults.map((item: string) => {
              if (item.includes(".")) {
                if (item.includes(":")) {
                  return item.split(".")[1].split(":")[0];
                }
                return item.split(".")[1];
              } else {
                return item;
              }
            }) || [];
          setClassLabels(class_labels);
        } else {
          // Handle the case when token or siteId is undefined
          setClassLabels([]);
        }
      }
    } catch (error) {
      console.debug("Error fetching class labels:", error);
    } finally {
      if (zone_configs && defaultRule) {
        let config = zone_configs.find(
          (item: any) => item.type === "class_label",
        );
        let labelResults = config.options;
        setClassifierName(
          config?.options && config.options.length > 0
            ? config.options[0].split(".")[0]
            : labelResults.name,
        );
        let class_labels: string[] | [] =
          labelResults.map((item: string) => {
            if (item.includes(".")) {
              if (item.includes(":")) {
                return item.split(".")[1].split(":")[0];
              }
              return item.split(".")[1];
            } else {
              return item;
            }
          }) || [];
        setClassLabels(class_labels);
        console.debug(
          "LabelResults: ",
          labelResults,
          "zone_configs: ",
          zone_configs,
          "config: ",
          config,
          "class_labels",
          class_labels,
        );
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkData = () => {
      if (!zone_options.includes("full image")) {
        setZoneOptions(["full image"]);
      }
    };
    checkData();
  }, []);

  let objectMotionDefault = "";
  let thresholdDefault = undefined;
  let defaultCountLogic = "";
  let showDefaultCount = false;

  if (classes === "people") objectMotionDefault = "include all";

  if (classes === "vehicles") objectMotionDefault = "moving only";

  if (classes.length >= 1) {
    thresholdDefault =
      zone_configs.find((item: any) => item?.type === "confidence_threshold")
        ?.default || classes === "people"
        ? 35
        : 50;
    showDefaultCount = true;
    defaultCountLogic = "> (greater than)";
  }

  useEffect(() => {
    getClassLabels(siteId);
  }, []);

  // UPDATED ADD HANDLER
  const addHandler = () => {
    if (!ctx.rule_name) {
      ctx.setRuleName(
        `${ctx.rule_name.toLocaleUpperCase().replace("_", " ")} ${
          ctx.last_rule_id
        }`.trim(),
      );
    }

    if (!isEdit) {
      console.debug(
        "ctx.zone_config",
        classes,
        "classifierName",
        classifierName,
      );

      // Check if a zone with the same class_label already exists
      const foundItem = ctx.zone_config.some(
        (item: any) => item.zone == zones && item.class_label == classes,
      );

      // Determine the object class based on rule type
      let objClass =
        ctx.rule_type === "Known Vehicle Filter" ? "vehicles" : classes;

      // Add new zone config if not found
      if (!foundItem) {
        console.debug(
          "Zone CONNNFIG: ",
          ctx.zone_config,
          "ctx.rule_type: ",
          sensorDefinedRule?.fields?.alert_config_type,
          "label: ",
          label,
          "subtype",
          ruleOption,
          "data: ",
          {
            zone: zones.includes("full image") ? "full_image" : zones,
            class_label:
              // ["alert_zones", "alert_zones_and_counts", "Guard Tour"].includes(
              //   ctx.rule_type
              // ) === false
              ruleTypes.includes("class_label") === false
                ? undefined
                : classifierName + "." + objClass,
            confidence_threshold:
              ruleTypes.includes("confidence_threshold") === false
                ? undefined
                : threshold && +threshold <= 100
                  ? +threshold
                  : threshold && +threshold > 100
                    ? 100
                    : 25,
            object_motion:
              ruleTypes.includes("object_motion") === false
                ? undefined
                : objectMotion
                  ? objectMotion.replace(" ", "_")
                  : "include_all",
            count:
              ruleTypes.includes("count_logic") === false
                ? undefined
                : count
                  ? +count
                  : 0,

            id: 11,
            count_logic:
              ruleTypes.includes("count_logic") === false
                ? undefined
                : countLogic
                  ? countLogic.split(" ")[0]
                  : ">",
            rule_type:
              ruleOption || sensorDefinedRule?.fields?.alert_config_type,
          },
        );
        ctx.setZoneConfig((prev: any) => [
          ...prev,
          {
            zone: zones.includes("full image") ? "full_image" : zones,
            class_label:
              // ["alert_zones", "alert_zones_and_counts", "Guard Tour"].includes(
              //   ctx.rule_type
              // ) === false
              ruleTypes.includes("class_label") === false
                ? undefined
                : // : ctx.rule_type === "Guard Management (no guard)"
                  // ? "human1.people"
                  // : ctx.rule_type === "PPE Detection - coming soon!"
                  // ? "safety." + objClass.toString().replace(/ /g, "_")
                  // : ctx.rule_type === "suspicious"
                  // ? "human1.people"
                  classifierName + "." + objClass,
            confidence_threshold:
              // ["alert_zones", "alert_zones_and_counts", "Guard Tour"].includes(
              //   ctx.rule_type
              // ) === false
              ruleTypes.includes("confidence_threshold") === false
                ? undefined
                : // :
                  // ctx.rule_type === "Guard Management (no guard)"
                  // ? 25.0
                  threshold && +threshold <= 100
                  ? +threshold
                  : threshold && +threshold > 100
                    ? 100
                    : 25,
            object_motion:
              // ["alert_zones", "alert_zones_and_counts", "Guard Tour"].includes(
              //   ctx.rule_type
              // ) === false
              ruleTypes.includes("object_motion") === false
                ? undefined
                : // : ctx.rule_type.includes("suspicious")
                  // ? "include_all"
                  // : ctx.rule_type === "PPE Detection - coming soon!" ||
                  //   ctx.rule_type === "Guard Management (no guard)"
                  // ? "include_all"
                  objectMotion
                  ? objectMotion.replace(" ", "_")
                  : "include_all",
            count:
              // !["alert_zones_and_counts", "Guard Tour"].includes(
              //   ctx.rule_type
              // )
              ruleTypes.includes("count_logic") === false
                ? undefined
                : // : ctx.rule_type === "Guard Management (no guard)"
                  // ? 0
                  count
                  ? +count
                  : 0,

            id: prev.length + 1,
            count_logic:
              // ["alert_zones_and_counts", "Guard Tour"].includes(
              //   ctx.rule_type
              // ) === false
              ruleTypes.includes("count_logic") === false
                ? undefined
                : // : ctx.rule_type === "Guard Management (no guard)"
                  // ? "<="
                  countLogic
                  ? countLogic.split(" ")[0]
                  : ">",
            rule_type:
              ruleOption || sensorDefinedRule?.fields?.alert_config_type,
            // ctx.rule_type.includes("Guard Management")
            //   ? ruleType
            //   : undefined,
            // cadence_secs:
            //   ctx.rule_type.includes("Guard Management (no guard)") &&
            //   site?.data_origin !== "sentinel_http_edge_human1"
            //     ? cadenceSecs
            //     : undefined,
          },
        ]);
      }
    } else {
      let foundRule = ctx.rulesList.find((rule: any) => rule.id === ruleId);

      // Filter and update the zone configs
      const zone_configs = foundRule.zoneConfigs.filter(
        (zone: any) => zone.id === zoneId,
      );

      const update = [
        ...zone_configs,
        {
          zone: zones.includes("full image") ? "full_image" : zones,
          class_label: classes,
          id: zoneId,
        },
      ];

      let rule_list = ctx.rulesList.filter((rule: any) => rule.id !== ruleId);

      ctx.setRuleList([
        ...rule_list,
        {
          id: ruleId,
          rulesName: foundRule.ruleName,
          rulesType: foundRule.ruleType,
          zoneConfigs: update,
          environment: foundRule.environment,
          scheduled: foundRule.scheduled,
        },
      ]);
    }

    resetForm();
    setClearZone(true);
    setClearClasses(true);
    setTouched(false);
    setZones([]);
  };

  const thresholdSanitiser = (e: any) => {
    if (e !== "") {
      e = Math.round(e);
    }
    setThreshold(e);
    setClearClasses(false);
  };

  if (loading)
    return (
      <div style={{ marginTop: 20, width: 500 }}>
        <LinearProgress />
      </div>
    );

  const foundZone = zone_configs
    ? zone_configs.find((item: any) => item?.type === "zone")
    : undefined;

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        height: 50,
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <MultipleSelect
          handleValueChange={(value: any) => {
            setClearZone(false);
            setZones(value);
            // if (["Guard Management (no guard)"].includes(ctx.rule_type)) {
            //   setRuleType("no_guard");
            //   setCadenceSecs("5 min");
            // }
          }}
          defaultValue={zone}
          multiple={false}
          title={foundZone?.label || "Select Zone"}
          names={showOnlyFullImage ? ["full image"] : zone_options}
          clear={clearZone}
          value={zones}
        />
        {ruleTypes.includes("class_label") &&
          sensorDefinedRule?.fields?.alert_config_type ===
            "SuspiciousActivitySelfStorage" && (
            // [
            //   "PPE Detection - coming soon!",
            //   "suspicious_activity_self_storage",
            // ].includes(ctx.rule_type) &&
            <>
              <MultipleSelect
                handleValueChange={(value: any) => {
                  setObjectMotion("");
                  setClearClasses(false);
                  setClasses(value);
                }}
                defaultValue={
                  // ctx.rule_type === "suspicious_activity_self_storage"
                  //   ? "suspicious activity"
                  //   : class_label
                  zone_configs.find((item: any) => item?.type === "class_label")
                    ?.default
                }
                multiple={false}
                title={
                  zone_configs.find((item: any) => item?.type === "class_label")
                    ?.label
                }
                names={
                  sensorDefinedRule?.fields?.alert_config_type ===
                  "SuspiciousActivitySelfStorage"
                    ? ["suspicious activity"]
                    : safetyClassLabels
                }
                clear={clearClasses}
                value={
                  sensorDefinedRule?.fields?.alert_config_type ===
                  "SuspiciousActivitySelfStorage"
                    ? "suspicious activity"
                    : classes
                }
              />
              {sensorDefinedRule?.fields?.alert_config_type !==
                "SuspiciousActivitySelfStorage" && (
                <MultipleSelect
                  handleValueChange={(value: any) => {
                    setClearClasses(false);
                    setColour(value);
                  }}
                  defaultValue={objectMotionDefault}
                  multiple={false}
                  title="Select Colour"
                  names={[]}
                  upcoming_options={colours_list}
                  clear={clearClasses}
                  value={colour}
                />
              )}
            </>
          )}
        {
          // ["alert_zones_and_counts", "alert_zones", "Guard Tour"].includes(
          //   ctx.rule_type
          // )
          ruleTypes.includes("class_label") &&
            sensorDefinedRule?.fields?.alert_config_type !==
              "SuspiciousActivitySelfStorage" && (
              <>
                <MultipleSelect
                  handleValueChange={(value: any) => {
                    setObjectMotion("");
                    setClearClasses(false);
                    setClasses(value);
                  }}
                  defaultValue={class_label}
                  multiple={false}
                  title={
                    zone_configs.find(
                      (item: any) => item?.type === "class_label",
                    )?.label || "Select Class"
                  }
                  names={classLabels}
                  clear={clearClasses}
                  value={classes}
                />
                {/* <MultipleSelect
            handleValueChange={(value: any) => {
              setClearClasses(false);
              setObjectMotion(value);
            }}
            defaultValue={objectMotionDefault}
            multiple={false}
            title="Object Motion"
            names={object_motion_list}
            clear={clearClasses}
            value={objectMotion}
          /> */}
              </>
            )
        }
        {ruleTypes.includes("object_motion") && (
          <>
            <MultipleSelect
              handleValueChange={(value: any) => {
                setClearClasses(false);
                setObjectMotion(value);
              }}
              defaultValue={objectMotionDefault}
              multiple={false}
              title={
                zone_configs.find((item: any) => item?.type === "object_motion")
                  ?.label || "Object Motion"
              }
              names={object_motion_list}
              clear={clearClasses}
              value={objectMotion}
            />
          </>
        )}
        {ruleTypes.includes("count_logic") && (
          <>
            <MultipleSelect
              handleValueChange={(value: any) => {
                setClearClasses(false);
                setcountLogic(value);
              }}
              defaultValue={defaultCountLogic}
              multiple={false}
              title={
                zone_configs.find((item: any) => item?.type === "count_logic")
                  ?.label || "Count logic"
              }
              names={
                zone_configs.find((item: any) => item?.type === "count_logic")
                  ?.options
              }
              // names={combination_logic_list}
              clear={clearClasses}
              value={countLogic}
            />

            <CustomInput
              value={count}
              type={"number"}
              // max={10000}
              min={
                zone_configs.find((item: any) => item?.type === "count")?.min ||
                0
              }
              max={
                zone_configs.find((item: any) => item?.type === "count")?.max ||
                1000
              }
              setValue={(e) => {
                setCount(e);
                setClearClasses(false);
              }}
              label={
                zone_configs.find((item: any) => item?.type === "count")
                  ?.label || "Count"
              }
              clear={clearClasses}
              touched={touched}
              setTouched={setTouched}
              defaultVal={showDefaultCount}
            />
          </>
        )}
        {["Guard Management (no guard)"].includes(ctx.rule_type) && (
          <>
            <MultipleSelect
              handleValueChange={(value: any) => {
                setClearClasses(false);
                setRuleType(value);
              }}
              defaultValue={defaultCountLogic}
              multiple={false}
              title="Rule Type"
              names={["no_guard"]}
              clear={clearClasses}
              value={ruleType}
            />
          </>
        )}
        {ruleTypes.includes("confidence_threshold") && (
          // [
          //   "alert_zones_and_counts",
          //   "alert_zones",
          //   "PPE Detection - coming soon!",
          //   "Guard Tour",
          // ].includes(ctx.rule_type) &&
          <CustomInput
            value={threshold}
            type={"number"}
            defaultVal={thresholdDefault}
            setValue={(e) => {
              thresholdSanitiser(e);
            }}
            label={
              zone_configs.find(
                (item: any) => item?.type === "confidence_threshold",
              )?.label || "Threshold"
            }
            max={
              zone_configs.find((item: any) => item?.type === "count")?.max ||
              1000
            }
            min={
              zone_configs.find((item: any) => item?.type === "count")?.min ||
              10
            }
            touched={touched}
            setTouched={setTouched}
            clear={clearClasses}
          />
        )}
      </div>
      <CustomButton
        disabled={zones.length === 0 && !class_label}
        title={isEdit ? "Change" : "Add"}
        onClickHandler={addHandler}
        id="ad"
        styles={{ backgroundColor: "#2E4FA5" }}
      />
    </div>
  );
};

export default AddZoneForm;
