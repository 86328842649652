import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useStyles } from "../../../styling/global/global";
interface BillingRunExpansionProps {
  submit: boolean;
  edit: boolean;
  del: boolean;
  add: boolean;
  final: boolean;
  exp: boolean;
  handleEdit: () => void;
  handleSubmit: () => void;
  handleDelete: () => void;
  handleAdd: () => void;
  handleFinalSubmit: () => void;
  handleExport: () => void;
}
const BillingRunExpansion: React.FC<BillingRunExpansionProps> = ({
  submit,
  edit,
  add,
  final,
  del,
  handleAdd,
  handleDelete,
  handleEdit,
  handleSubmit,
  handleFinalSubmit,
  handleExport,
  exp,
}: BillingRunExpansionProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const Edit = () => {
    setAnchorEl(null);
    handleEdit();
  };
  const Delete = () => {
    setAnchorEl(null);
    handleDelete();
  };

  const Add = () => {
    setAnchorEl(null);
    handleAdd();
  };

  const Submit = () => {
    setAnchorEl(null);
    handleSubmit();
  };
  const Final = () => {
    setAnchorEl(null);
    handleFinalSubmit();
  };

  const ExportBillingRun = () => {
    setAnchorEl(null);
    handleExport();
  };

  var menuitems: React.ReactNode[] = [];
  if (del) {
    menuitems.push(
      <MenuItem onClick={Delete}>
        <span className={classes.bold_text}>delete billing run</span>
      </MenuItem>,
    );
  }
  if (edit) {
    menuitems.push(
      <MenuItem onClick={Edit}>
        <span className={classes.bold_text}>edit billing run</span>
      </MenuItem>,
    );
  }

  if (submit) {
    menuitems.push(
      <MenuItem onClick={Submit}>
        <span className={classes.bold_text}>submit calculations</span>
      </MenuItem>,
    );
  }
  if (final) {
    menuitems.push(
      <MenuItem onClick={Final}>
        <span className={classes.bold_text}>finalise</span>
      </MenuItem>,
    );
  }
  if (exp) {
    menuitems.push(
      <MenuItem onClick={ExportBillingRun}>
        <span className={classes.bold_text}>export billing run</span>
      </MenuItem>,
    );
  }

  if (add) {
    menuitems.push(
      <MenuItem onClick={Add}>
        <span className={classes.bold_text}>add billing run</span>
      </MenuItem>,
    );
  }

  return (
    <>
      <div>
        <IconButton
          style={{ marginLeft: "2%" }}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onMouseLeave={handleClose}
        >
          {menuitems}
        </Menu>
      </div>
    </>
  );
};
export default BillingRunExpansion;
