import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { Org, Site, Hub } from "../../../../services/FunctionService";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import { TextField, Typography } from "@material-ui/core";

interface AddBillingOrgProps {
  open: boolean;
  appStore?: AppStore;
  profile: listBillingItems | undefined;
  onClose: () => void;
  AddBillingOrgFunction: (
    profile_id: number,
    billing_org_name: string,
    description: string,
    billing_org_id: number,
  ) => void;
}
interface listBillingItems {
  id: number;
  enabled: boolean;
  name: string;
  description: string;
  meta: { [key: string]: any };
  created: Date | string;
}
class impOrg implements Org {
  org_id!: number;
  org_name!: string;
  org_tree!: number[];
  org_level!: number;
  enabled!: boolean;
  sites!: Site[];
  hubs!: Hub[];
  orgs!: Org[];
  type!: string;
  created!: string;
  sub_org_count!: number;
  site_count!: number;
  hub_count!: number;
}

const AddBillingOrg: React.FC<AddBillingOrgProps> = ({
  open,
  onClose,
  profile,
  appStore,
  AddBillingOrgFunction,
}: AddBillingOrgProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
        paddingLeft: "2%",
        paddingRight: "2%",
      },
    }),
  )(TableRow);
  const useStyle = makeStyles({
    paper: {
      border: "2px solid #6D809D",
      color: "#15325F",
    },
  });
  const styler = useStyle();

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [renderValue, setRenderValue] = useState(false);
  const [inputName, setInputName] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [orgslist, setOrgsList] = React.useState<Org[]>([]);
  const [groupid, setGroupId] = React.useState(0);
  const [groupname, setGroupName] = React.useState("");
  const [textError, setTextError] = useState(false);
  const [groupError, setGroupError] = useState(false);
  const handleInputNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setTextError(false);
    setInputName(evt.target.value);
  };
  const handleInputDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    setInputDescription(event.target.value);
  };
  const handleChangeAuto = (value: any) => {
    setInputName(value.org_name);
    setGroupError(false);
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setGroupName(group.org_name);
    }
  };

  useEffect(() => {
    const fetchOrgList = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        const OrgListResult =
          await appStore?.functionService.getListOrgsWithTreeNames(token);
        if (OrgListResult && OrgListResult.success) {
          let orgz = OrgListResult?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      }
    };
    fetchOrgList();
  }, [renderValue]);

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <Typography
            className={classes.bold_text}
            style={{ color: "#3f51b5" }}
          >
            add group to profile
          </Typography>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {orgslist ? (
              !loading ? (
                <Autocomplete
                  id="combo-box-demo"
                  className={classes.form_component_medium}
                  options={orgslist}
                  onChange={(event: React.ChangeEvent<any>, value: any) => {
                    handleChangeAuto(value);
                  }}
                  style={{ marginLeft: "2%", width: "96%", color: "#6D809D" }}
                  classes={{ paper: styler.paper }}
                  getOptionLabel={(option: any) => {
                    let tree = option.org_tree_names!.map(
                      (item: any) => `   /   ${item} `,
                    );
                    let treeString = tree.toString();
                    const rpl = treeString.replace(/,/g, "");
                    return `${rpl}`;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span className={classes.bold_text_billing}>
                          {groupname ? groupname : "please select group"}
                        </span>
                      }
                      error={groupError}
                      variant="outlined"
                      value={groupid}
                      className={classes.bold_text_billing}
                    />
                  )}
                />
              ) : null
            ) : null}
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: "5%",
              }}
            >
              <TextField
                style={{ marginBottom: "5%" }}
                required
                autoFocus
                error={textError}
                variant="outlined"
                label="add billing group name"
                value={inputName}
                onChange={handleInputNameChange}
                helperText={
                  textError === true ? "group name field cannot be blank" : ""
                }
                InputProps={{
                  className: classes.bold_text_billing,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text_billing,
                  },
                }}
              />

              <TextField
                autoFocus
                variant="outlined"
                label="add billing group description"
                value={inputDescription}
                onChange={handleInputDescriptionChange}
                InputProps={{
                  className: classes.bold_text_billing,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text_billing,
                  },
                }}
              />
            </div>
          </>
        )}

        <div
          style={{ marginTop: "5%", marginBottom: "5%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              setInputName("");
              setInputDescription("");
              setGroupId(0);
              setGroupName("");
              onClose();
            }}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          <Button
            variant="contained"
            className={classes.cancel_button_billing}
            onClick={() => {
              if (inputName !== "" && groupid != 0) {
                AddBillingOrgFunction(
                  profile!.id,
                  inputName,
                  inputDescription,
                  groupid,
                );
                setInputName("");
                setInputDescription("");
                setGroupId(0);
                setGroupName("");
                onClose();
              } else if (inputName === "") {
                setTextError(true);
              } else {
                setGroupError(true);
              }
            }}
          >
            <span
              style={{ textOverflow: "ellipsis" }}
              className={classes.bold_text}
            >
              Add
            </span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default AddBillingOrg;
