import React, { useEffect, useState } from "react";
import { AppBar } from "@material-ui/core";
import { useStyles } from "../../../styling/global/global";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
  IconButton,
  Paper,
  Card,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import BillingExpansionPanel from "../BillingExpansionPanel/BillingExpansionPanel";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import ViewBillingProfiles from "../../Billing/BillingModals/ViewBillingProfiles";
import EditBillingProfiles from "../BillingModals/EditBillingProfiles";
import AddBillingOrg from "../BillingModals/AddBillingOrg";
import {
  listBillingItems,
  Org,
  Site,
  Hub,
  User,
} from "../../../../services/FunctionService";
import { listBillingProfileOrgsItems } from "../../../../services/FunctionService";
import AddBillingProfile from "../BillingModals/AddBillingProfile";
import DeleteBillingProfiles from "../BillingModals/DeleteBillingProfile";
import ViewProfileOrgs from "../BillingModals/ViewProfileOrgs";
import AddBillingProfileUser from "../BillingModals/AddBillingProfileUser";
import ViewBillingProfileUsers from "../BillingModals/ViewBillingProfileUsers";
import ResultMessage from "../../Billing/BillingModals/ResultMessage";

interface BillingProfilesProps {
  appStore?: AppStore;
}
export interface AddBillingFunctionProps {
  name: string;
  description: string;
}
class impOrg implements Org {
  org_id!: number;
  org_name!: string;
  org_tree!: number[];
  org_level!: number;
  enabled!: boolean;
  sites!: Site[];
  hubs!: Hub[];
  orgs!: Org[];
  type!: string;
  created!: string;
  sub_org_count!: number;
  site_count!: number;
  hub_count!: number;
}

const BillingProfiles: React.FC<BillingProfilesProps> = ({
  appStore,
}: BillingProfilesProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [billingProfiles, setBillingProfiles] = useState<any>([]);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openAddBillingProfileUser, setOpenAddBillingProfileUser] =
    useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openProfileOrgs, setOpenProfileOrgs] = useState(false);
  const [openProfileUsers, setOpenProfileUsers] = useState(false);
  const [openAddBillingOrg, setOpenAddBillingOrg] = useState(false);
  const [viewData, setViewData] = useState<listBillingItems | undefined>(
    undefined,
  );
  const [addBillingOrgData, setAddBillingOrgData] = useState<
    listBillingItems | undefined
  >(undefined);
  const [profileUserProfileData, setProfileUserProfileData] = useState<
    listBillingItems | undefined
  >(undefined);
  const [viewProfileOrgsData, setProfileOrgsViewData] = useState<
    listBillingItems | undefined
  >(undefined);
  const [viewProfileUsersData, setViewProfileUsersData] = useState<
    listBillingItems | undefined
  >(undefined);
  const [editData, setEditData] = useState<listBillingItems | undefined>(
    undefined,
  );
  const [deleteData, setDeleteData] = useState<listBillingItems | undefined>(
    undefined,
  );
  const [usersfortree, setUsersForTree] = useState<User[] | undefined>(
    undefined,
  );
  const [reloadValue, setReloadValue] = useState(false);

  const dummy = () => {};

  const [sitesexpanded, setSitesExpanded] = useState(true);
  //function to toggle sitesexpanded when sites open/close icon clicked
  const handleSitesExpanded = () => {
    setSitesExpanded((value) => !value);
  };
  const handleViewProfile = (x: listBillingItems) => {
    setViewData(x);
    setOpenView(true);
  };
  const handleAddBillingOrg = (x: listBillingItems) => {
    setAddBillingOrgData(x);
    setOpenAddBillingOrg(true);
  };
  const handleViewAddProfileUser = (x: listBillingItems) => {
    setProfileUserProfileData(x);
    setOpenAddBillingProfileUser(true);
  };
  const handleViewProfileOrgs = (x: listBillingItems) => {
    setProfileOrgsViewData(x);
    setOpenProfileOrgs(true);
  };
  const handleViewProfileUsers = (x: listBillingItems) => {
    setViewProfileUsersData(x);
    setOpenProfileUsers(true);
  };
  const handleEditProfile = (x: listBillingItems) => {
    setEditData(x);
    setOpenEdit(true);
  };
  const handleDeleteProfile = (x: listBillingItems) => {
    setDeleteData(x);
    setOpenDelete(true);
  };
  const handleCloseAddProfileUser = () => {
    setOpenAddBillingProfileUser(false);
  };
  const handleCloseViewProfileUsers = () => {
    setOpenProfileUsers(false);
  };
  const handleCloseAddBillingOrg = () => {
    setOpenAddBillingOrg(false);
  };

  const handleAddProfile = () => {
    setOpenAdd(true);
  };
  const handleCloseView = () => {
    setOpenView(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleCloseAddProfile = () => {
    setOpenAdd(false);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleCloseViewProfileOrgs = () => {
    setOpenProfileOrgs(false);
  };
  const closeMessageModal = () => {
    setOpenMessageModal(false);
    setResultMessage("");
  };

  const AddBillingProfileFunction = async (
    name: string,
    description: string,
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const addBillingProfileResult =
        await appStore?.functionService.addBillingProfile(
          token,
          name,
          description,
        );
      if (addBillingProfileResult) {
        setLoading(false);
        setReloadValue((value) => !value);
        if (addBillingProfileResult.success) {
          setResultMessage("Billing Profile successfully added");
        } else {
          setResultMessage("Error: " + addBillingProfileResult.msg);
        }

        setOpenMessageModal(true);
      }
    }
  };
  const DeleteProfileFunction = async (profile_id: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const deleteBillingProfileResult =
        await appStore?.functionService.deleteBillingProfile(token, profile_id);
      if (deleteBillingProfileResult) {
        setLoading(false);
        setReloadValue((value) => !value);
        if (deleteBillingProfileResult.success) {
          setResultMessage("Billing Profile successfully deleted");
        } else {
          setResultMessage("Error: " + deleteBillingProfileResult.msg);
        }

        setOpenMessageModal(true);
      }
    }
  };
  const AddBillingProfileUserFunction = async (
    profile_id: number,
    user_id: number,
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const AddBillingProfileUserResult =
        await appStore?.functionService.addBillingProfileUser(
          token,
          profile_id,
          user_id,
        );
      if (AddBillingProfileUserResult) {
        setLoading(false);
        setReloadValue((value) => !value);
        if (AddBillingProfileUserResult.success) {
          setResultMessage("Billing Profile successfully added");
        } else {
          setResultMessage("Error: " + AddBillingProfileUserResult.msg);
        }

        setOpenMessageModal(true);
      }
    }
  };
  const AddBillingOrgFunction = async (
    profile_id: number,
    billing_org_name: string,
    description: string,
    billing_org_id: number,
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const AddBillingOrgResult = await appStore?.functionService.addBillingOrg(
        token,
        profile_id,
        billing_org_id,
        billing_org_name,
        description,
      );
      if (AddBillingOrgResult) {
        setLoading(false);
        setReloadValue((value) => !value);
        if (AddBillingOrgResult.success) {
          setResultMessage("Group added to billing profile");
        } else {
          if (
            AddBillingOrgResult.msg.includes(
              "which already exists in billing database for",
            )
          ) {
            setResultMessage("a billing org already exists for this group");
          } else {
            setResultMessage("Error :  " + AddBillingOrgResult.msg);
          }
        }

        setOpenMessageModal(true);
      }
    }
  };
  const EditProfileFunction = async (
    profile_id: number,
    name: string,
    description: string,
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const EditBillingProfileResult =
        await appStore?.functionService.editBillingProfile(
          token,
          profile_id,
          name,
          description,
        );
      if (EditBillingProfileResult) {
        setLoading(false);
        setReloadValue((value) => !value);
      }
    }
  };

  //manage icon to expand and close profiles
  let expandsites: React.ReactNode[] = [];
  if (sitesexpanded) {
    expandsites.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => {
          handleSitesExpanded();
        }}
      />,
    );
  } else {
    expandsites.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => {
          handleSitesExpanded();
        }}
      />,
    );
  }
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);
  const getSitesDisplay = () => {
    let index = 0;
    let sitearray: React.ReactNode[] = [];
    let items = [];
    for (let profile_id in billingProfiles) {
      let profile = billingProfiles[profile_id];
      items.push(profile);
    }
    items.forEach((prof) => {
      if (sitesexpanded) {
        sitearray.push(
          <Card raised className={classes.expansion_shelf}>
            <div style={{ marginLeft: "5%" }} className={classes.shelf_name}>
              <div className={classes.bold_text}> {prof.name}</div>
            </div>
            <div className={classes.shelf_name}>
              <div className={classes.bold_text_lower}>
                {" "}
                profile id=({prof.id})
              </div>
            </div>
            <div className={classes.shelf_menu}>
              <BillingExpansionPanel
                del={true}
                view={true}
                edit={true}
                viewProfileOrgs={true}
                addProfileUser={true}
                viewProfileUsers={true}
                addBillingOrg={true}
                handleAddBillingOrg={() => handleAddBillingOrg(prof)}
                handleViewProfileUsers={() => handleViewProfileUsers(prof)}
                handleViewAddProfileUser={() => handleViewAddProfileUser(prof)}
                handleViewProfileOrgs={() => handleViewProfileOrgs(prof)}
                handleEdit={() => handleEditProfile(prof)}
                handleDelete={() => {
                  handleDeleteProfile(prof);
                }}
                handleView={() => handleViewProfile(prof)}
              />
            </div>
          </Card>,
        );
        index++;
      }
    });
    if (billingProfiles.length < 1) {
      sitearray.push(
        <Card raised className={classes.expansion_shelf}>
          <div className={classes.bold_text}>
            no billing profiles to display
          </div>
        </Card>,
      );
    }

    return [sitearray, index];
  };
  const [sitesdrawer, sitecount] = getSitesDisplay();
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        const billinglistResult =
          await appStore?.functionService.listBillingProfiles(token);
        if (billinglistResult?.success) {
          setBillingProfiles(billinglistResult?.billing_profiles);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [reloadValue]);
  useEffect(() => {
    const fetchAllUsers = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      let neworg = new impOrg();
      neworg.org_id = appStore?.authService.getEntryOrg()?.id!;
      if (token) {
        setLoading(true);

        const usersData = await appStore!.functionService.getAllVisibleUsers(
          token,
          neworg.org_id,
          true,
        );
        if (usersData) {
          setUsersForTree(usersData);
          setLoading(false);
        }
      }
    };
    fetchAllUsers();
  }, [reloadValue]);
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Card className={`${classes.expansionbar} `}>
          <Tooltip
            placement="bottom"
            title="Billing profiles are used to allow users with different rights to gain visibility of
            billing information of interest to them such as invoice detail, estimates for current
            month etc and also to actually create billing runs which include orgs linked to
            the billing profile."
          >
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_icon}>
                  <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.87">
                      <path
                        d="M17 6.3V1H14V3.6L10 0L0 9H3V17H9V11H11V17H17V9H20L17 6.3ZM15 15H13V9H7V15H5V7.19L10 2.69L15 7.19V15Z"
                        fill="#FFFFFF"
                        fill-opacity="0.87"
                      />
                      <path
                        d="M8 7H12C12 5.9 11.1 5 10 5C8.9 5 8 5.9 8 7Z"
                        fill="#FFFFFF"
                        fill-opacity="0.87"
                      />
                    </g>
                  </svg>
                </div>

                <div className={classes.header_text_white}>
                  Billing profiles
                  {"  (" + billingProfiles.length + ")"}
                </div>

                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleAddProfile();
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </IconButton>
                {/* {disabledicon} */}
              </div>
            </Typography>
          </Tooltip>
        </Card>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className={classes.expansioncontent}>{sitesdrawer}</div>
        )}
      </div>
      <ViewBillingProfiles
        open={openView}
        onClose={handleCloseView}
        profile={viewData}
        appStore={appStore}
      />
      <EditBillingProfiles
        open={openEdit}
        onClose={handleCloseEdit}
        profile={editData}
        EditProfileFunction={(
          profile_id: number,
          name: string,
          description: string,
        ) => EditProfileFunction(profile_id, name, description)}
      />
      <AddBillingProfile
        open={openAdd}
        onClose={handleCloseAddProfile}
        appStore={appStore}
        AddBillingProfileFunction={(name: string, description: string) =>
          AddBillingProfileFunction(name, description)
        }
      />
      <DeleteBillingProfiles
        open={openDelete}
        onClose={handleCloseDelete}
        appStore={appStore}
        profile={deleteData}
        DeleteProfileFunction={(profile_id: number) =>
          DeleteProfileFunction(profile_id)
        }
      />
      <ViewProfileOrgs
        open={openProfileOrgs}
        onClose={handleCloseViewProfileOrgs}
        appStore={appStore}
        profile={viewProfileOrgsData}
      />
      <AddBillingProfileUser
        open={openAddBillingProfileUser}
        onClose={handleCloseAddProfileUser}
        appStore={appStore}
        profile={profileUserProfileData}
        AddBillingProfileUserFunction={(profile_id: number, user_id: number) =>
          AddBillingProfileUserFunction(profile_id, user_id)
        }
      />
      <ViewBillingProfileUsers
        open={openProfileUsers}
        onClose={handleCloseViewProfileUsers}
        appStore={appStore}
        profile={viewProfileUsersData}
      />
      <AddBillingOrg
        open={openAddBillingOrg}
        onClose={handleCloseAddBillingOrg}
        appStore={appStore}
        profile={addBillingOrgData}
        AddBillingOrgFunction={AddBillingOrgFunction}
      />
      <ResultMessage
        open={openMessageModal}
        onClose={closeMessageModal}
        message={resultMessage}
      />
    </>
  );
};

export default BillingProfiles;
