import React, { useState, useEffect } from "react";
import { Switch as AntdSwitch } from "antd";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import { AppStore } from "../../../stores/AppStore";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "../../styling/global/global";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import {
  withStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { User, UsersForOrg } from "../../../services/FunctionService";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";

//Dialog
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//end

//Alert
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
//End

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// Roles Names
const names = [
  "View Alerts",
  "View History",
  "View Escalations",
  "Mute Controls",
  "Camera filter",
  "Bulk Actions",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// end

interface RuleSetsAssignmentProps {
  appStore?: AppStore;
}

// END OF Full Screen Modal
interface MockEvent {
  (event: Event): any;
}

// Switch Styles
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);

// WithStyles
const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

const RuleSetsAssignment: React.FC<RuleSetsAssignmentProps> = ({
  appStore,
}: RuleSetsAssignmentProps) => {
  const [loading, setLoading] = React.useState(true);
  let [isEditable, setIsEditable] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [unSelectAll, setUnSelectAll] = React.useState(false);
  const [refresh, setRefresh] = useState(0);
  const [reload, setReload] = useState(0);
  const [channelPushedIds, setChannelPushedIds] = useState<number[]>([]);
  const [orgslist, setOrgsList] = React.useState<any>([]);
  const [groupid, setGroupId] = React.useState(
    appStore?.authService.getEntryOrg()?.id!,
  );
  const [groupname, setGroupName] = React.useState(
    appStore?.authService.getEntryOrg()?.name || "",
  );
  const [usersfortree, setUsersForTree] = useState<User[] | undefined>([]);

  const [userId, setUserId] = useState<number | undefined>(-1);
  const [alertchannels, setAlertChannels] = useState<{ [key: string]: any }[]>(
    [],
  );
  const [usersDefaultText, setUsersDefaultText] =
    useState("Please Select User");

  // Users & Roles
  const [allUsers, setAllUsers] = React.useState(false);
  const [userFullName, setUserFullName] = useState("");
  const [roleNames, setRoleNames] = React.useState<string[] | any>([]);
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);
  let [channelsData, setChannelsData] = useState<number[]>([]);

  // Dialog
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("md");

  // Toast
  const [openToast, setOpenToast] = React.useState(false);
  const [err, setErr] = React.useState(false);
  const [message, setMessage] = React.useState("");

  //Fetching Roles Containers
  const [endPointRoles, setEndPointRoles] = useState<string | undefined[]>([]);
  const [channelIds, setChannelIds] = useState([]);

  //Fetching Users For specific channel
  const [channelEndPointId, setChannelEndPointId] = useState<number>();
  const [usersList, setUsersList] = useState<User[]>([]);
  const [tableMessage, setTableMessage] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [enabledSites, setEnabledSites] = useState(false);

  const [selectedGroupId, setSelectedGroupId] = React.useState(-1);
  const [selectedGroupName, setSelectedGroupName] = React.useState("");

  const getBaseUrl = (): string => {
    let base = "";
    if (window.location.href.includes("local")) {
      base = "https://manage.staging.deepalert.ai";
    }
    base += "/api/v1.3";
    return base;
  };

  if (message) {
    setTimeout(function () {
      setMessage("");
    }, 20000); //20 Seconds delay
  }

  const handleClickOpen = (id?: number) => {
    setOpen(true);
    if (id) {
      setChannelEndPointId(id);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToastOpen = () => {
    setOpenToast(true);
  };

  const handleToastClose = () => {
    setMessage("");
    setOpenToast(false);
    setChannelsData(channelsData);
  };

  //end

  //Radio
  const [selectedValue, setSelectedValue] = React.useState("mainGroup");
  const [includeSubs, setIncludeSubs] = React.useState(false);

  const handleSelectedChangeAuto = (value: any) => {
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    setSelectedGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setSelectedGroupName(group.org_name);
    }
  };

  const styler = useStyle();

  // STYLING SECTION
  const classes = useStyles();
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:hover": {
          backgroundColor: "#e3f2fd",
        },
      },
    }),
  )(TableRow);

  // Select All Function
  const selectAllHandler = () => {
    setSelectAll(true);
    setUnSelectAll(false);
    setIsEditable(true);
    setChannelsData(channelPushedIds);
    // channelsData = channelPushedIds
  };

  //Unselect All Function
  const unSelectAllHandler = () => {
    setUnSelectAll(true);
    setSelectAll(false);
    setIsEditable(true);
    setChannelsData([]);
  };

  const transformToServerData = (userRoles: string[]) => {
    const serverData: any = userRoles.map((role: string) => {
      if (role === "View Alerts") {
        return "web_alerts";
      }

      if (role === "View History") {
        return "web_alert_history";
      }

      if (role === "Mute Controls") {
        return "web_mute_controls";
      }

      if (role === "View Escalations") {
        return "web_alert_escalations";
      }

      if (role === "Camera filter") {
        return "web_alert_camera_filter";
      }
      if (role === "Bulk Actions") {
        return "web_bulk_actions";
      }
    });

    return serverData;
  };

  //Multiple Selection method
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIsEditable(true);
    setRoleNames(event.target.value as string[]);
    setChannelsData(channelsData);

    //Transform Data to Rest Server Args
    let userRolesList = event.target.value! as string[];

    if (userRolesList.length === 0) {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }

    const serverData: any = transformToServerData(userRolesList);
    setEndPointRoles(serverData);
  };

  //end

  //FUNCTIONS SECTION
  const handleAllUsersChange = (value: string) => {
    handleToastClose();
    if (value === "subgroups") {
      setSelectedValue("subgroups");
      setIncludeSubs(true);
      setAllUsers(false);
    }
    if (value === "mainGroup") {
      setSelectedValue("mainGroup");
      setIncludeSubs(false);
      setAllUsers(false);
    }
    if (value === "allUsers") {
      setSelectedValue("allUsers");
      setAllUsers(true);
    }

    setUserFullName("");
    setIsEditable(false);
  };

  const handleChangeAuto = (value: any) => {
    handleToastClose();
    setUserFullName("");
    setRoleNames([]);
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setGroupName(group.org_name);
    }
    setIsEditable(false);
  };

  const handleChangeUser = (value: any) => {
    handleToastClose();
    setRoleNames([]);
    setIsEditable(false);
    let new_user_id = +value.user_id;
    let user = usersfortree!.find((x) => x.user_id === new_user_id);
    setUserId(new_user_id);
    if (user) {
      setUserFullName(`${user.first_name} ${user.last_name}`);
    }
  };

  const fetchOrgsData = async () => {
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          enabled: true,
          paginate: false,
          verbose: false,
          incl_tree_names: true,
        });

        const res = fetch(baseUrl + "/list_orgs", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        const orglist = result;
        if (orglist && orglist.success) {
          let orgz = orglist?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }

      //Fetch end
    }
  };

  //************* Fetching Data Functions *************/
  // Fetch Organizations
  useEffect(() => {
    fetchOrgsData();
  }, [groupid]);

  // 2)Fetching Users
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setUserFullName("");
      setUsersDefaultText("Please Select User");
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && groupid >= 0) {
        let usersData: User[] = [];
        if (allUsers) {
          usersData = await appStore!.functionService.getAllVisibleUsers(
            token,
            appStore!.authService.getLoggedInOrg(),
            enabled,
          );
        }

        if (!allUsers && includeSubs) {
          usersData = await getAllUsersForOrg(token, groupid, false, enabled);
        }

        if (!allUsers && !includeSubs) {
          usersData = await getAllUsersForOrg(token, groupid, true, enabled);
        }

        if (usersData === undefined || usersData.length === 0) {
          setUsersDefaultText("No Users Found");
        }
        setUserId(-1);
        setUsersForTree(usersData);
        // setLoading(false);
      }
      setLoading(false);
    };
    fetchData();
  }, [groupid, allUsers, includeSubs, enabled]);

  // Fetch Alert Channels with rules
  const fetchAlertsData = async (channels: any, token: string) => {
    if (token && groupid >= 0) {
      // setLoading(true);
      const rulesets = await fetchChannels(token, groupid, false);
      if (rulesets && rulesets.length > 0) {
        let newalertchannels: { [key: string]: any }[] = [];
        rulesets.forEach((ruleset) => {
          newalertchannels.push(ruleset);
        });

        // Transform data
        let arr: { [key: string]: any }[] = [];
        newalertchannels.forEach(
          (alertchannel: { [key: string]: any }, index: any) => {
            let tree = alertchannel.org_tree_names!.map(
              (item: any) => ` / ${item} `,
            );
            let treeString = tree.toString();
            const rpl = treeString.replace(/,/g, "");
            const foundItem = channels?.find(
              (item: number) => item === alertchannel.alert_channel_id,
            );
            let isAssigned = false;
            if (foundItem) {
              isAssigned = true;
            }

            arr.push({
              Id: alertchannel.alert_channel_id,
              EId: alertchannel.alert_channel_end_point_id,
              Ruleset_Name: alertchannel.alert_channel_name,
              Group: rpl,
              Site: alertchannel.site_name,
              User_Count: alertchannel.user_count,
              Assigned: isAssigned,
              Count: isAssigned ? 2 : 1,
            });
          },
        );
        // End

        setAlertChannels(arr);
        setRefresh(new Date().getTime());

        return;
      } else {
        setAlertChannels([]);
        setRefresh(new Date().getTime());
        return;
      }
    }
  };

  const fetchChannelsData = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && selectedGroupId >= 0) {
      setLoading(true);
      const rulesets = await fetchChannels(token, selectedGroupId, false);
      if (rulesets && rulesets.length > 0) {
        let newalertchannels: { [key: string]: any }[] = [];
        rulesets.forEach((ruleset) => {
          newalertchannels.push(ruleset);
        });

        //Transform data To Org Tree
        let arr: { [key: string]: any }[] = [];
        newalertchannels.forEach(
          (alertchannel: { [key: string]: any }, index: any) => {
            let tree = alertchannel.org_tree_names!.map(
              (item: any) => ` / ${item} `,
            );
            let treeString = tree.toString();
            const rpl = treeString.replace(/,/g, "");
            const foundItem = channelsData?.find(
              (item) => item === alertchannel.alert_channel_id,
            );
            let isAssigned = false;
            if (foundItem) {
              isAssigned = true;
            }

            arr.push({
              Id: alertchannel.alert_channel_id,
              EId: alertchannel.alert_channel_end_point_id,
              Ruleset_Name: alertchannel.alert_channel_name,
              Group: rpl,
              Site: alertchannel.site_name,
              User_Count: alertchannel.user_count,
              Assigned: isAssigned,
              Count: isAssigned ? 2 : 1,
            });
          },
        );
        // End

        setAlertChannels(arr);
        setRefresh(new Date().getTime());

        newalertchannels.map((alertchannel) => {
          channelPushedIds.push(alertchannel.alert_channel_id);
        });
      } else {
        setAlertChannels([]);
        setChannelPushedIds([]);
        setRefresh(new Date().getTime());
      }
      setLoading(false);
    }
  };

  // Fetch Alert Channels
  useEffect(() => {
    fetchChannelsData();
  }, [selectedGroupId, reload, enabledSites]);

  // Org Content Setup
  let orgsContent;
  if (orgslist && orgslist.length > 0) {
    orgsContent = orgslist.map((org: any) => (
      <MenuItem value={org.org_id}>
        <span className={classes.bold_text}>{org.org_name}</span>
      </MenuItem>
    ));
  }

  // Fetch Users Function (All Users and For Specific Org Tree)
  const getAllUsersForOrg = async (
    access_token: string,
    top_org_id: number,
    top_org_only: boolean,
    enabled: boolean,
  ): Promise<User[] | any> => {
    var baseUrl = getBaseUrl();
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        order_by: "username",
        top_org_only: top_org_only,
        paginate: false,
        verbose: true,
        enabled: enabled ? null : true,
      });

      const res = fetch(baseUrl + "/list_users", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UsersForOrg;
      let ret = result.users;
      return ret;
    } catch (err) {
      return err;
    }
  };

  //Users Content Setup
  let usersContent;
  if (usersfortree && usersfortree.length > 0) {
    usersContent = usersfortree.map((user) => (
      <MenuItem value={user.user_id}>
        <span className={classes.bold_text}>
          {user.first_name} {user.last_name}
        </span>
      </MenuItem>
    ));
  }

  const channelRolesHandler = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let status = event.target.checked;
    let newItem = id;
    if (status) {
      channelsData.push(newItem);
    } else {
      const index = channelsData.indexOf(id);
      if (index > -1) {
        channelsData.splice(index, 1);
      }
    }
  };

  //Table Content Setup
  var alertchanneldisplay: React.ReactNode[] = [];
  if (alertchannels && alertchannels.length > 0)
    alertchannels.forEach(
      (alertchannel: { [key: string]: any }, index: any) => {
        alertchanneldisplay.push(
          <StyledTableRow>
            <TableCell>
              <Typography className={classes.bold_text}>
                {alertchannel.Group}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>
                {alertchannel.Site}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>
                {alertchannel.Ruleset_Name}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                className={classes.bold_text}
                onClick={(e) => {
                  handleClickOpen(alertchannel.EId);
                }}
              >
                <Tooltip
                  title="View users assigned to this channel"
                  aria-label="View users assigned to this channel"
                  arrow
                >
                  <p
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      listStyle: "none",
                    }}
                  >
                    <span style={{ fontSize: 14, textAlign: "center" }}>
                      {" "}
                      &nbsp; {alertchannel.User_Count} &nbsp; &nbsp;{" "}
                    </span>
                    <span style={{ marginTop: 3 }}>
                      {" "}
                      <VisibilityIcon />{" "}
                    </span>
                  </p>
                </Tooltip>
              </Typography>
            </TableCell>
            {userId! >= 0 && (
              <TableCell>
                <Typography className={classes.bold_text}>
                  <Tooltip
                    placement="bottom"
                    title="Assign or Disable user from this channel"
                  >
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      {/* <Grid item>disabled</Grid> */}
                      <Grid item>
                        <AntSwitch
                          defaultChecked={
                            !selectAll && !unSelectAll
                              ? alertchannel.Assigned
                              : selectAll && !unSelectAll
                                ? true
                                : false
                          }
                          onChange={(e) => {
                            channelRolesHandler(alertchannel.Id, e);
                            if (alertchannel.Assigned) {
                              alertchannel.Assigned = false;
                            } else {
                              alertchannel.Assigned = true;
                            }
                          }}
                          onClick={(e) => {
                            if (roleNames.length !== 0) {
                              setIsEditable(true);
                            }
                          }}
                          name="checkedC"
                        />
                      </Grid>
                      {/* <Grid item>enabled</Grid> */}
                    </Grid>
                  </Tooltip>
                </Typography>
              </TableCell>
            )}
          </StyledTableRow>,
        );
      },
    );
  // }

  // This Function Transforms WebRoles From Server to Interface
  const transformDataFromServer = (userRoles: string[]) => {
    const transformedData = userRoles.map((role: string) => {
      if (role === "web_alerts") {
        return "View Alerts";
      }

      if (role === "web_alert_history") {
        return "View History";
      }

      if (role === "web_mute_controls") {
        return "Mute Controls";
      }

      if (role === "web_alert_escalations") {
        return "View Escalations";
      }

      if (role === "web_alert_camera_filter") {
        return "Camera filter";
      }
      if (role === "web_bulk_actions") {
        return "Bulk Actions";
      }
    });

    if (transformedData.length > 0) {
      transformedData!.sort((a: any, b: any) => {
        return a.length - b.length;
      });
    }

    return transformedData;
  };

  //DIALOG TABLE CONTENT
  /***************************************************************/
  var usersListDisplay: React.ReactNode[] = [];
  if (usersList && usersList.length > 0) {
    usersList.forEach((user: { [key: string]: any }, index: any) => {
      const transformedData = transformDataFromServer(user.web_roles);
      usersListDisplay.push(
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {user.first_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {user.last_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {user.username}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              <span style={{ textAlign: "center", paddingLeft: 20 }}>
                {transformedData.length}
              </span>
            </Typography>
          </TableCell>
          <TableCell>
            <div>
              {transformedData.map((role: any) => {
                return (
                  <li key={role} style={{ listStyle: "none" }}>
                    <Chip
                      // icon={icon}
                      label={role}
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </div>
          </TableCell>
        </StyledTableRow>,
      );
    });
  }

  /***************************************************************************/
  //Fetch Users For Specific Channel
  useEffect(() => {
    const fetchUsersList = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && channelEndPointId) {
        setLoadingTable(true);
        const popUpUsersList =
          await appStore?.functionService.getUsersForEndPoint(
            token,
            channelEndPointId,
          );

        if (popUpUsersList) {
          setUsersList(popUpUsersList);
          setLoadingTable(false);
        } else {
          setTableMessage("No Users Found");
          setLoadingTable(false);
        }
      }
    };
    fetchUsersList();
  }, [channelEndPointId]);

  // Fetch Alert Channels For Specific User
  const fetchChannels = async (
    access_token: string,
    top_org_id: number,
    top_org_only: boolean,
  ): Promise<{ [key: string]: any }[]> => {
    var baseUrl = getBaseUrl();
    try {
      let request = undefined;
      if (top_org_id !== 0) {
        request = JSON.stringify({
          top_org_id: top_org_id,
          top_org_only: top_org_only,
          enabled_sites_only: enabledSites,
        });
      } else {
        request = JSON.stringify({
          top_org_only: top_org_only,
          enabled_sites_only: enabledSites,
        });
      }

      const res = fetch(baseUrl + "/org_web_channels", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;
      let ret = result.alert_channels;
      return ret;
    } catch (err: any) {
      return err.message;
    }
  };

  //Fetch User Roles Using User ID:
  useEffect(() => {
    setSelectAll(false);
    setUnSelectAll(false);
    setAlertChannels([]); // Clear previous user's data
    setChannelIds([]);
    setChannelsData([]);

    const fetchUserRoles = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      setLoading(true);

      if (token && userId! >= 0) {
        try {
          const request = JSON.stringify({
            top_org_id: groupid !== -1 ? groupid : undefined,
            user_id: userId,
          });

          const res = await fetch(baseUrl + "/user_web_channels", {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });

          if (res.ok) {
            const result = await res.json();
            setChannelIds(result.alert_channel_ids);
            setChannelsData(result.alert_channel_ids);

            // Fetch alert channels based on new user data
            await fetchAlertsData(result.alert_channel_ids, token);
          } else {
            handleToastOpen();
            setErr(true);
            setMessage("Something went wrong, please try again");
          }
        } catch (err) {
          console.debug("Error fetching user roles:", err);
        } finally {
          setLoading(false); // Ensure loading is set to false
        }
      } else {
        setLoading(false); // Ensure loading is set to false if token/userId conditions fail
      }
    };

    fetchUserRoles();
  }, [userId, groupid]); // Dependency on userId and groupid

  //Fetch User Roles Using User ID:
  useEffect(() => {
    const fetchUserRoles = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      setLoading(true);
      if (token && userId! >= 0) {
        try {
          let request = undefined;
          if (groupid !== -1) {
            request = JSON.stringify({
              top_org_id: groupid,
              user_id: userId,
            });
          } else {
            request = JSON.stringify({
              top_org_only: false,
            });
          }

          const res = await fetch(
            baseUrl + `/user_web_roles?user_id=${userId}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
              },
            },
          );
          if (!res.ok) {
            handleToastOpen();
            setErr(true);
            setMessage("Something went wrong, please try again");
          }
          const result = (await (await res).json()) as any;

          const userRolesList = result.web_roles;

          if (userRolesList.length > 0) {
            const transformedData = transformDataFromServer(userRolesList);
            setRoleNames(transformedData);
            setEndPointRoles(result.web_roles);
          }
          setLoading(false);
        } catch (err) {
          console.debug(err);
        }
      }
      setLoading(false);
    };
    fetchUserRoles();
  }, [userId]);

  // Remove Duplicate Values in Array Function
  function getUnique(arr: any) {
    let uniqueArr = [];

    // loop through array
    for (let i of arr) {
      if (uniqueArr.indexOf(i) === -1) {
        uniqueArr.push(i);
      }
    }
    return uniqueArr;
  }

  // Update Roles for Users (Bulk Assigment)
  const updateUsersRoles = async () => {
    setLoading(true);
    var baseUrl = getBaseUrl();
    const lastSnapShot = getUnique(channelsData);
    var token = await appStore?.authService.getAuthorisedToken();
    setMessage("");
    if (token) {
      try {
        let request = undefined;
        if (groupid !== -1) {
          request = JSON.stringify({
            top_org_id: groupid,
            user_id: userId,
            // web_end_point_roles: `${endPointRoles}`,
            alert_channel_ids:
              channelsData.length !== 0 ? `${lastSnapShot}` : "",
          });
        } else {
          request = JSON.stringify({
            top_org_only: false,
          });
        }

        const res = await fetch(baseUrl + "/user_web_channels", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        if (!res.ok) {
          setErr(true);
          setSelectAll(false);
          setUnSelectAll(false);
          setMessage("Something went wrong, please try again");
        }
        const result = (await (await res).json()) as any;
        const msg = result.msg;
        handleToastOpen();

        if (result.success) {
          setErr(false);
          setMessage(
            `Your changes for ${userFullName} have been successfully saved`,
          );
          setSelectAll(false);
          setUnSelectAll(false);
          setUserId(-1);
          setUserFullName("");
          fetchChannelsData();
        }

        if (!result.success) {
          setErr(true);
          setChannelsData(lastSnapShot);
          setMessage(msg);
        }
      } catch (err) {
        console.debug(err);
      }
    }
    setLoading(false);
  };

  // Order By Function To Sort List For Table
  const handleOrderChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    let order = e.target.value;

    if (order == "Group ASC") {
      sortByHandler("Group", true);
    }

    if (order == "Group DSC") {
      sortByHandler("Group", false);
    }

    if (order === "Site ASC") {
      sortByHandler("Site", true);
    }

    if (order === "Site DSC") {
      sortByHandler("Site", false);
    }

    if (order === "RuleSet ASC") {
      sortByHandler("RuleSet", true);
    }

    if (order === "RuleSet DSC") {
      sortByHandler("RuleSet", false);
    }
    if (order === "UserCount ASC") {
      sortByHandler("UserCount", true);
    }

    if (order === "UserCount DSC") {
      sortByHandler("UserCount", false);
    }
    if (order === "Assign ASC") {
      sortByHandler("Assign", true);
    }

    if (order === "Assign DSC") {
      sortByHandler("Assign", false);
    }
  };

  // Sorting Table's Data Function
  const sortByHandler = (orderBy: string | unknown, direction: boolean) => {
    switch (orderBy) {
      case "Group":
        if (direction) {
          const list = [...alertchannels].sort((a, b): number => {
            if (b.Group.toLowerCase() > a.Group.toLowerCase()) {
              return -1;
            }
            if (b.Group.toLowerCase() < a.Group.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setAlertChannels(newArr);
          return;
        } else {
          const list = [...alertchannels].sort((a, b): number => {
            if (b.Group.toLowerCase() > a.Group.toLowerCase()) {
              return 1;
            }
            if (b.Group.toLowerCase() < a.Group.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setAlertChannels(newArr);
          return;
        }
      case "Site":
        if (direction) {
          const list = [...alertchannels].sort((a, b): number => {
            if (b.Site.toLowerCase() > a.Site.toLowerCase()) {
              return -1;
            }
            if (b.Site.toLowerCase() < a.Site.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setAlertChannels(newArr);
          return;
        } else {
          const list = [...alertchannels].sort((a, b): number => {
            if (b.Site.toLowerCase() > a.Site.toLowerCase()) {
              return 1;
            }
            if (b.Site.toLowerCase() < a.Site.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setAlertChannels(newArr);
          return;
        }
      case "RuleSet":
        if (direction) {
          const list = [...alertchannels].sort((a, b): number => {
            if (b.Ruleset_Name.toLowerCase() > a.Ruleset_Name.toLowerCase()) {
              return -1;
            }
            if (b.Ruleset_Name.toLowerCase() < a.Ruleset_Name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setAlertChannels(newArr);
          return;
        } else {
          const list = [...alertchannels].sort((a, b): number => {
            if (b.Ruleset_Name.toLowerCase() > a.Ruleset_Name.toLowerCase()) {
              return 1;
            }
            if (b.Ruleset_Name.toLowerCase() < a.Ruleset_Name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setAlertChannels(newArr);
          return;
        }
      case "UserCount":
        if (direction) {
          const list = [...alertchannels].sort((a, b): number => {
            return a.User_Count - b.User_Count;
          });
          let newArr = [...list];
          setAlertChannels(newArr);
          return;
        } else {
          const list = [...alertchannels].sort((a, b): number => {
            return b.User_Count - a.User_Count;
          });
          let newArr = [...list];
          setAlertChannels(newArr);
          return;
        }
      case "Assign":
        if (direction) {
          const list = [...alertchannels].sort((a, b) => {
            return b.Count - a.Count;
          });
          let newArr = [...list];
          setAlertChannels(newArr);
          return;
        } else {
          const list = [...alertchannels].sort((a, b): number => {
            return a.Count - b.Count;
          });
          let newArr = [...list];
          setAlertChannels(newArr);
          return;
        }
    }
  };

  return (
    <div>
      {!loading && (
        <>
          <FormControl
            className={classes.form_component_medium}
            variant="outlined"
            fullWidth={true}
          >
            <div style={{ marginBottom: 3 }}>
              <h5 className={classes.bold_text}>
                Select Group to filter Users
              </h5>
            </div>

            <Autocomplete
              id="combo-box-demo"
              className={classes.form_component_medium}
              options={orgslist}
              onChange={(event: React.ChangeEvent<any>, value: any) => {
                handleChangeAuto(value);
              }}
              getOptionLabel={(option) => {
                let tree = option.org_tree_names!.map(
                  (item: any) => `   /   ${item} `,
                );
                let treeString = tree.toString();
                const rpl = treeString.replace(/,/g, "");
                return `${rpl}`;
              }}
              style={{ width: 600, color: "#6D809D" }}
              classes={{ paper: styler.paper }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span
                      className={classes.bold_text}
                      style={{ color: "#15325F" }}
                    >
                      {groupname ? groupname : "Please Select Group"}
                    </span>
                  }
                  variant="outlined"
                  value={groupid}
                  className={classes.bold_text}
                />
              )}
            />
          </FormControl>
          <Divider variant="middle" style={{ margin: 20 }} />

          {message && <div style={{ marginTop: 30 }} />}
          <Collapse in={openToast}>
            {message && (
              <div style={{ marginBottom: 35 }}>
                <Alert
                  severity={err ? "error" : "success"}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        handleToastClose();
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {message}
                </Alert>
              </div>
            )}
          </Collapse>

          <FormControl
            className={classes.form_component_medium}
            variant="outlined"
            fullWidth={false}
          >
            <h2 className={classes.bold_text} style={{ marginBottom: 25 }}>
              Users
            </h2>
            <Autocomplete
              id="combo-box-demo"
              options={usersfortree!}
              onChange={(event: React.ChangeEvent<any>, value: any) => {
                handleChangeUser(value);
              }}
              getOptionLabel={(option) => {
                return `${option.first_name} ${option.last_name} (${option.username})`;
              }}
              renderOption={(option: any) => {
                if (!option.enabled) {
                  return (
                    <span style={{ color: "red" }}>
                      {option.first_name} {option.last_name} - (
                      {option.username})
                    </span>
                  );
                }
                return (
                  <span>
                    {option.first_name} {option.last_name} - {option.username}
                  </span>
                );
              }}
              style={{ width: 300, color: "#6D809D", marginLeft: 10 }}
              classes={{ paper: styler.paper }}
              renderInput={
                (params) =>
                  // (
                  {
                    return (
                      <TextField
                        {...params}
                        label={
                          <span className={classes.bold_text}>
                            {userFullName ? userFullName : "Please Select User"}
                          </span>
                        }
                        variant="outlined"
                        value={userId}
                        className={classes.bold_text}
                      />
                    );
                  }
                // )
              }
            />
          </FormControl>

          {/* Users' Filter Options */}
          <FormControl
            className={classes.form_component_medium}
            variant="outlined"
            fullWidth={false}
          >
            <div>
              <h2 className={classes.header_text}>Users' Filter Options</h2>
              <div style={{ width: 300 }}>
                <RadioGroup
                  aria-label="gender"
                  name="Users"
                  value={selectedValue}
                  onChange={(e) => handleAllUsersChange(e.target.value)}
                >
                  <FormControlLabel
                    value="subgroups"
                    control={<Radio />}
                    label="Include subgroups"
                    // label='Include subgroups of selected group'
                  />
                  <FormControlLabel
                    value="mainGroup"
                    control={<Radio />}
                    label="Exclude subgroups"
                    // label='Exclude subgroups of selected group'
                  />
                </RadioGroup>
                <br />
              </div>
            </div>

            {/* Multiple Select for User Roles*/}
          </FormControl>
          {userId! >= 0 && (
            <div>
              <FormControl className={classes.form_component_medium}>
                <h2
                  className={classes.bold_text}
                  style={{ marginTop: 25, marginBottom: 25 }}
                >
                  Assigned Alert Web Interface Roles
                </h2>

                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  disabled={true}
                  value={roleNames}
                  onChange={handleChange}
                  input={<Input id="select-multiple-chip" />}
                  IconComponent={() => null}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {(selected as string[]).map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, personName, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {userId! >= 0 && (
            <div>
              <FormControl className={classes.form_component_medium}>
                <h2
                  className={classes.bold_text}
                  style={{ marginTop: 25, marginBottom: 25 }}
                >
                  Select Group to List Sites
                </h2>
                <Autocomplete
                  id="combo-box-demo"
                  className={classes.form_component_medium}
                  options={orgslist}
                  onChange={(event: React.ChangeEvent<any>, value: any) => {
                    handleSelectedChangeAuto(value);
                  }}
                  getOptionLabel={(option) => {
                    let tree = option.org_tree_names!.map(
                      (item: any) => `   /   ${item} `,
                    );
                    let treeString = tree.toString();
                    const rpl = treeString.replace(/,/g, "");
                    return `${rpl}`;
                  }}
                  style={{ width: 600, color: "#6D809D" }}
                  classes={{ paper: styler.paper }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span
                          className={classes.bold_text}
                          style={{ color: "#15325F" }}
                        >
                          {selectedGroupName
                            ? selectedGroupName
                            : "Please Select Group"}
                        </span>
                      }
                      variant="outlined"
                      value={selectedGroupId}
                      className={classes.bold_text}
                    />
                  )}
                />
              </FormControl>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 30,
            }}
          >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                className={classes.bold_text}
              >
                &nbsp; ORDER BY &nbsp;
              </InputLabel>
              <Select
                native
                defaultValue={"Group ASC!"}
                onChange={(e) => handleOrderChange(e)}
                label="Order By"
                inputProps={{
                  name: "Order By",
                  id: "outlined-age-native-simple",
                }}
              >
                <option aria-label="None" value="" />
                <option value={"Group ASC"}>Group (Ascending)</option>
                <option value={"Group DSC"}>Group (Descending)</option>
                <option value={"Site ASC"}>Site (Ascending)</option>
                <option value={"Site DSC"}>Site (Descending)</option>
                <option value={"RuleSet ASC"}>RuleSet (Ascending)</option>
                <option value={"RuleSet DSC"}>RuleSet (Descending)</option>
                <option value={"UserCount ASC"}>User Count (Ascending)</option>
                <option value={"UserCount DSC"}>User Count (Descending)</option>
                {userId! >= 0 && (
                  <>
                    <option value={"Assign ASC"}>Assigned Channels</option>
                    <option value={"Assign DSC"}>UnAssigned Channels</option>
                  </>
                )}
              </Select>
            </FormControl>
            {userId! >= 0 && (
              <div className={classes.button_container_small}>
                <Button
                  variant="contained"
                  className={`${classes.cancel_button2} {$classes.bold_text}`}
                  onClick={unSelectAllHandler}
                >
                  unSelect All
                </Button>
                <Button
                  variant="contained"
                  className={classes.mute_button}
                  onClick={selectAllHandler}
                >
                  Select All
                </Button>
                {/* <FormControl className={classes.form_component_small}> */}
                <Button
                  style={{ width: 170 }}
                  className={classes.mute_button}
                  variant="contained"
                  onClick={() => {
                    updateUsersRoles();
                  }}
                  // disabled={isEditable ? false : true}
                >
                  Save Changes
                </Button>
                {/* </FormControl> */}
              </div>
            )}
          </div>

          <br />
          <AntdSwitch
            checked={enabledSites}
            checkedChildren="All sites"
            unCheckedChildren="Enabled sites only"
            onChange={(e) => setEnabledSites(e)}
          />
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              marginTop: 15,
              borderRadius: 15,
              overflow: "hidden",
            }}
          >
            {alertchannels && alertchannels.length > 0 && (
              <h5 className={classes.bold_text} style={{ margin: 10 }}>
                Results Count: &nbsp;
                <span style={{ color: "#ccce" }}>
                  {alertchannels.length}{" "}
                </span>{" "}
              </h5>
            )}
            <TableContainer
              component={Paper}
              style={{ maxHeight: 600, overflowY: "scroll" }}
            >
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontSize: 13,
                          color: "#616161",
                          fontWeight: "bold",
                        }}
                      >
                        Group
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        style={{
                          fontSize: 13,
                          color: "#616161",
                          fontWeight: "bold",
                        }}
                      >
                        Site
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        style={{
                          fontSize: 13,
                          color: "#616161",
                          fontWeight: "bold",
                        }}
                      >
                        Ruleset Name
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        style={{
                          fontSize: 13,
                          color: "#616161",
                          fontWeight: "bold",
                        }}
                      >
                        Site Assigned Users
                      </span>
                    </TableCell>
                    {userId! >= 0 && (
                      <TableCell>
                        <strong
                          style={{
                            fontSize: 13,
                            color: "#616161",
                            fontWeight: "bold",
                          }}
                        >
                          Assign
                        </strong>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>{alertchanneldisplay}</TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
      {loading && <LoadingSpinner />}

      {/* Dialog / Pop Up for Table */}
      <Dialog
        fullWidth={false}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2
            className={classes.bold_text}
            style={{ marginBottom: 25, marginTop: 25 }}
          >
            Site Assigned Users and Roles
          </h2>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table size="small" stickyHeader>
                {!loadingTable && (
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>No. of Roles Assigned</TableCell>
                      <TableCell>Assigned Roles</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {!loadingTable ? (
                    usersListDisplay
                  ) : (
                    // <StyledTableRow>
                    <TableCell>
                      <div
                        className={classes.bold_text}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoadingSpinner />
                      </div>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default RuleSetsAssignment;
