import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { AppStore } from "../../../stores/AppStore";
import { Site, Sensor } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

interface DynDnsModalProps {
  appStore?: AppStore;
  onClose: () => void;
  open: boolean;
  site: Site | undefined;
}

const ActivateDynDnsModal: React.FC<DynDnsModalProps> = ({
  onClose,
  open,
  appStore,
  site,
}: DynDnsModalProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [emailAddress, setEmailAddress] = React.useState<string>("");
  const [siteId, setSiteId] = React.useState<number>(0);
  const [siteName, setSiteName] = React.useState<string>("");
  const [siteAdmin, setSiteAdmin] = React.useState<boolean>(false);
  const [sysAdmin, setSysAdmin] = React.useState<boolean>(false);
  const [dynDnsUsername, setDynDnsUsername] = React.useState<string>("");
  const [dynDnsPassword, setDynDnsPassword] = React.useState<string>("");
  const [dynDnsDomain, setDynDnsDomain] = React.useState<string>("");
  const [renderValue, setRenderValue] = useState(false);

  // var server_address: string = "";
  // let host = window.location.hostname;
  // if (host === "localhost") {
  //   server_address = "ddns-api.staging.deepalert.ai";
  // } else {
  //   var url_ending: string = host.slice(host.indexOf(".") + 1);
  //   server_address = "ddns-api." + url_ending;
  // }
  useEffect(() => {
    if (site) {
      setSiteId(site.site_id);
    }
  }, [renderValue, site]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     let dynDnsConfiguration = {
  //       username: "",
  //       password: "",
  //     };
  //     let siteId = null;
  //     let domain = "";
  //
  //     const token = await appStore?.authService.getAuthorisedToken();
  //     if (token) {
  //       if (site) {
  //         siteId = site.site_id;
  //       }
  //       if (siteId !== null) {
  //         let dynDnsResult =
  //           await appStore?.functionService.fetchDynDnsConfiguration(
  //             token,
  //             "site_id",
  //             siteId
  //           );
  //         if (dynDnsResult && dynDnsResult.username && dynDnsResult.password) {
  //           dynDnsConfiguration.username = dynDnsResult.username;
  //           dynDnsConfiguration.password = dynDnsResult.password;
  //         }
  //         let hubDetails = await appStore?.functionService.getHubsForSite(
  //           token,
  //           siteId
  //         );
  //         if (hubDetails && hubDetails[0].hub_id_str) {
  //           domain = hubDetails[0].hub_id_str + "." + server_address;
  //         }
  //       }
  //     }
  //     setDynDnsDomain(domain);
  //     setDynDnsUsername(dynDnsConfiguration.username);
  //     setDynDnsPassword(dynDnsConfiguration.password);
  //   };
  //   fetchData();
  // });

  const handleEmail = (event: React.ChangeEvent<any>) => {
    setEmailAddress(event.target.value);
    if (site) {
      setSiteId(site.site_id);
    }
  };

  const requestDynDNSActivation = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      await appStore?.functionService.requestDynDnsActivation(
        token,
        siteId,
        emailAddress,
      );
    }
  };

  const handleClose = () => {
    setRenderValue((value) => !value);
    setDynDnsUsername("");
    setDynDnsPassword("");
    onClose();
  };

  const StyledTableRowDynDns = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className={classes.modal_dialog_wide}
      fullWidth={true}
    >
      <DialogTitle className={classes.bold_text}>
        Activate DynDNS for {site?.name}
      </DialogTitle>
      <br></br>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <TextField
          fullWidth
          label="email_address"
          placeholder="Enter Email Address"
          onChange={handleEmail}
          variant="outlined"
          margin="normal"
          helperText="DynDNS config details will be sent to this email address"
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
      )}

      <div className={classes.twoButtonsInARow}>
        <Button
          variant="contained"
          className={classes.cancel_button}
          onClick={() => {
            setRenderValue((value) => !value);
            setEmailAddress("");
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className={classes.bold_text}
          onClick={() => {
            requestDynDNSActivation();
            setRenderValue((value) => !value);
            setEmailAddress("");
            onClose();
          }}
        >
          Activate
        </Button>
      </div>
    </Dialog>
  );

  // return (
  //   <Dialog
  //     onClose={handleClose}
  //     open={open}
  //     className={classes.modal_dialog_wide}
  //   >
  //     <DialogTitle>
  //       <span
  //         className={classes.bold_text}
  //         style={{ fontSize: 20, color: "primary" }}
  //       >
  //         Dynamic DNS settings
  //       </span>
  //     </DialogTitle>
  //     <br></br>
  //     {loading ? (
  //       <LoadingSpinner />
  //     ) : (
  //       <>
  //         <div
  //           style={{
  //             boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
  //             marginTop: 15,
  //             borderRadius: 15,
  //             overflow: "hidden",
  //           }}
  //         >
  //           <TableContainer>
  //             <StyledTableRowDynDns>
  //               <TableCell>
  //                 <Typography className={classes.bold_text_billing}>
  //                   Server Address:{" "}
  //                 </Typography>
  //               </TableCell>
  //               <TableCell>
  //                 <Typography
  //                   className={classes.bold_text}
  //                   style={{ color: "grey" }}
  //                 >
  //                   {server_address}
  //                 </Typography>
  //               </TableCell>
  //             </StyledTableRowDynDns>
  //
  //             <StyledTableRowDynDns>
  //               <TableCell>
  //                 <Typography className={classes.bold_text_billing}>
  //                   Domain:{" "}
  //                 </Typography>
  //               </TableCell>
  //               <TableCell>
  //                 <Typography
  //                   className={classes.bold_text}
  //                   style={{ color: "grey" }}
  //                 >
  //                   {dynDnsDomain}
  //                 </Typography>
  //               </TableCell>
  //             </StyledTableRowDynDns>
  //
  //             <StyledTableRowDynDns>
  //               <TableCell>
  //                 <Typography className={classes.bold_text_billing}>
  //                   Username:{" "}
  //                 </Typography>
  //               </TableCell>
  //               <TableCell>
  //                 <Typography
  //                   className={classes.bold_text}
  //                   style={{ color: "grey" }}
  //                 >
  //                   {dynDnsUsername}
  //                 </Typography>
  //               </TableCell>
  //             </StyledTableRowDynDns>
  //
  //             <StyledTableRowDynDns>
  //               <TableCell>
  //                 <Typography className={classes.bold_text_billing}>
  //                   Password:{" "}
  //                 </Typography>
  //               </TableCell>
  //               <TableCell>
  //                 <Typography
  //                   className={classes.bold_text}
  //                   style={{ color: "grey" }}
  //                 >
  //                   {dynDnsPassword}
  //                 </Typography>
  //               </TableCell>
  //             </StyledTableRowDynDns>
  //           </TableContainer>
  //         </div>
  //       </>
  //     )}
  //   </Dialog>
  // );
};
export default ActivateDynDnsModal;
