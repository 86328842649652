import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";

import { useStyles } from "../../styling/global/global";

interface TermsProps {
  checked: boolean;
  setChecked: (check: boolean) => void;
}

const Terms: React.FC<TermsProps> = ({ checked, setChecked }: TermsProps) => {
  const classes = useStyles();
  console.debug(checked);
  return (
    <div style={{ maxHeight: 400, overflowY: "scroll" }}>
      <p style={{ fontWeight: 800, color: "#ffff" }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          1. ABOUT US
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400, color: "#ffff" }}>
          1.1 Company details.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400, color: "#ffff" }}>
          Southern Africa Customers :{" "}
        </span>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          DeepAlert (Pty) Ltd (2021/427470/07){" "}
        </strong>
        <span style={{ fontWeight: 400, color: "#ffff" }}>
          is a company registered in South Africa. Our registered office and
          main trading address is at 12 Blaauwklippen Road, Kirstenhof, 7945,
          South Africa. Our VAT number is{" "}
        </span>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          4010295659.
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>Non Southern Africa Customers: </span>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          DeepAlert Limited (12356079)
        </strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          is a company registered in England and Wales. Our registered office
          and main trading address is at 25 East Street, Bromley, BR1QE. Our VAT
          number is{" "}
        </span>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          GB356678255
        </strong>
        <span style={{ fontWeight: 400 }}>.&nbsp;</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>We operate from the website </span>
        <a href="http://www.deepalert.ai/" target="_blank">
          <span style={{ fontWeight: 400, color: "#ffff" }}>
            www.deepalert.ai
          </span>
        </a>
        <span style={{ fontWeight: 400, color: "#ffff" }}>.</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400, color: "#ffff" }}>
          Hereafter we are referred to as the{" "}
        </span>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          Company.
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>1.2 Contacting us:&nbsp;</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          To contact us telephone our customer service team at
          +27212017111&nbsp; or email{" "}
        </span>
        <span style={{ fontWeight: 400 }}>support@deepalert.ai</span>
        <span style={{ fontWeight: 400 }}>&nbsp;</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>Our contract with you:</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>1.3 Our contract:&nbsp;</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          These terms and conditions (Terms) apply to the order by you and
          supply of goods/services by us to you (Contract). No other terms are
          implied by trade, custom, practice or course of dealing.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>1.4 Entire agreement: </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          The Contract is the entire agreement between us in relation to its
          subject matter. You acknowledge that you have not relied on any
          statement, promise or representation or assurance or warranty that is
          not set out in the Contract.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>1.5 Language:&nbsp;</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          These Terms and the Contract are made only in the English language.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>1.6 Your copy:&nbsp;</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          You should print a copy of these Terms (or save them to your computer)
          for future reference.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          1.7 The most up to date version of this document can be found on our
          website{" "}
        </span>
        <a href="http://www.deepalert.ai/" target="_blank">
          <span style={{ fontWeight: 400 }}>www.deepalert.ai</span>
        </a>
        <span style={{ fontWeight: 400 }}>
          {" "}
          and, unless otherwise agreed between the parties, will be deemed to be
          terms agreed between the parties.&nbsp;
        </span>
      </p>

      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          2. PLACING AN ORDER AND ITS ACCEPTANCE
        </strong>
      </p>

      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>2.1 Placing your order:&nbsp;</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          Placing an order for our products is an online process in most cases
          and the service is available and billable immediately. Should you
          require a hardware component for your product, contact support. Each
          order is an offer by you to buy the goods/services specified in the
          order subject to these Terms. POC (Proof of Concept) products are at
          zero charge for the duration of the POC.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>2.2 Correcting errors:&nbsp;</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          For hardware orders, our order process allows you to check and amend
          any errors before submitting your order to us. Please check the order
          carefully before confirming it. You are responsible for ensuring that
          your order [and any specification submitted by you] is complete and
          accurate.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          2.3 Acknowledging receipt of your order:&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          After you place a hardware order, you will receive an email from us
          acknowledging that we have received it, but please note that this does
          not mean that your order has been accepted. Our acceptance of your
          order will take place as described in 2.4.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>2.4 Accepting your order:</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          We will confirm our acceptance of your hardware order by sending you
          an email. The Contract between you and us will only be formed when we
          send you the confirmation.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          2.5 If we cannot accept your order or if we are unable to supply you
          with the Goods/Services for any reason, we will inform you of this by
          email and we will not process your order. At any stage, we reserve the
          right to control access to our systems and to refuse the service to a
          particular customer. If you have already paid for the Goods, we will
          refund you the full amount.&nbsp;
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          3. OUR GOODS/SERVICES
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          3.1 The images of the Goods on our site are for illustrative purposes
          only.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          3.2 We will make every effort to be as accurate as possible with the
          description of our goods and services.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          3.3 We reserve the right to amend the specification of the Goods
          supplied as part of our process of continuous improvement of the
          products. Furthermore, we reserve the right to amend the specification
          of the Goods if required by any applicable statutory or regulatory
          requirement.
        </span>
      </p>
      <p style={{ color: "#ffff" }}></p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          3.4. We adhere to a Service Level Agreement (SLA) of 99.9% (Uptime)
          for our core cloud service per month. In the event of these being in
          breach then the following Service Credits can be claimed for. The
          breach may be calculated using:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400, color: "#ffff" }}>
          <span style={{ fontWeight: 400, color: "#ffff" }}>
            A percentage of Previous Monthly Subscription Services Bill
          </span>
        </li>
        <li style={{ fontWeight: 400, color: "#ffff" }}>
          <span style={{ fontWeight: 400, color: "#ffff" }}>
            Based on a continuous service downtime as shown in the table below
          </span>
        </li>
        <li style={{ fontWeight: 400, color: "#ffff" }}>
          <span style={{ fontWeight: 400, color: "#ffff" }}>
            Offset against future invoices
          </span>
        </li>
      </ul>
      <Paper style={{ width: "100%", overflow: "hidden" }}>
        <TableContainer style={{ maxHeight: 300 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "#1f3161",
                  height: "20px",
                  color: "#fff",
                }}
              >
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  ></Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    SLA Range
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    Service Credit Level
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    Above
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    99.90%
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    0%
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    From
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    99.77% &gt; 99.90%
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    20%
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    From
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    99.65% &gt; 99.77%
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    35%
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    From
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    99.54% &gt; 99.65%
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    50%
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    Below
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    99.54%
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{ color: "#3f51b5" }}
                    className={classes.bold_text}
                  >
                    100%
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <p style={{ color: "#ffff" }}>&nbsp;</p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          3.5 For the avoidance of doubt and for the purposes of the SLA, our
          core service is defined as:
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (a) the cloud based machinery needed for us to receive data from your
          sites;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (b) the cloud based classification of images;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (c) the cloud based delivery of alerts;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          Misconfiguration of our services is excluded from the SLA.
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          4. RETURN AND REFUND
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          4.1 You may cancel the Contract and receive a refund for any
          non-hardware components if you notify us within 14 days of your
          receipt of goods/services. This is known as a &ldquo;Cooling
          Off&rdquo; period and only applies to your first order.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          4.2 To cancel the Contract during the Cooling Off period, email us
          at{" "}
        </span>
        <span style={{ fontWeight: 400 }}>support@deepalert.ai</span>
        <span style={{ fontWeight: 400 }}>
          . Please include details of your order to help us to identify it. Your
          cancellation is effective from the date you send us the email. For
          cancellation of services after the expiry of the Cooling Off period,
          entire sites or individual cameras should be disabled by you on the
          DeepAlert Management Web Interface or via API call (if applicable) and
          must reflect as disabled on the DeepAlert Management Web Interface.
          Disabled sites and cameras will not be included in subsequent billing
          cycles while these remain disabled.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          4.3 If Goods/services have been delivered to you (such as a Hub)
          before you decide to cancel the Contract, you must return them or
          cease use of the service without undue delay. This,&nbsp; in any
          event, must be done no later than 14 days after the day on which you
          let us know that you wish to cancel the Contract.&nbsp;
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          5 DELIVERY
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          5.1 Most of our products are immediately available online. If we are
          shipping a hardware component, we will confirm the expected date of
          delivery to you.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          5.2 Delivery is complete once the Goods/services have been
          acknowledged as received.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          5.3 If we fail to deliver the Goods/services, our liability is limited
          to the cost of replacing them with our own goods/services. However, we
          will not be liable to the extent that any failure to deliver was
          caused by an Event Outside Our Control, or because you failed to
          provide adequate delivery instructions or any other instructions that
          are relevant to the supply of goods/services.
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          6 INTERNATIONAL DELIVERY
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          6.1 We can deliver our goods/services to international buyers.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          6.2 If you order Goods/services from our site or from a representative
          for delivery to an International destination, your order may be
          subject to import duties and taxes which are applied when the delivery
          reaches that destination. Please note that we have no control over
          these charges and we cannot predict their amount.&nbsp;&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          6.3 You will be responsible for payment of any such import duties and
          taxes. Please contact your local customs office for further
          information before placing your order.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          6.4 You must comply with all applicable laws and regulations of the
          country for which the Goods are destined. We will not be held liable
          or responsible if you break any such law.
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          7. PRICE OF GOODS AND DELIVERY CHARGES
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          7.1 The price of the Goods/services will be as quoted on our site or
          agreed with a representative(s) at the time you submit your order. We
          take all reasonable care to ensure that the prices of Goods/services
          listed on the site are correct at all times.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          7.2 We will bill you for each camera that is connected to our service.
          In the first month a camera is connected, that month will be billed in
          arrears for that particular camera, prorated for the number of days in
          the month from when the camera was first connected (POC products are
          at zero charge for the duration of the POC). In all other cases, the
          camera is billed a month in advance or annually in advance while it is
          enabled. You have the right to disable and remove cameras at any stage
          and you won&rsquo;t be charged in the following billing cycle.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          7.3 We reserve the right to disable and remove cameras that are not
          functioning within the normal operating bounds of our system. We will
          inform you of this at the time of disabling the affected camera.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          7.4 Prices for our Goods may change from time to time, but changes
          will not affect any order you have already placed, subject to our
          right to increase prices annually. This increase is&nbsp; limited to
          the prevailing published CPI rate or 5%, whichever is the lower.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          7.5 The price of Goods excludes VAT (where applicable) at the
          applicable current rate chargeable in the region of the Company for
          the time being.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          7.6 The price of the hardware components does not include delivery
          charges.&nbsp;
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          8. HOW TO PAY
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          8.1 Invoices must be paid to our bank within 30 days as per the
          details displayed on the invoice.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          8.2 Late payment charges may be raised at a rate of 4% of the invoiced
          amount.
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          9. MANUFACTURER'S GUARANTEE
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          9.1 Our hardware comes with a manufacturer's guarantee. Upon
          application please contact our Support service to discuss this.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <br />
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          10. OUR WARRANTY FOR THE SERVICES
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          10.1 The Services are intended for use by the contract holder only.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          10.2 We provide a warranty that on delivery, the Services shall;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (a) be fit for any purpose held out by us.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>10.3 Subject to 4.1, if:</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (a) you give us notice in writing within a reasonable time of
          discovery that some or all of the Services do not comply with the
          warranty set out in 10.2;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (b) we are given a reasonable opportunity of examining the Services;
          and
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (c) if we ask you to do so, you return the hardware to us;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (d) we will decide whether to repair or replace the defective
          Services, or refund the price of the defective Services in full.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          10.4 We will not be held liable for breach of the warranty set out in
          10.2 if:
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (a) you make any further use of the Goods after giving us notices
          under 10.3 and us instructing you to cancel/disable the service;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (b) the defect arises as a result of us following any design or
          specification supplied by you;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (c) you alter or repair the Goods/services without our written
          consent;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (d) the defect arises as a result of negligence on your part or that
          of your Representative/Agent; or
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (e) the Goods differ from their description or specification as a
          result of changes made to ensure that they comply with applicable
          statutory or regulatory requirements.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (f) should the defect arise as a result of any third party hardware or
          software provided and used by you.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          10.5 We will only be liable to you for the Goods/services failure to
          comply with the warranty set out in 10.2 to the extent set out in this
          clause 10.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          10.6 The terms implied by sections 13 to 15 of the UK Sale of Goods
          Act 1979 are, to the fullest extent permitted by law, excluded from
          the Contract.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          10.7 We reserve the right to discontinue a product and will provide 8
          weeks&rsquo; notice to that effect. We will endeavour to provide you
          with a compatible replacement product.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          10.8 These Terms also apply to any repaired or replacement
          Goods/services supplied by us to you.
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          11. LIABILITY
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          11.1 Liabilities in this contract are limited and set out below.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>11.2 We share no liability for:</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (a) any loss of profits, sales, business, or revenue; or
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (b) loss or corruption of data, information or other 3
        </span>
        <span style={{ fontWeight: 400 }}>rd</span>
        <span style={{ fontWeight: 400 }}> party software; or</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (c) loss of business opportunity; or
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (d) loss of anticipated savings; or
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>(e) loss of goodwill; or</span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (f) any indirect or consequential loss.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (g) any Tax, VAT, other duties or Country Specific taxes or duties
          applicable to your company. (Should any be incurred by us, you will
          indemnify these to us in full).
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          11.3 Subject to 11.2, our total liability to you for all losses
          arising under or in connection with the Contract will in no
          circumstances exceed the price of the Goods/services.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          11.4 Any representation, condition or warranty which might be implied
          or incorporated into these Terms by statute, common law or otherwise
          is excluded to the fullest extent permitted by law. In particular, we
          will not be responsible for ensuring that the Goods or Services are
          suitable for your purposes.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          11.5 Deep neural networks are a powerful modern technology used at the
          core of DeepAlert for image and scene recognition. There are well
          documented fundamental features of deep neural network technology,
          which result in limitations compared to human performance of the same
          task.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          It is, on rare occasions, possible to "fool" a deep neural network
          into a misclassification with subtle variations in image pixel values
          where a human would not make the same "mistake". Deep neural networks
          used in image recognition do not process images in the same way as a
          human and therefore cannot be directly compared to the "equivalent
          human".&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          The ability to be "fooled" by an image on occasions which would not
          "fool" a human is due to the nature of the technology and not
          something that can be completely eliminated by any
          implementation.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          While we do focus our model training to be as broadly applicable as
          possible and design our systems to provide a strong level of
          robustness in the face of such variations, small variations in
          lighting, colour, image contrast, and other effects related to the
          camera installation, type of scene and environmental conditions, can
          sometimes affect recognition performance in addition to more obvious
          limitations of resolution and detection range.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          Users of DeepAlert should perform testing of the system at the
          installed sites to ensure that they are experiencing detection
          performance acceptable for their setup and application. Regular site
          testing is good practice for picking up environmental issues such as
          moisture in the optics, spider webs, glaring lighting affecting
          contrast, etc. which may also degrade camera performance and limit
          object recognition ability.
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          12. TERMINATION
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          12.1 Without limiting any of our other rights, we may suspend the
          supply or delivery of the Goods/services to you, or terminate the
          Contract with immediate effect by giving written notice to you if:
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (a) you commit a material breach of any term of the Contract and (if
          such a breach is remediable) fail to remedy that breach within 30 days
          of you being notified in writing to do so;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (b) you fail to pay any amount due under the Contract on the due date
          for payment;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (c) you suspend, threaten to suspend, cease or threaten to cease to
          carry on all or a substantial part of your business; or
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (d) your financial position deteriorates to such an extent that in our
          reasonable opinion your capability to adequately fulfil your
          obligations under the Contract has been placed in jeopardy.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          12.2 Termination of the Contract shall not affect your rights or ours
          and remedies that have accrued as at termination.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          12.3 Any provision of the Contract that expressly or by implication is
          intended to come into or continue to be in force on or after
          termination shall remain in full force and effect.
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          13. EVENTS OUTSIDE OUR CONTROL
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          13.1 We will not be liable or responsible for any failure to perform,
          or the delay in performance of, any of our obligations under the
          Contract that is caused by any act or event beyond our reasonable
          control (Event Outside Our Control). For example, if weather
          conditions affect the performance of security devices or a major
          internet outage takes place.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          13.2 If an Event Outside Our Control takes place that affects the
          performance of our obligations under the Contract:
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (a) we will contact you as soon as reasonably possible to notify you;
          and
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (b) our obligations under the Contract will be suspended and the time
          for performance of our obligations will be extended for the duration
          of the Event Outside Our Control. Where the Event Outside Our Control
          affects our delivery of Goods to you, we will arrange a new delivery
          date with you after the Event Outside Our Control is over.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          13.3 You may cancel the Contract affected by an Event Outside Our
          Control which has continued for more than 30 days. To cancel please
          contact us.&nbsp;&nbsp;
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          14. COMMUNICATIONS BETWEEN US
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          14.1 When we refer to "in writing" in these Terms, this includes
          email.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          14.2 Any notice or other communication given [by one of us to the
          other] under or in connection with the Contract must be in writing and
          be delivered by email.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          14.3 A notice or other communication is deemed to have been received
          by 09h00 the next working day&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          14.4 In proving the service of any notice, it will be sufficient to
          prove that such email was sent to the specified email address of the
          addressee.&nbsp;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          14.5 The provisions of this clause shall not apply to the service of
          any proceedings or other documents in any legal action.
        </span>
      </p>
      <p style={{ fontWeight: 800 }}>
        <strong style={{ color: "#ffff", fontSize: 15, fontWeight: 800 }}>
          15. ASSIGNMENT AND TRANSFER
        </strong>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          15.1 As per the contents of this agreement we retain the right to;
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (a) assign or transfer our rights and obligations under the Contract
          to another entity [but will always notify you [in writing] or by
          posting on this webpage if this happens].
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>
          (b) assign or transfer your rights or your obligations under the
          Contract to another person if we agree in writing.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>15.2 </span>
        <span style={{ fontWeight: 400 }}>Waiver.</span>
        <span style={{ fontWeight: 400 }}>
          {" "}
          If we do not insist that you perform any of your obligations under the
          Contract, or if we do not exercise our rights or remedies against you,
          or if we delay in doing so, that will not mean that we have waived our
          rights or remedies against you or that you do not have to comply with
          those obligations. If we waive any rights or remedies, we will only do
          so in writing, and that will not mean that we will automatically waive
          any right or remedy related to any later default by you.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>15.3 </span>
        <span style={{ fontWeight: 400 }}>Severance.</span>
        <span style={{ fontWeight: 400 }}>
          {" "}
          Each paragraph of these Terms operates separately. If any court or
          relevant authority decides that any of them is unlawful or
          unenforceable, the remaining paragraphs will remain in full force and
          effect.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>15.4 </span>
        <span style={{ fontWeight: 400 }}>Third party rights.</span>
        <span style={{ fontWeight: 400 }}>
          {" "}
          The Contract is between you and us. No other person or company has any
          rights to enforce any of its terms.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <span style={{ fontWeight: 400 }}>15.5 </span>
        <span style={{ fontWeight: 400 }}>Governing law and jurisdiction.</span>
        <span style={{ fontWeight: 400 }}>
          {" "}
          This Contract is governed by English law or South African Law, as
          determined by the location of the Company. Each party irrevocably
          agrees to submit all disputes arising out of or in connection with
          this Contract to the exclusive jurisdiction of the English courts or
          the South African courts, as determined by the location of the
          Company.
        </span>
      </p>
      <p style={{ color: "#ffff" }}>
        <br />
      </p>
      <p style={{ color: "#ffff" }}>
        <br />
      </p>
    </div>
  );
};

export default Terms;
