import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { AppStore } from "../../../stores/AppStore";
import { Site, BillingRate } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

interface EditSiteBillingRatesModalProps {
  appStore?: AppStore;
  onClose: () => void;
  site: Site | undefined;
  open: boolean;
  updateSiteBillingRate: (site_id: number, billing_rate_id: number) => void;
  message: string;
}

const EditSiteBillingRatesModal: React.FC<EditSiteBillingRatesModalProps> = ({
  onClose,
  open,
  appStore,
  message,
  site,
  updateSiteBillingRate,
}: EditSiteBillingRatesModalProps) => {
  const classes = useStyles();
  const [billing_rate_id, setBillingRateId] = React.useState(0);
  const [billing_rates, setBillingRates] = React.useState<
    BillingRate[] | undefined
  >(undefined);

  useEffect(() => {
    const fetchData = async () => {
      if (open) {
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var billing_rates =
            await appStore?.functionService.getBillingRates(token);
          if (billing_rates) {
            setBillingRates(billing_rates);
          }
        }
      }
    };
    fetchData();
  }, [open]);
  useEffect(() => {
    const fetchData = async () => {
      if (site) {
        setBillingRateId(site.billing_rate_id);
      }
    };
    fetchData();
  }, [site]);

  const handleClose = () => {
    setBillingRateId(0);
    onClose();
  };
  const handleSave = () => {
    updateSiteBillingRate(site!.site_id, billing_rate_id);
  };
  const handleProductChange = (event: React.ChangeEvent<any>) => {
    setBillingRateId(Number(event.target.value));
  };
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  var products: React.ReactNode[] = [];
  if (billing_rates) {
    billing_rates.forEach((billing_rate) => {
      products.push(
        <StyledTableRow>
          <TableCell>
            <Typography>
              <Radio
                checked={billing_rate_id === billing_rate.id}
                onChange={handleProductChange}
                value={billing_rate.id}
              />
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {billing_rate.id}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {billing_rate.product}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {billing_rate.public_product_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {billing_rate.tier}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {billing_rate.classifier}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {billing_rate.data_origin.name}
            </Typography>
          </TableCell>
        </StyledTableRow>,
      );
    });
  }
  if (billing_rates) {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className={classes.modal_dialog_wide}
        maxWidth="md"
      >
        <DialogTitle className={classes.bold_text}>
          Change product for {site?.name}
        </DialogTitle>
        <br></br>
        <Typography className={classes.bold_error_text}>{message}</Typography>
        <div
          style={{
            boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
            marginTop: 15,
            borderRadius: 15,
            overflow: "hidden",
            marginRight: 5,
            marginLeft: 5,
          }}
        >
          <TableContainer component={Paper} style={{ maxHeight: 350 }}>
            <Table size="medium" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      style={{ color: "black" }}
                      className={classes.bold_text}
                    >
                      select
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{ color: "black" }}
                      className={classes.bold_text}
                    >
                      billing&nbsp;rate&nbsp;id
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{ color: "black" }}
                      className={classes.bold_text}
                    >
                      billing&nbsp;rate
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{ color: "black" }}
                      className={classes.bold_text}
                    >
                      product public name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{ color: "black" }}
                      className={classes.bold_text}
                    >
                      tier
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{ color: "black" }}
                      className={classes.bold_text}
                    >
                      classifier
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{ color: "black" }}
                      className={classes.bold_text}
                    >
                      data origin
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{products}</TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={classes.button_container}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={handleClose}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            className={classes.save_button}
            onClick={handleSave}
          >
            SAVE
          </Button>
        </div>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className={classes.modal_dialog_wide}
        maxWidth="md"
      >
        <DialogTitle className={classes.bold_text}>
          Change product for {site?.name}
        </DialogTitle>
        <br></br>
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </Dialog>
    );
  }
};
export default EditSiteBillingRatesModal;
