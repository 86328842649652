import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

import { useStyles } from "../../../styling/global/global";
import { AppStore } from "../../../../stores/AppStore";

import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import {
  listBillingRunsItems,
  listBillingProfileOrgsItems,
  BillingExportMode,
} from "../../../../services/FunctionService";
import { ArrayToStringifiedArray } from "../../../../hooks/ArrayToStringifiedArray";
interface ExportBillingRunProps {
  appStore?: AppStore;
  open: boolean;
  onClose: () => void;
  billingRun: listBillingRunsItems | undefined;
  profile_id: number | undefined;
  exportBillingRun: (
    billing_run_id: number,
    billing_org_ids: string,
    selectedType: BillingExportMode,
  ) => void;
}

const ExportBillingRun: React.FC<ExportBillingRunProps> = ({
  open,
  onClose,
  appStore,
  profile_id,
  exportBillingRun,
  billingRun,
}: ExportBillingRunProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [renderValue, setRenderValue] = useState(false);
  const [viewProfileOrgsData, setProfileOrgsViewData] = useState<
    listBillingProfileOrgsItems[] | undefined
  >(undefined);
  const [checkedState, setCheckedState] = useState<boolean[] | undefined>([]);
  const [orgIds, setOrgIds] = React.useState<number[]>([]);

  const [selectedType, setSelectedType] =
    useState<BillingExportMode>("single_csv_file");

  const handleSelectionTypeChange = (event: React.ChangeEvent<any>) => {
    setSelectedType(event.target.value);
  };
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  const handleCheckboxChange = (position: number) => {
    const updatedCheckedState = checkedState!.map((item, index) =>
      index === position ? !item : item,
    );

    setCheckedState(updatedCheckedState);
  };

  const handleSelectAll = () => {
    if (viewProfileOrgsData) {
      setCheckedState(new Array(viewProfileOrgsData.length).fill(true));
    }
  };
  const handleDeSelectAll = () => {
    if (viewProfileOrgsData) {
      setCheckedState(new Array(viewProfileOrgsData.length).fill(false));
    }
  };
  const handleGetSelectedProfileOrgs = () => {
    var idsArray: number[] = [];
    viewProfileOrgsData &&
      viewProfileOrgsData.map((OrgItem, orgIndex) => {
        checkedState &&
          checkedState.map((checkedItem, checkedIndex) => {
            if (checkedItem == true) {
              if (orgIndex === checkedIndex) {
                idsArray.push(OrgItem.billing_org_id);
              }
            }
          });
      });
    if (idsArray.length > 0 && billingRun) {
      let stringifiedIds = ArrayToStringifiedArray(idsArray);
      exportBillingRun(billingRun!.id, stringifiedIds, selectedType);
      setRenderValue((value) => !value);
      setSelectedType("single_csv_file");
      onClose();
    }
  };

  const counts: { [key: string]: number } = {};
  checkedState &&
    checkedState.map((x) => {
      let item = x.toString();
      counts[item] = (counts[item] || 0) + 1;
    });
  useEffect(() => {
    const fetchBillingRuns = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        if (profile_id) {
          const listRunsResult =
            await appStore?.functionService.listBillingRuns(token, profile_id);
          if (listRunsResult) {
            setLoading(false);
          }
        }
      }
    };
    const ViewProfileFunction = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        if (profile_id) {
          const listBillingProfileOrgsResult =
            await appStore?.functionService.listBillingProfileOrgs(
              token,
              profile_id,
            );
          if (listBillingProfileOrgsResult) {
            setCheckedState(
              new Array(
                listBillingProfileOrgsResult.billing_profile_orgs.length,
              ).fill(true),
            );

            setProfileOrgsViewData(
              listBillingProfileOrgsResult.billing_profile_orgs,
            );
            setLoading(false);
          }
        }
      }
    };
    fetchBillingRuns();
    ViewProfileFunction();
    setSelectedType("single_csv_file");
  }, [profile_id, renderValue]);

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <span style={{ fontSize: "25px" }}>Export Billing Run</span>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <br></br>
            <div
              style={{
                width: "90%",
                marginLeft: "5%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "98%",
                  marginLeft: "1%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                    marginTop: 5,
                    borderRadius: 10,
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <Paper style={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer style={{ maxHeight: 300 }}>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Billing Group Name</TableCell>
                            <TableCell>Billing Group Description</TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  className={classes.bold_text_lower_small}
                                  onClick={handleSelectAll}
                                >
                                  Select All
                                </Typography>
                                <Typography
                                  className={classes.bold_text_lower_small}
                                >
                                  {" "}
                                  /{" "}
                                </Typography>
                                <Typography
                                  className={classes.bold_text_lower_small}
                                  onClick={handleDeSelectAll}
                                >
                                  DeSelect All
                                </Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {viewProfileOrgsData &&
                            viewProfileOrgsData.map((item, index) => {
                              return (
                                <StyledTableRow>
                                  <TableCell>
                                    <Typography className={classes.bold_text}>
                                      {item.billing_org_name}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography className={classes.bold_text}>
                                      {item.description}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <FormControl>
                                      <Checkbox
                                        className={
                                          classes.form_component_checkbox
                                        }
                                        onChange={() =>
                                          handleCheckboxChange(index)
                                        }
                                        color="primary"
                                        icon={
                                          <CheckBoxOutlineBlankIcon fontSize="small" />
                                        }
                                        checkedIcon={
                                          <CheckBoxIcon fontSize="small" />
                                        }
                                        checked={checkedState![index]}
                                      />
                                    </FormControl>
                                  </TableCell>
                                </StyledTableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: "5%",
                  marginRight: "10%",
                  width: "50%",
                  textAlign: "left",
                  marginTop: 10,
                }}
              >
                <Typography className={classes.bold_text}>
                  Total groups selected:
                </Typography>
                <Typography className={classes.bold_text}>
                  {counts && counts.hasOwnProperty("true") ? counts["true"] : 0}
                </Typography>
              </div>
              <FormControl
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 15,
                  marginBottom: 10,
                }}
              >
                <FormLabel id="demo-radio-buttons-group-label">
                  <span
                    className={classes.bold_text_billing}
                    style={{ fontSize: 13 }}
                  >
                    select report type
                  </span>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="single_csv_file"
                  name="radio-buttons-group"
                  row
                  value={selectedType}
                  onChange={handleSelectionTypeChange}
                >
                  <FormControlLabel
                    value="single_csv_file"
                    control={<Radio />}
                    label={
                      <Typography className={classes.bold_text}>
                        consolidated csv
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="per_billing_org"
                    control={<Radio />}
                    label={
                      <Typography className={classes.bold_text}>
                        individual csv
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div
              style={{ marginTop: "5%", marginBottom: "5%" }}
              className={classes.twoButtonsInARow}
            >
              <Button
                variant="contained"
                className={classes.cancel_button}
                onClick={() => {
                  setSelectedType("single_csv_file");
                  onClose();
                }}
              >
                <span className={classes.bold_text}>Dismiss</span>
              </Button>
              <Button
                variant="contained"
                className={classes.cancel_button}
                onClick={() => {
                  handleGetSelectedProfileOrgs();
                }}
              >
                <span className={classes.bold_text}>Submit</span>
              </Button>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default ExportBillingRun;
