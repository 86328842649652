import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import { Org } from "../../../services/FunctionService";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
  IconButton,
  Paper,
  Card,
} from "@material-ui/core";
import { TextField } from "@material-ui/core";

interface EnableOrgModalProps {
  // elements:React.ReactNode[]|undefined;
  open: boolean;
  onClose: () => void;
  appStore?: AppStore;
  enableOrgFunc: (org: Org) => void;
  org: Org | undefined;
}
const EnableOrgModal: React.FC<EnableOrgModalProps> = ({
  open,
  appStore,
  enableOrgFunc,
  org,
  onClose,
}: EnableOrgModalProps) => {
  const classes = useStyles();
  const [renderValue, setRenderValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputDescription, setInputDescription] = useState<string | undefined>(
    "",
  );
  const [textError, setTextError] = useState(false);
  const [message, setMessage] = useState("");
  const handleClose = () => {
    onClose();
  };
  const handleInputDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    setTextError(false);
    setMessage("");
    setInputDescription(event.target.value);
  };
  const handleOk = () => {
    onClose();
  };
  useEffect(() => {
    if (org) {
      setInputDescription(org.org_name);
    }
  }, [org, renderValue]);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.modal_dialog_wide}
      fullWidth={true}
    >
      <DialogTitle className={classes.bold_text}>
        <Typography align="center" className={classes.bold_text}>
          Are you sure you want to{" "}
          {org?.enabled === true ? "disable" : "enable"}{" "}
          {org ? org.org_name : ""} group?
        </Typography>
      </DialogTitle>

      <div className={classes.twoButtonsInARow}>
        <Button variant="contained" className="save" onClick={handleClose}>
          Dismiss
        </Button>
        <Button
          variant="contained"
          className="save"
          onClick={() => {
            enableOrgFunc(org!);
            setRenderValue((value) => !value);
            onClose();
          }}
        >
          {org?.enabled === true ? "Disable" : "Enable"}
        </Button>
      </div>
    </Dialog>
  );
};

export default EnableOrgModal;
