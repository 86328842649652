import React, { useState, useEffect } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import {
  Role,
  User,
  AlertChannel,
  UserEndPointRoles,
} from "../../../services/FunctionService";
import {
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import { AppStore } from "../../../stores/AppStore";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Guid } from "guid-typescript";
import { useStyles } from "../../styling/global/global";
import Select from "@material-ui/core/Select";
class impUserEndPointRoles implements UserEndPointRoles {
  key!: Guid;
  user_id!: number;
  web_roles!: { [key: string]: any };
  // end_point_roles!: { [key: string]: any };
}

interface UserRuleEndPointProps {
  endpointtype: string;
  appStore?: AppStore;
  alertchannel: AlertChannel;
  handleUserRuleEndPointChange: (x: UserEndPointRoles) => void;
  addUser: () => void;
  value: UserEndPointRoles;
  users: User[];
  addbutton: boolean;
}

const UserRuleEndPoint: React.FC<UserRuleEndPointProps> = ({
  endpointtype,
  appStore,
  alertchannel,
  addUser,
  value,
  handleUserRuleEndPointChange,
  users,
  addbutton,
}: UserRuleEndPointProps) => {
  const classes = useStyles();
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [selectedroles, setSelectedRoles] = React.useState<{
    [key: string]: any;
  }>(value.web_roles);
  const [user_id, setUserId] = React.useState<number | undefined>(
    value.user_id,
  );

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        const roles = await appStore?.functionService.getRolesForEndPointType(
          token,
          endpointtype,
        );
        setRoles(roles!);
      }
    };
    fetchData();
  }, [endpointtype]);

  const handleRoleSelect = (event: React.ChangeEvent<any>) => {
    selectedroles[event.target.value] = !selectedroles[event.target.value];
    setSelectedRoles(selectedroles);
    if (user_id) {
      var userendpointrules = new impUserEndPointRoles();
      userendpointrules.key = value.key;
      userendpointrules.user_id = user_id;
      userendpointrules.web_roles = selectedroles;
      handleUserRuleEndPointChange(userendpointrules);
    }
  };
  const handleUserChange = (event: React.ChangeEvent<any>) => {
    setUserId(event.target.value);
  };
  var users_combo: React.ReactNode[] = [];
  var role_display: React.ReactNode[] = [];
  var user_display: React.ReactNode[] = [];

  if (users) {
    users.forEach((user) => {
      users_combo.push(
        <option value={user.user_id}>
          {user.first_name + " " + user.last_name + " (" + user.username + ")"}
        </option>,
      );
    });
  }
  if (users.find((x) => x.user_id == user_id) == undefined) {
    if (user_id != undefined) {
      users_combo.push(
        <option value={user_id}>{"User (id=" + user_id + ")"}</option>,
      );
    }
  }
  user_display.push(
    <FormControl
      fullWidth
      variant="outlined"
      className={classes.form_component}
    >
      <InputLabel
        className={classes.bold_text}
        style={{
          marginLeft: 15,
          marginTop: 5,
        }}
        id="test-select-label"
      >
        User for this delivery method
      </InputLabel>
      <Select
        name="hub-select"
        value={user_id}
        fullWidth
        onChange={(e) => handleUserChange(e)}
        placeholder="No user selected"
      >
        {users_combo}
      </Select>
    </FormControl>,
  );
  if (roles) {
    var rolelist: React.ReactNode[] = [];
    var initroles: { [key: string]: any } = {};
    roles.forEach((role) => {
      const thisid = role.role;
      var checked = false;
      if (selectedroles) {
        checked = selectedroles![thisid];
      }
      const name = appStore?.functionService.translate(
        alertchannel.locale,
        "user_roles_names",
        role.role,
      );
      const description = appStore?.functionService.translate(
        alertchannel.locale,
        "user_roles_descriptions",
        role.role,
      );

      rolelist.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            className={classes.bold_text}
            control={
              <Checkbox
                className={classes.form_component_checkbox}
                checked={checked}
                onChange={handleRoleSelect}
                value={thisid}
              />
            }
            label={<span className={classes.bold_text}>{name}</span>}
          />
        </Tooltip>,
      );
      initroles[role.role] = false;
    });
    if (!selectedroles) {
      setSelectedRoles(initroles);
    }
    if (rolelist.length > 0) {
      role_display.push(
        <>
          <FormLabel className={classes.bold_text} component="legend">
            Select Roles
          </FormLabel>
          <FormGroup>{rolelist}</FormGroup>{" "}
        </>,
      );
    }
  }
  var addbuttonview: React.ReactNode[] = [];
  if (addbutton) {
    addbuttonview.push(
      <>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <div className="text">Add Another User</div>
          </Grid>
          <Grid item xs={5}>
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => {
                addUser();
              }}
            >
              <AddCircle color={"primary"} />
            </IconButton>
          </Grid>
        </Grid>
      </>,
    );
  }
  return (
    <>
      {user_display}
      {role_display}
      {addbuttonview}
    </>
  );
};

export default UserRuleEndPoint;
