import React, { useState, useEffect } from "react";
import TimeEntryUI from "./TimeEntryUI";
import { useStyles } from "../../styling/global/global";
import { TimeEntry, TimeEntrySet } from "../../../core/models/TimeEntry";
import Grid from "@material-ui/core/Grid";
interface TimeEntryPanelProps {
  disabled: boolean;
  entrySet: TimeEntrySet | undefined;
  addicon: boolean;
  horisontal: boolean;
  noEndTime: boolean;
  changeTimeEntryParent: (entrySet: TimeEntrySet) => void;
}

const TimeEntryPanel: React.FC<TimeEntryPanelProps> = ({
  disabled,
  entrySet,
  addicon,
  changeTimeEntryParent,
  horisontal,
  noEndTime,
}: TimeEntryPanelProps) => {
  const [timeentrySet, setTimeentrySet] = React.useState<
    TimeEntrySet | undefined
  >(undefined);
  const [refresh, setRefresh] = React.useState(0);
  const classes = useStyles();
  useEffect(() => {
    const fetchData = async () => {
      setTimeentrySet(entrySet);
      setRefresh(new Date().getTime());
    };
    fetchData();
  }, [entrySet]);
  const handleScheduleDeleteClicked = (timeEntry: TimeEntry) => {
    if (timeentrySet) {
      timeentrySet.deleteTimeEntry(timeEntry);
      setTimeentrySet(timeentrySet);
      changeTimeEntryParent(timeentrySet);
    }

    setRefresh(new Date().getTime());
  };
  const handleAdd = () => {
    if (timeentrySet) {
      timeentrySet.addTimeEntry();
      setTimeentrySet(timeentrySet);
      changeTimeEntryParent(timeentrySet);
    }

    setRefresh(new Date().getTime());
  };
  const changeTimeEntry = (entry: TimeEntry) => {
    const old_entry = timeentrySet?.entries.find((x) => x.key == entry.key);
    if (old_entry && timeentrySet) {
      timeentrySet!.entries[timeentrySet!.entries.indexOf(old_entry!)] = entry;
      setTimeentrySet(timeentrySet);
      changeTimeEntryParent(timeentrySet);
    }
  };
  var timeEntryContent: React.ReactNode[] = [];
  if (timeentrySet) {
    timeentrySet.entries.map((timeEntry, index) => {
      const addSchedule =
        index == timeentrySet.entries.length - 1 ? true : false;
      timeEntryContent.push(
        <Grid item xs={horisontal ? 4 : 12}>
          <TimeEntryUI
            noEndtime={noEndTime}
            addicon={addicon}
            timeEntry={timeEntry}
            disabled={disabled}
            addSchedule={addSchedule}
            timeEntries={timeentrySet}
            changeTimeEntry={(timeEntry: TimeEntry) =>
              changeTimeEntry(timeEntry)
            }
            handleScheduleDeleteClicked={(timeEntry: TimeEntry) =>
              handleScheduleDeleteClicked(timeEntry)
            }
            handleAdd={() => handleAdd()}
          ></TimeEntryUI>
        </Grid>,
      );
    });
    return <Grid container>{timeEntryContent}</Grid>;
  } else {
    return <></>;
  }
};

export default TimeEntryPanel;
