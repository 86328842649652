import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";

interface EditBillingProfilesProps {
  open: boolean;
  appStore?: AppStore;
  profile: listBillingItems | undefined;
  onClose: () => void;
  EditProfileFunction: (
    profile_id: number,
    name: string,
    description: string,
  ) => void;
}
interface listBillingItems {
  id: number;
  enabled: boolean;
  name: string;
  description: string;
  meta: { [key: string]: any };
  created: Date | string;
}

const EditBillingProfiles: React.FC<EditBillingProfilesProps> = ({
  open,
  onClose,
  profile,
  EditProfileFunction,
}: EditBillingProfilesProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
        paddingLeft: "2%",
        paddingRight: "2%",
      },
    }),
  )(TableRow);

  const classes = useStyles();
  const [inputName, setInputName] = useState<string | undefined>("");
  const [inputDescription, setInputDescription] = useState<string | undefined>(
    "",
  );
  const handleInputNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setInputName(evt.target.value);
  };
  const handleInputDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    setInputDescription(event.target.value);
  };

  useEffect(() => {
    setInputDescription(profile?.description);
    setInputName(profile?.name);
  }, []);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <Typography
            className={classes.bold_text}
            style={{ color: "#3f51b5" }}
          >
            edit profile details
          </Typography>
        </DialogTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "5%",
            marginBottom: "5%",
            width: "80%",
            marginLeft: "10%",
          }}
        >
          <TextField
            style={{ marginBottom: "5%" }}
            variant="outlined"
            placeholder={profile?.name}
            label="edit billing profile name"
            value={inputName}
            onChange={handleInputNameChange}
            defaultValue={profile?.name}
            InputProps={{
              className: classes.bold_text_billing,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text_billing,
              },
            }}
          ></TextField>
          <TextField
            variant="outlined"
            placeholder={profile?.description}
            label="edit billing profile description"
            value={inputDescription}
            onChange={handleInputDescriptionChange}
            defaultValue={profile?.description}
            InputProps={{
              className: classes.bold_text_billing,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text_billing,
              },
            }}
          ></TextField>
        </div>
        <div
          style={{ marginTop: "5%", marginBottom: "2%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              // setInputDescription('');
              // setInputName('');
              onClose();
            }}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              EditProfileFunction(profile!.id, inputName!, inputDescription!);
              // setInputDescription('');
              // setInputName('');
              onClose();
            }}
          >
            <span className={classes.bold_text}>Update</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default EditBillingProfiles;
