import React, { useState, useEffect } from "react";
import {
  Modal as AntModal,
  Spin as LoadingSpinner,
  Card as AntCard,
} from "antd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Brightness1 from "@material-ui/icons/Brightness1";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckIcon from "@material-ui/icons/Check";
import AddGenericNotes from "../../modals/AddGenericNotes/AddGenericNotes";
import GenerateSentinelToken from "../../modals/SentinelToken/GenerateSentinelToken";
import ShowSentinelToken from "../../modals/SentinelToken/ShowSentinelToken";
import AddGroupToSentinel from "../../modals/SentinelToken/AddGroupToSentinel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Modal from "@mui/material/Modal";
import { Box, Dialog } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { Typography, Tooltip, IconButton, Card } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import MessageModal from "../../modals/MessageModal/MessageModal";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import ExpansionPanelMenu from "../../components/ExpansionPanelMenu/ExpansionPanelMenu";
import HubExpansionPanelMenu from "../../components/ExpansionPanelMenu/HubExpansionPanelMenu";
import GroupExpansionPanelMenu from "../../components/ExpansionPanelMenu/GroupExpansionPanelMenu";
import StatusHubModal from "../../modals/AddHubModal/StatusHubModal";
import EditHubModal from "../../modals/AddHubModal/EditHubModal";
import MoveModal from "../../modals/MoveModal/MoveModal";
import SMTPChangePassword from "../../modals/SMTPChangePassword/SMTPChangePassword";
import ChangePasswordCheck from "../../modals/ChangePasswordCheck/ChangePasswordCheck";
import RenameSite from "../../modals/RenameSite/RenameSite";
import TrainingSettings from "../../modals/TrainingSettings/TrainingSettings";
import DeleteOrgModal from "../../modals/DeleteOrgModal/DeleteOrgModal";
import { AddCircle, Height } from "@material-ui/icons";
import EditNoteIcon from "@mui/icons-material/EditNote";
import TimeEntryPanel from "../TimeEntryUI/TimeEntryPanel";
import { TextField } from "@material-ui/core";
import { TimeEntrySet } from "../../../core/models/TimeEntry";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
// import LoadingSpinner from "../../../spinner/LoadingSpinner";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";

import {
  MoveSelect,
  Site,
  Org,
  Hub,
  ReportGroup,
  SiteHealth,
  NetworkConfig,
  Sensor,
} from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import EditOrgModal from "../../modals/EditOrgModal/EditOrgModal";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Immix from "../../modals/Immix/Immix";
import EnableOrgModal from "../../modals/EnableModal/EnableModal";
import Frontel from "../../modals/Frontel/Frontel";
import { scheduler } from "timers/promises";
import { spawn } from "child_process";
import { MainContext } from "../../containers/HomePage/HomePage";
import RulesList from "../CameraMainDrawer/RulesList";
import RulesWizard from "../CameraMainDrawer/RulesWizard";
import { SelectRulesModal } from "../CameraMainDrawer/SelectRulesModal";

interface NewPasswordProps {
  success: boolean;
  edge_enhanced_smtp_server: string;
  edge_enhanced_smtp_server_port: string;
  smtp_password: string;
}

interface ZoneConfig {
  zone?: string;
  count?: number;
  class_label?: any;
  count_logic?: string;
  object_motion?: any;
  confidence_threshold?: any;
}

interface Rule {
  rule_name: any;
  rule_type: any;
  rule_subtype: any;
  alert_type: any;
  zone_configs?: ZoneConfig[];
  valid_days_and_times?: string | null;
  cadence_secs?: number | null;
}

interface MainExpansionPanelProps {
  orgs: { [key: number]: Org };
  sites: Site[];
  hubs: Hub[];
  sensor: Sensor;
  reportgroups: ReportGroup[];
  healthsites: SiteHealth[];
  appStore?: AppStore;
  editOrgFunc: (org_id: number, org_name: string) => void;
  deleteOrgFunc: (org: Org) => void;
  EnableOrgFunc: (org: Org) => void;
  setEditReportGroupModalOpen: (report: ReportGroup) => void;
  openConfirmDeleteReportGroup: (
    report_id: number,
    report_name: string,
  ) => void;
  currentOrg: Org;
  notesResultsArray: string[];
  setSelectedItem: (item: any, editing: boolean) => void;
  loading: boolean;
  openAddSharedHubModal: () => void;
  renameSiteName: (site: Site, name: string) => void;
  trainingSettings: (
    site: Site,
    training_fraction: number,
    training_triggers_only: boolean,
  ) => void;
  openAddGroupModal: () => void;
  openAddSiteModal: () => void;
  openAddReportGroupModal: () => void;
  openSiteBillingRatesModal: (site: Site) => void;
  openSiteNvrModal: (sensor: Sensor, site: Site) => void;
  openActivateDynDnsModal: (sensor: Sensor, site: Site) => void;
  openSetDynDnsModal: (sensor: Sensor, site: Site) => void;
  openViewDynDnsModal: (sensor: Sensor, site: Site) => void;
  openSiteFrontel: (site: any) => void;
}
class impMoveSelect implements MoveSelect {
  id!: number;
  name!: string;
}

const style = {
  textAlign: "center",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 200,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const addRuleModal = {
  overflowY: "scroll",
  textAlign: "center",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 880,
  height: 550,
  maxHeight: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const addRuleDialogModal = {
  textAlign: "center",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  minHeight: 200,
  height: 200,
  maxHeight: 500,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const updateMsg = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  height: 200,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const warningMsg = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  height: 150,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const MainExpansionPanel: React.FC<MainExpansionPanelProps> = ({
  openSiteBillingRatesModal,
  openSiteNvrModal,
  openActivateDynDnsModal,
  openSetDynDnsModal,
  openViewDynDnsModal,
  openAddReportGroupModal,
  openAddSiteModal,
  openAddGroupModal,
  openAddSharedHubModal,
  editOrgFunc,
  deleteOrgFunc,
  loading,
  sensor,
  setSelectedItem,
  EnableOrgFunc,
  notesResultsArray,
  currentOrg,
  appStore,
  orgs,
  sites,
  hubs,
  renameSiteName,
  trainingSettings,
  reportgroups,
  healthsites,
  setEditReportGroupModalOpen,
  openConfirmDeleteReportGroup,
  openSiteFrontel,
}: MainExpansionPanelProps) => {
  // FETCH RELAVENT DEFS INFORMATION
  const [relaventRuleData, setRelaventRuleData] = useState<any>({});
  const [zoneConfigDefs, setZoneConfigDefs] = useState<any>([]);
  const [validDaysTimesDef, setValidDaysTimesDef] = useState<any>({});
  const [fetchIntervalDef, setFetchIntervalDef] = useState<any>({});
  const [ruleDefs, setRuleDefs] = useState<any>([]);
  const [alert, setAlert] = React.useState<string | null>(null);
  const [rule, setRule] = React.useState<string>("");
  const [ruleName, setRuleName] = useState<string>("");
  const [ruleTypes, setRuleTypes] = useState<string[]>([]);
  const [occuranceCount, setOccuranceCount] = useState<any>({});

  const [optionValue, setOptionValue] = React.useState(undefined);
  const [showWizard, setShowWizard] = useState(false);
  const [openWizardModal, setOpenWizardModal] = React.useState(false);
  const [sensorDefinedRules, setSensorDefinedRules] = useState<any | undefined>(
    [],
  );

  const handleChangeAuto = (value: any) => {
    if (!value) {
      return;
    }

    console.debug("handleChangeAuto Value: ", value);

    setOptionValue(value);
    ctx.setRuleType(value.fields.rule_type as string);
    ctx.setRuleName(value.fields?.name?.default);
  };

  const saveZonesHandler = async () => {};

  const orgDefaultRuleDefs = async () => {
    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        return;
      } else if (token) {
        const orgDefs = await appStore?.functionService?.orgDefaultRuleDefs(
          token,
          currentOrg?.org_id,
        );
        if (orgDefs && orgDefs?.success) {
          setRuleDefs(orgDefs?.alert_config_front_end_defs);
          setSensorDefinedRules(orgDefs?.alert_config_front_end_defs);
        }
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLocalLoading(false);
    }
  };

  useEffect(() => {
    orgDefaultRuleDefs();
  }, [currentOrg?.org_id]);

  useEffect(() => {
    if (alert) {
      setRuleTypes(
        ruleDefs?.filter(
          (rule: any) => rule?.fields?.alert_type === alert?.toLowerCase(),
        ),
      );
    }
  }, [alert]);

  const relaventRuleTypeOptions = (data: any) => {
    let abledOptionsArray: any = [];
    let disabledOptionsArray: any = [];
    let max_count_data: any = {};
    data?.forEach((item: any) => {
      if (item?.meta?.create) {
        if (
          item?.meta?.max_count &&
          item?.meta?.max_count <=
            occuranceCount[item?.fields?.rule_subtype?.subtype]
        ) {
          disabledOptionsArray?.push(`${item?.fields?.label}`);
        } else if (
          item?.meta?.max_count !==
          occuranceCount[item?.fields?.rule_subtype?.subtype]
        ) {
          abledOptionsArray?.push(item?.fields?.label);
          max_count_data[item?.fields?.rule_subtype] = item?.meta?.max_count;
        }
      } else if (!item?.meta?.create) {
        disabledOptionsArray?.push(
          `${item?.fields?.label} (${item?.meta?.create_reason.toLowerCase()})`,
        );
        max_count_data[item?.fields?.rule_subtype] = item?.meta?.max_count;
      }
    });
    return {
      abled: abledOptionsArray,
      disabled: disabledOptionsArray,
      max_count_data,
    };
  };

  let ruleTypeOptions = relaventRuleTypeOptions(ruleTypes);

  useEffect(() => {
    const foundRuleData = ruleDefs?.find(
      (option: any) => rule === option?.fields?.label,
    );
    setRelaventRuleData(foundRuleData);
    if (foundRuleData) {
      setZoneConfigDefs(foundRuleData?.fields?.zone_configs);
      setValidDaysTimesDef(foundRuleData?.fields?.valid_days_and_times);
      if (foundRuleData?.fields?.cadence_secs) {
        setFetchIntervalDef(foundRuleData?.fields?.cadence_secs);
      }
    }
  }, [rule, ruleTypeOptions]);

  // DEFAULT RULE MODAL
  const classes = useStyles();
  const [relaventRuleDataToDelete, setRelaventRuleDataToDelete] = useState<any>(
    {},
  );
  const [selectedInterVal, setSelectedInterVal] = useState<string | null>(
    relaventRuleData?.fields?.cadence_secs?.default || null,
  );
  const [selectedInterValEdit, setSelectedInterValEdit] = useState<
    string | null
  >(null);

  const handleIntervalChange = (event: any) => {
    setSelectedInterVal(event?.target?.value);
  };

  const handleIntervalEditChange = (event: any) => {
    setSelectedInterValEdit(event?.target?.value);
  };

  const alertTypes = ["Analytics", "Scheduled"];

  const ruleNameHandler = (event: any) => {
    setRuleName(event?.target?.value);
  };

  const handleAlertChange = (event: any, value: any) => {
    setAlert(value);
  };

  const handleRuleChange = (event: any, value: any) => {
    setRule(value);
    setRuleName(value);

    console.debug("handleRuleChange: ", value);

    // setOptionValue(value);
    // ctx.setRuleType(value.fields.rule_type as string);
  };

  // LOGIC FOR ADDING A RULE AT A GROUP LEVEL
  const [openRuleConfig, setOpenRuleConfig] = React.useState(false);
  const [ruleConfig, setRuleConfig] = React.useState(false);

  const handleRuleConfigModalClose = () => {
    setRule("");
    setAlert(null);
    setRuleName("");
    setOpenRuleConfig(false);
    setRuleConfig(false);
    setZoneData([]);
    setSteps(1);
    setSelectedClass("");
    setSelectedInterVal(null);
    setSelectedMotion("");
    setSelectedThreshold(35);
    setSelectedCount(0);
    setSelectedCountLogic("> (greater than)");
    handleCloseAddRule();
    setTimeEntrySetRules(undefined);
    setRadioValue("always");
    setRuleTypeToDelete("");
    setRuleSubTypeToDelete("");
    setRuleIDToDelete("");
    setAlertTypeToDelete("");

    ctx.clearRulesState();
    fetchDefaultRules();
    initialSpinnerHandler();
  };

  const openRuleConfigDialog = () => {
    setOpenRuleConfig(true);
    setShowWizard(true);
  };

  const closeRuleConfigDialog = () => {
    setAlert(null);
    setOpenRuleConfig(false);
    setShowWizard(false);
  };

  // LOGIC FOR THE SCENE CHANGE RULE
  const [openSceneChange, setOpenSceneChange] = React.useState(false);
  const [sceneChangeSteps, setSceneChangeSteps] = React.useState(1);

  const handleCloseSceneChange = () => {
    setOpenSceneChange(false);
    setTimeEntrySetRules(undefined);
    setSelectedClass("");
    setRuleName("");
    setSelectedInterVal(null);
    setSelectedMotion("");
    setSelectedThreshold(35);
    setSelectedCount(0);
    setSelectedCountLogic("> (greater than)");
    setZoneData([]);
    setSteps(1);
    setSceneChangeSteps(1);
    scheduleRules = [];
    setRadioValue("always");
  };

  const nextSceneStepsHandler = () => {
    let newStep = sceneChangeSteps + 1;
    setSceneChangeSteps(newStep);
  };

  const prevSceneStepsHandler = () => {
    let newStep = sceneChangeSteps - 1;
    setSceneChangeSteps(newStep);
  };

  const [radioValue, setRadioValue] = React.useState<any>("always");
  const [openRuleModal, setOpenRuleModal] = React.useState<boolean>(false);
  const handleOpenRuleModal = () => setOpenRuleModal(true);
  const handleCloseRuleModal = () => setOpenRuleModal(false);
  const [fetchRules, setFetchRules] = useState<number>(0);
  const [ruleTypeToDelete, setRuleTypeToDelete] = useState<string>("");
  const [ruleSubTypeToDelete, setRuleSubTypeToDelete] = useState<string>("");
  const [ruleNameToDelete, setRuleNameToDelete] = useState<string>("");
  const [ruleIDToDelete, setRuleIDToDelete] = useState<string>("");
  const [alertTypeToDelete, setAlertTypeToDelete] = useState<string>("");
  const [collectRules, setCollectRules] = useState<string[]>([]);

  useEffect(() => {
    const foundRuleData = ruleDefs?.find(
      (option: any) =>
        ruleSubTypeToDelete === option?.fields?.rule_subtype?.subtype,
    );
    setRelaventRuleDataToDelete(foundRuleData);
  }, [ruleSubTypeToDelete]);

  const [theORG, setTHEORG] = useState<Org | undefined>(undefined);

  useEffect(() => {
    if (openRuleModal) {
      ctx.setShowGraph(false);
    } else {
      ctx.setShowGraph(true);
    }
    return () => {
      ctx.setShowGraph(false);
    };
  }, [openRuleModal]);

  const editRuleNameHandler = (event: any) => {
    setRuleNameToDelete(event?.target?.value);
  };

  const ctx = React.useContext(MainContext);

  const [default_rules, setDefaultRules] = useState<any>(null);
  const [plain_default_rules, setPlainDefaultRules] = useState<any>(null);

  const fetchDefaultRules = async () => {
    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        return;
      } else if (token) {
        const default_rules =
          await appStore?.functionService?.fetchGroupDefaultRules(
            token,
            theORG?.org_id,
          );
        if (default_rules && default_rules?.success) {
          const rulesWithIds =
            default_rules?.default_alert_configs?.alert_configs?.map(
              (rule: any) => ({
                ...rule,
                id: uuidv4(),
                // Add id if it doesn't already exist
              }),
            );
          setDefaultRules({
            rules_origin:
              default_rules?.default_alert_configs?.alert_config_origin,
            rules: rulesWithIds,
          });
          setPlainDefaultRules({
            rules_origin:
              default_rules?.default_alert_configs?.alert_config_origin,
            rules: default_rules?.default_alert_configs?.alert_configs || [],
          });
          let occuranceData: any = {};

          // Add logic to set
          if (default_rules?.default_alert_configs?.alert_configs?.length > 0) {
            var isSnapshotRule = false;
            const filteredResults = rulesWithIds.map((item: any) => {
              if (item?.alert_config_type === "snapshot") {
                isSnapshotRule = true;
              }
              const ruleKeys = Object.keys(item?.rule_configs) || [];

              let zoneConfigs: any = [];
              ruleKeys.map((key: string) => {
                if (item?.rule_configs[key]?.zone_configs) {
                  item?.rule_configs[key]?.zone_configs.map((zone: any) =>
                    zoneConfigs.push({
                      ...zone,
                      rule_type: key,
                    }),
                  );
                } else {
                  zoneConfigs.push({
                    zone: "full_image",
                    rule_type: key,
                    type: "zone",
                  });
                }
              });

              return {
                cadence_secs: item?.cadence_secs,
                sensor_id: item?.sensor_id,
                rulesName: item?.name || "no data",
                rulesType: item?.alert_config_type || "no data",
                alertType: item?.alert_type || "no data",
                rulesSubType: item?.alert_config_type || "no data",
                zoneConfigs: zoneConfigs,
                environment: item?.environment || "no data",
                valid_days_and_times: item?.valid_days_and_times,
                exact_times: item?.exact_times,
                id: item?.alert_config_id || item.id,
                deliveryEndpoints: ["none"],
              };
            });
            ctx.setRuleList(filteredResults);
          }
          // End
          rulesWithIds?.forEach((rule: any) => {
            const subtype = rule?.alert_configs;
            if (subtype) {
              // Increment the count if subtype already exists, else set it to 1
              occuranceData[subtype] = (occuranceData[subtype] || 0) + 1;
            }
          });
          setOccuranceCount(occuranceData);
        }
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };
  // FETCH THE DEFAULT RULES
  useEffect(() => {
    fetchDefaultRules();
  }, [fetchRules]);

  useEffect(() => {
    if (default_rules?.rules) {
      setCollectRules(
        default_rules?.rules?.map((rule: any) =>
          rule?.rule_type?.toLocaleLowerCase(),
        ),
      );
    }
  }, [fetchRules, default_rules]);

  // DEFAULT RULES LOGIC
  const [expandDefaultRules, setExpandDefaultRules] = useState<boolean>(false);
  const [expandListOfRules, setExpandListOfRules] = useState<boolean>(false);
  const [listRules, setListRules] = useState<any>(null);
  const [loadingSpinner, setLoadingSpinner] = useState<boolean>(false);
  const [saveLoadingSpinner, setSaveLoadingSpinner] = useState<boolean>(false);
  const [parentReload, setParentReload] = useState<any>(new Date().getTime());
  const [orgName, setOrgName] = useState<any>(null);
  const [steps, setSteps] = useState<number>(1);

  // FETCH THE DEFAULT RULES

  const nextStepsHandler = () => {
    let newStep = steps + 1;
    setSteps(newStep);
  };

  const prevStepsHandler = () => {
    let newStep = steps - 1;
    setSteps(newStep);
  };

  const configZones = [];

  if (default_rules) {
    default_rules?.rules?.forEach((rule: any, index: any) => {
      // Assuming each rule contains an array named "data" that you want to spread into configZones
      if (rule?.data && Array.isArray(rule.data)) {
        configZones.push(...rule.data);
      }
    });
  }

  const handleExpandDefaultRules = () => {
    if (expandDefaultRules === false) {
      setExpandDefaultRules(true);
    } else if (expandDefaultRules === true) {
      setExpandDefaultRules(false);
    }
  };

  const handleExpandedListOfRules = () => {
    if (expandListOfRules === false) {
      setExpandListOfRules(true);
    } else if (expandListOfRules === true) {
      setExpandListOfRules(false);
    }
  };

  // TIME ENTRY DATA
  const [zoneData, setZoneData] = useState<any>([]);
  const [addZoneMsg, setAddZoneMsg] = useState<string>("");

  const [updateRulesMsg, setUpdateRulesMsg] = useState<string>("");

  const [openMsg, setOpenMsg] = React.useState(false);
  const handleOpenMsgModal = () => setOpenMsg(true);

  const handleCloseMsgModal = () => {
    setAddZoneMsg("");
    setOpenMsg(false);
  };

  const cloneDefaultRulesHandler = async () => {
    // Deep copy of default_rules to ensure no references to nested objects
    const updatedDefaultRules = JSON.parse(JSON.stringify(default_rules));

    // Remove the 'id' field from each rule
    if (updatedDefaultRules.rules) {
      updatedDefaultRules.rules = updatedDefaultRules.rules.map(
        ({ id, ...rest }: any) => rest,
      );
    }

    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        return;
      }

      const update = await appStore?.functionService?.createGroupDefaultRules(
        token,
        theORG?.org_id,
        updatedDefaultRules,
      );

      if (update) {
        fetchDefaultRules();
        // Optionally provide feedback to the user
        // setUpdateRulesMsg(update?.msg);
      }
    } catch (error) {
      console.debug("Error: ", error);
    } finally {
      // Trigger a re-fetch or state update
      setFetchRules(Math.floor(Math.random() * 1000) + 1);
    }
  };

  const addRuleHandler = () => {
    const showValidationError = () => {
      setAddZoneMsg(
        "Please note all fields are required in order to add a default rule.",
      );
      setOpenMsg(true);
    };

    // Determine required fields based on zone_configs
    const isClassRequired =
      relaventRuleDataToDelete?.fields?.zone_configs?.some(
        (config: any) => config.type === "class_label",
      );
    const isMotionRequired =
      relaventRuleDataToDelete?.fields?.zone_configs?.some(
        (config: any) => config.type === "object_motion",
      );
    const isThresholdRequired =
      relaventRuleDataToDelete?.fields?.zone_configs?.some(
        (config: any) => config.type === "confidence_threshold",
      );
    const isCountRequired =
      relaventRuleDataToDelete?.fields?.zone_configs?.some(
        (config: any) => config.type === "count",
      );
    const isCountLogicRequired =
      relaventRuleDataToDelete?.fields?.zone_configs?.some(
        (config: any) => config.type === "count_logic",
      );
    const isIntervalRequired =
      relaventRuleDataToDelete?.fields?.cadence_secs?.enabled;

    // Validate required fields
    if (
      (isClassRequired && !selectedClass) ||
      (isMotionRequired && !selectedMotion) ||
      (isThresholdRequired && selectedThreshold === null) ||
      (isCountRequired && selectedCount === null) ||
      (isCountLogicRequired && !selectedCountLogic) ||
      (isIntervalRequired && !selectedInterValEdit)
    ) {
      showValidationError();
      return;
    }

    let data = zoneData;
    const indexToUpdate = data?.findIndex(
      (item: any) => item?.class_label === selectedClass,
    );

    const newZone = {
      random_id: new Date().getTime(),
      zone: "full_image",
      class_label: selectedClass,
      object_motion: selectedMotion,
      count: selectedCount,
      count_logic: selectedCountLogic,
      confidence_threshold: selectedThreshold,
      cadence_secs: isIntervalRequired ? selectedInterValEdit : null,
    };

    if (indexToUpdate === -1) {
      setZoneData([...zoneData, newZone]);
      setSelectedClass("");
      setSelectedMotion("");
      setSelectedThreshold(35);
      // setSelectedInterVal(null);
    } else {
      setAddZoneMsg(
        `Class label ${
          selectedClass?.split(".")[1]
        } has already been selected! Please select another class.`,
      );
      setOpenMsg(true);
    }
  };

  const addRuleHandlerConfig = () => {
    const showValidationError = () => {
      setAddZoneMsg(
        "Please note all fields are required in order to add a default rule.",
      );
      setOpenMsg(true);
    };

    // Determine required fields based on zone_configs
    const isClassRequired = relaventRuleData?.fields?.zone_configs?.some(
      (config: any) => config.type === "class_label",
    );
    const isMotionRequired = relaventRuleData?.fields?.zone_configs?.some(
      (config: any) => config.type === "object_motion",
    );
    const isThresholdRequired = relaventRuleData?.fields?.zone_configs?.some(
      (config: any) => config.type === "confidence_threshold",
    );
    const isCountRequired = relaventRuleData?.fields?.zone_configs?.some(
      (config: any) => config.type === "count",
    );
    const isCountLogicRequired = relaventRuleData?.fields?.zone_configs?.some(
      (config: any) => config.type === "count_logic",
    );
    const isIntervalRequired = relaventRuleData?.fields?.cadence_secs?.enabled;

    // Validate required fields
    if (
      (isClassRequired && !selectedClass) ||
      (isMotionRequired && !selectedMotion) ||
      (isThresholdRequired && selectedThreshold === null) ||
      (isCountRequired && selectedCount === null) ||
      (isCountLogicRequired && !selectedCountLogic) ||
      (isIntervalRequired && !selectedInterVal)
    ) {
      showValidationError();
      return;
    }

    let data = zoneData;
    const indexToUpdate = data?.findIndex(
      (item: any) => item?.class_label === selectedClass,
    );

    const newZone = {
      random_id: new Date().getTime(),
      zone: "full_image",
      class_label: selectedClass,
      object_motion: selectedMotion,
      count: selectedCount,
      count_logic: selectedCountLogic,
      confidence_threshold: selectedThreshold,
      cadence_secs: isIntervalRequired ? selectedInterVal : null,
    };

    if (indexToUpdate === -1) {
      setZoneData([...zoneData, newZone]);
      setSelectedClass("");
      setSelectedMotion("");
      setSelectedThreshold(35);
      // setSelectedInterVal(null);
    } else {
      setAddZoneMsg(
        `Class label ${
          selectedClass?.split(".")[1]
        } has already been selected! Please select another class.`,
      );
      setOpenMsg(true);
    }
  };

  const removeRuleHandler = (id: any) => {
    const dataArray = zoneData;
    const filteredArr = dataArray?.filter(
      (data: any, index: any) => data?.random_id !== id,
    );
    setZoneData(filteredArr);
  };

  const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);

  const closeWarningModal = () => {
    setOpenWarningModal(false);
  };

  const deleteDefaultRuleHandler = async () => {
    setLoadingSpinner(true);
    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        setLoadingSpinner(false);
        return;
      }

      // Filter out the rule with the specified rule_type and alert_type
      const updatedRules = default_rules?.rules?.filter(
        (rule: any) =>
          !(
            rule.rule_type === ruleTypeToDelete &&
            rule.alert_type === alertTypeToDelete &&
            rule.id === ruleIDToDelete
          ),
      );

      // Remove the 'id' key from each rule
      const rulesWithoutId = updatedRules?.map(({ id, ...rule }: any) => rule);

      const updatedDefaultRule = {
        rules_origin: {
          ...default_rules?.rules_origin,
        },
        rules: rulesWithoutId, // Keep rules as an array, even if empty
      };

      const update = await appStore?.functionService?.updateGroupDefaultRules(
        token,
        theORG?.org_id,
        updatedDefaultRule,
      );

      if (update) {
        setUpdateResponse(update);
        fetchDefaultRules();
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoadingSpinner(false);
      setRuleTypeToDelete("");
      setRuleSubTypeToDelete("");

      setAlertTypeToDelete("");
      setRuleIDToDelete("");
    }
  };

  // DELETE DEFAULT RULE MODAL
  const [openDeleteRuleModal, setOpenDeleteRuleModal] = React.useState(false);
  const handleOpenDeleteRuleModal = () => {
    if (theORG?.org_id === 1 && default_rules?.rules?.length === 1) {
      setOpenWarningModal(true);
    } else {
      setOpenDeleteRuleModal(true);
    }
  };
  const handleCloseDeleteRuleModal = () => setOpenDeleteRuleModal(false);

  // ADD RULE MODAL
  const [openAddRule, setOpenAddRule] = React.useState(false);

  const handleOpenAddRule = (event: any) => {
    event.preventDefault();
    if (default_rules?.rules?.length !== 0) {
      setAddZoneMsg(
        "Please note that default rule already exists, please edit rule.",
      );
      setOpenMsg(true);
      return;
    } else {
      setOpenAddRule(true);
    }
  };

  const editRuleHandler = (ruleType: any) => {
    setOpenAddRule(true);
  };

  const handleCloseAddRule = () => {
    setOpenAddRule(false);
    setTimeEntrySetRules(undefined);
    setSelectedClass("");
    setRuleName("");
    setSelectedInterVal(null);
    setSelectedMotion("");
    setSelectedThreshold(35);
    setSelectedCount(0);
    setSelectedCountLogic("> (greater than)");
    setZoneData([]);
    setSteps(1);
    scheduleRules = [];
    setRadioValue("always");
  };

  // State for selected values of each section
  const [selectedClass, setSelectedClass] = useState<string | null>(null);
  const [selectedMotion, setSelectedMotion] = useState<string | null>(null);
  const [selectedThreshold, setSelectedThreshold] = useState<number>(35);
  const [selectedCount, setSelectedCount] = useState(0);

  // Assuming `relaventRuleData` is available at this point
  const defaultCountLogic =
    relaventRuleData?.fields?.zone_configs?.find(
      (config: any) => config.type === "count_logic",
    )?.default || ">";
  const [selectedCountLogic, setSelectedCountLogic] =
    useState("> (greater than)");

  const handleCountChange = (event: any) => {
    const newCount = event.target.value;
    setSelectedCount(newCount);
  };

  const handleCountLogicChange = (event: any) => {
    const newLogic = event.target.value;
    setSelectedCountLogic(newLogic);
  };

  const handleClassChange = (event: any) => {
    setSelectedClass(event?.target?.value);
    if (event?.target?.value === "human1.people") {
      setSelectedMotion("include_all");
    } else if (event?.target?.value === "human1.vehicles") {
      setSelectedMotion("moving_only");
    }
  };
  const handleObjectMotion = (event: any) => {
    setSelectedMotion(event?.target?.value);
  };
  const handleThreshold = (event: any) => {
    if (event?.target?.value < 10) {
      setSelectedThreshold(10);
    } else if (event?.target?.value > 100) setSelectedThreshold(100);
    else {
      setSelectedThreshold(event?.target?.value);
    }
  };

  // INITIAL LOADING SPINNER FOR THE VIEW DEFAULT RULES MODAL
  const [initialSpinner, setInitialSpinner] = React.useState<boolean>(false);

  const initialSpinnerHandler = () => {
    setInitialSpinner(true);
    setTimeout(() => {
      setInitialSpinner(false);
    }, 1000);
  };

  //UPDATE THE DEFAULT RULES
  const [updateResponse, setUpdateResponse] = useState<any>("");

  const [timeentrySetRules, setTimeEntrySetRules] = React.useState<
    TimeEntrySet | undefined
  >(undefined);

  const [noEndtimeRules, setNoEndtimeRules] = React.useState(false);

  const changeTimeEntryRules = (entrySet: TimeEntrySet | undefined) => {
    setTimeEntrySetRules(entrySet);
    //if deleted las one, make sure to add add button
    if (entrySet?.entries?.length === 0) {
      setRefresh(new Date().getTime());
    }
  };

  const addSchedule = () => {
    if (timeentrySetRules) {
      timeentrySetRules.addTimeEntry();
      setTimeEntrySetRules(timeentrySetRules);
    } else {
      const newtimeentrySet = new TimeEntrySet("");
      newtimeentrySet.addTimeEntry();
      setTimeEntrySetRules(newtimeentrySet);
    }
    setRefresh(new Date().getTime());
  };

  const saveUpdateGroupDefaultRulesHandler = async () => {
    const createNewZoneData = (): ZoneConfig[] => {
      if (!zoneData) return [];

      return zoneData.map((zone: ZoneConfig) => {
        const newZoneConfig: ZoneConfig = {
          zone: "full_image",
        };

        relaventRuleDataToDelete?.fields?.zone_configs.forEach(
          (config: any) => {
            switch (config.type) {
              case "class_label":
                newZoneConfig.class_label = zone.class_label;
                break;
              case "object_motion":
                newZoneConfig.object_motion = zone.object_motion;
                break;
              case "confidence_threshold":
                newZoneConfig.confidence_threshold = Number(
                  zone.confidence_threshold,
                );
                break;
              case "count":
                newZoneConfig.count = Number(zone.count) || 0;
                break;
              case "count_logic":
                const logicMap: { [key: string]: string } = {
                  "< (less than)": "<",
                  "> (greater than)": ">",
                  ">= (greater than or equal to)": ">=",
                  "<= (less than or equal to)": "<=",
                  "== (equal to)": "==",
                };
                const countLogic = zone?.count_logic;
                newZoneConfig.count_logic =
                  countLogic && logicMap[countLogic]
                    ? logicMap[countLogic]
                    : ">"; // Default to ">" if count_logic is not set
                break;
              default:
                break;
            }
          },
        );

        return newZoneConfig;
      });
    };

    const createValidDaysAndTimes = (): string => {
      if (!timeentrySetRules) return "[]";
      return `[${timeentrySetRules.entries
        .map((entry) => entry.timeString)
        .join(",")}]`;
    };

    const updateRule = (rule: any): Rule => {
      const updatedRule: Rule = {
        ...rule,
        rule_name: ruleNameToDelete,
        rule_type:
          relaventRuleDataToDelete?.fields?.rule_type || "default_rule_type",
        rule_subtype:
          relaventRuleDataToDelete?.fields?.rule_subtype?.subtype ||
          "default_subtype",
        alert_type:
          relaventRuleDataToDelete?.fields?.alert_type || "default_alert_type",
        valid_days_and_times: timeentrySetRules
          ? createValidDaysAndTimes()
          : null,
      };

      if (relaventRuleDataToDelete?.fields?.zone_configs?.length) {
        updatedRule.zone_configs = createNewZoneData();
      }

      // Implementing the cadence_secs logic based on your requirements
      if (relaventRuleDataToDelete?.fields?.cadence_secs) {
        if (relaventRuleDataToDelete.fields.cadence_secs.enabled) {
          if (selectedInterValEdit) {
            updatedRule.cadence_secs = parseInt(selectedInterValEdit);
          } else {
            throw new Error("cadence_secs is required but not provided.");
          }
        } else {
          updatedRule.cadence_secs = null; // Explicitly set to null if not enabled
        }
      } else {
        // Delete cadence_secs if it is not present in relaventRuleDataToDelete
        const { cadence_secs, ...rest } = updatedRule; // Remove cadence_secs if not required
        return rest;
      }

      return updatedRule;
    };

    const updatedRules = default_rules?.rules?.map((rule: any) => {
      if (rule.id === ruleIDToDelete) {
        return updateRule(rule);
      }
      return rule;
    });

    const rulesWithoutId = updatedRules.map(({ id, ...rest }: any) => ({
      ...rest,
    }));

    const updatedDefaultRules = {
      ...default_rules,
      rules: rulesWithoutId,
    };

    setLoadingSpinner(true);
    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        setLoadingSpinner(false);
        return;
      }

      const update = await appStore?.functionService?.updateGroupDefaultRules(
        token,
        theORG?.org_id,
        updatedDefaultRules,
      );

      if (update) {
        setUpdateRulesMsg(update.msg);
        fetchDefaultRules();
      }
    } catch (err) {
      console.error("Error updating group default rules:", err);
    } finally {
      setRadioValue("always");
      setRuleTypeToDelete("");
      setRuleSubTypeToDelete("");
      setAlertTypeToDelete("");
      setRuleIDToDelete("");
      setSelectedInterVal(null);
      setFetchRules(Math.floor(Math.random() * 1000) + 1);
      setLoadingSpinner(false);
      setRuleName("");
    }
  };

  const submitGroupDefaultRuleHandler = async () => {
    const createNewZoneData = (): ZoneConfig[] => {
      if (!zoneData) return [];
      return zoneData.map((zone: ZoneConfig) => {
        const newZoneConfig: ZoneConfig = {
          zone: "full_image",
        };
        relaventRuleData?.fields?.zone_configs.forEach((config: any) => {
          switch (config.type) {
            case "class_label":
              newZoneConfig.class_label = zone.class_label;
              break;
            case "object_motion":
              newZoneConfig.object_motion = zone.object_motion;
              break;
            case "confidence_threshold":
              newZoneConfig.confidence_threshold = Number(
                zone.confidence_threshold,
              );
              break;
            case "count":
              newZoneConfig.count = Number(zone.count) || 0;
              break;
            case "count_logic":
              // Map the descriptive value to a valid logic symbol
              const logicMap: { [key: string]: string } = {
                "< (less than)": "<",
                "> (greater than)": ">",
                ">= (greater than or equal to)": ">=",
                "<= (less than or equal to)": "<=",
                "== (equal to)": "==",
              };
              const countLogic = zone?.count_logic;
              newZoneConfig.count_logic =
                countLogic && logicMap[countLogic] ? logicMap[countLogic] : ">"; // Default to ">" if count_logic is not set
              break;
            default:
              break;
          }
        });

        return newZoneConfig;
      });
    };

    const createValidDaysAndTimes = (): string => {
      if (!timeentrySetRules) return "[]";
      return `[${timeentrySetRules.entries
        .map((entry) => entry.timeString)
        .join(",")}]`;
    };

    const createNewRule = (): Rule => {
      const newRule: Rule = {
        rule_name: ruleName || relaventRuleData?.fields?.label,
        rule_type: relaventRuleData?.fields?.rule_type || "default_rule_type",
        rule_subtype:
          relaventRuleData?.fields?.rule_subtype?.subtype || "default_subtype",
        alert_type:
          relaventRuleData?.fields?.alert_type || "default_alert_type",
        valid_days_and_times: timeentrySetRules
          ? createValidDaysAndTimes()
          : null,
      };

      // Add zone_configs based on the zoneData
      if (relaventRuleData?.fields?.zone_configs?.length) {
        newRule.zone_configs = createNewZoneData();
      }

      // Handle cadence_secs based on its presence and enabled status
      if (relaventRuleData?.fields?.cadence_secs) {
        if (relaventRuleData.fields.cadence_secs.enabled) {
          if (selectedInterVal) {
            newRule.cadence_secs = parseInt(selectedInterVal);
          } else {
            throw new Error("cadence_secs is required but not provided.");
          }
        } else {
          newRule.cadence_secs = null; // Explicitly set to null if not enabled
        }
      } else {
        // Delete cadence_secs if it is not present in relaventRuleDataToDelete
        const { cadence_secs, ...rest } = newRule; // Remove cadence_secs if not required
        return rest;
      }

      // If cadence_secs is not present, it will not be added to newRule

      return newRule;
    };

    const newRule = createNewRule();
    if (!newRule) return;

    const updatedDefaultRules = {
      ...default_rules,
      rules: [...default_rules?.rules, newRule],
    };

    const rulesWithoutId = updatedDefaultRules.rules.map(
      ({ id, ...rule }: { id: number; rule: Rule }) => rule,
    );

    const updatedDefaultRulesWithoutId = {
      ...default_rules,
      rules: rulesWithoutId,
    };

    setLoadingSpinner(true);
    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        setLoadingSpinner(false);
        return;
      }

      const update = await appStore?.functionService?.updateGroupDefaultRules(
        token,
        theORG?.org_id,
        updatedDefaultRulesWithoutId,
      );

      if (update) {
        setUpdateRulesMsg(update.msg);
        fetchDefaultRules();
      }
    } catch (err) {
      console.error("Error updating rules:", err);
    } finally {
      setRadioValue("always");
      setRuleTypeToDelete("");
      setRuleSubTypeToDelete("");
      setAlertTypeToDelete("");
      setRuleIDToDelete("");
      setSelectedInterVal(null);
      setFetchRules(Math.floor(Math.random() * 1000) + 1);
      setLoadingSpinner(false);
      setRuleName("");
    }
  };

  // HANDLE THE VALID DAYS AND TIMES FORM
  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(event?.target?.value);
  };

  var scene_valid_days_and_times = "[";
  if (timeentrySetRules) {
    timeentrySetRules.entries.forEach((timeEntry, index) => {
      scene_valid_days_and_times +=
        (index > 0 ? "," : "") + timeEntry.timeString;
    });
  }
  scene_valid_days_and_times += "]";

  var scheduleRules: React.ReactNode[] = [];
  if (timeentrySetRules && timeentrySetRules.entries?.length > 0) {
    scheduleRules
      .push
      // <TextField
      //   // value={currentAlertChannel.timezone_str}
      //   fullWidth={true}
      //   disabled={true}
      //   variant={"outlined"}
      //   label={"Time Zone"}
      //   margin="normal"
      // ></TextField>,
      // <br />
      ();
    scheduleRules.push(
      <TimeEntryPanel
        horisontal={false}
        addicon={true}
        disabled={false}
        noEndTime={noEndtimeRules}
        entrySet={timeentrySetRules}
        changeTimeEntryParent={(entrySet: TimeEntrySet | undefined) =>
          changeTimeEntryRules(entrySet)
        }
      ></TimeEntryPanel>,
    );
  } else {
    scheduleRules.push(
      <div className={classes.form_component}>
        <div
          className={classes.bold_text}
          style={{
            display: sceneChangeSteps === 2 ? "none" : "block",
          }}
        >
          Add Schedule
          <IconButton
            edge="end"
            aria-label="add"
            onClick={() => {
              addSchedule();
            }}
          >
            <AddCircle color={"primary"} />
          </IconButton>
        </div>
      </div>,
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [showComponent, setShowComponent] = useState(false);
  const [deletesiteid, setDeleteSiteId] = useState(0);
  const [confirmSiteOpen, setConfirmSiteOpen] = useState(false);
  const [editinghub, setEditingHub] = useState<Hub | undefined>(undefined);
  const [edithub, setEditHub] = useState(false);
  const [deletegroupid, setDeleteGroupId] = useState(0);
  const [confirmGroupOpen, setConfirmGroupOpen] = useState(false);
  const [deletesharedhubid, setDeleteSharedHubId] = useState(0);
  const [confirmSharedHubOpen, setConfirmSharedHubOpen] = useState(false);
  const [confirmmessage, setConfirmMessage] = useState("");
  const [messagemodalopen, setMessageModalOpen] = useState(false);
  const [popmessage, setPopMessage] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [statusinghub, setStatusingHub] = useState<Hub | undefined>(undefined);
  const [statushub, setStatusHub] = useState(false);
  const [localloading, setLocalLoading] = React.useState(false);
  const [moreinfo, setMoreInfo] = React.useState(false);
  const [movemodalopen, setMoveModalOpen] = useState(false);
  const [movingGroupOrg, setMovingGroupOrg] = useState<Org | undefined>(
    undefined,
  );
  const [openModal, setOpenModal] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsDisabled(false);
    }, 5000);
  }, []);

  const [orgTreeTotals, setOrgTreeTotals] = useState<any>(null);

  useEffect(() => {
    setLocalLoading(true);
    const fetchTreeTotals = async () => {
      try {
        const token = await appStore?.authService?.getAuthorisedToken();
        if (!token) {
          // setLocalLoading(false);
          return;
        } else if (token) {
          const orgtreecount =
            await appStore?.functionService?.getOrgTreeTotals(
              token,
              currentOrg?.org_id,
            );
          if (orgtreecount && orgtreecount.success) {
            setOrgTreeTotals(orgtreecount.totals);
          }
        }
      } catch (err) {
        console.error("Error:", err);
      } finally {
        setLocalLoading(false);
      }
    };
    fetchTreeTotals();
  }, [currentOrg.org_id]);

  const [movesite, setMoveSite] = useState<MoveSelect | undefined>(undefined);
  const [movegroup, setMoveGroup] = useState<MoveSelect | undefined>(undefined);
  const [movehub, setMoveHub] = useState<MoveSelect | undefined>(undefined);
  // boolean that indicates if disabled sites are visible
  const [seedisabledsites, setSeeDisabledSites] = useState(false);
  const [seeDisabledGroups, setSeeDisabledGroups] = useState(false);
  // boolean that indicates if sites are expanded
  const [sitesexpanded, setSitesExpanded] = useState(true);
  // boolean that indicates if groups are expanded
  const [groupsexpanded, setGroupsExpanded] = useState(false);
  // boolean that indicates if sharedhubs are expanded
  const [sharedhubsexpanded, setSharedHubsExpanded] = useState(false);
  // boolean that indicates if reportgroups are expanded
  const [reportgroupsexpanded, setReportGroupsExpanded] = useState(false);
  const [openRenameSiteModal, setOpenRenameSiteModal] = useState(false);
  const [openTrainingsSettingsModal, setOpenTrainingSettingsModal] =
    useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [site, setSite] = useState<Site | undefined>(undefined);
  const [openEditGroupModal, setOpenEditGroupModal] = useState(false);
  const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState(false);
  const [openShowSentinelToken, setShowSentinelToken] = useState(false);
  const [openGenerateSentinelToken, setGenerateSentinelToken] = useState(false);
  const [openAddGroupToSentinel, setOpenAddGroupToSentinel] = useState(false);
  const [selectedSentinelGroup, setSelectedSentinelGroup] = useState<
    Org | undefined
  >(undefined);
  const [openAddGroupToImmix, setOpenAddGroupToImmix] = useState(false);
  const [openAddGroupToFrontel, setOpenAddGroupToFrontel] = useState(false);
  const [openAddSiteToFrontel, setOpenAddSiteToFrontel] = useState(false);
  const [openEnableGroup, setOpenEnableGroup] = useState(false);
  const [selectedImmixGroup, setSelectedImmixGroup] = useState<Org | undefined>(
    undefined,
  );
  const [selectedFrontelGroup, setSelectedFrontelGroup] = useState<
    Org | undefined
  >(undefined);
  const [selectedFrontelSite, setSelectedFrontelSite] = useState<
    Site | undefined
  >(undefined);
  const [selectedFrontelHub, setSelectedFrontelHub] = useState<Hub | undefined>(
    undefined,
  );
  const [selectedEnableGroup, setSelectedEnableGroup] = useState<
    Org | undefined
  >(undefined);
  const [tot_sens_val, setTot_sens_val] = useState(0);

  const [getThumbnail, setGetThumbnail] = useState<any>(null);

  const closeShowSentinelToken = () => {
    setShowSentinelToken(false);
  };
  const closeGenerateSentinelToken = () => {
    setGenerateSentinelToken(false);
  };
  const closeAddGroupToSentinel = () => {
    setOpenAddGroupToSentinel(false);
  };
  const closeAddGroupToImmix = () => {
    setOpenAddGroupToImmix(false);
  };
  const closeAddGroupToFrontel = () => {
    setOpenAddGroupToFrontel(false);
  };
  const closeAddSiteToFrontel = () => {
    setOpenAddSiteToFrontel(false);
  };

  const dummy = () => {};

  const closeEditModal = () => {
    setOpenEditGroupModal(false);
  };
  const closeDeleteModal = () => {
    setOpenDeleteGroupModal(false);
  };

  const [current_org, setCurrentOrg] = useState(currentOrg);

  useEffect(() => {
    setCurrentOrg(currentOrg);
  }, [currentOrg]);

  //Displays group_id if more_info menu selected
  var groupmoreinfo = [];
  if (moreinfo) {
    groupmoreinfo.push(
      <div className={classes.medium_panel}>
        <span className={classes.bold_text_group_id}>
          Group ID: {currentOrg.org_id}
        </span>
      </div>,
    );
  }
  const totalSensor = (): number => {
    if (sites?.length > 0) {
      let total_sensor = sites.reduce((acc, item) => {
        return acc + item.sensorcount;
      }, 0);

      return total_sensor;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    // setTot_sens_val(totalSensor());
  }, [localloading]);

  const toggleMoreInfo = () => {
    setMoreInfo(!moreinfo);
  };

  const handleEditNotes = () => {
    setOpenNotesModal(true);
  };
  const statusHub = (hub: Hub) => {
    setStatusingHub(hub);
    setStatusHub(true);
  };
  const closeRenamesiteModal = () => {
    setOpenRenameSiteModal(false);
  };
  const closeTrainingSettingsModal = () => {
    setOpenTrainingSettingsModal(false);
  };
  const closeStatusHub = () => {
    setStatusHub(false);
  };

  const setSelectedOrg = (org: Org, index: number) => {
    setSelectedItem(org, false);
  };

  const setSelectedSite = (site: Site, index: number, editing: boolean) => {
    if (appStore!.authService.getRoles().indexOf("site_admin") >= 0) {
      setSelectedItem(site, editing);
    } else {
      setPopMessage(
        "You need site admin role to be able to view or edit a site - please contact your DeepAlert system administrator",
      );
      setMessageModalOpen(true);
    }
  };

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };
  const closeMessageModal = () => {
    setMessageModalOpen(false);
  };

  const openConfirmDeleteSite = (site_id: number, site_name: string) => {
    setDeleteSiteId(site_id);
    setConfirmMessage("Are you sure you want to delete " + site_name + "?");
    setConfirmSiteOpen(true);
  };
  const openConfirmDeleteSharedHub = (hub_id: number, hub_id_str: string) => {
    setDeleteSharedHubId(hub_id);
    setConfirmMessage("Are you sure you want to delete " + hub_id_str + "?");
    setConfirmSharedHubOpen(true);
  };
  const moveGroup = (org_id: number, org_name: string) => {
    let movegroup = new impMoveSelect();
    movegroup.id = org_id;
    movegroup.name = org_name;
    setMoveGroup(movegroup);
    setMoveHub(undefined);
    setMoveSite(undefined);
    setMoveModalOpen(true);
  };
  const moveSite = (site_id: number, site_name: string) => {
    let movegroup = new impMoveSelect();
    movegroup.id = site_id;
    movegroup.name = site_name;
    setMoveGroup(undefined);
    setMoveHub(undefined);
    setMoveSite(movegroup);
    setMoveModalOpen(true);
  };
  const moveSharedHub = (hub_id: number, hub_id_str: string) => {
    let movegroup = new impMoveSelect();
    movegroup.id = hub_id;
    movegroup.name = hub_id_str;
    setMoveGroup(undefined);
    setMoveHub(movegroup);
    setMoveSite(undefined);
    setMoveModalOpen(true);
  };
  const closeMoveModal = () => {
    setMoveModalOpen(false);
  };
  const closeEditNotes = () => {
    setOpenNotesModal(false);
  };
  const openConfirmDeleteGroup = (org_id: number, org_name: string) => {
    setDeleteGroupId(org_id);
    setConfirmMessage("Are you sure you want to delete " + org_name + "?");
    setConfirmGroupOpen(true);
  };

  const editHub = (hub: Hub) => {
    setEditingHub(hub);
    setEditHub(true);
  };
  const closeEditHub = () => {
    setEditHub(false);
  };
  const handleSeeDisabledSites = () => {
    setSeeDisabledSites(!seedisabledsites);
  };
  //function to toggle sitesexpanded when sites open/close icon clicked
  const handleSitesExpanded = () => {
    setSitesExpanded(!sitesexpanded);
  };
  //function to toggle groupsexpanded when groups open/close icon clicked
  const handleGroupsExpanded = () => {
    setGroupsExpanded(!groupsexpanded);
  };
  //function to toggle sharedhubsexpanded when sharedhubs open/close icon clicked
  const handleSharedHubsExpanded = () => {
    setSharedHubsExpanded(!sharedhubsexpanded);
  };
  //function to toggle reportgroupsexpanded when reportgroups open/close icon clicked
  const handleReportGroupsExpanded = () => {
    setReportGroupsExpanded(!reportgroupsexpanded);
  };
  //Function to sort sites array by name
  function comparesite(a: Site, b: Site) {
    // Use toUpperCase() to ignore character casing
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }
  function compareorg(a: Org, b: Org) {
    // Use toUpperCase() to ignore character casing
    const org_nameA = a.org_name.toUpperCase();
    const org_nameB = b.org_name.toUpperCase();

    let comparison = 0;
    if (org_nameA > org_nameB) {
      comparison = 1;
    } else if (org_nameA < org_nameB) {
      comparison = -1;
    }
    return comparison;
  }

  const deleteGroup = async () => {
    setConfirmGroupOpen(false);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_group = await appStore?.functionService.deleteOrg(
        token,
        deletegroupid,
      );

      if (delete_group) {
        if (delete_group.success) {
          let tempOrg: Org = currentOrg;
          tempOrg.type = "org";
          setSelectedItem(tempOrg, false);
          setPopMessage("Deleted group");
          openMessageModal();
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(delete_group.msg);
          openMessageModal();
        }
      }
    }
  };

  const dummyValues = {
    success: true,
    smtp_password: "",
    edge_enhanced_smtp_server: "",
    edge_enhanced_smtp_server_port: "",
  };
  const [open, setOpen] = useState(false);
  const [openChecker, setOpenChecker] = useState(false);
  const [createNewSmtpPasswordDetails, setCreateNewSmtpPasswordDetails] =
    useState(dummyValues);
  const [fetchItems, setFetchItems] = useState<any>([]);

  const onCloseChecker = () => {
    setOpenChecker(false);
  };

  const PasswordChangeHandler = async (siteId: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let newPassword = await appStore?.functionService.createNewSmtpPassword(
        token,
        siteId,
        true,
      );
      if (newPassword) {
        setCreateNewSmtpPasswordDetails(newPassword);
        setOpen(true);
        handleOpenSMTPModal(newPassword);
        // return(
        //   <SMTPChangePassword newPassword = {newPassword} open ={open} onClose = {onClose} />
        // )
      }
    }
  };
  const [siteId, setSiteId] = useState(0);
  const openNextModal = () => {
    setOpenChecker(false);
    setOpen(true);
    PasswordChangeHandler(siteId);
  };
  const onClose = () => {
    setOpen(false);
    setCreateNewSmtpPasswordDetails(dummyValues);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenSMTPModal = (
    newPassword: NewPasswordProps,
  ): React.ReactNode | null => {
    return <h1>{newPassword.edge_enhanced_smtp_server}</h1>;
  };

  const Holder = () => {
    setOpenChecker(true);
  };

  const deleteSite = async () => {
    setConfirmSiteOpen(false);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_site = await appStore?.functionService.deleteSite(
        token,
        deletesiteid,
      );
      if (delete_site) {
        if (delete_site.success) {
          setPopMessage("Deleted site");
          openMessageModal();
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(delete_site.msg);
          openMessageModal();
        }
      }
    }
  };

  const deleteSharedHub = async () => {
    setConfirmSharedHubOpen(false);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_hub = await appStore?.functionService.deleteHub(
        token,
        deletesharedhubid,
      );
      if (delete_hub) {
        if (delete_hub.success) {
          setPopMessage("Deleted hub");
          openMessageModal();
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(delete_hub.msg);
          openMessageModal();
        }
      }
    }
  };
  const updateSharedHub = async (
    location: string,
    networkconfig: NetworkConfig,
  ) => {
    setEditHub(false);
    setLocalLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && editinghub) {
      let update_hub = await appStore?.functionService.modifyHub(
        token,
        editinghub!.hub_id,
        location,
        0,
        true,
      );
      if (update_hub) {
        if (update_hub.success) {
          let update_network = await appStore?.functionService.modifyHubNetwork(
            token,
            editinghub?.hub_id!,
            networkconfig,
          );
          if (update_network) {
            if (update_network.success) {
              setLocalLoading(false);
              setPopMessage("Updated hub");
              openMessageModal();
              setRefresh(new Date().getTime());
            } else {
              setLocalLoading(false);
              setPopMessage(update_network.msg);
              openMessageModal();
              setRefresh(new Date().getTime());
            }
          }
        } else {
          setLocalLoading(false);
          setPopMessage(update_hub.msg);
          openMessageModal();
          setRefresh(new Date().getTime());
        }
      }
    }
  };
  const handleEnableDisableSite = async (site: Site) => {
    let reason = undefined;
    if (site.enabled) {
      reason = prompt("please indicate why this site should be disabled");
      if (reason === "") {
        reason = "Not provided";
      } else if (reason === null) {
        setPopMessage("Cancelled disabling of site");
        openMessageModal();
        setLocalLoading(false);
        setRefresh(new Date().getTime());
        return;
      }
    }
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLocalLoading(true);
      let updateSite = await appStore?.functionService.modifySiteEnabled(
        token,
        site.site_id,
        !site.enabled,
        reason,
      );
      if (updateSite && updateSite.success) {
        site.enabled = !site.enabled;
        sites![site.site_id] = site;
        if (site.enabled) {
          setPopMessage("Site enabled.");
        } else {
          setPopMessage("Site disabled.");
        }

        openMessageModal();
        setLocalLoading(false);
        setRefresh(new Date().getTime());
      } else {
        if (updateSite) {
          setPopMessage(updateSite.msg);
        } else {
          setPopMessage("Could not update sensor.");
        }
        openMessageModal();
      }
    }
  };
  function getGroupsDisplay() {
    let grouparray: any = [];
    let index = 0;
    let hasDisabledGroup = false;
    let items: any[] = [];
    for (let org_id in orgs) {
      let org = orgs[org_id];
      org.org_id = Number(org_id);
      org.type = "org";
      items.push(org);
    }
    items.sort(compareorg);
    items.forEach((org) => {
      var group_icon: React.ReactNode[] = [];
      let showgroup = false;
      if (org.enabled) {
        showgroup = true;
        group_icon.push(
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.87">
              <path
                d="M9.17 6L11.17 8H20V18H4V6H9.17ZM10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z"
                fill="#ffffff"
                fill-opacity="1"
              />
            </g>
          </svg>,
        );
      } else {
        hasDisabledGroup = true;
        if (seeDisabledGroups) {
          showgroup = true;
          group_icon.push(
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.87">
                <path
                  d="M9.17 6L11.17 8H20V18H4V6H9.17ZM10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z"
                  fill="#15325F"
                  fill-opacity="0.27"
                />
                <circle
                  cx="13"
                  cy="13"
                  r="10"
                  stroke="#ee4623"
                  stroke-width="2"
                ></circle>
                <line
                  x1="5"
                  y1="5"
                  x2="20"
                  y2="20"
                  stroke="#ee4623"
                  stroke-width="2"
                />
              </g>
            </svg>,
          );
        }
      }
      if (showgroup) {
        if (groupsexpanded) {
          grouparray.push(
            <Card raised className={classes.expansion_shelf}>
              <div
                className={classes.shelf_name}
                onClick={() => {
                  setSelectedItem(org, false);
                }}
              >
                <div style={{ display: "none" }}></div>
                <div className={classes.shelf_icon_group}>{group_icon}</div>
                <div className={classes.bold_text_subgroup}>{org.org_name}</div>
              </div>
              <div
                className={classes.shelf_container}
                onClick={() => {
                  setSelectedOrg(org, index);
                }}
              >
                <div className={classes.shelf_item}>
                  <Tooltip placement="bottom" title="Number of subgroups">
                    <div className={classes.icon}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.87">
                          <path
                            d="M9.17 6L11.17 8H20V18H4V6H9.17ZM10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z"
                            fill="#15325F"
                            fill-opacity="0.27"
                          />
                        </g>
                      </svg>
                    </div>
                  </Tooltip>
                  <div className={classes.bold_text_subgroup}>
                    {org.sub_org_count}
                  </div>
                </div>

                <div className={classes.shelf_item}>
                  <Tooltip
                    placement="bottom"
                    title="Total number of sites within the subgroup"
                  >
                    <div className="icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.87">
                          <path
                            d="M19 9.3V4H16V6.6L12 3L2 12H5V20H11V14H13V20H19V12H22L19 9.3ZM17 18H15V12H9V18H7V10.19L12 5.69L17 10.19V18Z"
                            fill="#15325F"
                            fill-opacity="0.27"
                          />
                          <path
                            d="M10 10H14C14 8.9 13.1 8 12 8C10.9 8 10 8.9 10 10Z"
                            fill="#15325F"
                            fill-opacity="0.27"
                          />
                        </g>
                      </svg>
                    </div>
                  </Tooltip>
                  <div className={classes.bold_text_subgroup}>
                    {org.site_count}
                  </div>
                </div>
                <div className={classes.shelf_item}>
                  <Tooltip placement="bottom" title="Hub Count">
                    <div className="icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.87">
                          <path
                            d="M17 16l-4-4V8.82C14.16 8.4 15 7.3 15 6c0-1.66-1.34-3-3-3S9 4.34 9 6c0 1.3.84 2.4 2 2.82V12l-4 4H3v5h5v-3.05l4-4.2 4 4.2V21h5v-5h-4z"
                            fill="#15325F"
                            fill-opacity="0.27"
                          />
                        </g>
                      </svg>
                    </div>
                  </Tooltip>
                  <div className={classes.bold_text_subgroup}>
                    {org.hub_count}
                  </div>
                </div>
              </div>
              <div className={classes.sitemenu_svg_icon}>
                <ExpansionPanelMenu
                  site={site}
                  // reloadLogos={reloadLogos}
                  defaultRules={true}
                  theOrg={org}
                  openAddDefaultsModal={handleOpenRuleModal}
                  thumbnail={getThumbnail}
                  activeOrg={currentOrg}
                  currentOrg={currentOrg.org_id}
                  appStore={appStore}
                  sentinel={
                    appStore!.authService.getRoles().indexOf("sys_admin") >= 0
                  }
                  showEnableOrDisableGroup={
                    appStore!.authService.getRoles().indexOf("sys_admin") >= 0
                  }
                  handleGenerateSentinelToken={dummy}
                  handleAddGroupToSentinel={() => {
                    setSelectedSentinelGroup(org);
                    setOpenAddGroupToSentinel(true);
                  }}
                  handleAddGroupToImmix={() => {
                    setSelectedImmixGroup(org);
                    setOpenAddGroupToImmix(true);
                  }}
                  handleAddGroupToFrontel={() => {
                    setSelectedFrontelGroup(org);
                    setOpenAddGroupToFrontel(true);
                  }}
                  handleAddSiteToFrontel={() => {
                    setSelectedFrontelSite(site);
                    openSiteFrontel(site);
                  }}
                  handleEnableGroup={() => {
                    setSelectedEnableGroup(org);
                    setOpenEnableGroup(true);
                  }}
                  handleShowSentinelToken={() => {
                    setSelectedSentinelGroup(org);
                    setShowSentinelToken(true);
                  }}
                  handleDefaultRulesOrg={() => {
                    setTHEORG(org);
                    setFetchRules(Math.floor(Math.random() * 1000) + 1);
                    initialSpinnerHandler();
                  }}
                  enableGroup={org.enabled}
                  disableGroupDate={org.disabled_date}
                  training={false}
                  handleTraining={dummy}
                  rename_site={false}
                  handleRenameSite={dummy}
                  enter={false}
                  disconnectOption={false}
                  nvr={false}
                  siteDynDNS={false}
                  requestDynDNS={true}
                  canSetDynDNS={false}
                  canViewDynDNS={false}
                  canViewFrontel={false}
                  disconnectStatus={false}
                  handleDisconnect={dummy}
                  billingratesedit={false}
                  handleBillingRatesEdit={dummy}
                  handleNvr={dummy}
                  handleActivateDynDns={dummy}
                  handleSetDynDns={dummy}
                  handleViewDynDns={dummy}
                  move={
                    appStore!.authService.getRoles().indexOf("sys_admin") >=
                      0 ||
                    appStore!.authService.getRoles().indexOf("support") >= 0
                  }
                  handleMove={() => {
                    setMovingGroupOrg(currentOrg);
                    moveGroup(org.org_id, org.org_name);
                  }}
                  del={true}
                  edit={false}
                  enable={false}
                  enabled={false}
                  handleEdit={dummy}
                  handleEnable={dummy}
                  handleDelete={() =>
                    openConfirmDeleteGroup(org.org_id, org.org_name)
                  }
                  alert_smtp_client={false}
                  handleSmtpPasswordChange={dummy}
                  report={false}
                  rules={false}
                  view_users={false}
                  handleAddTele={dummy}
                ></ExpansionPanelMenu>
              </div>
            </Card>,
          );
        }
        index++;
      }
    });

    if (index === 0) {
      grouparray.push(
        <Card raised className={classes.expansion_shelf}>
          <div className={classes.bold_text}>no sub-groups to display</div>
        </Card>,
      );
    }

    return [grouparray, index, hasDisabledGroup, items];
  }

  function getSitesDisplay() {
    let index = 0;
    let sitearray: React.ReactNode[] = [];
    let hasdisabled = false;

    let items = [];
    for (let key in sites) {
      let site = sites[key];
      site.type = "site";
      site.site_id = Number(key);
      items.push(site);
    }
    items.sort(comparesite);

    items.forEach((site) => {
      let key = site.site_id;
      let tooltip_dict: React.ReactNode[] = [];
      var indicator = {
        color: "clear",
        tooltip: tooltip_dict,
      };
      for (let healthkey in healthsites) {
        let elem = healthsites[healthkey];
        if (elem.site_id === key) {
          var healthsite = elem;
          var tooltip: React.ReactNode[] = [];
          for (let hubkey in healthsite.hubs) {
            let hub = healthsite.hubs[hubkey];
            tooltip.push("Hub " + hub.hub_id_str + " info:");
            tooltip.push(<br></br>);
            if (hub.msg_healthy === true) {
              tooltip.push("Message:");
              tooltip.push(
                <SentimentSatisfiedAltIcon></SentimentSatisfiedAltIcon>,
              );
            } else if (hub.msg_healthy === false) {
              tooltip.push("Message:");
              tooltip.push(
                <SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>,
              );
            } else {
            }

            if (hub.vpn_healthy === true) {
              tooltip.push("VPN:");
              tooltip.push(
                <SentimentSatisfiedAltIcon></SentimentSatisfiedAltIcon>,
              );
            } else if (hub.vpn_healthy === false) {
              tooltip.push("VPN:");
              tooltip.push(
                <SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>,
              );
            } else {
            }
            tooltip.push("Site:");
            if (hub.site_health_indicator === "error") {
              tooltip.push(
                <SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>,
              );
            } else if (hub.site_health_indicator === "warning") {
              tooltip.push(
                <SentimentDissatisfiedIcon></SentimentDissatisfiedIcon>,
              );
            } else if (hub.site_health_indicator === "ok") {
              tooltip.push(
                <SentimentSatisfiedAltIcon></SentimentSatisfiedAltIcon>,
              );
            } else {
              tooltip.push(
                <RadioButtonUncheckedIcon></RadioButtonUncheckedIcon>,
              );
            }
            tooltip.push(<Divider />);
          }
          indicator.tooltip = tooltip;

          indicator.color = "clear";
          if (healthsite.health_indicator === "ok") {
            indicator.color = "#3f54a5";
          }
          if (healthsite.health_indicator === "warning") {
            indicator.color = "#b4d5fa";
          }
          if (healthsite.health_indicator === "error") {
            indicator.color = "#ee4623";
          }
          break;
        }
      }
      if (indicator.tooltip?.length === 0) {
        indicator.tooltip.push("No Hubs");
        indicator.color = "clear";
      }
      var product = [];

      if (site.public_product_name?.includes("Weapons")) {
        product.push(
          <div className="product-brand">
            <img
              className={classes.product_icon}
              src="/assets/img/weapons.png"
              alt=""
            />
          </div>,
        );
      } else if (site.public_product_name?.includes("Safety")) {
        product.push(
          <div className="product-brand">
            <img
              className={classes.product_icon}
              src="/assets/img/safety.png"
              alt=""
            />
          </div>,
        );
      } else if (site.tier_name?.includes("premium")) {
        product.push(
          <div className="product-brand">
            <img
              className={classes.product_icon}
              src="/assets/img/premium.png"
              alt=""
            />
          </div>,
        );
      } else {
        product.push(
          <div className="product-brand">
            <img
              className={classes.product_icon}
              src="/assets/img/connect.png"
              alt=""
            />
          </div>,
        );
      }

      // var alerting_icon = [];
      // if (site.alertconfigcount.count > 0) {
      //   alerting_icon.push(<CheckIcon style={{ fill: "#3f54a5" }} />);
      // } else {
      //   alerting_icon.push(<CloseIcon style={{ fill: "#ee4623" }} />);
      // }

      // check icon
      let alerting_icon = <CheckIcon style={{ fill: "#3f54a5" }} />;

      var editable =
        appStore!.authService.getRoles().indexOf("site_admin") >= 0;
      let sitemenu: React.ReactNode[] = [];
      if (editable) {
        let requested = false;
        if (site.request_dyn_dns) {
          requested = site.request_dyn_dns;
        }
        let activated = false;
        if (site.active_dyn_dns) {
          activated = site.active_dyn_dns;
        }
        let viewFrontel = false;
        if (site?.meta?.active_frontel === true) {
          viewFrontel = true;
        }
        sitemenu.push(
          <ExpansionPanelMenu
            site={site}
            // reloadLogos={reloadLogos}
            thumbnail={getThumbnail}
            activeOrg={currentOrg}
            appStore={appStore}
            currentOrg={currentOrg.org_id}
            handleAddGroupToSentinel={() => {}}
            handleGenerateSentinelToken={() => {}}
            handleShowSentinelToken={() => {}}
            sentinel={false}
            rename_site={true}
            enter={true}
            training={
              appStore!.authService.getRoles().indexOf("sys_admin") >= 0
                ? true
                : false
            }
            nvr={site.data_origin === "alert_smtp_client" ? true : false}
            siteDynDNS={
              site?.data_origin === "sentinel_http_edge_human1" ||
              site.data_origin === "alert_smtp_client"
                ? true
                : false
            }
            requestDynDNS={requested}
            canSetDynDNS={
              appStore!.authService.getRoles().indexOf("sys_admin") >= 0 ||
              appStore!.authService.getRoles().indexOf("support") >= 0
            }
            canViewDynDNS={requested && activated}
            canViewFrontel={viewFrontel}
            billingratesedit={
              appStore!.authService.getRoles().indexOf("sys_admin") >= 0
            }
            disconnectOption={false}
            disconnectStatus={false}
            handleDisconnect={() => {}}
            handleTraining={() => {
              setSite(site);
              setOpenTrainingSettingsModal(true);
            }}
            handleNvr={() => {
              openSiteNvrModal(sensor, site);
            }}
            handleActivateDynDns={() => {
              openActivateDynDnsModal(sensor, site);
            }}
            handleSetDynDns={() => {
              openSetDynDnsModal(sensor, site);
            }}
            handleViewDynDns={() => {
              openViewDynDnsModal(sensor, site);
            }}
            handleRenameSite={() => {
              setSite(site);
              setOpenRenameSiteModal(true);
            }}
            handleBillingRatesEdit={() => openSiteBillingRatesModal(site)}
            move={
              appStore!.authService.getRoles().indexOf("sys_admin") >= 0 ||
              appStore!.authService.getRoles().indexOf("support") >= 0 ||
              appStore!.authService.getRoles().indexOf("move_site") >= 0
            }
            handleMove={() => {
              setMovingGroupOrg(currentOrg);
              moveSite(site.site_id, site.name);
            }}
            del={false}
            edit={true}
            enable={true}
            enabled={site.enabled}
            handleEdit={() => setSelectedSite(site, index, true)}
            handleEnable={() => handleEnableDisableSite(site)}
            handleDelete={() => openConfirmDeleteSite(site.site_id, site.name)}
            alert_smtp_client={
              site.data_origin === "alert_smtp_client" ? true : false
            }
            handleSmtpPasswordChange={() => {
              setSiteId(site.site_id);
              Holder();
            }}
            report={false}
            rules={false}
            view_users={false}
            handleAddTele={dummy}
            handleAddGroupToFrontel={() => {}}
            handleAddSiteToFrontel={() => openSiteFrontel(site)}
          ></ExpansionPanelMenu>,
        );
      }

      var site_icon: React.ReactNode[] = [];
      let showsite = false;
      if (site.enabled) {
        showsite = true;
        site_icon.push(
          <svg
            width="20.19"
            height="20.75"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.87">
              <path
                d="M17 6.3V1H14V3.6L10 0L0 9H3V17H9V11H11V17H17V9H20L17 6.3ZM15 15H13V9H7V15H5V7.19L10 2.69L15 7.19V15Z"
                fill="#15325F"
                fill-opacity="0.87"
              />
              <path
                d="M8 7H12C12 5.9 11.1 5 10 5C8.9 5 8 5.9 8 7Z"
                fill="ffffff"
                fill-opacity="0.87"
              />
            </g>
          </svg>,
        );
      } else {
        //set boolean to show see disabled icon only if disabled sites present
        hasdisabled = true;
        //only show disables sites when 'seedisabledsites'=true, this gets toggled by the icon in the expansion bar
        if (seedisabledsites) {
          showsite = true;
          site_icon.push(
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.87">
                <path
                  d="M17 6.3V1H14V3.6L10 0L0 9H3V17H9V11H11V17H17V9H20L17 6.3ZM15 15H13V9H7V15H5V7.19L10 2.69L15 7.19V15Z"
                  fill="#15325F"
                  fill-opacity="0.87"
                />
                <path
                  d="M8 7H12C12 5.9 11.1 5 10 5C8.9 5 8 5.9 8 7Z"
                  fill="#15325F"
                  fill-opacity="0.87"
                />
                <circle
                  cx="10"
                  cy="10"
                  r="7"
                  stroke="#ee4623"
                  stroke-width="2"
                ></circle>
                <line
                  x1="3"
                  y1="3"
                  x2="17"
                  y2="17"
                  stroke="#ee4623"
                  stroke-width="2"
                />
              </g>
            </svg>,
          );
        }
      }
      if (showsite) {
        if (sitesexpanded) {
          sitearray.push(
            <Card raised className={classes.expansion_shelf}>
              <div
                className={classes.shelf_name}
                onClick={() => setSelectedSite(site, index, false)}
              >
                <div className={classes.shelf_icon}>{site_icon}</div>
                <div
                  className={classes.bold_text_sites}
                  onClick={() => setSelectedSite(site, index, false)}
                >
                  {site.name}
                </div>
              </div>
              <div className={classes.shelf_container}>
                <div
                  className={classes.shelf_item}
                  style={{
                    position: "absolute",
                    left: "475px",
                  }}
                  onClick={() => setSelectedSite(site, index, false)}
                >
                  <div className={classes.bold_text}>{product}</div>
                </div>

                <div
                  className={classes.shelf_item}
                  style={{
                    position: "absolute",
                    left: "590px",
                  }}
                  onClick={() => setSelectedSite(site, index, false)}
                >
                  <Tooltip placement="bottom" title="Hub Count">
                    <div className="icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 16L13 12V8.82C14.16 8.4 15 7.3 15 6C15 4.34 13.66 3 12 3C10.34 3 9 4.34 9 6C9 7.3 9.84 8.4 11 8.82V12L7 16H3V21H8V17.95L12 13.75L16 17.95V21H21V16H17Z"
                          fill="#15325F"
                          fill-opacity="0.27"
                        />
                      </svg>
                    </div>
                  </Tooltip>
                  <div className={classes.text_site_hubcount}>
                    {site.hubcount}
                  </div>
                </div>
                <div
                  className={classes.shelf_item}
                  style={{
                    position: "absolute",
                    left: "680px",
                  }}
                  onClick={() => setSelectedSite(site, index, false)}
                >
                  <Tooltip placement="bottom" title="Camera Count">
                    <div className="icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="1">
                          <path
                            d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                            fill="#15325F"
                            fill-opacity="0.27"
                          />
                        </g>
                      </svg>
                    </div>
                  </Tooltip>
                  <div className={classes.ite_text_sensorcount}>
                    {site.sensorcount}
                  </div>
                </div>
                <div
                  className={classes.shelf_item}
                  style={{
                    position: "absolute",
                    left: "780px",
                  }}
                  onClick={() => setSelectedSite(site, index, false)}
                >
                  <Tooltip
                    placement="bottom"
                    title="Has an alert rule set been configured"
                  >
                    <div className="icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z"
                          fill="#15325F"
                          fill-opacity="0.24"
                        />
                      </svg>
                    </div>
                  </Tooltip>
                  <div
                    className={classes.shelf_item_check_icon}
                    style={{
                      color: "#2E4FA5",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#fff",
                      }}
                    >
                      {React.cloneElement(alerting_icon, {
                        style: {
                          fill: "#2E4FA5",
                          width: "20px",
                          height: "20px",
                        },
                      })}
                    </span>
                  </div>
                </div>

                <div
                  className={classes.shelf_item}
                  style={{
                    position: "absolute",
                    left: "880px",
                  }}
                >
                  {!site?.meta?.frontel_account ? (
                    <div className={classes.icon_cicle}>
                      <Tooltip placement="left" title={indicator.tooltip}>
                        {indicator.color === "clear" ? (
                          <RadioButtonUncheckedIcon
                            data-tip
                            data-for={"healthTip" + index}
                          ></RadioButtonUncheckedIcon>
                        ) : (
                          <Brightness1
                            data-tip
                            data-for={"healthTip" + index}
                            style={{ fill: indicator.color }}
                          ></Brightness1>
                        )}
                      </Tooltip>
                    </div>
                  ) : (
                    <div
                      className="circle"
                      style={{
                        borderColor: "red",
                        position: "absolute",
                        left: "850px",
                      }}
                    >
                      ...
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.shelf_menu}>
                <span className={classes.sitemenu_svg_icon}>{sitemenu}</span>
              </div>
            </Card>,
          );
        }
        index++;
      }
    });
    // setTot_sens_val(total_sensor_number);

    if (index === 0) {
      sitearray.push(
        <Card raised className={classes.expansion_shelf}>
          <div className={classes.bold_text}>no sites to display</div>
        </Card>,
      );
    }

    return [sitearray, index, hasdisabled];
  }
  function getHubsDisplay() {
    let index = 0;
    let hubarray: React.ReactNode[] = [];

    for (let key in hubs) {
      let hub = hubs[key];
      if (!hub.site_id) {
        hub.type = "hub";
        hub.hub_id = Number(key);
        if (sharedhubsexpanded) {
          hubarray.push(
            <Card raised className={classes.expansion_shelf}>
              <div className={classes.shelf_name} onClick={() => editHub(hub)}>
                <div className={classes.shelf_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 16L13 12V8.82C14.16 8.4 15 7.3 15 6C15 4.34 13.66 3 12 3C10.34 3 9 4.34 9 6C9 7.3 9.84 8.4 11 8.82V12L7 16H3V21H8V17.95L12 13.75L16 17.95V21H21V16H17Z"
                      fill="#15325F"
                      fill-opacity="0.27"
                    />
                  </svg>
                </div>
                <div
                  className={classes.bold_text_shared_hubs}
                  onClick={() => editHub(hub)}
                >
                  {hub.hub_id_str}
                </div>
              </div>

              <div
                className={classes.shelf_container}
                onClick={() => editHub(hub)}
              >
                <div className={classes.shelf_item}>
                  <div className="circle"></div>
                </div>
              </div>
              <div className={classes.sitemenu_svg_icon}>
                <HubExpansionPanelMenu
                  move={
                    appStore!.authService.getRoles().indexOf("sys_admin") >= 0
                  }
                  handleMove={() => {
                    setMovingGroupOrg(currentOrg);
                    moveSharedHub(hub.hub_id, hub.hub_id_str);
                  }}
                  status={true}
                  del={true}
                  edit={
                    appStore!.authService.getRoles().indexOf("sys_admin") >= 0
                  }
                  enable={false}
                  enabled={false}
                  handleEdit={() => editHub(hub)}
                  handleEnable={dummy}
                  handleStatus={() => statusHub(hub)}
                  handleDelete={() =>
                    openConfirmDeleteSharedHub(hub.hub_id, hub.hub_id_str)
                  }
                ></HubExpansionPanelMenu>
              </div>
            </Card>,
          );
        }
        index++;
      }
    }
    return [hubarray, index];
  }
  function getReportGroups() {
    let index = 0;
    let reportarray: React.ReactNode[] = [];

    if (reportgroups && reportgroups?.length > 0) {
      reportgroups.forEach((report) => {
        report.type = "report_group";
        if (reportgroupsexpanded) {
          reportarray.push(
            <Card raised className={classes.expansion_shelf}>
              <div
                className={classes.shelf_name}
                onClick={() => setEditReportGroupModalOpen(report)}
              >
                <div className={classes.shelf_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
                      fill="#15325F"
                      fill-opacity="0.87"
                    />
                  </svg>
                </div>
                <div className={classes.bold_text}>
                  {report.name} (id={report.id})
                </div>
              </div>

              <div
                className={classes.shelf_container}
                onClick={() => setEditReportGroupModalOpen(report)}
              >
                <div className={classes.shelf_item}>
                  <div className="circle"></div>
                </div>
              </div>
              <div className={classes.shelf_menu}>
                <ExpansionPanelMenu
                  site={site}
                  // reloadLogos={reloadLogos}
                  defaultRules={true}
                  openAddDefaultsModal={handleOpenRuleModal}
                  thumbnail={getThumbnail}
                  activeOrg={currentOrg}
                  currentOrg={currentOrg.org_id}
                  appStore={appStore}
                  handleAddGroupToSentinel={dummy}
                  sentinel={false}
                  training={false}
                  handleGenerateSentinelToken={() => {}}
                  handleShowSentinelToken={() => {}}
                  handleTraining={dummy}
                  rename_site={false}
                  handleRenameSite={dummy}
                  enter={false}
                  nvr={false}
                  siteDynDNS={false}
                  requestDynDNS={true}
                  canSetDynDNS={false}
                  canViewDynDNS={false}
                  canViewFrontel={false}
                  billingratesedit={false}
                  disconnectStatus={false}
                  disconnectOption={false}
                  handleDisconnect={dummy}
                  handleBillingRatesEdit={dummy}
                  handleNvr={dummy}
                  handleActivateDynDns={dummy}
                  handleSetDynDns={dummy}
                  handleViewDynDns={dummy}
                  move={false}
                  handleMove={dummy}
                  del={true}
                  edit={true}
                  enable={false}
                  enabled={false}
                  handleEdit={() => setEditReportGroupModalOpen(report)}
                  handleEnable={dummy}
                  handleDelete={() =>
                    openConfirmDeleteReportGroup(report.id, report.name)
                  }
                  alert_smtp_client={false}
                  handleSmtpPasswordChange={dummy}
                  report={false}
                  rules={false}
                  view_users={false}
                  handleAddTele={dummy}
                  handleAddGroupToFrontel={dummy}
                  handleAddSiteToFrontel={dummy}
                ></ExpansionPanelMenu>
              </div>
            </Card>,
          );
        }
        index++;
      });
    }

    return [reportarray, index];
  }

  const [subgroups, groupcount, hasDisabledGroup, items] = getGroupsDisplay();

  // You can use subgroups, groupcount, and hasDisabledGroup here or in other parts of your code.

  const [sitesdrawer, sitecount, hasdisabled] = getSitesDisplay();
  const [sharedhubs, hubcount] = getHubsDisplay();
  const [reportgroupsdrawer, reportcount] = getReportGroups();
  var sharedhubsdisplay = [];
  //manage icon to expand and close shared hubs
  let expandhubs: React.ReactNode[] = [];
  if (sharedhubsexpanded) {
    expandhubs.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleSharedHubsExpanded()}
      />,
    );
  } else {
    expandhubs.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleSharedHubsExpanded()}
      />,
    );
  }
  //manage icon to expand and close groups
  let expandgroups: React.ReactNode[] = [];
  if (groupsexpanded) {
    expandgroups.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleGroupsExpanded()}
      />,
    );
  } else {
    expandgroups.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleGroupsExpanded()}
      />,
    );
  }
  //manage icon to expand and close sites
  let expandsites: React.ReactNode[] = [];
  if (sitesexpanded) {
    expandsites.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleSitesExpanded()}
      />,
    );
  } else {
    expandsites.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleSitesExpanded()}
      />,
    );
  }
  //Set icon to red when able to see disabled sites, else white
  let disablecolor = "#FFFFFF";
  if (seedisabledsites) {
    disablecolor = "#ee4623";
  }

  let disableGroupcolor = "#FFFFFF";
  if (seeDisabledGroups) {
    disableGroupcolor = "#ee4623";
  }

  let disabledGroupicon: React.ReactNode[] = [];
  if (hasDisabledGroup) {
    disabledGroupicon.push(
      <IconButton
        edge="end"
        aria-label="add"
        onClick={(event) => {
          event.stopPropagation();
          setSeeDisabledGroups((val) => !val);
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.87">
            <circle
              cx="10"
              cy="10"
              r="7"
              stroke={disableGroupcolor}
              stroke-width="2"
            ></circle>
            <line
              x1="3"
              y1="3"
              x2="17"
              y2="17"
              stroke={disableGroupcolor}
              stroke-width="2"
            />
          </g>
        </svg>
      </IconButton>,
    );
  }
  //only show the show disabled icon if disabled sites present
  let disabledicon: React.ReactNode[] = [];
  if (hasdisabled) {
    disabledicon.push(
      <IconButton
        edge="end"
        aria-label="add"
        onClick={(event) => {
          event.stopPropagation();
          handleSeeDisabledSites();
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.87">
            <circle
              cx="10"
              cy="10"
              r="7"
              stroke={disablecolor}
              stroke-width="2"
            ></circle>
            <line
              x1="3"
              y1="3"
              x2="17"
              y2="17"
              stroke={disablecolor}
              stroke-width="2"
            />
          </g>
        </svg>
      </IconButton>,
    );
  }
  //manage icon to expand and close report groups
  let expandreports: React.ReactNode[] = [];
  if (reportgroupsexpanded) {
    expandreports.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleReportGroupsExpanded()}
      />,
    );
  } else {
    expandreports.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleReportGroupsExpanded()}
      />,
    );
  }
  //create the shared hub display separately as only shown if there is at least one
  if (typeof hubcount === "number" && hubcount > 0) {
    sharedhubsdisplay.push(
      <>
        <Card
          className={classes.expansionbar}
          onClick={() => {
            handleSharedHubsExpanded();
          }}
        >
          <Tooltip
            placement="bottom"
            title="A shared hub services multiple sites i.e. camera streams from different sites can be configured on the hub."
          >
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 16L13 12V8.82C14.16 8.4 15 7.3 15 6C15 4.34 13.66 3 12 3C10.34 3 9 4.34 9 6C9 7.3 9.84 8.4 11 8.82V12L7 16H3V21H8V17.95L12 13.75L16 17.95V21H21V16H17Z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </div>
                <div className={classes.header_text_white}>
                  Shared Hubs{"  (" + hubcount + ")"}
                </div>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    openAddSharedHubModal();
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </IconButton>
              </div>
            </Typography>
          </Tooltip>
          {expandhubs}
        </Card>
        <div className={classes.expansioncontent}>{sharedhubs}</div>
      </>,
    );
  }
  //create screen output
  if (loading || localloading) {
    return (
      <Container maxWidth="md">
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </Container>
    );
  } else {
    return (
      <div className="main-drawer-container">
        <div className={classes.location_header}>
          <div className={classes.header_icon}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10z"
                fill="#15325F"
                fill-opacity="0.87"
              ></path>
            </svg>
          </div>
          <div className={classes.header_text}>
            {currentOrg.org_name + "  (id=" + currentOrg.org_id + ")"}
          </div>
          {/* <div className={styles.shelf_item_logo}>
            {!localloading && (
              <ImageLogos
              appStore={appStore}
              currentOrg={currentOrg}
              getThumbnail={setGetThumbnail}
              localLoading={setLocalLoading}
              ></ImageLogos>
            )}
            </div> */}
          <div>
            <Modal
              open={openMsg}
              onClose={handleCloseMsgModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={updateMsg}>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "rgb(21,50,95)",
                    textAlign: "center",
                  }}
                >
                  {addZoneMsg}
                </p>
                <Button
                  style={{
                    backgroundColor: "rgb(220,220,220)",
                    color: "rgb(21,50,95)",
                    fontWeight: "bold",
                    margin: " 40px 180px",
                  }}
                  onClick={handleCloseMsgModal}
                >
                  OK
                </Button>
              </Box>
            </Modal>
            <Modal
              open={openDeleteRuleModal}
              onClose={handleCloseDeleteRuleModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                {loadingSpinner === true ? (
                  <CircularProgress
                    size={70}
                    style={{
                      color: "rgb(21,50,95)",
                      margin: "auto",
                    }}
                  />
                ) : (
                  <div>
                    <p
                      style={{
                        color: "rgb(21,50,95)",
                        fontWeight: "bold",
                      }}
                    >
                      {updateResponse === ""
                        ? "Are you sure you want to delete rule?"
                        : updateResponse?.msg}
                    </p>
                    {updateResponse !== "" ? (
                      <Button
                        style={{
                          backgroundColor: "rgb(220,220,220)",
                          color: "rgb(21,50,95)",
                          fontWeight: "bold",
                          marginTop: "40px",
                        }}
                        onClick={() => {
                          handleCloseDeleteRuleModal();
                          setRefresh(new Date().getTime());
                          setUpdateResponse("");
                          setFetchRules(Math.floor(Math.random() * 1000) + 1);
                          initialSpinnerHandler();
                        }}
                      >
                        OK
                      </Button>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "80%",
                          margin: "0 auto",
                          marginTop: "70px",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "rgb(220,220,220)",
                            color: "rgb(21,50,95)",
                            fontWeight: "bold",
                          }}
                          onClick={handleCloseDeleteRuleModal}
                        >
                          CANCEL
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            deleteDefaultRuleHandler();
                          }}
                        >
                          DELETE
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Modal>
            <Modal
              open={openSceneChange}
              onClose={handleCloseSceneChange}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={addRuleModal}>
                {loadingSpinner ? (
                  <div style={{ marginTop: "190px" }}>
                    <LoadingSpinner size="large" />
                  </div>
                ) : (
                  <div>
                    {updateRulesMsg !== "" ? (
                      <div
                        style={{
                          marginTop: "150px",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "rgb(21,50,95)",
                            textAlign: "center",
                          }}
                        >
                          {updateRulesMsg}
                        </p>
                        <Button
                          style={{
                            backgroundColor: "rgb(220,220,220)",
                            color: "rgb(21,50,95)",
                            fontWeight: "bold",
                            margin: " 40px 180px",
                            width: "100px",
                          }}
                          onClick={() => {
                            handleCloseSceneChange();
                            setUpdateRulesMsg("");
                          }}
                        >
                          OK
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {sceneChangeSteps === 1 && (
                          <div style={{ width: "100%" }}>
                            <p
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                fontSize: "12px",
                                margin: "0 auto",
                                padding: "10px 0",
                              }}
                            >
                              {
                                "Please note that the timezone applied will be as specified at the site level."
                              }
                            </p>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="schedule"
                                name="schedule"
                                value={radioValue}
                                onChange={handleTimeChange}
                              >
                                <FormControlLabel
                                  value="always"
                                  control={<Radio />}
                                  label="Always active"
                                  disabled={false}
                                />

                                <FormControlLabel
                                  value="schedule"
                                  control={<Radio />}
                                  label="Schedule"
                                  disabled={false}
                                />
                              </RadioGroup>
                            </FormControl>
                            {radioValue !== "always" && (
                              <div
                                style={{
                                  width: "38%",
                                  margin: "0 auto",
                                  display: "flex",
                                  alignItems: "flex-start",
                                }}
                              >
                                {radioValue === "" ? (
                                  <span>{""}</span>
                                ) : (
                                  <div
                                    style={{
                                      // width: "38%",
                                      zIndex: 1,
                                      margin: "0 auto",
                                    }}
                                  >
                                    {scheduleRules}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {sceneChangeSteps === 2 && (
                          <div>
                            <h2
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              CONFIRM CHANGES:-
                            </h2>
                            <div style={{ height: "20px" }}></div>
                            <p style={{ fontWeight: "bold", color: "red" }}>
                              {
                                "Please note that you will be updating your defaults rules for this ‘subgroup’"
                              }
                            </p>
                            <div style={{ height: "60px" }}></div>
                            <div
                              style={{
                                width: "80%",
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                RULE TYPE: <span>{"SCENE CHANGE"}</span>
                              </p>
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                RULE TIME:{"  "}
                                <span>
                                  {radioValue === "always"
                                    ? "ALWAYS ACTIVE"
                                    : radioValue === "schedule"
                                      ? "SCHEDULED"
                                      : ""}
                                </span>
                              </p>
                            </div>
                            <div
                              style={{
                                marginTop: "45px",
                                width: "100%",
                                margin: "0 auto",
                                // borderTop: "1px solid rgb(21,50,95)",
                                // borderBottom: "1px solid rgb(21,50,95)",
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                overflow: "auto",
                                maxHeight: "300px",
                                overflowY: "auto",
                                display: "grid",
                                // gridTemplateColumns: "repeat(2, 1fr)",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                }}
                              >
                                DAYS & TIMES:{" "}
                              </p>
                              <div
                                style={{
                                  width: "80%",
                                  margin: "0 auto",
                                  textAlign: "center",
                                }}
                              >
                                {radioValue === "always" ? (
                                  <p
                                    style={{
                                      color: "rgb(21,50,95)",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {"Days & Times: "}
                                    <span
                                      style={{
                                        color: "black",
                                        fontWeight: "light",
                                      }}
                                    >{`${"always active"}`}</span>
                                  </p>
                                ) : radioValue === "schedule" ? (
                                  <p
                                    style={{
                                      color: "rgb(21,50,95)",
                                      fontWeight: "bold",
                                      pointerEvents: "none",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "grey",
                                        fontWeight: "light",
                                      }}
                                    >
                                      {scene_valid_days_and_times}
                                    </span>
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        <div
                          className="cancel_next_btns"
                          style={{
                            display: "flex",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              color: "rgb(21,50,95)",
                              fontWeight: "bold",
                              width: "100px",
                              height: "40px",
                              margin: "35px auto",
                            }}
                            onClick={() => {
                              setZoneData([]);
                              setSteps(1);
                              setRuleName("");
                              setSelectedClass("");
                              setSelectedInterVal(null);
                              setSelectedMotion("");
                              setSelectedThreshold(35);
                              setSelectedCount(0);
                              setSelectedCountLogic("> (greater than)");
                              handleCloseAddRule();
                              setOpenSceneChange(false);
                              setTimeEntrySetRules(undefined);
                              setRadioValue("always");
                              setRuleTypeToDelete("");
                              setRuleSubTypeToDelete("");
                              setRuleName("");
                              setAlertTypeToDelete("");
                            }}
                          >
                            CANCEL
                          </Button>
                          {sceneChangeSteps > 1 && (
                            <Button
                              variant="contained"
                              disabled={false}
                              style={{
                                color: "white",
                                backgroundColor:
                                  radioValue === "" ? "" : "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={prevSceneStepsHandler}
                            >
                              PREV
                            </Button>
                          )}
                          {sceneChangeSteps !== 2 && (
                            <Button
                              variant="contained"
                              disabled={
                                radioValue === ""
                                  ? true
                                  : radioValue === "always"
                                    ? false
                                    : radioValue === "schedule"
                                      ? false
                                      : true
                              }
                              style={{
                                color: "white",
                                backgroundColor:
                                  radioValue === "" || sceneChangeSteps === 2
                                    ? ""
                                    : "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={nextSceneStepsHandler}
                            >
                              NEXT
                            </Button>
                          )}
                          {sceneChangeSteps === 2 && (
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "190px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={() => {
                                saveUpdateGroupDefaultRulesHandler();
                              }}
                            >
                              {`${
                                orgName === theORG?.org_name
                                  ? "Update Default Rules"
                                  : "Save Default Rules"
                              }`}
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Modal>
            <AntModal
              title={
                <h5
                  style={{
                    fontWeight: "bold",
                    color: "rgb(21,50,95)",
                    margin: "0 auto",
                    padding: "10px 0",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                >
                  <b>ADD DEFAULT RULE:</b>
                </h5>
              }
              open={ruleConfig}
              onCancel={handleRuleConfigModalClose}
              onOk={handleRuleConfigModalClose}
              footer={null}
              style={{ maxWidth: "1600px", minWidth: "1200px" }} // Set the width to 80% of the parent
            >
              {/*Rules Wiz start */}
              {default_rules?.rules &&
                sensorDefinedRules &&
                ruleConfig &&
                theORG?.org_id && (
                  <RulesWizard
                    closeWizard={() => {
                      // setShowWizard(false)
                      setRuleConfig(false);
                    }}
                    appStore={appStore}
                    sensor={sensor}
                    site={site}
                    setOpenWizardModal={setOpenWizardModal}
                    saveZonesHandler={saveZonesHandler}
                    sensorDefinedRule={optionValue}
                    sensorDefinedRules={ruleDefs}
                    ruleDefs={ruleDefs}
                    defaultRule
                    orgId={theORG?.org_id}
                    setUpdateRulesMsg={setUpdateRulesMsg}
                    default_rules={default_rules}
                    setLoadingSpinner={setLoadingSpinner}
                    fetchDefaultRules={fetchDefaultRules}
                  />
                )}
              {/* {<div
                style={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: "40px",
                }}
              >
                {loadingSpinner === true ? (
                  <div
                    style={{
                      margin: "auto",
                      fontSize: "100px",
                      position: "absolute",
                      top: "30%",
                      left: "50%",
                      color: "rgb(21,50,95)",
                    }}
                  >
                    <CircularProgress
                      size={80}
                      style={{
                        color: "rgb(21,50,95)",
                        margin: "auto",
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    {updateRulesMsg !== "" ? (
                      <div
                        style={{
                          marginTop: "150px",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "rgb(21,50,95)",
                            textAlign: "center",
                          }}
                        >
                          {updateRulesMsg}
                        </p>
                        <Button
                          style={{
                            backgroundColor: "rgb(220,220,220)",
                            color: "rgb(21,50,95)",
                            fontWeight: "bold",
                            margin: " 40px 180px",
                            width: "100px",
                          }}
                          onClick={() => {
                            handleCloseAddRule();
                            setUpdateRulesMsg("");
                            handleRuleConfigModalClose();
                          }}
                        >
                          OK
                        </Button>
                      </div>
                    ) : (
                      <AntCard style={{border: "1px solid #ccce", borderRadius: 8, minWidth: 800, maxWidth: '100%', backgroundColor: "#f6f6f6"}}>
                        {steps === 1 && (
                          <div>
                            <div>
                              {updateResponse === "" && false && (
                                <div
                                  className="save_update_btns"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h3
                                    style={{
                                      fontWeight: "bold",
                                      color: "rgb(21,50,95)",
                                      margin: "0 auto",
                                      padding: "10px 0",
                                    }}
                                  >
                                    <b>ADD DEFAULT RULE:</b>
                                  </h3>
                                </div>
                              )}
                              {updateResponse !== "" ? (
                                <div
                                  style={{
                                    margin: "0 auto",
                                  }}
                                >
                                  <div></div>
                                  <p
                                    style={{
                                      color: "rgb(21,50,95)",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {updateResponse?.msg}
                                  </p>
                                  <Button
                                    style={{
                                      backgroundColor: "rgb(220,220,220)",
                                      color: "rgb(21,50,95)",
                                      fontWeight: "bold",
                                      marginTop: "40px",
                                    }}
                                    onClick={() => {
                                      // setRefreshParent();
                                      setUpdateResponse("");
                                      setParentReload(new Date().getTime());
                                      setFetchRules(
                                        Math.floor(Math.random() * 1000) + 1
                                      );
                                    }}
                                  >
                                    OK
                                  </Button>
                                </div>
                              ) : (
                                <>
                                {<div
                                  style={{
                                    width: "100%",
                                    minWidth: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className="rule-name"
                                    style={{
                                      width: "100%",
                                      minWidth: "100%",
                                      margin: "0 auto",
                                      padding: "30px 0",
                                      // borderTop: "1px solid rgb(21,50,95)",
                                      // borderBottom: "1px solid rgb(21,50,95)",
                                      paddingTop: "20px",
                                      paddingBottom: "20px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <h3
                                      style={{
                                        fontWeight: "bold",
                                        color: "rgb(21,50,95)",
                                      }}
                                    >
                                      {"Enter Rule's Name:"}
                                    </h3>
                                    <TextField
                                      id="outlined-basic"
                                      // variant="outlined"
                                      style={{
                                        width: "450px",
                                      }}
                                      value={ruleName}
                                      onChange={ruleNameHandler}
                                      placeholder={rule}
                                    />
                                  </div>
                                  {relaventRuleData?.fields?.cadence_secs
                                    ?.enabled && (
                                    <div
                                      className="rule-name"
                                      style={{
                                        width: "100%",
                                        minWidth: "100%",
                                        margin: "0 auto",
                                        padding: "30px 0",
                                        // borderTop: "1px solid rgb(21,50,95)",
                                        // borderBottom: "1px solid rgb(21,50,95)",
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <FormControl
                                        className={classes.formControl}
                                        variant="outlined"
                                        style={{
                                          width: 140,
                                          color: "#6D809D",
                                        }}
                                      >
                                        <InputLabel
                                          id="demo-mutiple-chip-label"
                                          shrink={Boolean(selectedInterVal)}
                                        >
                                          {
                                            relaventRuleData?.fields
                                              ?.cadence_secs?.label
                                          }
                                        </InputLabel>
                                        <Select
                                          variant="outlined"
                                          labelId="demo-mutiple-chip-label"
                                          id="demo-mutiple-chip"
                                          defaultValue={""}
                                          value={selectedInterVal}
                                          onChange={handleIntervalChange}
                                          input={
                                            <Input id="select-multiple-chip" />
                                          }
                                        >
                                          {relaventRuleData?.fields?.cadence_secs?.options?.map(
                                            (item: any, index: number) => (
                                              <MenuItem
                                                key={index}
                                                value={item}
                                              >
                                                {`${item / 60} min`}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                      <p
                                        style={{
                                          fontStyle: "italic",
                                          color: "red",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {
                                          "applies where products and cameras are configured for fetching images"
                                        }
                                      </p>
                                    </div>
                                  )}
                                  <div
                                    className="form-field"
                                    style={{
                                      marginTop: "45px",
                                      width: "100%",
                                      minWidth: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      margin: "0 auto",
                                      padding: "10px 0",
                                    }}
                                  >
                                    {relaventRuleData?.fields?.zone_configs?.map(
                                      (config: any, index: number) => {
                                        if (config?.type === "zone") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={true}
                                              className={classes.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 140,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel>
                                                {config?.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={config?.default}
                                                input={<Input />}
                                                displayEmpty
                                              >
                                                <MenuItem
                                                  value={config?.default}
                                                >
                                                  {config?.options?.replaceAll(
                                                    "_",
                                                    " "
                                                  )}
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          );
                                        }

                                        if (config?.type === "class_label") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              className={classes?.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 140,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel>
                                                {config.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={selectedClass}
                                                onChange={handleClassChange}
                                                input={<Input />}
                                              >
                                                {config.options.map(
                                                  (
                                                    classItem: any,
                                                    idx: number
                                                  ) => (
                                                    <MenuItem
                                                      key={idx}
                                                      value={classItem}
                                                    >
                                                      {classItem.split(".")[1]}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </FormControl>
                                          );
                                        }
                                        if (config.type === "object_motion") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              className={classes.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 160,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel
                                                shrink={Boolean(selectedClass)}
                                              >
                                                {config.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={selectedMotion}
                                                onChange={handleObjectMotion}
                                                input={<Input />}
                                              >
                                                {config.options.map(
                                                  (
                                                    motion: any,
                                                    idx: number
                                                  ) => (
                                                    <MenuItem
                                                      key={idx}
                                                      value={motion.replaceAll(
                                                        " ",
                                                        "_"
                                                      )}
                                                    >
                                                      {motion.replaceAll(
                                                        "_",
                                                        " "
                                                      )}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </FormControl>
                                          );
                                        }
                                        if (
                                          config.type === "confidence_threshold"
                                        ) {
                                          return (
                                            <Tooltip
                                              key={index}
                                              placement="top"
                                              title={`Please note the default value for the Threshold is ${config.default}% with the minimum value being ${config.min}% and the maximum being ${config.max}%.`}
                                            >
                                              <TextField
                                                disabled={
                                                  zoneData?.length === 2
                                                }
                                                type="number"
                                                label={config.label}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                inputProps={{
                                                  min: config.min,
                                                  max: config.max,
                                                  step: 1,
                                                }}
                                                style={{
                                                  width: 130,
                                                  marginTop: "9px",
                                                }}
                                                value={selectedThreshold}
                                                onChange={handleThreshold}
                                                variant="standard"
                                              />
                                            </Tooltip>
                                          );
                                        }

                                        if (config.type === "count") {
                                          return (
                                            <TextField
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              type="number"
                                              label={config.label}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              inputProps={{
                                                min: config.min,
                                                max: config.max,
                                                step: 1,
                                              }}
                                              style={{
                                                width: 130,
                                                marginTop: "9px",
                                              }}
                                              value={selectedCount}
                                              onChange={handleCountChange}
                                              variant="standard"
                                            />
                                          );
                                        }

                                        if (config.type === "count_logic") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              className={classes.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 160,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel>
                                                {config.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={selectedCountLogic}
                                                onChange={
                                                  handleCountLogicChange
                                                }
                                                input={<Input />}
                                              >
                                                {config?.options?.map(
                                                  (logic: any, idx: any) => (
                                                    <MenuItem
                                                      key={idx}
                                                      value={logic}
                                                    >
                                                      {logic}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </FormControl>
                                          );
                                        }

                                        return null;
                                      }
                                    )}
                                    {updateResponse === "" ? (
                                      <div
                                        style={{
                                          display: relaventRuleData?.fields
                                            ?.zone_configs
                                            ? "block"
                                            : "none",
                                        }}
                                      >
                                        <Button
                                          disabled={
                                            zoneData?.length === 2
                                              ? true
                                              : false
                                          }
                                          variant="contained"
                                          style={{
                                            color: "white",
                                            backgroundColor:
                                              zoneData?.length === 2
                                                ? ""
                                                : "red",
                                            fontWeight: "bold",
                                            width: "95px",
                                            height: "50px",
                                            margin: "0 15px",
                                            transform: "translate(10px,0)",
                                          }}
                                          onClick={addRuleHandlerConfig}
                                        >
                                          ADD
                                        </Button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>}
                                </>
                                //end
                              )}
                              {zoneData?.length === 2 && (
                                <p
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                    width: "800px",
                                    textAlign: "center",
                                    margin: "auto",
                                    padding: "10px 0",
                                  }}
                                >
                                  The maximum default number of zone configs is
                                  2
                                </p>
                              )}
                              <div
                                style={{
                                  marginTop: "45px",
                                  width: "100%",
                                  margin: "0 auto",
                                  // borderTop: "1px solid rgb(21,50,95)",
                                  paddingTop: "20px",
                                  overflow: "auto",
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {zoneData?.length !== 0 && (
                                    <p
                                      style={{
                                        color: "#15325F",
                                        fontSize: "15px",
                                        fontStyle: "normal",
                                        fontFamily: "Open Sans",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {"ZONE CONFIGS:"}
                                    </p>
                                  )}
                                  {zoneData?.map((zone: any, index: any) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          margin: "10px auto",
                                          width: "100%",
                                          backgroundColor: "#F6F6F6",
                                          boxShadow: "2px 1px #F6F6F6",
                                          borderRadius: "10px",
                                          padding: "5px 0",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Zone:{" "}
                                          <span style={{ color: "grey" }}>
                                            {"full image"}
                                          </span>
                                        </p>

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "class_label"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Class:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.class_label?.split(".")[1]}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "object_motion"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Object Motion:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.object_motion?.replaceAll(
                                                "_",
                                                " "
                                              )}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type ===
                                            "confidence_threshold"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Threshold:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.confidence_threshold}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "count"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Count:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.count}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "count_logic"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Count Logic:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.count_logic}
                                            </span>
                                          </p>
                                        )}
                                        {!relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "class_label" ||
                                            config.type === "object_motion" ||
                                            config.type ===
                                              "confidence_threshold"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                              visibility: "hidden",
                                            }}
                                          >
                                            Fetch Interval:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.cadence_secs}
                                            </span>
                                          </p>
                                        )}
                                        <IconButton
                                          style={{
                                            color: "red",
                                            marginTop: "-8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            removeRuleHandler(zone?.random_id);
                                          }}
                                        >
                                          <DeleteForeverIcon />
                                        </IconButton>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {steps === 2 && (
                          <div
                            style={{
                              width: "800px",
                              display: "flex",
                              flexDirection: "column",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                fontSize: "12px",
                                margin: "0 auto",
                                padding: "10px 0",
                              }}
                            >
                              {
                                "Please note that the timezone applied will be as specified at the site level.."
                              }
                            </p>
                            {relaventRuleData?.fields?.valid_days_and_times
                              ?.enabled && (
                              <div
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignContent: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      aria-label="schedule"
                                      name="schedule"
                                      value={radioValue}
                                      onChange={handleTimeChange}
                                    >
                                      <FormControlLabel
                                        value="always"
                                        control={<Radio />}
                                        label="Always active"
                                        disabled={false}
                                      />

                                      <FormControlLabel
                                        value="schedule"
                                        control={<Radio />}
                                        label="Schedule"
                                        disabled={false}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                {radioValue !== "always" && (
                                  <div
                                    style={{
                                      width: "38%",
                                      margin: "0 auto",
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {radioValue === "" ? (
                                      <span>{""}</span>
                                    ) : (
                                      <div
                                        style={{
                                          // width: "38%",
                                          margin: "0 auto",
                                        }}
                                      >
                                        {scheduleRules}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {steps === 3 && (
                          <div style={{ width: "800px", textAlign: "center" }}>
                            <h2
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              CONFIRM DETAILS:
                            </h2>
                            <div style={{ height: "20px" }}></div>
                            <p style={{ fontWeight: "bold", color: "red" }}>
                              {
                                "Please note that you will be updating your defaults rules for this ‘subgroup’"
                              }
                            </p>
                            <div style={{ height: "60px" }}></div>
                            <div
                              style={{
                                width: "100%",
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                Rule Name:{" "}
                                <span style={{ color: "grey" }}>
                                  {`${ruleName === "" ? rule : ruleName}`}
                                </span>
                              </p>
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                Rule Type:{" "}
                                <span style={{ color: "grey" }}>{rule}</span>
                              </p>
                              {relaventRuleData?.fields?.cadence_secs
                                ?.enabled && (
                                <p
                                  style={{
                                    color: "rgb(21,50,95)",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  Fetch Interval:{" "}
                                  <span style={{ color: "grey" }}>
                                    {selectedInterVal}
                                  </span>
                                </p>
                              )}
                            </div>
                            <div
                              style={{
                                marginTop: "45px",
                                width: "100%",
                                margin: "0 auto",
                                // borderTop: "1px solid rgb(21,50,95)",
                                // borderBottom: "1px solid rgb(21,50,95)",
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                overflow: "auto",
                                maxHeight: "300px",
                                overflowY: "auto",
                              }}
                            >
                              <div
                                style={{
                                  display: relaventRuleData?.fields
                                    ?.zone_configs
                                    ? "block"
                                    : "none",
                                }}
                              >
                                <p
                                  style={{
                                    color: "rgb(21,50,95)",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  ZONE CONFIGS:{" "}
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {zoneData?.map((zone: any, index: any) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          margin: "10px auto",
                                          width: "100%",
                                          backgroundColor: "#F6F6F6",
                                          boxShadow: "2px 1px #F6F6F6",
                                          borderRadius: "10px",
                                          padding: "5px 0",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Zone:{" "}
                                          <span style={{ color: "grey" }}>
                                            {"full image"}
                                          </span>
                                        </p>

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "class_label"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Class:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.class_label?.split(".")[1]}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "object_motion"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Object Motion:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.object_motion?.replaceAll(
                                                "_",
                                                " "
                                              )}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type ===
                                            "confidence_threshold"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Threshold:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.confidence_threshold}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "count"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Count:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.count}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "count_logic"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Count Logic:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.count_logic}
                                            </span>
                                          </p>
                                        )}

                                        {!relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "class_label" ||
                                            config.type === "object_motion" ||
                                            config.type ===
                                              "confidence_threshold" ||
                                            config.type === "count" ||
                                            config.type === "count_logic"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                              visibility: "hidden",
                                            }}
                                          >
                                            Fetch Interval:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.cadence_secs}
                                            </span>
                                          </p>
                                        )}

                                        <IconButton
                                          style={{
                                            color: "red",
                                            marginTop: "-8px",
                                            cursor: "pointer",
                                            visibility: "hidden",
                                          }}
                                          onClick={() => {
                                            removeRuleHandler(zone?.random_id);
                                          }}
                                        >
                                          <DeleteForeverIcon />
                                        </IconButton>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  marginTop: "5px",
                                }}
                              >
                                DAYS & TIMES:{" "}
                              </p>
                              <div style={{ width: "100%" }}>
                                <p
                                  style={{
                                    color: "grey",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {timeentrySetRules === undefined
                                    ? "always active"
                                    : scene_valid_days_and_times}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          className="cancel_next_btns"
                          style={{
                            display: "flex",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              color: "rgb(21,50,95)",
                              fontWeight: "bold",
                              width: "100px",
                              height: "40px",
                              margin: "35px auto",
                            }}
                            onClick={() => {
                              setZoneData([]);
                              setSteps(1);
                              setSelectedClass("");
                              setRuleName("");
                              setSelectedInterVal(null);
                              setSelectedMotion("");
                              setSelectedCount(0);
                              setSelectedCountLogic("> (greater than)");
                              setSelectedThreshold(35);
                              handleCloseAddRule();
                              setTimeEntrySetRules(undefined);
                              setRadioValue("always");
                              setRuleTypeToDelete("");
                              setRuleSubTypeToDelete("");
                              setRuleIDToDelete("");
                              setAlertTypeToDelete("");
                              handleRuleConfigModalClose();
                            }}
                          >
                            CANCEL
                          </Button>
                          {steps > 1 && (
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={prevStepsHandler}
                            >
                              PREV
                            </Button>
                          )}
                          {steps !== 3 && (
                            <Button
                              variant="contained"
                              disabled={
                                !relaventRuleData?.fields?.zone_configs
                                  ? false
                                  : zoneData?.length === 0 || steps === 3
                                  ? true
                                  : false
                              }
                              style={{
                                color: "white",
                                backgroundColor:
                                  relaventRuleData?.fields?.zone_configs &&
                                  (zoneData?.length === 0 || steps === 3)
                                    ? "" // Default color when disabled
                                    : "rgb(21,50,95)", // Red background when enabled
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={nextStepsHandler}
                            >
                              NEXT
                            </Button>
                          )}
                          {steps === 3 && (
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={submitGroupDefaultRuleHandler}
                            >
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </AntCard>
                    )}
                  </div>
                )}
              </div>} */}
            </AntModal>
            {sensorDefinedRules && (
              <SelectRulesModal
                openModal={showWizard}
                title={"Select Alert Config Type"}
                onContinue={() => {
                  setShowWizard(false);
                  setOpenWizardModal(false);
                  setSteps(1);
                  setRuleConfig(true);
                }}
                onClose={closeRuleConfigDialog}
                onChange={(event: React.ChangeEvent<any>, value: any) => {
                  handleChangeAuto(value);
                }}
                value={ctx.rule_type}
                options={sensorDefinedRules}
              />
            )}
            {sensorDefinedRules && (
              <SelectRulesModal
                openModal={openWizardModal}
                title={"Select Alert Config Type"}
                onContinue={() => {
                  setSteps(1);
                  setRuleConfig(true);
                }}
                onClose={() => setOpenWizardModal(false)}
                onChange={(event: React.ChangeEvent<any>, value: any) => {
                  handleChangeAuto(value);
                }}
                value={ctx.rule_type}
                options={ruleDefs}
              />
            )}
            {/* {<Dialog
              open={openRuleConfig && false}
              onClose={closeRuleConfigDialog}
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
              fullWidth
              maxWidth="sm"
              PaperProps={{
                style: { height: "250px" }, // Set the width to 80% of the parent
              }}
            >
              <DialogContent>
                <Box sx={addRuleDialogModal}>
                  <div style={{ width: "100%", marginTop: 0 }}>
                    <Grid container spacing={1}>
                      <Autocomplete
                        id="combo-box-demo"
                        onChange={handleAlertChange}
                        options={alertTypes}
                        style={{ width: 400, color: "rgb(21,50,95)" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span
                                className={classes.bold_text}
                                style={{ color: "rgb(21,50,95)" }}
                              >
                                {"Alert Type"}
                              </span>
                            }
                            variant="outlined"
                            value={alert}
                            className={classes.bold_text}
                            style={{
                              color: "rgb(21,50,95)",
                              borderWidth: "2px",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <div style={{ height: "30px" }}></div>
                    {alert === null ? null : (
                      <Grid container spacing={1}>
                        <Autocomplete
                          id="combo-box-rule"
                          onChange={handleRuleChange}
                          options={[
                            ...ruleTypeOptions?.abled,
                            ...ruleTypeOptions?.disabled,
                          ]}
                          getOptionDisabled={(option) => {
                            return ruleTypeOptions?.disabled?.includes(option); // Disable the option if it's in the disabledOptions array
                          }}
                          style={{ width: 400, color: "rgb(21,50,95)" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <span
                                  className={classes.bold_text}
                                  style={{ color: "rgb(21,50,95)" }}
                                >
                                  {"Rule Type"}
                                </span>
                              }
                              variant="outlined"
                              value={rule}
                              className={classes.bold_text}
                              style={{
                                color: "rgb(21,50,95)",
                                borderWidth: "2px",
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </div>
                </Box>
              </DialogContent>
              <DialogActions>
                <Stack spacing={2} direction="row">
                  <div
                    style={{
                      transform: "translate(-70px,20px)",
                    }}
                  >
                    <Button
                      variant="text"
                      style={{ color: "rgb(21,50,95)" }}
                      onClick={closeRuleConfigDialog}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => {
                        setRuleConfig(true);
                        closeRuleConfigDialog();
                      }}
                      style={{
                        color:
                          alert === null || rule === ""
                            ? "#D3D3D3"
                            : "rgb(21,50,95)",
                        pointerEvents:
                          alert === null || rule === "" ? "none" : "auto",
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                </Stack>
              </DialogActions>
            </Dialog>} */}
            <Dialog
              open={openAddRule}
              onClose={handleCloseAddRule}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              maxWidth="md"
              fullWidth={true}
              PaperProps={{
                style: { maxWidth: "1200px", minWidth: "940px" }, // Set the width to 80% of the parent
              }}
            >
              <div
                style={{
                  margin: "auto",
                  height: "500px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: "40px",
                }}
              >
                {loadingSpinner === true ? (
                  <div
                    style={{
                      margin: "auto",
                      fontSize: "100px",
                      position: "absolute",
                      top: "30%",
                      left: "50%",
                      color: "rgb(21,50,95)",
                    }}
                  >
                    <CircularProgress
                      size={80}
                      style={{
                        color: "rgb(21,50,95)",
                        margin: "auto",
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    {updateRulesMsg !== "" ? (
                      <div
                        style={{
                          marginTop: "150px",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "rgb(21,50,95)",
                            textAlign: "center",
                          }}
                        >
                          {updateRulesMsg}
                        </p>
                        <Button
                          style={{
                            backgroundColor: "rgb(220,220,220)",
                            color: "rgb(21,50,95)",
                            fontWeight: "bold",
                            margin: " 40px 180px",
                            width: "100px",
                          }}
                          onClick={() => {
                            handleCloseAddRule();
                            setUpdateRulesMsg("");
                          }}
                        >
                          OK
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {steps === 1 && (
                          <div>
                            <div>
                              {updateResponse === "" && (
                                <div
                                  className="save_update_btns"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h3
                                    style={{
                                      fontWeight: "bold",
                                      color: "rgb(21,50,95)",
                                      margin: "0 auto",
                                      padding: "10px 0",
                                    }}
                                  >
                                    {default_rules?.rules?.length > 1 ? (
                                      <b>EDIT DEFAULT RULES:</b>
                                    ) : (
                                      <b>EDIT DEFAULT RULE:</b>
                                    )}
                                  </h3>
                                </div>
                              )}
                              {updateResponse !== "" ? (
                                <div
                                  style={{
                                    margin: "0 auto",
                                  }}
                                >
                                  <div></div>
                                  <p
                                    style={{
                                      color: "rgb(21,50,95)",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {updateResponse?.msg}
                                  </p>
                                  <Button
                                    style={{
                                      backgroundColor: "rgb(220,220,220)",
                                      color: "rgb(21,50,95)",
                                      fontWeight: "bold",
                                      marginTop: "40px",
                                    }}
                                    onClick={() => {
                                      // setRefreshParent();
                                      setUpdateResponse("");
                                      setParentReload(new Date().getTime());
                                      setFetchRules(
                                        Math.floor(Math.random() * 1000) + 1,
                                      );
                                    }}
                                  >
                                    OK
                                  </Button>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    minWidth: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className="rule-name"
                                    style={{
                                      width: "100%",
                                      minWidth: "100%",
                                      margin: "0 auto",
                                      padding: "30px 0",
                                      // borderTop: "1px solid rgb(21,50,95)",
                                      // borderBottom: "1px solid rgb(21,50,95)",
                                      paddingTop: "20px",
                                      paddingBottom: "20px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <h3
                                      style={{
                                        fontWeight: "bold",
                                        color: "rgb(21,50,95)",
                                      }}
                                    >
                                      {"Enter Rule's Name:"}
                                    </h3>
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      style={{
                                        width: "450px",
                                      }}
                                      value={ruleNameToDelete}
                                      onChange={editRuleNameHandler}
                                      placeholder={ruleNameToDelete}
                                    />
                                  </div>
                                  {relaventRuleDataToDelete?.fields
                                    ?.cadence_secs?.enabled && (
                                    <div
                                      className="rule-name"
                                      style={{
                                        width: "100%",
                                        minWidth: "100%",
                                        margin: "0 auto",
                                        padding: "30px 0",
                                        // borderTop: "1px solid rgb(21,50,95)",
                                        // borderBottom: "1px solid rgb(21,50,95)",
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <FormControl
                                        className={classes.formControl}
                                        variant="outlined"
                                        style={{
                                          width: 140,
                                          color: "#6D809D",
                                        }}
                                      >
                                        <InputLabel id="demo-mutiple-chip-label">
                                          {
                                            relaventRuleDataToDelete?.fields
                                              ?.cadence_secs?.label
                                          }{" "}
                                        </InputLabel>
                                        <Select
                                          variant="outlined"
                                          labelId="demo-mutiple-chip-label"
                                          id="demo-mutiple-chip"
                                          defaultValue={
                                            relaventRuleDataToDelete?.fields
                                              ?.cadence_secs?.default || ""
                                          }
                                          value={selectedInterValEdit}
                                          onChange={handleIntervalEditChange}
                                          input={
                                            <Input id="select-multiple-chip" />
                                          }
                                        >
                                          {relaventRuleDataToDelete?.fields?.cadence_secs?.options?.map(
                                            (item: any, index: number) => (
                                              <MenuItem
                                                key={index}
                                                value={item}
                                              >
                                                {`${item / 60} min`}
                                              </MenuItem>
                                            ),
                                          )}
                                        </Select>
                                      </FormControl>
                                      <p
                                        style={{
                                          fontStyle: "italic",
                                          color: "red",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {
                                          "applies where products and cameras are configured for fetching images"
                                        }
                                      </p>
                                    </div>
                                  )}
                                  <div
                                    className="form-field"
                                    style={{
                                      marginTop: "45px",
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      margin: "0 auto",
                                      borderTop: "1px solid rgb(21,50,95)",
                                      paddingTop: "20px",
                                    }}
                                  >
                                    {relaventRuleDataToDelete?.fields?.zone_configs?.map(
                                      (config: any, index: number) => {
                                        if (config?.type === "zone") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={true}
                                              className={classes.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 140,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel>
                                                {config?.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={config?.default}
                                                input={<Input />}
                                                displayEmpty
                                              >
                                                <MenuItem
                                                  value={config?.default}
                                                >
                                                  {config?.options?.replaceAll(
                                                    "_",
                                                    " ",
                                                  )}
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          );
                                        }

                                        if (config?.type === "class_label") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              className={classes?.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 140,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel>
                                                {config.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={selectedClass}
                                                onChange={handleClassChange}
                                                input={<Input />}
                                              >
                                                {config.options.map(
                                                  (
                                                    classItem: any,
                                                    idx: number,
                                                  ) => (
                                                    <MenuItem
                                                      key={idx}
                                                      value={classItem}
                                                    >
                                                      {classItem.split(".")[1]}
                                                    </MenuItem>
                                                  ),
                                                )}
                                              </Select>
                                            </FormControl>
                                          );
                                        }
                                        if (config.type === "object_motion") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              className={classes.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 160,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel
                                                shrink={Boolean(selectedClass)}
                                              >
                                                {config.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={selectedMotion}
                                                onChange={handleObjectMotion}
                                                input={<Input />}
                                              >
                                                {config.options.map(
                                                  (
                                                    motion: any,
                                                    idx: number,
                                                  ) => (
                                                    <MenuItem
                                                      key={idx}
                                                      value={motion.replaceAll(
                                                        " ",
                                                        "_",
                                                      )}
                                                    >
                                                      {motion.replaceAll(
                                                        "_",
                                                        " ",
                                                      )}
                                                    </MenuItem>
                                                  ),
                                                )}
                                              </Select>
                                            </FormControl>
                                          );
                                        }
                                        if (
                                          config.type === "confidence_threshold"
                                        ) {
                                          return (
                                            <Tooltip
                                              key={index}
                                              placement="top"
                                              title={`Please note the default value for the Threshold is ${config.default}% with the minimum value being ${config.min}% and the maximum being ${config.max}%.`}
                                            >
                                              <TextField
                                                disabled={
                                                  zoneData?.length === 2
                                                }
                                                type="number"
                                                label={config.label}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                inputProps={{
                                                  min: config.min,
                                                  max: config.max,
                                                  step: 1,
                                                }}
                                                style={{
                                                  width: 130,
                                                  marginTop: "9px",
                                                }}
                                                value={selectedThreshold}
                                                onChange={handleThreshold}
                                                variant="standard"
                                              />
                                            </Tooltip>
                                          );
                                        }
                                        if (config.type === "count") {
                                          return (
                                            <TextField
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              type="number"
                                              label={config.label}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              inputProps={{
                                                min: config.min,
                                                max: config.max,
                                                step: 1,
                                              }}
                                              style={{
                                                width: 130,
                                                marginTop: "9px",
                                              }}
                                              value={selectedCount}
                                              onChange={handleCountChange}
                                              variant="standard"
                                            />
                                          );
                                        }

                                        if (config.type === "count_logic") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              className={classes.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 160,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel>
                                                {config.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={selectedCountLogic}
                                                onChange={
                                                  handleCountLogicChange
                                                }
                                                input={<Input />}
                                              >
                                                {config?.options?.map(
                                                  (logic: any, idx: any) => (
                                                    <MenuItem
                                                      key={idx}
                                                      value={logic}
                                                    >
                                                      {logic}
                                                    </MenuItem>
                                                  ),
                                                )}
                                              </Select>
                                            </FormControl>
                                          );
                                        }

                                        return null;
                                      },
                                    )}
                                    {relaventRuleData?.fields?.cadence_secs
                                      ?.enabled && (
                                      <div>
                                        <FormControl
                                          className={classes.formControl}
                                          variant="outlined"
                                          style={{
                                            width: 140,
                                            color: "#6D809D",
                                            display: "none",
                                          }}
                                        >
                                          <InputLabel
                                            id="demo-mutiple-chip-label"
                                            shrink={Boolean(selectedInterVal)}
                                          >
                                            {
                                              relaventRuleData?.fields
                                                ?.cadence_secs?.label
                                            }
                                          </InputLabel>
                                          <Select
                                            variant="outlined"
                                            labelId="demo-mutiple-chip-label"
                                            id="demo-mutiple-chip"
                                            defaultValue={""}
                                            value={selectedInterVal}
                                            onChange={handleIntervalChange}
                                            input={
                                              <Input id="select-multiple-chip" />
                                            }
                                          >
                                            {relaventRuleData?.fields?.cadence_secs?.options?.map(
                                              (item: any, index: number) => (
                                                <MenuItem
                                                  key={index}
                                                  value={item}
                                                >
                                                  {`${item / 60} min`}
                                                </MenuItem>
                                              ),
                                            )}
                                          </Select>
                                        </FormControl>
                                        <p
                                          style={{
                                            fontStyle: "italic",
                                            color: "red",
                                            fontSize: "10px",
                                          }}
                                        >
                                          {
                                            "applies where products and cameras are configured for fetching images"
                                          }
                                        </p>
                                      </div>
                                    )}
                                    {updateResponse === "" ? (
                                      <div
                                        style={{
                                          display: relaventRuleDataToDelete
                                            ?.fields?.zone_configs
                                            ? "block"
                                            : "none",
                                        }}
                                      >
                                        <Button
                                          disabled={
                                            zoneData?.length === 2
                                              ? true
                                              : false
                                          }
                                          variant="contained"
                                          style={{
                                            backgroundColor:
                                              zoneData?.length === 2
                                                ? ""
                                                : "red",
                                            fontWeight: "bold",
                                            color: "white",
                                            width: "95px",
                                            height: "50px",
                                            margin: "0 15px",
                                            transform: "translate(10px,0)",
                                          }}
                                          onClick={addRuleHandler}
                                        >
                                          ADD
                                        </Button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              )}
                              {zoneData?.length === 2 && (
                                <p
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                    width: "800px",
                                    textAlign: "center",
                                    margin: "auto",
                                    padding: "10px 0",
                                  }}
                                >
                                  The maximum default number of zone configs is
                                  2
                                </p>
                              )}
                              <div
                                style={{
                                  marginTop: "45px",
                                  width: "100%",
                                  margin: "0 auto",
                                  // borderTop: "1px solid rgb(21,50,95)",
                                  paddingTop: "20px",
                                  overflow: "auto",
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {zoneData?.length !== 0 && (
                                    <p
                                      style={{
                                        color: "#15325F",
                                        fontSize: "15px",
                                        fontStyle: "normal",
                                        fontFamily: "Open Sans",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {"ZONE CONFIGS:"}
                                    </p>
                                  )}
                                  {zoneData?.map((zone: any, index: any) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          margin: "10px auto",
                                          width: "100%",
                                          backgroundColor: "#F6F6F6",
                                          boxShadow: "2px 1px #F6F6F6",
                                          borderRadius: "10px",
                                          padding: "5px 0",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Zone:{" "}
                                          <span style={{ color: "grey" }}>
                                            {"full image"}
                                          </span>
                                        </p>

                                        {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "class_label",
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Class:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.class_label?.split(".")[1]}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "object_motion",
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Object Motion:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.object_motion?.replaceAll(
                                                "_",
                                                " ",
                                              )}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type ===
                                            "confidence_threshold",
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Threshold:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.confidence_threshold}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "count",
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Count:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.count}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "count_logic",
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Count Logic:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.count_logic}
                                            </span>
                                          </p>
                                        )}

                                        {!relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "class_label" ||
                                            config.type === "object_motion" ||
                                            config.type ===
                                              "confidence_threshold",
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                              visibility: "hidden",
                                            }}
                                          >
                                            Fetch Interval:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.cadence_secs}
                                            </span>
                                          </p>
                                        )}

                                        <IconButton
                                          style={{
                                            color: "red",
                                            marginTop: "-8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            removeRuleHandler(zone?.random_id);
                                          }}
                                        >
                                          <DeleteForeverIcon />
                                        </IconButton>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {steps === 2 && (
                          <div
                            style={{
                              width: "800px",
                              display: "flex",
                              flexDirection: "column",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                fontSize: "12px",
                                margin: "0 auto",
                                padding: "10px 0",
                              }}
                            >
                              {
                                "Please note that the timezone applied will be as specified at the site level."
                              }
                            </p>
                            {relaventRuleDataToDelete?.fields
                              ?.valid_days_and_times?.enabled && (
                              <div
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignContent: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      aria-label="schedule"
                                      name="schedule"
                                      value={radioValue}
                                      onChange={handleTimeChange}
                                    >
                                      <FormControlLabel
                                        value="always"
                                        control={<Radio />}
                                        label="Always active"
                                        disabled={false}
                                      />

                                      <FormControlLabel
                                        value="schedule"
                                        control={<Radio />}
                                        label="Schedule"
                                        disabled={false}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                {radioValue !== "always" && (
                                  <div
                                    style={{
                                      width: "38%",
                                      margin: "0 auto",
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {radioValue === "" ? (
                                      <span>{""}</span>
                                    ) : (
                                      <div
                                        style={{
                                          // width: "38%",
                                          margin: "0 auto",
                                        }}
                                      >
                                        {scheduleRules}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {steps === 3 && (
                          <div style={{ width: "800px", textAlign: "center" }}>
                            <h2
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              CONFIRM CHANGES:
                            </h2>
                            <div style={{ height: "20px" }}></div>
                            <p style={{ fontWeight: "bold", color: "red" }}>
                              {
                                "Please note that you will be updating your defaults rules for this ‘subgroup’"
                              }
                            </p>
                            <div style={{ height: "60px" }}></div>
                            <div
                              style={{
                                width: "100%",
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                Rule Name:{" "}
                                <span style={{ color: "grey" }}>
                                  {ruleNameToDelete}
                                </span>
                              </p>
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                Rule Type:{" "}
                                <span style={{ color: "grey" }}>
                                  {ruleTypeToDelete
                                    ?.replaceAll("_", " ")
                                    ?.toLocaleUpperCase()}
                                </span>
                              </p>
                              {relaventRuleDataToDelete?.fields?.cadence_secs
                                ?.enabled && (
                                <p
                                  style={{
                                    color: "rgb(21,50,95)",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  Fetch Interval:{" "}
                                  <span style={{ color: "grey" }}>
                                    {selectedInterVal}
                                  </span>
                                </p>
                              )}
                            </div>
                            <div
                              style={{
                                marginTop: "45px",
                                width: "900px",
                                margin: "0 auto",
                                // borderTop: "1px solid rgb(21,50,95)",
                                // borderBottom: "1px solid rgb(21,50,95)",
                                marginRight: "100px",
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                overflow: "auto",
                                maxHeight: "400px",
                                overflowY: "auto",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  marginTop: "5px",
                                  display: relaventRuleDataToDelete?.fields
                                    ?.zone_configs
                                    ? "block"
                                    : "none",
                                }}
                              >
                                ZONE CONFIGS:{" "}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {zoneData?.map((zone: any, index: any) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        margin: "10px auto",
                                        width: "100%",
                                        backgroundColor: "#F6F6F6",
                                        boxShadow: "2px 1px #F6F6F6",
                                        borderRadius: "10px",
                                        padding: "5px 0",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: "rgb(21,50,95)",
                                          fontWeight: "bold",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        Zone:{" "}
                                        <span style={{ color: "grey" }}>
                                          {"full image"}
                                        </span>
                                      </p>
                                      {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "class_label",
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Class:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.class_label?.split(".")[1]}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "object_motion",
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Object Motion:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.object_motion?.replaceAll(
                                              "_",
                                              " ",
                                            )}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type ===
                                          "confidence_threshold",
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Threshold:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.confidence_threshold}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "count",
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Count:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.count}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "count_logic",
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Count Logic:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.count_logic}
                                          </span>
                                        </p>
                                      )}
                                      <IconButton
                                        style={{
                                          color: "red",
                                          marginTop: "-8px",
                                          cursor: "pointer",
                                          visibility: "hidden",
                                        }}
                                        onClick={() => {
                                          removeRuleHandler(zone?.random_id);
                                        }}
                                      >
                                        <DeleteForeverIcon />
                                      </IconButton>
                                    </div>
                                  );
                                })}
                                <div
                                  style={{
                                    marginTop: "45px",
                                    width: "100%",
                                    margin: "0 auto",
                                    // borderTop: "1px solid rgb(21,50,95)",
                                    paddingTop: "20px",
                                    paddingBottom: "20px",
                                    overflow: "auto",
                                    maxHeight: "300px",
                                    overflowY: "auto",
                                    display: "grid",
                                    // gridTemplateColumns: "repeat(2, 1fr)",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "rgb(21,50,95)",
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                  >
                                    DAYS & TIMES:{" "}
                                  </p>
                                  <div
                                    style={{
                                      width: "80%",
                                      margin: "0 auto",
                                      textAlign: "center",
                                    }}
                                  >
                                    {radioValue === "always" ? (
                                      <p
                                        style={{
                                          color: "rgb(21,50,95)",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "grey",
                                            fontWeight: "light",
                                          }}
                                        >{`${"always active"}`}</span>
                                      </p>
                                    ) : radioValue === "schedule" ? (
                                      <p
                                        style={{
                                          color: "rgb(21,50,95)",
                                          fontWeight: "bold",
                                          pointerEvents: "none",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "grey",
                                            fontWeight: "light",
                                          }}
                                        >
                                          {scene_valid_days_and_times}
                                        </span>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          className="cancel_next_btns"
                          style={{
                            display: "flex",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              color: "rgb(21,50,95)",
                              fontWeight: "bold",
                              width: "100px",
                              height: "40px",
                              margin: "35px auto",
                            }}
                            onClick={() => {
                              setZoneData([]);
                              setSteps(1);
                              setSelectedClass("");
                              setRuleName("");
                              setSelectedInterVal(null);
                              setSelectedMotion("");
                              setSelectedThreshold(35);
                              setSelectedCount(0);
                              setSelectedCountLogic("> (greater than)");
                              handleCloseAddRule();
                              setTimeEntrySetRules(undefined);
                              setRadioValue("always");
                              setRuleTypeToDelete("");
                              setRuleSubTypeToDelete("");
                              setAlertTypeToDelete("");
                              setRuleIDToDelete("");
                            }}
                          >
                            CANCEL
                          </Button>
                          {steps > 1 && (
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={prevStepsHandler}
                            >
                              PREV
                            </Button>
                          )}
                          {steps !== 3 && (
                            <Button
                              variant="contained"
                              disabled={
                                !relaventRuleDataToDelete?.fields?.zone_configs
                                  ? false
                                  : zoneData?.length === 0 || steps === 3
                                    ? true
                                    : false
                              }
                              style={{
                                color: "white",
                                backgroundColor:
                                  relaventRuleDataToDelete?.fields
                                    ?.zone_configs &&
                                  (zoneData?.length === 0 || steps === 3)
                                    ? "" // Default color when disabled
                                    : "rgb(21,50,95)", // Red background when enabled
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={nextStepsHandler}
                            >
                              NEXT
                            </Button>
                          )}
                          {steps === 3 && (
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "190px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={saveUpdateGroupDefaultRulesHandler}
                            >
                              {`${
                                orgName === theORG?.org_name
                                  ? "Update Default Rules"
                                  : "Save Default Rules"
                              }`}
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Dialog>
            <Modal
              open={openWarningModal}
              onClose={closeWarningModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={warningMsg}>
                <div
                  style={{
                    width: "80%",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <h3
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    Cannot delete remaining default rule
                  </h3>
                  <Button
                    variant="contained"
                    onClick={closeWarningModal}
                    style={{
                      color: "rgb(21,50,95)",
                      backgroundColor: "rgb(220,220,220)",
                      fontWeight: "bold",
                      marginTop: "20px",
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </Box>
            </Modal>
            <AntModal
              title={
                <h4
                  style={{
                    margin: 0,
                    fontWeight: "bold",
                    width: "50%",
                    fontSize: "20px",
                    // borderBottom: "2px solid rgb(21,50,95)",
                    color: "rgb(21,50,95)",
                  }}
                >
                  {theORG?.org_name}{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "rgb(21,50,95)",
                    }}
                  >
                    {" "}
                    {`(id=${theORG?.org_id})`}
                  </span>
                </h4>
              }
              open={openRuleModal}
              // aria-labelledby="customized-dialog-title"
              // maxWidth="md"
              onCancel={handleCloseRuleModal}
              onOk={handleCloseRuleModal}
              // fullWidth={true}
              // PaperProps={{
              //   style: { maxWidth: "1200px", minWidth: "940px" }, // Set the width to 80% of the parent
              // }}
              style={{
                minWidth: "1250px",
                position: "relative",
                zIndex: 30,
                maxHeight: 400,
              }} // Set the width to 80% of the parent
            >
              {initialSpinner ? (
                <div
                  style={{
                    margin: "auto",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop: "40px",
                  }}
                >
                  <LoadingSpinner size="large" />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    padding: "20px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AntCard
                    style={{
                      borderRadius: 8,
                      border: "1px solid #ccce",
                      marginBottom: 10,
                    }}
                  >
                    <p
                      className="explainer_text"
                      style={{
                        marginTop: 10,
                        fontWeight: "bold",
                        color: "rgb(21,50,95)",
                        textAlign: "center",
                      }}
                    >
                      {`Please note the below default rules are applied to each camera that has no rules configured. If one or more rules are configured on a camera this will override the default rules set for that specific camera.`}
                    </p>
                    <p
                      style={{
                        padding: "10px 0",
                        marginTop: "15px",
                        width: "100%",
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "red",
                        fontSize: "13px",
                      }}
                    >
                      <p>
                        {default_rules?.rules_origin?.org_name &&
                          (default_rules.rules_origin.org_name ===
                          theORG?.org_name ? (
                            <>
                              The default rules are defined at the level of this
                              group.
                            </>
                          ) : (
                            <>
                              The default rules for this group are inherited
                              from group {default_rules?.rules_origin?.org_name}
                              {` (id=${default_rules?.rules_origin?.org_id})`}
                            </>
                          ))}
                      </p>
                      {default_rules?.rules_origin?.org_name &&
                      default_rules?.rules_origin?.org_name ===
                        theORG?.org_name ? (
                        ""
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              color: "white",
                              backgroundColor: "rgb(21,50,95)",
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              cloneDefaultRulesHandler();
                              initialSpinnerHandler();
                            }}
                          >
                            Clone Inherited Rules
                          </Button>
                        </div>
                      )}
                      {default_rules?.rules_origin?.org_name &&
                        default_rules?.rules_origin?.org_name ===
                          theORG?.org_name && (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(21,50,95)",
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                openRuleConfigDialog();
                              }}
                            >
                              Add Rule
                            </Button>
                          </div>
                        )}
                    </p>
                  </AntCard>
                  <div style={{ width: "100%" }}>
                    {default_rules?.rules && (
                      <>
                        <h1
                          style={{
                            fontSize: 14,
                            marginBottom: 10,
                            fontWeight: 600,
                            textAlign: "left",
                            alignContent: "center",
                            color: "#15325F",
                            marginTop: 30,
                          }}
                        >
                          Configured Rules: (
                          {ctx.rulesList ? ctx.rulesList.length : 0})
                        </h1>
                        <RulesList
                          appStore={appStore}
                          reloadRules={() => {}}
                          reloadZones={() => {}}
                          // sensorId={sensor?.sensor_id}
                          site={site}
                          theORG={theORG}
                          default_rules={default_rules}
                          fetchDefaultRules={fetchDefaultRules}
                          isNotCloned={
                            default_rules?.rules_origin?.org_name &&
                            default_rules?.rules_origin?.org_name !==
                              theORG?.org_name
                          }
                        />
                      </>
                    )}
                    {/* {false && default_rules?.rules?.map(
                      (rule: any, parentIndex: any) => {
                        return (
                          <div
                            className="defaults"
                            style={{ marginTop: "5px" }}
                          >
                            {rule?.zone_configs?.length !== 0 ? (
                              <p
                                key={parentIndex}
                                style={{
                                  width: "95%",
                                  margin: "auto",
                                  fontWeight: "bold",
                                  color: "rgb(21,50,95)",
                                  fontSize: "11px",
                                }}
                              >
                                {rule?.rule_type
                                  ?.replaceAll("_", " ")
                                  .toLocaleUpperCase()}
                                <span
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  ({rule?.alert_type?.toLocaleLowerCase()})
                                </span>
                                <span
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {": "}
                                  {rule?.rule_name}
                                </span>
                              </p>
                            ) : null}
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  width: "90%",
                                  border: "2px solid rgb(21,50,95)",
                                  borderRadius: "10px",
                                  margin: "10px 0",
                                }}
                              >
                                {rule?.zone_configs?.map(
                                  (zone: any, childIndex: any) => {
                                    return (
                                      <div
                                        className="zone_information"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          backgroundColor: "#FFFFFF",
                                          borderRadius: "5px",
                                          width: "90%",
                                          margin: "0 auto",
                                          fontSize: "12px",
                                          marginTop: "15px",
                                        }}
                                        key={childIndex}
                                      >
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                            width: "25%",
                                          }}
                                        >
                                          Zone:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.zone?.replaceAll("_", " ")}
                                          </span>
                                        </p>
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                            width: "25%",
                                            visibility: `${
                                              zone?.class_label
                                                ? "visible"
                                                : "hidden"
                                            }`,
                                          }}
                                        >
                                          Class:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.class_label?.split(".")[1]}
                                          </span>
                                        </p>
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                            width: "30%",
                                            visibility: `${
                                              zone?.object_motion
                                                ? "visible"
                                                : "hidden"
                                            }`,
                                          }}
                                        >
                                          Object Motion:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.object_motion?.replaceAll(
                                              "_",
                                              " "
                                            )}
                                          </span>
                                        </p>
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                            width: "20%",
                                            visibility: `${
                                              zone?.confidence_threshold
                                                ? "visible"
                                                : "hidden"
                                            }`,
                                          }}
                                        >
                                          Threshold:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.confidence_threshold}
                                          </span>
                                        </p>
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                            width: "20%",
                                            display:
                                              zone?.count >= 0
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          Count:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.count}
                                          </span>
                                        </p>
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                            width: "20%",
                                            display: zone?.count_logic
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          Count Logic:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.count_logic}
                                          </span>
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                                <div
                                  style={{
                                    borderTop: "2px solid rgb(21,50,95)",
                                    paddingTop: "10px",
                                    margin: "auto",
                                    color: "rgb(21,50,95)",
                                    width: "95%",
                                    textAlign: "start",
                                    fontWeight: "bold",
                                    padding: "5px 10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginLeft: "18px",
                                    }}
                                  >
                                    Days-Times:{" "}
                                    <span style={{ color: "grey" }}>
                                      {rule?.valid_days_and_times === null
                                        ? "always active"
                                        : rule?.valid_days_and_times?.toString()}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignSelf: "center",
                                  marginTop: "8px",
                                  marginLeft: "10px",
                                  width: "2px",
                                }}
                              >
                                {default_rules?.rules_origin?.org_name ===
                                  theORG?.org_name && (
                                  <IconButton
                                    style={{
                                      color: "rgb(21,50,95)",
                                      marginTop: "-8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event: any) => {
                                      if (rule) {
                                        editRuleHandler(rule?.rule_type);
                                        setRuleNameToDelete(rule?.rule_name);
                                        setRuleIDToDelete(rule?.id);
                                        setRuleTypeToDelete(rule?.rule_type);
                                        setAlertTypeToDelete(rule?.alert_type);
                                        setRuleSubTypeToDelete(
                                          rule?.rule_subtype
                                        );
                                        if (rule?.cadence_secs) {
                                          setSelectedInterValEdit(
                                            rule?.cadence_secs
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    <EditNoteIcon
                                      style={{ fontSize: "18px" }}
                                    />
                                  </IconButton>
                                )}
                                {default_rules?.rules_origin?.org_name ===
                                  theORG?.org_name && (
                                  <IconButton
                                    style={{
                                      color: "red",
                                      marginTop: "-8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleOpenDeleteRuleModal();
                                      setRuleTypeToDelete(rule?.rule_type);
                                      setAlertTypeToDelete(rule?.alert_type);
                                      setRuleIDToDelete(rule?.id);
                                    }}
                                  >
                                    <DeleteForeverIcon
                                      style={{ fontSize: "18px" }}
                                    />
                                  </IconButton>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )} */}
                  </div>
                </div>
              )}
            </AntModal>
          </div>
          <div className={classes.shelf_menu}>
            <GroupExpansionPanelMenu
              // reloadLogos={reloadLogos}
              handleDefaultRulesOrg={() => {
                setTHEORG(currentOrg);
                setFetchRules(Math.floor(Math.random() * 1000) + 1);
                initialSpinnerHandler();
              }}
              defaultRules={true}
              openAddDefaultsModal={handleOpenRuleModal}
              setSelectedItem={setSelectedItem}
              activeOrg={currentOrg}
              currentOrg={currentOrg.org_id}
              appStore={appStore}
              advancedsettings={false}
              moreinfo={true}
              del={true}
              edit={false}
              enable={false}
              enabled={false}
              // handleMoreInfo={toggleMoreInfo}
              handleMoreInfo={() => {
                setOpenEditGroupModal(true);
              }}
              handleAdvancedSettings={dummy}
              handleEnable={dummy}
              handleEdit={dummy}
              handleDelete={() => {
                setOpenDeleteGroupModal(true);
              }}
              notes={true}
              handleNotes={handleEditNotes}
              handleAddGroupToFrontel={() => {
                setSelectedFrontelGroup(currentOrg);
                setOpenAddGroupToFrontel(true);
              }}
            ></GroupExpansionPanelMenu>
          </div>
        </div>
        {groupmoreinfo}
        <Divider className="right-drawer-divider-groups" />
        <Card
          className={classes.expansionbar_responsive}
          onClick={() => {
            handleGroupsExpanded();
          }}
        >
          <Tooltip
            placement="bottom"
            title="Groups provide a folder like arrangement reflecting your organisational structure, each site belongs to a specific group and user access to a group implies access to it's sub-groups."
          >
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_icon}>
                  <svg
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.17 2L9.17 4H18V14H2V2H7.17ZM8 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8 0Z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </div>
                <div className={classes.header_text_white}>
                  Sub-Groups{"  (" + groupcount + ")"}
                </div>
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={(event) => {
                    event.stopPropagation();
                    openAddGroupModal();
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                      fill="#ffffff"
                      fill-opacity="0.87"
                    />
                  </svg>
                </IconButton>
                {disabledGroupicon}
              </div>
            </Typography>
          </Tooltip>
          {expandgroups}
        </Card>
        <div className={classes.expansioncontent}>{subgroups}</div>
        {/* Added Expansion Function directly */}
        <Card
          className={`${classes.expansionbar_responsive} `}
          onClick={() => {
            handleSitesExpanded();
          }}
        >
          <Tooltip
            placement="bottom"
            title="A site represents a physical location with a set of cameras for a certain product and associated alerting rules and delivery methods. A site may be serviced by one or more hubs."
          >
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_icon}>
                  <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.87">
                      <path
                        d="M17 6.3V1H14V3.6L10 0L0 9H3V17H9V11H11V17H17V9H20L17 6.3ZM15 15H13V9H7V15H5V7.19L10 2.69L15 7.19V15Z"
                        fill="#FFFFFF"
                        fill-opacity="0.87"
                      />
                      <path
                        d="M8 7H12C12 5.9 11.1 5 10 5C8.9 5 8 5.9 8 7Z"
                        fill="#FFFFFF"
                        fill-opacity="0.87"
                      />
                    </g>
                  </svg>
                </div>
                {/*Site group sections*/}
                <div className={classes.header_text_white}>
                  Sites{"  (" + sitecount + ")"}
                </div>

                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={(event) => {
                    event.stopPropagation();
                    openAddSiteModal();
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </IconButton>
                {disabledicon}
              </div>
            </Typography>
          </Tooltip>
          {expandsites}
        </Card>
        <div className={classes.expansioncontent}>{sitesdrawer}</div>
        {sharedhubsdisplay}
        {/* <Card
          className={classes.expansionbar_responsive}
          onClick={() => {
            handleReportGroupsExpanded();
          }}
        >
          <Tooltip
            placement="bottom"
            title="A report group is a named set of email addresses used in site reporting to determine delivery."
          >
            <Typography>
              <div className={classes.header}>
                <div className={classes.header_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </div>
                <div className={classes.header_text_white}>
                  Report Groups{"  (" + reportcount + ")"}
                </div>
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={(event) => {
                    event.stopPropagation();
                    openAddReportGroupModal();
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </IconButton>
              </div>
            </Typography>
          </Tooltip>
          {expandreports}
        </Card> */}
        {/* <div className={classes.expansioncontent}>{reportgroupsdrawer}</div> */}
        {/****************************************  Modal POPUS  **************************************/}

        <ConfirmDialog
          title=""
          open={confirmSiteOpen}
          setOpen={setConfirmSiteOpen}
          onConfirm={deleteSite}
        >
          {confirmmessage}
        </ConfirmDialog>
        <ConfirmDialog
          title=""
          open={confirmSharedHubOpen}
          setOpen={setConfirmSharedHubOpen}
          onConfirm={deleteSharedHub}
        >
          {confirmmessage}
        </ConfirmDialog>
        <ConfirmDialog
          title=""
          open={confirmGroupOpen}
          setOpen={setConfirmGroupOpen}
          onConfirm={deleteGroup}
        >
          {confirmmessage}
        </ConfirmDialog>
        <StatusHubModal
          appStore={appStore}
          open={statushub}
          hub={statusinghub!}
          updateHub={dummy}
          onClose={closeStatusHub}
        ></StatusHubModal>
        <EditHubModal
          appStore={appStore}
          open={edithub}
          hub={editinghub!}
          updateHub={(location: string, networkconfig: NetworkConfig) =>
            updateSharedHub(location, networkconfig)
          }
          updateSharedHub={(location: string, networkconfig: NetworkConfig) =>
            updateSharedHub(location, networkconfig)
          }
          onClose={closeEditHub}
        ></EditHubModal>

        <MessageModal
          open={messagemodalopen}
          onClose={closeMessageModal}
          message={popmessage}
          elements={undefined}
        />
        <MoveModal
          appStore={appStore}
          open={movemodalopen}
          close={closeMoveModal}
          group={movegroup}
          movingGroupOrg={movingGroupOrg}
          site={movesite}
          hub={movehub}
          setSelectedItem={(item: any, editing: boolean) =>
            setSelectedItem(item, editing)
          }
        ></MoveModal>
        <AddGenericNotes
          open={openNotesModal}
          notesResultsArray={notesResultsArray}
          appStore={appStore}
          currentOrg={currentOrg}
          onClose={closeEditNotes}
        ></AddGenericNotes>
        <input value={refresh} type="hidden" />
        {openChecker ? (
          <ChangePasswordCheck
            open={openChecker}
            onClose={onCloseChecker}
            openNextModal={openNextModal}
          />
        ) : null}
        {open ? (
          <SMTPChangePassword
            newPassword={createNewSmtpPasswordDetails}
            open={open}
            onClose={onClose}
          />
        ) : null}
        <RenameSite
          open={openRenameSiteModal}
          onClose={closeRenamesiteModal}
          appStore={appStore}
          renameSiteName={(site: Site, name: string) => {
            renameSiteName(site, name);
          }}
          site={site}
        />
        {openTrainingsSettingsModal && (
          <TrainingSettings
            open={openTrainingsSettingsModal}
            onClose={closeTrainingSettingsModal}
            appStore={appStore}
            trainingSettings={(
              site: Site,
              training_fraction: number,
              training_triggers_only: boolean,
            ) => {
              trainingSettings(site, training_fraction, training_triggers_only);
            }}
            site={site}
          />
        )}

        <EditOrgModal
          open={openEditGroupModal}
          onClose={closeEditModal}
          appStore={appStore}
          editOrgFunc={(org_id: number, org_name: string) => {
            editOrgFunc(org_id, org_name);
          }}
          org={currentOrg}
        />
        <DeleteOrgModal
          open={openDeleteGroupModal}
          onClose={closeDeleteModal}
          deleteOrgFunc={(org: Org) => deleteOrgFunc(org)}
          org={currentOrg}
        />
        {openEnableGroup && (
          <EnableOrgModal
            open={openEnableGroup}
            onClose={() => {
              setOpenEnableGroup(false);
            }}
            enableOrgFunc={(org: Org) => EnableOrgFunc(org)}
            org={selectedEnableGroup}
          />
        )}

        <GenerateSentinelToken
          open={openGenerateSentinelToken}
          appStore={appStore}
          org={selectedSentinelGroup}
          onClose={closeGenerateSentinelToken}
        />
        <ShowSentinelToken
          open={openShowSentinelToken}
          appStore={appStore}
          org={selectedSentinelGroup}
          onClose={closeShowSentinelToken}
        />
        {openAddGroupToSentinel && (
          <AddGroupToSentinel
            open={openAddGroupToSentinel}
            appStore={appStore}
            org={selectedSentinelGroup}
            onClose={closeAddGroupToSentinel}
          />
        )}

        {openAddGroupToImmix && (
          <Immix
            open={openAddGroupToImmix}
            appStore={appStore}
            org={selectedImmixGroup}
            onClose={closeAddGroupToImmix}
          />
        )}

        {openAddGroupToFrontel && (
          <Frontel
            open={openAddGroupToFrontel}
            appStore={appStore}
            org={selectedFrontelGroup}
            site={undefined}
            hub={undefined}
            onClose={closeAddGroupToFrontel}
          />
        )}

        {openAddSiteToFrontel && (
          <Frontel
            open={openAddSiteToFrontel}
            appStore={appStore}
            org={undefined}
            site={selectedFrontelSite}
            hub={selectedFrontelHub}
            onClose={closeAddSiteToFrontel}
          />
        )}

        {/**********************************************************************************************/}
      </div>
    );
  }
};
export default MainExpansionPanel;
