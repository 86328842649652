import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import { makeStyles, Typography } from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";

import { useStyles } from "../../../styling/global/global";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";

import {
  listBillingRunsItems,
  listBillingProfileOrgsItems,
  RegisteredUser,
  Role,
  Org,
  RegisteredUsersResult,
} from "../../../../services/FunctionService";
interface AddRegisteredUserProps {
  appStore?: AppStore;
  open: boolean;
  onClose: () => void;
  user: RegisteredUser | undefined;
  allowed_roles: string[];
}

const AddRegisteredUser: React.FC<AddRegisteredUserProps> = ({
  open,
  onClose,
  appStore,
  user,
  allowed_roles,
}: AddRegisteredUserProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<Role[] | undefined>([]);
  const [roleId, setRoleId] = useState<number | undefined>(undefined);
  const [orgslist, setOrgsList] = useState<Org[]>([]);
  const [groupid, setGroupId] = React.useState(0);
  const [groupname, setGroupName] = React.useState("");
  const [groupError, setGroupError] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [checkedState, setCheckedState] = useState<boolean[] | undefined>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [resultStatus, setResultStatus] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [registrationDone, setRegistrationDone] = useState(false);

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  const useStyle = makeStyles({
    paper: {
      border: "2px solid #6D809D",
      color: "#15325F",
    },
  });
  const styler = useStyle();

  const handleCheckboxChange = (position: number) => {
    const updatedCheckedState = checkedState!.map((item, index) =>
      index === position ? !item : item,
    );

    setCheckedState(updatedCheckedState);
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    setRoleId(+event.target.value);
  };
  const handleChangeAuto = (value: any) => {
    setGroupError(false);
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setGroupName(group.org_name);
    }
  };

  const handleProcessedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProcessed(event.target.checked);
  };

  const handleAddUser = async () => {
    if (groupname !== "") {
      setSelectedRoles([]);
      let rolesArray: string[] = [];
      !!roles &&
        roles.map((OrgItem, orgIndex) => {
          checkedState &&
            checkedState.map((checkedItem, checkedIndex) => {
              if (checkedItem == true) {
                if (orgIndex === checkedIndex) {
                  rolesArray.push(OrgItem.role);
                }
              }
            });
        });
      if (rolesArray.join() !== "") {
        const token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          let add_user: RegisteredUsersResult =
            await appStore?.functionService.addRegisteredUser(
              token,
              groupid,
              rolesArray.join(),
              user!.id,
              processed,
            );
          if (add_user.success) {
            setResultStatus(true);
            setResultMessage(add_user.msg);
            setRegistrationDone(true);
          } else {
            setResultStatus(true);
            setResultMessage(add_user.msg);
          }
        }
      } else {
        setResultStatus(true);
        setResultMessage("Please select at least one role");
      }
    } else {
      setGroupError(true);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        const rolesResult: Role[] =
          await appStore?.functionService.getAllRoles(token);

        let filteredRoles = rolesResult.filter((item, index) => {
          let findRole = allowed_roles.findIndex((x) => x === item.role);
          if (findRole > 0) {
            return item;
          }
        });
        setRoles(filteredRoles);
        setCheckedState(new Array(filteredRoles.length).fill(false));
      }
      setLoading(false);
    };
    fetchData();
    if (user) {
      setProcessed(user.processed);
    }
  }, [user, allowed_roles]);
  useEffect(() => {
    const fetchOrgList = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        const OrgListResult =
          await appStore?.functionService.getListOrgsWithTreeNames(token);
        if (OrgListResult && OrgListResult.success) {
          let orgz = OrgListResult?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      }
    };
    fetchOrgList();
  }, []);

  useEffect(() => {
    if (resultStatus) {
      const timeId = setTimeout(() => {
        // After 5 seconds set the show value to false

        setResultStatus(false);
        setResultMessage("");
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
    // when the component is mounted, the alert is displayed for 5 seconds
  }, [resultStatus]);

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <span style={{ fontSize: "20px" }}>Add {user?.email}</span>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : registrationDone ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box mt={2} mb={2} justifyContent="center">
                <Alert variant="outlined" severity="success">
                  Registration successfully done
                </Alert>
              </Box>
              <Box mt={2} mb={2} justifyContent="center">
                <Button
                  variant="contained"
                  className={classes.menu_button}
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className={classes.bold_text}>Finish</span>
                </Button>
              </Box>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingRight: "5%",
                paddingLeft: "5%",
              }}
            >
              <div
                style={{
                  boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                  marginTop: 5,
                  borderRadius: 10,
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                <Paper style={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer style={{ maxHeight: 300 }}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography className={classes.bold_text}>
                              role
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.bold_text}>
                              {" "}
                              description
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.bold_text}>
                              select
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!!roles &&
                          roles.map((item, index) => {
                            return (
                              <StyledTableRow>
                                <TableCell>
                                  <Typography
                                    className={classes.bold_text_billing}
                                  >
                                    {item.role}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    className={classes.bold_text_billing}
                                  >
                                    {item.description}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <FormControl>
                                    <Checkbox
                                      className={
                                        classes.form_component_checkbox
                                      }
                                      onChange={() =>
                                        handleCheckboxChange(index)
                                      }
                                      color="primary"
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                      }
                                      checked={checkedState![index]}
                                    />
                                  </FormControl>
                                </TableCell>
                              </StyledTableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5%",
                marginBottom: "5%",
                width: "100%",
                paddingRight: "5%",
                paddingLeft: "5%",
              }}
            >
              <Autocomplete
                id="combo-box-demo"
                className={classes.form_component_medium}
                options={orgslist}
                onChange={(event: React.ChangeEvent<any>, value: any) => {
                  handleChangeAuto(value);
                }}
                style={{ width: 600, color: "#6D809D" }}
                classes={{ paper: styler.paper }}
                getOptionLabel={(option: any) => {
                  let tree = option.org_tree_names!.map(
                    (item: any) => `   /   ${item} `,
                  );
                  let treeString = tree.toString();
                  const rpl = treeString.replace(/,/g, "");
                  return `${rpl}`;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span className={classes.bold_text_billing}>
                        {groupname ? groupname : "Please Select entry group"}
                      </span>
                    }
                    error={groupError}
                    variant="outlined"
                    value={groupid}
                    className={classes.bold_text_billing}
                  />
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                paddingRight: "5%",
                paddingLeft: "7%",
              }}
            >
              <FormControl>
                <Typography
                  className={classes.bold_text_billing}
                  style={{ marginRight: 10, marginTop: 8 }}
                >
                  mark as processed
                </Typography>
              </FormControl>
              <FormControl>
                <Checkbox
                  className={classes.form_component_checkbox}
                  // disabled={true}
                  onChange={handleProcessedChange}
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={processed}
                />
              </FormControl>
            </div>
            {resultStatus && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  paddingRight: "20%",
                  paddingLeft: "20%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box mt={2} mb={2} justifyContent="center">
                  <Alert variant="outlined" severity="error">
                    {resultMessage}
                  </Alert>
                </Box>
              </div>
            )}

            <div
              style={{ marginTop: "5%", marginBottom: "5%" }}
              className={classes.twoButtonsInARow}
            >
              <Button
                variant="contained"
                className={classes.menu_button}
                onClick={() => {
                  onClose();
                }}
              >
                <span className={classes.bold_text}>dismiss</span>
              </Button>

              <Button
                variant="contained"
                className={classes.menu_button}
                onClick={() => handleAddUser()}
              >
                <span className={classes.bold_text}>add user</span>
              </Button>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default AddRegisteredUser;
