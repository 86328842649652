import { AppStore } from "../stores/AppStore";
import { AuthService } from "./AuthService";
import {
  FunctionService,
  Classifier,
  Org,
  Site,
  OrgTreeCount,
} from "./FunctionService";
export class Helpers {
  private authService!: AuthService;
  private functionService!: FunctionService;
  public roleSelectorStatus: any = {};
  public webRoleSelectorStatus: any = {};
  public current_org_id = -1;
  async intialiseRoleStatus() {
    if (this.authService) {
      const roles = this.authService.getRoles();
      (await roles)?.forEach((role) => {
        this.roleSelectorStatus[role] = false;
      });
    }
  }

  setAuthService(_authService: AuthService) {
    this.authService = _authService;
  }
  setFunctionService(_functionService: FunctionService) {
    this.functionService = _functionService;
  }
  setCurrentOrgId(_org_id: number) {
    this.current_org_id = _org_id;
  }

  configFromDefault(
    defaults: { [key: string]: any },
    config: { [key: string]: any },
  ) {
    for (let key in defaults) {
      var element = defaults[key];
      var entry = config[key];
      if (entry) {
        defaults[key] = entry;
      }
    }
    return defaults;
  }

  public getSitesFromTree(orgs: { [key: number]: Org }, sites: Site[]) {
    for (let key in orgs) {
      let org = orgs[key];
      if (org.sites) {
        for (let skey in org.sites) {
          let site = org.sites[skey];
          site.site_id = Number(skey);
          sites.push(site);
        }
      }
      if (org.orgs) {
        this.getSitesFromTree(org.orgs, sites);
      }
    }
    return sites;
  }
  public async getOrgDetail(
    appStore: AppStore,
    orgs: { [key: number]: Org },
    top_org_id: number,
    getOrgsFromServer: (orgs: { [key: number]: Org }) => void,
  ) {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let orgtreecount: OrgTreeCount =
        await appStore?.functionService.getOrgTreeTotals(token, top_org_id);
      if (orgtreecount && orgtreecount.success) {
        let totals = orgtreecount.totals;
        if (this.current_org_id === top_org_id) {
          for (let key in totals) {
            if (orgs[key]) {
              orgs[key].sub_org_count = totals[key]["org_count"];
              orgs[key].site_count = totals[key]["site_count"];
              orgs[key].hub_count = totals[key]["hub_count"];
            }
          }
          getOrgsFromServer(orgs);
        } else {
        }
      }
    }
  }
}
